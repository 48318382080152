import React from "react"
import heart from "../../../assets/Heart_01.svg"
import mail from "../../../assets/Mail.svg"
const ThankYou = () => {
    return (
        <div>
            <div style={{background: 'linear-gradient(74deg, #00C4B5 0%, #24E2B4 100%)'}}>
                <div className="ty_comp1" style={{ margin: 'auto', paddingTop: 80, paddingBottom: 50, textAlign: 'center'}}>
                    <div><img src={heart}></img></div>
                    <div style={{fontSize: 24, fontWeight: 700, color: '#FFF', marginTop: 12}}>Thank you for connecting with us at APRA PD!</div>
                    <div style={{fontSize: 16, fontWeight: 600, color: '#FFF', marginTop: 18}}>
                        Introducing you to AlmaConnect's capabilities was a pleasure. We're eager to explore our offerings further with you.
                    </div>
                </div>
            </div>

            <div style={{background: '#F8F8F8', height: '100vh'}}>
                <div className="ty_comp2" style={{ margin: 'auto', paddingTop: 32, textAlign: 'center'}}>
                    <div><img src={mail}></img></div>
                    <div className="text_subtitle_semi_bold" style={{marginTop: 8}}>
                        Watch your email for a special reference to waive the setup fee should you choose to become our valued customer.                    </div>
                </div>
            </div>
        </div>
    )
}

export default ThankYou
