import React, { useState, useEffect, Fragment } from "react";
import { apiAgent } from "../../../axiosConfig.js";
import Pagination from "../../../Pagination";
import { useHistory } from "react-router-dom";
import { switchCurrentTeam } from "../../../actions/teamSliceActions/actions.js";
import { useDispatch } from "react-redux";
import { Button } from "../../cta_utils/AnButton.js";
import TableHeads from "../../visualizers/tabular/TableHeads.jsx";
import TableRows from "../../visualizers/tabular/TableRows.jsx";
import { LoaderComponent } from "../../utils/LoaderComponent.js";
import {AnInputBox} from "../../input_utils/AnInput";

const ProspectMappings = () => {
    let history = useHistory();

    const [teams, setTeams] = useState([]);
    const [selectedTeam, selectTeam] = useState("");
    const [prospects, setProspects] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const _dispatch = useDispatch();

    useEffect(() => {
        fetchProspects(currentPage);
    }, [selectedTeam]);

    const handleOnChange = (e) => {
        setSearchTerm(e.target.value);
    };
    const handleOnKeyDown = (e) => {
        if (e.key == "Enter") {
            fetchProspects(1);
        }
    };

    const fetchProspects = (page) => {
        setIsFetching(true);
        apiAgent({
            method: "get",
            url: `/prospect/mappings?query=${searchTerm}&team_id=${selectedTeam}`,
            params: {
                page: page,
            },
        }).then(
            (res) => {
                setProspects(res.data.data);
                setTotalPages(res.data.total_pages);
                setTotal(res.data.total);
                setTeams(res.data.teams);
                setIsFetching(false);
                setCurrentPage(page);
            },
            (error) => {
                setIsFetching(false);
            }
        );
    };

    const renderSearchBar = () => {
        return (
            <div className="default_flex_container" style={{maxWidth: "700px", marginBottom: "24px"}}>
                <div className="an_search_box" style={{flexBasis: "100%"}}>
                    <div style={{ position: "relative" }}>
                        <input
                            type="text"
                            value={searchTerm}
                            placeholder="Search Prospect"
                            onChange={handleOnChange}
                            onKeyDown={handleOnKeyDown}
                        />
                        <span className="an_search_lens">
                            <img src="/search.svg" />
                        </span>
                    </div>
                </div>
                <Button buttonStyle={{marginLeft: "12px"}}
                        innerButtonStye={{padding: "9px 44px"}}
                        onClick={() => fetchProspects(1)}
                >
                    Search
                </Button>
            </div>

        );
    };
    const renderTableContent = () => (
        <table className="data_table" style={{textAlign: "left"}}>
            <TableHeads typoGraphyClass="text_body_big_light" headerClass="table_header_row">
                <th style={{width: "25%"}}>Name</th>
                <th style={{width: "25%"}}>Company</th>
                <th style={{width: "25%"}}>Location</th>
                <th style={{width: "25%"}}>Linkedin Data</th>
            </TableHeads>
            <tbody>
            {
                prospects.map(prospect => (
                    <TableRows key={prospect.id} rowClass="table_content_row text_body">
                        <td>{prospect.name}</td>
                        <td>{prospect.company}</td>
                        <td>{prospect.location}</td>
                        <td>{prospect.ln_prospects && prospect.ln_prospects.map(ln_prospect => (
                            <div>
                                <a href={'https://'+ln_prospect.url} target="_blank">{ln_prospect.name}</a> - {ln_prospect.company}
                            </div>
                        ))}</td>
                    </TableRows>
                ))
            }
            </tbody>
        </table>
    )

    return (
        <div>
            <div className="aggregate_container" style={{marginTop: "48px", maxWidth: "1120px"}}>
                <div className="text_title_big" style={{marginBottom: "10px"}}>Prospect Mappings</div>
                {renderSearchBar()}
                <AnInputBox label="Select Team" style={{maxWidth: "364px", width: "100%"}}>
                    <select name="team" id="teams" value={selectedTeam} onChange={(e) => selectTeam(e.target.value)}>
                        <option value="">All</option>
                        {
                            teams && teams.map(team => (
                                <option value={team.id}>{team.name}</option>
                            ))
                        }]
                    </select>
                </AnInputBox>
                <div className="default_flex_container" style={{margin: "24px auto 14px"}}>
                    <div className="text_subtitle_big">{total} Prospects</div>
                    <div style={{flex: 1}}>
                        <Pagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            onPageChange={fetchProspects}
                        />
                    </div>
                </div>
                {
                    !isFetching ?
                        renderTableContent() :
                        (<LoaderComponent isLoading={true}  loaderStyle={{width: 32, margin: '36px auto', display: 'block'}}/>)

                }
            </div>
        </div>
    );
};

export default ProspectMappings;
