import {useEffect, useRef, useState} from 'react';
import { apiAgent } from "../../../../axiosConfig.js";
import Post from "../../../feed_components/Post";
import PDFNewsHeader from "../../../../assets/pdf_news_header.png"
import { useSelector } from 'react-redux';
import SpreadsheetIcon from "../../../../assets/SpreadsheetIcon.png";
import useWindowSize from '../../../custom_hooks/useWindowSize.js';
import useCheckUserAccess from '../../../custom_hooks/useCheckUserAccess.js';

const TeamNewsPDFGeneration = (props) => {
    const [news, setNews] = useState([]);
    const parentRef = useRef(null);
    const current_team = useSelector(state => state.current_team.team);
    const windowDimensions = useWindowSize();
    const checkUserAccess = useCheckUserAccess();
    useEffect(() => {
        document.getElementsByClassName('login_header')[0].style.display = 'none'
        fetchNews();        
    }, []);
    useEffect(() => {
        if(parentRef.current && parentRef.current.childNodes.length > 0){
            let currentPage = 1;
            let currentHeight = 284 + 120//754 + 150; //height of header + analytics
            const pageHeight = 1350;
            parentRef.current.childNodes.forEach(element => {
                let emlem_heigt = element.clientHeight
                let newMargin = 0;
                if(currentHeight + emlem_heigt > currentPage * pageHeight){
                    let overFlow = (currentHeight + emlem_heigt) - currentPage * pageHeight;
                    newMargin = emlem_heigt - overFlow + 150;
                    currentPage++;
                    element.style.marginTop = newMargin+"px";
                    element.style.paddingTop= 150 + "px"
                }
                
                currentHeight = currentHeight + emlem_heigt + newMargin + 36;
            });
        }
    }, [news])

    const fetchNews = () => {
        apiAgent({
            method: "get",
            url: `/pdf_generation/team_news/${current_team.id}`,
            params: {},
        }).then(
            (res) => {
                let new_data = res.data.data
                setNews(new_data);
            },
            (error) => {
            }
        );
    }

    return (
        <>
            <img src={PDFNewsHeader} style={{width: '100%', position: 'fixed', top: 0, left: 0}}></img>
            <div style={{margin: '120px 24px 0px'}}>
                <div className="text_title_big" style={{fontSize: "36px"}}>
                    {current_team.name}
                </div>
                <div className="text_title_big" style={{fontWeight: 400, marginTop: "24px"}}>
                    Prospects in News
                </div>
                <div className='news_container' style={{marginTop: 16, padding: 24}}>
                    <div className='default_flex_container' style={{marginBottom: "16px"}}>
                        <img style={{width: "24px"}} src={SpreadsheetIcon}></img>
                        <div className='text_title' style={{paddingLeft: 12}}>Analytics</div>
                    </div>
                    <div className='default_flex_container' style={{gap: "72px"}}>
                        {/* <div className='text_body_light'>
                            <div className='text_title' style={{fontSize: "64px", lineHeight: "88px"}}>{current_team.prospects_count || 0}</div>
                            <div style={{fontSize: "36px", marginTop: 8, lineHeight: "50px"}}>
                                Prospects tracked
                            </div>
                            <div style={{fontSize: "28px", fontStyle: "italic", fontWeight: 400, lineHeight: "40px"}}>
                                (daily)
                            </div>
                        </div> */}
                        <div className='text_body_light'>
                            <div className='text_title_big'>2Mn</div>
                            <div className="text_body_big_light" style={{marginTop: 8}}>
                                Total news articles tracked
                            </div>
                            <div className="text_body_big_light">
                                (daily)
                            </div>
                        </div>
                        <div className='text_body_light'>
                            <div className='text_title_big'>500k</div>
                            <div className="text_body_big_light" style={{marginTop: 8}}>
                                Total News sources tracked
                            </div>
                            <div className="text_body_big_light">
                                (daily)
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text_title_big" style={{marginTop: 24}}>News Results</div>
                <div ref={parentRef} style={{width: '800px'}}>
                    {news && news.map((item, index) => (
                            <div style={{marginBottom: 36}}>
                                <Post key={item.id} item={item} simpleView="true" windowDimensions={windowDimensions} checkUserAccess={checkUserAccess}/>
                            </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default TeamNewsPDFGeneration;