import React, { useEffect, useReducer } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { TextHighlighter } from '../../utils/textHighlighter'
import { Button } from '../../cta_utils/AnButton'
import ShareIcon from "../../../assets/share_on_links/share.png"
import ConditionalRender from '../../../ConditionalRender'
import ShareViaEmail from './_ShareViaEmail'
import { FlashMessages } from '../../cta_utils/FlashMessages'
import generateGPTResponse from '../../utils/openAiUtil'
import { MENTION_TYPES, mention_types } from '../../utils/AllMentionTypes'

const prompt_text = "Which one of these below 11 comma seperated categories, does the text above fit in strictly ? Comment (Expert / Leadership), Achievement / Highlighting, Financial Insight of stockholding, Company achievement, Award / Recognition, Event Participation, Philanthropy, Job Updates, Personal News, Obituary, Negative News.";

const KwArticleContent = ({
    item,
    containerClass="article_content_container",
    ...props
}) => {
    
    const [componentState, componentReducer] = useReducer(reducer, {
        gptEmotionTags: [],
        popupsList: {},
        successMessage: ""
    })
    
    const setState = (payload) => {
        componentReducer({
            type: "SET_STATE",
            payload: payload
        })
    }

    useEffect(() => {
        if(process.env.NODE_ENV === 'production'){
            generateGPTResponse(`${item.shared_link.full_description}. ${prompt_text}`).then(resp => {
                let gptEmotionTags = resp?.choices?.[0]?.text?.replaceAll(" ","").toLowerCase();
                gptEmotionTags = MENTION_TYPES[MENTION_TYPES.map((mt, index) => ({tag: mt.value.replaceAll(" ","").toLowerCase(), index})).filter(mt => gptEmotionTags.includes(mt.tag))[0]?.index]?.value;
                if(gptEmotionTags) setState({gptEmotionTags: [gptEmotionTags]});
            }).catch(err => console.log(err, "xxxxx"))
        }
    }, [])

    let gptEmotionTags = componentState.gptEmotionTags || [];
    let item_tags = [...item.shared_link.all_of_these, ...item.shared_link.any_of_these, ...item.shared_link.none_of_these]
    
    return (
    <div className='kw_article_container'>
        <a href={item.shared_link.url}
            className={containerClass}
            target="_blank"
        >
            {item.shared_link.image_url &&
                <img
                    src={item.shared_link.image_url}
                    className="post_image"
                    alt="article"
                />
            }
            <div style={{wordBreak: 'break-word', flex: 1}}>
                <TextHighlighter
                    contentString={
                        item.shared_link.title
                    }
                    toMatch={
                        item.tagged_prospects?.[0]?.name
                    }
                    typography={{ className: "text_subtitle_big" }}
                    style={{ marginBottom: 4 }}
                />
                <TextHighlighter
                    contentString={
                        item.shared_link.description
                    }
                    toMatch={
                        item.tagged_prospects?.[0]?.name
                    }
                />
                <div
                    className="text_caption_light"
                    style={{ marginTop: "8px" }}
                >
                    {[item.shared_link.source, item.shared_link.published_at].filter(v => v).join(" | ")}
                </div>
            </div>
        </a>
        <div className='default_flex_container' style={{marginTop: 12, gap: 6, flexWrap: "wrap"}}>
            <ConditionalRender if={gptEmotionTags.length > 0}>
                <div className='tag_pill text_caption' style={{border: "1px solid rgba(67, 126, 254, 0.20)", backgroundColor: "rgba(67, 126, 254, 0.20)", fontWeight: "bold"}}>{gptEmotionTags[0]}</div>
            </ConditionalRender>
            {item_tags?.map(tag => (<div key={tag} className='tag_pill text_caption'>{tag}</div>))}
        </div>
        <div className="line_seperator_ex_light_grey"  style={{margin: "8px auto 16px"}}></div>
        <div className='flex_container_space_between'>
            <Button type="Secondary" disabled="true" innerButtonStye={{padding: "8px 16px", height: "unset"}}>
                Comment
            </Button>
            <Button
                type="Secondary"
                buttonSize="LessPadding"
                onClick={() => setState({popupsList: {shareViaEmail: true}})}
            >
                <span style={{display: 'flex', textTransform: "none"}}>
                    <img src={ShareIcon} style={{marginRight: "8px"}} alt="Share"></img>
                    Share via Email
                </span>
            </Button>
        </div>
        <ConditionalRender if={componentState.popupsList.shareViaEmail}>
            <ShareViaEmail item={item.shared_link} search_tags={item_tags} parentReducer={setState}></ShareViaEmail>
        </ConditionalRender>
        {componentState.successMessage && <FlashMessages messageTheme='success' firstIcon={true}
            closeCallback={() => setState({ successMessage: ""})}>{componentState.successMessage}
            </FlashMessages>}
    </div>
  )
}
const reducer = (state, action) => {
    switch(action.type){
      case "SET_STATE": {
        return {
          ...state,
          ...action.payload
        }
      }
      default: return state
    }
  }
  
export default KwArticleContent