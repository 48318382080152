import { Button } from "../../cta_utils/AnButton.js";
import React, { useState } from 'react'
import { AnInputBox } from '../../input_utils/AnInput'
import { feedFetcher } from './apiHitter'
import { indexOfObject } from "../../utils/ArrayUtils";
import Pagination from "../../../Pagination.js";
import { LoaderComponent } from "../../utils/LoaderComponent.js";
import ArticleContent from "../../feed_components/ArticleContent.jsx";

const SearchViaKeywords = () => {
    const [articles, setArticles] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [isFetching, setIsFetching] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);

    const [queries, setQueries] = useState([""])
    const [conjuction, setConjuction] = useState(false)
    const fetchFeed = (page) => {        
        let res_q = queries.join(` ${conjuction ? 'AND' : 'OR'} `);        
        setIsFetching(true);
        feedFetcher(res_q).then( res => {
            setArticles(res.data.results);
            setTotalPages(res.data.total_pages);
            setTotal(res.data.total);
            setIsFetching(false);
            setCurrentPage(page);
    }).catch(
        )
    }
    const renderArticles = () => {
        return (
            <div>
                {articles && articles.map(article => (
                    <div className="article_container" style={{position: 'relative'}}>
                        <ArticleContent key={article.id}
                            item={{shared_link: article}}
                            containerClass="default_flex_container news_container "
                            style={{marginBottom: "16px", alignItems: "flex-start"}}
                        />                        
                    </div>
                ))}
            </div>
        );
    }
    const handleOnChange = (event, i) => {
        let q = event.target.value;
        let temp = [...queries]
        if(!event.target.value){
            temp.splice(i, 1);
        }else{
            temp[i] = q;
        }
        setQueries(temp);
    }
    return (
        <div> 
            <div  className="line_seperator_ex_light_grey"  style={{ margin: "24px auto" }}></div>
            <div className='text_title'>
              Search
            </div>
            {
                queries.map((que,index) => (
                    <div key={index} className="default_flex_container">
                        <AnInputBox key={index}>
                            <input type="text" value={que}
                                onChange={(e) => handleOnChange(e, index)}
                                placeholder="Search"
                            />
                        </AnInputBox>
                    </div>
                ))
            }
            
            <div className="text_link" style={{cursor: 'pointer'}}
                onClick={() => {setQueries([...queries, ''])}}>
                +Add
            </div>
            <div style={{display: 'flex', margin: '16px 0px'}}>
                <div className={conjuction ? 'text_link box_border_highlight' : 'text_body'} style={{cursor: 'pointer', padding: '8px 16px'}} onClick={() => {setConjuction(true)}}>AND</div>
                <div className={!conjuction ? 'text_link box_border_highlight' : 'text_body'} style={{cursor: 'pointer', padding: '8px 16px'}} onClick={() => {setConjuction(false)}}>OR</div>
            </div>
            <Button onClick={() => fetchFeed(1)}>
                Search
            </Button>    
            <div  className="line_seperator_ex_light_grey"  style={{ margin: "24px auto" }}></div>

            <div className="aggregate_container"  style={{maxWidth: "880px", margin: "auto"}}>
                <div className="text_title_big" style={{margin: "10px 0px", textAlign: "center"}}>Result Articles</div>
                <div style={{flex: 1}}>
                    <Pagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        onPageChange={fetchFeed}
                    />
                </div>
                {
                !isFetching ?
                    renderArticles() :
                    (<LoaderComponent isLoading={true}  loaderStyle={{width: 32, margin: '36px auto', display: 'block'}}/>)

                }
            </div>
            </div>
    )
}

export default SearchViaKeywords