import { useSelector } from 'react-redux';
import {Route} from 'react-router-dom'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import useCheckUserAccess from '../components/custom_hooks/useCheckUserAccess';
import ComponentErrorBoundary from "../BugsnagErrorReporting.jsx";

const ALL_LAYOUTS = ["default_layout", "feed_layout"];

const RouteRenderer = (route) => {
  const current_user = useSelector(state => state.current_user)
  const current_team = useSelector(state => state.current_team)
  const checkUserAccess = useCheckUserAccess() 
  const PROTECTION_LEVELS = {
    'LOGGED_OUT': current_user.apiKeyStatus != 'validated', 
    'LOGGED_IN': current_user.apiKeyStatus == 'validated',
    'HAS_NOT_SELECTED_TEAM' : !current_team.team?.id,
    'HAS_SELECTED_TEAM': current_team.team?.id,
    'IS_INSTITUTE_ADMIN': current_user.user?.is_super_admin || current_team.team?.is_admin,
    'IS_ADMIN': current_user.user?.is_admin,
    'IS_SUPER_ADMIN': current_user.user?.is_super_admin,
    'IS_PUBLISHER': current_user.user?.is_publisher
  }
  let shouldRender = true;
  (route.protection || []).forEach( protection_level => shouldRender = shouldRender && PROTECTION_LEVELS[protection_level]);
  if(route.checkAuthorization){
    shouldRender = shouldRender && checkUserAccess(route.checkAuthorization)
  }
  
  if(shouldRender) {
    return <ComponentErrorBoundary>
      <Route
      path={route.path}
      exact={route.exact}      
      render={(props) => (
        <div className={route.layout || "default_layout"}>
          <route.component {...props} {...route.props} routes={route.routes} />
        </div>
      )}
      />
    </ComponentErrorBoundary>
  }
  return <Redirect from={route.path} to="/"></Redirect>;
}
export default RouteRenderer
