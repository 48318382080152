import { useEffect, useMemo, useReducer } from "react";

import { TabbedButton } from "../../cta_utils/AnButton";
import reducer from "../../utils/CommonReducer";
import PublishJobUpdates from "./PublishJobUpdates";
import AlgorithmSuggestion from "./_AlgorithmSuggestion";
import MappedSuggestion from "./_MappedSuggestion";
import UnmappedSuggestion from "./_UnmappedSuggestions";
import { IsCurrentPath } from "../../utils/locationPath";

const mappingTypes = [{label: 'Job Updates', key: 'job_updates'}, {label: 'Algorithm Suggestions', key: 'algo_sugg'}, {label: "Mapped", key: 'mapped'}, {label: 'Unmapped', key: 'unmapped'}];

const NewMapLinkedinProspectsPage = () => {
    let fromDirectory = IsCurrentPath('/mapper-suggestions')
    const [pageData, setPageData] = useReducer(reducer, {
        mappingType: fromDirectory ? "algo_sugg" : "job_updates",
        mappedTotalCount: null,
        unMappedTotalCount: null,     
    })    
    const setPageState = (payload) => {
        setPageData({type: "SET_STATE", payload})
    }
    const {mappingType, mappedTotalCount, unMappedTotalCount} = pageData;
    return (
        <>
            <div>
                <div style={{padding: '52px 32px 0px'}}>
                <div className="text_title_big">Mapper Suggestions</div>
                <div className="default_flex_container" style={{  margin: '16px 0px', width: "max-content"}}>
                    {
                        mappingTypes.map(type => {
                            let is_active = mappingType === type.key
                            if(type.key === "job_updates" && fromDirectory) return;
                            return(
                                <div key={type.key} className={mappingType === type.key ? 'text_link box_border_highlight' : 'text_subtitle_light_grey_semi_bold'} style={{cursor: 'pointer', fontSize: 16, padding: '8px 16px'}} onClick={() => setPageState({mappingType: type.key})}>
                                    {type.label} {(type.key === 'mapped' && mappedTotalCount ) ? `(${mappedTotalCount})` : (type.key === 'unmapped' && unMappedTotalCount) ? `(${unMappedTotalCount})` : ''}
                                </div>

                            // <div key={type.key}>
                            //     <TabbedButton buttonStyle={{padding: '0px 0px 10px', borderBottom: "none"}} isActive={is_active} onClick={() => setPageState({mappingType: type.key})}>
                            //         {type.label} {(type.key === 'mapped' && mappedTotalCount ) ? `(${mappedTotalCount})` : (type.key === 'unmapped' && unMappedTotalCount) ? `(${unMappedTotalCount})` : ''}
                            //     </TabbedButton>
                            // </div>
                        )})
                    }
                </div>
                </div>
                {mappingType === "job_updates" && <PublishJobUpdates />}
                {mappingType === 'algo_sugg' && <AlgorithmSuggestion setParentState={setPageState}/>}
                {mappingType === 'mapped' && <MappedSuggestion setParentState={setPageState}/>}
                {mappingType === 'unmapped' && <UnmappedSuggestion setParentState={setPageState}/>}
            </div>
        </>
    )
}

export default NewMapLinkedinProspectsPage