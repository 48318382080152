import React, { useCallback, useState } from "react";
import { MentionsInput, Mention } from "react-mentions";
import { debounce } from "lodash";
import { apiAgent } from "../../axiosConfig";
import { NameInitial } from "../cta_utils/AnNameInitial";

const DEFAULTMENTIONSTYLE = {
    backgroundColor: "#aeb4e5",
};
const DEFAULTSTYLE = {
    suggestions: {
        marginTop: "10px",
        loadingIndicator: {
            spinner:{      
              "@global":{
                "@keyframes element1":{
                  "0%":{
                    opacity: 0.33,
                  },
                  "33%":{
                    opacity: 1,
                  },
                  "66%":{
                    opacity: 0.66
                  },
                  "100%":{
                    opacity: 0.33
                  }
                },
                "@keyframes element2":{
                  "0%":{
                    opacity: 0.66,
                  },
                  "33%":{
                    opacity: 0.33,
                  },
                  "66%":{
                    opacity: 0.1
                  },
                  "100%":{
                    opacity: 0.66
                  }
                },
                "@keyframes element3":{
                  "0%":{
                    opacity: 1,
                  },
                  "33%":{
                    opacity: 0.66,
                  },
                  "66%":{
                    opacity: 0.33
                  },
                  "100%":{
                    opacity: 1
                  }
                }
            },      
                element: {
                    height: "4px",
                    width: "4px",                
                },
                element1: {
                  float: "left",
                  background: "#00c4b5",
                  animationName: "$element1",
                  animationIterationCount: "infinite",
                  animationDuration: "1s",
                },
                element2: {
                  float: "left",
                  marginLeft: "6px",
                  background: "#00c4b5",
                  animationName: "$element2",
                  animationIterationCount: "infinite",
                  animationDuration: "1s",
                },
                element3: {
                  float: "left",
                  marginLeft: "6px",
                  background: "#00c4b5",
                  animationName: "$element3",
                  animationIterationCount: "infinite",
                  animationDuration: "1s",
                },
                element4: {
                },
                element5: {
                }
            }
        }
    },
};


export const TaggableMentions = ({
    mentionTriggers=[{
        symbl: "@",
        suggestionUrl: "/suggestions/team_members",
    }],...props

}) => {
    const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);//useState("@[Jef Bezos](61a4869e979d500008eb7541)");
    const getQueryFunction = useCallback((suggestionUrl) => {
        return debounce((query, callback) => {
            setIsLoadingSuggestions(true)
            apiAgent({
                method: "get",
                url: suggestionUrl,
                params: { query },
            }).then((res) => {
                let formatted = res.data.data.map((prospect) => ({
                    id: prospect.id,
                    display: prospect.name,
                }));
                callback(formatted);
                setIsLoadingSuggestions(false)
            }).catch(err => setIsLoadingSuggestions(false));
        }, 300)
    }, [])

    const renderSuggestion = useCallback((entry, search, highlightedDisplay, index, focus) => {
        return (
            <div key={entry.id} className="default_flex_container" style={{padding: 16}}>
                <NameInitial name={entry.display} width={40} textStyle={{fontSize: 14}}></NameInitial>
                <div className="text_subtitle" style={{marginLeft: 6}}>{entry.display}</div>
            </div>
        )
    }, [])

    return (
        <>
            <div style={props.style}>
                <MentionsInput value={props.value} onChange={ props.onChange }
                        className="mentions"
                        disabled={props.disabled}
                        singleLine={true}
                        placeholder={props.placeholder}  
                        style={DEFAULTSTYLE}             
                        >
                    {
                        mentionTriggers.map( mention => (
                            <Mention key={mention.symbl} trigger={mention.symbl} data={getQueryFunction(mention.suggestionUrl)}                            
                                className="mentions__mention"
                                renderSuggestion={renderSuggestion}
                                // isLoading={true} TODO: raise a PR to library with fix for loading issues
                            >
                            </Mention>
                        ))
                    }
                </MentionsInput>
            </div>
        </>    
    );
};

const triggersToRegexer = (triggers) =>  new RegExp(`[${triggers.join('|')}]\\[([\\s\\w]+)\\]\\((\\w+)\\)`, 'g')

export const CommentsDisplay = (
    {
        markupedText,
        typographyClass,
        triggers=["@"],
        ...props
    }) => {    
    markupedText = markupedText || ""
    typographyClass = typographyClass || "text_body_light"
    let matcher = triggersToRegexer(triggers)
    let parsedMentions = []
    let singleMatch = null;
    while((singleMatch = matcher.exec(markupedText)) != null) parsedMentions.push(singleMatch)    
    let currentIndex = 0;
    if (parsedMentions.length == 0) return <div className={typographyClass}>{markupedText}</div>
    return(
        <div className={typographyClass}>
            {
                parsedMentions.map((singleMention, index) => {
                    let plainText = markupedText.substring(currentIndex, singleMention.index)
                    currentIndex = singleMention.index + singleMention[0].length
                    return(
                        <React.Fragment key={index}>
                            <span>
                                {plainText}
                            </span>
                            <span href={`/profile/${singleMention[2]}`} className="text_subtitle" style={{cursor: 'pointer'}} target="_blank">{singleMention[1]}</span>
                        </React.Fragment>
                    )
                })
            }
            <span>{markupedText.substring(currentIndex, markupedText.length)}</span>
        </div>
    )
}
