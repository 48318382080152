import React from 'react'
import { Button } from '../../cta_utils/AnButton'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const PipeDrivePermissions = () => {
    const history = useHistory();
    return (
    <div style={{maxWidth: "530px", margin: "120px auto"}}>
        <div className='text_headline3' style={{textAlign: "center"}}>
            Allow AlmaConnect News to access your Pipedrive account?
        </div>
        <div className='default_flex_container' style={{justifyContent: "center", gap: "16px", marginTop: "32px"}}>
            <a className="an_primary_button big_btn no_decoration" target="_blank" style={{padding: "8px 20px"}} href="https://oauth.pipedrive.com/oauth/authorize?client_id=4a09b358c00328d5&redirect_uri=https%3A%2F%2Fnews.almaconnect.com%2Fpipedrive-installation">Allow and Continue</a>            
            <Button buttonSize='Big' type='Secondary' innerButtonStye={{padding: "8px 20px"}} onClick={() => history.push("/connect_teams")}>Cancel</Button>
        </div>    
    </div>
  )
}

export default PipeDrivePermissions
