import { apiAgent } from "../../../axiosConfig";

export const compainesFetcher = (params) => apiAgent({
    method: "get",
    url: "/companies",
    params: params
})

export const companyEditter = (params) => apiAgent({
    method: "post",
    url: "/companies/update",
    data: params
})