import BlueTickWhite from "../../../assets/outbound/Logos/blue_tick_whitebg.png"
import Tick from "../../../assets/outbound/Logos/tick_black.png"
import BlueTick from "../../../assets/outbound/Logos/bluetick.png"
const NewCompareBox = () => {
    const FeaturesTable = () => {
        return (
          <table className="feature_table">
                <tbody>
                    <tr style={{height: '1px'}}>
                        <td className="text_title" style={{textAlign: 'unset', paddingTop: 24}}><div className="feature_title" style={{marginTop: 0}}>Overview
                        <div className="premium_line_separate_dark"></div>
                        </div>
                        </td>
                        <td style={{height: 'inherit'}}><div className="premium_tick_column get_premium_tick_column"></div></td>
                        <td style={{height: 'inherit'}}><div className="premium_tick_column get_premium_tick_column premium_box"></div></td>
                    </tr>
                    {renderFeatureRow("Daily News alerts", false, true, "Up to 3 results")}
                    {renderFeatureRow("Unlimited Team members", true, true)}
                    {renderFeatureRow("Uses public information to fetch news results", true, true)}
                    {renderFeatureRow("Unlimited prospects tracked", false, true)}
                    {renderFeatureRow("Personalized Email digest", false, true)}
                    {renderFeatureRow("View Similar articles", false, true)}
                    {renderFeatureRow("Search News Sources", false, true)}
                    {renderFeatureRow("Platform Accessibility", false, true)}
                    <tr style={{height: '1px'}}>
                        <td className="text_title"><div className="feature_title">NewsFeed
                        <div className="premium_line_separate_dark"></div>
                        </div>
                        </td>
                        <td style={{height: 'inherit'}}><div className="premium_tick_column get_premium_tick_column"></div></td>
                        <td style={{height: 'inherit'}}><div className="premium_tick_column get_premium_tick_column premium_box"></div></td>
                    </tr>
                    {renderFeatureRow("Personalized feed", false, true)}
                    {renderFeatureRow("Tags & Filters", false, true)}
                    {renderFeatureRow("Search News results", false, true)}
                    {renderFeatureRow("Share via Email", false, true)}
                    {renderFeatureRow("Comments", false, true)}
                    <tr style={{height: '1px'}}>
                        <td className="text_title"><div className="feature_title">Prospect Management
                        <div className="premium_line_separate_dark"></div>
                        </div>
                        </td>
                        <td style={{height: 'inherit'}}><div className="premium_tick_column get_premium_tick_column"></div></td>
                        <td style={{height: 'inherit'}}><div className="premium_tick_column get_premium_tick_column premium_box"></div></td>
                    </tr>
                    {renderFeatureRow("Import prospects in bulk", false, true)}
                    {renderFeatureRow("Add Tags", false, true)}
                    {renderFeatureRow("Assign Team members", false, true)}
                    {renderFeatureRow("Prospect Profile", false, true)}
                    {renderFeatureRow("Archive Prospects", false, true)}
                    {renderFeatureRow("Data Export", false, true)}
                    <tr style={{height: '1px'}}>
                        <td className="text_title"><div className="feature_title">Team Management
                        <div className="premium_line_separate_dark"></div>
                        </div>
                        </td>
                        <td style={{height: 'inherit'}}><div className="premium_tick_column get_premium_tick_column"></div></td>
                        <td style={{height: 'inherit'}}><div className="premium_tick_column get_premium_tick_column premium_box"></div></td>
                    </tr>
                    {renderFeatureRow("Invite Team members", false, true)}
                    {renderFeatureRow("Manage Team Notifications", false, true)}
                    {renderFeatureRow("Custom Fields", false, true)}
                    {renderFeatureRow("Team Settings", false, true)}
                    <tr style={{height: '1px'}}>
                        <td className="text_title"><div className="feature_title">Support
                        <div className="premium_line_separate_dark"></div>
                        </div>
                        </td>
                        <td style={{height: 'inherit'}}><div className="premium_tick_column get_premium_tick_column"></div></td>
                        <td style={{height: 'inherit'}}><div className="premium_tick_column get_premium_tick_column premium_box"></div></td>
                    </tr>
                    {renderFeatureRow("Onboarding and support", false, true)}
                    {renderFeatureRow("Data Deletion", false, true)}
                    {renderFeatureRow("Dedicated Account Manager", false, true)}
                    {/* ... Other sections */}
                </tbody>
          </table>
        );
      };
      
      const renderFeatureRow = (feature, freeTick, premiumTick, freeTickText = "") => (
        <tr key={feature} style={{height: '1px'}}>
            <td><div className="text_body_big_light" style={{ textAlign: "left" }}>{feature}</div></td>
            <td style={{height: 'inherit'}}><div className="text_body_big_light premium_tick_column get_premium_tick_column free_last_border">{freeTickText ? <div>{freeTickText}</div> : freeTick && <img src={Tick} style={{ maxWidth: 18 }} alt="Tick" />}</div></td>
            <td style={{height: 'inherit'}}><div className="text_body_big_light premium_tick_column get_premium_tick_column premium_box premium_last_border">{premiumTick && <img src={BlueTick} style={{ maxWidth: 20 }} alt="BlueTick" />}</div></td>
        </tr>
    );
    return (
        <div>
        <div style={{maxWidth: 1000, margin: 'auto'}}>
        <div className="plans_container" style={{ border: '1px solid #E7ECEB', borderTop: 'none', margin: '-1px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px'}}>
        <section className='section_1' style={{ borderRadius: 'inherit'}}>
            <div className='content_container' style={{padding: 0, borderRadius: 'inherit'}}>
                
            <div className="compare_box premium_compare_box premium_table_container">
                <div label="Compare features across our Free and Premium plans">
                {FeaturesTable()}
                </div>
            </div>

            </div>
        </section>
        </div>
        </div>
        </div>
    )
}
export default NewCompareBox