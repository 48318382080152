import React from 'react'

import {apiAgent} from './axiosConfig';
import Pagination from './Pagination';
import FileUpload from './FileUpload';
import { AnInputBox } from './components/input_utils/AnInput';
import TableHeads from "./components/visualizers/tabular/TableHeads"
import TableRows from "./components/visualizers/tabular/TableRows"
import ConditionalRender from './ConditionalRender';
import { LoaderComponent } from "./components/utils/LoaderComponent"

class NewsQueries extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: []
    };
  }

  componentDidMount() {
    this.fetchNewsQueries(1);
  }

  fetchNewsQueries = (page) => {
    this.setState({
      isLoaded: false,
    });
    apiAgent({
      method: 'get',
      url: '/news_queries',
      params: {
        person_name: this.state.person_name,
        company_name: this.state.company_name,
        page: page
      }
    }).then(
      (response) => {
        let result = response.data;
        this.setState({
          isLoaded: true,
          items: result.data,
          totalPages: result.total_pages,
          currentPage: page
        });
      },
      (error)  => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    );
  }

  onPersonNameInput = (event) => {
    if(event.charCode !== 13) return;
    this.setState({
      person_name: event.target.value
    }, () => this.fetchNewsQueries(1))
  }

  onCompanyNameInput = (event) => {
    if(event.charCode !== 13) return;
    this.setState({
      company_name: event.target.value
    }, () => this.fetchNewsQueries(1))
  }

  renderTableContent = () => {
    const {items} = this.state;
    return(
      <>
        <Pagination totalPages={this.state.totalPages}
            currentPage={this.state.currentPage}
            onPageChange={this.fetchNewsQueries}
            style={{padding: "16px 0px 0px"}}
          />
        <table className="data_table" style={{textAlign: "left", marginTop: "24px"}}>
          <TableHeads typoGraphyClass="text_body_big_light" headerClass="table_header_row_seperated">
            <th style={{width: "20%"}}>ID</th>
            <th style={{width: "40%"}}>Person Name</th>
            <th style={{width: "40%"}}>Company Name</th>            
          </TableHeads>
          <tbody>
            {items.map(item => (
              <TableRows key={item.id} rowClass="table_content_row_seperated text_body">
                <td>
                  {item.id}
                </td>
                <td>
                  {item.person_name}
                </td>
                <td>
                  {item.company_name}
                </td>
              </TableRows>
            ))}
          </tbody>
        </table>
      </>
    )
  }

  render(){
    const { error } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } 
    return (
      <div style={{margin: "33px 49px"}}>
        <div className='text_title_big'>News Queries</div>
        <div  className="line_seperator_ex_light_grey" style={{ margin: "16px auto" }}></div>          
        <div className='text_title'>Search</div>
        <div className='default_flex_container'>
          <AnInputBox style={{maxWidth: "364px", width: "100%"}}>
            <input type="text" id="person_name" onKeyPress={this.onPersonNameInput} placeholder="Search Person" />
          </AnInputBox>
          <div style={{margin: "0px 10px"}} className="text_body_light">or</div>
          <AnInputBox style={{maxWidth: "364px", width: "100%"}}>
            <input type="text" id="company_name" onKeyPress={this.onCompanyNameInput} placeholder="Search Company"/>
          </AnInputBox>
        </div>
        <ConditionalRender if={this.state.isLoaded && this.state.items.length > 0}>
          {this.renderTableContent()}
        </ConditionalRender>
        <ConditionalRender if={!this.state.isLoaded}>
          <LoaderComponent isLoading={true}></LoaderComponent>
        </ConditionalRender>          
      </div>
    );
  }
}

export default NewsQueries;
