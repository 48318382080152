import { trusted_by_the_best } from "../../landing_pages/clients_assets";
const Clients = () => {

    return (
        <div className="plans_container">
            <section className='section_2'>
            <div className='content_container'>
                <div className='who_uses'>
                    <div className='text_title_big' style={{textAlign: 'center', marginBottom: 32}}>
                        Trusted by the world’s best
                    </div>
                    <div className="trusted_grid_layout">
                        <div className="trusted_grid">
                            {Object.keys(trusted_by_the_best).map((key, index) => (
                                <span className="grid_section">
                                    <img
                                        key={key}
                                        src={trusted_by_the_best[key].asset}
                                        style={trusted_by_the_best[key].style}
                                    ></img>
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </div>
    )
}
export default Clients