import React, {useEffect, useState} from "react";
import { apiAgent } from '../../../../axiosConfig';
import {LoaderComponent} from "../../../utils/LoaderComponent";
import {Link} from "react-router-dom";
import {FlashMessages} from "../../../cta_utils/FlashMessages";
import suitCase from "../../../../assets/Suitcase.svg"
import GreyDownload from "../../../../assets/datamine/GreyDownload.svg"
import { AnConfirmPopup } from "../../../cta_utils/AnConfirmPopup";
import GoBack from "../../../cta_utils/GoBack";
import ConditionalRender from "../../../../ConditionalRender";
import useCheckUserAccess from "../../../custom_hooks/useCheckUserAccess";
import { useSelector } from "react-redux";
import MenuDots from '../../../../assets/MenuDots.svg'
import getApiUrl from "../../../utils/UrlHelper";
import AnDropDown from "../../../cta_utils/AnDropDown";
import ListSettings from "./ListSettings";
import { indexOfObject } from "../../../utils/ArrayUtils";
const DataMineList = () =>{
    const [lists, setLists] = useState([]);
    const [parentLists, setParentLists] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [popupsList, setPopupsList] = useState({});
    const [dropdownList, setDropDownList] = useState({});
    const [currList, setCurrList] = useState({id: "", title: ""})
    const checkUserAccess = useCheckUserAccess();
    const current_team = useSelector(state => state.current_team.team);
    const current_user = useSelector(state => state.current_user.user);
    useEffect(() => {
        setIsFetching(true);
        apiAgent({
            method: "get",
            url: '/linkedin_prospect/lists',
            params: {
            },
        }).then(
            (res) => {
                let list_data = res.data.data;
                let parent_lists = list_data.filter((list)=>list.is_parent_list)
                list_data = list_data.filter((list) => !list.is_parent_list)
                setLists(list_data);
                setParentLists(parent_lists)
                setIsFetching(false);
            },
            (error) => {
                setIsFetching(false);
            }
        );
    }, []);

    const handleClosePopup = () => {setPopupsList({});setCurrList({});setDropDownList({})};

    const download_list = () => {
        apiAgent({
            method: "post",
            url: "/linkedin_prospect/export_linkedin_list",
            data: {
                id: currList.id
            },
        }).then(
            (res) => {
                setSuccessMessage("list will be downloaded soon.")
                handleClosePopup()
            },
            (error) => {
            }
        );
    }
    const addProspectsFromListDropdown = () => {
        return (
            <AnDropDown style={{ position: 'absolute', right: 0, padding: '16px 12px', background: 'white', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', borderRadius: 4, width: 'max-content', zIndex: 99 }}
                toggleDropDown={handleClosePopup}>
                <ConditionalRender if={checkUserAccess('list_settings')}>
                    <div className="an_dropdown_filter text_body_light" onClick={() => setPopupsList({ listSettingsPopup: true })}>List Settings</div>
                </ConditionalRender>  
                <ConditionalRender if={checkUserAccess('create_prospects_from_list') && currList.team_type !== 'Premium'}>
                    <div className="an_dropdown_filter text_body_light" onClick={() => setPopupsList({ addProspectsconfirmPopup: true })}>Import Prospects</div>
                </ConditionalRender>

            </AnDropDown>
        )
    }
    const addProspectsFromList = () => {
        apiAgent({
            method: "post",
            url: getApiUrl("create_prospects_from_list", {":id": currList.id}),
            data: {},
        }).then(
            (res) => {
                setSuccessMessage('Request received. List members will be added as prospects soon')
                handleClosePopup()
            },
            (error) => {
            }
        );
    }
    const onSubmitCallback = () => {
        let tempChildList = [...lists]
        let childIndex = indexOfObject(tempChildList, "id", currList.id);
        if(childIndex !== -1)
        {
            tempChildList[childIndex] = {...currList}
            setLists(tempChildList)
        }
        let tempParentLists = [...parentLists]
        let parentIndex = indexOfObject(tempParentLists, "id", currList.id);
        if(parentIndex !== -1)
        {
            tempParentLists[parentIndex] = {...currList}
            setParentLists(tempParentLists)
        }
        handleClosePopup();
        setSuccessMessage("List updated")
    }
    const renderList = (list) => {
        let redirect_url = "/team/lists/" + list.id
        if(list.is_master_list) redirect_url = "/directory"
        return (
            <>
                <div className="list_item" style={{position: 'relative', padding: '24px 18px'}}>
                    <ConditionalRender if={list.is_master_list}>
                        <span className='master_label'>
                            <span className='text_caption' style={{color: '#437EFE'}}>Master</span>
                        </span>
                    </ConditionalRender>
                    <div className="text_body_big_bold truncate" style={{lineHeight: '20px'}}>{list.title} </div>
                    <div className="text_body_light">{list.ln_prospects_count} people</div>
                    <div className="text_caption_light">created on {list.created_at}</div>
                    <div className="default_flex_container" style={{ marginTop: 16}}>
                        <img src={suitCase} style={{marginRight: 5}}></img>
                        <div className="text_body_light">{list.formatted_recent_job_changed_count} people changed their jobs</div>
                    </div>
                    <ConditionalRender if={checkUserAccess('display_list_settings')}> 
                    <div className="line_seperator_ex_light_grey"  style={{ margin: "16px 0px 12px" }}></div>
                    <div className="text_caption" style={{lineHeight: '20px'}}>Auto prospect creation - <span style={{color: '#282A2C'}}>{list.auto_prospects_creation ? 'Enabled' : 'Disabled'}</span></div>
                    <div className="text_caption" style={{lineHeight: '20px', margin: '2px auto'}}>Auto Sync - <span style={{color: '#282A2C'}}>{list.auto_sync ? 'Enabled' : 'Disabled'}</span></div>
                    <div className="text_caption" style={{lineHeight: '20px'}}>Sync frequency - <span style={{color: '#282A2C'}}>{list.sync_frequency} days</span></div>
                    </ConditionalRender>
                    <div  className="line_seperator_ex_light_grey"  style={{ margin: "12px 0px 12px" }}></div>
                    <div className="flex_container_space_between">
                        <Link to={redirect_url} className="text_subtitle_light">Go to list</Link>
                        <div className="default_flex_container">
                        <ConditionalRender if={checkUserAccess('list_download') || (current_user.teams.is_admin && current_team.displayable_features.display_directory_download)}>
                        <div style={{cursor: 'pointer'}} onClick={() => {setCurrList(list);setPopupsList({exportPopup: true})}}><img src={GreyDownload}></img></div>
                        </ConditionalRender>
                        <ConditionalRender if={checkUserAccess('create_prospects_from_list') || checkUserAccess('list_settings')}>
                        <div onClick={() => {setCurrList(list);setDropDownList({addProspectsFromListDropdown: true})}} 
                            style={{ position: 'relative', cursor: "pointer"}}>
                            <img src={MenuDots} style={{height: 24}}></img>
                            {list.id === currList.id && dropdownList.addProspectsFromListDropdown && addProspectsFromListDropdown()}
                        </div>
                        </ConditionalRender>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    const renderEmptyList = () => {
        return (
            <div className="text_body_light" style={{fontSize: '20px', marginTop: 113, textAlign: 'center'}}>
                No lists created yet
            </div>
        )
    }

    const renderLists = () => {
        return (
            <div>
                <ConditionalRender if={parentLists.length > 0}>
                    <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '12px'}}>
                        {parentLists && parentLists.map(list => renderList(list))}
                    </div>
                    <div  className="line_seperator_ex_light_grey"  style={{height: '2px', margin: "32px 0px" }}></div>
                </ConditionalRender>
                <ConditionalRender if={lists.length > 0}>
                <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '12px'}}>
                    {lists && lists.map(list => renderList(list))}
                </div>
                </ConditionalRender>
                <ConditionalRender if={lists.length === 0 && parentLists.length === 0}>
                    {renderEmptyList()}
                </ConditionalRender>
            </div>
        );
    }

    return (
        <div style={{background: "#FAFBFC", padding: '44px 0px', minHeight: '100vh'}}>
            <div className="aggregate_container" style={{maxWidth: "880px"}}>
                <div className="default_flex_container">
                <GoBack className="default_flex_container text_link hover_under_line" style={{marginRight: 8, marginBottom: 10, filter: 'grayscale(100%)'}} text="" arrow_color={"green"}/>
                <div className="text_title_big" style={{marginBottom: "10px"}}>Lists</div>
                </div>
                {
                    !isFetching ? renderLists() :
                    (<div style={{height: '100vh'}}>
                        <LoaderComponent isLoading={true}  loaderStyle={{width: 32, margin: '36px auto', display: 'block'}}/>
                    </div>)
                }
            </div>
            {popupsList.exportPopup && <AnConfirmPopup name="List" onSubmitCallback={() => download_list()} onCancelCallback={()=>handleClosePopup()}></AnConfirmPopup>}
            {popupsList.addProspectsconfirmPopup && <AnConfirmPopup 
                confirm_message={`Are you sure you want to add members from ${currList.title} list as prospects?`} 
                btnLabels={{submitLabel: 'Yes', cancelLabel: 'No'}}
                onSubmitCallback={() => addProspectsFromList()} 
                onCancelCallback={()=>handleClosePopup()}
            ></AnConfirmPopup>}
            {popupsList.listSettingsPopup && <ListSettings 
            handleOnClosePopup={handleClosePopup}
            list={currList}
            setList={setCurrList}
            onSubmitCallback={onSubmitCallback}
            >
                </ListSettings>}
            {successMessage && <FlashMessages messageTheme='success' firstIcon={true} closeCallback={setSuccessMessage}>{successMessage}</FlashMessages>}
        </div>
    );
}

export default DataMineList;
