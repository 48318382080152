import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { NameInitial } from "./AnNameInitial";
import ConditionalRender from "../../ConditionalRender";
import { LoaderComponent } from "../utils/LoaderComponent";
import { apiAgent } from "../../axiosConfig";
import { Link } from "react-router-dom/cjs/react-router-dom";
const initialshortProfileData = {
    prospectFields: [],
    isLoading: false
}
const AnProspectProfileCard = ({
    containerClass,
    innerClass,
    innerStyle,
    children,
    prospect,
    ...props
    }) => {
  const current_user = useSelector(state => state.current_user.user)
  const [position, setPosition] = useState(null);
  const [profileData, setProfileData] = useState(initialshortProfileData);
  const containerRef = useRef(null);

  useEffect(() => {
    fetchDetails()
  },[])
  const fetchDetails = useCallback(() => {
    setProfileData({...profileData, isLoading: true});
    apiAgent({
        method: 'get',
        url: '/team/team_details',
        params: {}
    }).then(res => {
        let short_fields = res.data.data.short_profile_fields;
        const convertedArray = Object.keys(short_fields).map((key) => ({
            key: key,
            label: short_fields[key],
        }));
        setProfileData({...profileData, prospectFields: convertedArray, isLoading: false})
    }).catch(err => {
        setProfileData({...profileData, isLoading: false})
        console.log("Error fetching team details");
    });
  }, [])
  const handleMouseOver = (e) => {
    const bounds = e.currentTarget.getBoundingClientRect();
    setPosition({
      x: bounds.x,
      y: bounds.y + bounds.height,
    });
  };

  const handleMouseOut = () => {setPosition(null)};

  const anchorProps = {
    ref: containerRef,
    onMouseOver: handleMouseOver,
    onMouseOut: handleMouseOut,
  };
  if(React.isValidElement(children)) { 
    var anchor = (
        React.cloneElement(children, anchorProps)
      )

    } else {
    anchor = <span {...anchorProps}>{children}</span>
};

  const renderRelationShips = (item) => {
    return (
        <div className="an_short_profile_label" style={{alignItems: "first baseline"}}>
            <div className="text_caption_light hover_card_label" style={{fontWeight: 700, textAlign: "left", textTransform:'uppercase', width: "50%"}}>{item.label}</div>
            <div className="hover_card_label_text" style={{display: "flex", flexDirection: "column"}}>
                { prospect.spouse_relationships && prospect.spouse_relationships.length>0 && <div className="an_shortprofile_box">
                    <div className="text_caption_light" style={{display: 'inline', paddingRight: '6px'}}>Spouse: </div>
                    <div style={{width: 122}}>
                        {prospect.spouse_relationships.map((item,index)=>{
                        return  <Link className="text_link" style={{textDecoration: "none"}} to={"/prospects/"+item.id} target="_blank">{index>=1 && ",  "}{item.name}</Link>
                    })}
                    </div>
                </div>}
                { prospect.other_relationships && prospect.other_relationships.length>0 && 
                <div className="an_shortprofile_box">
                    <div className="text_caption_light" style={{display: 'inline',paddingRight: '14px'}}>Other: </div>
                    <div style={{width: 122}}>
                        {prospect.other_relationships.map((item,index)=>{
                        return <Link className="text_link" style={{textDecoration: "none"}} to={"/prospects/"+item.id} target="_blank">{index>=1 && ",  "}{item.name}</Link>
                    })}
                    </div>
                </div>}
            </div>
        </div>
    )
}

  return (
    <>
      {anchor}
      {position && current_user.is_publisher &&
        ReactDOM.createPortal(
          <div
            className={containerClass}
            style={{
              top: position?.y || 0,
              left: position?.x || 0,
              position: "fixed",
              margin: 4,
              zIndex: 9999,
              ...props.style,
            }}
          >
                    <div className="hover_card">
                        <div className="default_flex_container">
                            <NameInitial
                                name={prospect.name || prospect.company}
                            />                            
                            <div className="feed_prospect">
                                <div className="text_subtitle_big" style={{textAlign: "left"}}>{prospect.name}</div>
                                <ConditionalRender if={prospect.name}>
                                    <div className="text_body_light truncate" style={{maxWidth: 225}}>
                                        {prospect.designation}
                                    </div>
                                </ConditionalRender>
                            </div>
                        </div>
                    {
                        profileData.isLoading? 
                        
                        (<LoaderComponent isLoading={true}  loaderStyle={{width: 32, margin: '36px auto', display: 'block'}}/>)
                        :
                        (<ConditionalRender if={!profileData.isLoading}>
                            {
                            profileData.prospectFields?.length > 0 && 
                            (<div className="text_subtitle_big" style={{textAlign: "left", paddingTop: 20}}>
                                Profile Information
                            </div>)
                            }
    
                            {profileData.prospectFields?.map((item,index) => (
                                (item.key !== "relationships" && prospect[item.key]) && (
                                    <>
                                        <div className="an_short_profile_label">
                                            <div className="text_caption_light hover_card_label">{item.label}</div>
                                            <div className="text_label hover_card_label_text">
                                                <span>{(item.key === "assigns" || item.key === "tags")? prospect[item.key]?.map(assign => assign.name).join(", ") : prospect[item.key]}</span>
                                            </div>
                                        </div>
                                        {(index !== profileData.prospectFields?.length -1) && <div style={{borderBottom: "1px solid #E7ECEB"}}></div>}
                                    </>
                                )
                                ||
                                (item.key === "relationships" && (
                                    renderRelationShips(item)
                                ))
                            ))}
    
                            {prospect.custom_answers?.map(answer => {
                            if (profileData.prospectFields?.map(field => field.label)?.includes(answer.question) && answer.answer != null && answer.answer?.length > 0) {
                                return (
                                    <div className="an_short_profile_label">
                                        <div className="text_caption_light hover_card_label">{answer.question}</div>
                                        <div className="text_label hover_card_label_text">
                                            <span>{answer.answer}</span>
                                        </div>
                                    </div>
                                );
                            } else {
                                return null;
                            }
                            })} 
                        </ConditionalRender>)
                    }
                    </div>

            </div>,
          document.body
        )}
    </>
  );
}

export default AnProspectProfileCard;
