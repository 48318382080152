import React from "react";
export const dataminePrivacyPolicy = () => {
  return (
    <div
      style={{
        padding: '80px 20px 0px 20px',
        maxWidth: 960,
        margin: "0px auto",
        width: "100%",
      }}
    >
      <p style={{ textAlign: "center" }}>
        <span style={{ color: "#282a2c" }}>
          <h1>DataMine Privacy Policy</h1>
        </span>
      </p>
      <div
        className="line_seperator_ex_light_grey"
        style={{ margin: "24px auto" }}
      ></div>
      <h1>About this policy</h1>
      <p>
        At Almanews, we are committed to protecting your privacy. You can be our
        customer without giving us any information about yourself. But sometimes
        we do need information to provide the services that you request. This
        Privacy Policy (“Policy”) provides you with information concerning our
        practices and procedures as they relate specifically to 1) information
        we collect about our customers and 2) the customer's proprietary data
        that we collect, integrate, analyze, deliver, and destroy, and 3)
        information we collect about our Data Subjects in order to fulfill our
        services to our customers.
      </p>
      <p>
        If you have additional questions or would like further information on
        this topic, please feel free to write to us at admin@almaconnect.com. By
        reviewing this Policy agreement and becoming an AlmaConnect customer,
        you are accepting the practices described herein. We may update this
        Policy from time to time, so please check back with us periodically or
        during the renewal of your services.
      </p>
      <p>
        <span style={{ color: "#666666" }}>
          General Customer Information&nbsp;
        </span>
      </p>
      <h2>Collecting Your Personal Information&nbsp;</h2>
      <p>
        Almanews does not collect any personal information about you unless you
        choose to provide it to us voluntarily. We define “personal information”
        as information that personally identifies you or allows us to contact
        you. Such information might include your name or email address.
        Generally, this information is requested when requesting information
        about or purchasing Alma Labs, Inc products and services.
      </p>
      <p>
        If there is information you do not want to share with us, please do not
        provide the information to us. Once you’re a customer of Almanews. Your
        account information is secure and is accessible by using a password that
        you select. Do not share your password with anyone
      </p>
      <h2>Use of your information</h2>
      <p>
        We may hold and process personal data that you provide to us in
        accordance with the GDPR.
      </p>
      <p>
        The information that we collect and store relating to you is primarily
        used:
      </p>
      <ul>
        <li>
          To enable us to provide services to you and to communicate with you.
          This may include Identity, Contact, and Transactional data.
        </li>
        <li>
          To notify you about any changes to our business, such as improvements
          to our Website or service/product changes, that may affect our service
          or relationship with you. This may include Identity and Contact Data.
        </li>
        <li>
          If you are an existing customer or prospective customer, we may
          contact you with information about goods and services similar to those
          that were the subject of a previous sale to you, or inquiry made, or a
          discussion held with you. This may include Identity and Contact Data.
        </li>
        <li>
          To tailor the content and information that we may send or display to
          you, to offer location customization, and personalized help and
          instructions, and to otherwise personalize your experiences while
          using the Sites or our Services.
        </li>
        <li>
          To better understand how users access and use the Services, both on an
          aggregated and individualized basis, in order to improve our Services
          to you and everyone.
        </li>
        <li>
          To ensure that content from our Site is presented as optimally as
          possible for you, your computer, and your web browser.
        </li>
        <li>
          Where you have consented to receive our e-newsletters to provide that
          to you. This may include Identity and Contact Data.
        </li>
        <li>
          Where we need to comply with a legal obligation. This may include
          Identity, Contact, Transactional, and Technical Data.
        </li>
        <li>
          Where it is necessary for our legitimate interests (or those of a
          third party) and your interests and fundamental rights do not override
          those interests. This may include all types of data
        </li>
      </ul>
      <h2>Access to your Personal Information</h2>
      <p>
        <span style={{ color: "#282a2c" }}>
          We will only use your personal data for a reason other than the
          purpose for which it was originally obtained if we consider that we
          need to use it for that other purpose and have a legitimate interest
          in doing so.
        </span>
      </p>
      <p>
        <span style={{ color: "#282a2c" }}>
          Access to your Personal Information Almanews, Inc will provide you
          with the means to ensure that your personal information is correct and
          current. If you are a customer or user of our website, you may submit
          updated information at any time. Registered users can view and edit
          personal information they have already given us on our website. We
          require you to enter your password for security purposes before you
          update your email address, user name, password, and/or your security
          question and answer. If you change your email address, we send an
          email to both your old and new email addresses.
        </span>
      </p>
      <h2>Controlling the use of your data</h2>
      <p>
        Where we rely on consent as the lawful basis for processing your data
        you can revoke or vary that consent at any time.
      </p>
      <p>
        If you do not want us to use your data or want to vary the consent that
        you have provided you can email us at admin@almaconnect.com at any time.
      </p>
      <h2>Links to Other Websites&nbsp;</h2>
      <p>
        For your convenience, we may provide links to other websites and web
        pages that we do not control (such as LinkedIn). We cannot be
        responsible for the privacy practices of any websites or pages not under
        our control and we do not endorse any of these websites or pages, the
        services or products described or offered on such sites or pages, or any
        of the content contained on those sites or pages.
      </p>
      <h2>Browser extension</h2>
      <p>The DataMine browser extension may:</p>
      <ul>
        <li>
          Transmit your LinkedIn cookies to DataMine when you initiate an export
          in order to perform the scan on the cloud.
        </li>
        <li>
          Transmit data about the current LinkedIn profile, search, or list
          you're viewing to Datamine.
        </li>
        <li>Manipulate the LinkedIn user interface.</li>
      </ul>
      <p>Your Linkedin cookies are never shared with third parties.</p>
      <h2>Data Security</h2>
      <p>
        <span style={{ color: "#282a2c" }}>
          We use appropriate technical, organizational, and administrative
          security measures to protect any information we hold in our records
          from loss, misuse, unauthorized access, disclosure, alteration, and
          destruction. We will also take all reasonable precautions to ensure
          that our staff and employees who have access to personal data about
          you have received adequate training.
        </span>
      </p>
      <h2>Almanews and the GDPR</h2>

      <strong>Your rights as a data subject</strong>
      <p>
        You as a data subject have many possibilities to impact how your
        personal information is being processed. You can:
      </p>
      <ol>
        <li>Request access to the information we process.</li>
        <li>
          Withdraw your consent if our use of your information is based on
          consent.
        </li>
        <li>
          Request that your information be deleted or for you to be forgotten
          (right to erasure).
        </li>
        <li>
          Request that we correct or change your information. As a customer, you
          may also do this by logging in to your account.
        </li>
        <li>Limit, restrict, or object to our use of your information.</li>
        <li>Access your information and/or receive it.</li>
        <li>
          Subject to applicable law, lodge a complaint with your local data
          protection authority.
        </li>
      </ol>
      <p>
        Please note that if you request to remove your information, we may
        retain some of the information for specific reasons, such as to resolve
        disputes, troubleshoot problems, and as required by law. Furthermore,
        some information is never completely removed from our databases due to
        technical constraints and the fact that we regularly back up our
        systems. Therefore, not all of your personal information will ever be
        completely removed from our databases.
      </p>

      <h2>Controller and Data Protection Officer</h2>
      <p>
        This website is provided by Alma Labs, Inc. 3411 Silverside Road, STE
        104, Wilmington, Newcastle, DE 19810, USA. Alma Labs, Inc. is the
        controller for the purpose of all applicable data protection legislation
        and is subject to the investigatory and enforcement powers of the
        Federal Trade Commission (“FTC”). If you have any questions about this
        Policy or the use of your personal information, you are always welcome
        to contact us at admin@almaconnect.com. You will also reach our Data
        Protection Officer, Dhruv at the same address. For our office details,
        please visit:&nbsp;
        <a
          href="https://www.almaconnect.com/contact_us"
          target="_blank"
          rel="noopener"
          className="text_link"
        >
          www.almaconnect.com/contact_us
        </a>
      </p>
      <h2>Alma Labs and the APPI</h2>
      <strong>Your rights as a data subject</strong>
      <p>
        You as a data subject have many possibilities to impact how your
        personal information is being processed. You can:
      </p>
      <ol>
        <li>Request access to the information we process.</li>
        <li>
          Withdraw your consent if our use of your information is based on
          consent.
        </li>
        <li>
          Request that your information be deleted (right to erasure) if your
          information is incorrect, used beyond the necessary scope to achieve
          the utilization purposes or obtained by deceit or other improper
          means.
        </li>
        <li>
          Request that we correct or change your information if your information
          is incorrect. As a customer, you may also do this by logging in to
          your account.
        </li>
        <li>
          Limit, restrict, or object to our use of your information if your
          information is used beyond the necessary scope to achieve the
          utilization purposes or obtained by deceit or other improper means.
        </li>
        <li>
          Subject to applicable law, lodge a complaint with your local data
          protection authority.
        </li>
      </ol>
      <p>
        Please note that if you request to remove your information, we may
        retain some of the information for specific reasons, such as to resolve
        disputes, troubleshoot problems, and as required by law. Furthermore,
        some information is never completely removed from our databases due to
        technical constraints and the fact that we regularly back up our
        systems. Therefore, not all of your personal information will ever be
        completely removed from our databases.
      </p>
      <h2>Identity and contact details of the Data Controller</h2>
      <p>
        If you have any questions about this Policy or the use of your personal
        information, you are always welcome to contact us at
        admin@almaconnect.com. You will also reach our Data Protection Officer,
        Dhruv at the same address. For our office details, please visit:&nbsp;
        <a
          href="https://www.almaconnect.com/contact_us"
          target="_blank"
          rel="noopener"
          className="text_link"
        >
          www.almaconnect.com/contact_us
        </a>
      </p>
      <h2>Alma Labs and the CCPA</h2>
      <p>
        Your rights under the CCPA You have rights that impact how your
        information is being processed, including:
      </p>
      <ol>
        <li>
          The right to know what information is collected, used, shared or sold.
        </li>
        <li>
          The right to delete (erasure right) information processed by us.
        </li>
        <li>
          The right to opt out of the sale of your information. If you are under
          the age of 16, we will only sell your information if you opt-in, with
          your parent or guardian consenting if you are under 13.
        </li>
        <li>
          Request that we correct or change your information if your information
          is incorrect. As a customer, you may also do this by logging in to
          your account.
        </li>
        <li>
          Limit, restrict, or object to our use of your information if your
          information is used beyond the necessary scope to achieve the
          utilization purposes or obtained by deceit or other improper means.
        </li>
        <li>
          Subject to applicable law, lodge a complaint with your local data
          protection authority.
        </li>
      </ol>
      <h2>Submitting a personal information or erasure request</h2>
      <p>
        You may ask us to disclose certain information to you about our
        collection and use of your personal information over the past 12 months.
        Once we receive and confirm your identity as a user of our Service, we
        can disclose that information to you. You may also make a request to
        have that information erased or deleted.
      </p>
      <p>
        You can make a request for your information twice every 12 months. If
        you are making an erasure request, it will help us if you include
        details of the information you would like erased. We may need to ask you
        for additional information to fully identify you. If we can’t identify
        you, we may not be able to erase your information.
      </p>
      <p>
        Please note that if you request that we remove your information, we may
        retain some of the information for specific reasons, such as to resolve
        disputes, troubleshoot problems, and as required by law. Furthermore,
        some information is never completely removed from our databases due to
        technical constraints and the fact that we regularly back up our
        systems. Therefore, not all of your personal information will ever be
        completely removed from our databases.
      </p>
      <p>
        We will respond to you on these requests as quickly as received your
        note but it may take us up to 45 days. If we need more time than that,
        we will let you know.
      </p>
      <strong>Opt-out request</strong>
      <p>
        If you are a resident of California and you want to make it clear to us
        that you do not want us to sell your personal information, contact us at
        admin@almaconnect.com.
      </p>
      <h2>International Data Transfers</h2>
      <p>
        Almanews is a global company, and information that we collect from or
        about you within the European Economic Area (“EEA”), or within any
        country located outside of the EEA, may be transferred across
        international borders to third countries outside of the EEA, or the
        country in which you are located, that do not have laws providing an
        adequate level of data protection. We as the Data Controller (or Data
        Exporter) will do our best to ensure that your information is protected
        no matter where it is transferred to.
      </p>
      <p>
        To ensure adequate protection for personal information we enter into
        Data Processing Agreements with our suppliers, including the ones who
        process your personal information outside of the non-EEA country in
        which you are located or the EEA. When necessary, we utilize approved
        transfer mechanisms (such as adequacy decisions or the Standard
        Contractual Clauses in the EU) to ensure the necessary protection of
        your personal information.
      </p>
      <h2>Contact Information</h2>
      <p>
        To ask questions or comment about this Privacy Policy and our privacy
        practices, contact us at: admin@almaconnect.com
      </p>
    </div>
  );
};
