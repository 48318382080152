import React, { useState } from "react"
import event_reg from "../../../assets/event_reg.png"
import getApiUrl from "../../utils/UrlHelper"
import { apiAgent } from "../../../axiosConfig"
import { Button } from "../../cta_utils/AnButton"
import useWindowSize from "../../custom_hooks/useWindowSize"
import { isWidthDown } from "../../utils/designUtils"
import ConditionalRender from "../../../ConditionalRender"
import AnPhoneInput from "../../input_utils/AnPhoneInput"
const EventReg = () => {
    const [info, setInfo] = useState({
        name: '',
        email: '',
        phone: '',
        organization: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const windowDimensions = useWindowSize();
    const handleInputChange = (e) => setInfo({...info, [e.target.name]: e.target.value})
    const onFormSubmit = () => {
        setIsLoading(true);
        let ph_no;
        if(info.phone?.length > 5) ph_no = info.phone;
        apiAgent({
            method: 'post',
            url: getApiUrl('event_record'),
            data: {
                name: info.name,
                email: info.email,
                phone: ph_no,
                organization: info.organization,
                type: 'apra'
            }
        }).then(
            (response) => {
                setIsLoading(false);
                if(response.data.success)
                    window.location = "/thank_you";
            },
            (error)  => {
                this.setState({...this.state, isLoading: false})
            }
        );
    }

    return (
    <div>
        <div className="event_reg_container">
            <div className="event_component" style={{display: 'flex', justifyContent: 'flex-end', fill: '#FFF',strokeWidth: '1px',stroke: '#E7EBEC',margin: 'auto'}}>
                <ConditionalRender if={!isWidthDown(windowDimensions.width, "lg")}>
                    <div><img src={event_reg} style={{maxWidth: 342, maxHeight: 447}}></img></div>
                </ConditionalRender>
                <div className="event_item" style={{ maxHeight: 447}}>
                    <div className="text_title_big" style={{lineHeight: 'normal', display: 'flex', justifyContent: 'center', paddingBottom: isWidthDown(windowDimensions.width, "md") ? 8 : 0}}>
                        Sign up and Get a Deal
                    </div>
                    <div>
                        <div className="event_input_box">
                            <div className="text_body_light" style={{fontSize: 14, lineHeight: 'normal', paddingBottom: 4}}>Full Name*</div>
                            <input type="text" id="name" name="name" value={info.name} style={{height: 36}} onChange={handleInputChange}/>
                        </div>
                        <div className="event_input_box">
                            <div className="text_body_light" style={{fontSize: 14, lineHeight: 'normal',paddingBottom: 4}}>Email*</div>
                            <input type="text" id="email" name="email" value={info.email} style={{height: 36}} onChange={handleInputChange}/>
                        </div>
                        <div className="event_input_box">
                            <div className="text_body_light" style={{fontSize: 14, lineHeight: 'normal',paddingBottom: 4}}>Mobile</div>
                            <AnPhoneInput phone={info.phone} setPhone={(phone) => setInfo({...info, phone: phone})}></AnPhoneInput>
                        </div>
                        <div className="event_input_box">
                            <div className="text_body_light" style={{fontSize: 14, lineHeight: 'normal',paddingBottom: 4}}>Organization*</div>
                            <input type="text" id="organization" name="organization" value={info.organization} style={{height: 36}} onChange={handleInputChange}/>
                        </div>
                    </div>
                    <Button onClick={onFormSubmit}
                        disabled={!(info.name && info.email && info.organization)} isLoading={isLoading} buttonStyle={{marginTop: '20px'}}>
                        Submit
                    </Button>
                </div>
            </div>
        </div>
    </div>
    )
}

export default EventReg