import React, { Component } from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

const isProduction = process.env.NODE_ENV === 'production';
if(isProduction){
    Bugsnag.start({
        apiKey: 'f89d2371ec650616616d3ae68dd406bf',
        plugins: [new BugsnagPluginReact()],
    })
}

export default class FeedPostErrorBoundary extends Component {
    constructor(props){
        super(props);
        this.state = {
            caughtError: false
        }
    }
    static getDerivedStateFromError(error) {
        return { caughtError: true };
      }
    
      componentDidCatch(error, errorInfo) {
        console.log({ error, errorInfo });
        if(isProduction){
            Bugsnag.notify(errorInfo);
        }
      }
    render() {
        if(this.state.caughtError && isProduction) return(
            <div className="text_subtitle article_container" style={{textAlign: 'center'}}>
                Oops!!! Something went wrong.Can't display this item
            </div>
        )
        return this.props.children;
  }
}
