import React from 'react'
import GreenCancel from "../../assets/GreenCancel.svg"

const ALL_TYPES = [
    {label: "News Results", key: "news_results"},
    {label: "Job Updates", key: "job_updates"}
]
const LowConfidenceTypesFilter = ({
    activeType,
    setActiveType,
    ...props
}) => {

    const renderType = (lType) => {
        return (
            <div key={lType.key} className={lType.key === activeType ? 'text_link box_border_highlight' : 'text_subtitle_light_grey_semi_bold'} style={{ cursor: 'pointer', padding: '8px 16px', fontWeight: 700 }} onClick={() => setActiveType(lType.key)}>
                <span>{lType.label}</span>
            </div> 
        )
    }
    return (
        <div className="default_flex_container" style={{ gap: "12px"}}>
            {
                ALL_TYPES.map(l_type => renderType(l_type))
            }
        </div>  
    )
}

export default LowConfidenceTypesFilter