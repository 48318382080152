import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

//new tooltip using react portal. need to replace this from old AnToolTip
const Tooltip = ({
    containerClass="text_caption",
    innerClass="text_caption",
    textTheme='background-light-black bottom',
    tooltipPos="bottom",
    tooltTipOverlay={x: 0, y: 0},
    innerStyle,
    tooltipText, 
    children,
    showWithoutOverflowing,
    enableTooltip=true,
    ...props
    }) => {
  const [position, setPosition] = useState(null);
  const [tempPosition, setTempPosition] = useState(null);
  const containerRef = useRef(null);
  const tooltipRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
        const container = containerRef.current;
  
        if (container) {
          setIsOverflowing(container.scrollWidth > container.clientWidth);
        }
  }, []);

  useLayoutEffect(() => {
    if(tempPosition)
    {
        const container = containerRef.current;
        const tooltipContainer = tooltipRef.current;
        let x_cord = (tooltipContainer?.clientWidth / 2) - (container?.clientWidth / 2) + tooltTipOverlay.x;
        let y_cord = tempPosition.y + tooltTipOverlay.y;
        if(tooltipPos === 'top')
        y_cord = y_cord - tooltipContainer?.clientHeight - 7; // 7 because of the arrow in tooltip
        else
        y_cord = y_cord + 7 // default bottom
        setPosition({
            x: tempPosition.x - x_cord,
            y: y_cord,
        })
    }
  }, [tempPosition])
  
  const handleMouseOver = (e) => {
    const bounds = e.currentTarget.getBoundingClientRect();
    let y_cord = bounds.y + bounds.height;
    if(tooltipPos === 'top')
    y_cord = bounds.y
    setTempPosition({
      x: bounds.x,
      y: y_cord,
    });
  };

  const handleMouseOut = () => {setPosition(null);setTempPosition(null)};

  const anchorProps = {
    ref: containerRef,
    onMouseOver: handleMouseOver,
    onMouseOut: handleMouseOut,
  };

  const anchor = React.isValidElement(children) ? (
    React.cloneElement(children, anchorProps)
  ) : (
    <span {...anchorProps}>{children}</span>
  );

  return (
    <>
      {anchor}
      {enableTooltip && (isOverflowing || showWithoutOverflowing) && (position || tempPosition) &&
        ReactDOM.createPortal(
          <div
            ref={tooltipRef}
            className={containerClass}
            style={{
              top: position?.y || 0,
              left: position?.x || 0,
              position: "fixed",
              zIndex: 9999,
              ...props.style,
            }}
          >
            <div
              className={`tooltip-text ${textTheme} ${innerClass}`}
              style={{
                ...innerStyle,
              }}
            >
              {tooltipText()}
            </div>
          </div>,
          document.body
        )}
    </>
  );
}

export default Tooltip;
