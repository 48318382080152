import { NewsFeedHighConfidenceTabs } from "../../../utils/AllFilterTabs"

export const initialNewsFeedState = {
    confidenceType: 'high',
    leadingError: "",
    total: 0,
    allFilterTabs: NewsFeedHighConfidenceTabs,
    dropDownFilterlist: {viewType: 'all', containComment: 'all', containSuggestion: 'all', activeViewType: 'news_results'},
    postType: {label: "All results", key: "all"},
}
export const NewsFeedReducer = (state, action) => {
    switch(action.type){
        case "SET_STATE":{
            return {...state, ...action.payload}
        }
        case "CONFIDENCE_TAB_SWITCH":{
            return {...state, confidenceType: action.payload}
        }
        default:{
            return {...state}
        }
    }
}