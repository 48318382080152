import React, {useEffect, useState} from 'react'

import {apiAgent} from './axiosConfig';
import {Button} from './components/cta_utils/AnButton.js'

import {AnInputBox} from './components/input_utils/AnInput.js'
import {useSelector} from "react-redux";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js';

const CompleteProfile = () => {
    let current_user = useSelector(state => state.current_user.user)
    const [info, setInfo] = useState({name: current_user.name, phone: current_user.phone, password: ''})
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(false);
    const handleInputChange = (e) => setInfo({...info, [e.target.name]: e.target.value})
    const history = useHistory();

    const onFormSubmit = () => {        
        var errors = {}, error = false;
        if(!info.name) {
            errors["name"] = "Please enter Name.";
            error = true;
        }
        if(!info.password) {
            errors["password"] = "Please enter Password.";
            error = true;
        }
        if(info.confirm_password != info.password){
          errors["confirm_password"] = "Passwords didn’t match. Please try again.";
          error = true;
        }
        if(!info.phone) {
            errors["phone"] = "Please enter Phone.";
            error = true;
        }
        setErrors(errors);
        if(error)
            return;
        setIsLoading(true);        
        apiAgent({
            method: 'post',
            url: '/users/complete_profile',
            data: {
                name: info.name,
                password: info.password,
                phone: info.phone,
            }
        }).then(
            (response) => {
                const params = new URLSearchParams(window.location.search);
                const signup_source = params.get("source");
                setIsLoading(false);                
                if(signup_source === "pipedrive"){
                    history.push("/pipedrive-permission");
                    return
                }
                if(current_user.teams.id)
                    window.location = "/";
                else{                    
                    window.location = "/connect_teams";
                }
            },
            (error)  => {
                this.setState({...this.state, isLoading: false})
            }
        );
    }

    return (
        <React.Fragment>
            <div className="complete_profile_container">
                <div className="text_title_big" style={{textAlign: 'center'}}>
                    Welcome!
                </div>
                <div className='complete_profile_details'>
                    <AnInputBox label={"Your name"} error={errors["name"]} style={{marginTop: 8}}>
                        <input type="text" id="name" name="name" value={info.name} onChange={handleInputChange} placeholder="John Doe"/>
                    </AnInputBox>
                    <AnInputBox label={"Choose a password"} error={errors["password"]} style={{marginTop: 8}}>
                        <input type="password" id="password" name="password" value={info.password} onChange={handleInputChange} placeholder="Enter password"/>
                    </AnInputBox>
                    <AnInputBox label={"Confirm password"} error={errors["confirm_password"]} style={{marginTop: 8}}>
                        <input type="password" name="confirm_password" value={info.confirm_password} onChange={handleInputChange} placeholder="Re-enter password"/>
                    </AnInputBox>
                    <AnInputBox label={"Phone"} error={errors["phone"]} style={{marginTop: 8}}>
                        <input type="text" id="phone" name="phone" value={info.phone} onChange={handleInputChange} placeholder="+411234567890"/>
                    </AnInputBox>
                </div>
                <Button onClick={onFormSubmit} buttonSize="Big"
                    disabled={!(info.name || info.password || info.phone)} isLoading={isLoading} buttonStyle={{marginTop: '24px'}}>
                    Continue
                </Button>
            </div>
        </React.Fragment>
    );
}

export default CompleteProfile;
