import { Link } from "react-router-dom/cjs/react-router-dom.min";
import AnPopup from "../../../cta_utils/AnPopup";
import { map_prospect } from "../api";
import { ALL_MAPPER_ACTIONS } from "../reducers/TempMapperReducer";
import { Button } from "../../../cta_utils/AnButton";
import { setState } from "../../../utils/CommonReducer";

export const SuggestionMapPopup = ({pageData, pageDataReducer, handlePopupClose}) => {
    const {currItem} = pageData
    const map = () => {
        map_prospect({mapper_id: currItem.id}).then((response) => {
            pageDataReducer({type: ALL_MAPPER_ACTIONS['SET_STATE'], payload: {successMessages:{
                message: "Prospect mapped",
                duration: 3000
            }}})
            handlePopupClose();
            let temp_mappers = [...pageData.items]
            temp_mappers.forEach((temp_mapper, index) => {
                let new_mapper = {...temp_mapper}
                if(new_mapper.prospect_id === currItem.prospect_id)
                    new_mapper.mapped = true
                temp_mappers[index] = {...new_mapper}
            })
            setState(pageDataReducer)({items: [...temp_mappers]})
        })
    }
    return(
        <AnPopup 
            name="mapConfirmationPopup"
            handleOnClosePopup={handlePopupClose}
            maxWidth={480}
        >
            {currItem.mapped_url && (
                <div className="text_body" style={{textAlign: 'center'}}>
                    <div>This profile is already mapped.</div>
                    <div style={{marginTop: 8}}>
                        <Link to={`/prospects/${currItem.prospect_id}`} target="_blank"> 
                            <span>{currItem.name}</span>
                        </Link> - 
                        <a href={currItem.mapped_url} target="_blank"> 
                            <span> {currItem.l_name}</span>
                        </a>
                    </div>
                </div>
            )}
            <div className="text_body" style={{textAlign: 'center', marginTop: 8}}>Do you want to {currItem.mapped_url ? 'remap' : 'map'} this?</div>
            <div style={{display: 'flex', marginTop: 16, alignContent: 'center', justifyContent: 'center'}}>
                <Button buttonSize="Medium" buttonStyle={{width: 'max-content'}} innerButtonStye={{padding: '8px 20px'}}
                        onClick={() => map()}>Map</Button>
                <Button type="Secondary" buttonSize="Medium" buttonStyle={{width: 'max-content', marginLeft: 16}} innerButtonStye={{padding: '8px 20px'}}
                        onClick={handlePopupClose}>Cancel</Button>
            </div>
        </AnPopup>
        )
}