import {useState} from 'react'
import {useSelector} from 'react-redux'
import {AnInputBox} from '../../input_utils/AnInput.js'
import {Button} from '../../cta_utils/AnButton.js'
import {apiAgent} from '../../../axiosConfig.js'
import { Link } from 'react-router-dom'
import continueArrow from '../../../assets/ContinueGreenArrow.svg'

const InviteUsers = (props) => {
  const [emailList, setEmailList] = useState([''])
  const [errorMessages, setErrorMessages] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const current_team = useSelector( state => state.current_team.team);
  const handleOnChange = (event, index) => {
    if(!event.target.value){
      let temp = [...emailList]
      temp.splice(index, 1)
      setEmailList(temp)
    }else{
      setEmailList( oldValues => {
        let temp = [...oldValues]
        temp[index] = event.target.value
        return temp;
      })
    }
  }

  const handleOnInvite = () => {
    let errors = validateForm()
    if(errors){
      setErrorMessages(errors)
      return
    }
    setIsLoading(true)
    apiAgent({
      method: 'post',
      url: `/teams/${current_team.id}/send_invite`,
      data: {
        emails: emailList.map(e => e.toLowerCase())
      }
    }).then(res => {
      setIsLoading(false)
    }).catch(err => {
      setIsLoading(false)
    })
  }

  const validateForm = () => {
    let errors = null
    emailList.forEach((item, i) => {
      if(!item){
        errors = "Email address should not be empty"
      }
    });
    return errors
  }
  return(
    <>
      <div style={{maxWidth: 400, margin: '32px auto 0px'}}>
        <div className="text_title_big" style={{textAlign: 'center', marginBottom: 32}}>Add your team members</div>
        {
          emailList.map((email, index) => (
            <AnInputBox label={index === 0 ? 'Email address' : null} error={(!emailList[index] && errorMessages) ? errorMessages : null} key={index}>
              <input type="text" value={emailList[index]} onChange={(event) => {handleOnChange(event, index)}} placeholder='Team member’s email'></input>
            </AnInputBox>
          ))
        }
        <div className="text_link_small" style={{cursor: 'pointer'}} onClick={() => {setErrorMessages(null);setEmailList([...emailList, ''])}}>Add {emailList.length == 0 ? 'Email' : 'another'}</div>
        <Button buttonStyle={{width: '100%', marginTop: 24}} disabled={emailList.length == 0}onClick={handleOnInvite} isLoading={isLoading}>Send Invite</Button>        
        <Link to="/" className="text_link default_flex_container" style={{marginTop: 24, justifyContent: 'center', textDecoration: 'none'}}>
            <div>Skip and continue to news feed</div>
            <img src={continueArrow} style={{width: 24, marginLeft: 4}}/>
        </Link>
      </div>
    </>
  )
}

export default InviteUsers
