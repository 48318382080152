import React from 'react'
import Carousel from "react-bootstrap/Carousel";
import AnPopup from '../../cta_utils/AnPopup';
import ConditionalRender from '../../../ConditionalRender';


import TmnKatie from "../../../assets/outbound/testimonials/Katie.png"
import TmnJennifer from "../../../assets/outbound/testimonials/Jennifer.png"
import TmnMarlboro from "../../../assets/outbound/testimonials/Marlboro.png"
import TmnAugsburg from "../../../assets/outbound/testimonials/Augsburg.png"
import TmnBryant from "../../../assets/outbound/testimonials/Bryant.png"
import TmnPingry from "../../../assets/outbound/testimonials/Pingry_testimonial.png"
import TmnMenlo from "../../../assets/outbound/testimonials/Menlo.png"
import TmnLouis from "../../../assets/outbound/testimonials/Louis.png"
import TmnPlay from "../../../assets/outbound/testimonials/Play.png"
import TmnJenniferRHU from "../../../assets/outbound/testimonials/JenniferRHU.png"
import TmnBecky from "../../../assets/outbound/testimonials/Becky.png"
import { useState } from 'react';

const testimonials_data = [
    {
        image: TmnKatie,
        play_button: TmnPlay,
        video_link: "https://www.youtube.com/embed/vGrPUMbQLbI",
        hover_button: "play_btn",
        play_style: "z-index: 9999;width: 64px;height: 64px;margin: auto;",
        content: "We've been really pleased with the results. It's a really great product that has really changed the way that we've been doing news alerts.",
        person_info: {
            name: "-Katie Bokenkamp,",
            designation: " Willamette University"
        }
    },
    {
        image: TmnBecky,
        play_button: TmnPlay,
        video_link: "https://www.youtube.com/embed/HINLqpQ61zk",
        hover_button: "play_btn",
        play_style: "z-index: 9999;width: 64px;height: 64px;margin: auto;",
        content: "We absolutely love AlmaConnect News. We get all sorts of information that we've never had before, all in one place. We've also been getting job updates, and those are fantastic and so useful in terms of identifying potential new prospects.",
        person_info: {
            name: "-Becky Kennedy, ",
            designation: "Director of Advancement Services, Whitman College"
        }
    },
    {
        image: TmnJenniferRHU,
        play_button: TmnPlay,
        video_link: "https://www.youtube.com/embed/TrlCxSRK4ok",
        hover_button: "play_btn",
        play_style: "z-index: 9999;width: 64px;height: 64px;margin: auto;",
        content: "We use AlmaConnect News to streamline all of our news information about our alumni and those that were our donors that we're interested in. It's been very helpful.",
        person_info: {
            name: "-Jennifer Kenzor, ",
            designation: "Executive Director of Advancement Services, Rose-Hulman Institute of Technology"
        }
    },
    {
        image: TmnPingry,
        content: "I actually met with, and formed a relationship with, a current parent based on seeing his name in one of the news alerts. He was previously unknown to our office, but has an incredibly fascinating career and his family donated generously to our school this year without any solicitation!",
        play_button: TmnPlay,
        video_link: "https://www.youtube.com/embed/0CFjMHtnD2A",
        hover_button: "play_btn",
        play_style: "z-index: 9999;width: 64px;height: 64px;margin: auto;",
        content: "We recently received a news alert from AlmaConnect that one of our alumni, who is a library director in South Jersey, was a finalist for a national library award. We noticed that we hadn't had much interaction with her in a couple of years, but we were able to use that news alert to send her some of the renderings for the new library plans that we have for our lower school campus.",
        person_info: {
            name: "-Derrick Shearer,",
            designation: " Director of Advancement Services, The Pingry School"
        },
    },
    {
        image: TmnMarlboro,
        content: "AlmaConnect has not only made that easier to do, but also helped new team members familiarize themselves with their assigned constituents seamlessly. After only a few weeks of using the platform, one team member was able to secure an annual gift thanks to the awareness of a constituent in the news.",
        person_info: {
            name: "-Natalie Makous Cuadra,",
            designation: " Marlborough School"
        }
    },
    {
        image: TmnAugsburg,
        content: "There is so much information out there, and AlmaConnect makes navigating through it all to locate what I need easy.",
        person_info: {
            name: "-Jauston Campbell,",
            designation: " Augsburg University"
        }
    },
    {
        image: TmnBryant,
        content: "I am very happy with the service and continue to find prospects that I would not have found through other methods.",
        person_info: {
            name: "-Nicole Melmed,",
            designation: " Bryant University"
        }
    },
    {
        image: TmnMenlo,
        content: "I am using AlmaConnect daily and it is an easy and straightforward process. The articles you’re finding are relevant and helpful with our work.",
        person_info: {
            name: "-Vidya Kagan,",
            designation: " Director of Data Management & Research,  Menlo School"
        }
    },
    {
        image: TmnLouis,
        content: "AlmaConnect has been a great resource for us to keep up with alumni! We have recently been working on increasing our engagement on LinkedIn. AlmaConnect has provided us with a lot of great alumni news articles for us to share on this platform. We are excited to continue to utilize this service to stay in the know of alumni successes.",
        person_info: {
            name: "-Kathryn O'Leary,",
            designation: " Alumni Engagement Officer, Louisburg College"
        }
    }
];
const TestimonialsCarousel = ({
    colorPalette={mainText: "#FFFFFF", secondaryText: "#FFFFFF", variant: "white"},
    ...props
}) => {
    const [displayPopup, setDisplayPopup] = useState(false);
    const [tmnInterval, setTmnInterval] = useState(5000);
    const [activeIndex, setActiveIndex] = useState(0);

    const handleTestimonialClick = (e, index) => {
        e.stopPropagation();
        let data = testimonials_data[index];
        if(!data.play_button) return;
        setTmnInterval(null);
        setActiveIndex(index);
        setDisplayPopup(true)
    }
    const handlePopupClose = (list={}) => {
        setDisplayPopup(false)        
        setTmnInterval(5000);
    }
    const renderTestimonialPopup = () => {
        return(
            <AnPopup name="testimonialPopup" handleOnClosePopup={handlePopupClose} maxWidth="1000px" height="577px">
                <iframe title="testimonial" style={{maxWidth: "1000px", maxHeight: "577px", width: "100%", height: "100%"}}src={testimonials_data[activeIndex].video_link} frameborder="0"  
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                </iframe>
            </AnPopup>
        )
    }
    return (
        
        <div className="caraousel_section" style={{...props.style}}>
            <Carousel interval={tmnInterval} pause="hover" indicators={true} variant={colorPalette.variant}
                prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon" style={{alignSelf: "end", marginBottom: "0.8rem", maxWidth: 24, color: props.navColor || "white"}}/>}
                nextIcon={<span aria-hidden="true" className="carousel-control-next-icon" style={{alignSelf: "end", marginBottom: "0.8rem", maxWidth: 24, color: props.navColor || "white"}}/>}
            >
                {
                    testimonials_data.map((testimonial, index) => {
                        return(
                            <Carousel.Item key={index}>
                                <div className='item_container'>
                                    <div style={{position: "relative"}} onClick={(e) => handleTestimonialClick(e, index)}>
                                        <img src={testimonial.image} style={{maxWidth: 240}}></img>
                                        <ConditionalRender if={testimonial.play_button}>
                                            <img className="play_btn" src={TmnPlay} style={{position: "absolute",cursor: "pointer", top: "50%", left: "50%", maxWidth: "75px", transform: "translate(-50%, -50%)"}}></img>
                                        </ConditionalRender>
                                    </div>
                                    <div className="text_container">
                                        <div className="testimonial_text" style={{color: colorPalette.mainText}}>
                                            "{testimonial.content}"
                                        </div>
                                        <div className="testimonial_text2" style={{color: colorPalette.secondaryText}}>
                                            <span>{testimonial.person_info.name}</span>
                                            <span style={{fontWeight: 600}}>{testimonial.person_info.designation}</span>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                        )
                    })
                }
            </Carousel>
            {displayPopup && renderTestimonialPopup()}
        </div>
    )
}

export default TestimonialsCarousel