import React from 'react'
import { Link } from 'react-router-dom';
import SubscribedSuccess from '../../../assets/SubscribedSuccess.png';
import { Button } from '../../cta_utils/AnButton';
import SubscribedCard from "../../../assets/SubscribedCard.png"

const Subscribed = () => {
  return (
    <div className='subscribed_container'>
        <div style={{maxWidth: "540px", margin: "auto"}}>
          <img src={SubscribedSuccess} width="240px" height="240px"></img>
          <div className='text_title_big' style={{marginTop: 24}}>Thank you for subscribing to AlmaConnect's News Scanner free email digest. </div>
          <div className='text_body_big' style={{marginTop: 12}}>You will start receiving news results of your alumni soon.</div>
        </div>
        <a href="https://calendly.com/almaconnect-news-scanner/know-more">
          <img src={SubscribedCard} style={{maxWidth: "785px", width: "100%"}}></img>
        </a>
    </div>
  )
}

export default Subscribed