import { render } from '@testing-library/react';
import React, { useState } from 'react'
import MenuDots from '../../../../../assets/MenuDots.svg'
import AnDropDown from '../../../../cta_utils/AnDropDown';


const AdminProspectsOptions = ({
    renderOptions,
    ...props
}) => {
    const [isDisplayable, setIsDisplayable] = useState(false);
    
    const renderDropDown = () => {
        return(
            <AnDropDown style={{position: 'absolute', right: 0, padding: '20px', background: 'white', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', borderRadius: 4, width: 'max-content', zIndex: 99}}
                toggleDropDown={() => {setIsDisplayable(isDisplayable => !isDisplayable);}}>
            {renderOptions()}     
          </AnDropDown>
        )
    }
    
    return (
        <div className='admin_prospect_options_container' onClick={() => {setIsDisplayable(isDisplayable => !isDisplayable)}}>
          <img src={MenuDots} style={{cursor: 'pointer'}}/>
          {isDisplayable && renderDropDown()}
        </div>
    )
}

export default AdminProspectsOptions