import Cross from '../../assets/Cancel.svg'

const SelectedOptions = (props) => {
    const handleDeselect = (index) => {
        let newOptions = [...props.selectedOptions]
        console.log(props.selectedOptions)
        newOptions.splice(index, 1);
        props.callback(newOptions)
      }
    return(
        <div style={{display: 'flex', flexWrap: 'wrap', alignItems:' center', marginBottom: 8}}>
           {
           props.selectedOptions.map((opt, index) => (
                <div key={index} style={{width: 'fit-content', padding: '8px 12px', marginTop: 8, marginRight: 8, display: 'flex', alignItems: 'center', border: '1px solid #E7ECEB', borderRadius: 4}}>
                  <div className="text_subtitle_light">
                      {opt}
                  </div>
                  <img src={Cross} style={{marginLeft: 8, width: 20}} onClick={() => {handleDeselect(opt)}}/>
                </div>
            )
          ) 
          }
        </div>
    )
}

export default SelectedOptions;