import { AnConfirmPopup } from "../../../cta_utils/AnConfirmPopup"
import { generate_suggestions } from "../api"
import { ALL_MAPPER_ACTIONS } from "../reducers/TempMapperReducer";

export const GenerateSuggestionPopup = ({pageDataReducer, handlePopupClose}) => {
    const handleGenerateMapperSuggestions = () => {
        generate_suggestions({}).then((response) => {
            pageDataReducer({type: ALL_MAPPER_ACTIONS['SET_STATE'], payload: {successMessages:{
                message: "Suggestion will be generated soon",
                duration: 3000
            }}})
            handlePopupClose();
        })
    }
    return(
        <AnConfirmPopup 
            onSubmitCallback={() => handleGenerateMapperSuggestions()}
            onCancelCallback={handlePopupClose}
            name="Generate Suggestions"
            confirm_message="Do you want to generate suggestions?"
            btnLabels={{submitLabel: 'Generate', cancelLabel: 'Cancel'}}
        >
        </AnConfirmPopup>
        )
}