import { useEffect, useState } from "react";
import ConditionalRender from "../../../ConditionalRender";
import BannerImage from "./assets/newsLandingBanner.png";
import BulbIcon from "../../../assets/Bulb.svg";
import SuccessGrey from "../../../assets/SuccessGrey.svg";
import Numeric_1 from "../../../assets/Numeric_1.png";
import Numeric_2 from "../../../assets/Numeric_2.png";
import SampleNewsArticle from "../../../assets/SampleNewsArticle.png";
import { NoNewsLanding } from "./NoNewsLanding";
import { apiAgent } from "../../../axiosConfig";
import { useHistory } from "react-router";
import NewsFeed from "../news_feed/news_feed";
import { Link } from "react-router-dom";
import { HelpCenterLinks } from "../support_views/help_center/HelpCenterPage";

const NewsLanding = (props) => {
    const [prospectsStatus, setProspectsStatus] = useState(null);
    const history = useHistory();

    useEffect(() => {
        apiAgent({
            method: "get",
            url: "/team/status",
        })
            .then((res) => {
                setProspectsStatus(res.data);
            })
            .catch((err) => {});
    }, []);
    if (!prospectsStatus) return null;
    if (prospectsStatus.feed_status == "feed_present") {
        return <NewsFeed displaySearchBar={true}/>;
    }
    if (
        prospectsStatus.prospect_status == "prospect_data_present" &&
        prospectsStatus.feed_status == "no_feed"
    )
        return <NoNewsLanding />;

    return (
        <div style={{ maxWidth: 640, margin: "32px auto" }}>
            <img src={BannerImage} style={{ maxWidth: 522, margin: "auto" }} />
            <ConditionalRender
                if={prospectsStatus.prospect_status == "prospect_data_needed"}
            >
                <div
                    style={{ marginTop: 24, textAlign: "center" }}
                    className="text_title_big"
                >
                    Get news when your prospects get mentioned
                </div>
                <div style={{ textAlign: "center" }}>
                    <a
                        href="/import_prospects"
                        className="text_link_big"
                        style={{ textDecoration: "none" }}
                    >
                        Upload a list of prospects
                    </a>
                    <span> to get started</span>
                </div>
            </ConditionalRender>
            <ConditionalRender
                if={
                    !(prospectsStatus.prospect_status == "prospect_data_needed")
                }
            >
                <div
                    style={{ marginTop: 24, textAlign: "center" }}
                    className="text_title_big"
                >
                    Your news is getting fetched. Please wait for sometime
                </div>
            </ConditionalRender>
            <div
                style={{
                    marginTop: 40,
                    borderRadius: 16,
                    border: "1px solid #B6B9BB",
                }}
            >
                <div
                    style={{
                        padding: 24,
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <img
                        src={BulbIcon}
                        style={{ width: 32, marginRight: 14 }}
                    />
                    <div className="text_title">Getting Started</div>
                </div>
                <div style={{ height: 1, background: "#B6B9BB" }}></div>
                <div style={{ padding: 24 }}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "flex-start",
                            marginBottom: 16,
                        }}
                    >
                        <img
                            src={Numeric_1}
                            style={{ width: 20, marginRight: 8 }}
                        />
                        <div
                            style={{
                                paddingBottom: 16,
                                borderBottom: "1px solid #B6B9BB",
                            }}
                        >
                            <div className="text_subtitle_big">
                                Upload prospect data
                            </div>
                            <div
                                className="text_body_light"
                                style={{ fontWeight: 400 }}
                            >
                                Upload a list of your prospects (People or
                                organizations). You can add them individually or
                                bulk import them from a csv file.
                            </div>
                            <div style={{ marginTop: 12 }}>
                                <Link
                                    to={HelpCenterLinks["Adding Prospects"]}
                                    className="text_link"
                                    style={{
                                        display: "inline",
                                        marginRight: 54,
                                    }}
                                    target="_blank"
                                >
                                    Learn more about importing data >
                                </Link>
                                <Link
                                    to={HelpCenterLinks["Privacy Policy"]}
                                    className="text_link"
                                    style={{ display: "inline" }}
                                    target="_blank"
                                >
                                    Read our data security policy >
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div style={{ display: "flex", alignItems: "flex-start" }}>
                        <img
                            src={Numeric_2}
                            style={{ width: 20, marginRight: 8 }}
                        />
                        <div>
                            <div className="text_subtitle_big">
                                Getting news results
                            </div>
                            <div
                                className="text_body_light"
                                style={{ fontWeight: 400 }}
                            >
                                We know you like to see the news results
                                immediately... But our news tracker takes time
                                in analyzing the data with various sets of
                                keywords so that you get more accurate results.
                                It typically takes 1-2 days for your news to get
                                updated after you’ve uploaded your prospect
                                data.
                            </div>
                            <div style={{ marginTop: 12 }}>
                                <Link
                                    to={HelpCenterLinks["Keywords"]}
                                    className="text_link"
                                    style={{
                                        display: "inline",
                                        marginRight: 54,
                                    }}
                                    target="_blank"
                                >
                                    Learn more about keywords >
                                </Link>
                                <a
                                    href={SampleNewsArticle}
                                    target="_blank"
                                    className="text_link"
                                    style={{ display: "inline" }}
                                >
                                    See sample news article >
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewsLanding;
