import { useEffect, useRef } from 'react'
import axios from 'axios'
import { apiAgent } from '../../axiosConfig'

const useExternalArticleFetch = (
    api_url,
    external_url,
    callBack
) => {
    const cancelToken = useRef(null)
    useEffect(() => { 
        if(external_url){
            cancelToken.current = axios.CancelToken.source();
            callBack("LOADING", null)
            apiAgent({
                method: 'get',
                url: api_url,
                params: {
                    url: external_url
                },
                cancelToken: cancelToken.current.token
            }).then(res => {
                callBack("SUCCESS", res)
            }).catch( err => {
                if(axios.isCancel(err)){
                    //A NEWER REQUEST CAME
                }else{
                    // SOME PROPER ERROR HANDLING
                }
                callBack("ERROR", err)                
            })
        }        
        return () => {if(cancelToken.current) cancelToken.current.cancel("A newer request came")}
    }, [external_url])
}

export default useExternalArticleFetch