import { apiAgent } from "../../../axiosConfig"

export const fetchTeamsStats = (data_params, searchTerm) => {
    return apiAgent({
        method: "get",
        url: `/outbound_mail/outbound_stats?q=${searchTerm}`,
        params: data_params
    })
}

export const downloadOutboundStats = (data_params) => {
        return apiAgent({
            method: "post",
            url: "/outbound_mail/export_outbound_stats",
            params: data_params
        })
}