import React from 'react'
import LoadingGif from '../../assets/LoadingAnimation.gif'

export const LoaderComponent = ({
    type="SECTION",
    loaderStyle={width: 32, display: 'block', margin: '32px auto'},
    ...props
}) => {
    if(!props.isLoading) return null
    if(type==="FULL_PAGE") return(
        <div style={{position: 'fixed', inset: 0}}>
            <img src={LoadingGif} className="full_page_loader" style={loaderStyle}/>
        </div>
    )    
    return (
        <img src={LoadingGif} style={loaderStyle} />            
    )
}
