import React, { useState } from 'react'
import { apiAgent } from '../../../axiosConfig'
import { Button } from '../../cta_utils/AnButton'

const AddProspectStep3 = (props) => {
    const [isLoading, setIsloading] = useState(false)

    const handleFinishImport = () => {
        setIsloading(true)
        apiAgent({
            method: 'post',
            url: `/prospect_import/${props.step3Data.id}/finish`,
            data: {}
        }).then(res => {
            setIsloading(false)
            props.setActiveStep('finished')
        }).catch(err => {
            setIsloading(false)
        })
    }
    return (
        <>
            <div style={{padding: 24, borderBottom: '1px solid #E7ECEB'}}>
                <div className="text_title">
                    Preview of your data
                </div>
                <div className="text_body_light" style={{marginTop: 4}}>
                    CSV column mapping information:
                </div>
                <table className="map_step2_table" style={{textAlign: 'left', marginTop: 16}}>
                    <thead>
                        <tr className="text_subtitle_big" style={{background: 'rgba(67, 126, 254, 0.8)', color: '#fff'}}>
                            <th style={{width: '50%'}}>Column Header</th>
                            <th style={{width: '50%'}}>Mapped as</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.step3Data.field_mappings.map((field, index) => {
                                return(
                                    <tr key={index}>
                                        <td>
                                            {field.original_field}
                                        </td>
                                        <td>
                                            {field.mapped_to}
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className='default_flex_container' style={{padding: 24, borderTop: '1px solid #E7ECEB', justifyContent: 'flex-end'}}>
                <div style={{display: 'flex'}}>
                    <Button buttonSize='Medium' type="Secondary" onClick={() => {props.setActiveStep('step_2')}} buttonStyle={{zIndex: 99}} innerButtonStye={{padding: '8px 34px'}} >Back</Button>
                    <Button buttonSize='Medium' isLoading={isLoading} onClick={handleFinishImport} buttonStyle={{marginLeft: 16}} innerButtonStye={{padding: '8px 60px'}}>Finish</Button>
                </div>
            </div>
        </>
    )
}

export default AddProspectStep3
