import React, { useReducer, useState } from 'react'
import AnPopup from '../../cta_utils/AnPopup';
import { AnInputBox } from '../../input_utils/AnInput';
import { Button } from '../../cta_utils/AnButton';
import { apiAgent } from '../../../axiosConfig';
import AlmaLogo from "../../../assets/alma_logo.png";

const ShareViaEmail = ({
  parentReducer,
  item,
  search_tags,
  ...props
}) => {
  
  const [componentState, setComponentState] = useState({
    share_email: props.emails || "",
    name: "",
    search_tags: search_tags
  })

  const [isLoading, setIsLoading] = useState({})

  const share = () => {
    setIsLoading({isSendingEmail: true})
    apiAgent({
      method: "post",
      url: `/post/${item.id || item._id}/share_search_data_via_email`,
      params: componentState
  })
      .then((res) => {
          setIsLoading({isSendingEmail: true})
          parentReducer({popupsList: {}, successMessage: "Email sent"})
          setComponentState({...componentState, share_email: props.emails || "", name: ""});
      })
      .catch((err) => {});
  }

  return (
      <AnPopup
          name="shareViaMailPopup"
          handleOnClosePopup={() => {
              parentReducer({popupsList: {}})
          }}
          maxWidth={388}
      >
          <div style={{display: "block", margin: "0px auto 16px", width: "fit-content"}}>
              <img src={AlmaLogo} style={{width: 138, height: 32}}></img>
          </div>
            
          <AnInputBox label="Name&#42; ">
              <input
                  type="text"
                  id="name"
                  value={componentState.name}
                  onChange={(e) =>
                      setComponentState(st => ({...st, name: e.target.value}))
                  }
                  placeholder="Name"
              />
          </AnInputBox>
          <AnInputBox label="Email&#42; ">
              <input
                  type="text"
                  id="email"
                  value={componentState.share_email}
                  onChange={(e) =>
                      setComponentState(st => ({...st, share_email: e.target.value}))
                  }
                  placeholder="Email"
              />
          </AnInputBox>
          <Button
              onClick={() => {
                  share();
              }}
              isLoading={isLoading.isSendingEmail}
              buttonStyle={{ marginTop: "16px" }}
          >
              Submit
          </Button>
      </AnPopup>
  );
};
export default ShareViaEmail