import { useState } from "react";
import tick2 from "../../assets/tick2.svg"
import filtersIcon2 from "../../assets/filtersIcon2.svg"
import AnDropDown from "./AnDropDown.js";
import ConditionalRender from "../../ConditionalRender";

const AnDropdownGroupFilter = ({
    group_options,
    filterList,
    onChangeCallback,
    customContainerClass,
    customStyle,
    positionRight=false,
    outerDropdownStyle,
    ...props
}) => {
    const [showdropdown, setShowdropdown] = useState(false); 
    const toggleDropDown = () => {setShowdropdown(d => !d)}
    const renderGroupOptions = () => group_options.map((group, ind) => (
        <div key={group.group_key}>
            <ConditionalRender if={ind != 0}>
                <div  className="line_seperator_ex_light_grey"  style={{ margin: "8px 0px" }}></div>
            </ConditionalRender>
            <div>
                {
                    group.options.map((option) => (
                        <div
                            key={option.key}
                            className={ filterList[group.group_key] == option.key ? "an_dropdown_filter text_body" : "an_dropdown_filter text_body_light" }
                            onClick={() => onChangeCallback(group.group_key, option.key)}
                            style={{marginBottom: 6}}
                        >
                        <span>{option.label}</span>
                        { filterList[group.group_key] == option.key && <img src={tick2}></img>}
                        </div>
                    ))
                }
            </div>
        </div>
    ))
    const handleFilter = () => {
        return (
            <AnDropDown
                style={{
                    position: "absolute",
                    right: positionRight ? 'auto' : 0,
                    padding: "14px",
                    background: "white",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                    borderRadius: 4,
                    width: "178px",
                    zIndex: 99,
                    marginTop: 12,
                    ...outerDropdownStyle
                }}
                toggleDropDown={toggleDropDown}
            >
                {renderGroupOptions()}
        </AnDropDown>
        )
    }
    return (
        <>
            <div className={customContainerClass} onClick={toggleDropDown} style={customStyle}>
            <img src={filtersIcon2} style={{cursor: "pointer"}}></img>
            {  showdropdown && handleFilter() }
            </div>
        </>
    )
}
//propTypes = { group_options, filterList, setOption, customStyle}

export default AnDropdownGroupFilter