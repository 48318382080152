import FiltersIcon from '../../../../../assets/FiltersIcon.svg'
import { Button } from '../../../../cta_utils/AnButton'
import AnPopup from '../../../../cta_utils/AnPopup'
import { AnInputBox } from '../../../../input_utils/AnInput'
import { useMapperFilterContext } from '../../../new_temp_mapper/contexts/mapper_filter_context'
import { ALL_MAPPER_FILTER_ACTIONS, initialrange_filterFilter } from '../FiltersStateReducer'
import filtersIcon from "../../../../../assets/FiltersIcon.svg"
import { CONDITIONS } from '../MapperSuggestionUtils'
import { useMemo, useState } from 'react'
import ConditionalRender from '../../../../../ConditionalRender'
import { setState } from '../../../../utils/CommonReducer'
const RangeFilter = ({
    rangeLabel,
    toggleDropDown,
    ...props
}) => {
    const { filtersState, filtersStateReducer } = useMapperFilterContext();
    const {range_filter} = filtersState
    const [popupsList, setPopupsList] = useState({});
    const handlePopupClose = () => setPopupsList({})
    const handleConditionChange = (cond) => {
        let new_range_filter = { ...filtersState.range_filter }
        new_range_filter.cond = cond
        new_range_filter.min = new_range_filter.max = '';
        setState(filtersStateReducer)({ range_filter: new_range_filter })
    }
    const handleMaxChange = (value) => {
        let new_range_filter = { ...filtersState.range_filter }
        new_range_filter.max = value
        setState(filtersStateReducer)({ range_filter: new_range_filter })
    }
    const handleFilterApply = () => {
        let temp_range = {min: filtersState.range_filter.min, max: filtersState.range_filter.max}
        setState(filtersStateReducer)({range: temp_range})
        handlePopupClose()
        toggleDropDown()
    }
    const renderRangeFilterPopup = () => {
        return (
            <AnPopup name="RangeFilterPopup" handleOnClosePopup={handlePopupClose} maxWidth={370}>
                <div className="text_title">
                    {rangeLabel()}
                </div>
                <div className="line_seperator_ex_light_grey" style={{ margin: "16px 0px" }}></div>
                <div>
                    <AnInputBox label="Condition" style={{ maxWidth: "106px" }}>
                        <select placeholder="Above" value={filtersState.range_filter.cond} onChange={(e) => handleConditionChange(e.target.value)}>
                            {
                                CONDITIONS.map(condition => (
                                    <option key={condition} value={condition}>
                                        {condition}
                                    </option>
                                ))
                            }
                        </select>
                    </AnInputBox>
                    <div className='default_flex_container'>
                        <div>
                            <AnInputBox label={"Value"} style={{ maxWidth: "144px" }}>
                                <input type="text" id="value" value={ range_filter.cond === 'Below' ? range_filter.max : range_filter.min}  onChange={(e) => 
                                    filtersStateReducer({type: ALL_MAPPER_FILTER_ACTIONS["APPLY_RANGE_FILTER"], payload: {new_value: e.target.value}})
                                } placeholder={filtersState.range_filter.cond === 'Between' ? 'From' : '50'} />
                            </AnInputBox>
                        </div>
                        <div>
                            <ConditionalRender if={filtersState.range_filter.cond === 'Between'}>
                                <div className='default_flex_container' style={{paddingTop: 20}}>
                                <div className='text_body_light' style={{padding: '0px 6px'}}>-</div>
                                <AnInputBox label={""} style={{ maxWidth: "144px" }}>
                                    <input type="text" id="value" value={range_filter.max} onChange={(e) => handleMaxChange(e.target.value)} placeholder='To' />
                                </AnInputBox>
                                </div>
                            </ConditionalRender>
                        </div>
                    </div>
                </div>
                <div className="line_seperator_ex_light_grey" style={{ margin: "16px 0px" }}></div>
                <div className='flex_container_space_between'>
                    <div style={{ display: "flex" }}>
                        <Button
                            buttonSize="Medium"
                            type="Secondary"
                            innerButtonStye={{ padding: "8px 28px" }}
                            onClick={handlePopupClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            buttonSize="Medium"
                            innerButtonStye={{ padding: "8px 30px" }}
                            buttonStyle={{ marginLeft: 12 }}
                            onClick={handleFilterApply}
                            isLoading={false}
                        >
                            Apply
                        </Button>

                    </div>
                </div>
            </AnPopup>
        )
    }
    return (
        <div>
            <div className='filter_item_container' style={{ cursor: 'pointer' }} onClick={() => setPopupsList({ rangeFilterPopup: true })}>
                <div className='default_flex_container' style={{ padding: "12px 16px 16px" }}>
                    <div className='circle_image_background' style={{ width: 24, height: 24 }}>
                        <img className="circle_image" style={{width: 14, height: 14}} src={filtersIcon}></img>
                    </div>
                    <div className='text_body_light' style={{ marginLeft: 4 }}>Enter Manually</div>
                </div>
            </div>
            {popupsList.rangeFilterPopup && renderRangeFilterPopup()}
        </div>
    )
}

export default RangeFilter