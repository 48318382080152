
export const Keywords = () => {
    return (
        <div style={{ marginTop: 24 }}>
            <div className="text_title_big" style={{marginBottom: 20}}>Keywords</div>

            <div className="help_center_text">
                Keyword combinations are nothing but variations to be used to track a prospect. For instance, if you are trying to track an ‘Alex Blake’ of Princeton University, then it would not suffice to set up an alert or news feed for just
                <ul>
                    <li>Alex Blake or even </li>
                    <li>Alex Blake + Princeton University</li>
                </ul>
                In our research, we found that less than 5% of news mentions the alma mater. So the second keyword - <span style={{fontWeight: 700}} >‘Name + Institution’ </span>- may not even be netting any results. Google alerts are not going to pick up such an article. Therefore, a wide net has to be cast with wide variations:
            </div>

            <div style={{backgroundColor: "rgba(67, 126, 254, 0.10)", marginTop: 12}}>
                <ul style={{padding: "21px 25px"}}>
                    <li className="help_center_semi_bold">
                        Alex Blake
                    </li>
                    <li className="help_center_semi_bold" style={{marginTop: 8}}>
                        Alex Blake + <span style={{color: "#437EFE", fontStyle: "italic"}}>Princeton University </span>
                    </li>
                    <li className="help_center_semi_bold" style={{marginTop: 8}}>
                        Alex Blake + <span style={{color: "#437EFE", fontStyle: "italic"}}>‘Company Name’</span>
                    </li>
                    <li className="help_center_semi_bold" style={{marginTop: 8}}>
                        Alex Blake + <span style={{color: "#437EFE", fontStyle: "italic"}}>‘Designation’</span>
                    </li>
                    <li className="help_center_semi_bold" style={{marginTop: 8}}>
                        Alex Blake + <span style={{color: "#437EFE", fontStyle: "italic"}}>‘Location’</span>
                    </li>
                    <li className="help_center_semi_bold" style={{marginTop: 8}}>
                        Alex Blake + <span style={{color: "#437EFE", fontStyle: "italic"}}>‘Company’ + ‘Location’</span> and so on
                    </li>
                </ul>
            </div>


            <div className="help_center_text">
                Such a wide range nets news only about the correct ‘Alex Blake’ and not just people with the same name. Incidentally AlmaConnect News works with 500 sets of keywords when researching prospects.    
            </div>
        </div>
    );
};