import React from 'react';
import AnPopup from "../cta_utils/AnPopup";
import {Component, createRef, useState, useEffect} from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { Button } from "../cta_utils/AnButton.js";
import { apiAgent } from "../../axiosConfig.js";
import { AnInputBox } from "../input_utils/AnInput.js";
import { NameInitial } from "../cta_utils/AnNameInitial.js";
import AnDropDown from "../cta_utils/AnDropDown";
import MenuDots from "../../assets/MenuDots.svg";
import ViewIcon from "../../assets/ViewIcon.svg";
import TopProspectImage from "../../assets/top_prospect.svg";
import GreyArrowDown from "../../assets/GreyArrowDown.svg";
import ShareIcon from "../../assets/share_on_links/share.png";
import PlusCheckbox from "../../assets/Add_Plus_Circle.png";
import NewOne from "../../assets/new_one.svg";
import { Link } from "react-router-dom";
import { FlashMessages } from "../cta_utils/FlashMessages.js";
import ConditionalRender from "../../ConditionalRender.js";
import { LoaderComponent } from "../utils/LoaderComponent.js";
import AnToggleButton from "../cta_utils/AnToggleButton.js";
import AnMultipleSuggestionInsideBox from "../input_utils/AnMultipleSuggestionInsideBox.js"
import getApiUrl from '../utils/UrlHelper';

const AddToFolder = (props) => {
    const [popupsList, setPopupsList] = useState({});
    const [folder,setFolder] = useState({
        name: "",
        showFolderPrivacyToggle: false,
        team_members: []
    })
    const [isLoading, setIsLoading] = useState(false);

    const current_user = useSelector(state => state.current_user.user);

    const addNewFolder = () => {
        let members = [current_user.id, folder.team_members.map((member) => { return member.id})];
        var temp_popup_lists = {...props.popup_lists};
        temp_popup_lists['add_to_folder_popup'] = true;
        setIsLoading(true);
        apiAgent({
            method: "post",
            url: getApiUrl('create_new_folder'),
            data: {
                name: folder.name,
                team_member_ids: members
            },
        }).then((res) => {
            setPopupsList({});
            props.closePopup();
            props.setMemberFolders({type: 'SET_STATE', payload: {
                folderAdded: !props.folderUpdate
            }});
            setIsLoading(false);
            props.setStateFromChild({
                success_message: "New Folder created",
                messageTheme: "success",
                show_dropdown: false
                }
            );
        })
        .catch((err) => {
            setIsLoading(false);
        });
    }

    const handleFolderSharingData = (val) => {
        setFolder({
            ...folder,
            team_members: [...val]
        })
    };

    const handleFolderClick = (index) => {
        const updatedData = [...props.memberFolders];
        updatedData[index].selected_folder = !updatedData[index].selected_folder;
        props.setMemberFolders({type: 'SET_STATE', payload: {
            memberFolders: updatedData
        }});
    }    

    const createNewFolderPopup = () => {
        return (
          <AnPopup
            name="createNewFolderPopup"
            handleOnClosePopup={() => setPopupsList({})}
            maxWidth={480}
          >
            <div className="text_title">New folder</div>
            <div style={{height: 1, background: "rgb(231, 236, 235)", margin: "16px 0px 20px"}}></div>
            <AnInputBox label={"Folder Name"} style={{ margin: '16px 0px 12px'}}>
              <input
                type="text"
                id="folder_name"
                value={folder.name}
                onChange={(e) => setFolder({
                  ...folder,
                  name: e.target.value
                })}
                placeholder="Folder name"
              />
            </AnInputBox>
            <AnToggleButton
              toggleState={folder.showFolderPrivacyToggle}
              toggleText="Allow team members to this folder"
              onChangeCallback={() => setFolder({
                ...folder,
                showFolderPrivacyToggle: !folder.showFolderPrivacyToggle
              })}
              outerToggleStyle={{ margin: "8px 0px 24px" }}
            >
            </AnToggleButton>
            <ConditionalRender if={folder.showFolderPrivacyToggle}>
              <AnMultipleSuggestionInsideBox
                callback={handleFolderSharingData}
                suggestionUrl="/suggestions/team_members"
                placeholder="Search team members"
                inputClass="an_search_box"
                name="Team_member_search"
                theme="default"
                selectedOptions={folder.team_members || []}
              />
            </ConditionalRender>
            <Button
              onClick={addNewFolder}
              buttonSize="LessPadding"
              innerButtonStye={{padding: "8px 36px"}}
              isLoading={isLoading}
            >
              Add
            </Button>
          </AnPopup>
        );
    }

    const handleAddToFolder = () => {
        const folder_ids =  props.memberFolders.filter(res=>res.selected_folder).map(ele => ele.id);
        apiAgent({
            method: "post",
            url: getApiUrl("add_post_to_folder", {":post_id": props.post.id}),
            data: {
                folder_ids: folder_ids,
            },
        }).then((res) => {
            props.closePopup();
            props.setStateFromChild({
                success_message: "Article successfully added to chosen folders",
                messageTheme: "success"}
            );
            const updatedFolders = props.memberFolders.map(folder => ({
                ...folder,
                selected_folder: false
            }));
            props.setMemberFolders({type: 'SET_STATE', payload: {
                memberFolders: updatedFolders,
                folderAdded: !props.folderUpdate
            }});
        })
        .catch((err) => {});
    }

    return (
        <>
        {popupsList.createNewFolderPopup? <></> :
        <AnPopup
            name="addToFolderPopup"
            handleOnClosePopup={props.closePopup}
            maxWidth={480}
        >
            <div className="flex_container_space_between">
                <div className="text_title">Add to folder</div>
                <div className="text_link" onClick={() => setPopupsList({createNewFolderPopup: true})}>+Create new</div>
            </div>
            <div
                style={{
                    height: 1,
                    background: "#E7ECEB",
                    margin: "16px 0px",
                    marginBottom: "20px"
                }}
            ></div>
            <div className="text_body_light" style={{marginBottom: 12}}>
                Select folders to add
            </div>
            {
                props.memberFolders.length > 0 &&
                props.memberFolders.map((folder, index) => (
                    <div className="default_flex_container" key={index}>
                    <div className="default_flex_container" style={{padding: "7px 0px"}}>
                        <img src={folder.selected_folder? NewOne : PlusCheckbox} onClick={() => handleFolderClick(index)}></img>
                        <div className="text_subtitle_semi_bold" style={{marginLeft: 9}}>{folder.name}</div>
                    </div>
                    </div>
                ))
            }
            <Button
                onClick={() => handleAddToFolder()}
                buttonSize="LessPadding"
                buttonStyle={{marginTop: 24, width: "max-content"}}
                innerButtonStye={{padding: "8px 36px"}}
            >
                Add
            </Button>
        </AnPopup>
        }
        {popupsList.createNewFolderPopup && createNewFolderPopup()}
        </>
    )   
}

export default AddToFolder;