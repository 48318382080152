import React, { useReducer, useState } from 'react'
import { useEffect } from 'react';
import { apiAgent } from "../../../axiosConfig";
import Pagination from '../../../Pagination';
import TableHeads from '../../visualizers/tabular/TableHeads';
import TableRows from '../../visualizers/tabular/TableRows';
import { fetchListData, fetchSyncData, ListAddSync, ListAddRemark, EditSyncUrl, toggleSyncable } from './api.js';
import AnPopup from '../../cta_utils/AnPopup';
import { LoaderComponent } from '../../utils/LoaderComponent';
import ConditionalRender from '../../../ConditionalRender';
import { FlashMessages } from '../../cta_utils/FlashMessages';
import SearchTeamsUtils from '../../utils/SearchTeamsUtils';
import {Button, TabbedButton} from "../../cta_utils/AnButton";
import AnDropdownFilter from "../../cta_utils/AnDropdownfilter";
import { AnInputBox } from '../../input_utils/AnInput';
import { indexOfObject } from '../../utils/ArrayUtils';
import useCheckUserAccess from '../../custom_hooks/useCheckUserAccess';
import { plans } from '../../utils/AllPlanTypes.js';
import AnDropdownGroupFilter from '../../cta_utils/AnDropdownGroupfilter';
import { JobUpdatesDashboardFilterOptions } from '../../utils/PostFilterOptions';
import { AnConfirmPopup } from '../../cta_utils/AnConfirmPopup';
import { AnInputCheckBox } from '../../input_utils/AnInputCheckBox';
import EditPencil from "../../../assets/EditPencil.svg"
import LinkedinSyncUrlPopup from "./linkedin_sync_url_popup.jsx";
import AnToolTip from '../../cta_utils/AnTooltip.js';

const dashboard_types = [{label: 'Teams List', key: 'teams_list'}, {label: "Sync Status", key: 'sync_status'}];

const JobUpdatesDashboard = () => {

    const [allLists, setAllLists] = useState([]);
    const [currList, setCurrList] = useState({});
    const [allSyncs, setAllSyncs] = useState([]);
    const [dashboardType, setDashboardType] = useState('teams_list');
    const [searchTerm, setSearchTerm] = useState("");
    const [allStartableSyncs, setAllStartables] = useState([]);
    const [pageData, setPageData] = useState({
        loadingTeams: false,
        totalPages: 0,
        currentPage: 1,
        totalCount: 0,
        isLoading: {},
        dropDownFilterlist: {teamType: 'all', syncType: 'queue'}
    });
    const [successMessages, setSuccessMessages] = useState({
        message: "",
        duration: 0,
        from: ""
    });
    const [popupsList, setPopupsList] = useState({});
    const checkUserAccess = useCheckUserAccess();
    const [editItem, setEditItem] = useState({});
    const [syncUrlInfo, setSyncUrlInfo] = useState({});
    useEffect(() => {
       getData(1);
       const urlParams = new URLSearchParams(window.location.search);
       if (urlParams.has('linkedinSyncPopup')) {
        setPopupsList({linkedinSyncUrlPopup: true});
        window.history.replaceState({}, document.title, window.location.href.split('?')[0]);
       }
    }, [dashboardType,pageData.dropDownFilterlist.teamType, pageData.dropDownFilterlist.syncType ]);

    const getData = (page) => {
        if(dashboardType === 'teams_list')
            fetchLists(page);
        else
            fetchSyncs(page);
    }

    const fetchLists = (page) => {
        let data_params={page: page, plan: pageData.dropDownFilterlist.teamType, q: searchTerm}
        setPageData({...pageData, loadingTeams: true})
        fetchListData(data_params).then(res => {
            setAllLists(res.data.data);
            setPageData({...pageData, loadingTeams: false, totalPages: res.data.total_pages, currentPage: page, totalCount: res.data.total_count});
        }).catch(err => {
            console.log(err);
            setPageData({...pageData, loadingTeams: false});
        })
    }

    const fetchSyncs = (page) => {
        let data_params={page: page, plan: pageData.dropDownFilterlist.teamType, type: pageData.dropDownFilterlist.syncType, q: searchTerm}
        setPageData({...pageData, loadingTeams: true})
        fetchSyncData(data_params).then(res => {
            setAllSyncs(res.data.data);
            setPageData({...pageData, loadingTeams: false, totalPages: res.data.total_pages, currentPage: page, totalCount: res.data.total_count});
        }).catch(err => {
            console.log(err);
            setPageData({...pageData, loadingTeams: false});
        })
    }

    const addSync = () => {
        if(allStartableSyncs.length === 0) return;
        setPageData(pgd => ({...pageData, isLoading: {isSyncing: true}}))
        ListAddSync({id: currList.id, syncable_ids: allStartableSyncs}).then(res => {
            setSuccessMessages( {
                message: "Sync enqueued.",
                duration: 3000
            });
        }).then(res => {
            setPageData(pgd => ({...pageData, isLoading: {}}))            
            handleOnClose()}
        ).catch(err => {
            setPageData(pgd => ({...pageData, isLoading: {}}))        
        })
    }


    const onSuccess = (message) => {
        setSuccessMessages({...successMessages, message: message})
    }

    const handleOnClose = () => {
        setPopupsList({});
        setCurrList({});
        setEditItem({})
        setSyncUrlInfo({})
        setAllStartables([])
    }

    const handleSubmit = (id = null) => {
        let temp = [...currList.linkedin_sync_urls]
        let index = indexOfObject(temp, "id", id);
        let data_params = {};
        if(index !== -1)
        data_params = {ln_sync_url_id: id, url: temp[index].url, description: temp[index].description}
        else
        {
            if(!syncUrlInfo.url) {
                setSyncUrlInfo({errors: {url: "Url can't be empty."}})
                return;
            }
            data_params = {url: syncUrlInfo.url, description: syncUrlInfo.description}
        }
        EditSyncUrl(currList.id, data_params).then(res => {
            setSuccessMessages( {
                message: "Sync url saved",
                duration: 3000
            });
            handleOnClose();
            getData(1)
        }).catch(err => {
        })
    }

    const linkedinSyncUrlPopup = () => {
        return (
            <AnPopup name="linkedinSyncUrlPopup" handleOnClosePopup={handleOnClose} maxWidth={480}>
                <LinkedinSyncUrlPopup handleOnClose={handleOnClose} setSuccessMessages={setSuccessMessages} allLists={allLists} setAllLists={setAllLists} getData={getData}></LinkedinSyncUrlPopup>
            </AnPopup>
        )
    }

    const handleInputChange = (e, id) => {
        let temp = [...currList.linkedin_sync_urls]
        let index = indexOfObject(temp, "id", id);
        let new_value;
        if(index !== -1)
        new_value = {...temp[index], [e.target.name]: e.target.value}
        temp[index] = new_value
        setCurrList({...currList, linkedin_sync_urls:  temp})
    }

    const handleSyncUrlInputChange = (e) => {
        setSyncUrlInfo({...syncUrlInfo, [e.target.name]: e.target.value})
    }

    const enableOrDisableSyncUrl = () => {
        toggleSyncable(currList?.currSyncUrl?.id).then(res => {
            setSuccessMessages( {
                message: "Saved",
                duration: 3000
            });
            handleOnClose();
            getData(pageData.currentPage)
        }).catch(err => {
            setSuccessMessages( {
                messageTheme: 'error',
                message: err.response?.data?.error_message,
                duration: 3000
            });
            handleOnClose();
        })
    }
    const renderTitle = () => {
        return (
            <>
                <div className="text_body_light" style={{marginBottom: 4}}>List Name: {currList.title}</div>
                <div className="text_body_light">Team: {currList.team_name}</div>
            </>
        )
    }

    const addSyncUrlsPopup = () => {
        return(
            <AnPopup name="addSyncUrlsPopup" handleOnClosePopup={handleOnClose} maxWidth={480}>
                <div className='text_title' style={{marginBottom: 4}}>Add LinkedIn Sync URL</div>
                {renderTitle()}
                <div  className="line_seperator_ex_light_grey"  style={{ margin: "16px auto" }}></div>
                <AnInputBox label={"LinkedIn Sync URL"} error={syncUrlInfo.errors?.url} style={{marginTop: 8}}>
                    <input type="text" id="url" name="url" value={syncUrlInfo.url} onChange={handleSyncUrlInputChange} placeholder="add url"/>
                </AnInputBox>
                <AnInputBox label={"Remarks"} style={{marginTop: 8}}>
                    <input type="text" id="description" name="description" value={syncUrlInfo.description} onChange={handleSyncUrlInputChange} placeholder="description"/>
                </AnInputBox>
                <div className='default_flex_container' style={{marginTop: "12px"}}>
                <Button onClick={handleSubmit}
                    buttonStyle={{width: "max-content"}}
                >
                    Save
                </Button>
                <Button type="Secondary" buttonSize="Medium" buttonStyle={{width: 'max-content', marginLeft: 16}} innerButtonStye={{padding: '8px 20px'}} onClick={() => handleOnClose()}>
                    Cancel
                </Button>
                </div>
            </AnPopup>
        )
    }
    const startSyncPopup = () => {
        return(
            <AnPopup name="startSyncPopup" handleOnClosePopup={handleOnClose} maxWidth={360}>
                <div className='text_title' style={{marginBottom: 4}}>Start Sync</div>
                {renderTitle()}
                <div  className="line_seperator_ex_light_grey" style={{ margin: "16px auto 21px" }}></div> 
                {
                    currList.linkedin_sync_urls.map((sync_url, idx) => {
                        if(sync_url.disabled) return (<></>)
                        let check_index = allStartableSyncs.indexOf(sync_url.id)
                        let is_checked =  check_index!== -1
                        return (
                            <div key={sync_url.id} style={{marginBottom: 16}}>
                                <div className='default_flex_container' style={{wordBreak: "break-word"}}>
                                    <AnInputCheckBox isChecked={is_checked} label={sync_url.id}>
                                        <input type="checkbox" value={sync_url.id} checked={is_checked} onChange={() => setAllStartables(st => {
                                                let temp = [...st]
                                                if(is_checked) temp.splice(check_index, 1);
                                                else temp.push(sync_url.id)
                                                return temp;
                                            })}></input>
                                    </AnInputCheckBox>
                                    <a href={sync_url.url} target="_blank" rel="noreferrer" className="text_link" style={{marginLeft: 8}}>view</a>
                                </div>
                            </div>
                        )
                    })
                }
                <div className='default_flex_container' style={{ paddingTop: "8px" }}>
                    <Button buttonSize='Medium' onClick={() => addSync()}
                        buttonStyle={{ width: "max-content" }}
                        isLoading={pageData.isLoading.isSyncing}
                        innerButtonStye={{width: '100%', padding: '8px 20px'}}
                    >
                        Save
                    </Button>
                    <Button type="Secondary" buttonSize="Medium" buttonStyle={{ width: 'max-content', marginLeft: 16 }} innerButtonStye={{ padding: '8px 20px' }} onClick={() => handleOnClose()}>
                        Cancel
                    </Button>
                </div>
            </AnPopup>
        )
    }
    const editSyncUrlsPopup = () => {
        return(
            <AnPopup name="editSyncUrlsPopup" handleOnClosePopup={handleOnClose} maxWidth={920} customStyle={{padding: '36px 24px'}}>
                <div className="text_title" style={{marginBottom: 4}}>View Sync URL</div>
                {renderTitle()}
                <div  className="line_seperator_ex_light_grey"  style={{ margin: "16px auto 12px" }}></div>
                <div className='text_link' style={{float: 'right', marginBottom: 16}} onClick={()=>setPopupsList({addSyncUrlsPopup: true})}>+Add</div>
                <div style={{clear: 'both'}}></div>
                <table className="modern_data_table_light" style={{textAlign: "left"}}>
                    <TableHeads typoGraphyClass="text_body_big_light" headerClass="table_header_row_small" customStyles={{background: '#F3F4F5'}}>
                        <th style={{width: "30%"}}>ID</th>
                        <th style={{width: "30%"}}>Linkedin Sync URL</th>
                        <th style={{width: "15%"}}>Last synced on</th>
                        <th style={{width: "15%"}}>Remarks</th>
                        <ConditionalRender if={checkUserAccess('update_sync_url')}>
                        <th style={{width: "10%"}}>Actions</th>
                        </ConditionalRender>
                    </TableHeads>
                    <tbody>
                    {
                        currList.linkedin_sync_urls.map((sync_url, idx) => {
                            return (
                                    <TableRows key={sync_url.id} rowClass="table_content_row_small text_body">
                                        <td>{sync_url.id}</td>
                                        <td>
                                            <ConditionalRender if={editItem[sync_url.id]?.sync_url}>
                                                <AnInputBox style={{marginTop: 8}}>
                                                    <textarea type="text" id={sync_url.id} name="url" value={sync_url.url} 
                                                    onChange={(e) => handleInputChange(e, sync_url.id)} placeholder="Edit Linkedin Sync Url"
                                                    />
                                                </AnInputBox>
                                                <div className='default_flex_container'>
                                                <div className='text_link' style={{marginRight: 8}} onClick={() => handleSubmit(sync_url.id)}>Save</div>
                                                <div className='text_link red' onClick={() => setEditItem({})}>Cancel</div>
                                                </div>
                                            </ConditionalRender>
                                            <ConditionalRender if={!editItem[sync_url.id]?.sync_url}>
                                                <div className='default_flex_container'>
                                                <div className='truncate' style={{ maxWidth: '160px'}}>{sync_url.url}</div>
                                                <img src={EditPencil} onClick={()=>{setEditItem({...editItem, [sync_url.id]: {sync_url: true}})}} style={{cursor: 'pointer', width: 20, height: 20, margin: 'auto 4px', verticalAlign: 'bottom'}}/>
                                                </div>
                                            </ConditionalRender>
                                        </td>
                                        <td>{sync_url.last_synced_at}</td>
                                        <td>
                                            <ConditionalRender if={editItem[sync_url.id]?.description}>
                                                <AnInputBox style={{marginTop: 8}}>
                                                    <textarea type="text" id={sync_url.id} name="description" value={sync_url.description} 
                                                    onChange={(e) => handleInputChange(e, sync_url.id)} placeholder="Edit description"
                                                    />
                                                </AnInputBox>
                                                <div className='default_flex_container'>
                                                <div className='text_link' style={{marginRight: 8}} onClick={() => handleSubmit(sync_url.id)}>Save</div>
                                                <div className='text_link red' onClick={() => setEditItem({})}>Cancel</div>
                                                </div>
                                            </ConditionalRender>
                                            <ConditionalRender if={!editItem[sync_url.id]?.description}>
                                                {sync_url.description}
                                                <img src={EditPencil} onClick={()=>{setEditItem({...editItem, [sync_url.id]: {description: true}})}} style={{cursor: 'pointer', width: 20, height: 20, margin: 'auto 4px', verticalAlign: 'bottom'}}/>
                                            </ConditionalRender>
                                        </td>
                                        <ConditionalRender if={checkUserAccess('update_sync_url')}>
                                        <td>
                                            <div className={sync_url.disabled ? 'text_link red' : 'text_link'} onClick={() => {setPopupsList({confirmationSyncUrlPopup: true});setCurrList({...currList, currSyncUrl: sync_url})}}>{sync_url.disabled ? 'enable' :  'disable'}</div>
                                        </td>
                                        </ConditionalRender>
                                    </TableRows>
                                )
                            }
                        )
                    }
                    </tbody>
                </table>
            </AnPopup>)
    }

    const renderTeamLists = () => {
        return (
            <div>
                <ConditionalRender if={!pageData.loadingTeams}>
                <table className="modern_data_table_light" style={{textAlign: "left", marginTop: "24px"}}>
                    <TableHeads typoGraphyClass="text_body_big_light" headerClass="table_header_row" customStyles={{background: '#F3F4F5'}}>
                        <th style={{width: "15%"}}>List Name</th>
                        <th style={{width: "10%"}}>Team</th>
                        <th style={{width: "15%", whiteSpace: 'nowrap'}}>Team Type</th>
                        <th style={{width: "15%", whiteSpace: 'nowrap'}}>Linkedin sync URL</th>
                        <th style={{width: "15%", whiteSpace: 'nowrap'}}>Last synced on</th>
                        <th style={{width: "10%", whiteSpace: 'nowrap'}}>Sync Frequency<br/><span>(in days)</span></th>
                        <th style={{width: "10%", whiteSpace: 'nowrap'}}>Job Posts<br/><span>(in Week)</span></th>
                        <ConditionalRender if={checkUserAccess('auto_sync_job_updates')}>
                        <th style={{width: "10%"}}>Action</th>
                        </ConditionalRender>
                    </TableHeads>
                    <tbody>
                    {
                        allLists.map(list => {
                                return (
                                    <TableRows key={list.id} rowClass="table_content_row text_body">
                                        <td>{list.title}</td>
                                        <td>{list.team_name}</td>
                                        <td>{list.team_type}</td>
                                        <td><span onClick={() => {setPopupsList({editSyncUrlsPopup: true});setCurrList(list)}} className="text_link">View Sync URLs</span></td>
                                        <td>
                                            
                                            {<AnToolTip
                                            innerClass='text_caption_small_light'
                                            messageTheme='background-light-black top'
                                            tooltipText={list.synced_at && `${list.synced_at_datetime}`}
                                            innerStyle={{marginLeft: -54, color: 'white'}}
                                            > 
                                                    <div>
                                                    {list.synced_at}
                                                    </div>
                                            </AnToolTip>}
                                        </td>
                                        <td>{list.sync_frequency}</td>
                                        <td>{list.job_update_count}</td>
                                        <ConditionalRender if={checkUserAccess('auto_sync_job_updates')}>
                                        <td>
                                            <Button onClick={()=> {setCurrList(list); setPopupsList({startSyncPopup: true})}}                                                 
                                                innerButtonStye={{padding: "8px 22px"}}
                                            >
                                                Start Sync
                                            </Button>
                                        </td>
                                        </ConditionalRender>
                                    </TableRows>
                                )
                            }
                        )
                    }
                    </tbody>
                </table>
                </ConditionalRender>
            </div>
        );
    }

    const renderSyncStatus = () => {
        return (
            <div>
                <ConditionalRender if={!pageData.loadingTeams}>
                <table className="modern_data_table_light" style={{textAlign: "left", marginTop: "24px"}}>
                    <TableHeads typoGraphyClass="text_body_big_light" headerClass="table_header_row_small" customStyles={{background: '#F3F4F5'}}>
                        <th style={{width: "15%"}}>Linkedin URL ID</th>
                        <th style={{width: "13%"}}>List Name</th>
                        <th style={{width: "13%"}}>Team</th>
                        <th style={{width: "13%"}}>Team Type</th>
                        <th style={{width: "12%"}}>Sync Status</th>
                        <th style={{width: "12%"}}>State change at</th>
                        <th style={{width: "12%"}}>Completed Sync Count</th>
                        <th style={{width: "10%"}}>Server Log</th>
                    </TableHeads>
                    <tbody>
                    {
                        allSyncs.map(sync => {
                                return (
                                    <TableRows key={sync.id} rowClass="table_content_row_small text_body">
                                        <td>{sync.linkedin_sync_url_id}</td>
                                        <td>{sync.list_title}</td>
                                        <td>{sync.team_name}</td>
                                        <td>{sync.team_type}</td>
                                        <td>{sync.state}</td>
                                        <td>{sync.state_changed_at}</td>
                                        <td>{sync.completed_sync}</td>
                                        <td>{sync.server_log}</td>
                                    </TableRows>
                                )
                            }
                        )
                    }
                    </tbody>
                </table>
                </ConditionalRender>
            </div>
        );
    }

    return (
        <div style={{margin: "26px auto", maxWidth: "1080px", width: '100%'}}>

            <div className='text_title' style={{paddingBottom: 8}}>
                Job Updates Dashboard
            </div>
            <div className='tabbed_button_container' style={{margin: '16px 0px 12px'}}>
                {
                    dashboard_types.map(type => (
                        <div key={type.key}>
                            <TabbedButton className='text_body_big_light_bold' buttonStyle={{padding: '0px 0px 10px', lineHeight: '20px', borderBottom: 'none'}} isActive={dashboardType === type.key} onClick={() => setDashboardType(type.key)}>
                                {type.label}
                            </TabbedButton>
                        </div>
                    ))
                }
            </div>
            <ConditionalRender if={dashboardType == 'teams_list'}>
                <AnDropdownFilter
                    option = {pageData.dropDownFilterlist.teamType}
                    setOption = {(option) => setPageData({ ...pageData, dropDownFilterlist: { ...pageData.dropDownFilterlist, teamType: option } } )}
                    options = {plans}
                    style = {{position: 'relative', top: 11, float: 'right'}}
                >
                </AnDropdownFilter>
            </ConditionalRender>
            <ConditionalRender if={dashboardType == 'sync_status'}>
                <AnDropdownGroupFilter
                    filterList={pageData.dropDownFilterlist}
                    onChangeCallback={(group, option) => setPageData({ ...pageData, dropDownFilterlist: { ...pageData.dropDownFilterlist, [group]: option } } )}
                    group_options={JobUpdatesDashboardFilterOptions}
                    customStyle={{ position: 'relative',top: 11, float: 'right', lineHeight: 0 }}
                    outerDropdownStyle={{marginTop: 0}}>
                </AnDropdownGroupFilter>
            </ConditionalRender>
                <SearchTeamsUtils
                    containerStyle={{marginBottom: '8px'}}
                    fetchTeams={getData}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    placeholder={"Search List Name or Team"}
                >
                </SearchTeamsUtils>
                <div style={{clear: 'both'}}></div>
                <div className='default_flex_container' style={{marginTop: 8}}>
                    <div className="text_subtitle_big">{pageData.totalCount} Syncs</div>
                    <div style={{flex: 1}}>
                        <Pagination totalPages={pageData.totalPages}
                                    currentPage={pageData.currentPage}
                                    onPageChange={getData}
                        />
                    </div>
                    {dashboardType === "teams_list" &&
                        <Button onClick={() => setPopupsList({linkedinSyncUrlPopup: true})}
                            buttonStyle={{width: "min-content"}}
                            innerButtonStye={{padding: "8px 12px"}}
                        >
                            +Add
                        </Button>
                    } 
                </div>
                <div style={{clear: 'both'}}></div>
                <ConditionalRender if={pageData.loadingTeams}>
                    <LoaderComponent isLoading={true}  loaderStyle={{width: 32, margin: '36px auto', display: 'block'}}/>
                </ConditionalRender>
            {
                (dashboardType == 'teams_list') && renderTeamLists()
            }
            {
                (dashboardType == 'sync_status') && renderSyncStatus()
            }
            {popupsList.editSyncUrlsPopup && editSyncUrlsPopup()}
            {popupsList.linkedinSyncUrlPopup && linkedinSyncUrlPopup()}
            {popupsList.addSyncUrlsPopup && addSyncUrlsPopup()}     
            {popupsList.startSyncPopup && startSyncPopup()}     
            {popupsList.confirmationSyncUrlPopup && (
            <AnConfirmPopup
            confirm_message={`Are you sure you want to ${currList.currSyncUrl?.disabled ? 'enable' : 'disable'} this sync`} 
            btnLabels={{submitLabel: 'Yes', cancelLabel: 'No'}}
            onSubmitCallback={() => enableOrDisableSyncUrl()} 
            onCancelCallback={()=> setPopupsList({})}
            ></AnConfirmPopup>)}                   
            {
                successMessages.message && (<FlashMessages messageTheme={successMessages.messageTheme || 'success'} duration={successMessages.duration} closeCallback={onSuccess}>{successMessages.message}</FlashMessages>)
            }
        </div>
    )
}

export default JobUpdatesDashboard
