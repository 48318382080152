import { ProspectListFiltersTabs } from "../../../../utils/AllFilterTabs";
import { indexOfObject } from "../../../../utils/ArrayUtils";

export const initialFiltersState = {
    filtersSelected: {
        tags: [],
        assigns: [],
        type: [],
        location: [],
        company: [],
        title: [],
        college: [],
        miscellaneous: []
    },
    aggregatesData: {
        tags: [],
        assigns: [],
        type: [],
        location: [],
        company: [],
        title: [],
        college: [],
        miscellaneous: []
    },
    showFilterPopup: false,
    activeFilterTab: ProspectListFiltersTabs[0],
    appliedFiltersCount: 0,
};

export const prospectListFilterReducer = (state, action) => {
    switch (action.type) {
        case "ASSIGN_AGGREGATES_DATA":
            return {
                ...state,
                aggregatesData: { ...action.payload.aggregates },
            };
        case "ASSIGN_SPECIFIC_AGGREGATES_DATA":
            let aggregatesData = { ...state.aggregatesData };
            return {
                ...state,
                aggregatesData: {
                    ...aggregatesData,
                    [action.payload.filter_type]: action.payload.aggregates,
                },
            };
        case "APPLY_TEMP_FILTERS": {
            let appliedFiltersCount = Object.keys(
                action.payload.filtersSelected
            ).reduce(
                (prev, curr) =>
                    prev + action.payload.filtersSelected[curr].length,
                0
            );
            return {
                ...state,
                filtersSelected: { ...action.payload.filtersSelected },
                appliedFiltersCount: appliedFiltersCount,
            };
        }
        case "SELECTED_FILTER": {
            let filtersSelected = { ...state.filtersSelected };
            let aggregatesData = state.aggregatesData
            let {mappedKey, selectedVal} = action.payload
            let new_aggs = aggregatesData
            let newFiltersSelected = {
                ...filtersSelected,
                [mappedKey]: action.payload.selections,
            };
            if(action.payload.select){                
                let agg_match_index = aggregatesData[mappedKey].map(agg => agg.term).indexOf(selectedVal)
                let new_aggs_key_val = [...aggregatesData[mappedKey]]
                let spliced = new_aggs_key_val.splice(agg_match_index, 1)
                new_aggs[mappedKey] = [...spliced, ...new_aggs_key_val] 
            }
            return { ...state, 
                filtersSelected: newFiltersSelected, 
                aggregatesData: {...new_aggs}
            };
        }

        case "SELECT_AND_MODIFY": {
            let { mappedKey } = action.payload;
            let insertAt = "PREPEND"
            if(action.payload.insertAt) insertAt = action.payload.insertAt
            let newFiltersSelected = [
                ...(state.filtersSelected[mappedKey] || []),
                action.payload.data.term,
            ];
            let finalFilters = {
                ...state.filtersSelected,
                [mappedKey]: newFiltersSelected,
            };

            let appendedAggregates = []
            if(insertAt == "PREPEND"){
                appendedAggregates = [
                    action.payload.data,
                    ...state.aggregatesData[mappedKey],
                ];
            }else{
                appendedAggregates = [
                    ...state.aggregatesData[mappedKey],
                    action.payload.data,
                ];
            }
            
            let finalAggregates = {
                ...state.aggregatesData,
                [mappedKey]: appendedAggregates,
            };

            return {
                ...state,
                filtersSelected: finalFilters,
                aggregatesData: finalAggregates,
            };
        }

        case "SET_ACTIVE_FILTER_SUB_TAB":
            let {payload} = action;
            let allFilterTabs = payload.allFilterTabs || ProspectListFiltersTabs;
            let index = indexOfObject(allFilterTabs, "tab_key", payload.activeFilterTabKey);
            let activeFilter = allFilterTabs[index];
            return { ...state, activeFilterTab: activeFilter };
        case "SET_POPUP":
            return { ...state, showFilterPopup: action.payload.showable };
        case "CLEAR_SELECTED_FILTERS":
            return {
                ...state,
                filtersSelected: { ...initialFiltersState.filtersSelected },
            };
        case "SET_STATE":
            return {...state, ...action.payload}
        default:
            return { ...state };
    }
};
