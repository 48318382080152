import React, { useEffect, useRef, useState } from 'react'
import AnDropDown from "./AnDropDown.js";
import GreyDownArrow from '../../assets/GreyArrowDown.svg'
import tick2 from '../../assets/tick2.svg'
const AnPostTypeFilter = ({
    option,
    activeOptionStyle={className: "text_body_light"},
    options,
    onChangeCallback,
    customStyle={},
    positionRight,
    label={label: null, className: null},    
    ...props
}) => {
    const [showdropdown, setShowdropdown] = useState(false); 
    const toggleDropDown = () => {setShowdropdown(d => !d)}

    const handleFilter = () => {
        return (
            <AnDropDown
                style={{
                    position: "absolute",
                    right: positionRight ? 'auto' : 0,
                    padding: "14px",
                    background: "white",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                    borderRadius: 4,
                    width: "168px",
                    zIndex: 99,
                    marginTop: 12
                }}
                toggleDropDown={toggleDropDown}
            >
                {
                    options.map((item) => (
                        <div
                            key={item.key}
                            className={ option.key == item.key ? "an_dropdown_filter_small text_body" : "an_dropdown_filter_small text_body_light" }
                            onClick={() => {onChangeCallback(item);toggleDropDown()}}
                        >
                        <span>{item.label}</span>
                        { option.key == item.key && <img src={tick2}></img>}
                        </div>
                    ))
                }
        </AnDropDown>
        )
    }
    return (
        <div style={{position: 'relative', marginLeft: 12, maxWidth: 160, width: '100%', ...customStyle}}>
            <div className='default_flex_container text_body_light filters_button_box'
                style={{marginLeft: 'auto', padding: 8, cursor: 'pointer', position: 'relative', height: 36, border: '1px solid rgba(182, 185, 187, 0.4)', justifyContent: 'space-between', whiteSpace: 'nowrap'}}
                onClick={toggleDropDown}
                >
                <span className={activeOptionStyle.className}>{label.label ? (<span className={label.className} style={label.style}>{label.label}</span>) : ""}{option.label}</span>
                <img src={GreyDownArrow} style={{marginLeft: 4}}/>
            </div>
            {  showdropdown && handleFilter() }
        </div>
    )
}

export default AnPostTypeFilter;