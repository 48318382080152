import React, { useCallback, useEffect, useReducer } from 'react'
import { apiAgent } from '../../../axiosConfig'
import getApiUrl from '../../utils/UrlHelper'
import ConditionalRender from '../../../ConditionalRender';
import { LoaderComponent } from '../../utils/LoaderComponent';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import Greyarrow from "../../../assets/ContinueArrow.svg";
import GreenSearchLens from "../../../assets/GreenSearchLens.svg";

const SavedSearchSpaces = () => {
    let current_user = useSelector(state => state.current_user.user);
    const history = useHistory();
    const [pageData, setPageData] = useReducer(reducer, {
        searchSpaces: [],
        isLoading: {},
        errors: {},        
    })
    const setPageState = (payload) => {
        setPageData({
            type: "SET_STATE",
            payload
        })
    }
    const fetchSearchSpaces = useCallback((page=1) => {
        setPageState({isLoading: {fetchingSpaces: true}})
        apiAgent({
            method: "get",
            url: getApiUrl("get_search_spaces"),
            data: {}
        }).then(res => setPageState({isLoading: {}, searchSpaces: res.data.data})).catch(err => setPageState({isLoading: {}}))
    }, [])
    
    useEffect(() => {
        fetchSearchSpaces();
    }, [fetchSearchSpaces])

    const renderKw = (conj, kws) => {
        return (
            <div>
                <div className='text_subtitle' style={{display: 'inline-block'}}>{conj}</div>
                <div className='text_subtitle_light' style={{display: 'inline-block', marginLeft: 4}}>{kws.join(", ")}</div>
            </div>
        )
    }
    return (
        <>
            <div className='saved_spaces_container'>
                <div className='flex_container_space_between'>
                    <div className='default_flex_container' onClick={() => history.push("/search")} style={{cursor: "pointer"}}>
                        <img src={Greyarrow} style={{width: "24px", height: "24px", marginRight: 8, transform: "rotateY(180deg)"}} alt="<-"></img>
                        <div className='text_title'>Saved searches</div>
                    </div>
                    <Link className='default_flex_container text_link_big' to={"/search"} style={{cursor: "pointer", textDecoration: "none", fontSize: 16}}>
                        <img src={GreenSearchLens} style={{width: "12px", height: "16px", marginRight: 6}} alt="search"></img>
                        <div className=''>New Search</div>
                    </Link>
                </div>
                <div className="line_seperator_ex_light_grey"  style={{ margin: "10px 0px 20px" }}></div>
                <ConditionalRender if={pageData.isLoading.fetchingSpaces}>
                    <LoaderComponent isLoading={true}></LoaderComponent>
                </ConditionalRender>
                    <div style={{display: 'grid', gridTemplateColumns: 'repeat(3, 317px)', gap: "18px"}}>
                    {
                        pageData.searchSpaces.map((sp) => {
                            return(
                                <Link key={sp.id} className='search_space_card' to={{
                                    pathname: `/search/${sp.id}`,
                                    state: {searchSpace: {id: sp.id, mustKeywords: sp.and_keywords, shouldKeywords: sp.or_keywords, mustNotKeywords: sp.and_not_keywords}, from: history.location.pathname}
                                }} 
                                    style={{textDecoration: "none"}}
                                >
                                    <div className="content">
                                        <div className='text_title'>
                                            {current_user.name}
                                        </div>
                                        {sp.and_keywords.length > 0 && renderKw("AND:", sp.and_keywords)}
                                        {sp.or_keywords.length > 0 && renderKw("OR:", sp.or_keywords)}
                                        {sp.and_not_keywords.length > 0 && renderKw("AND NOT:", sp.and_not_keywords)}
                                    </div>
                                    <div className='text_caption_light'>Updated on {sp.last_updated_at}</div>
                                </Link>
                            )
                        })
                    }
                    </div>
            </div>
        </>
    )
}

const reducer = (state, action) => {
    switch(action.type){
        case "SET_STATE": {
            return {...state, ...action.payload}
        }
        default: return state
    }
}

export default SavedSearchSpaces