import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App, { App2 } from './App';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store.js'
import {Provider} from 'react-redux'
import { BrowserRouter } from 'react-router-dom';
import ComponentErrorBoundary from "./BugsnagErrorReporting.jsx";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <App /> */}
      <BrowserRouter>
        <ComponentErrorBoundary>
          <App />
        </ComponentErrorBoundary>      
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
