import almanews_logo from "../../almanews_logo.svg";
import almalogo from "../../assets/alma_logo.png";
const LoggedoutHeader = ({alma_headers_urls, ...props}) => {
    var logo = almanews_logo;
    if(alma_headers_urls.includes(window.location.pathname))
    logo = almalogo;
    return (
        <div className="logout_header">
            <a href="/" className="logout_header_text" style={{margin: "auto", display: "block", width: "max-content"}}>
                <img src={logo} style={{ width: 148, display: "block" }} />
            </a>
        </div>
    );
};

export default LoggedoutHeader;
