import Cookies from "universal-cookie";
import { apiAgent } from "../../axiosConfig";
import { COOKIE_SETTINGS } from "../../config/cookiesSettings";
import {
    teamlLoadError,
    updateCurrentTeam,
    updateTeamLoadingStage,
} from "../../redux/currentTeamSlice";

export const fetchCurrentTeam = (team_id) => async (_dispatch) => {
    _dispatch(updateTeamLoadingStage(true));
    apiAgent({
        method: "get",
        url: "/team_members/team",
        params: {
            team_id: team_id,
        },
    })
        .then((res) => {
            let expiring = (!res.data.team.premium && res.data.team.verified && res.data.team.trial_expiring_on_days >= 0 && res.data.team.trial_expiring_on_days <= 30)
            _dispatch(updateCurrentTeam({...res.data.team, expiring: expiring}));
            _dispatch(updateTeamLoadingStage(false));
        })
        .catch((err) => {
            // Set team id from cookie even if failed to prevent
            _dispatch(teamlLoadError());
            _dispatch(updateTeamLoadingStage(false));
        });
};

export const switchCurrentTeam = (team, fetch_and_update=false) => (_dispatch) => {
    let cookies = new Cookies();
    cookies.set("team_id", team.id, {
        path: "/",
        maxAge: COOKIE_SETTINGS["AUTH_DURATION"],
    });
    if(fetch_and_update){
        _dispatch(fetchCurrentTeam(team.id))
    }else{
        _dispatch(updateCurrentTeam(team));
    }
};
