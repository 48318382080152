import React, {useCallback, useEffect, useMemo, useReducer, useState} from 'react'
import HighConfidenceResults from './high_confidence_results.js';
import LowConfidenceResults from './low_confidence_result.js';
import { initialFiltersState, newsFeedFilterReducer as filterReducer } from '../../admin_views/filters/ProspectBasedFilters/reducer/newsFeedFilterReducer.js';
import FiltersIcon from '../../../assets/FiltersIcon.svg'
import CalendarIcon from '../../../assets/CalendarIcon.svg'
import GreenDot from '../../../assets/GreenDot.svg'
import { initialNewsFeedState, NewsFeedReducer } from './reducers/newsFeedReducer.js';
import { ProspectBasedDateFilter, ProspectBasedFilters } from '../../admin_views/filters/ProspectBasedFilters/filter/ProspectBasedFilters.js';
import ConditionalRender from '../../../ConditionalRender.js';
import { NewsFeedHighConfidenceTabs } from '../../utils/AllFilterTabs.js';
import {apiAgent} from "../../../axiosConfig.js";
import { useSelector } from 'react-redux';
import { NameInitial } from '../../cta_utils/AnNameInitial.js';
import { isWidthDown } from '../../utils/designUtils.js';
import useWindowSize from '../../custom_hooks/useWindowSize.js';
import { Link } from 'react-router-dom';
import TopProspectImage from "../../../assets/top_prospect.svg";
import AnToolTip from '../../cta_utils/AnTooltip.js';
import { applyUrlFilters, getFilterUrl } from '../../utils/ApplyUrlFilters.js';
import MatchingArticleDateFilters from '../../admin_views/filters/ArticleBasedFilters/filters/matchingArticleDateFilters.jsx';
import AnDropdownGroupFilter from '../../cta_utils/AnDropdownGroupfilter.js';
import { PostFilterOptions, TempPostFilterOptions } from '../../utils/PostFilterOptions.js';
import { indexOfObject } from '../../utils/ArrayUtils.js';
import useCheckUserAccess from '../../custom_hooks/useCheckUserAccess.js';

const postTypes = [{label: "All results", key: "all"}, {label: "Only News articles", key: "articles"}, {label: "Only Job updates", key: "Job_Updates"}];

const initFilters = ({...initState}) => {
    return applyUrlFilters(initState, []);
}

const fetchInitialState = (initialState) => {
    const paramsString = new URLSearchParams(window.location.search);
    let type = paramsString.get("post_type");
    type = type ? type.replace(/"/g, "").slice(1, -1) : null;
    let post_type = {...initialState.postType};
    if(type)
    {
        let idx = indexOfObject(postTypes, "key", type);
        post_type = postTypes[idx];
    }
    return {
        ...initialState,
        postType: post_type
    }
}
const initNewsfeedState = ({...initialState}) => {
    return fetchInitialState(initialState);
}

export const NewsFeed = ({
    lowConfidenceUrl='/aggregates',
    highConfidenceUrl='/feed',
    displayFilterSection=true,
    displaySideColumn=true,
    displaySearchBar=false,
    filtersInUrl=true,
    ...props
}) => {
    const [newsFeedState, newsFeedReducer] = useReducer(NewsFeedReducer, initialNewsFeedState, initNewsfeedState)
    const [filtersState, filtersStateReducer] = useReducer(filterReducer , initialFiltersState, initFilters)
    const [teamMembers, setTeamMembers] = useState([]);
    const [topProspects, setTopProspects] = useState([]);
    const current_team = useSelector(state => state.current_team)
    const windowDimensions = useWindowSize();
    const checkUserAccess = useCheckUserAccess();
    const current_user = useSelector(state => state.current_user?.user);

    useEffect(() => {
        if(displaySideColumn) {
            getTeamMembers();
            getTopProspects();
        }
        const paramsString = new URLSearchParams(window.location.search);
        let postsType=paramsString.get("postsType");
        newsFeedReducer({payload: {confidenceType: postsType || 'high'}, type: 'SET_STATE'})
    }, []);
    
    const updateParentState = useCallback((payload) => {
        newsFeedReducer({payload, type: 'SET_STATE'})
    }, [])

    const updateTotalCount = useCallback((high_confidence_results_count, mid_confidence_results_count, low_confidence_results_count) => {
        newsFeedReducer({payload: {high_confidence_results_count: high_confidence_results_count,mid_confidence_results_count: mid_confidence_results_count, low_confidence_results_count: low_confidence_results_count}, type: 'SET_STATE'})
    }, [])

    const switchConfTab = (tabType) => {
        if(filtersState.confidenceType != tabType) filtersStateReducer({payload: {confidenceType: tabType, allFilterTabs: newsFeedState.allFilterTabs}, type: 'RESET_FILTER'})
        newsFeedReducer({payload: {confidenceType: tabType}, type: 'SET_STATE'})
    }
    const allTopProspectUrl = useMemo(() => {let url = getFilterUrl({miscellaneous: ["Top Prospect"]}, "/prospects", ["miscellaneous"], {type: "short"}); return url}, []);

    const renderResultTabs = () => {
        var high = (newsFeedState.confidenceType == "high") ? 'active' : '';
        var mid = (newsFeedState.confidenceType == "mid") ? 'active' : '';
        var low = (newsFeedState.confidenceType == "low") ? 'active' : '';
        var is_mid_enable = current_team.team.displayable_features.display_mid_confidence
        return (
            <div className="newsfeed_tabs">
                <div onClick={() => switchConfTab('high')} className={high} style={{padding: '13px 0px', textAlign: 'center', width: is_mid_enable ? '33.33%' : '50%'}}>
                    <div>High Confidence Results</div>
                    <ConditionalRender if={!isWidthDown(windowDimensions.width, "md") && newsFeedState.high_confidence_results_count > 0}>
                        <div className='text_caption_light' style={{fontWeight: 700}}> ({newsFeedState.high_confidence_results_count})</div>
                    </ConditionalRender>
                </div>
                <ConditionalRender if={is_mid_enable}>
                <div onClick={() => switchConfTab('mid')} className={mid} style={{padding: '13px 0px', textAlign: 'center', width: is_mid_enable ? '33.33%' : '50%'}}>
                    <div>Mid Confidence Results</div>
                    <ConditionalRender if={!isWidthDown(windowDimensions.width, "md") && newsFeedState.mid_confidence_results_count > 0}>
                        <div className='text_caption_light' style={{fontWeight: 700}}> ({newsFeedState.mid_confidence_results_count})</div>
                    </ConditionalRender>
                </div>
                </ConditionalRender>
                <div onClick={() => switchConfTab('low')} className={low} style={{padding: '13px 0px', textAlign: 'center', width: is_mid_enable ? '33.33%' : '50%'}}>
                    <div>Low Confidence Results</div>
                    <ConditionalRender if={!isWidthDown(windowDimensions.width, "md") && newsFeedState.low_confidence_results_count > 0}>
                        <div className='text_caption_light' style={{fontWeight: 700}}> ({newsFeedState.low_confidence_results_count})</div>
                    </ConditionalRender>
                </div>
            </div>
        );
    }
    
    const getTeamMembers = () => {
        apiAgent({
            method: 'get',
            url: '/team/team_members',
            params: {}
        }).then(
            (response) => {
                setTeamMembers(response.data.team_members);
            },
            (error)  => {
            }
        );
    }

    const getTopProspects = () => {
        apiAgent({
            method: 'get',
            url: '/team/top_prospects',
            params: {}
        }).then(
            (response) => {
                setTopProspects(response.data.data);
            },
            (error)  => {
            }
        );
    }

    const renderResult = () => {
        if(newsFeedState.confidenceType === 'low'){
           return(
                <LowConfidenceResults 
                    fetchUrl={lowConfidenceUrl}
                    displayFilterSection={displayFilterSection}
                    filtersState={filtersState} 
                    filtersStateReducer={filtersStateReducer}
                    parentReducer={newsFeedReducer}
                    confidenceType={newsFeedState.confidenceType}
                />
            );
        }
        else if(newsFeedState.confidenceType === 'mid'){
            return(
                <LowConfidenceResults 
                    fetchUrl={lowConfidenceUrl}
                    displayFilterSection={displayFilterSection}
                    filtersState={filtersState} 
                    filtersStateReducer={filtersStateReducer}
                    parentReducer={newsFeedReducer}
                    confidenceType={newsFeedState.confidenceType}
                />
            );
        }
        else if(newsFeedState.confidenceType === 'high'){
            return(
                <HighConfidenceResults
                    fetchUrl={highConfidenceUrl}
                    filtersState={filtersState} 
                    filtersStateReducer={filtersStateReducer}
                    parentReducer={newsFeedReducer}
                    displaySearchBar={displaySearchBar}
                    filtersInUrl={filtersInUrl}
                    newsFeedItems={{dropDownFilterlist: newsFeedState.dropDownFilterlist, allFilterTabs: newsFeedState.allFilterTabs}}
                    displayFilterSection={displayFilterSection}
                    total_count={newsFeedState.high_confidence_results_count}
                />
            );
        }
    }

    if(newsFeedState.leadingError) return <div>{newsFeedState.leadingError}</div>

    let toggleMobile = isWidthDown(windowDimensions.width, "md") && current_team.team.feed_job_updates_enabled
    let show_job_update_filter = newsFeedState.confidenceType === 'high' && current_team.team.feed_job_updates_enabled
    return(
        <div className="newsfeed_main_container" style={{marginTop: 16}}>
            <div className='newsfeed_main_column'>
                {renderResultTabs()}
                {renderResult()}
                {
                    filtersState.showFilterPopup && <ProspectBasedFilters 
                        filtersState={filtersState} 
                        filtersStateReducer={filtersStateReducer} 
                        suggestionUrl='/posts/post_term_search' 
                        tempFilterReducer={filterReducer}
                        allFilterTabs={newsFeedState.allFilterTabs}
                        />
                }
                
            </div>
            <ConditionalRender if={displaySideColumn}>
                <div className="side_column" style={(current_team.team.expiring) ? {top: 138}:{top: 112}}>
                    <div className="team_column" style={{paddingBottom: 16}}>
                        <div className="flex_container_space_between text_title">
                            <div>Team</div>
                            <ConditionalRender if={current_team.team.is_admin}>
                                <a href="/settings/manage_teams" className="text_link hover_under_line">+Invite</a>
                            </ConditionalRender>
                        </div>
                        {
                            teamMembers && teamMembers.length > 0 && teamMembers.slice(0,5).map(member => {
                                return (
                                <div key={member} className="default_flex_container" style={{marginTop: 16}}>
                                    <NameInitial name={member} width={40} textStyle={{fontSize: 16}}>
                                    </NameInitial>
                                    <div className="text_subtitle" style={{marginLeft: 6}}>
                                        {member}
                                    </div>
                                </div>
                            )})
                        }
                        <ConditionalRender if={checkUserAccess("view_all_button")}>
                            { teamMembers.length > 5 && <a className="text_link hover_under_line" style={{marginTop: "6px", display: "flex", justifyContent: "center"}} href="/settings/manage_teams"> View all</a>}
                        </ConditionalRender>
                    </div>
                    {
                        topProspects && topProspects.length > 0 && (
                        <div className="team_column" style={{marginTop: 24}}>
                                <div className="text_title">
                                    <div className='flex_container_space_between'>
                                        <div><img src={TopProspectImage} style={{width: 24, marginRight: 6}}/>Top prospects this week</div>
                                        {/* <a href={allTopProspectUrl} target="_blank" className='text_link'>View all</a> */}
                                    </div>                                    
                                    <div className="text_body" style={{marginTop: 8}}>Prospect featured in news articles higher than their weekly average</div>
                                </div>

                            <div  className="line_seperator_ex_light_grey" style={{ margin: "16px auto" }}></div>
                            {
                                topProspects.map(prospect => {
                                    const name = prospect.name || prospect.company;
                                    const designation = prospect.designation;
                                    return (
                                        <div key={prospect.id} className="default_flex_container" style={{marginTop: 16}}>
                                            <NameInitial name={name} width={40} textStyle={{fontSize: 16}}>
                                            </NameInitial>
                                            <div style={{marginLeft: '16px', marginTop: '3px', width: '216px'}}>
                                                <div className="text_subtitle">
                                                    {name}
                                                    <AnToolTip
                                                        tooltipText={prospect.top_prospect_stat}
                                                        innerClass='text_body_light'
                                                        messageTheme='background-light-black top'
                                                        innerStyle={{
                                                            padding: '4px 8px', 
                                                            marginTop: '4px', 
                                                            color: 'white', 
                                                            marginLeft: -92, 
                                                            width: '200px', 
                                                            whiteSpace: 'break-spaces',
                                                            borderRadius: '8px'
                                                        }}
                                                    >
                                                        <img src={TopProspectImage} style={{marginLeft: 8}}/>
                                                    </AnToolTip>
                                                </div>
                                                {designation && (
                                                    <div className="text_body_light truncate">
                                                        {designation}
                                                    </div>)}
                                            </div>
                                        </div>
                                    )})
                            }
                            <Link to={allTopProspectUrl} className='text_link default_flex_container' style={{marginTop: 16, justifyContent: 'center'}}>View all</Link>
                        </div>)
                    }
                </div>
            </ConditionalRender>
        </div>
    )
}

export default NewsFeed;
