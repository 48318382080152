import ProfileSettings from "../../../../../assets/help_center/profile_setting.png"
import NotificationSetting from "../../../../../assets/help_center/notifications_setting.png"
import AnScrollToLinkPage from "../../../../cta_utils/AnScrollToLinkPage";

export const PersonalSettings = () => {
    return (
        <div style={{ margin: "24px 0px" }}>
            <div className="help_center_top_section"> 
                <div className="text_title_big" style={{marginBottom: 20}}>Personal Settings</div>
                <div style={{border: "1px solid grey", padding: "14px 20px", borderRadius: "4px", fontWeight: "700"}}>
                    <div className="text_body" style={{color: "#707274", fontWeight: 700}}> SKIP AHEAD TO</div>
                    <div style={{marginTop: "12px"}}>
                        <span style={{color: "#437EFE", fontSize: "14px"}}><AnScrollToLinkPage href="#profile"> Profile </AnScrollToLinkPage></span>
                        <span style={{color: "#437EFE", marginLeft: 20, fontSize: "14px"}}><AnScrollToLinkPage href="#notifications"> Notifications  </AnScrollToLinkPage></span>
                    </div>
                </div>
            </div>
            <div className="help_center" id="profile">
                Profile
            </div>

            <div className="help_center_text">
            Under the General section, you can
            <ul style={{marginLeft: -4}}>
                <li>Change your name</li>
                <li>View your email ID</li>
                <li>Change your phone number</li>
                <li>Change your title/position</li>
                <li>View your team name</li>
            </ul>
            </div>
            <img className="help_center_img" src={ProfileSettings} style={{maxWidth: "650px", maxHeight: 391, margin: "auto"}}></img>

            <div className="help_center" id="notifications">
                Notifications   
            </div>
            <div className="help_center_text">
                From the Notifications tab you can access your personal notification:
            </div>
            
            <div className="help_center_text">
            <span style={{fontWeight: 600}} >Notify me about News mentions of -</span> This option allows you to choose when you want to be notified: <br/>
                i. When all the prospects are mentioned <br/>
                ii. Only the ones that you are assigned to
            </div>

            <div className="help_center_text">
            <span style={{fontWeight: 600}} >Email notification frequency of news</span>  - This option allows you to choose the email notification frequency -<br/>
                i. Daily <br/>
                ii. weekly<br/>
                iii. Whenever there are 3 articles or <br/>
                iv. Whenever there are 5 articles. 
            </div>
            <img className="help_center_img" src={NotificationSetting} style={{maxWidth: "645px", maxHeight: "305px", margin: "8px auto"}}></img>


            </div>
    );
};