import {Component, createRef} from "react";
import React from "react";
import { Button } from "../cta_utils/AnButton.js";
import { apiAgent } from "../../axiosConfig.js";
import { AnInputBox } from "../input_utils/AnInput.js";
import { NameInitial } from "../cta_utils/AnNameInitial.js";
import { connect } from "react-redux";
import AnPopup from "../cta_utils/AnPopup";
import AnDropDown from "../cta_utils/AnDropDown";
import MenuDots from "../../assets/MenuDots.svg";
import ViewIcon from "../../assets/ViewIcon.svg";
import SuccessTick from "../../assets/Success_tick.svg"
import TopProspectImage from "../../assets/top_prospect.svg";
import GreyArrowDown from "../../assets/GreyArrowDown.svg";
import ShareIcon from "../../assets/share_on_links/share.png";
import PlusCheckbox from "../../assets/Add_Plus_Circle.svg";
import NewOne from "../../assets/new_one.svg";
import { Link } from "react-router-dom";
import { AnInputRadioButton } from "../input_utils/AnInputRadioButton.js";
import { AnInputCheckBox } from "../input_utils/AnInputCheckBox.js";
import { TextHighlighter } from "../utils/textHighlighter.js";
import { FlashMessages } from "../cta_utils/FlashMessages.js";
import { debounce } from "lodash";
import {
    CommentsDisplay,
    TaggableMentions,
} from "../input_utils/commentMentions.js";
import ConditionalRender from "../../ConditionalRender.js";
import {MENTION_TYPES} from "../utils/AllMentionTypes";
import { LoaderComponent } from "../utils/LoaderComponent.js";
import {ShareOn, getElements } from "../cta_utils/ShareOn.js";
import Mail from "../../assets/share_on_links/mail.png"
import InsightsIcon from "../../assets/increaseGraphIcon.svg"
import LinkedinIcon from "../../assets/LinkedinIcon.png"
import SuitcaseIcon from "../../assets/SuitcaseIcon.png"
import Link_Vertical from "../../assets/share_on_links/Link_Vertical.png"
import { getElementsAtEvent } from "react-chartjs-2";
import { isWidthDown } from "../utils/designUtils.js";
import Seemore from "../utils/SeemoreUtils.js";
import CommentDropdown from "../cta_utils/AnCommentDropdown.js";
import AnToolTip from "../cta_utils/AnTooltip.js";
import ClubbedPost from "./ClubbedPost.jsx";
import { FRONTEND_CONFIG } from "../../configurations/application_config.js";
import AnShortProfile from "../cta_utils/AnShortProfile.js";
import { postTypeLabel } from "../utils/AllLabels.js";
import AnToggleButton from "../cta_utils/AnToggleButton.js";
import AnMultipleSuggestionBox from "../input_utils/AnMultipleSuggestionBox.js";
import AddToFolder from "./addToFolder.js";
import getApiUrl from "../utils/UrlHelper.js";
import { saveOrRemoveFromProfile } from "./PostFeatures.js";
import Tooltip from "../cta_utils/Tooltip.js";
import AddToCrmPopup from "./_AddToCrmPopup.jsx";
import HighConfidenceContext from "../views/news_feed/high_confidence_context.js";
import { JobUpdateDesc } from "./JobUpdateDesc.js";
import AnMultipleSuggestionInputBox from "../input_utils/AnMultipleSuggestionBox.js";
import Icon from "../utils/Icon.js";

var popup_lists = {
    share_via_mail_popup: false,
    share_via_popup: false,
    flag_post_popup: false,
    add_to_folder_popup: false,
    edit_post_popup: false
}

var flag_post_reasons = [
    'I don’t find the content relevant enough',
    'I don’t find the prospect relevant enough',
    'I don’t find the source relevant enough'
]

const shortProfileData = {
    isHovering: true,
    parent: true,
    element: true
}

class Post extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: {
                ...this.props.item,
            },
            expanded: false,
            addComment: false,
            editComment: false,
            comment: {
                markupVal: "",
                plainText: "",
                mentionsList: [],
                comment_id: "",
                prevComment: ""
            },
            shareViaPopup: false,
            share_params: {
                email: "",
                subject: "",
                content: "",
            },
            folder: {
                name: "",
                showFolderPrivacyToggle: false,
                team_members: []
            },
            member_folders: [],
            selected_folders: [],
            popup_lists: popup_lists,
            show_dropdown: false,
            show_assigned_dropdown: false,
            tagsList: this.props.tagsList,
            assigneeList: this.props.assigneeList,
            flag_post_reasons: [],
            customReason: "",
            isOtherReson: false,
            isLoading: false,
            loader: {addtoCRM: false},
            isHovering: false,
            parent: null,
            distance_data: 0,
            currentAggregate: null,
            currentTags: this.props.item.tags.map(tag => tag?.id),
            currentAssigns: this.props.item.assigns.map(assign => assign?.id),
            mentionType: "",
            viewersList: [],
            filtersState: this.props.filtersState || {},
            errors: {},
        };
        this.singlePostRef = createRef();
        this.is_crm_disabled = !(this.state.item.post_type === "Post::JobUpdates" ? this.props.current_team.displayable_features.display_save_jobs_in_crm : this.props.current_team.displayable_features.display_save_news_in_crm)
    }

    componentDidMount() {
        var prospect = this.state.item.tagged_prospects[0];
        var subject = "";
        if(prospect && prospect.name) {
            let label = this.state.item.post_type === 'Post::JobUpdates' ? "'s Job Update" : " in news"
            subject = prospect.name + label;
        }
        var email = "";
        if(this.state.item.assigns && this.state.item.assigns.length > 0) {
            email = this.state.item.assigns.filter(item => this.props.current_team.passive_user_ids.indexOf(item.id) === -1).map(item => item.email).join(", ")
        }
        this.setState({
            share_params: {
                email: email,
                subject: subject
            },
        });
        if(this.props.allPostsRef){
            this.props.allPostsRef.current[this.state.item.id] = this.singlePostRef;
        }
    }
    componentWillUnmount(){
        if(this.props.allPostsRef){
            delete this.props.allPostsRef.current[this.state.item.id];
        }
    }
     
    addCommentSection = () => {
        this.setState({
            addComment: !this.state.addComment,
            editComment: false,
            comment: {markupVal: "",
                    plainText: "",
                    mentionsList: [],
                    comment_id: "",
                    prevComment: ""}
        });
    };

    editCommentSection = (id, prevComment) => {
        this.setState({
            editComment: !this.state.editComment,
            addComment: false,
            comment: {...this.state.comment, comment_id: id, prevComment: prevComment}
        });
    };

    onComment = (event) => {
        event.preventDefault();
        this.setState({
            comment: event.target.value,
        });
    };

    onCommentChange = (event, newVal, plainText, mentionsList) => {
        // event.preventDefault();
        this.setState({
            comment: {
                ...this.state.comment,
                markupVal: newVal,
                plainText: plainText,
                mentionsList: mentionsList,
                prevComment: event.target.value
            },
        });
    };
    addToCrm = () => {
        if(this.state.item.saved_to_crm) return;
        if(this.is_crm_disabled) return;
        if(this.state.item.post_type === "Post::JobUpdates"){
            this.setState({popup_lists: {jobUpdatesCrm: true}})
            return
        }
        this.setState({
            loader: {addtoCRM: true},
        });
        apiAgent({
            method: "post",
            url: `/post/${this.state.item.id}/save_to_crm`,
            data: {
            },
        })
            .then((res) => {
                if(res.data.success) {
                    this.setState({
                        item: {...this.state.item, saved_to_crm: true},
                        loader: {addtoCRM: false},
                        success_message: "Post will be saved to CRM",
                        messageTheme: "success"
                    });
                }
                else{
                    this.setState({
                        loader: {addtoCRM: false},
                        success_message: "Prospect not linked to CRM",
                        messageTheme: "warning"
                    });
                }
            })
            .catch((err) => {});
    }
    addComment = () => {
        if(this.state.comment.markupVal == "")
        {
            this.addCommentSection();
            return;
        }
        let { current_user } = this.props;
        let mentionsList = this.state.comment.mentionsList.map( m => m.id);
        apiAgent({
            method: "post",
            url: `/post/${this.state.item.id}/comment`,
            data: {
                comment: this.state.comment.markupVal,
                mentionsList: mentionsList
            },
        })
            .then((res) => {
                let temp = [...this.state.item.comments, {
                    comment_id: res.data.comment_id,
                    comment: this.state.comment.markupVal,
                    user_name: current_user.name,
                }]
                this.setState({
                    item: {...this.state.item, comments: temp},
                    comment: {markupVal: "",
                            plainText: "",
                            mentionsList: [],
                            comment_id: "",
                            prevComment: ""},
                    addComment: false,
                });
            })
            .catch((err) => {});
    };

    editComment = () => {
        if(this.state.comment.markupVal == "")
        {
            this.editCommentSection();
            return;
        }
        let { current_user } = this.props;
        let mentionsList = this.state.comment.mentionsList.map( m => m.id);
        apiAgent({
            method: "post",
            url: `/post/${this.state.item.id}/edit_comment`,
            data: {
                id: this.state.comment.comment_id,
                edited_comment: this.state.comment.markupVal,
                mentionsList: mentionsList
            },
        })
            .then((res) => {
                let temp2 = [...this.state.item.comments]
                let temp = temp2.map(comment => {
                   if(comment.comment_id == this.state.comment.comment_id)
                   comment.comment=this.state.comment.markupVal
                    return comment
                })
                this.setState({
                    item: {...this.state.item, comments: temp},
                    comment: {markupVal: "",
                            plainText: "",
                            mentionsList: [],
                            comment_id: "",
                            prevComment: ""},
                    editComment: false,
                });
            })
            .catch((err) => {});
    };

    deleteComment = (id) => {
        let { current_user } = this.props;
        apiAgent({
            method: "post",
            url: `/post/${this.state.item.id}/delete_comment`,
            data: {
                id: id
            },
        })
            .then((res) => {
                let temp2 = [...this.state.item.comments]
                let temp = temp2.filter(comment => {
                    return comment.comment_id != id
                })
                this.setState({
                    item: {...this.state.item, comments: temp},
                    comment: {markupVal: "",
                            plainText: "",
                            mentionsList: [],
                            comment_id: "",
                            prevComment: ""},
                    editComment: false,
                });
            })
            .catch((err) => {});
    };

    addOrRemoveLike = () => {
        let like_status = this.state.item.liked;
        apiAgent({
            method: "post",
            url: `/post/${this.state.item.id}/like_update`,
            params: {
                isAlreadyLiked: like_status,
            },
        })
            .then((res) => {
                this.setState({
                    item: { ...this.state.item, liked: !like_status },
                });
            })
            .catch((err) => {});
    };
    fetchViewDetails = () => {
        this.setState({ isLoading: true });
        apiAgent({
            method: "get",
            url: "/posts/post_view_details",
            params: {
                post_id: this.state.item.id
            }
        }).then( res => {
            this.setState({isLoading: false, viewersList: res.data.data, item: {...this.state.item, view_count: res.data.data.length}})
        }).catch(err => this.setState({isLoading: false}))
    }
    share_via_mail = () => {
        this.setState({ isLoading: true });
        apiAgent({
            method: "post",
            url: `/post/${this.state.item.id}/share_via_mail`,
            params: this.state.share_params,
        })
            .then((res) => {
                this.setState({
                    isLoading: false,
                    success_message: "Email sent",
                    messageTheme: "success"
                });
                this.close_popups();
            })
            .catch((err) => {});
    };

    share_via_params = (type, value) => {
        var share_params = { ...this.state.share_params };
        share_params[type] = value;
        this.setState({ share_params });
    };

    editPost = () => {
        let errors = {}
        if(this.state.taggedProspects.length === 0){
            errors["edit_post_error"] = "Please tag atleast one prospect related to this post";
            this.setState({errors: errors});
            return;
        }
        this.setState({ isLoading: true, errors: errors });
        apiAgent({
            method: "post",
            url: "/post/" + this.state.item.id + "/update_description",
            data: {
                tagged_prospect_ids: this.state.taggedProspects.map((tp) => tp.id),
                mentioned_for: this.state.item.mentioned_for,
                article_description: this.state.item.shared_link.description,
                interests: this.state.item.interests
            },
        })
            .then((res) => {
                this.setState({ 
                    isLoading: false, 
                    item: {...res.data}, 
                    success_message: "Post saved successfully",
                    messageTheme: "success"
                 });    
                this.close_popups();   
                this.setState({ show_dropdown: false })        
                // window.location.reload(); //Pending: To be imporved, Maybe resend the prospet list here itself
            })
            .catch((err) => {
                this.setState({ isLoading: false });
            });
    };

    discardPost = () => {
        if (!window.confirm("Are you sure you want to delete this post?"))
            return;
        this.setState({ isLoading: true });
        apiAgent({
            method: "post",
            url: "/post/" + this.state.item.id + "/discard",
            data: {page: this.props.currentPage},
        })
            .then((res) => {
                this.setState({ isLoading: false });
                if(typeof this.props.discardCallBack === 'function')
                this.props.discardCallBack(this.state.item.id, res.data)
                else
                window.location.reload(); //Pending: To be imporved, Maybe resend the prospet list here itself
            })
            .catch((err) => {
                this.setState({ isLoading: false });
            });
    };

    deleteAndUnmapPost = () => {
        if (!window.confirm("Are you sure you want to delete and unmap this post?"))
            return;
        this.setState({ isLoading: true });
        apiAgent({
            method: "post",
            url: "/post/" + this.state.item.id + "/delete_and_unmap",
            data: {},
        })
            .then((res) => {
                this.setState({ isLoading: false });
                window.location.reload(); //Pending: To be imporved, Maybe resend the prospet list here itself
            })
            .catch((err) => {
                this.setState({ isLoading: false });
            });
    }

    onAddFlagPostReason = () => {
        this.setState({ isLoading: true });
        let res = [...this.state.flag_post_reasons]
        if(this.state.customReason)
        res.push(this.state.customReason)
        apiAgent({
            method: "post",
            url: `/post/${this.state.item.id}/flag`,
            data: {
                flagged_reasons: res
            },
        })
            .then((res) => {
                this.setState({
                  isLoading: false,
                  success_message: `News article flagged`,
                  messageTheme: "success",
                  show_dropdown: false,
                  customReason: '',
                  flag_post_reasons: []
                });
                this.close_popups();
            })
            .catch((err) => {
                this.setState({ isLoading: false });
            });
    };

    handleTagsChange = (e) => {
        let val = e.target.value;
        let index = this.state.currentTags.indexOf(val);
        let temp = this.state.currentTags;
        if (index == -1) {
            temp.push(val);
        } else {
            temp.splice(index, 1);
        }
        this.setState({ currentTags: temp });
    };

    handleAssignsChange = (e) => {
        let val = e.target.value;
        let index = this.state.currentAssigns.indexOf(val);
        let temp = this.state.currentAssigns;
        if (index == -1) {
            temp.push(val);
        } else {
            temp.splice(index, 1);
        }
        this.setState({ currentAssigns: temp });
    };

    handleFlagReasonsChange = (e) => {
        let val = e.target.value;
        let temp = [...this.state.flag_post_reasons];
        let index = this.state.flag_post_reasons.includes(val)
        if (!index) {
            temp.push(val);
        }
        else{
            temp.splice(index, 1);
        }
        this.setState({ flag_post_reasons: temp });
    };

    handleClubExpansion = () => {
        this.setState(state => ({expanded: !state.expanded}))
    }

    openPopup = (name) => {
        var temp_popup_lists = {...popup_lists};
        temp_popup_lists[name] = true;
        this.setState({ popup_lists: temp_popup_lists });
    };

    close_popups = () => {
        this.setState({
            popup_lists: popup_lists,
            isOtherReson: false,
        });
    };

    openViewersDetailPopup = (e) => {
        if (this.state.viewersList.length == 0)
            this.fetchViewDetails()
        this.setState({ popup_lists: { "viewDetailsPopup": true } })

        e.stopPropagation();
    }

    setArticleDescription = (value) => {
        var item = { ...this.state.item };
        item.shared_link.description = value;
        this.setState({ item: item });
    };

    setComponentState = (property, value) => {
        if(shortProfileData[property]){
            this.setState({ [property]: value });
        }
    }

    render_assigned_dropdown = () => {
        return (
            <AnDropDown
                style={{
                    position: "absolute",
                    right: 0,
                    padding: "16px",
                    background: "white",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                    borderRadius: 4,
                    width: "max-content",
                    zIndex: 99,
                }}
                toggleDropDown={() => this.setState({ show_assigned_dropdown: false })}
            >
                {this.state.item.assigns.map((assign, index) => {
                    if((index > 0) && assign.name)
                        return (<div className="text_body" style={(index === this.state.item.assigns.length - 1) ? {cursor: 'pointer'} : {marginBottom: 16, cursor: "pointer"}} onClick={() => this.openPopup("share_via_mail_popup")}>{assign.name.split(" ")[0]}</div>);
                })}
            </AnDropDown>
        )
    }
    setStateFromChild = (payload) => {
        this.setState(payload);
    }

    renderProspectDropdown = () => {
        return (
            <AnDropDown
                style={{
                    position: "absolute",
                    right: 0,
                    padding: "20px",
                    background: "white",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                    borderRadius: 4,
                    width: "max-content",
                    zIndex: 99,
                }}
                toggleDropDown={() => this.setState({ show_dropdown: false })}
            >
                <ConditionalRender if={this.props.checkUserAccess("post_editing") && this.state.item.post_type !== "Post::JobUpdates"}>
                <div
                    style={{ marginBottom: 16, cursor: "pointer" }}
                    onClick={() => {this.setState({taggedProspects: this.state.item.tagged_prospects}, () => {this.openPopup("edit_post_popup")})}}
                >
                    Edit Post
                </div>
                </ConditionalRender>
                <ConditionalRender if={this.props.checkUserAccess("add_to_folder")}>
                <div
                    style={{ marginBottom: 16,  cursor: "pointer" }}
                    onClick={() => { this.openPopup("add_to_folder_popup");}}
                >
                    Add to folder
                </div>
                </ConditionalRender>
                <div
                    style={{ marginBottom: 16, cursor: "pointer" }}
                    onClick={()=>saveOrRemoveFromProfile({setState: this.setStateFromChild, parentProps: this.props, item: this.state.item})}
                >
                    {this.state.item.is_saved ? 'Remove from Profile' : 'Save to Profile'}
                </div>
                <ConditionalRender if={this.props.checkUserAccess("post_deleting")}>
                <div
                    style={{ marginBottom: 16, cursor: "pointer" }}
                    onClick={() => this.discardPost()}
                >
                    Delete Post
                </div>
                </ConditionalRender>
                <ConditionalRender if={this.props.checkUserAccess("post_deletion_and_unmap") && this.state.item.post_type == "Post::JobUpdates"}>
                    <div
                        style={{ marginBottom: 16, cursor: "pointer" }}
                        onClick={() => this.deleteAndUnmapPost()}
                    >
                        Delete And Unmap
                    </div>
                </ConditionalRender>
                <div
                    style={{ marginBottom: 16,  cursor: "pointer" }}
                    onClick={() => this.openPopup("share_via_popup")}
                >
                    Share
                </div>
                <ConditionalRender if={this.props.checkUserAccess("post_flagging")}>
                <div
                    style={{ cursor: "pointer" }}
                    onClick={() => this.openPopup("flag_post_popup")}
                >
                    Flag Post
                </div>
                </ConditionalRender>
            </AnDropDown>
        );
    };


    updatesSelectedFolders = () => {
        if(!this.props.memberFolders) return
        const updatedFoldersData = this.props.memberFolders.map(folder => {
            if (folder.tagged_post_ids.includes(this.state.item.id)) {
                return {
                    ...folder,
                    selected_folder: true
                };
            } else {
                return {
                    ...folder,
                    selected_folder: false
                };
            }
        });
        this.props.pageDataReducer({type: 'SET_STATE', payload: {
            memberFolders: updatedFoldersData
        }});
    }

    updateReview = () => {
        if(this.state.item.reviewed_at) return;
        apiAgent({
            method: "post",
            url: getApiUrl("post_review_update", {":post_id": this.state.item.id}),
            params: {
            },
        })
            .then((res) => {

                this.setState({
                    item: { ...this.state.item, reviewed_at: res.data.reviewed_at, reviewed_by: res.data.reviewed_by },
                    success_message: "Post marked as reviewed", 
                    messageTheme: "success"
                });
            })
            .catch((err) => {});
    }

    renderSimilarAticles = () => {
        if(!(this.state.item.similar_articles_text && this.state.item.similar_articles_text !== "")) return <></>;
        return(
            <>
                <div className="text_caption" style={{ marginTop: "12px" }}>
                    Also featured in{" "}
                    {this.state.item.similar_articles_text}.{" "}
                    <Link className="text_link_small"
                        to={{
                            pathname: `/${this.state.item.id}/similar_articles`,
                            state: {filtersState: this.state.filtersState, from: window.location.pathname}
                        }}
                    >
                        View articles
                    </Link>
                </div>
            </>
        )
    }
    
    renderPostCTAs = () => {
        if(this.props.simpleView) return <></>
        return(
            <div className="article_bottom_section flex_container_space_between">
                {/*<img src={this.state.item.liked ? LikeFilled : LikeUnfilled} title={this.state.item.liked ? 'Unlike this article' : 'Like this article'} onClick={this.addOrRemoveLike} style={{float: 'left', marginRight: '8px', width: 32, height: 32, cursor: 'pointer'}} />*/}
                <div className="default_flex_container">
                    <div className="icon_box_container_hover flex_center" style={{width: 36, height: 36}} onClick={this.addCommentSection}>
                        <Icon name="CommentIcon"/>
                    </div>
                    <div className="icon_box_container_hover flex_center" style={{width: 36, height: 36, marginLeft: 8}} onClick={() => this.openPopup("share_via_mail_popup")}>
                        <Icon name="ShareIcon"/>
                    </div>
                    <Tooltip
                        tooltipText={() => <><span style={{fontWeight: 600}}>Saved by </span><span style={{fontWeight: 700}}>{this.state.item.saved_to_crm_by}</span></>}
                        innerClass='text_body_light'
                        textTheme='background-light-black top'
                        innerStyle={{ padding: '8px', alignSelf: 'center', color: 'white', borderRadius: '8px'}}
                        style={{}}
                        showWithoutOverflowing={true}
                        tooltipPos="top"
                        enableTooltip={this.state.item.saved_to_crm_by && this.state.item.saved_to_crm}
                    > 
                    <div className={(this.is_crm_disabled || this.state.item.saved_to_crm ? "icon_box_container" : "icon_box_container_hover") + " flex_center"} 
                        style={{ marginLeft: 8, width: 'max-content', width: 67, height: 36, cursor: this.is_crm_disabled ? 'not-allowed' : 'pointer'}} 
                        onClick={this.addToCrm}>              
                        <div className="default_flex_container" style={{ opacity: this.is_crm_disabled ? '50%' : '1'}}>
                            <div className="flex_center" style={{width: 24, height: 24}}><Icon name="CRM" style={{fill: this.state.item.saved_to_crm ? '#707274' : 'none'}}/></div>
                            <div className="text_caption">CRM</div>
                        </div> 
                    </div>
                    </Tooltip>
                </div>
                <div className="default_flex_container">
                <div className="icon_box_container_hover flex_center" style={{width: 46, height: 36, marginLeft: 8}} onClick={this.openViewersDetailPopup}>
                   <div className="flex_center"><Icon name="View"/></div>
                   <ConditionalRender if={this.state.item.view_count > 0}>
                   <div className="text_caption" style={{marginLeft: 2}}>{this.state.item.view_count}</div>
                   </ConditionalRender>
                </div>
                <Tooltip
                        tooltipText={() => 
                        <>
                        <span style={{fontWeight: 600}}>Reviewed by </span>
                        <span style={{fontWeight: 700}}>{this.state.item.reviewed_by}<br/></span>
                        <span style={{fontWeight: 600}}> on </span>
                        <span style={{fontWeight: 700}}>{this.state.item.reviewed_at}</span>
                        </>}
                        innerClass='text_body_light'
                        textTheme='background-light-black top'
                        innerStyle={{ padding: '8px', alignSelf: 'center', color: 'white', borderRadius: '8px'}}
                        showWithoutOverflowing={true}
                        tooltipPos="top"
                        enableTooltip={this.state.item.reviewed_by}
                    >
                <div className={(this.state.item.reviewed_by ? "icon_box_container" : "icon_box_container_hover") + " flex_center"} style={{width: 36, height: 36, marginLeft: 8}} onClick={this.updateReview}>
                    {this.state.item.reviewed_at ? <Icon name="Reviewed"/> : <Icon name="Review"/>}
                </div>
                </Tooltip>
                </div>
            </div>
        )
    }

    renderAddCommentSection = () => {
        let { current_user } = this.props;
        if (this.state.addComment) {
            return (
                <div className="add_comment_section" style={{marginTop: 16}}>
                    <div style={{ marginRight: "8px" }}>
                        <NameInitial
                            name={current_user.name}
                            width={36}
                            textStyle={{ fontSize: 16 }}
                        />
                    </div>
                    <TaggableMentions
                        value={this.state.comment.markupVal}
                        onChange={this.onCommentChange}
                        style={{ width: "100%"}} //TODO: provide support for standard classNames as well as inline style in future
                        placeholder="Add your comment"
                    ></TaggableMentions>
                    <Button
                        onClick={this.addComment}
                        buttonStyle={{ padding: "8px 0px 8px 22px" }}
                        innerButtonStye={{ padding: "9px 23px" }}
                        buttonSize="Medium"
                    >
                        Comment
                    </Button>
                </div>
            );
        } else {
            return <div></div>;
        }
    };

    renderEditCommentSection = () => {
        let { current_user } = this.props;
        if (this.state.editComment) {
            return (
                <div className="add_comment_section" style={{marginLeft: 16, width: '100%'}}>
                    <TaggableMentions
                        value={this.state.comment.prevComment}
                        onChange={this.onCommentChange}
                        style={{ width: "100%"}} //TODO: provide support for standard classNames as well as inline style in future
                        placeholder={"Add your comment"}
                    ></TaggableMentions>
                    <Button
                        onClick={this.editComment}
                        buttonStyle={{ padding: "8px 0px 8px 22px" }}
                        innerButtonStye={{ padding: "9px 23px" }}
                        buttonSize="Medium"
                    >
                        Comment
                    </Button>
                </div>
            );
        } else {
            return <div></div>;
        }
    }
    renderComments = () => {
        let comments = this.state.item.comments;
        if (this.state.item.comments.length > 0) {
            return (
                <>
                    <div style={{ marginTop: "16px" }}>
                        {comments.map((comment, index) => (
                            <div key={comment.comment_id} style={{ marginTop: "12px", display: "flex", alignItems: 'center' }}>
                                <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                                <div>
                                <NameInitial
                                    name={comment.user_name}
                                    width={36}
                                    textStyle={{ fontSize: 16 }}
                                />
                                </div>
                                { this.state.editComment  &&
                                    this.state.comment.comment_id==comment.comment_id ? this.renderEditCommentSection() 
                                    :
                                    <div className="comment_item" style={{ marginLeft: "8px", display: 'flex', flexDirection: 'row'}}>
                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                            <div className="text_caption_bold">
                                                {comment.user_name}
                                            </div>
                                            <CommentsDisplay markupedText={comment.comment}></CommentsDisplay>
                                        </div>
                                    </div>
                                    
                                }
                                </div> 
                                <ConditionalRender if={this.props.current_team.is_admin || this.props.current_user.name==comment.user_name}>
                                   {!this.state.editComment && <CommentDropdown 
                                        commentID={comment.comment_id}
                                        prevComment={comment.comment}
                                        value={this.state.comment.markupVal}
                                        editCommentSection={this.editCommentSection}
                                        deleteComment={this.deleteComment}
                                    >   
                                    </CommentDropdown>
                                    }
                                </ConditionalRender>
                            </div>
                        ))}
                    </div>
                </>
            );
        } else return <div></div>;
    };

    renderCommentsSection = () => {
        return(
            <>
                {this.renderAddCommentSection()}
                {this.renderComments()}
            </>
        )
    }

    editPostPopup = () => {
        return (
            <AnPopup
                name="editPostPopup"
                handleOnClosePopup={() => this.close_popups()}
                maxWidth={600}
            >
                <div className="text_title" style={{ marginBottom: "24px" }}>
                    Edit Post
                </div>
                <AnMultipleSuggestionInputBox
                    label="Add prospects related to news article"
                    inputClass="an_search_box"
                    placeholder="Search Prospects"
                    suggestionUrl="/prospects/search?team_search=true"
                    selectedOptions={this.state.taggedProspects}
                    callback={(prospects) => {this.setState({taggedProspects: prospects})}}
                    style={{ margin: "16px 0px 0px" }}
                    theme="modern_selected"
                ></AnMultipleSuggestionInputBox>
                <div className="text_body">
                    <span className="text_body_light">Mentioned for:</span>{" "}
                </div>
                {MENTION_TYPES.map((mention_type) => {
                    let is_checked = this.state.item.mentioned_for === mention_type.value;
                    return (
                        <>
                            <AnInputRadioButton
                                label={mention_type.text}
                                style={{ marginTop: 12 }}
                                isChosen={is_checked}
                            >
                                <input
                                    type="radio"
                                    id={mention_type.text}
                                    style={{ width: "16px", height: "16px" }}
                                    name="mention"
                                    value={mention_type.value}
                                    checked={is_checked}
                                    onChange={() => {
                                        this.setState({
                                            item: {
                                                ...this.state.item,
                                                mentioned_for: mention_type.value,
                                            },
                                        });

                                    }}
                                />
                            </AnInputRadioButton>
                        </>
                    );
                })}
                {this.state.item.mentioned_for && (
                    <div
                        style={{ marginTop: "16px", padding: "10px" }}
                        className="text_body news_container"
                    >
                        {this.state.item.mentioned_for}
                    </div>
                )}
                <AnInputBox label={"Interests"} style={{margin: '16px 0px'}}>
                    <input type="text" id="interests" value={this.state.interests} onChange={(e) => this.setState({
                        item: {
                            ...this.state.item,
                            interests: e.target.value
                        }
                    })} placeholder="Folder name"/>
                </AnInputBox>
                <div
                    style={{
                        margin: "16px 0px",
                        borderTop: "0.5px solid #B6B9BB",
                    }}
                ></div>
                <div className="text_body_light">Article Link: </div>
                <div
                    className="text_body news_container"
                    style={{ marginTop: "6px", padding: "10px" }}
                >
                    {this.state.item.shared_link.url}
                </div>
                <AnInputBox label={"Description"} style={{ marginTop: "16px" }}>
                    <textarea
                        type="text"
                        id="metaDescription"
                        value={this.state.item.shared_link.description}
                        onChange={(e) => {
                            this.setArticleDescription(e.target.value);
                        }}
                    ></textarea>
                </AnInputBox>
                <div className="text_body" style={{ marginTop: "16px" }}>
                    <div
                        style={{ marginBottom: "8px" }}
                        className="text_body_light"
                    >
                        Article Preview:
                    </div>
                    <a
                        href={this.state.item.shared_link.url}
                        target="_blank"
                        className="news_container"
                    >
                        { this.state.item.shared_link.image_url &&
                        <div style={{ width: "144px", float: "left" }}>
                            <img
                                src={this.state.item.shared_link.image_url}
                                className="post_image"
                            />
                        </div>
                        }
                        <div style={{
                            width: this.state.item.shared_link.image_url? "calc(100% - 144px)" : 'auto',
                            float: this.state.item.shared_link.image_url? "right": "left"
                            }}>
                            <div
                                className="text_subtitle_big"
                                style={{ marginBottom: "4px" }}
                            >
                                {this.state.item.shared_link.title}
                            </div>
                            <div className="text_body_light">
                                {this.state.item.shared_link.description}
                            </div>
                        </div>
                        <div style={{ clear: "both" }}></div>
                    </a>
                </div>
                <Button
                    onClick={() => {
                        this.editPost();
                    }}
                    isLoading={this.state.isLoading}
                    buttonStyle={{ marginTop: "16px" }}
                    error={this.state.errors?.edit_post_error}
                >
                    Edit Post
                </Button>
            </AnPopup>
        );
    };

    postNotifySection = () =>{
        if(this.state.item.assigns && this.state.item.assigns.length > 0) {
            return (
                <div
                    style={{
                        alignSelf: "start",
                        marginRight: 16,
                    }}
                >
                    <span className="text_body_light">
                        Notify{" "}
                    </span>
                    <span className="text_subtitle">
                        <span className="hover_under_line" onClick={() => this.openPopup("share_via_mail_popup")}>{this.state.item.assigns[0].name.split(" ")[0]}</span>
                        {(this.state.item.assigns.length > 1) && (
                            <span className="text_link" style={{paddingLeft: 4, position: 'relative'}} onClick={() =>this.setState({ show_assigned_dropdown: true })}>
                                 +{(this.state.item.assigns.length - 1)}
                                {this.state.show_assigned_dropdown && this.render_assigned_dropdown()}
                            </span>
                        )}
                    </span>
                </div>
            );
        }
        else
            return(<div></div>);
    }

    viewDetailsPopup = () => {
        return(
            <AnPopup
                name="viewDetailsPopup"
                handleOnClosePopup={() => this.close_popups()}
                maxWidth={400}
            >
            <div className="text_subtitle_big">
                {this.state.item.view_count} view{this.state.item.view_count > 1 ? "s" : ""}
            </div>
            <div className="line_seperator_ex_light_grey" style={{marginTop: 16}}></div>
            <LoaderComponent isLoading={this.state.isLoading}></LoaderComponent>
            <ConditionalRender if={!this.state.isLoading}>
            {this.state.item.view_count === 0 ? 
            (
                <div className="text_body_big_light_semi_bold" style={{lineHeight: '24px', margin: '54px auto', textAlign: 'center'}}>
                    You are the first to view this post
                </div>
            ):
                this.state.viewersList.map(viewer => (
                    <div className="default_flex_container" key={viewer.id} style={{marginTop: "16px"}}>
                        <NameInitial name={viewer.name}></NameInitial>
                        <div className="text_subtitle" style={{marginLeft: "6px"}}>
                            {viewer.name}
                        </div>
                    </div>
                ))
            }
            </ConditionalRender>
            </AnPopup>
        )
    }

    handleHover = (event) => {
        const loginHeader = document.querySelector(".login_header");
        const element = event.target;
        
        let id = element.parentElement.id === ("NameText" + this.state.item.id)? "NameText" + this.state.item.id : "NameInitial"+ this.state.item.id;
        let outermostDiv = document.getElementById(id);
        
        if (element && loginHeader) {
          const elementRect = element.getBoundingClientRect(); // ignore this use only for 
          const outermostRect = outermostDiv.getBoundingClientRect();
          const loginHeaderRect = loginHeader.getBoundingClientRect();

          this.setComponentState("element", {hoverDiv: outermostRect, scrollY: window.scrollY, loginHeaderRect: loginHeaderRect});
          
          const top_distance = elementRect.top - loginHeaderRect.bottom;
          const bottom_distance = window.innerHeight - elementRect.bottom;
      
          let distance_data = this.state.parent === "NameText"
            ? { dTop: top_distance + 4, dBottom: bottom_distance }
            : { dTop: top_distance, dBottom: bottom_distance };
      
          this.setState({
            distance_data,
            isHovering: true
          });
        }
    } 

    renderProspect = () => {
        const job_update = this.state.item;
        let { current_user } = this.props;
        const { shortProfileFields } = this.context || { shortProfileFields: { prospectFields: [], fieldsFetched: false } };
        let prospectFields = [], fieldsFetched = false;
        if(shortProfileFields){
            prospectFields = shortProfileFields.prospectFields;
            fieldsFetched = shortProfileFields.fieldsFetched;
        }

        if (this.state.item.tagged_prospects.length > 0) {
            const [tagged_prospect] = this.state.item.tagged_prospects;
            const name = tagged_prospect.name || tagged_prospect.company;
            return (
                <>  
                    <ConditionalRender if={tagged_prospect.article_insight}>
                        <div className="default_flex_container insights_container" style={{marginBottom: "12px"}}>
                            <img src={InsightsIcon} style={{width: '16px', marginRight: "8px"}} />
                            <span className="text_caption" style={{marginRight: "6px"}}>{tagged_prospect.article_insight}</span>
                            <Link to={`/prospect_articles/${tagged_prospect.id}`} 
                                className="text_link_small hover_under_line">View</Link>
                        </div>
                    </ConditionalRender>
                    <div className="flex_container_space_between">
                        <div style={{position: "relative", borderRadius: 8}}                        
                        >
                            <div className="default_flex_container" style={{flexWrap: "wrap-reverse", alignItems: "flex-end"}}>
                                    <Link
                                        to={{
                                            pathname: "/prospects/" + tagged_prospect.id,
                                            state: {filtersState: this.state.filtersState, from: window.location.pathname}
                                        }}
                                        className="default_flex_container"
                                        style={{
                                            alignItems: 'flex-start',
                                            textDecoration: "none",
                                            position: "relative"
                                        }}
                                    >
                                        <div id={"NameInitial"+ this.state.item.id} onMouseOver={(event) => {this.setState({ parent: "NameInitial" });this.handleHover(event);}}
                                            onMouseLeave={() => this.setComponentState("isHovering", false)}
                                        >
                                            <NameInitial
                                                name={name}
                                                profile_image={tagged_prospect?.profile_image}
                                            /> 
                                        </div>
                                    </Link>
                                    {this.state.parent === "NameInitial" && (prospectFields && prospectFields.length >0) &&
                                        <div>
                                            <AnShortProfile 
                                                prospect={tagged_prospect} 
                                                isHovering={this.state.isHovering}
                                                setComponentState={this.setComponentState}
                                                parent={this.state.parent}
                                                element={this.state.element}
                                                parentDistance={this.state.distance_data}
                                                post={this.state.item}
                                            />
                                        </div>
                                    }
                                                      
                                        <div className="feed_prospect">
                                            <div className="default_flex_container">
                                                <Link
                                                    to={{
                                                        pathname: "/prospects/" + tagged_prospect.id,
                                                        state: {filtersState: this.state.filtersState, from: window.location.pathname}
                                                    }}
                                                    className="default_flex_container"
                                                    style={{
                                                        alignItems: 'flex-start',
                                                        textDecoration: "none",
                                                        position: "relative"
                                                    }}
                                                >
                                                    <div id={"NameText"+ this.state.item.id} onMouseOver={(event) => {this.setState({ parent: "NameText" });this.handleHover(event);}}
                                                        onMouseLeave={() => this.setState({isHovering: false})} style={{backgroundColor: "transparent"}}
                                                    >
                                                        <div className="text_subtitle_big make_visible" style={{whiteSpace: "nowrap", textDecoration: (this.state.isHovering && current_user.is_admin && fieldsFetched)? "underline" : "none"}}>
                                                            {name}
                                                        </div>
                                                    </div>
                                                </Link>
                                                {this.state.parent === "NameText" && prospectFields.length > 0 && 
                                                    <div>
                                                        <AnShortProfile 
                                                            prospect={tagged_prospect} 
                                                            isHovering={this.state.isHovering}
                                                            setComponentState={this.setComponentState}
                                                            parent={this.state.parent}
                                                            element={this.state.element}
                                                            parentDistance={this.state.distance_data}
                                                            post={this.state.item}
                                                        />
                                                    </div>
                                                }
                                                <Link
                                                    to={{
                                                        pathname: "/prospects/" + tagged_prospect.id,
                                                        state: {filtersState: this.state.filtersState, from: window.location.pathname}
                                                    }}
                                                    className="default_flex_container"
                                                    style={{
                                                        alignItems: 'flex-start',
                                                        textDecoration: "none",
                                                        position: "relative",
                                                        overflow: "hidden",
                                                        color: "#B6B9BB"
                                                    }}
                                                >
                                                    {this.state.item.post_type === "Post::JobUpdates" ? 
                                                        <div className="default_flex_container text_caption" style={{marginLeft: 6, alignItems: 'end'}}>via 
                                                            <a href={job_update.linkedin_url} 
                                                               target="_blank"
                                                               style={{marginLeft: 3}}
                                                               onClick={(e) => e.stopPropagation()}
                                                            >
                                                                <img src={LinkedinIcon}></img>
                                                            </a>
                                                        </div>
                                                    :
                                                    <Tooltip
                                                    tooltipText={() => this.state.item.tags?.map((tag, i) => <span key={tag.id} style={{textTransform: 'uppercase'}}>{tag.name}{((i + 1) !== this.state.item.tags?.length) ? (i + 1) % 3 === 0 ? <br /> : ", " : ""}</span>)}
                                                    innerClass='text_body_light'
                                                    textTheme='background-light-black bottom'
                                                    innerStyle={{ padding: '8px', alignSelf: 'center', color: 'white', borderRadius: '8px'}}
                                                    >
                                                    <div className="truncate" style={{marginLeft: 16 }}>
                                                        {this.state.item.tags?.map((tag) => <span key={tag.id} className="text_caption_small_light" style={{textTransform: 'uppercase', marginRight: 8 }}>{tag.name}</span>)}
                                                    </div>
                                                    </Tooltip>
                                                    }
                                                </Link>
                                            </div>
                                            <ConditionalRender if={this.state.item.tagged_prospects[0].name}>
                                                <Tooltip
                                                    tooltipText={() => this.state.item.tagged_prospects[0].designation}
                                                    innerClass='text_body_light'
                                                    textTheme='background-light-black bottom'
                                                    innerStyle={{ padding: '8px', alignSelf: 'center', color: 'white', borderRadius: '8px'}}
                                                    >
                                                <div className="text_body_light truncate">
                                                    {this.state.item.tagged_prospects[0].designation}
                                                </div>
                                                </Tooltip>
                                            </ConditionalRender>
                                        </div>  
                            </div>                            
                        </div>
                        <div
                            className="default_flex_container"
                            style={{ position: "relative" }}
                        >
                            { !isWidthDown(this.props.windowDimensions.width, "md") && !this.props.simpleView && this.postNotifySection()}
                            {(
                                <AnToolTip
                                innerClass='text_caption_small_light'
                                messageTheme='background-light-black top'
                                tooltipText={this.state.item.formatted_created_at}
                                innerStyle={{marginLeft: -54, color: 'white'}}
                                >
                                    <div>{this.state.item.created_at_time}</div>
                                </AnToolTip>
                            )}
                            { !this.props.simpleView &&
                                (<div
                                    onClick={() =>
                                        {this.updatesSelectedFolders(); this.setState({show_dropdown: true})}
                                    }
                                >
                                    <img
                                        src={MenuDots}
                                        style={{cursor: "pointer", height: 24}}
                                    />
                                    {this.state.show_dropdown &&
                                        this.renderProspectDropdown()}
                                </div>)
                            }
                        </div>
                    </div>
                    <div
                        style={{
                            margin: "16px 0px",
                            borderTop: this.state.item.post_type === "Post::JobUpdates" ? "none" : "0.5px solid #B6B9BB",
                        }}
                    ></div>
                </>
            );
        } else return <div></div>;
    };

    shareViaMailPopup = () => {
        return (
            <AnPopup
                name="shareViaMailPopup"
                handleOnClosePopup={() => {
                    this.close_popups();
                }}
                maxWidth={600}
            >
                <div className="text_title" style={{ marginBottom: "16px" }}>
                    Share {postTypeLabel(this.state.item.post_type)}
                </div>
                <AnInputBox label="To(Email IDs seperated by comma): ">
                    <input
                        type="text"
                        id="email"
                        value={this.state.share_params.email}
                        onChange={(e) =>
                            this.share_via_params("email", e.target.value)
                        }
                        placeholder="Email"
                    />
                </AnInputBox>
                <AnInputBox label="Subject:">
                    <input
                        type="text"
                        id="subject"
                        value={this.state.share_params.subject}
                        onChange={(e) =>
                            this.share_via_params("subject", e.target.value)
                        }
                        placeholder="Subject"
                    />
                </AnInputBox>
                <AnInputBox
                    label="Add Message"
                    helper={`${postTypeLabel(this.state.item.post_type)} snippet will be automatically added`}
                >
                    <textarea
                        type="text"
                        id="content"
                        value={this.state.share_params.content}
                        onChange={(e) =>
                            this.share_via_params("content", e.target.value)
                        }
                        placeholder="Content"
                    />
                </AnInputBox>
                <Button
                    onClick={() => {
                        this.share_via_mail();
                    }}
                    isLoading={this.state.isLoading}
                    buttonStyle={{ marginTop: "16px" }}
                >
                    Share
                </Button>
            </AnPopup>
        );
    };

    Socials = () =>
    {
        const sharable_url = this.state.item.shared_link?.url;
        const params = {
            "Facebook": {
                url: sharable_url,
                title: this.state.item.shared_link?.title,
                summary: this.state.item.shared_link?.description
            },
            "LinkedIn": {
                url: sharable_url,
                title: this.state.item.shared_link?.title,
                summary: this.state.item.shared_link?.description
            },
            "Twitter": {
                url: sharable_url,
                text: this.state.item.shared_link?.title || this.state.item.shared_link?.description
            }
        }
        const SUB_SECTIONS=getElements(params);
        let elements=[];
        elements.push(
            <div>
                { Object.keys(SUB_SECTIONS).map((key) => (
                    <div key={key} style={{marginBottom: 18}}>
                        <ShareOn
                            icon={SUB_SECTIONS[key].source}
                            url={SUB_SECTIONS[key].url}
                            text={key}
                            label={key}
                        >
                        </ShareOn>
                    </div>
                ))}
            </div>)
        return elements;
    }
    shareViaPopup = () => {
        return (
            <AnPopup
                name="shareViaPopup"
                handleOnClosePopup={() => {
                    this.close_popups();
                }}
                maxWidth={240}
            >
                <div className="text_title">Share via...</div>
                <div className="line_seperator_ex_light_grey" style={{margin: "12px 0px 20px"}}></div>
                {this.state.item.post_type !== "Post::JobUpdates" && this.Socials()}
                <div className='default_flex_container' style={{marginBottom: 18}}>
                    <img src={Mail} style={{width: 24, height: 24}}></img>
                    <button className={'text_body'}
                        style={{
                            background: 'none',
                            border: 'none',
                            marginLeft: 12,
                            cursor: 'pointer'}}
                        onClick={() => {this.openPopup("share_via_mail_popup")}}
                    >
                        Email
                    </button>
                </div>
                <div className='default_flex_container'>
                    <img src={Link_Vertical} style={{width: 24, height: 24}}></img>
                    <button className={'text_body'}
                        style={{
                            background: 'none',
                            border: 'none',
                            marginLeft: 12,
                            cursor: 'pointer'}}
                        onClick={() => {
                            navigator.clipboard.writeText(FRONTEND_CONFIG.WEB_URL + "post/" + this.state.item.id)
                            this.close_popups();
                            this.setState({ show_dropdown: false })
                            this.setState({success_message: "Link Copied", messageTheme: "success"});}}
                    >
                        Copy Link
                    </button>
                </div>
            </AnPopup>
        );
    }

    flagPostPopup = () => {
        return (
            <AnPopup
                name="flagPostPopup"
                handleOnClosePopup={() => {
                    this.close_popups();
                }}
                maxWidth={480}
            >
                <div className="text_subtitle_big" style={{marginBottom: 12}}>Please mention the reason:</div>
                {flag_post_reasons.map((reason, index) => {
                    return (
                        <AnInputCheckBox
                            key={index}
                            label={reason}
                            style={{ marginBottom: 12 }}
                        >
                            <input
                                type="checkbox"
                                id={index}
                                style={{ marginRight: 6 }}
                                value={reason}
                                onChange={(e) => {
                                    this.handleFlagReasonsChange(e);
                                }}
                            />
                        </AnInputCheckBox>
                    );
                })}
                 <AnInputCheckBox
                    key='other'
                    label='Other'
                    style={{ marginBottom: 12 }}
                >
                    <input
                        type="checkbox"
                        id='other'
                        style={{ marginRight: 6 }}
                        value={'Other'}
                        onChange={(e) => this.setState({isOtherReson: !this.state.isOtherReson})}
                    />
                </AnInputCheckBox>
                <ConditionalRender if={this.state.isOtherReson}>
                    <AnInputBox
                    style={{maxWidth: 404, margin: 'auto'}}
                    >
                        <textarea
                            type="text"
                            id="comment"
                            value={this.state.customReason}
                            onChange={(e) =>
                                this.setState({
                                    customReason: e.target.value,
                                })
                            }
                            placeholder="Please give comment"
                        />
                    </AnInputBox>
                </ConditionalRender>
                <div style={{ display: "flex", justifyContent: 'center', marginTop: 24 }}>
                    <Button
                        onClick={() => {
                            this.onAddFlagPostReason();
                        }}
                        isLoading={this.state.isLoading}
                    >
                        Submit
                    </Button>
                    <Button
                        type="Secondary"
                        buttonStyle={{ marginLeft: 20 }}
                        onClick={() => {
                            this.close_popups();
                        }}
                        isLoading={false}
                    >
                        Cancel
                    </Button>
                </div>
            </AnPopup>
        )
    }

    renderNewsPost = () => {
        return (
            <div className="article_container" style={{padding: '24px 0px'}}>
                <div className="post_padding">
                {this.renderProspect()}
                </div>
                <ConditionalRender if={this.state.item.clubbed_posts_count > 0}>                        
                    <ClubbedPost item={this.state.item} expanded={this.state.expanded} 
                        toggleExpansionCallback={this.handleClubExpansion} 
                        renderSimilarArticles={this.renderSimilarAticles} 
                        renderCTAs={this.renderPostCTAs}
                        renderCommentsSection={this.renderCommentsSection}
                    />
                </ConditionalRender>
                <ConditionalRender if={this.state.item.clubbed_posts_count === 0}>
                    <div className="post_padding">
                    <div className="mentioned_for">
                        <ConditionalRender if={this.state.item.mentioned_for}>Mentioned for: {this.state.item.mentioned_for}</ConditionalRender>
                        <ConditionalRender if={this.state.item.mentioned_for && this.state.item.interests}>
                            &nbsp;|&nbsp;
                        </ConditionalRender>
                        {this.state.item.interests}
                    </div>
                    <a
                        href={this.state.item.shared_link.url}
                        target="_blank"
                        className="news_container"
                    >
                        {this.state.item.shared_link.image_url &&
                        <div className="article_img">
                            <img
                                src={this.state.item.shared_link.image_url}
                                className="post_image"
                            />
                        </div>
                        }
                        <div className={this.state.item.shared_link.image_url ? "article_desc_with_img" : "article_desc_without_img"}>
                            <TextHighlighter
                                contentString={
                                    this.state.item.shared_link.title
                                }
                                toMatch={[
                                    ...[this.state.item.tagged_prospects?.[0]?.name,this.state.item.tagged_prospects?.[0]?.company],
                                    {toMatchText: this.props.searchTerm, highlightCustomStyle: "background: rgba(0, 196, 181, 0.35); text-transform: capitalize"} 
                                ]}
                                typography={{ className: "text_subtitle_big" }}
                                style={{ marginBottom: 4 }}
                            />
                            <Seemore
                                short_description = {this.state.item.shared_link.short_description}
                                description = {this.state.item.shared_link.description}
                                person_name = {[this.state.item.tagged_prospects?.[0]?.name,this.state.item.tagged_prospects?.[0]?.company]}
                                searchTerm = {this.props.searchTerm}
                            >
                            </Seemore>
                            <div
                                className="text_caption_light"
                                style={{ marginTop: "8px" }}
                            >
                                <Link to={"/news_source/"+this.state.item.shared_link.source+"/feed"} className="text_link_small">{this.state.item.shared_link.source}</Link>{" "}
                                {this.state.item.shared_link.published_at
                                    ? `| ${this.state.item.shared_link.published_at}`
                                    : ""}
                            </div>
                        </div>
                        <div style={{ clear: "both" }}></div>
                    </a>
                    {this.renderSimilarAticles()}
                    </div>
                    { !this.props.simpleView && (<div className="line_seperator_ex_light_grey" style={{marginTop: 16}}></div>)}
                    <div className="post_padding">
                    {this.renderPostCTAs()}
                    {this.renderCommentsSection()}
                    </div>
                </ConditionalRender>
                <ConditionalRender if={this.props.checkUserAccess('display_omf_tags') && this.props.current_team.omf_enabled}>
                    <div className="flex_container_space_between post_padding" style={{marginTop: 16}}>
                        {this.state.item.openai_tags && 
                        <div>
                            <span className="text_subtitle_light">Tags: </span>
                            {
                                this.state.item.openai_tags.map(k => (
                                    <span className="text_subtitle" key={k} style={{marginLeft: "12px"}}>{k}</span>
                                ))
                            }
                        </div>}
                    </div>
                </ConditionalRender>
            </div>
        )
    }
    renderJobUpdate = () => {
        const job_update = this.state.item;
        if (this.state.item.tagged_prospects.length > 0) {
            var [tagged_prospect] = this.state.item.tagged_prospects;
            var name = tagged_prospect.name;
        }
        return (
            <>
                <div className="article_container" style={{padding: '12px 0px'}}>
                    <div className="post_padding">
                    <div className="text_caption default_flex_container post_type_deco" style={{width: 'max-content', padding: "4px 12px", marginBottom: 8}}>
                        <img src={SuitcaseIcon} style={{marginRight: 6}}></img>
                        <div>Job update</div>
                    </div>
                    <div className="line_seperator_ex_light_grey" style={{margin: '12px 0px 16px 0px'}}></div>
                    {this.renderProspect()}
                    <JobUpdateDesc job_update={{...job_update, name: name}} searchTerm={this.props.searchTerm}/>
                    </div>
                    <div className="line_seperator_ex_light_grey" style={{marginTop: 16}}></div>
                    <div className="post_padding">
                    {this.renderPostCTAs()}
                    {this.renderCommentsSection()}
                    </div>
                </div>
            </>
        )
    }

    render() {
        return (
            <div ref={this.singlePostRef} trackid={this.state.item.id}>
                {
                    this.state.item.post_type === "Post::JobUpdates" ?
                        this.renderJobUpdate() : this.renderNewsPost()
                }
                {this.state.popup_lists.share_via_mail_popup &&
                    this.shareViaMailPopup()}
                {this.state.popup_lists.share_via_popup &&
                    this.shareViaPopup()}
                {this.state.popup_lists.add_to_folder_popup &&
                    (
                        <AddToFolder closePopup={this.close_popups}
                            setStateFromChild={this.setStateFromChild} 
                            post={this.state.item} memberFolders={this.props.memberFolders} setMemberFolders={this.props.pageDataReducer} 
                            folderUpdate={this.props.folderUpdate} updatesSelectedFolders={this.updatesSelectedFolders}
                            popup_lists={this.state.popup_lists}
                        />
                    )
                }
                {this.state.popup_lists.create_new_folder_popup && 
                    this.createNewFolder()}
                {this.state.popup_lists.flag_post_popup &&
                    this.flagPostPopup()}
                {this.state.popup_lists.edit_post_popup &&
                    this.editPostPopup()}
                {
                    this.state.popup_lists.viewDetailsPopup && this.viewDetailsPopup()
                }
                {this.state.popup_lists.jobUpdatesCrm && (<AddToCrmPopup post={this.state.item} prospect={this.state.item.tagged_prospects[0]} handleOnClosePopup={() => this.setState({popup_lists: {}})} onFinish={(payload) => this.setState({...payload})}/>)}
                {this.state.success_message && (
                    <FlashMessages
                        messageTheme={this.state.messageTheme}
                        closeCallback={() =>
                            this.setState({ success_message: null })
                        }
                    >
                        {this.state.success_message}
                    </FlashMessages>
                )}
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        current_user: state.current_user.user,
        current_team: state.current_team.team,
    };
};
Post.contextType = HighConfidenceContext;
export default connect(mapStateToProps)(Post);

// TODO: CONVERT TO FUNCTIONAL COMPONENT AND USE USESELECTOR HOOK FOR current_user and current_team
