import React, { useState, useEffect } from 'react'
import { apiAgent } from '../../../axiosConfig'
import useCheckUserAccess from '../../custom_hooks/useCheckUserAccess';
import useWindowSize from '../../custom_hooks/useWindowSize';
import Post from "../../feed_components/Post";
import getApiUrl from '../../utils/UrlHelper';

const SinglePost = (props) => {
    const [post, setPost] = useState(undefined)
    const [isFetching, _setIsFetching] = useState(false)
    const windowDimensions = useWindowSize();
    const checkUserAccess = useCheckUserAccess();
    useEffect(() => {
        _setIsFetching(true);
        let paramsString = window.location.search;
        let searchParams = new URLSearchParams(paramsString);
        let from_flagged = searchParams.get("from_flagged")
        apiAgent({
            method: 'get',
            url: getApiUrl("get_post", {":id": props.match.params.post_id}),
            params: {from_flagged: from_flagged}
        }).then(res => {
            setPost(res.data);
            _setIsFetching(false)
        })
    }, [props.match.params.post_id])

    return (
        <div style={{maxWidth: '640px', margin: 'auto'}}>
            {!isFetching && post && (
                <Post key={post.id} item={post} windowDimensions={windowDimensions} checkUserAccess={checkUserAccess}/>
                )}
        </div>
    )
}

export default SinglePost
