import React, { useEffect, useReducer, useState } from 'react'
import { Button, TabbedButton } from '../../cta_utils/AnButton';
import Pagination from '../../../Pagination';
import { LoaderComponent } from '../../utils/LoaderComponent';
import { apiAgent } from '../../../axiosConfig';
import { ALL_ACTIONS, ProspectMappingReducer, initialListState } from './reducers/prospectMappingReducer';
import ConditionalRender from '../../../ConditionalRender';
import { indexOfObject } from '../../utils/ArrayUtils';
import { NameInitial } from '../../cta_utils/AnNameInitial';
import linkedinIcon from '../../../assets/linkedin_icon.png'
import { FlashMessages } from '../../cta_utils/FlashMessages';
import { Link } from 'react-router-dom/cjs/react-router-dom';
const mappingTypes = [{label: 'Unique matching', key: 'unique_matching'}, {label: "Maybe matching", key: 'maybe_matching'},
                {label: 'No matching', key: 'no_matching'}, {label: "Mapped profiles", key: 'mapped_profiles'}];

const LinkedinProspectMapping = () => {
    const [mappingType, setMappingType] = useState("unique_matching");
    const [pageData, pageDataDispatcher] = useReducer(ProspectMappingReducer, initialListState)
    const [select_all, setSelectAll] = useState(false)
    useEffect(() => {
        fetchProspectMappings(1);
    }, [mappingType]);

    const fetchProspectMappings = (page) => {
        let fetchURL = "/linkedin_prospect_suggestions";
        if(mappingType === 'mapped_profiles') fetchURL = "/linkedin_prospect_suggestions/mapped"
        apiAgent({
            method: "get",
            url: fetchURL,
            params: {
                page: page,
                type: mappingType
            },
        }).then(
            (res) => {
                let payload = {
                    isFetching: false,
                    prospectList: res.data.data,
                    totalPages: res.data.total_pages,
                    total: res.data.total,
                    currentPage: page,
                    mappingList: []
                }
                pageDataDispatcher({
                    type: ALL_ACTIONS['SET_STATE'],
                    payload: payload
                })
            },
            (error) => {
                pageDataDispatcher({
                    type: ALL_ACTIONS['SET_STATE'],
                    payload: {isFetching: false}
                })
            }
        );
        setSelectAll(false)
    }
    const handleMap = (id = null, ln_prospect_id = null) => {
        let mp_list = [...pageData.mappingList]
        let mappings = mp_list || []
        if(id && ln_prospect_id)
            mappings = [{id: id, ln_prospect_id: ln_prospect_id}]
        let params = {mappings: mappings}
        apiAgent({
            method: "post",
            url: `/linkedin_prospect_suggestion/map`,
            data: params,
        }).then(
            (res) => {
                let temp = [...pageData.prospectList]
                temp = temp.filter((value) => {
                    let ind=indexOfObject(mappings, 'id', value.id)
                    return (ind == -1)
                })
                let payload = {
                    prospectList: temp, 
                    mappingList: [],
                    total: pageData.total - mappings.length,
                    successMessages: {
                        message: "Mapping complete",
                        duration: 3000,
                    }
                }
                pageDataDispatcher({
                    type: ALL_ACTIONS['SET_STATE'], 
                    payload: payload
                })
                setSelectAll(false)
            },
            (error) => {
            }
        );
    }
    const handleMappingListChange = (sug_id, ln_prospect_id) => {
        let val = {id: sug_id, ln_prospect_id: ln_prospect_id}
        let temp = [...pageData.mappingList]
        let index = indexOfObject(temp, 'id', sug_id)
        if(index===-1) {
            temp.push(val)
        }
        else{
            temp.splice(index, 1)
        }
        let payload = {
            ...pageData,
            mappingList: temp
        }
        pageDataDispatcher({
            type: ALL_ACTIONS['SET_STATE'], 
            payload: payload
        })
        if(temp.length != 0)
        setSelectAll(true)
    }
    const handleSelectAll = () => {
        let all_selected = []
        if(!select_all){
            pageData.prospectList.map((result) => {
                let ln_prospects = result.linkedin_prospects || [];
                all_selected.push({id: result.id, ln_prospect_id: ln_prospects[0].id})
            })
        }
        pageDataDispatcher({
            type: ALL_ACTIONS['SET_STATE'], 
            payload: {...pageData, mappingList: all_selected}
        })
        setSelectAll(!select_all)
    }
    const renderResults = () => {
        return (
        <div>
                <div className='default_flex_container' style={{justifyContent: 'space-between'}}>
                    <ConditionalRender if={mappingType !== 'mapped_profiles'}>
                    <div className='text_title' style={{width: '50%'}}>Unmapped Profiles</div>
                    </ConditionalRender>
                    <div className='default_flex_container' style={{width: '50%', justifyContent: 'space-between'}}>
                        <div className='text_title'>{ mappingType === 'mapped_profiles' ? 'Mapped Profiles' : mappingType !== 'no_matching' && 'Probable Mapped Matches' }</div>
                        <div className='default_flex_container'>
                            <ConditionalRender if={mappingType === 'unique_matching'}>
                                <Button
                                    buttonSize="Medium"
                                    innerButtonStye={{ padding: "8px 30px" }}
                                    onClick={() => handleMap()}
                                    isLoading={false}
                                    disabled={!select_all}
                                >
                                    Map all
                                </Button>
                                <input style={{marginLeft: 8}} type="checkbox" value={select_all} checked={select_all} onChange={(e) => {handleSelectAll()}}/>
                            </ConditionalRender>
                        </div>
                    </div>
                </div>
            {pageData.prospectList.map((result, index) => {
                let prospect = result.prospect || result;
                let ln_prospects = result.linkedin_prospects || [];
                return (
                <>
                    <div key={result.id} className='default_flex_container' style={{alignItems: 'flex-start', borderTop: index===0 ? 'none' : '1px solid #E7ECEB'}}>
                        <div className='prospect_block' style={{width: '50%', padding: '20px 0px', justifyContent: 'normal', alignItems: 'flex-start', borderTop: 'none'}}>
                            <NameInitial
                            name={prospect.name || prospect.designation}
                            />
                            <div className="prospect_sub_block">
                            <div className="default_flex_container">
                                <Link to={`/prospects/${prospect.id}`} target="_blank" className="text_subtitle_big" style={{textDecoration: 'none'}}>
                                    {prospect.name || prospect.designation}
                                </Link>
                                <ConditionalRender if={prospect.linkedin_url}>
                                    <a href={prospect.linkedin_url} target="_blank" style={{marginLeft: 6, display: 'flex'}}>
                                        <img src={linkedinIcon}></img>
                                    </a>
                                </ConditionalRender>
                            </div>
                            <ConditionalRender if={prospect.name}>
                                <div className="text_body_light truncate">
                                {prospect.designation}
                                </div>
                            </ConditionalRender>
                                <div className='text_body_light'>{prospect.location}</div>
                            </div>
                        </div>

                    <div style={{width: '50%'}}>
                    {ln_prospects.map((ln_prospect, index) => {
                        let isChecked = indexOfObject(pageData.mappingList, 'id', result.id) !== -1
                        let matchedKeys = result.matched_keys[index] || [];
                        return (
                        <div key={ln_prospect.id} className='prospect_block' style={{padding: '20px 0px',justifyContent: 'space-between', borderTop: 'none'}}>
                        <div className='default_flex_container'>
                        <NameInitial
                        name={ln_prospect.name || ln_prospect.last_profession_designation}
                        />
                        <div className="prospect_sub_block">
                        <div className="default_flex_container">
                            <div className="text_subtitle_big">
                            {ln_prospect.name || ln_prospect.last_profession_designation}
                            </div>
                            <ConditionalRender if={ln_prospect.linkedin_url}>
                                <a href={ln_prospect.linkedin_url} target="_blank" style={{marginLeft: 6, display: 'flex'}}>
                                    <img src={linkedinIcon}></img>
                                </a>
                            </ConditionalRender>
                        </div>
                        <ConditionalRender if={ln_prospect.name}>
                            <div className={`text_body_light truncate ${matchedKeys.includes('last_profession_designation') ? 'highlight' : ''}`}>
                            {ln_prospect.last_profession_designation}
                            </div>
                        </ConditionalRender>
                        <div className={`text_body_light ${matchedKeys.includes('location') ? 'highlight' : ''}`}>{ln_prospect.location}</div>
                        </div>
                        </div>
                        <div className='default_flex_container'>
                            <Button
                                buttonSize="Medium"
                                innerButtonStye={{ padding: "8px 30px" }}
                                onClick={() => handleMap(result.id, ln_prospect.id)}
                                isLoading={false}
                            >
                                Map
                            </Button>
                            <ConditionalRender if={mappingType === 'unique_matching'}>
                                <input style={{marginLeft: 8}} type="checkbox" id={result.id} checked={isChecked} onChange={() => handleMappingListChange(result.id, ln_prospect.id)}/>
                            </ConditionalRender>
                        </div>
                        </div>
                        )}
                    )}
                    </div>
                    </div>
                </>
                )})}
        </div>
        )
    }
    const setActiveTab = (mapping) => {
        pageDataDispatcher({
            type: ALL_ACTIONS['SET_STATE'],
            payload: {isFetching: true}
        })
        setMappingType(mapping)
    }
  return (
    <div style={{padding: '24px 24px 0px 24px'}}>
        <div className='text_title' style={{marginBottom: 28}}>Prospect Mapping</div>
        <div className='default_flex_container'>
        {
            mappingTypes.map(type => (
                <div key={type.key} style={{marginBottom: 12}}>
                <TabbedButton buttonStyle={{padding: '12px 16px'}} isActive={mappingType === type.key} onClick={() => setActiveTab(type.key)}>
                    {type.label}
                </TabbedButton>
                </div>
            ))
        }
        </div>
        <div className='text_subtitle_light' style={{marginTop: 12, marginBottom: 24}}><span className='text_subtitle'>{pageData.total}</span> Total Listings</div>
        <div style={{marginBottom: 24}}>
            { !pageData.isFetching && (pageData.totalPages !== 0) && (
                <Pagination totalPages={pageData.totalPages}
                            currentPage={pageData.currentPage}
                            onPageChange={fetchProspectMappings}
                />)
            }
        </div>
        <ConditionalRender if={!pageData.isFetching}>
        {renderResults()}
        </ConditionalRender>
        {pageData.successMessages.message && <FlashMessages messageTheme='success' firstIcon={true} closeCallback={() =>
              pageDataDispatcher({type: ALL_ACTIONS["SET_STATE"], payload: {successMessages: {
                message: "",
                duration: 0,
                from: ""
            }}})}>{pageData.successMessages.message}</FlashMessages>}
        { pageData.isFetching && (<LoaderComponent isLoading={true}  loaderStyle={{width: 32, margin: '36px auto', display: 'block'}}/>)}
    </div>
  )
}

export default LinkedinProspectMapping