import React, {useReducer, useState} from "react";
import { useSelector } from 'react-redux';
import { apiAgent } from "../../../axiosConfig.js";
import { useHistory } from "react-router-dom";
import { Button } from "../../cta_utils/AnButton.js";
import {AnInputBox} from "../../input_utils/AnInput";
import {ALL_TIME_ZONES} from "../../utils/timeUtils";
import {AnInputCheckBox} from "../../input_utils/AnInputCheckBox";
import TableHeads from "../../visualizers/tabular/TableHeads";
import ConditionalRender from '../../../ConditionalRender';
import TableRows from "../../visualizers/tabular/TableRows";
import {FlashMessages} from "../../cta_utils/FlashMessages";
import {TEAM_COUNTRIES} from "../../utils/countryUtils";
import { dateFormator, datepicker_input_format, getDateUtil } from '../../utils/dateUtils';
import DatePicker from 'react-modern-calendar-datepicker';

const AdminCreateTeam = () => {
    let _history = useHistory();
    const current_user = useSelector(state => state.current_user.user);
    const [teamParams, teamParamsDispatcher] = useReducer(teamSettingsReducer, {
        team: {
            name: "",
            linkedin_url: "",
            team_time_zone: "",
            aliases: [""],
            verified: false,
            premium: false,
            trial_expiring_on: null,
            google_news_tracking: false,
            names_tracking: false,
            date: {}
        },
        step: 1,
        isLoading: false,
        successMessage: null,
        errors: {}
    });

    const setTeam = (payload) => {
        teamParamsDispatcher({
            type: ALL_TEAM_ACTIONS['SET_TEAM'],
            payload: payload
        })
    }

    const handleInputChange = (e) => {
        let payload = {
            [e.target.name]: e.target.value
        }
        setTeam(payload);
    }

    const handleZoneChange = (e) => {
        let time_zone = e.target.value;
        setTeam({team_time_zone: time_zone})
    }

    const handleOnAliasesChange = (event, index) => {
        let temp = [...teamParams.team.aliases]
        if(!event.target.value){
            temp.splice(index, 1);
        }else{
            temp[index] = event.target.value;
        }
        setTeam({aliases: temp})
    }

    const handleVerified = (verify=!teamParams.team.verified) => {
        let payload={verified: verify};
        let defaultExpireDate = dateFormator(getDateUtil(+30), {type: "datepicker_object"});
        if(teamParams.team.verified){
            payload={
                verified: verify,
                trial_expiring_on: null
            }
        }
        else{
            payload={
                verified: verify,
                trial_expiring_on: defaultExpireDate,
                date: defaultExpireDate
            }
        }
        setTeam(payload);
    }

    const handleTrialExpiringOn = (e) => {
        let res=new Date(e.year,e.month-1,e.day);
        res=dateFormator(res, {type: "datepicker_object"});
        let payload= {
            trial_expiring_on: res,
            date: e
        }
        setTeam(payload);
    }

    const receiveDateString = () => {
        let val=teamParams.team.trial_expiring_on;
        if(val)
        {
            val=new Date(val.year,val.month-1,val.day).toString();
        }
        return val;
    }

    const validateForm = () => {
        let errors = {}
        if(!teamParams.team.name) errors.name = "Team name should not be empty";
        return errors
    }

    const nextStep = () => {
        let errors = validateForm();
        if(Object.keys(errors).length > 0) {
            teamParamsDispatcher({
                type: ALL_TEAM_ACTIONS['SET_ERRORS'],
                payload: errors
            });
            return;
        }
        else{
            teamParamsDispatcher({
                type: ALL_TEAM_ACTIONS["SET_REQUEST_STATUS"],
                payload: {
                    step: 2
                }
            });
        }
    }

    const handleSubmit = () => {
        teamParamsDispatcher({
            type: ALL_TEAM_ACTIONS["SET_REQUEST_STATUS"],
            payload: {isLoading: true}
        });
        let data_params = {...teamParams.team};
        data_params.aliases = teamParams.team.aliases.filter(a=>a);
        data_params.trial_expiring_on = receiveDateString();
        apiAgent({
            method: 'post',
            url: '/teams/admin_create',
            data: data_params
        }).then(res => {
            teamParamsDispatcher({
                type: ALL_TEAM_ACTIONS["SET_REQUEST_STATUS"],
                payload: {
                    isLoading: false,
                    successMessage: res.success_message,
                    serverError: res.error_message
                }
            });
            _history.push('/admin/teams')
        }).catch(err => {
            teamParamsDispatcher({
                type: ALL_TEAM_ACTIONS["SET_REQUEST_STATUS"],
                payload: {
                    isLoading: false,
                    successMessage: null,
                    serverError: "Something went wrong! Please try again later"
                }
            });
        })
    }
    let flash_type = "success";
    if(teamParams.serverError) flash_type = "warning";
    return (
        <div>
            <div style={{maxWidth: "640px", margin: '48px auto', padding: 24, border: '1px solid #E7ECEB', borderRadius: 4}}>
                <div className="text_title" style={{marginBottom: 12}}>Create Team</div>
                {
                    (teamParams.step === 1) && (
                        <div>
                            <AnInputBox label="Team Name" error={teamParams.errors.name}>
                                <input type="text" name="name" value={teamParams.team.name}
                                       onChange={handleInputChange}
                                       placeholder="Team name"
                                />
                            </AnInputBox>
                            <AnInputBox label="Linkedin URL">
                                <input type="text" name="linkedin_url" value={teamParams.team.linkedin_url}
                                       onChange={handleInputChange}
                                       placeholder="Linkedin URL"
                                />
                            </AnInputBox>
                            <AnInputBox label="Country" style={{maxWidth: "480px"}}>
                                <select placeholder="select the country" value={teamParams.team.country} onChange={(e) => setTeam({country: e.target.value})}>
                                <option key="null_valued" value="">
                                    Select a country
                                </option>
                                    {
                                        Object.keys(TEAM_COUNTRIES).map(country => (
                                            <option key={country} value={TEAM_COUNTRIES[country]}>
                                                {country}
                                            </option>
                                        ))
                                    }
                                </select>
                            </AnInputBox>
                            <AnInputBox label="Timezone" style={{maxWidth: "300px"}}>
                                <select value={teamParams.team.team_time_zone} onChange={handleZoneChange}>
                                    {
                                        Object.keys(ALL_TIME_ZONES).map(tz => (
                                            <option key={tz} value={ALL_TIME_ZONES[tz]}>
                                                {tz}
                                            </option>
                                        ))
                                    }
                                </select>
                            </AnInputBox>
                            <ConditionalRender if={current_user.is_publisher}>
                                <div className="line_seperator_ex_light_grey" style={{margin: "12px 0px 20px"}}></div>
                                <div className="text_subtitle" style={{marginBottom: '12px'}}>
                                    Team Type
                                </div>
                                <div style={{marginBottom: 20, opacity: teamParams.team.verified && teamParams.team.premium ? 0.4 : 1}}>
                                    <div className='default_flex_container'>
                                        <AnInputCheckBox
                                            label="Verified"
                                            isChecked={teamParams.team.verified}
                                        >
                                            <input
                                                type="checkbox"
                                                disabled={teamParams.team.verified}
                                                checked={teamParams.team.verified}
                                                onChange={() => handleVerified()}
                                            >
                                            </input>
                                        </AnInputCheckBox>
                                        <AnInputCheckBox
                                            label="Premium"
                                            isChecked={teamParams.team.premium}
                                            style={{marginLeft: "29px"}}
                                        >
                                            <input
                                                type="checkbox"
                                                disabled={teamParams.team.verified && teamParams.team.premium}
                                                checked={teamParams.team.premium}
                                                onChange={() => {setTeam({premium: !teamParams.team.premium}); handleVerified(true)}}
                                            >
                                            </input>
                                        </AnInputCheckBox>
                                    </div>
                                    <ConditionalRender if={teamParams.team.verified}>
                                        <div className='text_body_light' style={{marginTop: 16}}>
                                            Reverting to non-verified on
                                        </div>
                                        <div className='text_body' style={{pointerEvents: teamParams.team.verified && teamParams.team.premium ? 'none' : 'auto'}}>
                                            <AnInputBox style={{maxWidth: "120px"}}>
                                                <DatePicker
                                                    value={teamParams.team.date}
                                                    onChange={handleTrialExpiringOn}
                                                    inputPlaceholder="Enter Expiry Date"
                                                    formatInputText={() => datepicker_input_format(teamParams.team.trial_expiring_on)}
                                                    calendarPopperPosition="bottom"
                                                    shouldHighlightWeekends
                                                >
                                                </DatePicker>
                                            </AnInputBox>
                                        </div>
                                    </ConditionalRender>
                                </div>
                            </ConditionalRender>
                            <div className="line_seperator_ex_light_grey" style={{margin: "12px 0px 20px"}}></div>
                            <div className="text_subtitle" style={{marginBottom: '6px'}}>
                                Team Aliases
                            </div>
                            {
                                teamParams.team.aliases.map((alias, index) => (
                                    <AnInputBox key={index} error={(!alias && teamParams.errors["aliases"]) ? teamParams.errors["aliases"] : null}>
                                        <input type="text" value={alias}
                                               onChange={(e) => handleOnAliasesChange(e, index)}
                                               placeholder="Team alias"
                                        />
                                    </AnInputBox>
                                ))
                            }
                            <div className="text_link" style={{cursor: 'pointer'}}
                                 onClick={() => {
                                     setTeam({aliases: [...teamParams.team.aliases, '']})
                                     teamParamsDispatcher({
                                         type: ALL_TEAM_ACTIONS['SET_ERRORS'],
                                         payload: {}
                                     });
                                 }
                                 }>
                                +Add {teamParams.team.aliases.length == 0 ? 'alias' : 'another'}
                            </div>
                            <AnInputCheckBox
                                label="Enable Google News tracking"
                                isChecked={teamParams.team.google_news_tracking}
                                style={{margin: "12px 0px 8px"}}
                            >
                                <input
                                    type="checkbox"
                                    checked={teamParams.team.google_news_tracking}
                                    onChange={(e) => setTeam({google_news_tracking: !teamParams.team.google_news_tracking})}
                                >
                                </input>
                            </AnInputCheckBox>
                            <AnInputCheckBox
                                label="Enable names tracking"
                                isChecked={teamParams.team.names_tracking}
                                style={{marginBottom: "12px"}}

                            >
                                <input
                                    type="checkbox"
                                    checked={teamParams.team.names_tracking}
                                    onChange={(e) => setTeam({names_tracking: !teamParams.team.names_tracking})}
                                >
                                </input>
                            </AnInputCheckBox>
                            <Button buttonSize="Medium" isLoading={teamParams.isLoading}
                                    innerButtonStye={{padding: '8px 48px'}}
                                    buttonStyle={{marginTop: "20px"}}
                                    onClick={() => nextStep()}
                            >
                                Continue
                            </Button>
                        </div>
                    )
                }
                {
                    (teamParams.step === 2) && (
                        <div>
                            <AnInputBox label="Linkedin URL">
                                <input type="text" name="linkedin_url" value={teamParams.team.linkedin_url}
                                       onChange={handleInputChange}
                                       placeholder="Linkedin URL"
                                       disabled="true"
                                />
                            </AnInputBox>
                            <table className="data_table" style={{textAlign: "left"}}>
                                <TableHeads typoGraphyClass="text_body_big_light" headerClass="table_header_row">
                                    <th style={{width: "30%"}}>Profiles Found</th>
                                    <th style={{width: "40%"}}>Select No. of Profiles</th>
                                    <th style={{width: "20%"}}>Status</th>
                                    <th style={{width: "10%"}}></th>
                                </TableHeads>
                                <tbody>
                                    <TableRows rowClass="table_content_row text_body">
                                        <td>0</td>
                                        <td>No. of Profiles</td>
                                        <td>To be imported</td>
                                        <td>
                                            <Button buttonSize="LessPadding">Import</Button>
                                        </td>
                                    </TableRows>
                                </tbody>
                            </table>
                            <Button buttonSize="Medium" isLoading={teamParams.isLoading}
                                    innerButtonStye={{padding: '8px 48px'}}
                                    buttonStyle={{marginTop: "20px"}}
                                    onClick={handleSubmit}
                            >
                                Create Team
                            </Button>
                        </div>
                    )
                }
                {
                    (teamParams.successMessage || teamParams.serverError) && (<FlashMessages
                        messageTheme={flash_type}
                        closeCallback={() => {
                            teamParamsDispatcher({
                                type: ALL_TEAM_ACTIONS["SET_REQUEST_STATUS"],
                                payload: {
                                    successMessage: null,
                                    serverError: null,
                                }
                            });
                        }}
                    >
                        {teamParams.successMessage || teamParams.serverError}
                    </FlashMessages>)
                }
            </div>
        </div>
    );
};

export default AdminCreateTeam;

const teamSettingsReducer = (state, action) => {
    switch(action.type){
        case ALL_TEAM_ACTIONS["SET_STATE"]:{
            return {...state, ...action.payload}
        }
        case ALL_TEAM_ACTIONS["SET_TEAM"]: {
            return {...state, team: {
                    ...state.team,
                    ...action.payload
                }}
        }
        case ALL_TEAM_ACTIONS["SET_ERRORS"]:{
            return {...state, errors: {...action.payload}}
        }
        case ALL_TEAM_ACTIONS["SET_REQUEST_STATUS"]:{
            return {
                ...state,
                ...action.payload
            }
        }
        default: return {...state}
    }
}

const ALL_TEAM_ACTIONS = {
    "SET_STATE": "SET_STATE",
    "SET_TEAM": "SET_TEAM",
    "SET_ERRORS": "SET_ERRORS",
    "SET_REQUEST_STATUS": "SET_REQUEST_STATUS"
}
