import React, {useEffect, useState} from 'react'
import { apiAgent } from '../../../axiosConfig'
import { Button } from '../../cta_utils/AnButton'
import {Link} from "react-router-dom";
import ALL_PROSPECT_SHOW_ACTIONS from "../profiles/prospectProfile/actions/prospectProfileShowActions";
import {LoaderComponent} from "../../utils/LoaderComponent";

const ProspectImportShow = (props) => {
    const [isFetching, setIsFetching] = useState(false)
    const [prospectImport, setProspectImport] = useState({});

    useEffect(() => {
        setIsFetching(true);
            apiAgent({
                method: 'get',
                url: `/prospect_import/${props.match.params.id}`,
                params: {}
            }).then(res => {
                setProspectImport(res.data.data);
                setIsFetching(false);
            }).catch(err => {

            })
    }, [])

    return (
        <div className="light_grey_border_container" style={{margin: '24px auto 0px'}}>
                { isFetching && (<LoaderComponent isLoading={true}  loaderStyle={{width: 32, margin: '36px auto', display: 'block'}}/>)}
                { !isFetching && (
                    <div>
                        <div style={{padding: 24, borderBottom: '1px solid #E7ECEB'}}>
                            <div className="text_title">
                                Preview of your data
                            </div>
                            <div className="text_body">
                                Name: {prospectImport.name}
                            </div>
                            <div className="text_body_light" style={{marginTop: 4}}>
                                CSV column mapping information:
                            </div>
                            <table className="map_step2_table" style={{textAlign: 'left', marginTop: 16}}>
                                <thead>
                                <tr className="text_subtitle_big" style={{background: 'rgba(67, 126, 254, 0.8)', color: '#fff'}}>
                                    <th style={{width: '50%'}}>Column Header</th>
                                    <th style={{width: '50%'}}>Mapped as</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    prospectImport && prospectImport.field_mappings && prospectImport.field_mappings.map((field, index) => {
                                        return(
                                            <tr key={index}>
                                                <td>
                                                    {field.original_field}
                                                </td>
                                                <td>
                                                    {field.mapped_to}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        <div className='default_flex_container' style={{padding: 24, borderTop: '1px solid #E7ECEB', justifyContent: 'flex-end'}}>
                            <div style={{display: 'flex'}}>
                                <Link to="/import_prospects" style={{display: 'block', padding: '16px 24px', cursor: 'pointer'}} className="text_link hover_under_line">
                                    <Button buttonSize='Medium' buttonStyle={{marginLeft: 16}} innerButtonStye={{padding: '8px 60px'}}>Done</Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
        </div>
    )
}

export default ProspectImportShow
