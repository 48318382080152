import NewsPost from "../../../../../assets/help_center/news_post.png"
import PostViews from "../../../../../assets/help_center/post_views.png"
import DateFilter from "../../../../../assets/help_center/date_filter.png"
import OtherFilters from "../../../../../assets/help_center/other_filters.png"
import NewsfeedSearch from "../../../../../assets/help_center/newsfeed_search.png"
import UnseenFilter from "../../../../../assets/help_center/unseen_filter.png"
import ExportNews from "../../../../../assets/help_center/export_news.png"
import AnScrollToLinkPage from "../../../../cta_utils/AnScrollToLinkPage";

export const HighConfidenceResults = () => {
    return (
        <div style={{ margin: "24px 0px" }}>
        <div className="help_center_top_section">
            <div className="text_title_big" style={{marginBottom: 20}}>High confidence results</div>
            <div style={{border: "1px solid grey", padding: "14px 20px", borderRadius: "4px", fontWeight: "700"}}>
                <div className="text_body" style={{color: "#707274", fontWeight: 700}}> SKIP AHEAD TO</div>
                <div style={{marginTop: "12px"}}>
                    <span style={{color: "#437EFE", fontSize: "14px"}}><AnScrollToLinkPage href="#news_snippet"> News snippet </AnScrollToLinkPage></span>
                    <span style={{color: "#437EFE", marginLeft: 20, fontSize: "14px"}}><AnScrollToLinkPage href="#date_range"> Date Range </AnScrollToLinkPage></span>
                    <span style={{color: "#437EFE", marginLeft: 20, fontSize: "14px"}}><AnScrollToLinkPage href="#filters"> Filters </AnScrollToLinkPage></span>
                    <span style={{color: "#437EFE", marginLeft: 20, fontSize: "14px"}}><AnScrollToLinkPage href="#seen_unseen">Seen/Unseen filter </AnScrollToLinkPage> </span>
                    <span style={{color: "#437EFE", marginLeft: 20, fontSize: "14px"}}><AnScrollToLinkPage href="#export_news"> Export news article </AnScrollToLinkPage></span>
                </div>
            </div>
        </div>

            <div className="help_center_text">
                High confidence results refer to those that have a high probability of being accurate and correct. These results are obtained through matching multiple key words for each prospect to minimize errors and ensure the reliability of the findings. They are considered to be highly reliable and trustworthy. 
            </div>

            <div className="help_center" id="news_snippet">
                News snippet
            </div>
            <div className="help_center_text">
                The news snippet looks like this
            </div>

            <img className="help_center_img" src={NewsPost} style={{maxWidth: "720px", margin: "12px auto 0px"}}></img>
            <div className="help_center_text">
                Actions to be taken on the news article:
            </div>
            <ul style={{marginTop: 6, marginLeft: -6}}>
                <li><span style={{fontWeight: "600"}}>Edit Post –</span> This allows you to make changes to the description of the post.</li>
                <li><span style={{fontWeight: "600"}}>Delete Post –</span> This allows you to delete the post.</li>
                <li><span style={{fontWeight: "600"}}>Share –</span> This allows you to share the post on social media or a link to this can be made and then shared.</li>
                <li><span style={{fontWeight: "600"}}>Flag post –</span>  This allows you to flag a post if the news article is not accurate. </li>
                <li><span style={{fontWeight: "600"}}>Add Tag –</span> This allows you to add tags, which helps in filtering.</li>
                <li><span style={{fontWeight: "600"}}>Assign Team Member –</span> This allows you to assign a team member to a post.</li>
            </ul>

            <div className="help_center_text">
                You can also see the number of views this post has got among your team.
            </div>
            <img className="help_center_img" src={PostViews} style={{maxWidth: "650px", margin: "24px auto 0px"}}></img>
            
            <div className="help_center" id="date_range">
                Date Range
            </div>
            <div className="help_center_text">
                Date Range is a filter that allows you to narrow down the news results displayed on a page based on a specific date range and quickly access and filter the news articles you want to read based on the date of publication. We have the calendar format of the date filter that allows you to select the exact day or range of days you want to view, providing a convenient and intuitive way to filter news articles.
            </div>
            <img className="help_center_img" src={DateFilter} style={{maxWidth: "650px", margin: "12px auto 0px"}}></img>


            <div className="help_center" id="filters">
                Filters
            </div>
            <div className="help_center_text">
                Filters enable you to easily find and access relevant news articles based on specific criteria. By using these filters, you can customize your browsing experience and access news articles that are most relevant to you, without having to go through irrelevant content. News filters include the following options: 
            </div>
            
            <ul style={{marginTop: 6, marginLeft: -6}}>
                <li><span style={{fontWeight: "600", marginTop: 4}}>Tags –</span> based on tags added to prospects</li>
                <li><span style={{fontWeight: "600", marginTop: 4}}>Assigned Team member –</span> based on the team member is who is assigned to prospects</li>
                <li><span style={{fontWeight: "600", marginTop: 4}}>Company –</span> based on the company of your prospects</li>
                <li><span style={{fontWeight: "600", marginTop: 4}}>College –</span>  based on the education of your prospects </li>
                <li><span style={{fontWeight: "600", marginTop: 4}}>Location –</span>  based on the city where your prospects are located</li>
                <li><span style={{fontWeight: "600", marginTop: 4}}>Title –</span> based on the title of your prospects</li>
                <li><span style={{fontWeight: "600", marginTop: 4}}>Description –</span> based on the description of the article, why is it mentioned</li>
            </ul>
            <img className="help_center_img" src={OtherFilters} style={{maxWidth: "650px", margin: "12px auto 0px"}}></img>

            <div className="help_center">
                Search news articles
            </div>
            <div className="help_center_text">
                The search news articles feature allows you to search for specific news articles by entering keywords or phrases into the search box. This feature is particularly useful when you are looking for specific news content or want to find articles on a particular topic, published corresponding to your prospects. 
                <br/><br/><span style={{fontWeight: 600}}>How to search</span><br/>
                i. Enter keywords or phrases in the search box<br/>
                ii. Click on Enter to get the desired results<br/>      
            </div>
            <img className="help_center_img" src={NewsfeedSearch} style={{maxWidth: "650px", maxHeight: 321, margin: "12px auto 0px"}}></img>


            <div className="help_center" id="seen_unseen">
                Seen/Unseen filter
            </div>
            <div className="help_center_text">
                A Seen/Unseen filter allows you to easily sort through articles based on whether you have already read/seen them or not. This is especially helpful when you visit the website frequently and want to quickly catch up on the latest news. <br/><br/>By selecting the "unseen" option, you would be able to view only the articles you have not yet read, while the "seen" option would display the articles you have already read. This helps you save time and avoid having to scroll through the same articles repeatedly. 
            </div>
            <img className="help_center_img" src={UnseenFilter} style={{maxWidth: "650px", maxHeight: "321px", margin: "12px auto 0px"}}></img>

            <div className="help_center" id="export_news">
                Export news articles
            </div>
            <div className="help_center_text">
                You can export the news articles from the high confidence tab using the export button. This is useful to save articles for future reference, share them with others, or even analyze them for research purposes. 
            </div>
            <img className="help_center_img" src={ExportNews} style={{maxWidth: "650px", maxHeight: 350, margin: "12px auto 0px"}}></img>
            <div className="help_center_text">
                Depending on the purpose of the export, you may prefer to apply filters to the articles before exporting them. For example, if you want to export news articles of all the prospects with a particular tag, you can apply the filter first and then export. On the other hand, if you want to save all the articles, you may choose to export without applying any filters. Both the options cater to the diverse needs.
            </div>

        </div>
    );
};