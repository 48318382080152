import React, {useState, useEffect} from 'react'

import {apiAgent} from './axiosConfig';
import {Button} from './components/cta_utils/AnButton.js'

import {AnInputBox, AnSuggestionBox} from './components/input_utils/AnInput.js'

import LogoutHeader from './components/headers/logoutHeader.js'
import TryAlmaFree from './components/logout_components/tryAlmaFree.js'

import {updateCurrentUser} from './redux/currentUserSlice.js'
import { useDispatch, useSelector } from 'react-redux'
import {BrowserRouter, Route, Switch} from "react-router-dom";
import SignupScreen from './signupScreen';
import { Link } from 'react-router-dom';

class LoginScreen extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      email: '',
      password: '',
      isLoading: false,
      errors: {}
    };
  }

  onSignupSucess = (response) => {
    this.props.onLoginSuccess(response);
  }

  onEmailInput = (event) => {
    this.setState({
      email: event.target.value,
      errors: {...this.state.errors, email: ""}
    })
  }

  onPasswordInput = (event) => {
    this.setState({
      password: event.target.value,
      errors: {...this.state.errors, password: ""}
    })
  }
  onFormSubmit = () => {
    var errors = {}, error = false;
    if(!this.state.email) {
      errors["email"] = "No account found for this email\n";
      error = true;
    }
    if(!this.state.password) {
      errors["password"] = "Please enter Password.";
      error = true;
    }
    this.setState({...this.state, errors: errors});
    if(error)
      return;
    this.setState({...this.state, isLoading: true});
    apiAgent({
      method: 'post',
      url: '/users/login',
      data: {
        email: this.state.email.toLowerCase(),
        password: this.state.password,
      }
    }).then(
      (response) => {
        this.props.onLoginSuccess(response.data);
      },
      (error)  => {
        this.isLoading = false;
        if(error.response.data)
          this.setState({...this.state, errors: {"password": error.response.data.error}});
        this.setState({...this.state, isLoading: false})
      }
    );
  }

  render(){
    return (
        <React.Fragment>
          <div className="login_component_container">
            <div className="text_title_big" style={{textAlign: 'center'}}>
              Log in to your account
            </div>
            <div style={{marginTop: '24px'}}>
              <AnInputBox label={"Email"} error={this.state.errors["email"]}>
                <input type="text" id="email" value={this.state.email} onChange={this.onEmailInput} placeholder="johndoe@domain.com"/>
              </AnInputBox>
            </div>
            <div style={{marginTop: '8px'}}>
              <AnInputBox label={"Password"} error={this.state.errors["password"]}>
                <input type="password" id="password" value={this.state.password} onChange={this.onPasswordInput} placeholder="password"/>
              </AnInputBox>
            </div>
            <Link to="/forgot_password" className='text_link hover_under_line' >
              Forgot password?
            </Link>
            <div className='btn_login'>
              <Button buttonSize="Big" onClick={this.onFormSubmit} isLoading={this.state.isLoading} buttonStyle={{width: '100%'}}>
                Log in
              </Button>
            </div>
            <div className="text_body_light" style={{marginTop: '16px', textAlign: 'center'}}>
              Don’t have an account?  <a href="/signup" className="text_link hover_under_line">Sign up</a>
            </div>
          </div>
        </React.Fragment>
    );
  }
}

export default LoginScreen;
