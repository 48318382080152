import { CenturyView } from "react-calendar";
import Publishnews1 from "../../assets/Publishnews1.png";
import Teams from "../../assets/edit institutes 1.png";
import RssCandidates from "../../assets/Email contents 1.png";
import Aliasing from "../../assets/Aliasing 1.png";
import { Link } from "react-router-dom";
import { CompaniesPage } from "./companies";
import { useSelector } from "react-redux";
import ConditionalRender from "../../ConditionalRender";
import useCheckUserAccess from "../custom_hooks/useCheckUserAccess";

const Panel = (SUB_SECTIONS) => {
    let elements=[];
    elements.push(
        <div style={{gap: 12, display: 'grid', gridTemplateColumns: 'repeat(3, 200px)'}}>     
            { Object.keys(SUB_SECTIONS).map((key) => (      
                <ConditionalRender if={SUB_SECTIONS[key].access}>                       
                    <Link to={SUB_SECTIONS[key].url} className="text_link" style={{marginLeft: '12px', width: '200px', height: '200px', background: '#FAFBFC', display: 'inline-block', border: '1px solid #E7ECEB', textDecoration: 'none'}}>   
                        <div className="container">
                            <img src={SUB_SECTIONS[key].source} style= {{display: 'block', marginLeft: 'auto', marginRight: 'auto', marginTop: 29}} />
                            <div className="text" style={{ marginTop: '26px', textAlign: 'center', color: "#707274"}}>
                                    {key}
                            </div>
                        </div>
                    </Link>
                </ConditionalRender>
            ))}
        </div>) 
    return elements;
}

const AdminPanel = () => {
    const current_user = useSelector(state => state.current_user.user)
    const super_admin = current_user.is_super_admin;
    const publisher = current_user.is_super_admin || current_user.is_publisher;
    const checkUserAccess = useCheckUserAccess();
    const SUB_SECTIONS = {
        "Smart Publish News": {url: "/admin/smart_publish", source: Publishnews1, access: checkUserAccess('page_smart_publish')},
        "Teams": {url: "/admin/teams", source: Teams, access: checkUserAccess('page_teams')},
        "Companies": {url: "/admin/companies", source: Teams, access: checkUserAccess('page_companies')},
        "RSS Candidates": {url: "/rss-candidates", source: RssCandidates, access: checkUserAccess('page_rss_candidates')},
        "RSS Sources": {url: "/rss-sources", source: Aliasing, access: checkUserAccess('page_rss_sources')},
        "Articles": {url: "/articles", source: Aliasing, access: checkUserAccess('page_articles')},
        "News Queries": {url: "/news_queries", source: Aliasing, access: checkUserAccess('page_news_queries')},
        "Team Stats": {url: "/admin/team_stats", source: Aliasing, access: checkUserAccess('page_team_stats')},
        "Team User Activities": {url: "/admin/activities", source: Aliasing, access: checkUserAccess('page_team_user_activities')},
        "Outbound Stats": {url: "/admin/outbound_stats", source: Aliasing, access: checkUserAccess('page_outbound_stats')},
        "Prospect Mappings": {url: "/admin/prospect_mappings", source: Aliasing, access: checkUserAccess('page_prospect_mappings')},
        "Flagged Posts": {url: "/admin/flagged_articles", source: Publishnews1, access: checkUserAccess('page_flagged_articles')},
        "System Generted Posts": {url: "/admin/system_generated_posts", source: Aliasing, access: checkUserAccess('page_system_generated_posts')},
        "Teams Dashboard": {url: "/admin/teams_dashboard", source: Aliasing, access: checkUserAccess('page_teams_dashboard')},
        "Categories": {url: "/admin/categories", source: Teams, access: checkUserAccess('page_categories')},
        "Linkedin Prospects": {url: "/admin/linkedin_prospects", source: Aliasing, access: checkUserAccess('page_linkedin_prospects')},
        "Linkedin Prospect Mapping": {url: "/admin/linkedin_prospect_mapping", source: Aliasing, access: checkUserAccess('page_linkedin_prospect_mapping')},
        "Job Updates Dashboard": {url: "/admin/job_updates_dashboard", source: Aliasing, access: checkUserAccess('job_updates_dashboard')},
        "RSS Experiments": {url: "/rss-experiments", source: Aliasing, access: checkUserAccess('page_rss_experiments')},
        "Mapper Suggestions": {url: "/admin/temp-mapper", source: Aliasing, access: checkUserAccess('page_temp_mappers')},
        "System Configuration": {url: "/admin/system_configuration", source: Aliasing, access: checkUserAccess('email_configuration')}
    };

    return (
       <div>
            <div className="text_title_big" style={{textAlign: 'center', marginTop: '38px'}}>
                Super Admin Panel
            </div>
            <div className="container" style={{display: 'flex', justifyContent: 'center', marginTop: '32px'}}>
                {Panel(SUB_SECTIONS)}
            </div>
        </div>
    );
};

export default AdminPanel;