import React from 'react'
import { useEffect } from 'react'
import { useCallback } from 'react'
import { useRef } from 'react'
import { useReducer } from 'react'
import { apiAgent } from '../../../axiosConfig'
import ConditionalRender from '../../../ConditionalRender'
import { Button } from '../../cta_utils/AnButton'
import { AnInputBox } from '../../input_utils/AnInput'
import { cssPath } from '../../utils/designUtils'
import { LoaderComponent } from '../../utils/LoaderComponent'
import getApiUrl from '../../utils/UrlHelper'

const SingleRssCandidate = (props) => {
    const [state, setState] = useReducer(reducer, {
        candidate: {
            article_selector: [""],
            negative_article_selector: [""]
        },
        sharableArticleUrl: "",
        externalWebContent: null,
        previewContent: {},
        isLoading: {},
        activeTab: "iframe",
        articleSelectors: [""],
        negativeArticleSelectors: [""],
        currentSelector: {},
        error: {},

    })
    const externalWebRef = useRef(null);

    const paintElemBox = useCallback((event) => {
        let elem = externalWebRef.current.contentWindow.document.elementFromPoint(event.clientX, event.clientY)
        if(elem){
            let targetSelector = cssPath(elem);
            let targetNodes = externalWebRef.current.contentWindow.document.querySelectorAll(targetSelector);
            const clickListener = (e) => {
                e.preventDefault();
                e.stopPropagation();
                if(state.currentSelector?.name){
                    let candidate = {...state.candidate};
                    candidate[state.currentSelector.name][state.currentSelector.index] = cssPath(e.target);
                    setState({payload: {candidate: candidate, currentSelector: {}}})
                }
            }
            const mouseOutListener = (e) => {
                for(let i=0; i<targetNodes.length; i++){
                    let node = targetNodes[i];
                    node.style.border = elem.getAttribute("prev_border");
                    node.removeAttribute("prev_border");
                    node.removeEventListener("mouseout", mouseOutListener);
                    node.removeEventListener("click", clickListener);
                }
            }

            for(let i=0; i<targetNodes.length; i++){
                let node = targetNodes[i];
                node.setAttribute("prev_border", elem.style.border);
                node.style.border = "2px dashed #fc9803";
                node.addEventListener("click", clickListener);
                node.addEventListener("mouseout", mouseOutListener);
            }
        }
    }, [state.currentSelector, state.candidate.active_selector, state.candidate.negative_article_selector])
    
    useEffect(() => {
        fetchRssCandidate();
    }, [])
    useEffect(() => {
        if(state.externalWebContent && state.activeTab === "iframe"){
            if(externalWebRef.current){
                setTimeout(() => {
                    if(state.activeTab === "iframe"){
                        externalWebRef.current.contentWindow.document.addEventListener("mouseover", paintElemBox)                        
                    }
                }, 1000)
                return () => {
                    if(externalWebRef.current)
                        externalWebRef.current.contentWindow.document.removeEventListener("mouseover", paintElemBox)
                }
            }
        }
    }, [state.externalWebContent, state.activeTab, paintElemBox])


    const fetchRssCandidate = () => {
        if(!props.match.params.id) return;
        setState({payload: {isLoading: {fetchingRssCandidate: true}}});
        apiAgent({
            method: "get",
            url: getApiUrl("get_rss_candidate", {":id": props.match.params.id})
        }).then(res => {
            let paramsString = window.location.search;
            let searchParams = new URLSearchParams(paramsString);
            let sharable_article_url = searchParams.get("article_url")
            let payload = {
                sharableArticleUrl: sharable_article_url,
                candidate: res.data.candidate,
                isLoading: {}
            };
            setState({payload: payload})
        }).catch(err => {
            setState({payload: {isLoading: {}}})
        })
    }

    const fetchExternalWebsite = () => {
        let {sharableArticleUrl} = state;
        if(!sharableArticleUrl) return;
        setState({payload: {isLoading: {fetchingExternalArticle: true}, error: {}}});
        apiAgent({
            method: "get",
            url: getApiUrl("get_rss_candidate_external_preview"),
            params: {sharable_article_url: sharableArticleUrl}
        }).then(res => {
            setState({payload: {externalWebContent: res.data.html_page, isLoading: {}}});
        }).catch(err => {
            setState({payload: {externalWebContent: null, isLoading: {}, error: {externalWeb: "Error fetching this link"}}});
        })
    }
    const fetchPreviewContent = () => {
        setState({payload: {previewContent: null, isLoading: {previewContent: true}}})
        let article_selector = (state.candidate.article_selector || []).filter(Boolean).join(",")
        let negative_article_selector = (state.candidate.negative_article_selector || []).filter(sel => sel).join(",")
        let params = {
            sharable_article_url: state.sharableArticleUrl,
            article_selector,
            negative_article_selector 
        }
        apiAgent({
          method: "get",
          url: getApiUrl("get_rss_candidate_preview"),
          params: params
        }).then(res => {
            setState({payload: {previewContent: res.data, isLoading: {}}})
        }).catch(err => setState({payload: {previewContent: null, isLoading: {}}}))
      }

    const handleTabSwitch = (tab) => {
        if(state.activeTab !== tab){
          if(state.activeTab === 'iframe'){
            fetchPreviewContent();
          }
            state.activeTab = tab;      
            setState({payload: {activeTab: tab}})
        }
      }
    const handleSelectMe = (e, option) => {  
        setState({payload: {currentSelector: {
            name: option.name,
            index: option.index
        }}})
    }

    const handleSharableUrlChange = (e) => {
        setState({payload: {sharableArticleUrl: e.target.value}});
    }
    
    const handleSelectorsChange = (event, index) => {
        let temp = [...state.candidate[event.target.name]];
        if(!event.target.value){
            temp.splice(index, 1)      
        }else{
            temp[index] = event.target.value;
        }
        setState({payload: {candidate:{...state.candidate, [event.target.name]: temp}, currentSelector: {}}})
    }

    const addSelectors = (event) => {
        let name = event.target.getAttribute("name")
        let temp = [...state.candidate[name]];
        temp.push("");
        setState({payload: {candidate: {...state.candidate, [name]: temp}, currentSelector: {}}})
    }

    const saveSelectors = () => {
        let article_selector = (state.candidate.article_selector || []).filter(Boolean).join(",")
        let negative_article_selector = (state.candidate.negative_article_selector || []).filter(Boolean).join(",")
        setState({payload: {isLoading: {addingSelectors: true}}})
        let data_params = {rss_candidate_id: state.candidate.id, sharable_article_url: state.sharableArticleUrl};
        if(article_selector.length) data_params.article_selector = article_selector
        if(negative_article_selector.length) data_params.negative_article_selector = negative_article_selector
        apiAgent({
          method: "post",
          url: getApiUrl("post_update_selectors"),
          data: data_params
        }).then(res => {
            setState({payload: {isLoading: {}}})
        }).catch(err => {
            setState({payload: {isLoading: {}}})
        })
      }
    if(state.isLoading.fetchingRssCandidate) return <LoaderComponent isLoading={true}></LoaderComponent>
    return (
        <>
            <div className='default_flex_container' style={{gap: 24, alignItems: "flex-start"}}>
                <div style={{width: "100%"}}>
                    <div className='default_flex_container show-tabs' style={{padding: '0px 24px', borderBottom: '1px solid #E5E5E5'}}>
                        <div className={state.activeTab ==='iframe' ?'active-tab':''} 
                        style={{padding: '16px', borderBottom: '4px solid #fff'}}
                        onClick={(e) => handleTabSwitch('iframe')}
                        >
                            Link
                        </div>
                        <div className={state.activeTab === 'preview' ?'active-tab':''} 
                        style={{padding: '16px', borderBottom: '4px solid #fff'}}
                        onClick={(e) => handleTabSwitch('preview')}
                        >
                            Parsed Data
                        </div>
                    </div>
                    <ConditionalRender if={state.isLoading.fetchingExternalArticle || state.isLoading.previewContent}>
                        <LoaderComponent isLoading={true}></LoaderComponent>
                    </ConditionalRender>
                    <ConditionalRender if={state.candidate?.id && !(state.isLoading.fetchingExternalArticle || state.isLoading.previewContent)}>
                        <ConditionalRender if={state.activeTab === "iframe"}>
                            <ConditionalRender if={state.externalWebContent}>
                                <iframe id="external-web-content" ref={externalWebRef} 
                                    srcDoc={state.externalWebContent}
                                    width="100%"
                                    style={{margin: "24px auto", display: "block", height: "80vh", overflow: "scroll"}}
                                    allow="payment 'none';"
                                    // sandbox="allow-scripts"
                                ></iframe>
                            </ConditionalRender>
                            <ConditionalRender if={state.error.externalWeb}>
                                <div style={{textAlign: 'center', margin: "24px auto"}} className="text_subtitle_big">{state.error.externalWeb}</div>
                            </ConditionalRender>
                        </ConditionalRender>
                        <ConditionalRender if={state.activeTab === 'preview' }>
                            <div className='text_body_light'>
                            {Object.keys(state.previewContent || {}).map(k => {
                                return(
                                <div key={k} style={{marginTop: 24}}>
                                    <div className='text_subtitle_big'>{k}</div>
                                    <div style={{marginTop: 8}}>{state.previewContent[k]}</div>
                                </div>
                                )
                            })}
                            </div>
                        </ConditionalRender>
                    </ConditionalRender>
                </div>
                <div style={{maxWidth: 300, width: "100%"}}>
                    <div className='text_title'>Host</div>
                    <div className='text_subtitle_light'>{state.candidate?.host}</div>
                    <div  className="line_seperator_ex_light_grey" style={{ margin: "12px auto 24px" }}></div>        
                    <ConditionalRender if={state.isLoading.candidateSelectors}>
                        <LoaderComponent isLoading={true}></LoaderComponent>
                    </ConditionalRender>
                    <ConditionalRender if={!state.isLoading.candidateSelectors}>
                        <AnInputBox>
                            <input value={state.sharableArticleUrl} 
                            onChange={handleSharableUrlChange}
                            placeholder="Add Url" />
                        </AnInputBox>
                        <Button onClick={fetchExternalWebsite}
                            innerButtonStye={{padding: "8px 22px"}}
                            isLoading={state.isLoading.fetchingExternalArticle}
                            >Fetch me</Button>
                        <div className='text_subtitle_big' style={{marginTop: 20}}>
                            Add Selector                            
                        </div>
                        <ConditionalRender if={state.candidate.selectors_updated_at}>
                            <div className='text_caption_light' style={{marginBottom: "12px"}}>(Last updated: {state.candidate.selectors_updated_at})</div>
                        </ConditionalRender>
                        {
                            state.candidate.article_selector.map((sel, ind) => {
                            let is_active = state.currentSelector.name === "article_selector" && state.currentSelector.index === ind
                            return(
                                <div key={ind} className={`default_flex_container + ${is_active ? 'box_highlighter' : ''}`} style={{gap: 16, alignItems: "center"}}>
                                <AnInputBox style={{width: "100%"}}>
                                    <input value={state.candidate.article_selector[ind]}
                                    onChange={(e) => {handleSelectorsChange(e, ind)}}
                                    name="article_selector"
                                    placeholder="Add selector to include" />
                                </AnInputBox>
                                <Button onClick={(e) => {handleSelectMe(e, {name: "article_selector", index: ind})}}
                                    innerButtonStye={{padding: "8px"}}
                                >me</Button>
                                </div>
                            )
                            })
                        }
                        <div className="text_link_small" style={{cursor: 'pointer'}} onClick={addSelectors} name="article_selector">+ Add</div>
                        <div className='text_subtitle_big' style={{marginTop: 20}}>
                            Exclude Selector
                        </div>
                        {
                            state.candidate.negative_article_selector.map((sel, ind) => {
                            let is_active = state.currentSelector.name === "negative_article_selector" && state.currentSelector.index === ind
                            return(
                                <div key={ind} className={`default_flex_container + ${is_active ? 'box_highlighter' : ''}`} style={{gap: 16, alignItems: "center"}}>
                                <AnInputBox style={{width: "100%"}}>
                                    <input value={state.candidate.negative_article_selector[ind]}
                                    onChange={(e) => {handleSelectorsChange(e, ind)}}
                                    name="negative_article_selector"
                                    placeholder="Add selector to exclude" />
                                </AnInputBox>
                                <Button onClick={(e) => {handleSelectMe(e, {name: "negative_article_selector", index: ind})}}
                                    innerButtonStye={{padding: "8px"}}
                                >me</Button>
                                </div>
                            )
                            })
                        }
                        <div className="text_link_small" style={{cursor: 'pointer'}} onClick={addSelectors} name="negative_article_selector">+ Add</div>
                        <div className='default_flex_container' style={{alignItems: "center", gap: 16, marginTop: "20px"}}>
                            <Button onClick={saveSelectors}
                            innerButtonStye={{padding: "8px 22px"}}
                            isLoading={state.isLoading.addingSelectors}
                            >
                            Save
                            </Button>                
                        </div>
                    </ConditionalRender>
                </div>
            </div>
        </>
    )
}

const reducer = (state, action) => {
    switch(action.type){        
        default: return {...state, ...action.payload};
      }
}
export default SingleRssCandidate