export const PastEducation = ({edu}) => {
    return (
        <div className='flex_container_space_between'>
        <div>
            <div className="text_label">{edu.institute_name}</div>
            <div className='text_body_light'>
                {edu.course}{edu.branch && <span>, </span>}
                {edu.branch}{edu.from_year && <span>, </span>}
                {edu.from_year}{edu.to_year && <span> - </span>}
                {edu.to_year}
            </div>
        </div>
        </div>
    )
}