
import React from 'react'

const TableRows = ({
    rowClass,
    customStyles={},
    ...props
}) => {
  return (
    <tr className={rowClass} style={customStyles}>
        {props.children}
    </tr>
  )
}

export default TableRows