export const PrivacyPolicy = () => {
    return (
        <div style={{ marginTop: 24 }}>
        <div className="help_center_top_section">
            <div className="text_title_big">Privacy Policy</div>
        </div>
            <div style={{ marginTop: 12 }}>
                This privacy policy and general privacy notice applies to Alma
                Labs, Inc. (t/a AlmaConnect) (“AlmaConnect” or “Company” or
                “We”). Your privacy is extremely important to us and we are
                committed to fair practices that protect your personal data. We
                have developed this Privacy Policy to communicate, clearly and
                transparently, what information we collect, how we collect it
                and what we do with it when you access and use our websites and
                products.
                <br />
                <br />
                By using any of our Sites or Services, you agree that your
                information will be handled as described in these Policies, to
                the extent applicable. Your use of our Services, the Site or
                cookies, and any dispute over privacy, is subject to these
                Policies and the possible Agreement we have with you, including
                its applicable limitations on damages and the resolution of
                disputes.
            </div>
            <div className="text_subtitle_big" style={{ marginTop: 24 }}>
                Influencer Policy (ICM)
            </div>
            <div style={{ marginTop: 8 }}>
                If you are a journalist and you believe your information is
                included in the Alma Labs database, please contact{" "}
                <span className="">admin@almaconnect.com</span> for additional
                information regarding our processing ofjournalist information.
            </div>
            <div className="text_subtitle_big" style={{ marginTop: 24 }}>
                Information We Collect
            </div>
            <div style={{ marginTop: 6 }}>
                Alma Labs, Inc. collects several types of information about you
                directly from you, automaticallythrough your use of the Site or
                our Services, and through third parties. We collect
                informationfrom you when you request information from us or
                otherwise interact with our Site and ourServices. The type of
                information that we collect from you depends on your particular
                interaction with our Site.
                <br />
                <br />
                Such information is utilized in providing an efficient and
                effective experience to all users. When we provide the Services
                to you, your data is stored on our computers and servers. In
                somecases, the data may be accessible to people involved with
                the operation of the site(administration, legal, system
                administration) appointed, as necessary, as Data Processors by
                us. We collect the following information:
            </div>
            <div className="square_bullet_list" style={{ marginTop: 22 }}>
                <div
                    className="black_square_points"
                    style={{ marginTop: 6 }}
                ></div>
                <div className="black_sqaure_li_item">
                    <span>Identity Data:</span> may include name, personal or
                    job title and position, location, or similar identifier.
                </div>
            </div>
            <div className="square_bullet_list" style={{ marginTop: 8 }}>
                <div
                    className="black_square_points"
                    style={{ marginTop: 6 }}
                ></div>
                <div className="black_sqaure_li_item">
                    <span>Contact Data:</span> may include physical address,
                    email address and telephone number
                </div>
            </div>
            <div className="square_bullet_list" style={{ marginTop: 8 }}>
                <div
                    className="black_square_points"
                    style={{ marginTop: 6 }}
                ></div>
                <div className="black_sqaure_li_item">
                    <span>Transaction Data:</span> may include requests for
                    products and services you have made to us, or in relation to
                    products or services that we have supplied to you.
                </div>
            </div>
            <div className="square_bullet_list" style={{ marginTop: 8 }}>
                <div
                    className="black_square_points"
                    style={{ marginTop: 6 }}
                ></div>
                <div className="black_sqaure_li_item">
                    <span>Technical Data:</span> may include IP address, browser
                    type and version, time zone setting andlocation, operating
                    system, platform and other technology on the devices used to
                    accessthis Website.
                </div>
            </div>
            <div className="square_bullet_list" style={{ marginTop: 8 }}>
                <div
                    className="black_square_points"
                    style={{ marginTop: 6 }}
                ></div>
                <div className="black_sqaure_li_item">
                    <span>Usage Data:</span> may include enquiries submitted by
                    you, information supplied, and how youuse our website.
                </div>
            </div>
            <div style={{ marginTop: 6 }}>
                We may also collect non-personal data such as aggregated data
                which is data that may be obtained from your personal data, but
                which does not directly or indirectly identify you. This may
                include Profile and Usage Data detailing how you use our Website
                and areas that you have interacted with.
            </div>

            <div className="text_subtitle_big" style={{ marginTop: 40 }}>
                How do we collect your personal data?
            </div>
            <div style={{ marginTop: 6 }}>
                We collect your data from direct interactions with us in person,
                by phone, email or otherwise or where you have supplied your
                Identity and Contact Data.
                <br />
                <br />
                We also collect your data through automated technologies or
                interactions with our website, including use of the web enquiry
                form where we may collect in addition Transaction, Technical,
                Profile and Usage Data and Marketing Data.
            </div>

            <div className="text_subtitle_big" style={{ marginTop: 40 }}>
                Use of your information
            </div>
            <div style={{ marginTop: 20 }}>
                We may hold and process personal data that you provide to us in
                accordance with the GDPR.
                <br />
                <br />
                The information that we collect and store relating to you is
                primarily used:
            </div>
            <ul style={{ marginTop: 12, paddingLeft: 20 }}>
                <li>
                    To enable us to provide services to you and to communicate
                    with you. This may include Identity, Contact, and
                    Transactional data.
                </li>
                <li>
                    To notify you about any changes to our business, such as
                    improvements to our Website or service/product changes, that
                    may affect our service or relationship with you. This may
                    include Identity and Contact Data.
                </li>
                <li>
                    If you are an existing customer or prospective customer, we
                    may contact you with information about goods and services
                    similar to those that were the subject of a previous sale to
                    you or enquiry made, or a discussion held with you. This may
                    include Identity and Contact Data.
                </li>
                <li>
                    To tailor the content and information that we may send or
                    display to you, to offer location customization, and
                    personalized help and instructions, and to otherwise
                    personalize your experiences while using the Sites or our
                    Services.
                </li>
                <li>
                    To better understand how users access and use the Services,
                    both on an aggregated and individualized basis, in order to
                    improve our Services to you and everyone.
                </li>
                <li>
                    To ensure that content from our Site is presented as
                    optimally as possible for you, your computer, and your web
                    browser.
                </li>
                <li>
                    Where you have consented to receive our e-newsletters to
                    provide that to you. This may include Identity and Contact
                    Data.
                </li>
                <li>
                    Where we need to comply with a legal obligation. This may
                    include Identity, Contact, Transactional and Technical Data.
                </li>
                <li>
                    Where it is necessary for our legitimate interests (or those
                    of a third party) and your interests and fundamental rights
                    do not override those interests. This may include all types
                    of data
                </li>
            </ul>
            <div style={{ marginTop: 32 }}>
                We will only use your personal data for a reason other than the
                purpose for which it was originally obtained if we consider that
                we need to use it for that other purpose and have a legitimate
                interest in doing so.
            </div>
            <div className="text_subtitle_big" style={{ marginTop: 52 }}>
                Disclosure of Your Information
            </div>
            <div style={{ marginTop: 20 }}>
                We may disclose Personal Information that we collect or you
                provide as described in this Policy:
            </div>
            <ul style={{ marginTop: 25, paddingLeft: 20 }}>
                <li>
                    To our service providers, which provide services for us
                    including but not limited to sending emails on our behalf,
                    processing payments, providing analytics services, and
                    helping us provide services as requested by users. Our
                    service providers are bound bylaw or contract to protect
                    Personal Information and are only allowed to use Personal
                    Information in accordance with the terms of our service
                    agreements with them. We DONOT share, sell, rent, or trade
                    Personal Information with third parties for their own
                    promotional purposes, and we will only share Personal
                    Information as outlined herein or with your consent.
                </li>
                <li style={{ marginTop: 28 }}>
                    We may also disclose your Personal Information: (1) To
                    comply with any court order, law, or legal process,
                    including to respond to any government or regulatory
                    request; (2) To enforce or apply our Terms of Services and
                    other agreements, including for billing and collection
                    purposes; and (3) If we believe disclosure is necessary or
                    appropriate to protect the rights, property, or safety of
                    Alma Labs, Inc.., our customers, or others. This includes
                    exchanging information with other companies and
                    organizations for the purposes of fraud protection and
                    credit risk reduction.
                </li>
            </ul>
            <div className="text_subtitle_big" style={{ marginTop: 58 }}>
                Links
            </div>
            <div style={{ marginTop: 6 }}>
                Our Site may, from time to time, contain links to and from the
                websites of our partner networks, advertisers, and affiliates.
                If you follow a link to any of these websites, please note that
                these websites have their own privacy policies and that we do
                not accept any responsibility or liability for their policies.
                Please check their policies before you submit any personal
                information
            </div>

            <div className="text_subtitle_big" style={{ marginTop: 58 }}>
                Controlling the use of your data
            </div>
            <div style={{ marginTop: 6 }}>
                Where we rely on consent as the lawful basis for processing your
                data you can revoke or vary that consent at any time.
                <br />
                <br />
                If you do not want us to use your data or want to vary the
                consent that you have provided you can email us at
                admin@almaconnect.com at any time.
            </div>

            <div className="text_subtitle_big" style={{ marginTop: 49 }}>
                Data Security
            </div>
            <div style={{ marginTop: 6 }}>
                We use appropriate technical, organizational, and administrative
                security measures to protect any information we hold in our
                records from loss, misuse, and unauthorized access, disclosure,
                alteration, and destruction. We will also take all reasonable
                precautions to ensure that our staffand employees who have
                access to personal data about you have received adequate
                training.
            </div>

            <div className="text_subtitle_big" style={{ marginTop: 58 }}>
                Data Retention
            </div>
            <div style={{ marginTop: 6 }}>
                We process and store your personal data only for the period
                necessary to achieve the purpose of storage. If the storage
                purpose is not applicable, or if a storage period prescribed by
                the European legislator or another competent legislator expires,
                the personal data are routinely blocked or erased in accordance
                with legal requirements.
            </div>

            <div className="text_subtitle_big" style={{ marginTop: 40 }}>
                Use of cookies
            </div>
            <div style={{ marginTop: 6 }}>
                Our websites use cookies (small text files containing a string
                of alphanumeric characters that we put on your computer) and
                other similar tracking technologies (like web beacons, tags and
                scripts) to uniquely identify your browser and to gather
                information about how you interact withthe websites. Some
                cookies collect information about browsing behavior when you
                access this website via the same computer or device. This
                includes information about pages viewed, products purchased and
                your journey around a website. We do not use cookies to collect
                or record information on your name, address, or other contact
                details.
            </div>
            <div style={{ marginTop: 16 }}>
                <div>We use this information for the following purposes:</div>
                <ul style={{ margin: 0, paddingLeft: 20 }}>
                    <li>Assisting you in navigation</li>
                    <li>
                        Assisting in registration, login, and your ability to
                        provide feedback
                    </li>
                    <li>Analysing your use of our website</li>
                    <li>
                        Assisting with our promotional and marketing efforts
                        (including behavioral advertising).
                    </li>
                </ul>
            </div>
            <div
                style={{
                    marginTop: 20,
                    padding: 24,
                    border: "1px solid #E7ECEB",
                    borderRadius: 4,
                }}
            >
                <div className="text_title">How do I disable cookies?</div>
                <div style={{ marginTop: 8 }}>
                    If you want to disable cookies, you can change your website
                    browser settings to reject cookies. How you can do this will
                    depend on the browser you use. Further details on how to
                    disable cookies for the most popular browsers are set out
                    below:
                </div>
                <div className="text_subtitle" style={{ marginTop: 12 }}>
                    A. Microsoft Internet Explorer:
                </div>
                <ol style={{ marginTop: 8 }}>
                    <li>Choose the menu “tools” then “Internet Options”</li>
                    <li>Click on the “privacy” tab</li>
                    <li>Select the appropriate setting</li>
                </ol>

                <div className="text_subtitle" style={{ marginTop: 20 }}>
                    B. Google Chrome:
                </div>
                <ol style={{ marginTop: 8 }}>
                    <li>Choose Settings  Advanced</li>
                    <li>
                        Under “Privacy and security,” click “Content settings”
                    </li>
                    <li>Click “Cookies”</li>
                </ol>

                <div className="text_subtitle" style={{ marginTop: 20 }}>
                    C. Safari
                </div>
                <ol style={{ marginTop: 8 }}>
                    <li>Choose Preferences  Privacy</li>
                    <li>Click on “Remove all Website Data”</li>
                </ol>

                <div className="text_subtitle" style={{ marginTop: 20 }}>
                    D. Mozilla Firefox
                </div>
                <ol style={{ marginTop: 8 }}>
                    <li>Choose the menu “tools” then “Options”</li>
                    <li>Click on the icon “privacy”</li>
                    <li>
                        Find the menu “cookie” and select the relevant options
                    </li>
                </ol>

                <div className="text_subtitle" style={{ marginTop: 20 }}>
                    E. Opera 6.0 and further
                </div>
                <ol style={{ marginTop: 8 }}>
                    <li>Choose the menu “Files” “Preferences”</li>
                </ol>
            </div>
            <div className="text_title" style={{ marginTop: 60 }}>
                Alma Labs, Inc. and the GDPR
            </div>
            <div className="text_subtitle_big" style={{ marginTop: 8 }}>
                Your rights as a data subject
            </div>
            <div style={{ marginTop: 8 }}>
                You as a data subject have many possibilities to impact how your
                personal information is being processed. You can:
            </div>
            <div>
                (a) Request access to your information we process.
                <br />
                (b) Withdraw your consent if our use of your information is
                based on consent.
                <br />
                (c) Request that your information to be deleted or for you to be
                forgotten (right to erasure).
                <br />
                (d) Request that we correct or change your information. As a
                customer you may also do this by logging in to your account.
                <br />
                (e) Limit, restrict or object to our use of your information.
                <br />
                (f) Access your information and/or receive it.
                <br />
                (g) Subject to applicable law, lodge a complaint with your local
                data protection authority.
                <br />
            </div>
            <div style={{ marginTop: 24 }}>
                Please note that if you request to remove your information, we
                may retain some of the information for specific reasons, such as
                to resolve disputes, troubleshoot problems, and asrequired by
                law. Furthermore, some information is never completely removed
                from our databases due to technical constraints and the fact
                that we regularly back up our systems. Therefore, not all of
                your personal information will ever be completely removed from
                our databases.
            </div>
            <div className="text_title" style={{ marginTop: 46 }}>
                Controller and Data Protection Officer
            </div>
            <div style={{ marginTop: 8 }}>
                This website is provided by Alma Labs, Inc. 3411 Silverside
                Road, STE 104, Wilmington, NewCastle, DE 19810, USA. Alma Labs,
                Inc. is the controller for the purpose of all applicable
                dataprotection legislation and is subject to the investigatory
                and enforcement powers of the Federal Trade Commission (the
                “FTC”). If you have any questions about this Policy or the use
                of your personal information, you are always welcome to contact
                us at admin@almaconnect.com. You will also reach our Data
                Protection Officer, Dhruv at the same address. For our office
                details, please visit:{" "}
                <a
                    className="text_link_big hover_under_line"
                    href="https://www.almaconnect.com/contact_us"
                >
                    www.almaconnect.com/contact_us
                </a>
            </div>
            <div className="text_title" style={{ marginTop: 38 }}>
                Alma Labs and the APPI
            </div>
            <div style={{ marginTop: 8 }}>Your rights as a data subject</div>
            <div style={{ marginTop: 8 }}>
                You as a data subject have many possibilities to impact how your
                personal information is being processed. You can:
            </div>
            <div>
                (a) Request access to your information we process.
                <br />
                (b) Withdraw your consent if our use of your information is
                based on consent.
                <br />
                (c) Request that your information be deleted (right to erasure)
                if your information is incorrect, used beyond the necessary
                scope to achieve the utilization purposes or obtained by deceit
                or other improper means.
                <br />
                (d) Request that we correct or change your information if your
                information is incorrect. As a customer you may also do this by
                logging in to your account.
                <br />
                (e) Limit, restrict or object to our use of your information if
                your information is used beyond the necessary scope to achieve
                the utilization purposes or obtained by deceit or other improper
                means.
                <br />
                (f) Subject to applicable law, lodge a complaint with your local
                data protection authority.
                <br />
            </div>
            <div style={{ marginTop: 24 }}>
                Please note that if you request to remove your information, we
                may retain some of the information for specific reasons, such as
                to resolve disputes, troubleshoot problems, and asrequired by
                law. Furthermore, some information is never completely removed
                from our databases due to technical constraints and the fact
                that we regularly back up our systems. Therefore, not all of
                your personal information will ever be completely removed from
                our databases.
            </div>
            <div style={{ marginTop: 14 }}>
                <div className="text_subtitle_big">
                    Identity and contact details of the Data Controller
                </div>
                <div style={{ marginTop: 8 }}>
                    If you have any questions about this Policy or the use of
                    your personal information, you are always welcome to contact
                    us at admin@almaconnect.com. You will also reach our Data
                    Protection Officer, Dhruv at the same address. For our
                    office details, please visit:{" "}
                    <a
                        className="text_link_big hover_under_line"
                        href="https://www.almaconnect.com/contact_us"
                    >
                        www.almaconnect.com/contact_us
                    </a>
                </div>
            </div>

            <div className="text_title" style={{ marginTop: 32 }}>
                Alma Labs and the CCPA
            </div>
            <div className="text_subtitle_big" style={{ marginTop: 8 }}>
                Your rights under the CCPA
            </div>
            <div style={{ marginTop: 8 }}>
                You have rights which impact how your information is being
                processed, including:
            </div>
            <div>
                (a) The right to know what information is collected, used,
                shared or sold.
                <br />
                (b) The right to delete (erasure right) information processed by
                us.
                <br />
                (c) The right to opt-out of the sale of your information. If you
                are under the age of 16, we willonly sell your information if
                you opt-in, with your parent or guardian consenting if you
                areunder 13.
                <br />
                (d) Request that we correct or change your information if your
                information is incorrect. As acustomer you may also do this by
                logging in to your account.
                <br />
                (e) Limit, restrict or object to our use of your information if
                your information is used beyond the necessary scope to achieve
                the utilization purposes or obtained by deceit or other improper
                means.
                <br />
                (f) Subject to applicable law, lodge a complaint with your local
                data protection authority.
                <br />
            </div>
            <div className="text_subtitle_big" style={{ marginTop: 54 }}>
                Submitting a personal information or erasure request
            </div>
            <div style={{ marginTop: 8 }}>
                You may ask us to disclose certain information to you about our
                collection and use of your personal information over the past 12
                months. Once we receive and confirm your identity as auser of
                our Service, we can disclose that information to you. You may
                also make a request to have that information erased or deleted.
                <br />
                <br />
                You can make a request for your information twice every 12
                months. If you are making an erasure request, it will help us if
                you include details of the information you would like erased. We
                may need to ask you for additional information to fully identify
                you. If we can't identify you, we may not be able to erase your
                information.
                <br />
                <br />
                Please note that if you request that we remove your information,
                we may retain some of the information for specific reasons, such
                as to resolve disputes, troubleshoot problems, and asrequired by
                law. Furthermore, some information is never completely removed
                from our databases due to technical constraints and the fact
                that we regularly back up our systems. Therefore, not all of
                your personal information will ever be completely removed from
                ourdatabases.
                <br />
                <br />
                We will respond to you on these requests as quickly as received
                your note but it may take us upto 45 days. If we need more time
                than that, we will let you know.
            </div>
            <div style={{ marginTop: 40 }}>Opt-out request</div>
            <div style={{ marginTop: 8 }}>
                If you are a resident of California and you want to make it
                clear to us that you do not want us to sell your personal
                information, contact us at admin@almaconnect.com.
            </div>
            <div className="text_subtitle_big" style={{ marginTop: 38 }}>
                International Data Transfers
            </div>
            <div style={{ marginTop: 8 }}>
                Alma Labs, Inc. is a global company and information that we
                collect from or about you within the European Economic Area
                (“EEA”), or within any country located outside of the EEA, may
                be transferred across international borders to third countries
                outside of the EEA, or the country inwhich you are located, that
                do not have laws providing an adequate level of data protection.
                We as the Data Controller (or Data Exporter) will do our best to
                ensure that your information is protected no matter where it is
                transferred to.
                <br />
                <br />
                To ensure adequate protection for personal information we enter
                into Data Processing Agreements with our suppliers, including
                the ones who process your personal information outside of the
                non-EEA country in which you are located or the EEA. When
                necessary, we utilize approved transfer mechanisms (such as
                adequacy decisions or the Standard Contractual Clauses in the
                EU) to ensure the necessary protection of your personal
                information.
            </div>
            <div className="text_subtitle_big" style={{ marginTop: 46 }}>
                Opting out of promotional or information emails fromAlma Labs
            </div>
            <div style={{ marginTop: 8 }}>
                We may send periodic promotional or informational emails to you.
                You may opt-out of such communications by following the opt-out
                instructions contained in the email. If you opt-out of receiving
                emails about recommendations or other information we think may
                interest you, we may still send you emails about your account or
                any services you have requested or received from us.
            </div>
            <div className="text_subtitle_big" style={{ marginTop: 38 }}>
                Changes to the Policies
            </div>
            <div style={{ marginTop: 8 }}>
                We may change the Policy from time to time due to changed or
                updated legislation and/or business standards. All changes to
                this Policy are posted on this page and we encourage you to
                review our Policy regularly to stay informed.
            </div>
            <div className="text_subtitle_big" style={{ marginTop: 40 }}>
                Contact Information
            </div>
            <div style={{ marginTop: 8 }}>
                To ask questions or comment about this Privacy Policy and our
                privacy practices, contact us at: admin@almaconnect.com
            </div>
        </div>
    );
};
