import { useEffect, useReducer, useRef } from "react";
import MapperReducer, { ALL_MAPPER_ACTIONS, initialMapperData } from "./reducers/TempMapperReducer";
import { export_mapper_sugg, fetch_temp_mappers } from "./api";
import MapperFiltersReducer, { ALL_MAPPER_FILTER_ACTIONS, initialMapperFilterState } from "../filters/MapperFilters/FiltersStateReducer";
import TagsFilter from "../filters/MapperFilters/filters/TagsFilter";
import OrderFilter from "../filters/MapperFilters/filters/OrderFilter";
import ConfidenceFilter from "../filters/MapperFilters/filters/ConfidenceFilter";
import { MapperFilterProvider } from "./contexts/mapper_filter_context";
import { TempMapperProvider } from "./contexts/temp_mapper_context";
import TableData from "./_TableData";
import Pagination from "../../../Pagination";
import ConditionalRender from "../../../ConditionalRender";
import { IsCurrentPath } from "../../utils/locationPath";
import { AnConfirmPopup } from "../../cta_utils/AnConfirmPopup";
import { SummaryPopup } from "./popups/SummaryPopup";
import { GenerateSuggestionPopup } from "./popups/GenerateSuggestoinPopup";
import { SuggestionMapPopup } from "./popups/SuggestionMapPopup";
import { ClearSuggestionPopup } from "./popups/ClearSuggestionPopup";
import { ExportPopup } from "./popups/ExportPopup";
import { FlashMessages } from "../../cta_utils/FlashMessages";
import { LoaderComponent } from "../../utils/LoaderComponent";
import { SuggestionDropdown } from "./dropdowns/SuggestionDropdown";
import DownloadImage from '../../../assets/download.png'
import MenuDots from "../../../assets/MenuDots.svg"
import { setState } from "../../utils/CommonReducer";


const AlgorithmSuggestion = ({...props}) => {
    const [pageData, pageDataReducer] = useReducer(MapperReducer, initialMapperData);
    const [filtersState, filtersStateReducer] = useReducer(MapperFiltersReducer, initialMapperFilterState);
    const {popupsList, successMessages} = pageData
    const loader = useRef('not-loaded')
    const setPageData = (payload) => pageDataReducer({type: ALL_MAPPER_ACTIONS['SET_STATE'], payload: payload})
    const setSuccessMessages = (payload) => setPageData({successMessages: payload})
    const handlePopupClose = () => setPageData({popupsList: {}})
    const toggleDropDown = () => setPageData({dropDownList: {...initialMapperData.dropDownList}})
    useEffect(() => {
        fetchAlgoSugg(1)   
    },[filtersState.order, filtersState.range, filtersState.sortings, filtersState.selectedTags])
    const fetchAlgoSugg = (page) => {
        let valueData = {...filtersState}
        let data_params = {
            page: page,
            tags: valueData.selectedTags,
            order: valueData.order,
            confidence: {min: valueData.range.min, max: valueData.range.max},
            sortings: valueData.sortings,
            name: valueData.search.name
            // list_name: valueData.list_name,
            // mappedFilter: valueData.mappedFilter
        }
        setPageData({isFetching: {loadingPageData: true}})
        fetch_temp_mappers(data_params).then(
            (res) => {
                setPageData({
                    items: res.data.data,
                    totalPages: res.data.total_pages,
                    totalCount: res.data.total,
                    currentPage: page,
                    isFetching: {loadingPageData: false},
                    no_of_mapped: res.data.no_of_mapped,
                    no_of_unmapped: res.data.no_of_unmapped,
                    average_confidence_of_mapped: res.data.average_confidence_of_mapped,
                    no_of_no_sugg: res.data.no_of_no_sugg
                });
                if(loader.current === 'not-loaded'){
                    setState(filtersStateReducer)({suggestionTags: [...res.data.all_suggestion_tags].map((tag) => ({...tag, is_selected: false}))})
                    loader.current = 'loaded'
                }
                props.setParentState({
                    mappedTotalCount: res.data.total_mapped,
                    unMappedTotalCount: res.data.total_unmapped,
                })
            },
            (error) => {
                setPageData({...pageData, isFetching: {loadingPageData: false}});
            }
        );
    };
    return (
        <div>
            <MapperFilterProvider filtersState={filtersState} filtersStateReducer={filtersStateReducer} handleFilterApply={() => fetchAlgoSugg(1)}>
            <div style={{padding: '0px 32px 0px'}}>
            <div className="default_flex_container">
                <div style={{marginRight: 16}}>
                    <TagsFilter/>
                </div>
                <div style={{marginRight: 16}}>
                    <OrderFilter/>
                </div>
                <div>
                    <ConfidenceFilter/>
                </div>
                <ConditionalRender if={filtersState.selectedTags.length  || filtersState.order > 0 || (filtersState.range.min && filtersState.range.max)}>
                <div className="text_body_big_light_semi_bold" style={{marginLeft: 16, cursor: 'pointer'}} onClick={() => filtersStateReducer({type: ALL_MAPPER_FILTER_ACTIONS['RESET_ALL']})}>
                    Reset all
                </div>
                </ConditionalRender>
            </div>
            <div style={{ clear: 'both' }}></div>
            <div className='default_flex_container' style={{ marginTop: 24 }}>
                <div className="text_subtitle_big">{pageData.totalCount} Suggestions</div>
                <div style={{ flex: 1 }}>
                    <Pagination totalPages={pageData.totalPages}
                        currentPage={pageData.currentPage}
                        onPageChange={fetchAlgoSugg}
                    />
                </div>
                    <div onClick={() => {setPageData({popupsList: {exportPopup: true}})}} className="default_flex_container"
                         style={{ cursor: "pointer", width: "36px", height: "36px", border: '1px solid #e6e6e6', padding: '6px 6px', background: "#F8F8F8", borderRadius: 3}}>
                        <img src={DownloadImage} style={{height: 20, width: 20}}></img>
                    </div>
                    <ConditionalRender if={!IsCurrentPath('/mapper-suggestions')}>
                    <div onClick={() => setPageData({ dropDownList: { suggestionDropdown: true } } )}
                        style={{ marginLeft: 8, position: 'relative', cursor: "pointer", width: "36px", height: "36px", border: '1px solid #e6e6e6', padding: '8px', background: "#F8F8F8", borderRadius: 3 }}>
                        <img src={MenuDots} style={{ width: 20, height: 20, verticalAlign: 'baseline' }}></img>
                        {pageData.dropDownList.suggestionDropdown && <SuggestionDropdown pageDataReducer={pageDataReducer} toggleDropDown={toggleDropDown}/>}
                    </div>
                    </ConditionalRender>
            </div>
            <ConditionalRender if={pageData.isFetching.loadingPageData}>
                <LoaderComponent isLoading={true}  loaderStyle={{width: 32, margin: '36px auto', display: 'block'}}/>
            </ConditionalRender>
            </div>
            <ConditionalRender if={!pageData.isFetching.loadingPageData}>
                <TempMapperProvider pageData={pageData} pageDataReducer={pageDataReducer} mappingType={"algo_sugg"}>
                    <TableData/>
                </TempMapperProvider>
            </ConditionalRender>
            {popupsList.exportPopup && <ExportPopup pageDataReducer={pageDataReducer} handlePopupClose={handlePopupClose}/>}
            {popupsList.summaryPopup && <SummaryPopup pageData={pageData} handlePopupClose={handlePopupClose}/>}
            {popupsList.generateSuggestionPopup && <GenerateSuggestionPopup pageDataReducer={pageDataReducer} handlePopupClose={handlePopupClose}/>}
            {popupsList.mapConfirmationPopup && <SuggestionMapPopup pageData={pageData} pageDataReducer={pageDataReducer} handlePopupClose={handlePopupClose}/>}
            {popupsList.clearSuggestionPopup && <ClearSuggestionPopup pageDataReducer={pageDataReducer} handlePopupClose={handlePopupClose}/>}
            {
                successMessages.message && (<FlashMessages messageTheme='success' duration={successMessages.duration} closeCallback={(message) => setSuccessMessages({...successMessages, message: message})}>{successMessages.message}</FlashMessages>)
            }
            </MapperFilterProvider>
        </div>
    )
}
export default AlgorithmSuggestion