import { useEffect, useMemo, useState } from 'react'
import FiltersIcon from '../../../../../assets/FiltersIcon.svg'
import { Button } from '../../../../cta_utils/AnButton'
import AnPopup from '../../../../cta_utils/AnPopup'
import { AnInputCheckBox } from '../../../../input_utils/AnInputCheckBox'
import { useMapperFilterContext } from '../../../new_temp_mapper/contexts/mapper_filter_context'
import { ALL_MAPPER_FILTER_ACTIONS } from '../FiltersStateReducer'
import { setState } from '../../../../utils/CommonReducer'
const TagsFilter = ({
    ...props
}) => {
    const [popupsList, setPopupsList] = useState({});
    const { filtersState, filtersStateReducer } = useMapperFilterContext();
    const handlePopupClose = () => setPopupsList({})
    const {suggestionTags} = filtersState
    const handleFilterApply = () => {
        let tempSelectedTag = filtersState.suggestionTags.filter(tag => tag.is_selected).map(tag => tag.key);
        setState(filtersStateReducer)({selectedTags: tempSelectedTag})
        handlePopupClose(); 
    }
    const renderTagsFilterPopup = () => {
        return (
            <AnPopup name="TagsFilterPopup" handleOnClosePopup={handlePopupClose}>
                <div className="text_title">
                    Tags
                </div>
                <div className="line_seperator_ex_light_grey" style={{ margin: "16px 0px" }}></div>
                <div className='tags_filter_container' style={{ height: 280, overflowY: 'scroll' }}>
                    {suggestionTags.map((tag) => {
                        return (
                            <AnInputCheckBox key={tag.key} label={tag.label} isChecked={tag.is_selected}>
                                <input type="checkbox" checked={tag.is_selected} onChange={() =>
                                    filtersStateReducer({ type: ALL_MAPPER_FILTER_ACTIONS["SELECT_TAGS"], payload: { selections: [tag.key] } })}
                                ></input>
                            </AnInputCheckBox>
                        )
                    })}
                </div>
                <div className="line_seperator_ex_light_grey" style={{ margin: "16px 0px" }}></div>
                <div className='flex_container_space_between'>
                    <div
                        className="text_link"
                        onClick={() =>
                            filtersStateReducer({type: ALL_MAPPER_FILTER_ACTIONS['CLEAR_TAGS']})
                        }
                    >
                        Clear filters ({filtersState.suggestionTags.filter((tag) => tag.is_selected).length})
                    </div>
                    <div style={{ display: "flex" }}>
                        <Button
                            buttonSize="Medium"
                            type="Secondary"
                            innerButtonStye={{ padding: "8px 28px" }}
                            onClick={handlePopupClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            buttonSize="Medium"
                            innerButtonStye={{ padding: "8px 30px" }}
                            buttonStyle={{ marginLeft: 12 }}
                            onClick={() => { handleFilterApply();}}
                            isLoading={false}
                        >
                            Apply
                        </Button>

                    </div>
                </div>
            </AnPopup>
        )
    }
    return (
        <div>
            <div style={{ width: 'max-content' }}>
                <div className="default_flex_container filter_btn" style={{ padding: 10, borderRadius: 6 }}
                    onClick={() => {if(suggestionTags.length > 0) setPopupsList( { tagsFilterPopup: true } )}}>
                    <div className='text_body_big_light_semi_bold' style={{ marginRight: 8 }}>Tags</div>
                    {
                        filtersState.selectedTags.length > 0 ? (
                            <div className='applied_filters_box default_flex_container' style={{ justifyContent: 'center' }}>
                                <span >{filtersState.selectedTags.length}</span>
                            </div>
                        ) : (
                            <img src={FiltersIcon} style={{ width: 20, height: 20 }}></img>
                        )
                    }
                </div>
            </div>
            {popupsList.tagsFilterPopup && renderTagsFilterPopup()}
        </div>
    )
}

export default TagsFilter