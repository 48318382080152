import { useEffect, useMemo, useReducer } from "react";
import ConditionalRender from "../../../ConditionalRender";
import Pagination from "../../../Pagination";
import { IsCurrentPath } from "../../utils/locationPath";
import { useTempMapperContext } from "./contexts/temp_mapper_context"
import TableReducer, { ALL_TABLE_ACTIONS, findLastDisplayTrueIndex, initialTableState } from "./reducers/TableReducer";
import { ALL_MAPPER_ACTIONS } from "./reducers/TempMapperReducer";
import TableHeads from "../../visualizers/tabular/TableHeads";
import TableRows from "../../visualizers/tabular/TableRows";
import cross from '../../../assets/cross.svg'
import { ColumnFilterDropDown } from "../filters/MapperFilters/filters/_ColumnFilterDropDown";
import { HiddenColumnFilter } from "../filters/MapperFilters/filters/HiddenColumnFilter";
import { ColumnSearch } from "../filters/MapperFilters/filters/ColumnSearch";
import searchIcon from '../../../assets/search.svg'
import blackMenuDots from '../../../assets/directory/black_menu_dot.svg'
const TableData = ({
    ...props
}) => {
    const { pageData, pageDataReducer, mappingType } = useTempMapperContext();
    const [tableState, tableStateReducer] = useReducer(TableReducer, initialTableState);
    const { tableData, all_columns } = tableState
    const setTableState = (payload) => tableStateReducer({ type: ALL_TABLE_ACTIONS['SET_STATE'], payload: payload })
    useEffect(() => {
        if(pageData.items?.length > 0)
        tableStateReducer({ type: ALL_TABLE_ACTIONS['SET_TABLE_DATA'], payload: { items: pageData.items, pageDataReducer: pageDataReducer, mappingType: mappingType } })
    }, [pageData.items])
    const handleHeaderClick = (field) => {
        if(field.search_enabled) setTableState({searchBarActive: {[field.key]: true}})
    }
    const displayTrueFields = useMemo(() => {
        return all_columns[0].fields.filter(field => field.display === true);
    }, [all_columns[0].fields]) 
    const renderDataTable = () => {
        return (
            <table className='mapper_suggestions_data_table'>
                <TableHeads typoGraphyClass="text_body_big_light" headerClass="table_header_row" customStyles={{position: 'relative'}}>
                    {
                        all_columns[0].fields.map((field, index) => {
                            // let searchActiveOuterStyle = tableState.searchBarActive[field.key] ? {padding: 0} : field.search_enabled ? {padding: 12} : {}
                            let searchActiveInnerStyle = tableState.searchBarActive[field.key] ? {padding: '6px 0px'} : field.search_enabled ? {padding: 12} : {}
                            return (
                                <ConditionalRender if={field.display}>
                                    <th className="movable_th" key={field.key} style={{ ...field.labelStyle }}>
                                        <div style={{background: 'white'}}>
                                        <div className="column_hover" style={{...searchActiveInnerStyle, position: 'relative', cursor: field.search_enabled ? 'pointer': 'default' }} onClick={() => handleHeaderClick(field)}>
                                        {tableState.searchBarActive[field.key] ? <ColumnSearch toggleSearchBar={(e) => { e.stopPropagation(); setTableState({ searchBarActive: {} }) }} /> :
                                            <div className='default_flex_container' style={{whiteSpace: 'nowrap'}}>
                                                        <ConditionalRender if={field.search_enabled}>
                                                        <div style={{marginRight: 6}}>
                                                            <img src={searchIcon} height={24} />
                                                        </div>
                                                        </ConditionalRender>
                                                        <div>{field.label}</div>
                                                        <ConditionalRender if={field.dropDownEnabled}>
                                                            <ColumnFilterDropDown field_key={field.key} column_index={0} tableState={tableState} tableStateReducer={tableStateReducer} positionRight = {displayTrueFields[1]?.key === field.key}/>
                                                        </ConditionalRender>
                                            </div>
                                        }
                                        </div>
                                        </div>
                                    </th>
                                </ConditionalRender>
                            )
                        })
                    }
                    <HiddenColumnFilter tableState={tableState} tableStateReducer={tableStateReducer} />
                    {
                        all_columns[1].fields.map((field, index) => {
                            let is_fixed = all_columns[1].is_fixed && index === 0
                            let fixedInnerStyle = is_fixed ? { borderLeft: '1px solid #E0E3EB', height: '100%'} : {};
                            return (
                                <th key={field.key} style={{...field.labelStyle, zIndex: 5}}>
                                    <div style={{background: 'white'}}>
                                    <div className="column_hover" style={{...fixedInnerStyle, position: 'relative', width: '100%'}}>
                                        <div className='default_flex_container'style={{whiteSpace: 'nowrap'}} >
                                            <div>{field.label}</div>
                                            <ConditionalRender if={field.dropDownEnabled}>
                                                <ColumnFilterDropDown field_key={field.key} column_index={1} tableState={tableState} tableStateReducer={tableStateReducer} />
                                            </ConditionalRender>
                                        </div>
                                    </div>
                                    </div>
                                </th>
                            )
                        })
                    }
                </TableHeads>
                <tbody>
                    {tableData.map((item, index) => {
                        return (
                            <TableRows key={index} rowClass="table_content_row_seperated text_subtitle_semi_bold" customStyles={{position: 'relative'}}>
                                {
                                    all_columns[0].fields.map((field) => {
                                        return (
                                            <ConditionalRender if={field.display}>
                                            <td className="movable_td" key={field.key} style={{...field.labelStyle}}>
                                                {item[field.key]}
                                            </td>
                                            </ConditionalRender>
                                        )
                                    })
                                }
                                <td style={{width: '38px', background: 'transparent', zIndex: 4}}></td>
                                {
                                    all_columns[1].fields.map((field, index) => {
                                        let is_fixed = all_columns[1].is_fixed && index === 0
                                        let fixedOuterStyle = is_fixed ? { padding: 0, height: 1} : {};
                                        let fixedInnerStyle = is_fixed ? { borderLeft: '1px solid #E0E3EB', height: '100%', padding: '14px', display: 'flex', alignItems: 'center'} : {};
                                        return (
                                            <td key={field.key} style={{...fixedOuterStyle, ...field.labelStyle, zIndex: 4}}>
                                                <div style={{...fixedInnerStyle, width: '100%'}}>
                                                {item[field.key]}
                                                </div>
                                            </td>
                                        )
                                    })
                                }
                            </TableRows>
                        )
                    })}
                </tbody>
            </table>
        )
    }
    return (
        <div style={{ margin: '24px 0px', width: '100%' }}>
            <div className="mapper_suggestion_container">
            {renderDataTable()}
            </div>
        </div>
    )
}
export default TableData;