import { apiAgent } from "../../../axiosConfig";

export const categoriesFetcher = (params) => apiAgent({
    method: "get",
    url: "/categories",
    params: params
})

export const categoryCreateOrEdit = (params) => apiAgent({
    method: "post",
    url: "/categories/create_or_update",
    data: params
})