import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import ConditionalRender from "../../../../../ConditionalRender";
import { apiAgent } from "../../../../../axiosConfig";
import { indexOfObject } from "../../../../utils/ArrayUtils";

const MINCHAR = 2;

export const FilterSuggestionBox = ({ 
    filtersState, 
    filterType="include", 
    filterTab, 
    prop_params, 
    customInputStyle,
    lens_style,
    setShowSearchedRes,
    suggestionRenderer,
    ...props }) => {
    const [fetchedRes, setFetchedRes] = useState([]);
    const [userInput, setUserInput] = useState("");
    const [isFetching, setIsFetching] = useState(false);
    const [activeOption, setActiveOption] = useState(0);
    const [showOptions, setShowOptions] = useState(false);

    useEffect(() => {
        if (userInput.length == 0) resetAll();
        if(setShowSearchedRes) setShowSearchedRes(showOptions)
    }, [showOptions]);

    const debouncedHandleChange = useCallback(
        debounce((ip) => {
            setIsFetching(true);
            let data_params = prop_params || {}
            // let data_params = {
            //     tags: filtersSelected.tags,
            //     assigned: filtersSelected.assigned,
            //     title: filtersSelected.title,
            //     company: filtersSelected.company,
            //     college: filtersSelected.college,
            //     location: filtersSelected.location,
            //     miscellaneous: filtersSelected.miscellaneous
            // };
            apiAgent({
                method: "get",
                url: props.suggestionUrl,
                params: {
                    term_query: ip,
                    term: filterTab.search_term,
                    ...data_params,
                },
            })
                .then((res) => {
                    setIsFetching(false);
                    setFetchedRes(res.data.aggregates);
                    setShowOptions(true);
                    if(props.filtersStateReducer) {
                        let aggregates = res.data.aggregates;
                        let mappedKey = filterTab.tab_key;
                        props.filtersStateReducer({type: "ASSIGN_SEARCHED_AGGREGATES", payload: {aggregates, filterType, mappedKey}})
                    }
                })
                .catch((e) => {
                    setIsFetching(false);
                    setShowOptions(true);
                });
        }, 300),
        [filtersState]
    );

    const dupped = () => {
        let {aggregatesData} = filtersState;
        let temp = {...aggregatesData[filterType]}
        let aggregates = [...temp[filterTab.tab_key]]
        return (
            aggregates.filter((val) => val.term == fetchedRes[activeOption].term).length > 0
        );
    };

    const resetAll = () => {
        setFetchedRes([]);
        setActiveOption(0);
        setShowOptions(false);
        setUserInput("");
    };

    const applyAlreadyExisting = () => {
        let val = fetchedRes[activeOption].term;
        let mappedKey = filterTab.tab_key;
        let { filtersSelected } = filtersState;
        let index = indexOfObject(filtersSelected[filterType][mappedKey], "value", val);
        if (index === -1) {
            let new_val = [...filtersSelected[filterType][mappedKey], {value: val}];
            props.filtersStateReducer({
                type: "SELECTED_FILTER",
                payload: { 
                    mappedKey: mappedKey, 
                    selections: new_val , 
                    selectedVal: val, 
                    select: true,
                    filterType,
                    insertAt: "PREPEND"
                },
            });
        } else {
            let temp = [...filtersSelected[filterType][mappedKey]];
            temp.splice(index, 1);
            props.filtersStateReducer({
                type: "SELECTED_FILTER",
                payload: { mappedKey: mappedKey, selections: temp, filterType },
            });
        }
    };

    const handleOnClick = () => {
        if (!dupped())
            props.filtersStateReducer({
                type: "SELECT_AND_MODIFY",
                payload: {
                    mappedKey: filterTab.tab_key,
                    data: fetchedRes[activeOption],
                    filterType
                },
            });
        else {
            applyAlreadyExisting();
        }
        resetAll();
    };

    const handleOnChange = (e) => {
        let userInput = e.target.value;
        setUserInput(userInput);
        if (userInput.length >= MINCHAR) {
            debouncedHandleChange(userInput);
        }
        if (userInput.length == 0) {
            resetAll();
        }
    };

    const handleOnKeyDown = (e) => {
        if (e.keyCode === 13 && fetchedRes.length > 0) {
            // Return
            if (!dupped())
                props.filtersStateReducer({
                    type: "SELECT_AND_MODIFY",
                    payload: {
                        mappedKey: filterTab.tab_key,
                        data: fetchedRes[activeOption],
                        filterType
                    },
                });
            else {
                applyAlreadyExisting();
            }
            resetAll();
        } else if (e.keyCode === 38) {
            // Up
            if (activeOption === 0) {
                return;
            }
            setActiveOption(activeOption - 1);
        } else if (e.keyCode === 40) {
            // Down
            if (activeOption === fetchedRes.length - 1) {
                return;
            }
            setActiveOption(activeOption + 1);
        }
    };

    const defaultRenderer = () => {
        if (showOptions && fetchedRes.length > 0) {
            return (
                <ul
                    className="suggestions_container"
                    style={{ listStyle: "none" }}
                >
                    {fetchedRes.map((optionName, index) => {
                        let className = "suggested_item text_body_light";
                        if (index === activeOption) {
                            className += " active_option text_body_light";
                        }
                        return (
                            <li
                                className={className}
                                key={optionName.term}
                                onClick={handleOnClick}
                                onMouseEnter={() => {
                                    setActiveOption(index);
                                }}
                            >
                                {optionName.term}
                            </li>
                        );
                    })}
                </ul>
            );
        } else if (showOptions) {
            return (
                <ul
                    className="suggestions_container"
                    style={{ listStyle: "none" }}
                >
                    <li
                        className="suggested_item text_body_light"
                        key="NoResults"
                    >
                        No Results
                    </li>
                </ul>
            );
        }
    }
    const renderOptionList = () => {
        if(suggestionRenderer)
        return <suggestionRenderer.component showsearchedRes={showOptions}/>
        else
        return defaultRenderer();
    };

    let computed_classes = props.inputClass || "an_input_box";
    computed_classes = props.error
        ? computed_classes + " an_input_error"
        : computed_classes;

    return (
        <div style={props.style}>
            <ConditionalRender if={showOptions && !suggestionRenderer}>
                <div
                    style={{ inset: "0", position: "fixed" }}
                    onClick={resetAll}
                ></div>
            </ConditionalRender>
            <ConditionalRender if={props.label}>
                <div className="text_caption_light">{props.label}</div>
            </ConditionalRender>
            <div className={computed_classes}>
                <div style={{ position: "relative" }}>
                    <input
                        type="text"
                        value={userInput}
                        placeholder={props.placeholder}
                        onChange={handleOnChange}
                        onKeyDown={handleOnKeyDown}
                        style={customInputStyle}
                    ></input>
                    <ConditionalRender if={props.inputClass}>
                        <span className="an_search_lens" style={lens_style}>
                            <img src="/search.svg" style={{width: '100%', height: '100%'}} />
                        </span>
                    </ConditionalRender>
                    <ConditionalRender if={isFetching}>
                        <div
                            style={{
                                position: "absolute",
                                width: 20,
                                right: 8,
                                top: 0,
                                height: "100%",
                                textAlign: "center",
                                fontSize: 0,
                            }}
                        >
                            <div className="vertical_middle"></div>
                            <div
                                style={{
                                    display: "inline-block",
                                    verticalAlign: "middle",
                                }}
                                className="loader-green4"
                            >
                                <div className="loader-box loader_box1"></div>
                                <div className="loader-box loader_box2"></div>
                                <div className="loader-box loader_box3"></div>
                                <div style={{ clear: "both" }}></div>
                            </div>
                        </div>
                    </ConditionalRender>
                </div>
                <div style={{ position: "relative" }}>{renderOptionList()}</div>
            </div>
        </div>
    );
};
