import { apiAgent } from "../../../axiosConfig"

export const fetchListData = (data_params, searchTerm) => {
    return apiAgent({
        method: "get",
        url: `/linkedin_prospect/auto_sync_lists`,
        params: data_params
    })
}

export const fetchSyncData = (data_params, searchTerm) => {
    return apiAgent({
        method: "get",
        url: `/linkedin_prospect/get_all_syncs`,
        params: data_params
    })
}

export const ListAddSync = (data_params) => {
    return apiAgent({
        method: "post",
        url: `/linkedin_prospect/list/${data_params.id}/add_sync`,
        data: {syncable_ids: data_params.syncable_ids}
    })
}

export const ListAddRemark = (id, data_params) => {
    return apiAgent({
        method: "post",
        url: `/linkedin_prospect/list/${id}/add_remark`,
        data: data_params
    })
}

export const EditSyncUrl = (id, data_params) => {
    return apiAgent({
        method: "post",
        url: `/linkedin_prospect/list/${id}/create_or_update_sync_url`,
        data: data_params
    })
}

export const toggleSyncable = (id) => {
    return apiAgent({
        method: "post",
        url: `/linkedin_prospect/ln_sync_url/${id}/toggle_syncable`,
    })
}