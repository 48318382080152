export const WEEKLY = "Weekly";
export const DAILY = "Daily";
export const THREE_ARTICLES = "Whenever there are 3 articles";
export const FIVE_ARTICLES = "Whenever there are 5 articles";
export const NEVER = "Never";


export const TYPE_ALL = "All";
export const TYPE_ASSIGNED_TO_ME = "Have been assigned to me";



export const NOTIFICATIONS_FREQUENCIES = [WEEKLY, DAILY, THREE_ARTICLES, FIVE_ARTICLES, NEVER]
export const NOTIFICATIONS_TYPES = [TYPE_ALL, TYPE_ASSIGNED_TO_ME]