import React, { useEffect } from 'react'
import { apiAgent } from '../../axiosConfig';
import { LoaderComponent } from '../../components/utils/LoaderComponent';

const ConfirmSuccess = (props) => {  
  useEffect(() => {      
      const params = new URLSearchParams(window.location.search);
      const user_id=params.get("id");
      const signup_source = params.get("source");
      apiAgent({
        method: 'post',
        url: `/confirm`,
        data: {
            id: user_id
        }
    }).then(res => {
          let redirect_path = (signup_source === "pipedrive") ? "/complete_profile?source=pipedrive" : "/complete_profile"          
          props.onSignupSucess(res.data, redirect_path)
      })    
    }, [])
    
  return (
    <LoaderComponent isLoading={true} type="FULL_PAGE" loaderStyle={{ width: 64}}>
    </LoaderComponent>
  )
}

export default ConfirmSuccess