import { useState } from "react"
import AnPopup from "../../../cta_utils/AnPopup"
import { AnInputCheckBox } from "../../../input_utils/AnInputCheckBox"
import { AnInputBox } from "../../../input_utils/AnInput"
import { Button } from "../../../cta_utils/AnButton"
import { update_list } from "./api"
import ConditionalRender from "../../../../ConditionalRender"
import Tooltip from "../../../cta_utils/Tooltip"
import InfoIcon from "../../../../assets/Info.svg"

const ListSettings = ({
    handleOnClosePopup,
    list,
    setList,
    onSubmitCallback,
    ...props
}) => {
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const handleOnChange = (e) => {
        let temp_list = { ...list }
        temp_list[e.target.name] = !temp_list[e.target.name]
        setList(temp_list)
    }
    const validateForm = () => {
        let errors = {}
        //if(list.sync_frequency > 90) errors = {job_update_freq: "Sync frequency should not be greater than 90"};
        return errors;
    }
    const handleSubmit = () => {
        let validate_errors = validateForm(); 
        if(Object.keys(validate_errors).length > 0){
            setErrors({...validate_errors});
            return;
        }
        setIsLoading(true);
        let data_params = {
            id: list.id,
            is_master_list: list.is_master_list,
            auto_prospects_creation: list.auto_prospects_creation,
            auto_sync: list.auto_sync,
            sync_frequency: list.sync_frequency
        }
        update_list(data_params).then(
            (res) => {
                onSubmitCallback();
                setIsLoading(false)
                setErrors({})
            }, (err) => {
                setErrors({ error_message: err.response.data.error_message })
                setIsLoading(false)
            })

    }
    return (
        <AnPopup name="listSettingsPopup" handleOnClosePopup={() => handleOnClosePopup()} maxWidth={320}>
            <div className='text_title'>List Settings</div>
            <div className="text_body_light">{list.title}</div>
            <div className="line_seperator_ex_light_grey" style={{ margin: "8px auto 20px" }}></div>
            <div className="list_settings">
                <AnInputCheckBox label="Master list" isChecked={list.is_master_list}>
                    <input type="checkbox" name="is_master_list" checked={list.is_master_list} onChange={handleOnChange}></input>
                </AnInputCheckBox>
                <AnInputCheckBox label="Auto prospect creation" isChecked={list.auto_prospects_creation}>
                    <input type="checkbox" name="auto_prospects_creation" checked={list.auto_prospects_creation} onChange={handleOnChange}></input>
                </AnInputCheckBox>
                <div className="default_flex_container">
                    <AnInputCheckBox label="Auto sync" isChecked={list.auto_sync}>
                        <input type="checkbox" disabled={!list.has_auto_sync_urls} name="auto_sync" checked={list.auto_sync} onChange={handleOnChange}></input>
                    </AnInputCheckBox>
                    <ConditionalRender if={!list.has_auto_sync_urls}>
                    <div className="default_flex_container" style={{marginLeft: 8, marginTop: 2}}>
                    <a className="text_link_extra_small underline" href="/admin/job_updates_dashboard?linkedinSyncPopup=true" target="_blank">Click here&nbsp;</a>
                    <div className="text_caption_small_light" style={{color: '#2A282C'}}> to add Sync URL</div>
                    </div>
                    <Tooltip
                        tooltipText={() => "Add LinkedIn Sync\nURL to the list to\nenable Auto Sync"}
                        innerClass='text_body_light'
                        textTheme='background-light-black top'
                        innerStyle={{ padding: '8px', alignSelf: 'center', color: 'white', borderRadius: '8px' }}
                        style={{textAlign: 'center', zIndex: '9999'}}
                        showWithoutOverflowing={true}
                        tooltipPos="top"
                    >
                        <div>
                            <img src={InfoIcon} alt="info" style={{ width: 16, height: 16 }}></img>
                        </div>
                    </Tooltip>
                    </ConditionalRender>
                </div>
            </div>
            <AnInputBox label={"Job update frequency"} style={{ marginBottom: 8 }} error={errors.job_update_freq}>
                <div className="default_flex_container">
                    <input type="text" name="sync_frequency" style={{ maxWidth: 100 }} value={list.sync_frequency} onChange={(e) => setList({ ...list, sync_frequency: e.target.value })} placeholder="7" />
                    <span className="text_body_light" style={{ marginLeft: 12 }}>days</span>
                </div>
            </AnInputBox>
            <div className='default_flex_container'>
                <Button onClick={handleSubmit}
                    buttonStyle={{ width: "120px" }}
                    isLoading={isLoading}
                >
                    Save
                </Button>
                <Button type="Secondary" buttonSize="Medium" buttonStyle={{ width: 'max-content', marginLeft: 16 }} innerButtonStye={{ padding: '8px 20px' }} onClick={() => {handleOnClosePopup();setErrors({})}}>
                    Cancel
                </Button>
            </div>
            <ConditionalRender if={errors.error_message}>
                <div className="text_caption_error" style={{ marginTop: 8 }}>{errors.error_message}</div>
            </ConditionalRender>
        </AnPopup>
    )
}
export default ListSettings