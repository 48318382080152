import { useEffect, useMemo, useReducer } from "react";

import { TabbedButton } from "../../cta_utils/AnButton";

import TempMapper from "./TempMapper";
import reducer from "../../utils/CommonReducer";
import PublishJobUpdates from "./PublishJobUpdates";
import { indexOfObject } from "../../utils/ArrayUtils";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const mappingTypes = [{label: 'Job Updates', key: 'job_updates'}, {label: 'Algorithm Suggestions', key: 'algo_sugg'}, {label: "Mapped", key: 'mapped'}, {label: 'Unmapped', key: 'unmapped'}];

const MapLinkedinProspectsPage = () => {
    const location = useLocation();
    let fromDirectory = useMemo(() => {
        return location.pathname === '/mapper-suggestions'
    }, []) 
    const [pageData, setPageData] = useReducer(reducer, {
        mappingType: fromDirectory ? "algo_sugg" : "job_updates",
        mappedTotalCount: null,
        unMappedTotalCount: null,     
    })    
    const setPageState = (payload) => {
        setPageData({type: "SET_STATE", payload})
    }
    const {mappingType, mappedTotalCount, unMappedTotalCount} = pageData;
    return (
        <>
            <div style={{padding: '32px 48px'}}>
                <div className="text_title_big">Mapper Suggestions</div>
                <div className="default_flex_container" style={{ gap: 24, borderBottom: "1px solid #B6B9BB", margin: '16px 0px', width: "max-content"}}>
                    {
                        mappingTypes.map(type => {
                            let is_active = mappingType === type.key
                            if(type.key === "job_updates" && fromDirectory) return;
                            return(
                            <div key={type.key}>
                                <TabbedButton buttonStyle={{padding: '0px 0px 10px', borderBottom: "none"}} isActive={is_active} onClick={() => setPageState({mappingType: type.key})}>
                                    {type.label} {(type.key === 'mapped' && mappedTotalCount ) ? `(${mappedTotalCount})` : (type.key === 'unmapped' && unMappedTotalCount) ? `(${unMappedTotalCount})` : ''}
                                </TabbedButton>
                            </div>
                        )})
                    }
                </div>
                {mappingType === "job_updates" ? <PublishJobUpdates /> : <></>}
                {mappingType !== "job_updates" ? <TempMapper mappingType={mappingType} setParentState={setPageState} fromDirectory={fromDirectory}/>:<></>}
            </div>
        </>
    )
}

export default MapLinkedinProspectsPage