import { useEffect } from "react"
import { useReducer } from "react"
import ConditionalRender from "../../../ConditionalRender"
import FileUpload from "../../../FileUpload"
import Pagination from "../../../Pagination"
import { Button } from "../../cta_utils/AnButton"
import AnPopup from "../../cta_utils/AnPopup"
import { FlashMessages } from "../../cta_utils/FlashMessages"
import { AnInputBox } from "../../input_utils/AnInput"
import { AnInputCheckBox } from "../../input_utils/AnInputCheckBox"
import { CommentsDisplay } from "../../input_utils/commentMentions"
import { indexOfObject } from "../../utils/ArrayUtils"
import { LoaderComponent } from "../../utils/LoaderComponent"
import getApiUrl from "../../utils/UrlHelper"
import TableHeads from "../../visualizers/tabular/TableHeads"
import TableRows from "../../visualizers/tabular/TableRows"
import { compainesFetcher, companyEditter } from "./apiHitters"


export const CompaniesPage = () => {
    const [companyPageData, companyPageDataReducer] = useReducer(reducer, {
        companyList: [],
        tempCompany: {},
        totalPages: 0,
        totalCompanies: 0,
        currentPage: 1,
        isLoading: {
            isFetching: true
        },
        query: {
            search_term: "",
        },
        popupsList: {},
    })
    useEffect(() => {
        fetchCompanies()
    }, [])
    
    const fetchCompanies = (page=1) => {
        companyPageDataReducer({
            type: "SET_STATE",
            payload: {
                isLoading: {isFetching: true}
            }
        });
        let data_params = {
            page: page,
            ...companyPageData.query
        }
        compainesFetcher(data_params).then( res =>
            companyPageDataReducer({
                type: "SET_STATE",
                payload: {
                    companyList: res.data.data,
                    isLoading: {},
                    currentPage: page,
                    totalPages: res.data.total_pages,
                    totalCompanies: res.data.total_companies
                }
            })
        ).catch(e => companyPageDataReducer({
                type: "SET_STATE",
                payload: {
                    currentPage: page,
                    isLoading: {}
                }
            })
        )
    }

    const handleUpdate = () => {        
        companyPageDataReducer({
            type: "SET_STATE",
            payload: {
                isLoading: {isSaving: true}
            }
        })
        companyEditter({...companyPageData.tempCompany}).then(res => {
            let {tempCompany, companyList} = companyPageData;
            let index = indexOfObject(companyList, "_id", tempCompany._id);
            companyList[index] = {...tempCompany};
            let payload = {
                companyList: [...companyList],
                successMessage: "Updated",
                isLoading: {},
                popupsList: {}
            }
            companyPageDataReducer({
                type: "SET_STATE",
                payload: payload
            })
        }).catch(e => {
            companyPageDataReducer({
                type: "SET_STATE",
                payload: {
                    isLoading: {}
                }
            })
        })
    }

    const handleOnChange = (e) => {
        let payload = {...companyPageData.query}
        payload[e.target.name] = e.target.value;
        companyPageDataReducer({
            type: "SET_STATE",
            payload: {
                query: payload
            }
        })
    }
    const handleCompanyEdit = (e) => {
        let tempComp = {...companyPageData.tempCompany}
        tempComp[e.target.name] = !tempComp[e.target.name]
        companyPageDataReducer({
            type: "SET_STATE",
            payload: {
                tempCompany: tempComp
            }
        })
    }
    const closeFlash = () => {
        companyPageDataReducer({type: "SET_STATE", payload: {successMessage: null}})

    }
    const setPopupsList = (popupsList={}) => {
        companyPageDataReducer({type: "SET_STATE", payload: {popupsList: popupsList}})
    }
    
    const renderEditPopup = () =>{
        const {tempCompany} = companyPageData        
        return (
            <AnPopup handleOnClosePopup={setPopupsList}>
                <div className="text_title">Edit</div>
                <div
                    className="line_seperator_ex_light_grey"
                    style={{ margin: "16px auto" }}
                ></div>
                <AnInputCheckBox label="Is generic" isChecked={tempCompany.is_generic}>
                    <input type="checkbox" name="is_generic" checked={tempCompany.is_generic} onChange={handleCompanyEdit}></input>
                </AnInputCheckBox>
                <Button onClick={handleUpdate} 
                    isLoading={companyPageData.isLoading.isSaving}
                    buttonStyle={{marginTop: "12px", width: "max-content"}}
                >
                    Save
                </Button>
            </AnPopup>
        )
    }

    const renderTableContent = () => {
        let {companyList, totalPages, currentPage} = companyPageData;
        return(
            <>
                <Pagination totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={fetchCompanies}
                    style={{padding: "16px 0px 0px"}}
                />

                <table className="data_table" style={{textAlign: "left", marginTop: "24px"}}>
                    <TableHeads typoGraphyClass="text_body_big_light" headerClass="table_header_row">
                        <th style={{width: "30%"}}>Primary</th>  
                        <th style={{width: "50%"}}>Terms</th>  
                        <th style={{width: "10%"}}>Generic</th>  
                        <th style={{width: "10%"}}>Edit</th>  
                    </TableHeads>
                    <tbody>
                        {
                            companyList.map((comp, index) => (
                                <TableRows key={comp._id} rowClass="table_content_row text_body">
                                    <td>{comp.primary_term}</td>
                                    <td>{comp.terms.join(", ")}</td>
                                    <td>{comp.is_generic ? "true" : "false"}</td>
                                    <td className="text_link" onClick={() => {
                                            companyPageDataReducer({
                                                type: "SET_STATE",
                                                payload: {
                                                    popupsList: {editPopup: true},
                                                    tempCompany: companyPageData.companyList[index]
                                                }
                                            })
                                        }}>Edit</td>
                                </TableRows>
                            ))
                        }
                    </tbody>
                </table>
            </>
        )
    }
    return(
        <div style={{margin: "33px 49px"}}>
            <div className="text_title">Companies</div>
            <div  className="line_seperator_ex_light_grey" style={{ margin: "8px auto 24px" }}></div>
            <div className='text_title' style={{marginBottom: "4px"}}>Update Companies in bulk</div>
            <div className='text_body_light'>
                Supported headers are: company_name, generic(true/false)
            </div>
            <FileUpload 
                style={{marginTop: "12px", maxWidth: "600px"}}
                submitUrl={getApiUrl("bulk_updates_companies")}
                uploaderSecondaryText={{content: "Supported headers are: company_name, generic(true/false)"}}
            />

            <div  className="line_seperator_ex_light_grey"  style={{ marginTop: "24px" }}></div>
            <div style={{margin: "16px auto"}}>
                <AnInputBox label="Search terms" style={{maxWidth: "300px"}}>
                    <input type="text" 
                        value={companyPageData.query.search_term}
                        name="search_term"
                        onChange={handleOnChange}
                        placeholder="Search"></input>
                </AnInputBox>
                <Button onClick={() => fetchCompanies(1)}>
                    Search
                </Button>
            </div>
            <LoaderComponent isLoading={companyPageData.isLoading.isFetching} />
            <ConditionalRender if={!companyPageData.isLoading.isFetching}>
                {renderTableContent()}
            </ConditionalRender>
            {companyPageData.popupsList.editPopup && renderEditPopup()}           
            {companyPageData.successMessage && (
                <FlashMessages messageTheme="success"
                    closeCallback={closeFlash}
                >{companyPageData.successMessage}</FlashMessages>
            )}
        </div>
    )
}

const reducer = (state, action) => {
    switch(action.type){
        case "SET_STATE": {
            return {...state, ...action.payload}
        }
        default: return state
    }
}