import Cookies from "universal-cookie";
import { apiAgent } from "../../axiosConfig";
import { COOKIE_SETTINGS } from "../../config/cookiesSettings";
import {
    updateCurrentUser,
    updateLoadError,
    updateLoadingStage,
} from "../../redux/currentUserSlice";

export const SET_USER = "SET_USER";
export const SET_USER_LOADING = "USER_LOADING";
export const SET_USER_ERROR = "USER_ERROR";

export const fetchCurrentUser = () => async (dispatch) => {
    dispatch(updateLoadingStage(true));
    await apiAgent({
        method: "get",
        url: "/users/validate",
        params: {},
    })
        .then((res) => {
            const cookies = new Cookies();
            cookies.set("api_key", res.data.api_key, {
                path: "/",
                maxAge: COOKIE_SETTINGS["AUTH_DURATION"],
            });
            dispatch(updateCurrentUser(res.data));
        })
        .catch((err) => {
            let timedout = (err.code === 'ECONNABORTED' || err.response?.status === 500)
            dispatch(updateLoadError({ error_message: "Unvalidated User", timedout}));
        });
};
