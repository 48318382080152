import {useState, useEffect} from 'react'
import {AnInputBox, AnSuggestionBox} from '../../input_utils/AnInput.js'
import {apiAgent} from '../../../axiosConfig.js'
import {Button} from '../../cta_utils/AnButton.js'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import axios from 'axios'
import InstituteSearch from '../../../assets/InstituteSearch.svg'

const FindYourTeam = (props) => {

  const [selectedTeam, setSelectedTeam] = useState()
  const [isLoading, setIsLoading] = useState(false)
  let history = useHistory()

  const selectSuggestionCallback = (name, value) => {
    setSelectedTeam(value)
  }
  const onRequestSubmit = () => {
    setIsLoading(true);
    apiAgent({
      method: 'post',
      url: '/team_members',
      data: {
        team_id: selectedTeam._id
      }
    }).then((response) => {
      setIsLoading(false);
      history.push('/requested')
    }).catch((error) => {
      console.log(`Request failed bcz of this error`, error)
      setIsLoading(false)
    })
  }
  return(
    <div style={{maxWidth: 510, margin: "32px auto 0px"}}>
      <img src={InstituteSearch} style={{display: 'block', maxWidth: 80, margin: 'auto'}}/>
      <div className="text_title_big" style={{margin: '24px auto 0px', textAlign: 'center'}}>
        Find your institution and join your teammates
      </div>
      <div className="text_body_light" style={{margin: '5px auto 0px', maxWidth: 340, textAlign: 'center'}}>
        Search for your institution here and request to join your teammates in AlmaNews
      </div>
      <div style={{maxWidth: 400, margin: 'auto'}}>
        <div style={{maxWidth: 400, margin:'32px auto 0px'}}>
          <AnSuggestionBox inputClass="an_search_box" suggestionUrl="/suggestions/teams" placeholder="Search institution" callback={selectSuggestionCallback}/>
        </div>
        <Button buttonSize="Big" buttonStyle={{width: "100%", marginTop: 24}} isLoading={isLoading} disabled={selectedTeam ? false : true} onClick={onRequestSubmit}>
          Request to Join
        </Button>
      </div>

      <div className="text_caption" style={{textAlign: 'center', marginTop: 16}} >
        Can’t find your institution? Please ask your teammates to send you an invite,
      </div>
    </div>
  )
}
export default FindYourTeam
