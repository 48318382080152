import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ConditionalRender from "../../../ConditionalRender";
import { apiAgent } from "../../../axiosConfig";
import useCheckUserAccess from "../../custom_hooks/useCheckUserAccess";
import AnPopup from "../AnPopup";
import { AnInputCheckBox } from "../../input_utils/AnInputCheckBox";
import { Button } from "../AnButton";
import { useMemo } from "react";
import { indexOfObject } from "../../utils/ArrayUtils";

export const AddAssigneePopup = ({
    prospectData,
    dispatcher,
    handlePopupClose,
    onAddedAssigneeCallback,
    ...props
}) => {
    let {assigneeList, currentAssigns, prospect_id, isLoading, lockedAssigns, profileAssigns} = prospectData;
    const submitBtnDisabled = useMemo(() => {
        if(profileAssigns?.length !== currentAssigns?.length) return false
        currentAssigns.forEach((assign) => {
            let index = indexOfObject(profileAssigns, "id", assign.id);
            if(index === -1)
                return false
        })
        return true
    }, [currentAssigns])
    const onAddAssignee = () => {
        dispatcher({isLoading: true})
        apiAgent({
            method: 'post',
            url: '/prospects/add_assignee',
            data: {
            prospect_id: prospect_id,
            assign_ids: currentAssigns
        }
        }).then(res => {
            onAddedAssigneeCallback();            
        }).catch(err => {
            dispatcher({isLoading: false})
        })
    }
    const handleAssignsChange = (e) => {
        let val = e.target.value
        let index = currentAssigns.indexOf(val)
        let temp = []
        if(index===-1) {
          temp = [...currentAssigns, val]
        }
        else{
          temp = [...currentAssigns]
          temp.splice(index, 1)
        }
        dispatcher({currentAssigns: temp})
      }

      return(
        <AnPopup name="addAssigneePopup" handleOnClosePopup={handlePopupClose}>
            <div className="text_title">
                Assign team members
            </div>
            <div style={{height: 1, background: '#E7ECEB', margin: '16px 0px'}}></div>
            {
                assigneeList.length > 0 ? (<div className="text_body_light" style={{marginBottom: 12}}>Team members</div>)
                    : (<div style={{textAlign: 'center', margin: '64px 0px 64px'}} className="text_subtitle">No Team Members are present.</div>)
            }
            {
                assigneeList.map((member, index) => {
                    let is_checked = currentAssigns.includes(member.id)
                    let is_disabled = lockedAssigns.includes(member.id);
                    return (
                        <AnInputCheckBox label={member.name} isChecked={is_checked} style={{marginBottom: 12}}>
                            <input type="checkbox" value={member.id} checked={is_checked} disabled={is_disabled} onChange={handleAssignsChange}/>
                        </AnInputCheckBox>
                    )
                })
            }
            <ConditionalRender if={assigneeList.length > 0}>
            <div style={{display: 'flex', marginTop: 4}}>
                <Button buttonSize="Medium" innerButtonStye={{padding: '8px 28px'}}
                    onClick={onAddAssignee} isLoading={isLoading} disabled={assigneeList.length==0 || submitBtnDisabled}>Assign</Button>
                <Button buttonSize="Medium" type="Secondary" buttonStyle={{marginLeft: 20}} innerButtonStye={{padding: '8px 28px'}}
                    onClick={handlePopupClose} isLoading={false}>Cancel</Button>
            </div>
            </ConditionalRender>
        </AnPopup>
    )
}