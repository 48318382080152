import Icon from '../../../../utils/Icon'

export const ResetFilterBtn = ({resetCallBack, ...props}) => {
    return (
        <div>
            <div className='default_flex_container' style={{ padding: '16px 16px 12px', cursor: 'pointer' }} onClick={() => resetCallBack()}>
                <div className='circle_image_background' style={{ width: 24, height: 24 }}>
                    <Icon className='circle_image' name="Cancel" width={20} height={20}/>
                </div>
                <div className='text_body_light' style={{ marginLeft: 4, lineHeight: '20px' }}>Reset</div>
            </div>
        </div>
    )
}