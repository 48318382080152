import React, { Suspense, useCallback, useEffect, useReducer, useState } from "react";
import { apiAgent } from "../../../axiosConfig";
import ConditionalRender from "../../../ConditionalRender";
import { AnInputBox } from "../../input_utils/AnInput";
import { LoaderComponent } from "../../utils/LoaderComponent";
import TableHeads from "../../visualizers/tabular/TableHeads";
import TableRows from "../../visualizers/tabular/TableRows";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker, {Calendar, utils} from "react-modern-calendar-datepicker";
import { Button } from "../../cta_utils/AnButton";
import TeamVisitActivity from "./teamVisitActivity";
import { getDateUtil, dateFormator } from "../../utils/dateUtils";
import AnDropDown from "../../cta_utils/AnDropDown";
import GreyDownArrow from "../../../assets/GreyArrowDown.svg"
import tick2 from "../../../assets/tick2.svg"

const ChartComp = React.lazy(() =>  import('./chartComp'))

const mailTypes = [{label: "All results", key: "all"}, {label: "Only News articles", key: "articles"}, {label: "Only Job updates", key: "Job_Updates"}];
const ChartPage = () => {
  const [showdropdown, setShowdropdown] = useState(false);
  const toggleDropDown = () => {setShowdropdown(d => !d)}
  const [option, setOption] = useState(mailTypes[0])
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
    {
      label: "Emails sent",
      data:[],
      extra_tooltip_datasets: [],
      pointRadius: 4,
      pointHoverRadius: 6,
      borderColor: "#437EFE",
    },
    {
      label: "Emails opened",
      data:[],
      extra_tooltip_datasets: [],
      pointRadius: 4,
      pointHoverRadius: 6,
      borderColor: "#17BF63",
    },
    {
      label: "Emails clicked",
      data:[],
      extra_tooltip_datasets: [],
      pointRadius: 4,
      pointHoverRadius: 6,
      borderColor: "#FB0000",
    },
  ],
  fetchingChart: false,
  })
  const [emailActivityData, setEmailActivityData] = useState(() =>{
    
    let from = dateFormator(getDateUtil(-7), {type: "datepicker_object"})
    let to = dateFormator(getDateUtil(), {type: "datepicker_object"})

    return {
      team_members: [],
      from: from,
      to: to,
      dateOfActivities: dateFormator(getDateUtil(), {type: "en-uk", year:"short"}, "-"),
      fetchingMemberInfo: false    
    }
  })

  useEffect(() => {
    chartSubmitClick();
  }, [])

  useEffect(() => {
    setEmailActivityData({...emailActivityData, fetchingMemberInfo: true})
    apiAgent({
      method: "get",
      url: "/stats/team_members_activity_details",
      params: {
        date: emailActivityData.dateOfActivities,
        email_type: ["news_recap_mail", "subscribed_outbound_mail", "outbound_sequence_job_update_mail", "job_updates_mail"]
      }
    }).then(res => {
      setEmailActivityData({...emailActivityData, team_members: res.data.team_members, fetchingMemberInfo: false})
    }).catch(err => {
      setEmailActivityData({...emailActivityData, fetchingMemberInfo: false})
      console.log(err)
    })
  }, [emailActivityData.dateOfActivities])
  
  const dateSetter = useCallback((dateVal) => {
    setEmailActivityData(prev => {return {...prev, dateOfActivities: dateVal}})
  }, [])

  const fetchChartDetails = (from=null, to=null, option=mailTypes[0]) => {
    let email_types = ["news_recap_mail", "subscribed_outbound_mail", "outbound_sequence_job_update_mail", "job_updates_mail"]
    if(option.key !== 'all') email_types = (option.key === 'articles') ? ["news_recap_mail", "subscribed_outbound_mail"] : ["outbound_sequence_job_update_mail", "job_updates_mail"]
    apiAgent({
      method: "get",
      url: "/stats/team_members_activity_chart",
      params:{
        from: from,
        to: to,
        email_type: email_types
      }
    }).then(res => {
      let res_data = res.data;
      let {data, dates} = res_data;      
      let datasetChart = [...chartData.datasets]
      datasetChart[0].label = data.mail_sent.header
      datasetChart[0].data = data.mail_sent.data
      
      datasetChart[1].label = data.mail_opened.header;
      datasetChart[1].data = data.mail_opened.data;

      datasetChart[2].label = data.mail_clicked.header;
      datasetChart[2].data = data.mail_clicked.data;
    
      // ***** HOW TO ADD EXTRA TOOLTIP DATA FOR EACH LINE *****
      // dataSets[0].extra_tooltip_datasets = [{
      //   label: data.news_result_mail_opened.header,
      //   data: data.news_result_mail_opened.data
      // }]

      // Adding job update emails and news results email values for displaying all results
      
      setChartData({...chartData, labels: dates, datasets: datasetChart, fetchingChart: false})
    }).catch(err => {
      setChartData({fetchingChart: false})      
      console.log(err, "Something went wrong while fetching chart details")
    })
  }
  const chartSubmitClick = (option=mailTypes[0]) => {
    const {from, to} = emailActivityData
    const { isBeforeDate } = utils();
    if(!from || !to || isBeforeDate(to, from)) return;
    const from_final = Object.values(from).join("/")
    const to_final = Object.values(to).join("/")
    setChartData({...chartData, fetchingChart: true})
    fetchChartDetails(from_final, to_final, option)
  }

  const renderTableContent = () => {
    if(emailActivityData.team_members.length == 0){
      return(
        <div className="text_body_big_light" style={{textAlign: "center"}}>
          No information to be displayed for date 
          <span className="text_body_big"> {emailActivityData.dateOfActivities}.</span>
          <br />
          Please select another date
        </div>
      )
    }
    return(
      <table className="data_table" style={{textAlign: "left"}}>          
          <TableHeads typoGraphyClass="text_body_big_light" headerClass="table_header_row">
              <th style={{width: "40"}}>Team member</th>
              <th style={{width: "20%"}}>Sent on</th>
              <th style={{width: "20%"}}>First opened at</th>
              <th style={{width: "20%"}}>First clicked at</th>
          </TableHeads>
          <tbody>
              {
                  emailActivityData.team_members.map(member => (
                      <TableRows key={member.id} rowClass="table_content_row text_body">
                          <td>{member.name}</td>
                          <td>{member.sent_at || emailActivityData.dateOfActivities}</td>
                          <td>
                              {member.first_opened_at || "-"}
                          </td>
                          <td>
                              {member.first_clicked_at || "-"}
                          </td>
                      </TableRows>
                  ))
              }
          </tbody>
      </table>
    )
  }

  const onChangecallBack = (item) => {
    setOption(item)
    chartSubmitClick(item)
  }

  const handleFilter = () => {
    return (
        <AnDropDown
            style={{
                position: "absolute",
                right: 0,
                padding: "14px",
                background: "white",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                borderRadius: 4,
                width: "168px",
                zIndex: 99,
                marginTop: 12
            }}
            toggleDropDown={toggleDropDown}
        >
            {
                mailTypes.map((item) => (
                    <div
                        key={item.key}
                        className={ option.key == item.key ? "an_dropdown_filter_small text_body" : "an_dropdown_filter_small text_body_light" }
                        onClick={() => {onChangecallBack(item);toggleDropDown()}}
                    >
                    <span>{item.label}</span>
                    { option.key == item.key && <img src={tick2}></img>}
                    </div>
                ))
            }
    </AnDropDown>
    )
  }

  return(
    <div style={{maxWidth: "980px", margin: "48px auto"}}>
        <TeamVisitActivity></TeamVisitActivity>
        <div className="text_title" style={{margin: "48px 0px 12px"}}>Email Log</div>
        <div style={{display:"flex", justifyContent:"space-between"}}>
          <div className="default_flex_container">
            <AnInputBox style={{maxWidth: "120px"}}>
              <DatePicker
                value={emailActivityData.from}
                onChange={(val) => setEmailActivityData({...emailActivityData, from: val})}
                inputPlaceholder="From"
                calendarPopperPosition="bottom"
                shouldHighlightWeekends
              />
            </AnInputBox>
            <div className="text_caption" style={{margin: "auto 8px"}}>-</div>
            <AnInputBox style={{maxWidth: "120px"}}>
              <DatePicker
                value={emailActivityData.to}
                onChange={(val) => setEmailActivityData({...emailActivityData, to: val})}
                inputPlaceholder="To"
                calendarPopperPosition="bottom"
                shouldHighlightWeekends
              />
            </AnInputBox>
            <Button 
              buttonStyle={{marginLeft: "12px"}}
              innerButtonStye={{padding: "8px 15px"}}
              onClick={() => chartSubmitClick(option)}
              isLoading={chartData.fetchingChart}
            >
              Go
            </Button>   
               
          </div>
          <div style={{position: 'relative', marginLeft: 12, maxWidth: 160, width: '100%', float: 'right', paddingTop: '8px'}}>
              <div className='default_flex_container text_body_light filters_button_box'
                  style={{marginLeft: 'auto', padding: 8, cursor: 'pointer', position: 'relative', right: 0, height: 36,border: '1px solid rgba(182, 185, 187, 0.4)', justifyContent: 'space-between', whiteSpace: 'nowrap'}}
                  onClick={toggleDropDown}
                  >
                  <span className="text_body_light">{option.label}</span>
                  <img src={GreyDownArrow} style={{marginLeft: 4}}/>
              </div>
              {  showdropdown && handleFilter() }
          </div> 
        </div>
        <Suspense fallback={() => (
            <LoaderComponent isLoading={true}></LoaderComponent>
        )}>
          <ChartComp 
            chartData={chartData}
            dateSetter={dateSetter} 
            style={{margin: "48px auto"}}
          />
        </Suspense>
        <ConditionalRender if={!emailActivityData.isfetchingMemberInfo}
        >
          {renderTableContent()}
        </ConditionalRender>
        <ConditionalRender if={emailActivityData.fetchingMemberInfo}>
          <LoaderComponent isLoading={true}></LoaderComponent>
        </ConditionalRender>
    </div>
  )
}

export default ChartPage

const reducer = (state, action) => {
  switch(action.type){
    case "SET_STATE": {
      return {
        ...state,
        ...action.payload
      }
    }
  }
}
