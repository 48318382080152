import { TextHighlighter } from "../../utils/textHighlighter";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import SuccessGreen from "../../../assets/SuccessGreen.svg"
import React from "react";
import { ALL_MAPPER_ACTIONS } from "./reducers/TempMapperReducer";
import { Button } from "../../cta_utils/AnButton";

export const FillColumnValue = (new_columns, item, mappingType = 'algo_sugg', pageDataReducer) => {
    let highlightStyle = "color: #FFAC12;text-transform: capitalize";
    let typography = { className: 'text_body' }
    let tableRowData = {id: item.id}
    const handleMap = (item) => {
        pageDataReducer({type: ALL_MAPPER_ACTIONS['SET_STATE'], payload: {
            popupsList: {mapConfirmationPopup: true},
            currItem: item
        }})
    }
    new_columns.forEach((column, index) => {
        let new_fields = [...column.fields]
        new_fields.forEach((field, index) => {
            let value
            switch (field.key) {
                case "name": {
                    value = (
                        <div key={item.id + field.key} style={{width: '100%'}}>
                            <Link to={`/prospects/${item.prospect_id}`} target="_blank" className="default_flex_container">
                                <TextHighlighter contentString={item.name} typography={typography} toMatch={item.all_matched_value?.name} highlightStyle={highlightStyle} />
                                {item.prospect_mapped && (<img src={SuccessGreen} style={{ marginLeft: 4, width: 16, height: 16 }}></img>)}
                            </Link><div style={{textAlign: 'center'}}>-</div>
                            <a href={item.linkedin_url} target="_blank">
                                <TextHighlighter contentString={item.l_name} typography={typography} toMatch={item.all_matched_value?.name} highlightStyle={highlightStyle} />
                            </a>
                        </div>
                    )
                    break;
                }
                case "company": {
                    value = (
                        <div key={item.id + field.key} style={{width: '100%'}}>
                            <div className="default_flex_container" style={{ justifyContent: 'center' }}>
                                <TextHighlighter contentString={item.companies?.join(",")} typography={typography} toMatch={item.all_matched_value?.companies} highlightStyle={highlightStyle} />
                            </div><div style={{textAlign: 'center'}}>-</div>
                            <div className="default_flex_container" style={{ justifyContent: 'center' }}>
                                <TextHighlighter contentString={item.l_companies?.join(",")} typography={typography} toMatch={item.all_matched_value?.companies} highlightStyle={highlightStyle} />
                            </div>
                        </div>
                    )
                    break;
                }
                case "university": {
                    value = (
                        <div key={item.id + field.key} style={{width: '100%'}}>
                            <TextHighlighter contentString={item.university} typography={typography} toMatch={item.all_matched_value?.university} highlightStyle={highlightStyle} /><div style={{textAlign: 'center'}}>-</div>
                            <TextHighlighter contentString={item.l_university} typography={typography} toMatch={item.all_matched_value?.university} highlightStyle={highlightStyle} />
                        </div>
                    )
                    break;
                }
                case "grad_year": {
                    value = (
                        <div key={item.id + field.key} style={{width: '100%'}}>
                            <TextHighlighter contentString={item.grad_year} typography={typography} toMatch={item.all_matched_value?.grad_year} highlightStyle={highlightStyle} /><div style={{textAlign: 'center'}}>-</div>
                            <TextHighlighter contentString={item.l_grad_year} typography={typography} toMatch={item.all_matched_value?.grad_year} highlightStyle={highlightStyle} />
                        </div>
                    )
                    break;
                }
                case "first_name": {
                    value = (
                        <div key={item.id + field.key} style={{width: '100%'}}>
                            <TextHighlighter contentString={item.first_name} typography={typography} toMatch={item.all_matched_value?.first_name} highlightStyle={highlightStyle} /><div style={{textAlign: 'center'}}>-</div>
                            <TextHighlighter contentString={item.l_first_name} typography={typography} toMatch={item.all_matched_value?.first_name} highlightStyle={highlightStyle} />
                        </div>
                    )
                    break;
                }
                case "last_name": {
                    value = (
                        <div key={item.id + field.key} style={{width: '100%'}}>
                            <TextHighlighter contentString={item.last_name} typography={typography} toMatch={item.all_matched_value?.last_name} highlightStyle={highlightStyle} /><div style={{textAlign: 'center'}}>-</div>
                            <TextHighlighter contentString={item.l_last_name} typography={typography} toMatch={item.all_matched_value?.last_name} highlightStyle={highlightStyle} />
                        </div>
                    )
                    break;
                }
                case "course": {
                    value = (
                        <div key={item.id + field.key} style={{width: '100%'}}>
                            <TextHighlighter contentString={item.course} typography={typography} toMatch={item.all_matched_value?.grad_year} highlightStyle={highlightStyle} /><div style={{textAlign: 'center'}}>-</div>
                            <TextHighlighter contentString={item.l_course} typography={typography} toMatch={item.all_matched_value?.grad_year} highlightStyle={highlightStyle} />
                        </div>
                    )
                    break;
                }
                case "title": {
                    value = (
                        <div key={item.id + field.key} style={{width: '100%'}}>
                            <TextHighlighter contentString={item.title} typography={typography} toMatch={item.all_matched_value?.title} highlightStyle={highlightStyle} /><div style={{textAlign: 'center'}}>-</div>
                            <TextHighlighter contentString={item.l_title?.join(", ")} typography={typography} toMatch={item.all_matched_value?.title} highlightStyle={highlightStyle} />
                        </div>
                    )
                    break;
                }
                case "location": {
                    value = (
                        <div key={item.id + field.key} style={{width: '100%'}}>
                            <div className="default_flex_container" style={{ justifyContent: 'center' }}>
                                <TextHighlighter contentString={item.locations?.join(",")} typography={typography} toMatch={item.all_matched_value?.locations} highlightStyle={highlightStyle} />
                            </div><div style={{textAlign: 'center'}}>-</div>
                            <div className="default_flex_container" style={{ justifyContent: 'center' }}>
                                <TextHighlighter contentString={item.l_locations?.join(",")} typography={typography} toMatch={item.all_matched_value?.locations} highlightStyle={highlightStyle} />
                            </div>
                        </div>
                    )
                    break;
                }
                case "alias_names": {
                    value = (
                        <div key={item.id + field.key} style={{width: '100%'}}>
                            {item.alias_names?.join(", ")}
                        </div>
                    )
                    break;
                }                
                case "tags": {
                    value = (
                        <div key={item.id + field.key} style={{width: '100%'}}>
                            {item.tags}
                        </div>
                    )
                    break;
                }
                case "order": {
                    value = (
                        <div key={item.id + field.key} style={{width: '100%'}}>
                            {item.order}
                        </div>
                    )
                    break;
                }
                case "suggestions": {
                    value = (
                        <div key={item.id + field.key} style={{width: '100%'}}>
                            {item.count}
                        </div>
                    )
                    break;
                }
                case "confidence": {
                    value = (
                        <div key={item.id + field.key} style={{width: '100%'}}>
                            {item.confidence}{item.confidence && '%'}
                        </div>
                    )
                    break;
                }
                case "status": {
                    value = (
                        <div key={item.id + field.key} style={{width: '100%'}}>
                            {item.mapped || mappingType === 'mapped' ? (
                                <div className="text_subtitle" style={{color: '#17BF63', textAlign: 'center'}}>
                                    Mapped
                                </div>
                            ) : mappingType === 'unmapped' ? (
                                <div className="text_subtitle" style={{color: '#17BF63', textAlign: 'center'}}>
                                    Unmapped
                                </div>
                            ) : (
                                <Button 
                                    buttonSize="Small"
                                    innerButtonStye={{padding: "7px 12px"}}
                                    buttonStyle={{textAlign: 'center'}}
                                    onClick={() => handleMap(item)}
                                >
                                    Map
                                </Button>
                            )} 
                          </div>
                    )
                    break;
                }
            }
            tableRowData = {...tableRowData, [field.key]: value}
        })
    })
    return tableRowData
}