import axios from 'axios';
import Cookies from 'universal-cookie';
import APPLICATION_CONFIG from './configurations/application_config.js'

const axiosClient = axios.create({
  baseURL: APPLICATION_CONFIG.API_URL,
  responseType: 'json',
  timeout: 20000
});

const apiAgent = (args) => {
  const cookies = new Cookies();
  let api_key = cookies.get('api_key');
  let team_id = cookies.get('team_id');
  if(args.params == null || args.params == undefined){
    args.params = {}
  }
  args.params.api_key = api_key;
  if(!args.params?.team_id) args.params.team_id = team_id;
  return axiosClient(args);
}

export {axiosClient, apiAgent};
