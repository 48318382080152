import {apiAgent} from '../../../axiosConfig.js'
import {useCallback, useEffect, useReducer, useState} from 'react'
import {useSelector} from 'react-redux'
import { FlashMessages } from '../../cta_utils/FlashMessages.js'
import { NameInitial } from '../../cta_utils/AnNameInitial.js'
import ConditionalRender from '../../../ConditionalRender.js'
import AnPopup from "../../cta_utils/AnPopup";
import {Button} from '../../cta_utils/AnButton.js';
import AnPostTypeFilter from '../../cta_utils/AnPostTypeFilter.js'
import EditPencil from "../../../assets/EditPencil.svg"
import AnDropDown from '../../cta_utils/AnDropDown.js'
import { AnInputRadioButton } from '../../input_utils/AnInputRadioButton.js'
import { LoaderComponent } from '../../utils/LoaderComponent.js'
import Pagination from '../../../Pagination.js'
import reducer from "../../../components/utils/CommonReducer.js"

const all_member_types = [{label: "All", key: ""}, {label: "Admin", key: "admin"}, {label: "Regular", key: "regular"}, {label: "Passive", key: "passive"}];
const ActiveTeamMembers = ({inviteEmail}) => {

  const [componentData, setComponentData] = useReducer(reducer ,{
    activeMembers: [],
    currentPage: 1,
    responseMessage: {},
    searchTerm: "",
    memberType: all_member_types[0],
    memberTypeDD: false,
    totalPages: 1,
    totalCount: "",
    isLoading: {}
  })

  let { activeMembers, currentPage, responseMessage, searchTerm, memberType, 
        memberTypeDD, totalPages, isLoading } = componentData;

  const setState = (payload) => {
    setComponentData({type: "SET_STATE", payload})
  }

  const current_user = useSelector(state => state.current_user.user)
  const current_team = useSelector(state => state.current_team.team)

  const [popupsList, setPopupsList] = useState({})
  const [idx,setIdx] = useState();

  const fetchMemberDetails = useCallback((query=null, currentPage) => {
    setState({isLoading: {fetchingMembers: true}})
    apiAgent({
      menthod: 'get',
      url: '/team_members',
      params: {
        approval_status: 'accepted',
        name_query: query,
        role: memberType?.key,
        page: currentPage
      }
    }).then(res => {
        if(res.data){
          let result_data = res.data.data.map(member => ({...member}))
          setState({activeMembers: result_data, totalPages: res.data.total_pages, isLoading: {}})
        }
    }).catch(err => {
        console.log("Error from ActiveTeamMembers component did mount", err)
        setState({isLoading: {}})
    })
  }, [memberType])
  
  useEffect(() => {
    fetchMemberDetails(searchTerm, currentPage)
  }, [inviteEmail, fetchMemberDetails, searchTerm, currentPage])

  const handleOnKeyDown = (e) => {
    if(e.key === 'Enter'){
        setState({searchTerm: e.target.value, currentPage: 1})
    }
  }

  const removeTeamMember = (index=idx) => {
    const team_member = activeMembers[index]
    apiAgent({
      method: 'post',
      url: `/team_members/${team_member.id}/remove_member`,
      data: {}
    }).then(res => {
      let temp = [...activeMembers]
      temp.splice(index, 1)
      inviteEmail = true
      setState({activeMembers: [...temp], responseMessage: {type: "warning", message: "Team member removed", firstIcon: true}})
    }).catch(err => alert('Something went wrong! unable to remove Team member'))
  }  


  const handleRoleChange = (e) => {
    let role_value = e.target.value;
    if(role_value === activeMembers[idx].team_role) return;
    if(!window.confirm(`Are you sure you want to change ${activeMembers[idx].user.id == current_user.id ? "your" : activeMembers[idx].user.name+"'s"} role in this team to ` + role_value)) return;
    apiAgent({
      method: 'post',
      url: '/team_members/update_role',
      data: {id: activeMembers[idx].id, role: role_value}
    }).then(res => {
      if(activeMembers[idx].user.id == current_user.id) window.location.reload();
      let temp = [...activeMembers]
      temp[idx].team_role = role_value;
      setState({activeMembers: [...temp], memberTypeDD: false, responseMessage: {type: "success", message: `Access rights updated for ${temp[idx].user.name}`, firstIcon: true}})
    }).catch(err => alert('Something went wrong! unable to update role'))
  }

  const confirmPopup = () => {
    return(
        <AnPopup name="confirmPopup" handleOnClosePopup={() => {setPopupsList({});}} maxWidth={480}>
          <div className="text_title" style={{marginBottom: 16, textAlign: 'center'}}>Are you sure you want to remove this user?</div>
          
          <div style={{display: 'flex', marginTop: 16, alignContent: 'center', justifyContent: 'center'}}>
            <Button buttonSize="Medium" buttonStyle={{width: 'max-content'}} innerButtonStyle={{padding: '8px 20px'}}
                    onClick={() => {setPopupsList({});removeTeamMember()}}>Yes</Button>
            <Button type="Secondary" buttonSize="Medium" buttonStyle={{width: 'max-content', marginLeft: 16}} innerButtonStye={{padding: '8px 38px'}}
                    onClick={() => {setPopupsList({});}}>No</Button>
          </div>
        </AnPopup>)
  }

  const renderSearchBar = () => {
      return(
          <div className="an_search_box" style={{fontSize: 14, maxWidth: "240px", width: "100%"}}>
              <div style={{position: 'relative'}}>
                  <input type="text" placeholder="Search by name or email" onKeyDown={handleOnKeyDown}/>
                  <span className="an_search_lens">
                      <img src="/search.svg" />
                  </span>
              </div>
          </div>
      )
  }
  const renderMemberTypeDD = () => {
    let member = activeMembers[idx];
    return(
      <AnDropDown style={{position: 'absolute', right: 0, padding: '16px', background: 'white', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', borderRadius: 4, width: 'max-content', zIndex: 99}}
          toggleDropDown={() => {setState({memberTypeDD: !memberTypeDD})}}>
            <AnInputRadioButton label="Admin">
              <input type="radio" value="admin"
                    checked={ member.team_role === 'admin'}
                    onClick={handleRoleChange}
              />
            </AnInputRadioButton>
            <AnInputRadioButton label="Regular" style={{marginTop: "20px"}}>
              <input type="radio" value="regular"
                    checked={ member.team_role === 'regular'}
                    onClick={handleRoleChange}
              />
            </AnInputRadioButton>
      </AnDropDown>
    )
  }
  return (
    <>
      <div className='flex_container_space_between' style={{margin: "16px 0px 0px"}}>
        {renderSearchBar()}
        <div>
          <AnPostTypeFilter
              label={{label: "Member type: ", className: "text_body_light"}}
              customStyle={{width: "max-content", maxWidth: "unset"}}
              option = {memberType}
              activeOptionStyle={{className: "text_subtitle"}}
              onChangeCallback={(value) => setState({memberType: {...value}, currentPage: 1})}
              options = {all_member_types}
          >
          </AnPostTypeFilter>
        </div>
      </div>
      <ConditionalRender if={isLoading.fetchingMembers}>
        <LoaderComponent isLoading={true}></LoaderComponent>
      </ConditionalRender>
      <ConditionalRender if={!isLoading.fetchingMembers}>
        <Pagination currentPage={currentPage} onPageChange={(v) => setState({currentPage: v})} totalPages={totalPages} style={{padding: 0, marginTop: "16px"}}/>
        <table className='manage_team_table_container' style={{marginTop: totalPages > 1 ? "16px" : "24px"}}>
          <thead>
            <tr className="text_body_big_light" style={{border: "0.4px solid #B6B9BB", background: "#F8F8F8", padding: "14px 24px"}}>
              <th style={{padding: "14px 0px 14px 24px", width: "60%", border: "none"}}>Name</th>
              <th style={{padding: "14px 0px 14px 14px", width: "20%", border: "none"}}>Member type</th>
              <th style={{padding: "14px 0px 14px 14px", width: "20%", border: "none"}}></th>
            </tr>
          </thead>
          <tbody>
              {
                activeMembers.map((member, index) => (
                  <ConditionalRender if={current_user.is_publisher || !member.user.is_super_member}>
                  <tr key={member.id} style={{border: "0.4px solid #B6B9BB", padding: "14px 7px"}}>
                    <td style={{padding: "14px 0px 14px 24px", border: 'none'}}>
                      <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                        <NameInitial name={member.user.name || member.user.email}></NameInitial>
                        <div style={{marginLeft: 10}}>
                          <div className="text_subtitle_big">
                            {member.user.name}
                          </div>
                          <ConditionalRender if={member.team_role !== "passive"}>
                            <div className="text_body_light">
                              {member.user.email}
                            </div>
                          </ConditionalRender>
                        </div>
                      </div>
                    </td>
                    <td className="an_input_box" style={{padding: "14px 0px 14px 14px", border: 'none'}}>
                      <ConditionalRender if={current_team.is_admin && member.team_role !== "passive"}>
                        <div className='text_body' style={{textTransform: "capitalize", cursor: "default"}}>
                          <span>{member.team_role}</span>
                          <img src={EditPencil} alt="edit" style={{marginLeft: "8px", cursor: "pointer"}} onClick={() => {setState({memberTypeDD: true}); setIdx(index)}}></img>
                          {memberTypeDD && idx === index && renderMemberTypeDD()}
                        </div>
                      </ConditionalRender>
                      <ConditionalRender if={!current_team.is_admin || member.team_role === "passive"}>
                        <div className='text_body' style={{cursor: "default", textTransform: "capitalize"}}>
                          {member.team_role}
                        </div>
                      </ConditionalRender>
                    </td>
                    <td style={{padding: "14px 0px 14px 18px", border: 'none'}}>
                      {
                        member.user.email !== current_user.email ? (
                          <div className="text_link" style={{color: "#EF5D60", cursor: "pointer"}} onClick={() => {setIdx(index); setPopupsList({confirmPopup: true})}}>
                            Remove
                          </div>
                        ): null
                      }
                    </td>                  
                  </tr>
                  </ConditionalRender>
                ))
              }
          </tbody>
        </table>
      </ConditionalRender>
      {popupsList.confirmPopup && confirmPopup()}
      {responseMessage.type && (<FlashMessages closeCallback={() => setState({responseMessage: {}})} messageTheme={responseMessage.type} firstIcon={responseMessage.firstIcon}>{responseMessage.message}</FlashMessages>)}
    </>
  )
}

export default ActiveTeamMembers
