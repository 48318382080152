import { useEffect, useState } from "react";

const getDimensions = () => {
    const {innerHeight: height, innerWidth: width} = window;
    return {height, width};
}
const useWindowSize = () => {
    const [windowDimensions, setWindowDimensions] = useState(getDimensions());
    useEffect(() => {
        const handleDimensionsChange = () => {
            setWindowDimensions(getDimensions());
        }
        window.addEventListener("resize", handleDimensionsChange);
        return () => window.removeEventListener("resize", handleDimensionsChange);
    }, [])

    return windowDimensions
}

export default useWindowSize