import { NameInitial } from "./AnNameInitial";
import { useState, useRef, useMemo, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { apiAgent } from "../../axiosConfig";
import ConditionalRender from "../../ConditionalRender";
import { Link } from "react-router-dom";
import { LoaderComponent } from "../utils/LoaderComponent";
import ReactDOM from "react-dom";
import { useHighConfidenceContext } from '../views/news_feed/high_confidence_context';

const AnShortProfile = ({
    containerClass="text_caption",
    innerClass="text_caption",
    messageTheme='background-white right',
    innerStyle={marginLeft: -140},
    ...props
}) => {

    const [isLoading, setIsLoading] = useState(false);
    const current_user = useSelector(state => state.current_user.user);
    const { shortProfileFields } = useHighConfidenceContext();
    const prospectFields = shortProfileFields.prospectFields;

    const renderRelationShips = (item) => {
        return (
            <div className="an_short_profile_label" style={{alignItems: "first baseline"}}>
                <div className="text_caption_light" style={{fontWeight: 700, textAlign: "left", textTransform:'uppercase', width: "50%"}}>{item.label}</div>
                <div style={{display: "flex", flexDirection: "column"}}>
                    { props.prospect.spouse_relationships && props.prospect.spouse_relationships.length>0 && <div className="an_shortprofile_box">
                        <div className="text_caption_light" style={{display: 'inline', paddingRight: '6px'}}>Spouse: </div>
                        <div style={{width: 122}}>
                            {props.prospect.spouse_relationships.map((item,index)=>{
                            return  <Link className="text_link" style={{textDecoration: "none"}} to={"/prospects/"+item.id} target="_blank">{index>=1 && ",  "}{item.name}</Link>
                        })}
                        </div>
                    </div>}
                    { props.prospect.other_relationships && props.prospect.other_relationships.length>0 && 
                    <div className="an_shortprofile_box">
                        <div className="text_caption_light" style={{display: 'inline',paddingRight: '14px'}}>Other: </div>
                        <div style={{width: 122}}>
                            {props.prospect.other_relationships.map((item,index)=>{
                            return <Link className="text_link" style={{textDecoration: "none"}} to={"/prospects/"+item.id} target="_blank">{index>=1 && ",  "}{item.name}</Link>
                        })}
                        </div>
                    </div>}
                </div>
            </div>
        )
    }

    const calculateLeft = useMemo(() => {
        if (props.parent) {
            return props.parent === "NameInitial" ? props.element.hoverDiv.x : props.element.hoverDiv.x;
        } else {
            return props.element.x;
        }
    }, [props.parent]);       

    const calculatePosition = useMemo(() => {
        if (props.parent === "NameInitial") {
            if ((props.parentDistance && Math.abs(props.parentDistance.dTop) < Math.abs(props.parentDistance.dBottom))) {
                return { top: props.element.hoverDiv.y + props.element.hoverDiv.height - 8};
            } else {
                return { bottom: window.innerHeight - props.element.hoverDiv.y};
            }
        } else {
            if ((props.parentDistance && Math.abs(props.parentDistance.dTop) < Math.abs(props.parentDistance.dBottom))) {
                return { top: props.element.hoverDiv.y + props.element.hoverDiv.height - 8};
            } else {
                return { bottom: window.innerHeight - props.element.hoverDiv.y};
            }
        }
    }, [props.parent, props.parentDistance, props.parentDistance.dTop, props.parentDistance.dBottom]);
    
    return ReactDOM.createPortal(
        <ConditionalRender if={current_user}>
        <div style={{ position: "fixed", padding: "16px 0px", zIndex: 999, backgroundColor: "transparent", left: calculateLeft, ...calculatePosition }} onMouseOver={() => props.setComponentState("isHovering", true)}
        onMouseLeave={() => props.setComponentState("isHovering", false)}
        >
            {props.isHovering ? (
                <div className="hover_card">
                    <div className="default_flex_container">
                        <NameInitial
                            name={props.prospect.name || props.prospect.company}
                        />                            
                        <div className="feed_prospect" style={{marginLeft: 8}}>
                            <div className="text_subtitle_big" style={{textAlign: "left"}}>{props.prospect.name || props.prospect.company}</div>
                            <ConditionalRender if={props.prospect.name}>
                                <div className="text_body_light truncate" style={{maxWidth: 225}}>
                                    {props.prospect.designation}
                                </div>
                            </ConditionalRender>
                        </div>
                    </div>
                {
                    isLoading? 
                    
                    <LoaderComponent isLoading={true}  loaderStyle={{width: 32, margin: '36px auto', display: 'block'}}/>
                    :
                    <>
                        {prospectFields.length > 0 && <div className="text_subtitle_big" style={{textAlign: "left", paddingTop: 20}}>
                            Profile Information
                        </div>
                        }

                        {prospectFields.map((item,index) => (
                            (item.key !== "relationships") && (
                                <>
                                    <div className="an_short_profile_label">
                                        <div className="text_caption_light hover_card_label">{item.label}</div>
                                        {props.prospect[item.key] && <div className="text_label hover_card_label_text">
                                            <span style={{wordWrap: "break-word"}}>{(item.key === "assigns" || item.key === "tags")? props.prospect[item.key].map(assign => assign.name).join(", ") : props.prospect[item.key].replace(/\n/g, ', ')}</span>
                                        </div>}
                                    </div>
                                    {(index !== prospectFields.length -1) && <div style={{borderBottom: "1px solid #E7ECEB"}}></div>}
                                </>
                            )
                            ||
                            (item.key === "relationships" && (
                                renderRelationShips(item)
                            ))
                        ))}

                        {props.prospect.custom_answers.map(answer => {
                        if (prospectFields.map(field => field.label).includes(answer.question) && answer.answer != null && answer.answer.length > 0) {
                            return (
                                <div className="an_short_profile_label">
                                    <div className="text_caption_light hover_card_label">{answer.question}</div>
                                    <div className="text_label hover_card_label_text">
                                        <span>{answer.answer}</span>
                                    </div>
                                </div>
                            );
                        } else {
                            return null;
                        }
                        })} 
                    </>
                }
                </div>
            ) : null}
        </div>
        </ConditionalRender>, 
        document.getElementById("hover_card")
    );
};

export default AnShortProfile;
