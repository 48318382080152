import { Link } from "react-router-dom/cjs/react-router-dom.min"
import ConditionalRender from "../../ConditionalRender"
import { Button } from "../cta_utils/AnButton"
import Tooltip from "../cta_utils/Tooltip"
import { JobUpdateDesc } from "./JobUpdateDesc"
import { NameInitial } from "../cta_utils/AnNameInitial"
import { useEffect, useState } from "react"

export const JobUpdatePost = ({jobUpdate, buttonLabel, maxConfidence, setComponentState, ...props}) => {
    const [name, setName] = useState("");
    useEffect(() => {
        if (jobUpdate.tagged_prospects?.length > 0) {
            const [tagged_prospect] = jobUpdate.tagged_prospects;
            setName(tagged_prospect.name || tagged_prospect.company)
        }
    },[])
    const renderLinkedinProspect = (linkedin_prospect) => {
        return(            
            <div className="default_flex_container" style={{flexWrap: "wrap-reverse", alignItems: "flex-end"}}>
                    <Link to={{ pathname: linkedin_prospect.linkedin_url }}
                        className="default_flex_container"
                        style={{alignItems: 'flex-start',textDecoration: "none"}}
                    >
                        <NameInitial
                            name={name}
                            profile_image={linkedin_prospect.profile_image}
                        />
                        <div style={{marginLeft: 8, width: "140px"}}>
                            <div className='default_flex_container'>
                                <Tooltip
                                    tooltipText={() => name}
                                    innerClass='text_body_light'
                                    textTheme='background-light-black bottom'
                                    innerStyle={{ padding: '8px', alignSelf: 'center', color: 'white', borderRadius: '8px' }}
                                    style={{ marginTop: 6 }}
                                >
                                    <div className="text_subtitle_big truncate" style={{flex: 'none', width: "100%"}}>{name}</div>
                                </Tooltip>
                        </div>
                        <Tooltip
                            tooltipText={() => { return `${linkedin_prospect.designation}, ${linkedin_prospect.company}` }}
                            innerClass='text_body_light'
                            textTheme='background-light-black bottom'
                            innerStyle={{ padding: '8px', alignSelf: 'center', color: 'white', borderRadius: '8px' }}
                            style={{ marginTop: 6 }}
                        >
                            <div className="text_body_light truncate">{linkedin_prospect.designation}, {linkedin_prospect.company}</div>
                            </Tooltip>
                        </div>
                    </Link>                                                                                           
            </div>
        )
    }

    return (
        <div key={jobUpdate.id} className="article_container" style={{padding: '24px', marginTop: 24}}>
        <div className='flex_container_space_between'>
            {renderLinkedinProspect(jobUpdate)}
            <div className='default_flex_container'>  
                <ConditionalRender if={maxConfidence}>                 
                    <div className='text_caption_light' style={{marginRight: 12, fontWeight: 700}}>Top confidence: <span className='text_caption_bold'>{maxConfidence}%</span></div>
                </ConditionalRender> 
                <ConditionalRender if={buttonLabel}>
                <Button innerButtonStye={{padding: 8, width: "max-content"}} onClick={() => setComponentState({selectedJobUpdate: jobUpdate, popupsList: {mapAndPostPopup: true}})}><div className="text_caption" style={{color: "white"}}>{buttonLabel}</div></Button>       
                </ConditionalRender>                             
                <Tooltip
                    tooltipText={() => jobUpdate.formatted_created_at}
                    innerClass='text_body_light'
                    textTheme='background-light-black top'
                    innerStyle={{ padding: '8px', alignSelf: 'center', color: 'white', borderRadius: '8px' }}
                    style={{  }}
                    showWithoutOverflowing={true}
                    tooltipPos="top"
                    tooltTipOverlay={{x: 0,y: -7}}
                >
                    <div style={{marginLeft: 12, cursor: "default"}} className='text_caption'>{jobUpdate.created_at_time}</div>
                </Tooltip> 
            </div>
        </div>
        <div className="line_seperator_ex_light_grey" style={{margin: "16px 0px 22px"}}></div>
        <JobUpdateDesc job_update={{...jobUpdate, name: name}} />
        </div>
    )
}