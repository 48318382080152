import { useEffect, useState } from "react";
import { Button } from "../../cta_utils/AnButton"
import { AnInputBox } from "../../input_utils/AnInput"
import FileUpload from "../../../FileUpload";
import ConditionalRender from "../../../ConditionalRender";
import SuccessMessage from "../../../assets/SuccessImage.png"
import { useSelector } from "react-redux";
export const ShareDataImport = () => {
    const current_user = useSelector(state => state.current_user.user)
    const current_team = useSelector(state => state.current_team.team)
    const [info, setInfo] = useState({ name: current_user.name, email: current_user.email, institution: current_team.name});
    const [submitSuccess, setSubmitSuccess] = useState(false)
    const handleInputChange = (e) => setInfo({ ...info, [e.target.name]: e.target.value })
    const renderSubmitSuccess = () => {
        return (
            <div>
                <div style={{margin: 'auto', paddingTop: 36, maxWidth: 434}}>
                    <img src={SuccessMessage} style={{maxWidth: 434, maxHeight: 200}}></img>
                    <div style={{textAlign: 'center', marginTop: 34}}>
                    <div className="text_title_big">Your data has been received</div>
                    <div className="text_body_big_light_semi_bold" style={{marginTop: 8}}>We'll import your data and be in touch shortly.</div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div>
            <ConditionalRender if={!submitSuccess}>
            <div style={{ textAlign: 'center' }}>
                <div className="text_title_big">SFTP Upload</div>
                <div className="text_body_big_light_semi_bold" style={{ marginTop: 12 }}>Upload your prospect data securely and expect a response within 24 hours</div>
            </div>
            <div className="complete_profile_container" style={{ marginTop: 16, padding: '24px 32px' }}>
                <div className='complete_profile_details' style={{ marginTop: 0 }}>
                    <AnInputBox label={"Your name"}>
                        <input type="text" id="name" name="name" value={info.name} onChange={handleInputChange} placeholder="John Doe" />
                    </AnInputBox>
                    <AnInputBox label={"Email"} style={{ marginTop: 8 }}>
                        <input type="text" id="email" name="email" value={info.email} onChange={handleInputChange} placeholder="johndoe@domain.com" />
                    </AnInputBox>
                    <AnInputBox label={"Institution"} style={{ marginTop: 8 }}>
                        <input type="text" id="institution" name="institution" value={info.institution} disabled={current_team.name} onChange={handleInputChange} placeholder="New York University" />
                    </AnInputBox>

                    <div style={{marginTop: 24}}>
                    <FileUpload
                    submitUrl="/share_import_data"
                    uploaderSecondaryText={{content: "Supported formats: Excel (.xls and .xlsx) and .csv"}}    
                    style={{maxheight: "160px"}}
                    submitButtonSize="Big"
                    submitbuttonStyle={{width: '100%'}}
                    innersubmitButtonStye={{width: '100%'}}
                    disabled={!(info.name && info.email && info.institution)}
                    prop_params={{...info, type: "Share data"}}
                    onSubmitFinishCallback={() => setSubmitSuccess(true)}
                    />
                    </div>
                </div>
                <div className="text_body_light privacy_policy_container">
                    By continuing, you are agreeing to AlmaConnect News <a href="https://www.almaconnect.com/terms-of-use" className='text_link'>Terms Of Use</a> and&nbsp;
                    <a href="https://www.almaconnect.com/privacy-policy" className='text_link'>Privacy Policy</a>.
                </div>
            </div>
            </ConditionalRender>
            <ConditionalRender if={submitSuccess}>
                {renderSubmitSuccess()}
            </ConditionalRender>
        </div>
    )
}