import React, { useState, useEffect, useCallback, Fragment, useReducer } from "react";
import { apiAgent } from "../../../axiosConfig.js";
import Pagination from "../../../Pagination";
import { Button } from "../../cta_utils/AnButton.js";
import AnPopup from "../../cta_utils/AnPopup";
import LowConfidencePost from '../../feed_components/low_confidence_post';
import {LoaderComponent} from "../../utils/LoaderComponent";
import ManualPublishPosts from "../../feed_components/ManualPublishPosts.jsx";
import {AnInputCheckBox} from "../../input_utils/AnInputCheckBox";
import { Calendar } from "react-modern-calendar-datepicker";
import {AnInputBox} from "../../input_utils/AnInput";
import {FlashMessages} from "../../cta_utils/FlashMessages";
import ConditionalRender from "../../../ConditionalRender.js";

import { initialFiltersState, matchingArticleFilterReducer as filterReducer } from "../filters/ArticleBasedFilters/reducers/matchingArticleFilterReducer.js";
import { smartPublishTabs } from "../../utils/AllFilterTabs.js";
import FiltersIcon from "../../../assets/FiltersIcon.svg"
import Cross from "../../../assets/GreenCancel.svg"
import RedCross from "../../../assets/RedCross.png"
import VerifiedTick from '../../../assets/Verified.png'
import ReloadIcon from '../../../assets/ReloadIconGreen.svg'
import GreenDot from '../../../assets/GreenDot.svg'

import { fetchSmartAggregates } from "./apiHitter.js";
import { MatchingArticleBasedFilters } from "../filters/ArticleBasedFilters/filters/matchingArticleBasedFilters.jsx";
import { useSelector } from "react-redux";
import SmartPublishSortDropDown from "./components/SmartPublishSortDropDown.jsx";
import { indexOfObject } from "../../utils/ArrayUtils.js";
import MatchingArticleDateFilters from "../filters/ArticleBasedFilters/filters/matchingArticleDateFilters.jsx";
import { datepicker_input_format } from "../../utils/dateUtils.js";
import useCheckUserAccess from "../../custom_hooks/useCheckUserAccess.js";
import DownloadImage from "../../../assets/download.png";
import { AnConfirmPopup } from "../../cta_utils/AnConfirmPopup.js";

const articleTypes = [{label: 'All', key: 'all'}, {label: "Individuals", key: 'individual'}, {label: "Organizations", key: 'organization'}];
const intitialInviteData = {
    name: "",
    email: "",
    position: "",
    step: 1,
    isLoading: false,
    as_subscriber: false,
    errors: {}
}
const SmartAggregatesList = () => {
    const [smartPublishData, setSmartPublishData] = useState({
        articleList: [],
        totalPages: 0,
        total_smart_res: 0,
        total_low_confidence_res: 0,
        total_published_res: 0,
    });
    
    const [filtersState, filtersStateReducer] = useReducer(filterReducer , initialFiltersState)
    
    const [isFetching, setIsFetching] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [discardList, setDiscardList] = useState([]);
    const [isLoading, setIsLoading] = useState({});
    const [popupsList, setPopupsList] = useState({});
    const [newsResultsParams, setNewsResultsParams] = useState({
        email: ""
    });
    const [successMsg, setSuccessMsg] = useState(null);
    const [articleTab, setArticleTab] = useState("smart");
    const [articleType, setArticleType] = useState("all");
    const paramsString = new URLSearchParams(window.location.search);
    
    const {articleList} = smartPublishData

    const [inviteData, setInviteData] = useState(intitialInviteData)
    const current_team = useSelector(state => state.current_team.team);
    const checkUserAccess = useCheckUserAccess();

    useEffect(() => {
        if(articleTab === 'smart' && paramsString.get("tab_type") === 'low')
            setArticleTab('low')
        fetchAggregates(1);
    }, [articleTab, filtersState.filtersSelected, filtersState.dateRanges, filtersState.sortSelected, articleType]);

    const fetchAggregates = (page) => {
        setIsFetching(true);
        let smart_id = paramsString.get("smart_id");
        const {filtersSelected, sortSelected} = filtersState
        let sort = {...sortSelected};
        if(articleTab == "published" && sort.article_created_date){
            delete Object.assign(sort, {"published_at": sort["article_created_date"]})["article_created_date"];
        }
        delete sort.label;
        let range_params = {}
            Object.keys(filtersState.dateRanges).forEach(k => {
                if(filtersState.dateRanges[k].value.from && filtersState.dateRanges[k].value.to){
                    let {from, to} = filtersState.dateRanges[k].value;
                    range_params[k] = {from: datepicker_input_format(from), to: datepicker_input_format(to)};
                }
            })
        let exclude_filters = filtersSelected["exclude"].score_tags.length > 0 ? {
            score_tags: filtersSelected["exclude"].score_tags
        } : null;
        let data_params = {
            score_tags: filtersSelected["include"].score_tags,
            mentioned_for: filtersSelected["include"].mentioned_for?.map(mf => mf.value),
            categories: filtersSelected["include"].categories?.map(category => category.value),
            article_type: articleType,
            page: page,
            article_condition: articleTab,
            smart_id: smart_id,
            sort: sort,
            date_ranges: range_params,
            exclude_filters: exclude_filters
        }
        fetchSmartAggregates(data_params)
        .then(
            (response) => {
                let temp = {
                    ...smartPublishData,
                    articleList: response.data.data.map(d => {
                        if(d.mentioned_for){
                            if(!d.matching_article_tags)
                                d.matching_article_tags = {};
                            d.mentioned_for.map((mf) => d.matching_article_tags[mf] = mf )  
                            d.categories.map((c) => d.matching_article_tags[c] = c )                                                                                                  
                        }
                        return d;
                    }),
                    totalPages: response.data.total_pages,
                    total_smart_res: response.data.count_hash.smart,
                    total_mid_res: response.data.count_hash.mid,
                    total_low_confidence_res: response.data.count_hash.low,
                    total_published_res: response.data.count_hash.published
                }
                let filterType = 'include'
                let mappedKey = 'categories'
                filtersStateReducer({type: "ASSIGN_AGGREGATES_DATA", payload: {aggregates: response.data.aggregates, filterType: filterType, mappedKey: mappedKey}})
                setSmartPublishData(temp)
                setIsFetching(false);
                setCurrentPage(page);
            },
            (error) => {
                setIsFetching(false);
            }
        );
    };

    const discardAll = () => {
        setIsLoading({discardingAll: true});
        apiAgent({
            method: "post",
            url: "/aggregates/multiple_discard",
            data: {
                article_ids: discardList
            },
        }).then(
            (response) => {
                setIsLoading({discardingAll: false});
                fetchAggregates(1);
                setSuccessMsg("Articles discarded");
            },
            (error) => {
                setIsLoading({discardingAll: false});
            }
        );
    }

    const deleteAll = () => {
        if(discardList && discardList.length > 0){
            setIsLoading({deletingAll: true});
            apiAgent({
                method: "post",
                url: "/aggregates/multiple_delete",
                data: {
                    article_ids: discardList
                },
            }).then(
                (response) => {
                    setIsLoading({deletingAll: false});
                    fetchAggregates(1);
                    setDiscardList([]);
                    setSuccessMsg("Posts deleted");
                },
                (error) => {
                    setIsLoading({deletingAll: false});
                }
            );
        } else return;
    }

    const triggerNotificationMail = (type) => {
        setIsLoading({triggeringMails: true});
        apiAgent({
            method: "post",
            url: "/team/news_notifications_mail",
            data: { type: type
            },
        }).then(
            (response) => {
                setIsLoading({triggeringMails: false});
                fetchAggregates(1);
                handleOnClosePopup();
                setSuccessMsg("Mail will be sent soon");
            },
            (error) => {
                setIsLoading({triggeringMails: false});
                alert("Something went wrong! Unable to trigger emails at the moment.")
            }
        );
    }

    const triggerNewsResults = () => {
        if(!window.confirm("Are you sure you want to trigger news results?")) return;
        setIsLoading({triggeringNewsResult: true});     
        let range_params = {};
        Object.keys(filtersState.dateRanges).forEach(k => {
            if(filtersState.dateRanges[k].value.from && filtersState.dateRanges[k].value.to){
                let from = filtersState.dateRanges[k].value.from;
                let to = filtersState.dateRanges[k].value.to;
                range_params[k] = {from: datepicker_input_format(from), to: datepicker_input_format(to)};
            }
        })
        apiAgent({
            method: "post",
            url: "/team/send_news_results",
            data: {
                email: newsResultsParams.email,
                start_date: range_params["created_at"].from,
                end_date: range_params["created_at"].to
            },
        }).then(
            (response) => {
                setIsLoading({triggeringNewsResult: false});        
                fetchAggregates(1);
                handleOnClosePopup();
                setSuccessMsg("Mail will be sent soon");
            },
            (error) => {
                setIsLoading({triggeringNewsResult: false});
                alert("Something went wrong! Unable to trigger emails at the moment.")
            }
        );
    }

    const exportArticles = () => {
        if(!window.confirm("Are you sure you want to download news articles?")) return;
        setIsLoading({exportArticles: true});
        let range_params = {};
        Object.keys(filtersState.downloadDateRanges).forEach(k => {
            if(filtersState.downloadDateRanges[k].value.from && filtersState.downloadDateRanges[k].value.to){
                let from = filtersState.downloadDateRanges[k].value.from;
                let to = filtersState.downloadDateRanges[k].value.to;
                range_params[k] = {from: datepicker_input_format(from), to: datepicker_input_format(to)};
            }
        })

        const {filtersSelected} = filtersState;
        let exclude_filters = filtersSelected["exclude"].score_tags.length > 0 ? {
            score_tags: filtersSelected["exclude"].score_tags
        } : null;
        let data_params = {
            score_tags: filtersSelected["include"].score_tags,
            mentioned_for: filtersSelected["include"].mentioned_for?.map(mf => mf.value),
            article_type: articleType,
            article_condition: articleTab,
            exclude_filters: exclude_filters,
            from_date: range_params["created_at"].from,
            end_date: range_params["created_at"].to
        }
        apiAgent({
            method: "get",
            url: "/aggregates/download",
            params: data_params,
        }).then(
            (response) => {
                setIsLoading({exportArticles: false});
                handleOnClosePopup();
                setSuccessMsg("News articles will be downloaded soon");
            },
            (error) => {
                setIsLoading({exportArticles: false});
                alert("Something went wrong!")
            }
        );
    }

    const triggerScoreRefresh = () => {
        setIsLoading({refreshingScore: true});
        apiAgent({
            method: "post",
            url: "/team/refresh_score",            
        }).then(
            (response) => {
                setIsLoading({refreshingScore: false});
                setSuccessMsg("Scores will be refreshed shortly");
            },
            (error) => {
                setIsLoading({refreshingScore: false});
                alert("Something went wrong! Unable to refresh scores at the moment.")
            }
        );
    }
    const inviteEmail = () => {
        let errors = {}, error = false;
        if(!inviteData.email) {
          errors["email"] = "Email can't be blank";
          error = true;
        }
        if(!inviteData.name) {
          errors["name"] = "Name can't be blank";
          error = true;
        }
        if(error) {            
            setInviteData(d => ({...d, errors: errors}))   
            return;
        }else{
            setInviteData(d => ({...d, errors: errors, isLoading: true}))   
        }
        let url = `/outbound_mail/instant_mail`;
        let data_params = {
          email: inviteData.email.toLowerCase(),
          name: inviteData.name,
          position: inviteData.position,
          step: inviteData.step,
          as_subscriber: inviteData.as_subscriber
        }
        apiAgent({
          method: 'post',
          url: url,
          data: data_params
        }).then(res => {
            setSuccessMsg("Mail will be sent soon");
            setInviteData(intitialInviteData)   
            handleOnClosePopup();
        }).catch(err => {
            let temp = {...inviteData};
            temp.loading = false;
            if(err.response && err.response.data)            
                temp.errors =  {server: err.response.data.error_message};
            setInviteData(temp)   
        })
      }
    const handleDiscardListChange = (e) => {
        let val = e.target.value
        let index = discardList.indexOf(val)
        if(index==-1) {
            setDiscardList([...discardList, val])
        }
        else{
            let temp = [...discardList]
            temp.splice(index, 1)
            setDiscardList(temp)
        }
    }

    const handleArticleListUpdate = useCallback((agg, caller=null) => {
        let index = indexOfObject(articleList, "id", agg.id);
        if(index == -1) return
        let temp = [...articleList]
        temp.splice(index, 1)
        let payload = {...smartPublishData};
        payload.articleList = temp;
        if((articleTab=="smart" || articleTab=="low")){            
            if(caller!="discarded") {
                setSuccessMsg("News article moved to high confidence");
                payload.total_published_res+=1;
            }
            articleTab == "smart" ? payload.total_smart_res-=1 : payload.total_low_confidence_res-=1
        }
        setSmartPublishData(payload)
    }, [articleList])

    const handleFilterRemove = (e, mappedKey, index, filterType) => {
        e.stopPropagation();
        let {filtersSelected} = filtersState;        
        filtersSelected[filterType][mappedKey] = [...filtersSelected[filterType][mappedKey]]
        filtersSelected[filterType][mappedKey].splice(index, 1);
        filtersStateReducer({
            type: "APPLY_TEMP_FILTERS",
            payload: { filtersSelected: filtersSelected },
        });
    }

    const updateArticleType = (type) => {
        let tempFiltersSelected = JSON.parse(JSON.stringify(initialFiltersState.filtersSelected));
        setArticleType(type)
        if(type === 'all'){
            filtersStateReducer({
                type: "APPLY_TEMP_FILTERS",
                payload: {filtersSelected: tempFiltersSelected}
            });
        } else if(type === 'individual'){
            tempFiltersSelected["include"]['score_tags'].push({value: "name_present"})
            filtersStateReducer({
                type: "APPLY_TEMP_FILTERS",
                payload: { filtersSelected: tempFiltersSelected }
            });
        } else if(type === 'organization'){
            tempFiltersSelected["include"]['score_tags'].splice(1, 1);
            filtersStateReducer({
                type: "APPLY_TEMP_FILTERS",
                payload: { filtersSelected: tempFiltersSelected }
            });
        }
    }

    const handleOnClosePopup = () => setPopupsList({})
    const handleInviteChanges = (e) => {
        const {name, value} = e.target;
        setInviteData(d => ({...d, [name]: value}));
    }
    const renderManualPostPopup = () => {
        return(
            <AnPopup handleOnClosePopup={handleOnClosePopup}>
                <ManualPublishPosts createPostSuccess={handleOnClosePopup}/>
            </AnPopup>
        )
    }
    const invitePopup = () => {
        const {name, email, position, errors, step, isLoading, as_subscriber} = inviteData;
        return(
            <AnPopup name="invitePopup" handleOnClosePopup={() => {handleOnClosePopup()}} maxWidth={480}>
              <div className="text_title" style={{marginBottom: 16}}>Invite Team Members</div>
              <ConditionalRender if={!current_team.outbound_enabled}>
                <div className="text_body" style={{marginBottom: 8}}>Invite by email:</div>
              </ConditionalRender>
              <AnInputBox label={"Name"} error={errors["name"]}>
                <input type="text" name="name" value={name} onChange={handleInviteChanges} placeholder="Name"/>
              </AnInputBox>
              <AnInputBox label={"Email Address"} error={errors["email"]}>
                <input type="text" name="email" value={email} onChange={handleInviteChanges} placeholder="Email"/>
              </AnInputBox>
              <AnInputBox label={"Title/Position"}>
                <input type="text" name="position" value={position} onChange={handleInviteChanges} placeholder="Associate Dean"/>
              </AnInputBox>
              <ConditionalRender if={current_team.outbound_enabled}>
                <AnInputBox label="Step" style={{width: "99px"}} error={errors["step"]}>
                  <select disabled={as_subscriber} style={{padding: '10px 14px', opacity: as_subscriber && '0.5'}} name="step" value={step} onChange={handleInviteChanges}>
                    <option value={1}>Step 1</option>
                    <option value={2}>Step 2</option>
                    <option value={3}>Step 3</option>
                    <option value={4}>Step 4</option>
                  </select>
                </AnInputBox>
                <AnInputCheckBox
                    label="Add as a subscriber"
                    isChecked={as_subscriber}
                    style={{marginBottom: "12px"}}
                >
                    <input
                        name="as_subscriber"
                        type="checkbox"
                        checked={as_subscriber}
                        onChange={() => setInviteData({...inviteData, as_subscriber: !as_subscriber})}
                    >
                    </input>
                </AnInputCheckBox>
              </ConditionalRender>
              <div style={{display: 'flex', marginTop: 16}}>
                <Button buttonSize="Medium" buttonStyle={{width: 'max-content'}} innerButtonStye={{padding: '8px 20px'}}
                        onClick={inviteEmail} isLoading={isLoading}>Invite</Button>
                <Button type="Secondary" buttonSize="Medium" buttonStyle={{width: 'max-content', marginLeft: 16}} innerButtonStye={{padding: '8px 20px'}}
                        onClick={handleOnClosePopup}>Cancel</Button>
              </div>
              <ConditionalRender if={errors["server"]}>
                <div className="text_caption_error" style={{ marginTop: 8 }}>
                    {errors["server"]}
                </div>
            </ConditionalRender>
            </AnPopup>)
      }
    const sendNewsResultsPopup = () => {
        return Object.keys(filtersState.dateRanges).map(k => {
            let option = filtersState.dateRanges[k];
            let from = null;
            let placeholderFrom = "1 June 2022";
            let placeholderTo = "30 June 2022";
            if(option.value.from)
                from = new Date(datepicker_input_format(option.value.from, "mm-dd-yyyy")).toLocaleDateString("en-UK", 
                    {year: 'numeric', month: 'long', day: 'numeric'});
            let to = null;
            if(option.value.to)
                to = new Date(datepicker_input_format(option.value.to, "mm-dd-yyyy")).toLocaleDateString("en-UK", 
                    {year: 'numeric', month: 'long', day: 'numeric'});
            return(
                <AnPopup maxWidth={430} handleOnClosePopup={handleOnClosePopup}>
                    <div className="text_body_light" style={{margin: "12px auto 6px"}}>
                            Selected range
                    </div>
                    <div className="default_flex_container text_body_big" 
                        style={{background: "#F3F4F5", padding: "9px 17px", gap: "4px", borderRadius: "4px"}}
                    >
                        <span className={from ? "text_body" : "text_subtitle_light_grey_semi_bold"}>{from ? from : placeholderFrom} - </span>
                        <span className={to ? "text_body" : "text_subtitle_light_grey_semi_bold"}>{to ? to : placeholderTo}</span>
                    </div>
                    <div style={{margin: "8px 0px"}}>
                        {/* <Calendar onChange={(val) => setNewsResultsParams({...newsResultsParams, dateRanges: val})} value={dateRanges} selectRange={true} /> */}
                        <Calendar style={{width: "100%"}} onChange={(val) => filtersStateReducer({type: "UPDATE_DATE_RANGE", payload:{
                                                                            key: k,
                                                                            subkey: "value",
                                                                            value: val
                                                                        }})} value={filtersState.dateRanges[k].value} selectRange={true} />
                    </div>
                    <AnInputBox label="Email:">
                        <input
                            type="text"
                            id="Email"
                            value={newsResultsParams.email}
                            onChange={(e) =>
                                setNewsResultsParams({...newsResultsParams, email: e.target.value})
                            }
                            placeholder="Email"
                        />
                    </AnInputBox>
                    <Button
                        onClick={() => {
                            triggerNewsResults();
                        }}
                        isLoading={isLoading.triggeringNewsResult}
                        buttonStyle={{ marginTop: "16px"}}
                    >
                        Send Email
                    </Button>
                </AnPopup>
            )}
    )}

    const exportPopup = () => {
        return Object.keys(filtersState.downloadDateRanges).map(k => {
            let option = filtersState.downloadDateRanges[k];
            let from = null;
            let placeholderFrom = "1 June 2022";
            let placeholderTo = "30 June 2022";
            if(option.value.from)
                from = new Date(datepicker_input_format(option.value.from, "mm-dd-yyyy")).toLocaleDateString("en-UK",
                    {year: 'numeric', month: 'long', day: 'numeric'});
            let to = null;
            if(option.value.to)
                to = new Date(datepicker_input_format(option.value.to, "mm-dd-yyyy")).toLocaleDateString("en-UK",
                    {year: 'numeric', month: 'long', day: 'numeric'});
            return(
                <AnPopup maxWidth={430} handleOnClosePopup={handleOnClosePopup}>
                    <div className="text_body_light" style={{margin: "12px auto 6px"}}>
                        Selected range
                    </div>
                    <div className="default_flex_container text_body_big"
                         style={{background: "#F3F4F5", padding: "9px 17px", gap: "4px", borderRadius: "4px"}}
                    >
                        <span className={from ? "text_body" : "text_subtitle_light_grey_semi_bold"}>{from ? from : placeholderFrom} - </span>
                        <span className={to ? "text_body" : "text_subtitle_light_grey_semi_bold"}>{to ? to : placeholderTo}</span>
                    </div>
                    <div style={{margin: "8px 0px"}}>
                        {/* <Calendar onChange={(val) => setNewsResultsParams({...newsResultsParams, dateRanges: val})} value={dateRanges} selectRange={true} /> */}
                        <Calendar style={{width: "100%"}} onChange={(val) => filtersStateReducer({type: "UPDATE_DOWNLOAD_DATE_RANGE", payload:{
                                key: k,
                                subkey: "value",
                                value: val
                            }})} value={filtersState.downloadDateRanges[k].value} selectRange={true} />
                    </div>
                    <Button
                        onClick={() => {
                            exportArticles();
                        }}
                        isLoading={isLoading.triggeringNewsResult}
                        buttonStyle={{ marginTop: "16px"}}
                    >
                        Download
                    </Button>
                </AnPopup>
            )}
        )}


    const showAggregate = (article) => {
        let smart_id = paramsString.get("smart_id");
        let single_article = false;
        if(smart_id === article.id || articleTab == 'published')
            single_article = true
        if ((article.published || article.discarded) && !single_article)
            return (<div key={article.id}></div>);
        else{
            return(
                <div key={article.id} style={{display: 'grid', gridTemplateColumns: '32px 1fr'}}>
                    <div style={{marginRight: 12, marginTop: 24, alignSelf: "start"}}>
                        <ConditionalRender if={checkUserAccess('aggregates_discard_all') || (checkUserAccess('aggregates_delete_all') && articleTab === "published")}>
                            <input type="checkbox" id={article.id} value={article.id} checked={discardList.includes(article.id)} onChange={(e) => {handleDiscardListChange(e)}}/>
                        </ConditionalRender>
                    </div>
                    <LowConfidencePost admin={true} item={article} tabType={articleTab} singleArticle={single_article} style={{width: '100%'}} callbacks={{
                      onDiscardCallback: handleArticleListUpdate,
                      onPublishCallback: handleArticleListUpdate
                    }} 
                    renderParentType='smart'
                    />
                </div>
            )
        }
    }

    const renderResultTabs = () => {
        var high = (articleTab == "smart") ? 'active' : '';
        var mid = (articleTab == "mid") ? 'active' : '';
        var low = (articleTab == "low") ? 'active' : '';
        let published = (articleTab == 'published') ? 'active' : '';
        return (
            <div className="newsfeed_tabs" style={{margin: "16px auto 0px"}}>
                <div onClick={() => setArticleTab('smart')} className={`${high} default_flex_container`} style={{padding: '13px 0px', flex: 1, flexDirection: "column"}}>
                    <span>High Probable</span>
                    <ConditionalRender if={smartPublishData.total_smart_res > 0}>
                        <div>({smartPublishData.total_smart_res})</div>
                    </ConditionalRender>
                </div>
                <div onClick={() => setArticleTab('mid')} className={`${mid} default_flex_container`} style={{padding: '13px 0px', flex: 1, flexDirection: "column"}}>
                    <span>Mid Probable</span>
                    <ConditionalRender if={smartPublishData.total_mid_res > 0}>
                        <div>({smartPublishData.total_mid_res})</div>
                    </ConditionalRender>
                </div>
                <div onClick={() => setArticleTab('low')} className={`${low} default_flex_container`} style={{padding: '13px 0px', flex: 1, flexDirection: "column"}}>
                    <span>Low Probable</span>
                    <ConditionalRender if={smartPublishData.total_low_confidence_res > 0}>
                        <div>({smartPublishData.total_low_confidence_res})</div>
                    </ConditionalRender>
                </div>
                <div className={published} onClick={() => setArticleTab('published')} 
                    style={{padding: '13px 0px', flex: 1}}>
                    <div className="default_flex_container" style={{justifyContent: "center"}}>
                        <img src={VerifiedTick} style={{marginRight: 4, width: "16px"}} alt="verified"/>
                        <span>Published</span>
                    </div>
                    <ConditionalRender if={smartPublishData.total_published_res > 0}>
                        <div style={{textAlign: "center"}}>({smartPublishData.total_published_res})</div>
                    </ConditionalRender>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="aggregate_container" style={{marginTop: "20px"}}>
                <div className="text_title_big">Smart Publish</div>
                <div style={{marginTop: '16px'}}>
                    <ConditionalRender if={checkUserAccess('aggregates_discard_all') && articleTab !== "published"}>
                    <Button onClick={discardAll} isLoading={isLoading.discardingAll} buttonStyle={{float: 'left'}} innerButtonStye={{background: '#F94545'}}>
                        Discard All
                    </Button>
                    </ConditionalRender>
                    <ConditionalRender if={checkUserAccess('aggregates_delete_all') && articleTab === "published"}>
                    <Button onClick={deleteAll} isLoading={isLoading.deletingAll} buttonStyle={{float: 'left' }} innerButtonStye={{background: (discardList && discardList.length>0)? '#F94545' : "#fdb5b5"}}>
                        Delete All
                    </Button>
                    </ConditionalRender>
                    {current_team.outbound_enabled ? 
                    <Button buttonSize="LessPadding" isLoading={isLoading.triggeringMails} onClick={() => setPopupsList({triggerOutboundMailPopup: true})} buttonStyle={{float: 'right', marginLeft: 16}}>
                        Trigger notification mail
                    </Button>
                    : 
                    <Button  buttonSize="LessPadding" buttonStyle={{float: 'right', marginLeft: 16}} dropOptions={[
                        {
                            checkUserAccess: true,
                            label: 'News results',
                            onClick: () => setPopupsList({triggerNewsResultsPopup: true})
                        },
                        {
                            checkUserAccess: true,
                            label: 'Job Updates',
                            onClick: () => setPopupsList({triggerJobUpdatesPopup: true})
                        }
                    ]}
                    dropDownTextClass="text_subtitle_medium"
                    >Trigger notification mail</Button>
                    }
                    <ConditionalRender if={checkUserAccess('news_publishing')}>
                    <Button buttonSize="LessPadding" onClick={() => {setPopupsList({manualPostCreationPopup: true})}} buttonStyle={{float: 'right', marginLeft: 16}}>
                        Post Article
                    </Button>
                    </ConditionalRender>
                    <Button buttonSize="LessPadding" type="Secondary" onClick={() => {setPopupsList({sendNewsResultsPopup: !current_team.outbound_enabled, invitePopup: current_team.outbound_enabled})}} buttonStyle={{float: 'right', marginLeft: 16}}>
                        Send {current_team.outbound_enabled ? "Outbound Emails" : "News Results"}
                    </Button>
                    <Button buttonSize="LessPadding" type="Secondary" isLoading={isLoading.refreshingScore} onClick={triggerScoreRefresh} 
                        buttonStyle={{float: 'right'}}
                        innerButtonStye={{padding: "8px 12px"}}
                    >
                        <span className="default_flex_container">
                            <img src={ReloadIcon} style={{marginRight: 4, width: "14px", height: "16px"}}></img>
                            <span>Score</span>
                        </span>
                    </Button>
                    <div style={{clear: "both"}}></div>
                </div>
                <div  className="line_seperator_ex_light_grey"  style={{ margin: "20px auto" }}></div>
                <div style={{display: 'flex'}}>
                    {
                        articleTypes.map(type => (
                            <div className={articleType === type.key ? 'text_link box_border_highlight' : 'text_body'} style={{cursor: 'pointer', padding: '8px 16px'}} onClick={() => updateArticleType(type.key)}>{type.label}</div>
                        ))
                    }
                </div>
                <div style={{clear: 'both'}}></div>
                <div className="text_title" style={{margin: '12px 0px 8px', display: 'flex'}}>
                    <div style={{width: 'calc(100% - 36px)', paddingTop: 8}}>News Articles</div>
                    <div onClick={() => {setPopupsList({exportPopup: true})}}
                         style={{
                             alignItems: 'center',
                             cursor: "pointer",
                             width: "36px",
                             border: '1px solid #e6e6e6',
                             padding: '6px 6px',
                             background: "white",
                             borderRadius: 3
                         }}>
                        <img src={DownloadImage} style={{maxWidth: 22}}></img>
                    </div>
                </div>
                <div style={{clear: 'both'}}></div>
                <div className="default_flex_container" style={{gap: "12px", flexWrap: "wrap"}}>
                    <div className="filters_button_box text_body_light"
                        onClick={() => {filtersStateReducer({type: "SET_POPUP", payload: {showable: true}})}}
                        style={{padding: "6px 8px", margin: "unset"}}
                        >
                        {
                            filtersState.appliedFiltersCount > 0 ? (
                                <div className="default_flex_container" style={{position: "relative"}}>
                                    <img src={FiltersIcon} style={{width: 24, height: 24}} />
                                    <img src={GreenDot} style={{position: "absolute", width: 6, right: 0, top: 0, transform: "translateX(50%)"}} />

                                </div>
                            ): (
                                <img src={FiltersIcon} style={{width: 24, height: 24}} />
                            )
                        }
                    </div>
                    {
                        Object.keys(filtersState.filtersSelected).map(filterType => {
                            return Object.keys(filtersState.filtersSelected[filterType]).map(key => {
                                let filtersSelected = [...filtersState.filtersSelected[filterType][key]];
                                if(filtersSelected.length === 0) return null;
                                return(filtersSelected.map((selected_filter, index) => {
                                    let range_text = ""
                                    if(selected_filter.max > 0){
                                        range_text = `(${selected_filter.min}-${selected_filter.max})`
                                    }
                                    const computed_class = `default_flex_container ${filterType==="include" ? 'text_link type_box_highlight_green' : "type_box_highlight_red"}`
                                    return (
                                        <div key={key + selected_filter.value}                                    
                                            className={computed_class}
                                        >
                                            <div style={{marginRight: "6px", textTransform: "capitalize"}}>{selected_filter.value.replaceAll("_", " ")} {range_text}</div>
                                            <img src={filterType === "include" ? Cross : RedCross} style={{width: "16px"}} alt="Remove"
                                                onClick={(e) => handleFilterRemove(e, key, index, filterType)} 
                                            />
                                        </div>
                                    )
                                }))
                            })
                        })
                    }
                </div>
                {renderResultTabs()}
                <ConditionalRender if={articleList.length > 0}>                    
                    <div className="flex_container_space_between" style={{marginTop: "12px"}}>
                        <div>
                            <MatchingArticleDateFilters
                                filtersState={filtersState}
                                filtersStateReducer={filtersStateReducer}
                                tempFilterReducer={filterReducer}
                            />
                        </div>
                        { !isFetching && (<Pagination
                            style={{margin: "auto", padding: "9px 0px"}}
                            totalPages={smartPublishData.totalPages}
                            currentPage={currentPage}
                            onPageChange={fetchAggregates}
                        />)}
                        <div >
                            <SmartPublishSortDropDown onChangeCallback={filtersStateReducer} sortSelected={filtersState.sortSelected}></SmartPublishSortDropDown>
                        </div>
                    </div>
                </ConditionalRender>

                {!isFetching && articleList.map((article) => showAggregate(article))}
                { isFetching && (<LoaderComponent isLoading={true}  loaderStyle={{width: 32, margin: '36px auto', display: 'block'}}/>)}
                <ConditionalRender if={articleList.length > 0}>                    
                    <div className="flex_container_space_between" style={{marginTop: "12px"}}>
                        { !isFetching && (<Pagination
                            style={{margin: "auto", padding: "9px 0px"}}
                            totalPages={smartPublishData.totalPages}
                            currentPage={currentPage}
                            onPageChange={fetchAggregates}
                        />)}
                    </div>
                </ConditionalRender>
            </div>
            { popupsList.manualPostCreationPopup && renderManualPostPopup()}
            { popupsList.sendNewsResultsPopup && sendNewsResultsPopup()}
            { popupsList.invitePopup && invitePopup()}
            { popupsList.exportPopup && exportPopup()}
            { popupsList.triggerOutboundMailPopup && <AnConfirmPopup
                confirm_message={`Are you sure you want to trigger news emails for entire team?`}
                btnLabels={{ submitLabel: 'Yes', cancelLabel: 'No' }}
                onSubmitCallback={() => triggerNotificationMail()}
                onCancelCallback={() => setPopupsList({})}
            />}
            { popupsList.triggerNewsResultsPopup && <AnConfirmPopup
                confirm_message={`Are you sure you want to send news update emails to all team members?`}
                btnLabels={{ submitLabel: 'Yes', cancelLabel: 'No' }}
                onSubmitCallback={() => triggerNotificationMail('news_result')}
                onCancelCallback={() => setPopupsList({})}
            />}
            { popupsList.triggerJobUpdatesPopup && <AnConfirmPopup
                confirm_message={`Are you sure you want to send job update emails to all team members?`}
                btnLabels={{ submitLabel: 'Yes', cancelLabel: 'No' }}
                onSubmitCallback={() => triggerNotificationMail('job_update')}
                onCancelCallback={() => setPopupsList({})}
            />}
            {
                filtersState.showFilterPopup &&
                (<MatchingArticleBasedFilters
                    filtersState={filtersState}
                    filtersStateReducer={filtersStateReducer}
                    fetchProspects={fetchAggregates}
                    allFilterTabs={smartPublishTabs}
                    tempFilterReducer={filterReducer}
                />)
            }
            {successMsg && (
                <FlashMessages
                    messageTheme="success"
                    closeCallback={() =>
                        setSuccessMsg(null)
                    }
                >
                    {successMsg}
                </FlashMessages>
            )}
        </div>
    );
};

export default SmartAggregatesList;
