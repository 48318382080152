import { ReactComponent as LeftArrow } from '../../assets/directory/leftArrow.svg'
import { ReactComponent as RightArrow } from '../../assets/directory/rightArrow.svg'
import { ReactComponent as UpArrow } from '../../assets/directory/UpArrow.svg'
import { ReactComponent as DownArrow } from '../../assets/directory/DownArrow.svg'
import { ReactComponent as Cancel } from '../../assets/Cancel.svg'
import { ReactComponent as Cross } from '../../assets/cross.svg'
import { ReactComponent as FiltersIcon } from '../../assets/Icons/newsfeed icons/Filters.svg'
import { ReactComponent as FiltersIcon2 } from '../../assets/Icons/newsfeed icons/More filters.svg'
import { ReactComponent as CalendarIcon } from '../../assets/Icons/newsfeed icons/Date range.svg'
import { ReactComponent as GreyArrowDown } from '../../assets/GreyArrowDown.svg'
import { ReactComponent as CommentIcon } from '../../assets/Icons/post icons/comments.svg'
import { ReactComponent as ShareIcon } from '../../assets/Icons/post icons/share.svg'
import { ReactComponent as CRM } from '../../assets/Icons/post icons/crm.svg'
import { ReactComponent as View } from '../../assets/Icons/post icons/view.svg'
import { ReactComponent as Review } from '../../assets/Icons/post icons/review.svg'
import { ReactComponent as Reviewed } from '../../assets/Icons/post icons/review filled.svg'
import { ReactComponent as Flag } from '../../assets/flag.svg'
import { ReactComponent as GreenDot } from '../../assets/GreenDot.svg'
import { ReactComponent as Download } from '../../assets/Icons/newsfeed icons/download.svg'
import { Suspense } from 'react'
const iconMap = {
  LeftArrow: LeftArrow,
  RightArrow: RightArrow,
  UpArrow: UpArrow,
  DownArrow: DownArrow,
  Cancel: Cancel,
  Cross: Cross,
  FiltersIcon: FiltersIcon,
  CalendarIcon: CalendarIcon,
  FiltersIcon2: FiltersIcon2,
  GreyArrowDown: GreyArrowDown,
  CommentIcon: CommentIcon,
  ShareIcon: ShareIcon,
  CRM: CRM,
  View: View,
  Review: Review,
  Reviewed: Reviewed,
  Flag: Flag,
  GreenDot: GreenDot,
  Download: Download
};

const Icon = ({ name, ...props }) => {
  const IconComponent = iconMap[name];

  if (!IconComponent) {
    console.error(`No icon found for name: ${name}`);
    return null;
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <IconComponent {...props} />
    </Suspense>
  );
};

export default Icon;
