import { NewsFeedHighConfidenceTabs } from "../../../../utils/AllFilterTabs";
import { indexOfObject } from "../../../../utils/ArrayUtils";
import { dateFormator, datepicker_input_format, getDateUtil } from "../../../../utils/dateUtils";

const high_conf_dates = {
    created_at: { value: {from: null, to: null}, label: "Posted on", maxDate: dateFormator(new Date(), {type:"datepicker_object"})},
    article_created_date: { value: {from: null, to: null}, label: "Published on", maxDate: dateFormator(new Date(), {type:"datepicker_object"})},
}
const low_conf_dates = {
    created_at: { value: {from: null, to: null}, label: "Created at", maxDate: dateFormator(new Date(), {type:"datepicker_object"})},
}
const high_conf_sort = [
    {value: "created_at-desc", label: "Published at: Newest to Oldest", is_default: true},
    {value: "created_at-asc", label: "Published at: Oldest to Newest"}, 
    {value: "article_created_date-desc", label: "Created at: Newest to Oldest"},
    {value: "article_created_date-asc", label: "Created at: Oldest to Newest"}, 
]
const low_conf_sort = [
    {value: "article_created_date-desc", label: "Created at: Newest to Oldest", is_default: true},
    {value: "article_created_date-asc", label: "Created at: Oldest to Newest"}, 
]
export const initialDropDownFilterList = {viewType: 'all', containComment: 'all', containSuggestion: 'all', activeViewType: 'news_results', review: 'all'}
export const initialFiltersState = {
    filtersSelected: {
        sort: [],
        tags: [],
        assigns: [],
        type: [],
        location: [],
        company: [],
        title: [],
        college: [],
        mentioned_for: [],
        miscellaneous: [],
        score_tags: []
    },
    dateRanges: {...high_conf_dates},
    aggregatesData: {
        sort: [...high_conf_sort],
        tags: [],
        assigns: [],
        type: [],
        location: [],
        company: [],
        title: [],
        college: [],
        mentioned_for: [],
        miscellaneous: [],
        score_tags: [],
    },
    showFilterPopup: false,
    showDateFilterPopup: false,
    activeFilterTab: NewsFeedHighConfidenceTabs[0],
    appliedFiltersCount: 0,
    confidenceType: "high",
    postType: "all",
    dropDownFilterlist: initialDropDownFilterList,
};

export const newsFeedFilterReducer = (state, action) => {
    let {payload} = action;
    switch (action.type) {
        case "ASSIGN_AGGREGATES_DATA":
            return {
                ...state,
                aggregatesData: { ...state.aggregatesData, ...action.payload.aggregates },
            };
        case "ASSIGN_SPECIFIC_AGGREGATES_DATA":
            let aggregatesData = { ...state.aggregatesData };
            return {
                ...state,
                aggregatesData: {
                    ...aggregatesData,
                    [action.payload.filter_type]: action.payload.aggregates,
                },
            };
        case "APPLY_TEMP_FILTERS": {
            let appliedFiltersCount = Object.keys(
                action.payload.filtersSelected
            ).reduce(
                (prev, curr) =>
                    prev + action.payload.filtersSelected[curr].length,
                0
            );
            let new_state = {
                ...state,
                filtersSelected: { ...action.payload.filtersSelected },
                appliedFiltersCount: appliedFiltersCount,
            }
            if(action.payload.dateRanges)
                new_state.dateRanges = action.payload.dateRanges
            return new_state;
        }
        case "SELECTED_FILTER": {
            let filtersSelected = { ...state.filtersSelected };
            let aggregatesData = state.aggregatesData
            let {mappedKey, selectedVal} = action.payload
            let new_aggs = aggregatesData
            
            let newFiltersSelected = {
                ...filtersSelected,
                [action.payload.mappedKey]: action.payload.selections,
            };
            if(action.payload.select){                
                let agg_match_index = aggregatesData[mappedKey].map(agg => agg.term).indexOf(selectedVal)
                let new_aggs_key_val = [...aggregatesData[mappedKey]]
                let spliced = new_aggs_key_val.splice(agg_match_index, 1)
                new_aggs[mappedKey] = [...spliced, ...new_aggs_key_val]                
            }
            return { ...state, 
                filtersSelected: newFiltersSelected,
                aggregatesData: {...new_aggs}
            };
        }        
        case "SELECT_AND_MODIFY": {
            let { mappedKey } = action.payload;
            let insertAt = "PREPEND"
            if(action.payload.insertAt) insertAt = action.payload.insertAt
            let newFiltersSelected = [
                ...(state.filtersSelected[mappedKey] || []),
                action.payload.data.term,
            ];
            let finalFilters = {
                ...state.filtersSelected,
                [mappedKey]: newFiltersSelected,
            };

            let appendedAggregates = []
            if(insertAt == "PREPEND"){
                appendedAggregates = [
                    action.payload.data,
                    ...state.aggregatesData[mappedKey],
                ];
            }else{
                appendedAggregates = [
                    ...state.aggregatesData[mappedKey],
                    action.payload.data,
                ];
            }
            
            let finalAggregates = {
                ...state.aggregatesData,
                [mappedKey]: appendedAggregates,
            };

            return {
                ...state,
                filtersSelected: finalFilters,
                aggregatesData: finalAggregates,
            };
        }
        case "SET_ACTIVE_FILTER_SUB_TAB":{
            let {payload} = action;
            let allFilterTabs = payload.allFilterTabs || NewsFeedHighConfidenceTabs;
            let index = indexOfObject(allFilterTabs, "tab_key", payload.activeFilterTabKey);
            let activeFilter = allFilterTabs[index];
            return { ...state, activeFilterTab: activeFilter };
        }
        case "SET_POPUP":
            return { ...state, [action.payload.popup || "showFilterPopup"]: action.payload.showable };
        case "CLEAR_SELECTED_FILTERS":{
            //clear custom filters as well
            let {allFilterTabs} = action.payload;
            let newFiltersSelected = {...initialFiltersState.filtersSelected}
            allFilterTabs?.forEach(item => {
                if(item.is_custom){
                    newFiltersSelected[item.label] = []
                }
            })
            return {
                ...state,
                filtersSelected: newFiltersSelected,
            };
        }
        case "UPDATE_DATE_RANGE":
            let {dateRanges} = state;
            let temp = dateRanges[payload.key];
            return {
                ...state,
                dateRanges: {...dateRanges, [payload.key]: {...temp, [payload.subkey]: payload.value}}
            };
        case "APPLY_DATE_RANGES": {
            let new_state = {...state};            
            new_state.dateRanges = action.payload.dateRanges
            return new_state;
        }
        case "CLEAR_DATE_RANGES": {
            let new_state = {...state};
            if(action.payload?.confidenceType)
                new_state.confidenceType = action.payload.confidenceType
            
            if(new_state.confidenceType == "low") {
                new_state.dateRanges = {...low_conf_dates}
            }
            else if(new_state.confidenceType == "mid") {
                new_state.dateRanges = {...low_conf_dates}
            }
            else{
                new_state.dateRanges = high_conf_dates
            }
            return new_state;
        }
        case "RESET_FILTER": {
            let new_state = {...state};
            let {allFilterTabs} = action.payload;
            let newFiltersSelected = {...initialFiltersState.filtersSelected}
            let newAggregatesData = {...initialFiltersState.aggregatesData}
            if(action.payload?.confidenceType)
                new_state.confidenceType = action.payload.confidenceType

            if(new_state.confidenceType == "low") {
                new_state.dateRanges = {...low_conf_dates}
                newAggregatesData.sort = [...low_conf_sort]
            }
            else if(new_state.confidenceType == "mid") {
                new_state.dateRanges = {...low_conf_dates}
                newAggregatesData.sort = [...low_conf_sort]
            }
            else{
                new_state.dateRanges = high_conf_dates
                newAggregatesData.sort = [...high_conf_sort]
            }
            allFilterTabs?.forEach(item => {
                if(item.is_custom){
                    newFiltersSelected[item.label] = []
                }
            })
            new_state.aggregatesData = newAggregatesData
            new_state.filtersSelected = newFiltersSelected
            let appliedFiltersCount = Object.keys(
                newFiltersSelected
            ).reduce(
                (prev, curr) =>
                    prev + newFiltersSelected[curr].length,
                0
            );
            new_state.appliedFiltersCount = appliedFiltersCount
            new_state.dropDownFilterlist = initialDropDownFilterList
            return new_state
        }
        case "SET_STATE": {
            return {...state, ...action.payload}
        }
        default:
            return { ...state };
    }
};
