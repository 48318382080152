import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, TabbedButton } from "../../cta_utils/AnButton.js";
import { apiAgent } from "../../../axiosConfig";
import AnPopup from "../../cta_utils/AnPopup";
import AnMultipleSuggestionInputBox from "../../input_utils/AnMultipleSuggestionBox";
import { AnInputBox } from "../../input_utils/AnInput";
import { useSelector } from "react-redux";
import ConditionalRender from "../../../ConditionalRender.js";
import { FlashMessages } from '../../cta_utils/FlashMessages.js';
import AnDropDown from '../../cta_utils/AnDropDown.js'
import TableHeads from "../../visualizers/tabular/TableHeads.jsx";
import TableRows from "../../visualizers/tabular/TableRows.jsx";
import { isWidthDown } from "../../utils/designUtils.js";
import useWindowSize from "../../custom_hooks/useWindowSize.js";
import useCheckUserAccess from "../../custom_hooks/useCheckUserAccess.js";
import getApiUrl from "../../utils/UrlHelper.js";
import MenuDots from '../../../assets/MenuDots.svg'
import GreyArrowDown from "../../../assets/GreyArrowDown.svg"
import {LoaderComponent} from "../../utils/LoaderComponent";
import AnMultipleSelectDropdown from "../../input_utils/AnMultipleSelectDropdown.js"

const fields = ["Name", "Company Name", "Full Name", "Spouse Name", "College Name", "Course", "Branch", "Graduation Year","Past Education", "City", "State", "Country", "Location", "Title/Position", "LinkedIn URL", "Alias Names", "Other Companies"];
const optionsList = [
    {label: 'Spouse Name', key: 'spouse_name'},
    {label: 'Other Companies', key: 'custom_companies'},
    {label: 'Education', key: 'college'},
    {label: 'Course', key: 'course_name'},
    {label: 'Branch', key: 'branch_name'},
    {label: 'Graduation Year', key: 'graduation_year'},
    {label: 'City', key: 'city'},
    {label: 'State', key: 'state'},
    {label: 'Country', key: 'country'},
    {label: 'Location', key: 'location'},
    {label: 'Alias Names', key: 'alias_names'}
];
const ManageDataFields = (props) => {
    const [activeTab, setActiveTab] = useState("Active");
    const [customFields, setCustomFields] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const [isLoading, setIsLoading] = useState({});
    const [currentField, setCurrentField] = useState("");
    const [currentFieldType, setCurrentFieldType] = useState("Text Box");
    const [selectedField, setSelectedField] = useState({});
    const [customOptions, setCustomOptions] = useState([""]);
    const [popupsList, setPopupsList] = useState({});
    const current_user = useSelector((state) => state.current_user.user);
    const current_team = useSelector((state) => state.current_team.team);
    const [successMessage, setSuccessMessage] = useState();
    const windowDimensions = useWindowSize();
    const buttonStyleResponsive = {marginBottom: 14, float: "right" }
    const buttonStyle = {marginTop: 16, float: "right" }
    const checkUserAccess = useCheckUserAccess();
    useEffect(() => {
        if (current_team.save_to_crm_enabled) {
            fields.splice(0, 0, 'CRM ID');
        }
        props.setActiveSettingTab("data_fields");
        apiAgent({
            method: "get",
            url: "/team/custom_fields",
            params: {},
        })
        .then((customFieldsRes) => {
            setCustomFields(customFieldsRes.data.custom_fields);
            let keys = customFieldsRes.data.newsfeed_export_fields;
            let filteredData = keys.map(item => {
                let foundOption = optionsList.find(option => option.key === item);
                let foundCustomField = customFieldsRes.data.custom_fields.find(customField => customField.id === item);
                return foundOption !== undefined ? foundOption : { key: item, label: foundCustomField ? foundCustomField.field_name : '' };
            });
            setSelectedValues(filteredData);
        })
        .catch((err) => {
            console.error(err);
        });
    }, []);

    const addCustomField = () => {
        setIsLoading({creatingField: true});
        if (currentField == "") return;
        apiAgent({
            method: "post",
            url: getApiUrl("create_or_update_custom_fields"),
            data: {
                field_name: currentField,
                field_type: currentFieldType,
                options: customOptions,
            },
        })
            .then((res) => {
                setIsLoading({});
                setSuccessMessage("Custom Field added");
                setPopupsList({ ...popupsList, addFieldPopup: false });
                var temp = [...customFields];
                temp.push(res.data.custom_fields[0]);
                setCustomFields(temp);
                setCurrentField("");
                setCurrentFieldType("Text Box");
            })
            .catch((err) => {
                setIsLoading({});
            });
    };

    const editCustomField = () => {
        setIsLoading({editingField: true});
        if (selectedField == {}) return;
        const data = {
            field_id: selectedField.id,
            field_name: selectedField.field_name,
            indexing_allowed: selectedField.indexing_allowed
        }
        apiAgent({
            method: "post",
            url: getApiUrl("create_or_update_custom_fields"),
            data: data,
        })
            .then((res) => {
                setIsLoading({});
                setSuccessMessage("Custom field edited");
                handlePopupClose();
                let temp = [...customFields]
                temp[selectedField.index] = res.data.customFields[0]     
                setCustomFields(temp);
                setSelectedField({});
            })
            .catch((err) => {
                setIsLoading({});
            });
    };

    const deleteField = () => {
        setIsLoading({deletingField: true});
        if (selectedField == {}) return;
        apiAgent({
            method: "post",
            url: getApiUrl("delete_custom_field"),
            data: {
                field_id: selectedField.id
            },
        })
            .then((res) => {
                setIsLoading({});
                setSuccessMessage("Custom field deleted");
                handlePopupClose();
                let temp = [...customFields]
                temp.splice(selectedField.index, 1);
                setCustomFields(temp);
                setSelectedField({});
            })
            .catch((err) => {
                setIsLoading({});
            });
    }

    const showAddFieldPopup = () => {
        setPopupsList({ ...popupsList, addFieldPopup: true });
    };

    const updateCustomOption = (value, index) => {
        var tempCustomOptions = [...customOptions];
        tempCustomOptions[index] = value;
        setCustomOptions(tempCustomOptions);
    };

    const addCustomOption = () => {
        var tempCustomOptions = [...customOptions];
        tempCustomOptions.push("");
        setCustomOptions(tempCustomOptions);
    };

    const renderSingleFieldDropDown = () => {
        return(
            <AnDropDown style={{position: 'absolute', padding: '20px', background: 'white', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', borderRadius: 4, width: 'max-content', zIndex: 99}}
                toggleDropDown={handleToggleDropdown}>
                <div style={{cursor: 'pointer', marginBottom: "16px"}} className="text_subtitle_semi_bold" onClick={(e) => {e.stopPropagation(); setPopupsList({ ...popupsList, updateFieldPopup: true })}}>
                    Edit
                </div>
                <div style={{cursor: 'pointer'}} className="text_subtitle_semi_bold" onClick={(e) => {e.stopPropagation();setPopupsList({ ...popupsList, deleteFieldConfirmation: true })}}>
                    Delete
                </div>
            </AnDropDown>
        )
    }

    const handlePopupClose = () => {
        setPopupsList({})
    }

    const deleteFieldConfirmation = () => {
        return(
            <AnPopup name="deleteFieldConfirmation" handleOnClosePopup={handlePopupClose} maxWidth={480}>
                <div className='text_body' style={{textAlign: "center"}}>Do you want to delete this custom field?</div>
                <div className='default_flex_container' style={{justifyContent: "center", marginTop: 16}}>
                    <Button buttonSize="Medium" buttonStyle={{width: 'max-content'}} innerButtonStye={{padding: '8px 20px'}}
                            onClick={deleteField} isLoading={isLoading.deletingField}>Delete </Button>
                    <Button type="Secondary" buttonSize="Medium" buttonStyle={{width: 'max-content', marginLeft: 16}} innerButtonStye={{padding: '8px 20px'}}
                        onClick={handlePopupClose}>Cancel</Button>
                </div>
            </AnPopup>
        )
    }

    const handleCustomFilter = (data_field, index) => {
        if(!window.confirm(`Are you sure you want to ${data_field.filters_enabled ? 'disable' : 'enable'} filters for '${data_field.field_name}'?`)) return;
        setIsLoading({[data_field.id]: true});
        apiAgent({
            method: "post",
            url: getApiUrl("create_or_update_custom_fields"),
            data: {
                field_id: data_field.id,
                indexing_allowed: !data_field.indexing_allowed
            }
        }).then(res => {
            let temp = [...customFields]
            temp[index] = res.data.custom_fields[0];
            setCustomFields(temp);
            setIsLoading({});
            setSuccessMessage("Custom field updated");
        }).catch(err => {
            console.log("Something went wrong");
            setIsLoading({});            
        });
    }

    const renderCustomOption = (option, index, disabled = false) => {
        return (
            <AnInputBox>
                <input
                    type="text"
                    value={option}
                    placeholder={"Option " + index}
                    onChange={(e) =>
                        updateCustomOption(e.target.value, index - 1)
                    }
                    disabled={disabled}
                />
            </AnInputBox>
        );
    };

    const addFieldPopup = () => {
        return (
            <AnPopup
                name="addFieldPopup"
                handleOnClosePopup={() => {
                    setPopupsList({ ...popupsList, addFieldPopup: false });
                }}
                maxWidth={480}
            >
                <div className="text_title" style={{ marginBottom: "16px" }}>
                    Add custom field
                </div>
                <AnInputBox label={"Field Name"}>
                    <input
                        type="text"
                        id="name"
                        value={currentField}
                        onChange={(e) => setCurrentField(e.target.value)}
                        placeholder="Name"
                    />
                </AnInputBox>
                <AnInputBox label={"Field Type"}>
                    <select
                        value={currentFieldType}
                        onChange={(e) => setCurrentFieldType(e.target.value)}
                    >
                        <option className="text_body" value="Text Box">
                            Text Box
                        </option>
                        <option className="text_body" value="Radio Button">
                            Radio Button
                        </option>
                        <option className="text_body" value="Check Box">
                            Check Box
                        </option>
                        <option className="text_body" value="Select Box">
                            Select Box
                        </option>
                        <option className="text_body" value="Text Area">
                            Text Area
                        </option>
                    </select>
                </AnInputBox>
                {!currentFieldType.includes("Text") &&
                    customOptions.map((option, index) =>
                        renderCustomOption(option, index + 1)
                    )}
                {!currentFieldType.includes("Text") && (
                    <div className="text_link" onClick={() => addCustomOption()}>
                        Add Option
                    </div>
                )}
                <Button
                    onClick={() => {
                        addCustomField();
                    }}
                    isLoading={isLoading.creatingField}
                    buttonStyle={{ marginTop: "16px" }}
                >
                    Create Custom Field
                </Button>
            </AnPopup>
        );
    };

    const handleFieldInputChange = e => setSelectedField({...selectedField, [e.target.name]: e.target.value})

    const updateFieldPopup = () => {
        return(
        <AnPopup name="updateFieldPopup" handleOnClosePopup={handlePopupClose} maxWidth={480}>
            <div className="text_subtitle_big" style={{marginBottom: 16}}>Type: {selectedField.field_type}</div>
            <AnInputBox label={"Name"}>
                <input type="text" name="field_name" value={selectedField.field_name} onChange={handleFieldInputChange} placeholder="Name"/>
            </AnInputBox>
            <div style={{display: 'flex', marginTop: 16}}>
                <Button buttonSize="Medium" buttonStyle={{width: 'max-content'}} innerButtonStye={{padding: '8px 20px'}}
                    onClick={() => {editCustomField()}} isLoading={isLoading.editingField}>Update</Button>
                <Button type="Secondary" buttonSize="Medium" buttonStyle={{width: 'max-content', marginLeft: 16}} innerButtonStye={{padding: '8px 20px'}}
                    onClick={handlePopupClose}>Cancel</Button>
            </div>
        </AnPopup>)
    }

    const handleToggleDropdown = (e) => {
        e?.stopPropagation()
        setPopupsList({ ...popupsList, fieldDropDown: popupsList.fieldDropDown ? false : true })
    }

    const handleFieldClick = (event, index) => {
        setSelectedField({...customFields[index], index: index})
        handleToggleDropdown()
    }

    const keyValueArray = customFields.map((field) => ({
        key: field.id,
        label: field.field_name
    }));
    let defaultList = [{"Default fields": optionsList}, {"Custom fields": keyValueArray}];

    const handleSubmit = () => {
        const export_fields = selectedValues.length > 0 ? selectedValues.map(option => option.key) : [];
        setIsLoading({addFieldsToExport: true});
        apiAgent({
            method: "post",
            url: getApiUrl("add_fields_newsfeed_export"),
            params: {
                export_fields: export_fields
            },
        })
        .then((res) => {
            setIsLoading({addFieldsToExport: false});
            setSuccessMessage("Changes saved");
        })
        .catch((err) => {
            setIsLoading({addFieldsToExport: false});
        });
    }    

    let custom_width = (current_user.is_admin || current_user.is_super_admin)? "20%" : "30%"
    return (
        <>
            <div className="text_title" style={{ marginBottom: 16 }}>
                Data Fields
            </div>
            <div className="text_body_big_light">
                Improve the quality of your results by adding as many data
                fields as possible during data import. We have a list of default
                fields. You can create your custom fields and upload the data
                with those fields.
            </div>
            <ConditionalRender if={checkUserAccess("custom_filed_adding")}>
            <Button
                onClick={() => showAddFieldPopup()}
                buttonStyle={isWidthDown(windowDimensions.width, "md") ? buttonStyleResponsive : buttonStyle}
            >
                Add Custom Field
            </Button>
            </ConditionalRender>
            <div style={{ clear: "both" }}></div>

            {customFields && customFields.length > 0 && (
                <table className="data_table" style={{marginTop:10, textAlign: "left"}}>
                    <TableHeads typoGraphyClass="text_body_big_light" headerClass="table_header_row">
                        <th style={{width: custom_width}}>Custom Field</th>
                        <th style={{width: custom_width}}>Type</th>
                        <ConditionalRender if={checkUserAccess("enable_custom_field_filter")}>
                            <th style={{width: "10%"}}>Filters</th>
                            <th style={{width: "10%"}}></th>
                        </ConditionalRender>
                        <ConditionalRender if={!checkUserAccess("enable_custom_field_filter")}>
                            <th style={{width: "40%"}}></th>
                        </ConditionalRender>
                    </TableHeads>
                    <tbody>
                        {
                            customFields.map((item,index) => (
                                <TableRows key={item.id} rowClass="table_content_row text_body">
                                    <td>{item.field_name}</td>
                                    <td>{item.field_type}</td>
                                    <td>
                                        <ConditionalRender if={checkUserAccess("enable_custom_field_filter") && item.is_indexable}>
                                            <ConditionalRender if={isLoading[item.id]}>
                                                <LoaderComponent isLoading={true} loaderStyle={{width: 24, display: 'block'}}></LoaderComponent>
                                            </ConditionalRender>
                                            <ConditionalRender if={!isLoading[item.id]}>
                                                <div onClick={(e) => handleCustomFilter(item, index)}>
                                                    {item.indexing_allowed ? (<span className="text_link red">Disable</span>) : (<span className="text_link">Enable</span>)}
                                                </div>
                                            </ConditionalRender>
                                        </ConditionalRender>
                                    </td>
                                    <ConditionalRender if={checkUserAccess("enable_custom_field_filter")}>
                                        <td style={{position: 'relative'}} onClick={(event) => handleFieldClick(event, index)}>
                                            <img src={MenuDots} style={{cursor: 'pointer', width: 20, height: 20}}/>
                                            { popupsList.fieldDropDown && index === selectedField.index ? renderSingleFieldDropDown() : null}
                                        </td>
                                    </ConditionalRender>
                                </TableRows>
                            ))
                        }
                    </tbody>
                </table>
            )}

            <div className="data_field_box_border">
                <div
                    className="text_body_big data_field_border"
                    style={{ background: "#F8F8F8" }}
                >
                    Default fields
                </div>
                {fields.map((item) => (
                    <div className="text_body data_field_border">{item}</div>
                ))}
                <div className="default_flex_container text_body data_field_border">
                    <span style={{ float: "left", flex: 1 }}>Assigned Team Member</span>
                    <ConditionalRender
                        if={checkUserAccess('add_team_member')}
                    >
                        <Link
                            to="/settings/manage_teams?invite=true"
                            className="text_link"
                            style={{ float: "right" }}
                        >
                            Add Team Members
                        </Link>
                    </ConditionalRender>
                    <div style={{ clear: "both" }}></div>
                </div>
                <div className="text_body data_field_border">
                    <span style={{ float: "left" }}>Tags</span>
                    <Link
                        to="/manage/tags"
                        className="text_link"
                        style={{ float: "right" }}
                    >
                        Go to Tags Page
                    </Link>
                    <div style={{ clear: "both" }}></div>
                </div>
            </div>
            <ConditionalRender if={current_user.is_admin || current_user.is_super_admin}>
                <div style={{margin: '16px 0px', borderTop: '0.5px solid #B6B9BB'}}></div>
                <div className="text_subtitle">
                    Data Fields in Export
                </div>
                <div className='text_body' style={{color: "#707274", paddingBottom: "4px", marginTop: 12}}>
                    Choose fields that are to be added to the Export file 
                </div>
                <AnMultipleSelectDropdown 
                    options={defaultList} 
                    maxSelections={30} 
                    selectedValues={selectedValues} 
                    setSelectedValues={setSelectedValues}
                    dropdownStyle={{maxHeight: 200, overflowY: "scroll", overflowX: "hidden",marginRight: -10}}
                />
                <Button isLoading={isLoading.addFieldsToExport}
                    buttonSize="LessPadding" innerButtonStye={{padding: '8px 16px'}}
                    buttonStyle={{marginTop: "20px", width: 'max-content'}}
                    onClick={handleSubmit}
                >
                    Save changes
                </Button>
            </ConditionalRender>
            {popupsList.addFieldPopup && addFieldPopup()}
            {popupsList.updateFieldPopup && updateFieldPopup()}
            {popupsList.deleteFieldConfirmation && deleteFieldConfirmation()}
            {successMessage && <FlashMessages messageTheme='success' firstIcon={true} closeCallback={setSuccessMessage}>{successMessage}</FlashMessages>}
        </>
    );
};

export default ManageDataFields;
