import { indexOfObject } from "../../../../utils/ArrayUtils";
import { AllDataMineFilterTabs } from "../utils/AllDataMineFilterTabs";

export const initialState = {
    filtersSelected: {
        include: {
            ...AllDataMineFilterTabs.reduce((r, tab) => {r[tab.tab_key]=[]; return r}, {}),
            company_size: [{value: {min: "", max: ""}, label: 'Company Size', type: 'range'}]
        },
        exclude: {}
    },
    aggregatesData: {
        include: {
            ...AllDataMineFilterTabs.reduce((r, tab) => {r[tab.tab_key]=[]; return r}, {}),
            profile_updates: [{term: "Employment", label: "Employment"}, {term: "Promotion", label: "Promotion"}],
            matching_type: [{term: "Matched data", label: "Matched data"}, {term: "Unmatched data", label: "Unmatched data"}],
            // course: [{term: "I.C.T", count: 20}, {term: "Computer Science", count: 10}, {term: "Economics", count: 5}],
            // branch: [{term: "B.tech", count: 20}, {term: "M.B.B.S", count: 10}, {term: "B.S.C", count: 5}]
        },
        exclude: {}
    },
    searchedAggregatesData: {
        include: {
            ...AllDataMineFilterTabs.reduce((r, tab) => {r[tab.tab_key]=[]; return r}, {}),
            profile_updates: [{term: "Employment", label: "Employment"}, {term: "Promotion", label: "Promotion"}],
            matching_type: [{term: "Matched data", label: "Matched data"}, {term: "Unmatched data", label: "Unmatched data"}],
        },
        exclude: {}
    },
    allFilterTabs: AllDataMineFilterTabs,
    appliedFiltersCount: 0,
    totalAppliedFiltersCount: 0
}

const updateAppliedFilterCount = (newFiltersSelected, filterType = 'include', total = false) => {
    let count = 0;
    newFiltersSelected[filterType] = {...newFiltersSelected[filterType]}
    Object.keys(newFiltersSelected[filterType]).map((mappedKey) => {
        let temp = [...newFiltersSelected[filterType][mappedKey]];
        let is_range = temp[0]?.type === 'range';
        let applied_filter = total ? temp : temp.filter((selected_filter) => !selected_filter.applied)
        count += applied_filter?.length 
        if (is_range && temp[0]?.value.min.trim() === "" && temp[0]?.value.max.trim() === "")
        count -= 1        
    })
    return count
}

export const prospectListDataMineReducer = (state, action) => {
    let {payload} = action;
    switch (action.type) {
        case "ASSIGN_AGGREGATES":
            let {aggregates, filterType} = action.payload;
            let newAggregates = {...state.aggregatesData};
            newAggregates[filterType] = {...newAggregates[filterType], ...aggregates}
            return {
                ...state,
                aggregatesData: newAggregates,
            };
        case "ASSIGN_SEARCHED_AGGREGATES": {
            let {aggregates, filterType, mappedKey} = action.payload;
            let newAggregates = {...state.searchedAggregatesData};
            newAggregates[filterType][mappedKey] = [...aggregates]
            return {
                ...state,
                searchedAggregatesData: newAggregates,
            };
        }
        case "SELECTED_FILTER": {
            let {filtersSelected, aggregatesData} = state
            let {filterType, selections, mappedKey} = payload            
            let newFiltersSelected = {...filtersSelected}
            let insertAt
            if(action.payload.insertAt) insertAt = action.payload.insertAt
            let newAggregates = {...aggregatesData};
            newAggregates[filterType] = {...newAggregates[filterType]}
            newAggregates[filterType][mappedKey] = [...newAggregates[filterType][mappedKey]]
            let tempAggregatesData = [...newAggregates[filterType][mappedKey]]
            selections.map((selection) => {
                let filtersSelectedidx = indexOfObject(newFiltersSelected[filterType][mappedKey], "value", selection.value);
                if(filtersSelectedidx === -1)
                {
                    let newAggregatesidx = indexOfObject(newAggregates[filterType][mappedKey], "term", selection.value);
                    if(newAggregatesidx !== -1  && insertAt === "PREPEND")
                    {
                        tempAggregatesData.splice(newAggregatesidx, 1);
                        tempAggregatesData.unshift(newAggregates[filterType][mappedKey][newAggregatesidx]);
                    }
                }
            })
            newAggregates[filterType][mappedKey] = [...tempAggregatesData]
            newFiltersSelected[filterType] = {
                ...newFiltersSelected[filterType],
                [mappedKey]: selections
            }
            let appliedFiltersCount = updateAppliedFilterCount(newFiltersSelected, filterType);
            let totalappliedFiltersCount = updateAppliedFilterCount(newFiltersSelected, filterType, true)
            return {...state, filtersSelected: newFiltersSelected, appliedFiltersCount: appliedFiltersCount, totalAppliedFiltersCount: totalappliedFiltersCount, aggregatesData: newAggregates}
        }
        case "APPLIED_FILTER": {
            let {filtersSelected, aggregatesData} = state
            let {tags, filterType} = payload
            let newFiltersSelected = {...filtersSelected}
            Object.keys(tags).map((tag) => {
                let val = tags[tag];
                let mappedKey = tag;
                newFiltersSelected[filterType] = {...newFiltersSelected[filterType]};
                let tempFiltersSelected = newFiltersSelected[filterType][mappedKey] ? [...newFiltersSelected[filterType][mappedKey]] : []  
                newFiltersSelected[filterType][mappedKey] = tempFiltersSelected;  
                let tempAggregatesData = aggregatesData[filterType][mappedKey] ? [...aggregatesData[filterType][mappedKey]] : [] 
                val.map((item) => {
                    let v = item?.term || item;
                    let new_filter = {value: v}
                    let indexOfFilterSelected = indexOfObject(tempFiltersSelected, "value", v);
                    let indexOfAggregatesData = indexOfObject(tempAggregatesData, "term", v);
                    if(indexOfAggregatesData !== -1)
                    new_filter = {...new_filter, count: tempAggregatesData[indexOfAggregatesData].count, applied: tempAggregatesData[indexOfAggregatesData].applied ? tempAggregatesData[indexOfAggregatesData].applied : true};
                    if(indexOfFilterSelected === -1 && typeof v==='string')
                    {
                        try {
                            new_filter["value"] = JSON.parse(v);
                          } catch (error) {
                            new_filter["value"] = v
                          }
                        if(newFiltersSelected[filterType][mappedKey][0]?.type === 'range'){
                            newFiltersSelected[filterType][mappedKey][0].applied = new_filter.value.min !== '' && new_filter.value.max !== ''
                            newFiltersSelected[filterType][mappedKey][0].value = new_filter.value
                        }
                        else newFiltersSelected[filterType][mappedKey].push(new_filter);
                    }
                })
            })
            return {...state, filtersSelected: newFiltersSelected, 
                totalAppliedFiltersCount: updateAppliedFilterCount(newFiltersSelected, filterType, true)
            }
        }
        case "APPLY_TEMP_FILTERS": {
            return {
                ...state,
                filtersSelected: { ...action.payload.filtersSelected },
                appliedFiltersCount: updateAppliedFilterCount(action.payload.filtersSelected),
                totalAppliedFiltersCount: updateAppliedFilterCount(action.payload.filtersSelected, 'include', true)
            };
        }
        case "SELECT_AND_MODIFY": {
            let { mappedKey, filterType } = action.payload;
            let insertAt = "PREPEND"
            if(action.payload.insertAt) insertAt = action.payload.insertAt
            
            let newFiltersSelected = {...state.filtersSelected}
            newFiltersSelected[filterType] = {...newFiltersSelected[filterType]};
            newFiltersSelected[filterType][mappedKey] = [...newFiltersSelected[filterType][mappedKey]];
            newFiltersSelected[filterType][mappedKey].push({value: action.payload.data.term});
            let finalFilters = newFiltersSelected;


            let appendedAggregates = []
            if(insertAt === "PREPEND"){
                appendedAggregates = [
                    action.payload.data,
                    ...state.aggregatesData[filterType][mappedKey],
                ];
            }else{
                appendedAggregates = [
                    ...state.aggregatesData[filterType][mappedKey],
                    action.payload.data,
                ];
            }
            
            let finalAggregates = {
                ...state.aggregatesData,
                [filterType]: {...state.aggregatesData[filterType], [mappedKey]: appendedAggregates}
            };

            return {
                ...state,
                filtersSelected: finalFilters,
                aggregatesData: finalAggregates,
                appliedFiltersCount: updateAppliedFilterCount(newFiltersSelected),
                totalAppliedFiltersCount: updateAppliedFilterCount(newFiltersSelected, filterType, true)
            };
        }
        case "TOGGLE_TAB": {
            let {tabKey, val} = payload;
            let index = indexOfObject(state.allFilterTabs, "tab_key", tabKey);
            let newAllFilterTabs = [...state.allFilterTabs];
            if(index!==-1)  newAllFilterTabs[index].active = val;
            return {...state, allFilterTabs: newAllFilterTabs};
        }
        case "CLEAR_SELECTED_FILTERS": {
            let filterType = 'include'
            let newFiltersSelected = {...state.filtersSelected}
            let initFiltersSelected = {...initialState.filtersSelected}
            initFiltersSelected[filterType] = {...initFiltersSelected[filterType]}
            Object.keys(newFiltersSelected[filterType]).map(mappedKey => {
                let filtersSelected = [...newFiltersSelected[filterType][mappedKey]];
                let is_range = filtersSelected[0]?.type === 'range';
                let appliedFiltersSelected = filtersSelected.filter((selected_filter)=>selected_filter.applied)
                if(is_range)
                {
                    filtersSelected[0].value.min = ""
                    filtersSelected[0].value.max = ""
                }
                else
                {
                    filtersSelected = appliedFiltersSelected
                }
                newFiltersSelected[filterType][mappedKey] = filtersSelected
            }) 
            return {
                ...state,
                filtersSelected: newFiltersSelected,
                appliedFiltersCount: updateAppliedFilterCount(newFiltersSelected),
                totalAppliedFiltersCount: updateAppliedFilterCount(newFiltersSelected, filterType, true)
            };
        }
        case "CLEAR_ALL_FILTERS": {
            let filterType = 'include'
            let newFiltersSelected = {...state.filtersSelected}
            let initFiltersSelected = {...initialState.filtersSelected}
            initFiltersSelected[filterType] = {...initFiltersSelected[filterType]}
            Object.keys(newFiltersSelected[filterType]).map(mappedKey => {
                let filtersSelected = [...newFiltersSelected[filterType][mappedKey]];
                filtersSelected.map((selected_filter) => {
                    if(selected_filter.type === 'range')
                    {
                        selected_filter.value.min = ""
                        selected_filter.value.max = ""
                    }
                    else{
                        newFiltersSelected[filterType][mappedKey] = []
                    }
                })
            }) 
            return {
                ...state,
                filtersSelected: newFiltersSelected,
                totalAppliedFiltersCount: updateAppliedFilterCount(newFiltersSelected, filterType, true)
            };
        }
        default:{
            return { ...state };
        }
    }
};
