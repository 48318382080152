import React, {useState, useEffect} from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import {apiAgent} from '../../../axiosConfig.js'
import {AnInputBox} from '../../input_utils/AnInput.js'
import {Button} from '../../cta_utils/AnButton.js'
import userIcon from '../../../assets/user.svg'
import userCredentialIcon from '../../../assets/exit.svg'
import AnPopup from "../../cta_utils/AnPopup";
import MenuDots from '../../../assets/MenuDots.svg'
import NoTagsIcon from '../../../assets/3UsersIcon.svg'
import { LoaderComponent } from '../../utils/LoaderComponent.js'
import { FlashMessages } from '../../cta_utils/FlashMessages.js'
import FileUpload from '../../../FileUpload.js'
import useCheckUserAccess from '../../custom_hooks/useCheckUserAccess.js'
import ConditionalRender from '../../../ConditionalRender.js'
import { Link } from "react-router-dom";
import Folder from "../../../assets/Folder.svg";
import Users from "../../../assets/Users.svg";
import AnMultipleSuggestionInsideBox from "../../input_utils/AnMultipleSuggestionInsideBox.js";
import AnToggleButton from '../../cta_utils/AnToggleButton.js'
import AnDropDown from "../../cta_utils/AnDropDown.js";
import { current } from '@reduxjs/toolkit'
import Lock from "../../../assets/Lock.svg";
import getApiUrl from '../../utils/UrlHelper.js'

const PostFolders = () => {
    const [folders, setFolders] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [popupsList, setPopupsList] = useState({});
    const [currentFolder, setCurrentFolder] = useState({});
    const [folder, setFolder] = useState({
        name: "",
        team_members: [],
        showFolderPrivacyToggle: false
    })
    const current_user = useSelector(state => state.current_user.user);
    const [tempFolder, setTempFolder] = useState({});
    const [fetchData, setFetchData] = useState(false);
    const [successMsg, setSuccessMsg] = useState(null);
    
    useEffect(() => {
        setIsFetching(true)
        apiAgent({
            method: 'get',
            url: getApiUrl("fetch_member_folders"),
            params: {}
        }).then(res => {
            setIsFetching(false);
            let data = res.data.folders;
            data.map(folder => ({
                ...folder,  
                showDropdown: false
            }));
            setFolders(data);
        }).catch(err => {
            setIsFetching(false)
        })
    }, [fetchData]);

    const handleShowDropdown = (index) => {
      setCurrentFolder(index);
      const updatedFolders = [...folders];
      updatedFolders[index].showDropdown = !updatedFolders[index].showDropdown; // Set showDropdown to true for the specific folder
      setFolders(updatedFolders);
    }

    const deleteFolder = () => {
      const confirmed = window.confirm("Are you sure you want to delete this folder?");
      if(confirmed){
        setFetchData(true);
        apiAgent({
            method: 'post',
            url: getApiUrl("delete_folder"),
            params: {folder_id: folders[currentFolder].id}
        }).then(res => {
          setFetchData(false);
          setSuccessMsg("Folder deleted");
        }).catch(err => {
          setFetchData(false);
        })
      }
    }

    const showFolderDropdown = (index) => {
      const folder = folders[index];
      if (folder.showDropdown) {
        return (
            <AnDropDown
                style={{
                    position: "absolute",
                    right: 0,
                    padding: "24px",
                    background: "white",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                    borderRadius: 4,
                    width: "max-content",
                    zIndex: 99,
                    fontSize: 14
                }}
                toggleDropDown={() => handleShowDropdown(currentFolder)}
            >
                <div
                    style={{ marginBottom: 16, cursor: "pointer" }}
                    onClick={() => {setTempFolder(folders[currentFolder]);
                      setPopupsList({editFolderPopup: true})}}
                >
                    Edit Folder
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    onClick={() => deleteFolder()}
                >
                    Delete Folder
                </div>
            </AnDropDown>
        );
      }else{
        return null;
      }
  };

    const renderFolders = () => {
        return (
          <div className='default_flex_container' style={{ flexDirection: "row", flexWrap: "wrap" }}>
            {folders.length > 0 &&
              folders.map((folder,index) => (
                <div style={{ width: "315px",height: 160,margin: (index%3 === 2)? "16px 0px" : "16px 24px 16px 0px", borderRadius: 8,padding: 24, border: "1px solid #E7ECEB", background: "#FAFBFC" }} key={folder.id}>
                  <div className="default_flex_container" style={{flexDirection: "initial"}}>
                        <img src={Folder} style={{height: 20, width: 20}}></img>
                        <a href={"/manage/folders/" + folder.id} style={{textDecoration: "none"}}>
                            <div className='text_body_big_bold' style={{marginLeft: 4}}>{folder.name}</div>
                        </a>
                  </div>
                    <div className="text_subtitle_semi_bold" style={{color: "#707274"}}> 
                    <div style={{marginTop: 8}}>{folder.post_count} items</div>
                    <div style={{fontSize: 12, marginTop: 4}}>Created by <span style={{color: "#282A2C", fontWeight: 700}}> {folder.created_by} </span></div>
                    <div className="line_seperator_ex_light_grey" style={{margin: "16px 0px"}}></div>
                    <div style={{marginBottom: 16}}>
                      <div className="flex_container_space_between text_caption">
                        <div
                            style={{
                            flex: 1,
                            maxWidth: '278px',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word'
                            }}
                        >
                            {folder.team_members.length > 0 ? (
                            <div className="default_flex_container" style={{ alignItems: 'center' }}>
                              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <img src={Users} alt="Users" style={{ marginRight: 4, marginBottom: 2}} />
                                <span>
                                  Shared with {folder.team_members[0].name.split(" ")[0]}
                                  <ConditionalRender if={folder.team_members.length > 1}>
                                    <span style={{marginLeft: 4}}>
                                      and  
                                    </span>
                                  </ConditionalRender>
                                  <ConditionalRender if={folder.team_members.length > 1}>
                                    <span style={{marginLeft: 4}}>
                                      {folder.team_members.length -1 } other{folder.team_members.length > 2 && <span>s</span>}
                                    </span>
                                  </ConditionalRender>
                                </span>
                              </div>
                            </div>                          
                            ) : (
                            <div>
                              <img src={Lock} alt="Users" style={{ marginRight: 4, marginBottom: 2}} /> <span>Private</span>
                            </div>
                            )}
                        </div>
                        <div onClick={() => handleShowDropdown(index)} style={{position:"relative"}}>
                          <img src={MenuDots} alt="Menu" style={{ flexShrink: 0, height: 20, width: 20, cursor: "pointer" }} />
                          {showFolderDropdown(index)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        );
    };

    const handleFolderSharingData = (val) => {
      console.log(val);
        setFolder({
            ...folder,
            team_members: Array.from(new Set(val))
        })
    };

    const addNewFolder = () => {
        let members = [current_user.id, folder.team_members.map((member) => { return member.id})];
        setFetchData(true);
        apiAgent({
            method: "post",
            url: getApiUrl("create_new_folder"),
            data: {
                name: folder.name,
                team_member_ids: members
            },
        }).then((res) => {
            setPopupsList({});
            setFetchData(false);
            setFolder({
              name: "",
              team_members: [],
              showFolderPrivacyToggle: false
            });
            setSuccessMsg("Folder created");
        })
        .catch((err) => {
          setFetchData(false);
        });
    }

    const editFolderPopup = () => {
      
      const handleFolderNameChange = (e) => {
          const updatedFolder = { ...tempFolder };
          updatedFolder.name = e.target.value;
          setTempFolder(updatedFolder);
      };
  
      const handleFolderPrivacyToggle = () => {
          const updatedFolder = { ...tempFolder };
          updatedFolder.showFolderPrivacyToggle = !updatedFolder.showFolderPrivacyToggle;
          setTempFolder(updatedFolder);
      };
  
      const handleFolderSharingData = (val) => {
          const updatedFolder = { ...tempFolder };
          updatedFolder.team_members = val;
          setTempFolder(updatedFolder);
      };
  
      const saveEditedFolder = () => {
        const members = [current_user.id].concat(
          tempFolder.team_members.map((member) => member.id)
        ); 
          apiAgent({
              method: 'post',
              url: getApiUrl("update_folder"),
              params: {
                folder_id: folders[currentFolder].id,
                name: tempFolder.name,
                team_member_ids: members
              }
          }).then(res => {
            window.location.reload();
          }).catch(err => {
              setIsFetching(false)
          })
      };
  
      return (
          <AnPopup
              name="editFolderPopup"
              handleOnClosePopup={() => setPopupsList({})}
              maxWidth={480}
          >
              <div className="text_title">Edit Folder</div>
              <AnInputBox label={"Folder Name"} style={{ margin: '16px 0px' }}>
                  <input
                      type="text"
                      id="folder_name"
                      value={tempFolder.name}
                      onChange={handleFolderNameChange}
                      placeholder="Folder name"
                  />
              </AnInputBox>
              <AnToggleButton
                  toggleState={tempFolder.showFolderPrivacyToggle}
                  toggleText="Allow team members to this folder"
                  onChangeCallback={handleFolderPrivacyToggle}
                  outerToggleStyle={{ margin: "16px 0px" }}
              />
              <ConditionalRender if={tempFolder.showFolderPrivacyToggle}>
                  <AnMultipleSuggestionInsideBox
                      callback={handleFolderSharingData}
                      suggestionUrl="/suggestions/team_members"
                      placeholder="Search team members"
                      inputClass="an_search_box"
                      name="Team_member_search"
                      theme="default"
                      selectedOptions={tempFolder.team_members || []}
                  />
              </ConditionalRender>
              <Button onClick={saveEditedFolder} buttonSize="LessPadding">
                  Save
              </Button>
          </AnPopup>
      );
    };
  
  

    const createNewFolderPopup = () => {
        return (
          <AnPopup
            name="createNewFolder"
            handleOnClosePopup={() => setPopupsList({})}
            maxWidth={480}
          >
            <div className="text_title">New folder</div>
            <div style={{height: 1, background: "rgb(231, 236, 235)", margin: "16px 0px 20px"}}></div>
            <AnInputBox label={"Folder Name"} style={{ margin: '16px 0px 12px' }}>
              <input
                type="text"
                id="folder_name"
                value={folder.name}
                onChange={(e) => setFolder({
                  ...folder,
                  name: e.target.value
                })}
                placeholder="Folder name"
              />
            </AnInputBox>
            <AnToggleButton
              toggleState={folder.showFolderPrivacyToggle} // Removed 'this.state.'
              toggleText="Allow team members to this folder" // Removed 'this.state.'
              onChangeCallback={() => setFolder({
                ...folder, // Corrected object spread syntax here
                showFolderPrivacyToggle: !folder.showFolderPrivacyToggle
              })}
              outerToggleStyle={{ margin: "16px 0px 24px" }}
            >
            </AnToggleButton>
            <ConditionalRender if={folder.showFolderPrivacyToggle}>
              <AnMultipleSuggestionInsideBox
                callback={handleFolderSharingData}
                suggestionUrl="/suggestions/team_members"
                placeholder="Search team members"
                inputClass="an_search_box"
                name="Team_member_search"
                theme="default"
                selectedOptions={folder.team_members || []}
              />
            </ConditionalRender>
            <Button
              onClick={addNewFolder}
              buttonSize="LessPadding"
              innerButtonStye={{padding: "8px 36px"}}
            >
              Add
            </Button>
          </AnPopup>
        );
    }

    return (
        <div style={{maxWidth: 1290, padding: "26px 148px", margin: "auto"}}>
            <div className="flex_container_space_between">
                <div className='text_title_big'>Folders</div>
                <Button
                    onClick={() => setPopupsList({createNewFolderPopup: true})}
                    buttonSize="LessPadding"
                >
                   + Create New
                </Button>
            </div>
            {renderFolders()}
            {popupsList.createNewFolderPopup && createNewFolderPopup()}
            {popupsList.editFolderPopup && editFolderPopup()}
            {successMsg && (
                <FlashMessages
                    messageTheme="success"
                    closeCallback={() =>
                        setSuccessMsg(null)
                    }
                >
                    {successMsg}
                </FlashMessages>
            )}
        </div>
    )
}

export default PostFolders
