import env from 'react-dotenv'

const dev = {
  "API_URL": "http://0.0.0.0:3002"
}

const prod = {
  "API_URL": "https://karmabox.almaconnect.com"
}


const REACT_DEV = {"WEB_URL": "http://localhost:3000/"};
const REACT_PROD = {"WEB_URL": "https://news.almaconnect.com/"};


const api_config = process.env.NODE_ENV === 'production' ? prod : dev;

export default api_config
export const FRONTEND_CONFIG = process.env.NODE_ENV === 'production' ?  
                              REACT_PROD :
                              REACT_DEV
