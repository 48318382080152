const ALL_TIME_ZONES = {
    "Eastern Standard Time - EST": "EST",
    "Universal Time Coordinated - UTC": "UTC",
    "Indian Standard Time - IST": "New Delhi",
    "Hawaii Standard Time - HST": "Hawaii",
    "Alaska Daylight Time - AKDT": "Alaska",
    "Pacific Standard Time - PST": "Pacific Time (US & Canada)",
    "Mountain Standard Time - MST": "Mountain Time (US & Canada)",
    "Central Standard Time- CST": "Central Time (US & Canada)",
    "Atlantic Standard Time": "Atlantic Time (Canada)",
    "Australian Eastern Standard Time - AEST": "Australia/Sydney",
    "British Summer Time - BST": "Europe/London"
};

export { ALL_TIME_ZONES };
