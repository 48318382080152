import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { apiAgent } from '../../../../axiosConfig';
import { LoaderComponent } from '../../../utils/LoaderComponent';
import { FlashMessages } from '../../../cta_utils/FlashMessages';
import DownloadImage from "../../../../assets/download.png";

const WebPageJobCategory = () => {
    
    const [webPage, setWebPage] = useState([]);
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const current_team = useSelector(state => state.current_team.team);

    useEffect(() => {
        setLoading(true);
        apiAgent({
            method: "get",
            url: "/linkedin_prospect/job_category_webpage",
            data: {},
        }).then(
            (res) => {
                console.log(res.data);
                setWebPage(res.data.page_data);
                setLoading(false);
            },
            (error) => {
                console.log("error!");
            }
        );
    }, [])

    const download_job_category_pdf = () => {
        apiAgent({
            method: "post",
            url: "/linkedin_prospect/export_linkedin_job_category_data",
            data: {
            },
        }).then(
            (res) => {
                setSuccessMessage("Job category will be downloaded soon.")
            },
            (error) => {
            }
        );
    }
    let max_chars = Math.max(...webPage.map((p) => (p.name + (p.location ? (' in ' + p.location) : '')).length));
    
    return (
        <div style={{ gap: 24, alignItems: "flex-start", maxWidth: "810px", margin: "auto", paddingTop: 32 }}>
            <div className='flex_container_space_between'>
                <div className='text_title_big'>{current_team.name}</div>
                <div onClick={() => download_job_category_pdf()} style={{marginRight: 12, position: 'relative', float: 'right', display: 'flex', alignItems: 'center', cursor: "pointer"}}>
                    <img src={DownloadImage} style={{maxWidth: 15, maxHeight: 15}}></img>
                    <span className='text_link' style={{marginLeft: 6}}>Download Job Category</span>
                </div>
                </div>
            <div style={{ fontSize: 16, lineHeight: "22px", fontWeight: 700, color: "#707274" }}>Job change stats</div>
            <div style={{ fontSize: 16, lineHeight: "24px", fontWeight: 400, marginTop: 20 }}>
                Staying informed about the career moves of your alumni can provide invaluable insights. That is why we are excited to
                introduce AlmaConnect DataMine, designed to keep you informed, up to date, & provide easy access to employment updates.
            </div><br/>
            <div style={{ fontSize: "16px", lineHeight: "24px", fontWeight: 400, marginBottom: 16 }}>
                <span style={{ marginBottom: '4px' }}>With DataMine, you can:</span><br />
                <ul>
                    <li><span style={{ fontWeight: 700}}>Stay Updated:</span> Receive the latest employment updates, including job changes, promotions, and advancements within your network.</li>
                    <li><span style={{ fontWeight: 700}}>Make Custom Lists:</span> Create custom lists of alumni focusing on factors like location and designation, among many others.</li>
                </ul>
                Here are a few categories where your alumni have recently embraced new roles:
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '24px', alignItems: 'flex-start', marginBottom: 24 }}>
            {
                !loading ?
                <>
                {webPage.length > 0?
                <div style={{ marginTop: '8px', textAlign: "center" }}>
                        {webPage.map((post) => {
                            const temp = `${post.name}${post.location ? ' in ' + post.location : ''}`;
                            const dummy = "_".repeat(max_chars - temp.length);                     
                            return (
                                <div key={post.id} style={{ display: 'inline-block', width: '258px', height: 'max-content', background: '#F9F9F9', borderRadius: '8px', boxSizing: 'border-box', padding: '16px 18px', textAlign: 'left', margin: '12px 6px 0px', border: '1px solid #B6B9BB' }}>
                                    <div className="text_subtitle_big" style={{ fontWeight: '700', lineHeight: '24px'}}>
                                        {temp}
                                        {dummy.length > 0 && <span style={{ color: '#F9F9F9' }}>{" "+ dummy}</span>}
                                    </div>
                                    <div className="text_subtitle_big" style={{ fontWeight: '400', marginTop: '8px' }}>
                                        <span className="text_link" style={{ fontSize: '16px' }}>
                                            {post.count} alumni
                                        </span> have changed their jobs recently
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
                :
                <div className='text_title_big' style={{display: "block", margin: "48px auto"}}>
                    No Categories to show
                </div>
                }
                </>
                :
                <LoaderComponent isLoading={loading}></LoaderComponent>
            }
            </div>
            {successMessage && <FlashMessages messageTheme='success' firstIcon={true} closeCallback={setSuccessMessage}>{successMessage}</FlashMessages>}
        </div>
    )

}
export default WebPageJobCategory;