import { useContext, useEffect, useMemo, useState } from "react";
import ConditionalRender from "../../../../../ConditionalRender";
import { Button } from "../../../../cta_utils/AnButton";
import { AnInputCheckBox } from "../../../../input_utils/AnInputCheckBox";
import { indexOfObject } from "../../../../utils/ArrayUtils";
import { LoaderComponent } from "../../../../utils/LoaderComponent";
import humanizeString from "../../../../utils/stringUtils";
import AggregatesListContext from "./aggregates_list_context";

export const AggregatesList = ({showsearchedRes, ...props}) => { 
    const { currfilterState, setCurrFilterState, filterTab, isLoading} = useContext(AggregatesListContext);
    const {filtersSelected: currfiltersSelected} = currfilterState
    const mappedKey = filterTab.tab_key
    const filterType = 'include';
    const tagsList = useMemo(() => {
      return showsearchedRes ? currfilterState.searchedAggregatesData[filterType][mappedKey] : currfilterState.aggregatesData[filterType][mappedKey]
    }, [showsearchedRes, currfilterState]) 
    const handleTagsChange = (e) => {
        let val = e.target.value
        let currentTags = [...currfiltersSelected[filterType][mappedKey]]
        let index = indexOfObject(currentTags, "value", val)
        let temp = []
        if(index===-1) {
          temp = [...currentTags, {value: val}]
        }
        else{
          temp = [...currentTags]
          temp.splice(index, 1)
        }
        setCurrFilterState({
            type: "SELECTED_FILTER",
            payload: { 
                mappedKey: mappedKey,
                filterType, 
                selections: temp, 
            },
        });
    }
    return (
        <div style={{marginTop: 16, height: 320, overflowY: 'scroll'}}>
            <ConditionalRender if={isLoading.tagsListLoader}>
                <LoaderComponent isLoading={true}></LoaderComponent>
            </ConditionalRender>
            <ConditionalRender if={!isLoading.tagsListLoader}>
                <div style={{  padding: '0px 0px 8px' }}>
                    {
                        tagsList.map((item, index) => {
                            if (!item.term) return null;
                            let is_checked = indexOfObject(currfiltersSelected[filterType][mappedKey], "value", item.term) !== -1
                            return (
                                <div key={item.term + index}>
                                    <AnInputCheckBox
                                        label={`${humanizeString(item.term)} ${item.count ? ("(" + item.count + ")") : ""}`}
                                        isChecked={is_checked}
                                        style={{ marginBottom: '8px' }}
                                    >
                                        <input type="checkbox" checked={is_checked} value={item.term} disabled={item.applied}
                                            onChange={handleTagsChange}
                                        />
                                    </AnInputCheckBox>
                                </div>
                            )
                        })
                    }
                </div>
            </ConditionalRender>
        </div>
    )
}