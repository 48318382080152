import AddingProspect from "../../../../../assets/help_center/adding_prospects.png"
import AddingProspectStep1 from "../../../../../assets/help_center/adding_prospects_step1.png"
import AddingProspectStep2 from "../../../../../assets/help_center/adding_prospects_step2.png"
import AddingProspectStep3 from "../../../../../assets/help_center/adding_prospects_step3.png"
import { isWidthDown } from "../../../../utils/designUtils"
import useWindowSize from "../../../../custom_hooks/useWindowSize";
import AnScrollToLinkPage from "../../../../cta_utils/AnScrollToLinkPage";

export const AddingProspects = () => {
    const windowDimensions = useWindowSize();
    return (
        <div style={{ marginTop: 24 }}>
            <div className="help_center_top_section">
                <div className="text_title_big" style={{marginBottom: 20}}>Adding Prospects</div>
                <div style={{border: "1px solid grey", padding: "14px 20px", borderRadius: "4px", fontWeight: "700"}}>
                    <div className="text_body" style={{color: "#707274", fontWeight: 700}}> SKIP AHEAD TO</div>
                    <div style={{marginTop: "12px"}}>
                        <span style={{color: "#437EFE", fontSize: "14px"}}><AnScrollToLinkPage href="#adding_prospects"> Adding Prospects </AnScrollToLinkPage></span>
                        <span style={{color: "#437EFE", marginLeft: 20, fontSize: "14px"}}><AnScrollToLinkPage href="#initiate_import"> Initiating your import</AnScrollToLinkPage> </span>
                        <span style={{color: "#437EFE", marginLeft: 20, marginRigt: 77, fontSize: "14px"}}><AnScrollToLinkPage href="#updating_prospect"> Updating prospect data</AnScrollToLinkPage> </span>
                    </div>
                </div>
            </div>

            <div className="help_center_text" id="adding_prospects">
                There are two ways to Add prospects to your team on AlmaConnect News. <br/>
                <div style={{marginTop: 12}}></div>
                i. Add prospect individually <br/>
                ii. Importing data using a CSV
            </div>

            <div className="help_center_bold" style={{ marginTop: 16}}>
                i. Adding prospects individually
            </div>
            <div className="help_center_item" style={{ marginTop: 6 }}>
                A single prospect can be added by clicking on the Add prospect from the prospect page.
            </div>
            <img className="help_center_img" src={AddingProspect} style={{maxWidth: "446px", height: 178, margin: "24px auto"}}></img>


            <div className="help_center_bold" style={{ marginTop: 16}}>
                ii. Importing data using a CSV
            </div>
            <div className="help_center_text" style={{ marginTop: 6 }}>
                Using a CSV file, you can import your data to your team on AlmaConnect News. The data should contain fields like Name, Title, Company, Location etc. 
            </div>
            <div className="help_center_text">
                Not sure how to format your CSV? Download our sample file <a className="text_link" href="https://d3sr7cc30ek8f4.cloudfront.net/raw/prod/64d600b9442fa20010ee757e/SampleDBforImport-Individuals.csv"> here.</a>
            </div>

            <div style={{background: "#FAFBFC", marginTop: 12, padding: 16}}>
                <div className="help_center_bold">
                    Some formatting tips for the CSV file:
                </div>
                <div>
                <div className="help_center_semi_bold" style={{marginTop: 16}}>
                    No special symbols - 
                    <span className="help_center_item" style={{ marginTop: 6, fontWeight: 'normal'}}>
                        Make sure your spreadsheet doesn't have any symbols. 
                    </span>
                </div>
                <div className="help_center_semi_bold" style={{marginTop: 16}}>
                    Remove formulas -
                    <span className="help_center_item" style={{ marginTop: 6 ,fontWeight: 'normal'}}>
                        We recommend using a fresh file without any formulas, in the import process. If you have a file that uses formulas or data-linking to generate the contents of a cell, we suggest copying the data in your spreadsheet and pasting it into a fresh sheet without the formulas.
                    </span>
                </div>
                <div className="help_center_semi_bold" style={{ marginTop: 16}}>
                    One tab per spreadsheet -
                    <span className="help_center_item" style={{ marginTop: 6,fontWeight: 'normal' }}>
                        Make sure your spreadsheet only has one tab containing data when importing. If your spreadsheet has more than one tab, you will need to copy and paste them to individual files and import them one file at a time.
                    </span>
                </div>
                <div className="help_center_semi_bold" style={{marginTop: 16}}>
                    Spreadsheet size limit - 
                    <span className="help_center_item" style={{ marginTop: 6,fontWeight: 'normal' }}>
                        While there is no maximum limit on the number of columns in the spreadsheet, the maximum file size is limited to 25mb.
                    </span>
                </div>
                </div>
            </div>

            <div className="help_center_bold" style={{marginTop: 16}} id="initiate_import">
                Initiating your Import
            </div>
            <div className="help_center_text">
                Once you are ready with the spreadsheet, you can start your import.
            </div>
            <img className="help_center_img" src={AddingProspectStep1} style={{maxWidth: "720px", margin: "auto"}}></img>
            <img className="help_center_img" src={AddingProspectStep2} style={{maxWidth: "720px", margin: "auto"}}></img>
            <img className="help_center_img" src={AddingProspectStep3} style={{maxWidth: "720px", margin: "auto"}}></img>


            <div className="help_center_notes" style={{margin: "16px 0px"}} id="updating_prospect">
                <div className="help_center_item" style={{ padding: "11px 14px"}}> For adding or <span style={{fontWeight: 700}}>updating </span>additional details of any prospect that has already been imported, you need to have the prospect name and company name as well along with those additional fields. The prospect name and company should match with what was imported before.</div>
            </div>
        </div>
    );
};
