import { createContext } from "react";

const AggregatesListContext = createContext();

export const AggregatesListProvider = ({ children, currfilterState, setCurrFilterState, filterTab, isLoading  }) => {
    return (
        <AggregatesListContext.Provider value={{ currfilterState, setCurrFilterState, filterTab, isLoading  }}>
            {children}
        </AggregatesListContext.Provider>
    );
};
export default AggregatesListContext;
