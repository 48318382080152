import { useCallback, useState } from 'react';
import {debounce} from 'lodash'
import { apiAgent } from '../../axiosConfig';
import ConditionalRender from '../../ConditionalRender';
import { NameInitial } from "../cta_utils/AnNameInitial.js";
import Cancel from "../../assets/Cancel.svg";


const MINCHAR = 2

const AnMultipleSuggestionInsideBox = (props) => {  

  const [fetchedRes, setFetchedRes] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [isFetching, setIsFetching] = useState(false)
  const [activeOption, setActiveOption] = useState(0)
  const [showOptions, setShowOptions] = useState(false);

  const debouncedHandleChange = useCallback(
    debounce((ip) => {
      setIsFetching(true);
      apiAgent({
          method: 'get',
          url: props.suggestionUrl,
          params: {
              query: ip
          }
      }).then((res) => {
          setFetchedRes(res.data.data)
          setIsFetching(false)
          setShowOptions(true)
      }).catch(e => {
          setIsFetching(false)
          setShowOptions(true)
        });
    }, 300), [props.suggestionUrl])

  const handleOnClick = (e) => {
    let temp = [...props.selectedOptions, fetchedRes[activeOption]];
    temp = temp.filter((item, index, array) => 
    array.findIndex(obj => obj.id === item.id) === index
    );
    props.callback(temp)
    setFetchedRes([])
    setActiveOption(0)
    setShowOptions(false)
    setUserInput("")
  }
  const handleOnChange = (e) => {
    let userInput = e.target.value
    setShowOptions(false)
    setUserInput(userInput)
    if(userInput.length >= MINCHAR){
      debouncedHandleChange(userInput)
    }

  }

  const handleDeselect = (index) => {
    let newOptions = [...props.selectedOptions]
    newOptions.splice(index, 1);
    props.callback(newOptions)
  }

  let computed_classes = props.inputClass || 'an_input_box';
  computed_classes = props.error ? (computed_classes + ' an_input_error') : computed_classes
  

  const renderOptionList = () => {
    if(showOptions && fetchedRes.length > 0){
      return(
        <ul className="suggestions_container" style={{listStyle: "none", width: 400}}>
          {
            fetchedRes.map((optionName, index) => {
                let className = 'suggested_item text_body_light';
                if (index === activeOption) {
                    className += ' active_option text_body_light';
                }
                return (
                <li className={className} key={optionName.id} onClick={handleOnClick} onMouseEnter={() => {setActiveOption(index)}}>
                    {optionName.name || optionName.company}
                </li>
                );
            })
          }
        </ul>
      )
    }
    else if(showOptions){
      return(
        <ul className="suggestions_container" style={{listStyle: "none"}}>
          <li className="suggested_item" key="NoResults">No Results</li>
        </ul>
      )
    }
  }

  const selectedOptionPills = props.selectedOptions.map((opt, index) => (
    <div key={opt.name + index} className='keyword_pill' style={{border: "none", background: "rgba(112, 114, 116, 0.15)", margin: "4px 2px", borderRadius: 4}}>
      <div className='text_caption' style={{fontSize: 14}}>{opt.name}</div>
      <img
        src={Cancel}
        alt='X'
        style={{ width: '16px', height: '16px', cursor: 'pointer', marginLeft: 4 }}
        onClick={(e) => handleDeselect(index)}
      ></img>
    </div>
  ));

  return(
    <div className='user_search_space_root' style={{ margin: '16px 0px' }}>
      <div className='all_keywords_container'>
        <div className='card_item'>
          <div
            className='keywords_card_container'
            style={{
              padding: '0px 8px',
              border: '1px solid #b6b9bb',
              height: "auto",
              width: 432,
            }}
          >
            <div className='keyword_card'>
              <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                {selectedOptionPills}
                <div className='invisible_pill' style={{padding: "10px 0px", marginBottom: 0}}>
                  <input
                    autoFocus={true}
                    value={userInput}
                    name={props.name}
                    className='inline_inputter'
                    onChange={handleOnChange}
                    style={{ flex: 1 }}
                  />
                </div>
              </div>
            </div>
            {renderOptionList()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AnMultipleSuggestionInsideBox;