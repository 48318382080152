export const ProspectListFiltersTabs = [
    {
        label: "Tags",
        tab_key: "tags",
        search_bar: {
            placeholder: "Search Tags",            
        },
        filter_field_mapping: "tags_filter",
        empty_text: "No tags present"
    },
    {
        label: "Assigned Team member",
        tab_key: "assigns",
        search_bar: {
            placeholder: "Search assigned team members"
        },
        filter_field_mapping: "assigned_filter",
        empty_text: "No assigned team members"
    },
    {
        label: "Company",
        tab_key: "company",
        search_bar: {
            placeholder: "Search companies"
        },
        filter_field_mapping: "company_filter",
        empty_text: "No companies"
    },
    {
        label: "College",
        tab_key: "college",
        search_bar: {
            placeholder: "Search colleges"
        },
        filter_field_mapping: "college_filter",
        empty_text: "No colleges"
    },
    {
        label: "Location",
        tab_key: "location",
        search_bar: {
            placeholder: "Search locations"
        },
        filter_field_mapping: "location_filter",
        empty_text: "No location"
    },
    {
        label: "Title",
        tab_key: "title",
        search_bar: {
            placeholder: "Search title/position"
        },
        filter_field_mapping: "title_filter",
        empty_text: "No titles"
    },
    {
        label: "Miscellaneous",
        tab_key: "miscellaneous",
        search_bar: {
            placeholder: "Search Miscellaneous",
        },
        filter_field_mapping: "miscellaneous_filter",
        empty_text: "No miscellaneous present"
    }
]

export const NewsFeedHighConfidenceTabs = [
    {
        label: "Sort By",
        tab_key: "sort",
        filter_field_mapping: "sort_filter",
        tab_type: "sort_radio"
    },
    {
        label: "Mentioned for",
        tab_key: "mentioned_for",
        search_bar: {
            placeholder: "Search post mentioned for"
        },
        filter_field_mapping: "mentioned_for_filter",
        empty_text: "No matching mentioned for"
    },
    {
        label: "Tags",
        tab_key: "tags",
        search_bar: {
            placeholder: "Search Tags",            
        },
        filter_field_mapping: "tags_filter",
        empty_text: "No tags present"
    },
    {
        label: "Assigned Team member",
        tab_key: "assigns",
        search_bar: {
            placeholder: "Search assigned team members"
        },
        filter_field_mapping: "assigned_filter",
        empty_text: "No assigned team members"
    },
    {
        label: "Company",
        tab_key: "company",
        search_bar: {
            placeholder: "Search companies"
        },
        filter_field_mapping: "company_filter",
        empty_text: "No companies"
    },
    {
        label: "College",
        tab_key: "college",
        search_bar: {
            placeholder: "Search colleges"
        },
        filter_field_mapping: "college_filter",
        empty_text: "No colleges"
    },
    {
        label: "Location",
        tab_key: "location",
        search_bar: {
            placeholder: "Search locations"
        },
        filter_field_mapping: "location_filter",
        empty_text: "No location"
    },
    {
        label: "Title",
        tab_key: "title",
        search_bar: {
            placeholder: "Search title/position"
        },
        filter_field_mapping: "title_filter",
        empty_text: "No titles"
    },
    {
        label: "Miscellaneous",
        tab_key: "miscellaneous",
        search_bar: {
            placeholder: "Search Miscellaneous",
        },
        filter_field_mapping: "miscellaneous_filter",
        empty_text: "No miscellaneous present"
    },
    {
        label: "Language",
        tab_key: "score_tags",
        filter_field_mapping: "language_filter",
        empty_text: "No Language tags present"
    },
]
export const smartPublishTabs = {
    include: [
        {
            label: "Article Matching",
            tab_key: "score_tags",
            sub_key: 'article_matching',
            filter_field_mapping: "article_matching_filter",
            empty_text: "No Article Matching present"
        },
        {
            label: "Generic",
            tab_key: "score_tags",
            sub_key: 'generic',
            filter_field_mapping: "generic_filter",
            empty_text: "No Generic present"
        },
        {
            label: "Linkedin data",
            tab_key: "score_tags",
            sub_key: 'linkedin_data',
            filter_field_mapping: "linkedin_filter",
            empty_text: "No Linkedin data present"
        },
        {
            label: "Language",
            tab_key: "score_tags",
            sub_key: 'language',
            filter_field_mapping: "language_filter",
            empty_text: "No Miscellaneous present"
        },
        {
            label: "Prospect",
            tab_key: "score_tags",
            sub_key: 'prospect',
            filter_field_mapping: "prospect_filter",
            empty_text: "No Miscellaneous present"
        },
        {
            label: "Miscellaneous",
            tab_key: "score_tags",
            sub_key: 'miscellaneous',
            filter_field_mapping: "miscellaneous_filter",
            empty_text: "No Miscellaneous present"
        },
        {
            label: "Mentioned for",
            tab_key: "mentioned_for",        
            filter_field_mapping: "mentioned_for_filter",
            empty_text: "No Descriptions present"
        },
        {
            label: "Categories",
            tab_key: "categories",        
            filter_field_mapping: "categories_filter",
            empty_text: "No Categoriy present"
        },
        {
            label: "Analysis",
            tab_key: "score_tags",
            sub_key: 'analysis',
            filter_field_mapping: "analysis_filter",
            empty_text: "No Analysis present"
        },
        {
            label: "Media Score",
            tab_key: "score_tags",
            sub_key: 'media_score',
            filter_field_mapping: "media_score",
            empty_text: "No articles present"
        }
    ],
    exclude: [
        {
            label: "Article Matching",
            tab_key: "score_tags",
            sub_key: 'article_matching',
            filter_field_mapping: "article_matching_filter",
            empty_text: "No Article Matching present"
        },
        {
            label: "Prospect",
            tab_key: "score_tags",
            sub_key: 'prospect',
            filter_field_mapping: "prospect_filter",
            empty_text: "No Miscellaneous present"
        },
    ]
}
