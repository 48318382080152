import DataFields1 from "../../../../../assets/help_center/data_fields1.png"
import CustomFields from "../../../../../assets/help_center/custom_fields.png"
import AnScrollToLinkPage from "../../../../cta_utils/AnScrollToLinkPage";

export const DataFields = () => {
    return (
        <div style={{ marginTop: 24 }}>
            <div className="help_center_top_section">
            <div className="text_title_big" style={{marginBottom: 20}}>Data Fields</div>
            <div style={{border: "1px solid grey", padding: "14px 20px", borderRadius: "4px", fontWeight: "700"}}>
                <div className="text_body" style={{color: "#707274", fontWeight: 700}}> SKIP AHEAD TO</div>
                <div style={{marginTop: "12px"}}>
                    <span style={{color: "#437EFE", fontSize: "14px"}}><AnScrollToLinkPage href="#default_fields"> Default Fields </AnScrollToLinkPage></span>
                    <span style={{color: "#437EFE", marginLeft: 20, fontSize: "14px"}}><AnScrollToLinkPage href="#custom_fields"> Custom Fields </AnScrollToLinkPage></span>
                </div>
            </div>
            </div>
            
            <div className="help_center" id="default_fields">
                Default Fields:
            </div>
            <div className="help_center_item" style={{ marginTop: 6 }}>
                When importing data to your team on AlmaConnect News from a spreadsheet, it's important to understand defaults fields. We have Name, Company Name, College Name, Location, Title, Assigned Team Members and Tags as default fields. Although its not mandatory to include all the fields in the import process,<span style={{fontWeight: 700}}> Name and company name are necessary </span> to fetch accurate news results. However, to improve the quality of your results, we recommend you add as many data fields as possible during data import.
            </div>
            <img className="help_center_img" src={DataFields1} style={{maxWidth: "650px", maxHeight: "316px", margin: "20px auto 0px"}}></img>

            <div className="help_center" id="custom_fields">
                Custom Fields:
            </div>
            <div className="help_center_item" style={{ marginTop: 6 }}>
                If you have additional data about your prospects, you can also easily add them to your data through the import. If the field does not exist as a default field, you can add the field as a custom field before importing any data, so that your data has somewhere to be mapped to.
            </div>
            <img className="help_center_img" src={CustomFields} style={{maxWidth: "720px", maxHeight: 470, margin: "20px auto 0px"}}></img>
            <div className="help_center_item" style={{ marginTop: 6 }}>
                For example, a prospect's 'graduation year' is not a default field. To successfully include this information in your import, you will first have to create it as a custom field, before mapping this column to the newly created field.    
            </div>
            

            <div style={{background: "#FAFBFC", marginTop: 12, padding: 16}}>
                <div className="help_center_item">
                    There are five different types of custom fields. The type of custom field you choose will depend on what kind of information you want to add. 
                </div>
                <div>
                <div className="help_center_semi_bold" style={{marginTop: 16}}>
                    Text - 
                    <span className="help_center_item" style={{ marginTop: 6, fontWeight: 'normal', marginLeft: 4}}>
                        One word of input or other text. <br/>
                        Useful for – Graduation year, Date of Birth
                    </span>
                </div>
                <div className="help_center_semi_bold" style={{marginTop: 16}}>
                    Radio button - 
                    <span className="help_center_item" style={{ marginTop: 6 ,fontWeight: 'normal' ,marginLeft: 4}}>
                        Only one of a predefined set of mutually exclusive options<br/>
                        Useful for – category, industry
                    </span>
                </div>
                <div className="help_center_semi_bold" style={{marginTop: 16}}>
                    Select box - 
                    <span className="help_center_item" style={{ marginTop: 6,fontWeight: 'normal',marginLeft: 4 }}>
                        A dropdown selection of options<br/>
                        Useful for – course name, branch name
                    </span>
                </div>
                <div className="help_center_semi_bold" style={{marginTop: 16}}>
                    Check box
                    <span className="help_center_item" style={{ marginTop: 6,fontWeight: 'normal',marginLeft: 4 }}>
                    Multiple selections from a list of predefined options
                    <br/>Useful for: Industry, region 
                    </span>
                </div>
                </div>
            </div>
        </div>
    );
};