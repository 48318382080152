import Cross from "../../assets/Cancel.svg";

const AnPopup = ({
    height, //depricated use customStyle insted
    width = 400,
    minWidth = 280, //depricated
    maxWidth = 500, //depricated
    customStyle={},
    customInnerStyle={height: '100%'},
    ...rest
}) => {
    return (
        <>
            <div className="an_popup_container">
                <div className="dark_overlayer">
                    <div className="closer_overlayer"
                        onClick={rest.handleOnClosePopup}
                    ></div>
                    <div className="content_container"
                        style={{position: "relative", minWidth: minWidth, maxWidth: maxWidth, alignSelf: 'flex-start'}}>
                        <div className="white_box" style={{height: height, ...customStyle }}>
                            <div style={{textAlign: "left", ...customInnerStyle}}>
                                {rest.children}
                            </div>
                            <div className="close_cross_container"
                                onClick={rest.handleOnClosePopup}>
                                <div className="vertical_middle"></div>
                                <img
                                    src={Cross}
                                    alt="X"
                                    style={{
                                        display: "inline-block",
                                        verticalAlign: "middle",
                                    }}
                                />
                        </div>
                        </div>
                    </div>     
                </div>
            </div>
        </>
    );
};

export default AnPopup;
// TODO: ADD PROPTYPES
