import React from 'react'
import NewsFeedIcon from "./../../assets/NewsfeedIcon.svg"
import NewsFeedActiveIcon from "./../../assets/NewsfeedActive.svg"
import MenuIcon from "./../../assets/MenuIcon.svg"
import MenuActive from "./../../assets/MenuActive.svg"
import ProspectsIcon from "./../../assets/ProspectsIcon.svg"
import ProspectsActive from "./../../assets/ProspectsActive.svg"
import ProfileIcon from "./../../assets/Profile.svg";
import Tags from "./../../assets/Tags.svg";
import SettingsIcon from "./../../assets/Settings.svg";
import TeamMembersIcon from "./../../assets/TeamMembers.svg";
import SyncIcon from "./../../assets/SyncIcon.svg";
import LogOutIcon from "./../../assets/LogOut.svg";
import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import ConditionalRender from '../../ConditionalRender'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import Cookies from 'universal-cookie'
import { updateCurrentTeam } from '../../redux/currentTeamSlice'

const FOOTER_TABS = {
    NewsFeed: {
        activeClass: "text_link",
        activeIcon: NewsFeedActiveIcon,
        Icon: NewsFeedIcon
    },
    ProspectList: {
        activeClass: "text_link",
        activeIcon: ProspectsActive,
        Icon: ProspectsIcon
    }
}

const PostAuthMobileFooter = (props) => {
    
    const current_user = useSelector((state) => state.current_user.user);
    const current_team = useSelector((state) => state.current_team.team);
    const _dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState("");
    const _history = useHistory();

    useEffect(() => {
        if (_history.location.pathname == "/feed") setActiveTab("NewsFeed");
        else if (_history.location.pathname == "/prospects") setActiveTab("ProspectList");;
        const unlisten = _history.listen(() => {
            if (_history.location.pathname == "/feed") setActiveTab("NewsFeed");
            else if (_history.location.pathname == "/prospects")
                setActiveTab("ProspectList");
            else setActiveTab("");
        });
        return unlisten;
    }, []);

    const handleSwitchAccount = () => {        
        const cookies = new Cookies();
        cookies.remove("team_id", { path: "/" });
        _dispatch(updateCurrentTeam({}));
        _history.push("/switch_acc");
    };

    const renderHamburgerMenu = () => {
        return(
            <div className="mobile_overlay_container" style={{padding: "24px", top: "0px"}}>                                
                <Link
                    to={`/profile/${current_user.id || current_user.api_key}`}
                    className="login_drop_link"
                >
                    <img
                        src={ProfileIcon}
                        className="login_drop_icon"
                    ></img>
                    <div>Profile</div>
                </Link>
                <Link
                    to="/settings/profile"
                    className="login_drop_link"
                >
                    <img
                        src={SettingsIcon}
                        className="login_drop_icon"
                    ></img>
                    <div>Settings</div>
                </Link>
                <Link
                    to="/manage/tags"
                    className="login_drop_link"
                >
                    <img
                        src={Tags}
                        className="login_drop_icon"
                    ></img>
                    <div>Tags/Labels</div>
                </Link>
                <ConditionalRender if={current_user.is_super_admin || current_team.is_admin}>
                    <Link
                        to="/settings/manage_teams"
                        className="login_drop_link"
                    >
                        <img
                            src={TeamMembersIcon}
                            className="login_drop_icon"                                
                        ></img>
                        <div>Team members</div>
                    </Link>
                </ConditionalRender>
                <ConditionalRender  if={current_user.is_super_admin}>
                    <Link
                        to="/admin/panel"
                        className="login_drop_link"
                    >
                        <img
                            src={TeamMembersIcon}
                            className="login_drop_icon"
                        ></img>
                        <div>Super Admin Panel</div>
                    </Link>
                </ConditionalRender>

                <div style={{height: 1,background: "#E7ECEB",margin: "16px 0px"}}></div>

                <a  className="login_drop_link"
                    onClick={handleSwitchAccount}
                >
                    <img
                        src={SyncIcon}
                        className="login_drop_icon"
                    ></img>
                    <div>Switch Accounts</div>
                </a>
                <a  className="login_drop_link"
                    onClick={props.logout}
                >
                    <img
                        src={LogOutIcon}
                        className="login_drop_icon"
                    ></img>
                    <div>Logout</div>
                </a>                
            </div>
        )
    }
    return (
        <>

            {activeTab == "Menu" && renderHamburgerMenu()}
            <div className="footer_container flex_container_space_between">
                <Link to="/feed" style={{textAlign: "center"}} className="no_decoration">
                    <img src={activeTab == "NewsFeed" ? NewsFeedActiveIcon : NewsFeedIcon}></img>
                    <div className={activeTab == "NewsFeed" ? "text_link" : "text_body"}>News feed</div>
                </Link>
                <Link to="/prospects" style={{textAlign: "center"}} className="no_decoration">
                    <img src={activeTab == "ProspectList" ? ProspectsActive : ProspectsIcon}></img>
                    <div className={activeTab == "ProspectList" ? "text_link" : "text_body"}>Prospects</div>
                </Link>
                <div style={{textAlign: "center"}} onClick={() => setActiveTab(state => (state === "Menu" ? "" : "Menu"))}>
                    <img src={activeTab == "Menu" ? MenuActive : MenuIcon}></img>
                    <div className={activeTab == "Menu" ? "text_link" : "text_body"}>Menu</div>
                </div> 

            </div>
        </>
    )
}

export default PostAuthMobileFooter