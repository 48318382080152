import AddTags from "../../../../../assets/help_center/add_tags.png"
import ImportTags from "../../../../../assets/help_center/import_tags.png"
import AssignTeamMembers from "../../../../../assets/help_center/assign_team_members.png"
import ImportTeamMembers from "../../../../../assets/help_center/import_team_members.png";
import AnScrollToLinkPage from "../../../../cta_utils/AnScrollToLinkPage";
import useWindowSize from "../../../../custom_hooks/useWindowSize"
import { isWidthDown } from "../../../../utils/designUtils";

export const AssigningTagsandTeamMembers = () => {
    const windowDimensions = useWindowSize();
    return (
        <div style={{ marginTop: 24 }}>
        <div className="help_center_top_section">
            <div className="text_title_big" style={{marginBottom: 20}}>Assigning tags and team members</div>
            <div style={{border: "1px solid grey", padding: "14px 20px", borderRadius: "4px", fontWeight: "700"}}>
                <div className="text_body" style={{color: "#707274", fontWeight: 700}}> SKIP AHEAD TO</div>
                <div style={{marginTop: "12px"}}>
                    <span style={{color: "#437EFE", fontSize: "14px"}}> <AnScrollToLinkPage href="#assigning_tags"> Assigning tags </AnScrollToLinkPage></span>
                    <span style={{color: "#437EFE", marginLeft: 20, fontSize: "14px"}}> <AnScrollToLinkPage href="#assign_team_members"> Assigning team members </AnScrollToLinkPage></span>
                </div>
            </div>
        </div>
            <div className="help_center" id="assigning_tags">
                Assigning tags
            </div>

            <div className="help_center_text">
                There are two ways to assign tags to prospects on AlmaConnect News.<br/>
                <span style={{marginTop: 12}}></span>
                i. Assign tags manually to every prospect<br/>
                ii. Assign tags while importing the prospects    <br/>
            </div>
            
            <div style={{ color: '#282A2C', fontSize: '18px', marginTop: "32px", fontWeight: 600, lineHeight: '22px' }}>
                i. Assign tags manually to every prospect
            </div>
            <div className="help_center_text">
                Tags can be assigned to each prospect manually from the newsfeed page, the prospect page or prospect profile page. 
            </div>
            <img className="help_center_img" src={AddTags} style={{maxWidth: "650px", margin: "24px auto"}}></img>

            <div style={{ color: '#282A2C', fontSize: '18px', marginTop: "32px", fontWeight: 600, lineHeight: '22px' }}>
                ii. Assign tags while importing the prospects    
            </div>
            <div className="help_center_text">
                Tags can also be assigned while importing the prospects by creating a ‘tags’ column in the import spreadsheet and mapping this column with the ‘Tags’ field during the import. In this column, multiple tags can be added with comma separated values. 
            </div>
            <img className="help_center_img" src={ImportTags} style={{maxWidth: "650px", margin: "24px auto"}}></img>

            <div className="help_center" id="assign_team_members">
                Assigning team members
            </div>
            <div className="help_center_text">
                i. Assign team members manually to every prospect<br/>
                ii. Assign team members while importing the prospects<br/>
            </div>
            
            <div className="help_center_item" style={{ marginTop: 12, fontWeight: 600 }}>
                i. Assign team members manually to every prospect<br/>
            </div>
            <div className="help_center_text">
                Team members can be assigned to each prospect manually from the newsfeed page, the prospect page or prospect profile page. 
            </div>
            <img className="help_center_img" src={AssignTeamMembers} style={{maxWidth: "650px", maxHeight: "390px", margin: "24px auto"}}></img>

            <div className="help_center_item" style={{ marginTop: 12, fontWeight: 600 }}>
                ii. Assign team members while importing the prospects
            </div>
            <div className="help_center_text">
                Team members can also be assigned while importing the prospects by creating a ‘team members’ column in the import spreadsheet and mapping this column with the ‘Assigned Team Member’ field during the import. In this column, multiple tags can be added with comma separated values.
            </div>
            <img className="help_center_img" src={ImportTeamMembers} style={{maxWidth: "650px", maxHeight: "132px", margin: "24px auto"}}></img>
        </div>
    );
};