import React from 'react'

import {apiAgent} from './axiosConfig';
import Pagination from './Pagination';
import FileUpload from './FileUpload';
import { LoaderComponent } from "./components/utils/LoaderComponent"
import { AnInputBox } from "./components/input_utils/AnInput"
import { AnInputCheckBox } from './components/input_utils/AnInputCheckBox';
import TableHeads from "./components/visualizers/tabular/TableHeads"
import TableRows from "./components/visualizers/tabular/TableRows"
import ConditionalRender from './ConditionalRender';
import AnPopup from "./components/cta_utils/AnPopup"
import getApiUrl from './components/utils/UrlHelper';
import { Button } from './components/cta_utils/AnButton';
import { cssPath } from './components/utils/designUtils';
import AnDropdownFilter from './components/cta_utils/AnDropdownfilter';
import Download from './assets/download.png'
import { FlashMessages } from './components/cta_utils/FlashMessages';
import SelectedOptions from './components/utils/SelectedOptions';
import AnDropDown from './components/cta_utils/AnDropDown';
import SyncIcon from "./assets/SyncIcon.svg"

const TAGS = [{label: "verified", key: "verified"}, {label: "competitor", key: "competitor"}, {label: "google alerts", key: "google alerts"}, {label: "local sources", key: "local sources"}];
const tag_filters = [{label: "All", key: "all"}, {label: "verified", key: "verified"}, {label: "competitor", key: "competitor"}, {label: "google alerts", key: "google alerts"}, {label: "local sources", key: "local sources"}, {label: "No Tags", key: "no_tags"}];
class RssCandidates extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      error: null,
      errors: {},
      isLoaded: false,
      items: [],
      popupsList: {},
      rssSources: [],
      rssCandidate: {},
      selectedTags: [],
      isLoading: {},
      mediaScorePresent: true,
      externalWebRef: "",
      tag_filter: "all",
      export_filter: {min_rank: null, max_rank: null},  
      successMessages: {
        message: "",
        duration: 0,
        from: ""
      }
    };
    this.externalWebRef = React.createRef();
  }

  fetchExternalWebsite = async () => {
    let url = this.state.currentCandidate.sharable_article_url;
    if(!url) return;
    this.setState(state => ({isLoading: {fetchingExternalArticle: true}}));
    apiAgent({
      method: "get",
      url: getApiUrl("get_rss_candidate_external_preview"),
      params: {
        sharable_article_url: this.state.currentCandidate.sharable_article_url
      }
    }).then(res => {
      this.setState({externalWebRef: res.data.html_page, isLoading: {}})
      this.setDelayedContent()
    }).catch(err => {
      console.log(err)
      this.setState({externalWebRef: null, isLoading: {}})
    })  
  }
  
  componentDidMount() {
    this.fetchRssCandidates(1);
  }

  setDelayedContent = () => {
    if(!this.externalWebRef.current) return;
    setTimeout(() => {
      if(this.state.currentCandidate.active_tab === "iframe")
        this.externalWebRef.current.contentWindow.document.addEventListener("mouseover", this.paintElemBox)
    }, 1000)
  }

  paintElemBox = (event) => {
    let elem = this.externalWebRef.current.contentWindow.document.elementFromPoint(event.clientX, event.clientY)
    if(elem){
      let targetSelector = cssPath(elem);
      let targetNodes = this.externalWebRef.current.contentWindow.document.querySelectorAll(targetSelector);
      const clickListener = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if(this.state.currentCandidate.currentSelector?.name){
          let candidate = this.state.currentCandidate;
          candidate[candidate.currentSelector.name][candidate.currentSelector.index] = cssPath(e.target);
          candidate.currentSelector = {};
          this.setState({currentCandidate: candidate});
        }
      }
      const mouseOutListener = (e) => {
        for(let i=0; i<targetNodes.length; i++){
          let node = targetNodes[i];
          node.style.border = elem.getAttribute("prev_border");
          node.removeAttribute("prev_border");
          node.removeEventListener("mouseout", mouseOutListener);
          node.removeEventListener("click", clickListener);
        }
      }

      for(let i=0; i<targetNodes.length; i++){
        let node = targetNodes[i];
        node.setAttribute("prev_border", elem.style.border);
        node.style.border = "2px dashed #fc9803";
        node.addEventListener("click", clickListener);
        node.addEventListener("mouseout", mouseOutListener);
      }
    }
  }

  fetchRssCandidates = (page) => {
    this.setState({
      isLoaded: false,
      isLoading: {rssCandidates: true}   
    });
    apiAgent({
      method: 'get',
      url: '/rss_candidates',
      params: {
        q: this.state.searchText,
        page: page,
        mediaScorePresent: this.state.mediaScorePresent,
        tag_filter: this.state.tag_filter
      }
    }).then(
      (response) => {
        let result = response.data;
        this.setState({
          isLoaded: true,
          isLoading: {},
          items: result.data,
          totalPages: result.total_pages,
          currentPage: page
        });
      },
      (error)  => {
        this.setState({
          isLoaded: true,
          isLoading: {},
          error
        });
      }
    );
  }

  saveSelectors = () => {
    if(!this.state.currentCandidate.sharable_article_url) return;
    let article_selector = (this.state.currentCandidate.new_selectors || []).filter(sel => sel).join(",")
    let negative_article_selector = (this.state.currentCandidate.excluded_selectors || []).filter(sel => sel).join(",")
    this.setState(state => ({isLoading: {...state.isLoading, addingSelectors: true}}))
    apiAgent({
      method: "post",
      url: getApiUrl("post_update_selectors"),
      data: {rss_candidate_id: this.state.currentCandidate.id, 
        sharable_article_url: this.state.currentCandidate.sharable_article_url,
        article_selector, 
        negative_article_selector,
      }
    }).then(res => {
      this.setState(state => ({popupsList: {}, isLoading: {...state.isLoading, addingSelectors: false}}))
    }).catch(err => {
      this.setState(state => ({isLoading: {...state.isLoading, addingSelectors: false}}))
    })
  }
  handleSharableUrlChange = (e) => {
    let candidate = this.state.currentCandidate;
    candidate.sharable_article_url = e.target.value;
    this.setState({currentCandidate: candidate});
  }

  handleSelectorsChange = (event, index) => {
    let temp = this.state.currentCandidate[event.target.name];
    if(!event.target.value){
      temp.splice(index, 1)      
    }else{
      temp[index] = event.target.value;
    }
    let candidate = this.state.currentCandidate;
    candidate.currentSelector = {};
    candidate[event.target.name] = temp;
    this.setState({currentCandidate: candidate});
  }

  addSelectors = (event) => {
    let candidate = this.state.currentCandidate;
    candidate[event.target.getAttribute("name")].push("");
    this.setState({currentCandidate: candidate})
  }

  onTextInput = (event) => {
    if(event.charCode !== 13) return;
    this.setState({
      searchText: event.target.value
    }, () => this.fetchRssCandidates(1))
  }

  recheck = (rss_candidate) => {
    apiAgent({
      method: 'post',
      url: `/rss_candidates/${rss_candidate.id}/recheck`
    });
  }

  _renderPossiblePaths = () => {
    let possible_paths = [
      "", "feed", "rss", "rss.xml", "search", "section/feed",
      "feeds/posts/default", "index.rss", "api/rss/content.rss", "comments/feed",
      "rss.php", "index.php", "newsRSS", "todaysnews/rss.xml", "index.php/feed",
      "latest/rss", "rss/index.xml", "wordpress/feed", "rss/headlines",
      "components/rssfeed.php", "tags/index.html", "rssfeeds"
    ];
    return possible_paths.map((path) => {
      return (
        <div>
          /{path}
        </div>
      );
    });
  }

  handlePopupClose = () => {
    this.setState({
      popupsList: {}, 
      preview: false, 
      previewContent: null, 
      currentCandidate: {}, 
      externalWebRef: null, 
      rssCandidate: {}, 
      selectedTags: [],
      errors: {}})
  }
  handleTabSwitch = (tab) => {
    if(!this.state.currentCandidate) return;    
    let candidate = this.state.currentCandidate;
    if(candidate.active_tab !== tab){
      if(candidate.active_tab === 'iframe'){
        this.fetchPreviewContent();
      }
      candidate.active_tab = tab;      
      this.setState({currentCandidate: candidate}, this.setDelayedContent);
    }
  }
  handleSelectMe = (e, option) => {
    let candidate = this.state.currentCandidate;
    candidate.currentSelector = {
      name: option.name,
      index: option.index
    }
    this.setState({currentCandidate: candidate});
  }

  fetchCandidateSelectors = (candidate) => {
    candidate.new_selectors = [""];
    candidate.excluded_selectors = [""];
    candidate.active_tab = "iframe";
    candidate.currentSelector = {};
    this.setState(state => ({currentCandidate: candidate, isLoading: {...state.isLoading, candidateSelectors: true}}))
    apiAgent({
      method: "get",
      url: getApiUrl("get_rss_candidate_selectors"),
      params: {host: candidate.host}
    }).then(res => {      
      candidate.sharable_article_url = res.data.sharable_article_url; 
      candidate.new_selectors = res.data.new_selectors;
      candidate.excluded_selectors = res.data.excluded_selectors;     
      this.setState(state => ({currentCandidate: candidate, isLoading: {...state.isLoading, candidateSelectors: false}}), this.fetchExternalWebsite)
    }).catch(err => {
      this.setState(state => ({isLoading: {...state.isLoading, candidateSelectors: false}}))
    })
  }

  ftechRssSources = (page) => {
    let candidate = this.state.currentCandidate;
    this.setState(state => ({isLoading: {...state.isLoading, rssSources: true}}))
    apiAgent({
      method: "get",
      url: getApiUrl("rss_candidate_sources", {":id": candidate.id}),
      params: {page: page}
    }).then(res => {
      this.setState(state => ({
        rssSources: res.data.data, 
        totalRssPages: res.data.total_pages,
        currentRssSourcesPage: page,
        isLoading: {...state.isLoading, rssSources: false}
      }
    ))
    }).catch(err => {
      this.setState(state => ({isLoading: {...state.isLoading, rssSources: false}}))
    })
  }

  fetchPreviewContent = () => {
    this.setState(state => ({previewContent: null, isLoading: {...state.isLoading, previewContent: true}}));
    let article_selector = (this.state.currentCandidate.new_selectors || []).filter(sel => sel).join(",")
    let negative_article_selector = (this.state.currentCandidate.excluded_selectors || []).filter(sel => sel).join(",")
    let params = {
      sharable_article_url: this.state.currentCandidate.sharable_article_url,
      article_selector,
      negative_article_selector 
    }
    apiAgent({
      method: "get",
      url: getApiUrl("get_rss_candidate_preview"),
      params: params
    }).then(res => {
      this.setState(state => ({previewContent: res.data, isLoading: {...state.isLoading, previewContent: false}}))
    }).catch(err => this.setState(state => ({isLoading: {...state.isLoading, previewContent: false}}))    )
  }

  filterExport = () => {
    return (
      <AnDropDown style={{position: 'absolute', right: 64, marginTop:"8px", padding: '24px', background: 'white', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', borderRadius: 4, width: '380px', zIndex: 99}}
          toggleDropDown={() => this.handlePopupClose()}>
             <div className='text_title'>Download RSS Candidates</div>
             <div  className="line_seperator_ex_light_grey" style={{ margin: "12px auto" }}></div> 
             <div className='default_flex_container'>
              <AnInputBox label="Min Rank" style={{maxWidth: 135, marginRight: 20}}>
                <input
                    type="number"
                    id="min_rank"
                    value={this.state.export_filter.min_rank}
                    onChange={(e) =>
                        this.setState(state => ({ export_filter: {...state.export_filter, min_rank: e.target.value} }))
                    }
                    placeholder="1"
                />
              </AnInputBox>
              <AnInputBox label="Max Rank" style={{maxWidth: 135}}>
                <input
                    type="number"
                    id="max_rank"
                    value={this.state.export_filter.max_rank}
                    onChange={(e) =>
                        this.setState(state => ({ export_filter: {...state.export_filter, max_rank: e.target.value }}))
                    }
                    placeholder="1000"
                />
              </AnInputBox>
            </div> 
            <ConditionalRender if={this.state.errors["diff_in_rank"]}>
                <div className="text_caption_error" style={{marginBottom: 16}}>{this.state.errors["diff_in_rank"]}</div>
            </ConditionalRender>
            <Button buttonSize="Medium" buttonStyle={{width: 'max-content'}} innerButtonStye={{padding: '8px 20px'}}
                    onClick={() => {this.exportRssCandidates()}}>Download</Button>
      </AnDropDown>
    )
  }

  confirmPopup = () => {
    return(
        <AnPopup name="confirmPopup" handleOnClosePopup={() => this.handlePopupClose() } maxWidth={480}>
          <div className="text_title" style={{marginBottom: 16, textAlign: 'center'}}>Do you want to export rss candidates</div>
          
          <div style={{display: 'flex', marginTop: 16, alignContent: 'center', justifyContent: 'center'}}>
            <Button buttonSize="Medium" buttonStyle={{width: 'max-content'}} innerButtonStye={{padding: '8px 20px'}}
                    onClick={() => {this.exportRssCandidates()}}>Export</Button>
            <Button type="Secondary" buttonSize="Medium" buttonStyle={{width: 'max-content', marginLeft: 16}} innerButtonStye={{padding: '8px 20px'}}
                    onClick={() => this.handlePopupClose()}>Cancel</Button>
          </div>
        </AnPopup>)
  }

  exportRssCandidates = () => {
    let errors = {}, error = false;
    let min = this.state.export_filter.min_rank, max = this.state.export_filter.max_rank;
    if((max - min) > 100) {
      errors["diff_in_rank"] = "difference should not be more than 100 ";
      error = true;
    }
    if(error) {            
        this.setState({...this.state, errors: errors})   
        return;
    }else{
      this.setState({...this.state, errors: errors})   
    }
    let params = {
      tag_filter: this.state.tag_filter,
      mediaScorePresent: this.state.mediaScorePresent,
      from_ms: min,
      to_ms: max 
    }
    apiAgent({
      method: "post",
      url: getApiUrl("export_rss_candidates"),
      params: params
    }).then(res => {
      this.handlePopupClose();
      this.setState({
        successMessages: {
          message: "You will receive an email when the export is ready to download",
          duration: 3000,
          from: "Data Exports"
        }
      })
    }).catch(
      (error) => {
        
      }
    )
  }

  renderSourceTable = () => {
    if(this.state.isLoading.rssSources) return <LoaderComponent isLoading={true}></LoaderComponent>
    return(
      <div>
        <Pagination totalPages={this.state.totalRssPages}
          currentPage={this.state.currentRssSourcesPage}
          onPageChange={this.ftechRssSources}
          style={{padding: "16px 0px 0px"}}
        />
        <div style={{overflow: "scroll"}}>
          <table className="data_table" style={{textAlign: "left", marginTop: "24px"}}>
            <TableHeads typoGraphyClass="text_body_big_light" headerClass="table_header_row_seperated">          
                <th style={{width: "30%"}}>URL</th>
                <th style={{width: "10%"}}>RSS type</th>
                <th style={{width: "10%"}}>RSS Fetch No</th>
                <th style={{width: "10%"}}>Article Fetch No</th>
                <th style={{width: "10%"}}>Last Article Created At</th>
                <th style={{width: "10%"}}>Unscheduled at</th>
                <th style={{width: "10%"}}>Unscheduled Reason</th>
                <th style={{width: "5%"}}>Source</th>
            </TableHeads>
            <tbody>
              {this.state.rssSources.map(item => (
                <TableRows key={item.id} rowClass="table_content_row_seperated text_body">
                  <td>
                    {item.url}
                  </td>
                  <td>{item.feed_type}</td>
                  <td>
                    {item.fetch_rss_count}
                  </td>
                  <td>
                    <span>{item.fetch_article_count}</span>
                  </td>
                  <td>
                    <span>{item.last_fetched_article_at}</span>
                  </td>
                  <td>
                    {item.unscheduled_at}
                  </td>
                  <td>
                    {item.unscheduled_reason}
                  </td>
                  <td>
                    {item.source}
                  </td>
                </TableRows>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  renderSourcePopup = () => {    
    return(
      <AnPopup handleOnClosePopup={this.handlePopupClose} maxWidth={"90vw"}>
        <div className='text_title'>RSS sources</div>
        <div className='default_flex_container' style={{marginTop: "6px"}}>
          <div className='text_subtitle_light'>{this.state.currentCandidate?.host}</div>          
          <img src={SyncIcon} style={{marginLeft: "6px", width: "24px", height: "24px"}} alt="Re-fetch" onClick={() => this.ftechRssSources(this.state.currentRssSourcesPage)}/>
        </div>
        {this.renderSourceTable()}
      </AnPopup>
    )
  }

  rss_candidate_set_params = (type, value) => {
    let rssCandidateData = this.state.rssCandidate;
    rssCandidateData[type]=value;

    this.setState({ rssCandidate: rssCandidateData });
  };
  

  handleTagsChange = (val) => {
    let temp = [...this.state.selectedTags, val];

    this.setState({selectedTags: temp });
  }
  

  handleSubmission = () => {
    let errors = {}, error = false;
    let data = {...this.state.rssCandidate}
    if(!data.host) {
      errors["host"] = "Host can't be blank";
      error = true;
    }
    if(error) {            
        this.setState({...this.state, errors: errors})   
        return;
    }else{
      this.setState({...this.state, errors: errors})   
    }
    this.setState(state => ({isLoading: {...state.isLoading, addRssCandidate: true}}));
    let params= {...this.state.rssCandidate, tags: [...this.state.selectedTags]}
    apiAgent({
      method: "post",
      url: getApiUrl("add_rss_candidate"),
      params: params
    }).then(res => {
      this.setState(state => ({popupsList: {}, rssCandidate: {}, selectedTags: [], isLoading: {...state.isLoading, addRssCandidate: false}}))
      this.setState({
        successMessages: {
          message: "Saved",
          duration: 3000,
          from: "Data Exports"
        }
      })
      this.fetchRssCandidates(1);
    }).catch(err => {
      this.setState(state => ({isLoading: {...state.isLoading, addRssCandidate: false}}))
    })
  }

  fillCandidateValues = (item) => {
    let temp={...item};
    Object.keys(temp).map(key => {
      if(key!='tags' && key && temp[key])
      {
        this.rss_candidate_set_params(key, temp[key]);
      }
    })
    this.setState({selectedTags: [...temp.tags]})
  }

  sanitizeSources = () => {
    this.setState({isLoading: {sanitizingSource: true}})
    apiAgent({
      url: `/rss_candidates/${this.state.currentCandidate.id}/sanitize_sources`,
      method: "post",
    }).then(res => {
      this.setState({isLoading: {}, popupsList: {}})
    }).catch(err => {
      this.setState({isLoading: {}})
    })
  }
  rendersanitizeSourcePopup = () => {
    return(
      <AnPopup handleOnClosePopup={this.handlePopupClose} maxWidth={"480px"}>
        <div style={{margin: "auto", textAlign: "center"}}>
          <div className='text_body_light'>This will sanitize <span className='text_body'>{this.state.currentCandidate.host}</span> candidate and will also auto create additional sources. Are you sure?</div>       
          <Button onClick={this.sanitizeSources} isLoading={this.state.isLoading.sanitizingSource} buttonStyle={{width: "max-content", margin: "auto", marginTop: "16px"}}>Yes</Button>
        </div>
      </AnPopup>
    )
  }

  addRssCandidatePopup = () => {
    return (
      <AnPopup handleOnClosePopup={this.handlePopupClose} maxWidth={452}>
        <AnInputBox label="Name">
            <input
                type="text"
                id="name"
                value={this.state.rssCandidate.name}
                onChange={(e) =>
                    this.rss_candidate_set_params("name", e.target.value)
                }
                placeholder="Add Name"
            />
        </AnInputBox>
        <AnInputBox label="Host*" error={this.state.errors["host"]}>
            <input
                type="text"
                id="host"
                value={this.state.rssCandidate.host}
                onChange={(e) =>
                    this.rss_candidate_set_params("host", e.target.value)
                }
                placeholder="Add Host"
            />
        </AnInputBox>
        <AnInputBox label="Tags" style={{maxWidth: "140px"}}>
          <select placeholder="Select Tag" value="no_value" onChange={(e) => this.handleTagsChange(e.target.value)}>
              <option key='no_value' value={""}>Select Tag</option>
              {
                  TAGS.map((tag, index) => (
                      <option key={index} value={tag.key} style={{display: this.state.selectedTags?.includes(tag.key) ? 'none': ''}}>
                          {tag.label}
                      </option>
                  ))
              }   
          </select>
        </AnInputBox>
        <ConditionalRender if={this.state.selectedTags}>
          <SelectedOptions
            selectedOptions={this.state.selectedTags}
            callback={(val) => this.setState({selectedTags: val})}
          >
          </SelectedOptions>
        </ConditionalRender>
        <AnInputBox label="Country Code">
            <input
                type="text"
                id="country_codes"
                value={this.state.rssCandidate.country_codes}
                onChange={(e) =>
                    this.rss_candidate_set_params("country_codes", e.target.value)
                }
                placeholder="Add code"
            />
        </AnInputBox>
        <AnInputBox label="Media score">
            <input
                type="text"
                id="media_score"
                value={this.state.rssCandidate.media_score}
                onChange={(e) =>
                    this.rss_candidate_set_params("media_score", e.target.value)
                }
                placeholder="Add score"
            />
        </AnInputBox>
        <Button 
            buttonStyle={{marginTop: '16px', width: "max-content"}}
            onClick={this.handleSubmission}
            isLoading={this.state.isLoading.addRssCandidate}
            innerButtonStye={{
                padding: "8px 25px",          
              }}
            >
              Save     
        </Button>
      </AnPopup>
    )
  }
  
  renderSelectorPopup = () => {
    return(
      <AnPopup handleOnClosePopup={this.handlePopupClose} maxWidth={"98vw"}>
        <div className='default_flex_container' style={{gap: 24, alignItems: "flex-start"}}>
          <div style={{width: "100%"}}>
            <div className='default_flex_container show-tabs' style={{padding: '0px 24px', borderBottom: '1px solid #E5E5E5'}}>
                <div className={this.state.currentCandidate.active_tab ==='iframe' ?'active-tab':''} 
                  style={{padding: '16px', borderBottom: '4px solid #fff'}}
                  onClick={(e) => this.handleTabSwitch('iframe')}
                >
                    Link
                </div>
                <div className={this.state.currentCandidate.active_tab === 'preview' ?'active-tab':''} 
                  style={{padding: '16px', borderBottom: '4px solid #fff'}}
                  onClick={(e) => this.handleTabSwitch('preview')}
                >
                    Parsed Data
                </div>
            </div>
            <ConditionalRender if={this.state.isLoading.fetchingExternalArticle || this.state.isLoading.previewContent}>
              <LoaderComponent isLoading={true}></LoaderComponent>
            </ConditionalRender>
            <ConditionalRender if={this.state.currentCandidate && !(this.state.isLoading.fetchingExternalArticle || this.state.isLoading.previewContent)}>
              <ConditionalRender if={this.state.currentCandidate.active_tab === "iframe"}>
                <iframe id="external-web-content" ref={this.externalWebRef} 
                  srcDoc={this.state.externalWebRef}
                  width="100%"
                  style={{margin: "24px auto", display: "block", height: "80vh", overflow: "scroll"}}
                ></iframe>
              </ConditionalRender>
              <ConditionalRender if={this.state.currentCandidate.active_tab === 'preview' }>
                <div className='text_body_light'>
                  {Object.keys(this.state.previewContent || {}).map(k => {
                    return(
                      <div key={k} style={{marginTop: 24}}>
                        <div className='text_subtitle_big'>{k}</div>
                        <div style={{marginTop: 8}}>{this.state.previewContent[k]}</div>
                      </div>
                    )
                  })}
                </div>
              </ConditionalRender>
            </ConditionalRender>
          </div>
          <div style={{maxWidth: 300, width: "100%"}}>
            <div className='text_title'>Host</div>
            <div className='text_subtitle_light'>{this.state.currentCandidate?.host}</div>
            <div  className="line_seperator_ex_light_grey" style={{ margin: "12px auto 24px" }}></div>        
            <ConditionalRender if={this.state.isLoading.candidateSelectors}>
              <LoaderComponent isLoading={true}></LoaderComponent>
            </ConditionalRender>
            <ConditionalRender if={!this.state.isLoading.candidateSelectors}>
              <AnInputBox>
                <input value={this.state.currentCandidate.sharable_article_url} 
                  onChange={this.handleSharableUrlChange}
                  placeholder="Add Url" />
              </AnInputBox>
              <Button onClick={this.fetchExternalWebsite}
                  innerButtonStye={{padding: "8px 22px"}}
                  isLoading={this.state.isLoading.fetchingExternalArticle}
                >Fetch me</Button>
              <div className="text_body" style={{marginTop: 8}}>Follow this order</div>
              <div className="text_body">
                <ul>
                  <li>Title Elements</li>
                  <li>Published at</li>
                  <li>Article Content</li>
                  <li>Image Url</li>
                </ul>
              </div>
              <div className='text_subtitle_big' style={{marginTop: 20}}>
                Add Selector
              </div>
              {
                this.state.currentCandidate.new_selectors.map((sel, ind) => {
                  let is_active = this.state.currentCandidate.currentSelector.name === "new_selectors" && this.state.currentCandidate.currentSelector.index === ind
                  return(
                    <div key={ind} className={`default_flex_container + ${is_active ? 'box_highlighter' : ''}`} style={{gap: 16, alignItems: "center"}}>
                      <AnInputBox style={{width: "100%"}}>
                        <input value={this.state.currentCandidate.new_selectors[ind]}
                          onChange={(e) => {this.handleSelectorsChange(e, ind)}}
                          name="new_selectors"
                          placeholder="Add selector to include" />
                      </AnInputBox>
                      <Button onClick={(e) => {this.handleSelectMe(e, {name: "new_selectors", index: ind})}}
                        innerButtonStye={{padding: "8px"}}
                      >me</Button>
                    </div>
                  )
                })
              }
              <div className="text_link_small" style={{cursor: 'pointer'}} onClick={this.addSelectors} name="new_selectors">+ Add</div>
              <div className='text_subtitle_big' style={{marginTop: 20}}>
                Exclude Selector
              </div>
              {
                this.state.currentCandidate.excluded_selectors.map((sel, ind) => {
                  let is_active = this.state.currentCandidate.currentSelector.name === "excluded_selectors" && this.state.currentCandidate.currentSelector.index === ind
                  return(
                    <div key={ind} className={`default_flex_container + ${is_active ? 'box_highlighter' : ''}`} style={{gap: 16, alignItems: "center"}}>
                      <AnInputBox style={{width: "100%"}}>
                        <input value={this.state.currentCandidate.excluded_selectors[ind]}
                          onChange={(e) => {this.handleSelectorsChange(e, ind)}}
                          name="excluded_selectors"
                          placeholder="Add selector to exclude" />
                      </AnInputBox>
                      <Button onClick={(e) => {this.handleSelectMe(e, {name: "excluded_selectors", index: ind})}}
                        innerButtonStye={{padding: "8px"}}
                      >me</Button>
                    </div>
                  )
                })
              }
              <div className="text_link_small" style={{cursor: 'pointer'}} onClick={this.addSelectors} name="excluded_selectors">+ Add</div>
              <div className='default_flex_container' style={{alignItems: "center", gap: 16, marginTop: "20px"}}>
                <Button onClick={this.saveSelectors}
                  innerButtonStye={{padding: "8px 22px"}}
                  isLoading={this.state.isLoading.addingSelectors}
                >
                  Save
                </Button>                
              </div>
            </ConditionalRender>
          </div>
        </div>
      </AnPopup>
    )
  }

  renderRssTableContent = () => {
    return(
      <>
      <div className='default_flex_container'>
        <div style={{flex: 100}}>
        <Pagination totalPages={this.state.totalPages}
              currentPage={this.state.currentPage}
              onPageChange={this.fetchRssCandidates}
            />
        </div>
        <div className='text_link hover_under_line'
          onClick={() => {this.setState({popupsList: {addRssCandidatePopup: true}})}} 
          style={{marginRight: 12}}
        >
          Add RSS candidate
        </div>
        <AnDropdownFilter
            option = {this.state.tag_filter}
            setOption = {(plan) => this.setState({tag_filter: plan}, () => this.fetchRssCandidates(1))}
            options = {tag_filters}
            style={{ position: 'relative', lineHeight: 0, marginRight: 6}}
          >
        </AnDropdownFilter>
        <div style={{position: 'relative'}}>
          <div
              onClick={() => {this.setState({popupsList: {filterExportRssCandidatesPopup: true}})}} 
              style={{ alignItems: 'center',cursor: "pointer", width: "35px", border: '1px solid #e6e6e6', padding: '6px 6px', background: "white", borderRadius: 3, lineHeight: 0}}>
              <img src={Download} style={{maxWidth: 22, maxHeight: 22}}></img>
          </div>
        </div>
        <div style={{clear: "both"}}></div>
      </div>
      
      {this.state.popupsList.filterExportRssCandidatesPopup && this.filterExport()}

        <table className="data_table" style={{textAlign: "left", marginTop: "24px"}}>
          <TableHeads typoGraphyClass="text_body_big_light" headerClass="table_header_row_seperated">
              <th style={{width: "10%"}}>Created At</th>
              <th style={{width: "10%"}}>Name</th>
              <th style={{width: "20%"}}>Host</th>
              <th style={{width: "10%"}}>Tags</th>
              <th style={{width: "10%"}}>Country Codes</th>
              <th style={{width: "5%"}}>Media Score</th>
              <th style={{width: "10%"}}>Checked At</th>
              <th style={{width: "25%"}}>Actions</th>
          </TableHeads>
          <tbody>
            {this.state.items.map(item => (
              <TableRows key={item.id} rowClass="table_content_row_seperated text_body">
                <td>
                  {item.created_at}
                </td>
                <td>
                  {item.name}
                </td>
                <td>
                  {item.host}
                </td>
                <td>
                  {item.tags.map(tag => tag).join(", ")}
                </td>
                <td>
                  {item.country_codes}
                </td>
                <td>
                  {item.media_score}
                </td>
                <td>
                  {item.fetched_at}
                </td>
                <td style={{whiteSpace: 'nowrap'}}>
                <div name="Edit" onClick={() => {this.fillCandidateValues(item); this.setState({popupsList: {addRssCandidatePopup: true}})}}  className="text_link hover_under_line">
                    Edit 
                </div>
                <div onClick={() => {this.setState({currentCandidate: item, popupsList: {sourcePopup: true}}, () => this.ftechRssSources(1));}} className="text_link hover_under_line">
                    View sources
                  </div>
                  <div onClick={() => {this.fetchCandidateSelectors(item); this.setState({popupsList: {selectorsPopup: true}})}}  className="text_link hover_under_line">
                    Enable selectors
                  </div>
                  <div onClick={() => this.recheck(item)}  className="text_link hover_under_line">
                    Re-check
                  </div>
                  <div onClick={() => this.setState({currentCandidate: item, popupsList: {sanitizeSourcePopup: true}})}  className="text_link hover_under_line">
                    Sanitize
                  </div>
                </td>
                </TableRows>
            ))}
          </tbody>
        </table>
      </>
    )
  }

  render(){
    const { error, isLoaded } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } 
    else {
      return (
        <div style={{margin: "33px 49px"}}>
          <div className='flex_container_space_between'>
            <div className='text_title_big'>RSS Candidates</div>
          </div>
          <div  className="line_seperator_ex_light_grey" style={{ margin: "8px auto 24px" }}></div>          
          <div className='text_title' style={{marginBottom: "4px"}}>Import RSS candidates</div>
          <div className='text_body_light'>
            Supported headers are: name, host, tags, country_codes, media_score
          </div>

          <FileUpload 
            submitUrl="/rss_candidates/import_via_csv"            
            style={{marginTop: "12px", maxWidth: "600px"}}/>
          
          <div  className="line_seperator_ex_light_grey"  style={{ margin: "24px auto" }}></div>
          {/* <div>
            {this._renderPossiblePaths()}
          </div> */}
          <div className='flex_container_space_between'>
            <AnInputBox style={{maxWidth: "364px"}}>
              <input type="text" id="search" onKeyPress={this.onTextInput} placeholder="Search host"/>
            </AnInputBox>
            <AnInputCheckBox label="Media Score Present" isChecked={this.state.mediaScorePresent}>
              <input type="checkbox" checked={this.state.mediaScorePresent} onChange={() => this.setState(s => ({mediaScorePresent: !s.mediaScorePresent}), () => this.fetchRssCandidates(1))}></input>
            </AnInputCheckBox>
          </div>

          <ConditionalRender if={!this.state.isLoading.rssCandidates}>
            {this.renderRssTableContent()}
          </ConditionalRender>
          <ConditionalRender if={this.state.isLoading.rssCandidates}>
            <LoaderComponent isLoading={true}></LoaderComponent>
          </ConditionalRender>
          {this.state.popupsList.sourcePopup && this.renderSourcePopup()}      
          {this.state.popupsList.selectorsPopup && this.renderSelectorPopup()}
          {this.state.popupsList.exportRssCandidatesPopup && this.confirmPopup()}
          {this.state.popupsList.addRssCandidatePopup && this.addRssCandidatePopup()}
          {this.state.popupsList.manualArticleSyncPopup && this.manualArticleSyncPopup()}
          {this.state.popupsList.sanitizeSourcePopup && this.rendersanitizeSourcePopup()}
          
          {
            this.state.successMessages.message && (
            <FlashMessages 
              messageTheme='success' 
              duration={this.state.successMessages.duration} 
              closeCallback={() =>
              this.setState({ successMessages: {
                message: "",
                duration: 0,
                from: ""} 
              })}
            >
              {this.state.successMessages.message}
            </FlashMessages>)
          }
        </div>
      );
    }
  }
}

export default RssCandidates;
