import React, { useReducer, useState } from 'react'
import { useEffect } from 'react';
import { apiAgent } from "../../../axiosConfig";
import Pagination from '../../../Pagination';
import TableHeads from '../../visualizers/tabular/TableHeads';
import TableRows from '../../visualizers/tabular/TableRows';
import { downloadOutboundStats, fetchTeamsStats } from './outBoundApis';
import ArrowNextStep from "../../../assets/ArrowNextStep.svg";
import AnPopup from '../../cta_utils/AnPopup';
import { LoaderComponent } from '../../utils/LoaderComponent';
import ConditionalRender from '../../../ConditionalRender';
import DownloadImage from "../../../assets/download.png";
import { FlashMessages } from '../../cta_utils/FlashMessages';
import { initialFiltersState, newsFeedFilterReducer as filterReducer } from '../../admin_views/filters/ProspectBasedFilters/reducer/newsFeedFilterReducer.js';
import GreenDot from '../../../assets/GreenDot.svg'
import { ProspectBasedDateFilter, ProspectBasedFilters } from '../filters/ProspectBasedFilters/filter/ProspectBasedFilters';
import { datepicker_input_format } from '../../utils/dateUtils';
import SearchTeamsUtils from '../../utils/SearchTeamsUtils';


const OutboundTeamsStats = () => {
    
    const [allTeamsData, setAllTeamsData] = useState([]);
    const [filtersState, filtersStateReducer] = useReducer(filterReducer , initialFiltersState)
    const [searchTerm, setSearchTerm] = useState("");
    const [pageData, setPageData] = useState({
        loadingTeams: false,
        totalPages: 0,
        currentPage: 1
    });
    const [successMessages, setSuccessMessages] = useState({
        message: "",
        duration: 0,
        from: ""
    });
    const [singleStepData, setSingleStepData] = useState({
        teamDetails: {},
        userDetails: [],
        showPopup: false,
        loadingStep: false,
    })
    
    useEffect(() => {
       fetchTeams();
    }, [pageData.currentPage]);

    const fetchTeams = (page=pageData.currentPage) => {
        let data_params={page: page}
        setPageData({...pageData, loadingTeams: true})
        fetchTeamsStats(data_params, searchTerm).then(res => {
            setAllTeamsData(res.data.data);
            setPageData({...pageData, loadingTeams: false, totalPages: res.data.total_pages, currentPage: res.data.page});
        }).catch(err => {
            console.log(err);
            setPageData({...pageData, loadingTeams: false});
        })
    }

    const toggleShowStep = (e, team, step) => {
        e.stopPropagation();
        if(e.target.className == "text_body_light") return;
        setSingleStepData({...singleStepData, teamDetails: {name: team.name, currentStep: step}, showPopup: true, loadingStep: true});
        apiAgent({
            method: "get",
            url: "outbound_mail/single_step_stats",
            params: {team_id: team.id, step: step}
        }).then(res => {
            setSingleStepData((singleStepData) => ({...singleStepData, loadingStep: false, userDetails: res.data.data}));
        }).catch(err => {
            console.log("From catch")
            setSingleStepData((singleStepData)=> ({...singleStepData, loadingStep: false}));
        })
    }
    const handlePopupClose = () => {
        setSingleStepData({
            teamDetails: {},
            userDetails: [],
            showPopup: false,
            loadingStep: false,
        });
    }
    const renderSingleStepPopup = () => {
        let {teamDetails} = singleStepData;
        return(
            <AnPopup handleOnClosePopup={handlePopupClose} maxWidth={800}>
                <div className='text_title' style={{marginBototm: "18px"}}>
                    {teamDetails.name} - Step {teamDetails.currentStep}
                </div>
                <LoaderComponent isLoading={singleStepData.loadingStep} loaderStyle={{width: 32, margin: '36px auto', display: 'block'}}/>
                <ConditionalRender if={!singleStepData.loadingStep}>
                    <table className="data_table" style={{textAlign: "left", marginTop: "24px"}}>
                        <TableHeads typoGraphyClass="text_body_big_light" headerClass="table_header_row_seperated">
                            <th style={{width:"30%"}}>User</th>
                            <th style={{width:"15%"}}>Email Sent</th>
                            <th style={{width:"15%"}}>Opened</th>
                            <th style={{width:"15%"}}>Clicked</th>
                            <th style={{width:"15%"}}>Action</th>
                        </TableHeads>
                        <tbody>
                            {
                                singleStepData.userDetails.map(user => {
                                    return (
                                        <TableRows key={user.id} rowClass="table_content_row_seperated text_body">
                                            <td>
                                                <div className='text_subtitle_big'>{user.name}</div>
                                                <div className='text_body_light' style={{marginTop: "3px"}}>{user.email}</div>
                                            </td>
                                            <td>{user.email_sent_at || "-"}</td>
                                            <td>{user.email_opened_at || "-"}</td>
                                            <td>{user.email_clicked_at || "-"}</td>
                                            <td style={{textTransform: "capitalize"}}>{user.action_type ? (user.action_type + "d") : "-"}</td>                                        
                                        </TableRows>
                                    )}
                                )
                            }
                        </tbody>
                    </table>
                </ConditionalRender>
            </AnPopup>
        )
    }

    const exportReport = (tempFiltersState) => {
        let range_params = {}
        Object.keys(tempFiltersState.dateRanges).forEach(k => {
            if(tempFiltersState.dateRanges[k].value.from && tempFiltersState.dateRanges[k].value.to){
                let from = tempFiltersState.dateRanges[k].value.from;
                let to = tempFiltersState.dateRanges[k].value.to;
                range_params[k] = {from: datepicker_input_format(from), to: datepicker_input_format(to)};
            }
        })
        downloadOutboundStats({date_ranges: range_params}).then(res => {
            setSuccessMessages({
                message: "You will receive an email when the export is ready to download",
                duration: 3000,
                from: "Data Exports"
            })
        }).catch(
            (error) => {
                console.log(error);
            }
        );
    }
    const dataExportedMessage = (message) => {
       
        setSuccessMessages({...successMessages, message: message})
    }
    return (
        <div style={{margin: "16px auto", maxWidth: "920px"}}>
            
            <div className='text_title'>
                Marketing Campaign Report
            </div>
            <SearchTeamsUtils
                fetchTeams={fetchTeams}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
            >
            </SearchTeamsUtils>
            <div className='default_flex_container'>
                <div style={{flex: 1}}>
                    <Pagination totalPages={pageData.totalPages}
                        currentPage={pageData.currentPage}
                        onPageChange={(cp) => setPageData({...pageData, currentPage: cp})}
                    />
                </div>
                <div onClick={() => {filtersStateReducer({type: "SET_POPUP", payload: {popup: "showDateFilterDropDown", showable: true}})}} style={{position: 'relative', display: 'flex', alignItems: 'center', cursor: "pointer"}}>
                    <img src={DownloadImage} style={{maxWidth: 15, maxHeight: 15}}></img>
                    <span className='text_link' style={{marginLeft: 6 }}>Download</span>
                    {
                        filtersState.showDateFilterDropDown && (
                                <ProspectBasedDateFilter 
                                    filtersState={filtersState}
                                    filtersStateReducer={filtersStateReducer}
                                    tempFilterReducer={filterReducer}
                                    filter="exportReport"
                                    exportReport={exportReport}
                                />
                            )
                    }
                </div>
            </div>
            <table className="data_table" style={{textAlign: "left", marginTop: "24px"}}>
                <TableHeads typoGraphyClass="text_body_big_light" headerClass="table_header_row_seperated">
                    <th style={{width:"30%"}}>Team</th>
                    <th style={{width:"15%"}}>Date</th>
                    <th style={{width:"15%"}}>Sequence</th>
                    <th style={{width:"40%"}}>Sequence step</th>
                </TableHeads>
                <tbody>
                    {
                        allTeamsData.map(team => {
                            return (
                                <TableRows key={team.id} rowClass="table_content_row_seperated text_body">
                                    <td>{team.name}</td>
                                    <td>{team.created_at}</td>
                                    <td>{team.sequence_type}</td>
                                    <td style={{gap: "15px"}}>
                                        <div className='default_flex_container' style={{gap: "15px"}}>
                                            <div className={team.sequence_steps >= 1 ? "text_link" : "text_body_light"} onClick={(e) => toggleShowStep(e,team,1)}>
                                                Step 1
                                            </div>
                                            <img src={ArrowNextStep}></img>
                                            <div className={team.sequence_steps >= 2 ? "text_link" : "text_body_light"} onClick={(e) => toggleShowStep(e,team,2)}>
                                                Step 2
                                            </div>
                                            <img src={ArrowNextStep}></img>
                                            <div className={team.sequence_steps >= 3 ? "text_link" : "text_body_light"} onClick={(e) => toggleShowStep(e,team,3)}>
                                                Step 3
                                            </div>
                                            <img src={ArrowNextStep}></img>
                                            <div className={team.sequence_steps == 4 ? "text_link" : "text_body_light"} onClick={(e) => toggleShowStep(e,team,4)}>
                                                Step 4
                                            </div>
                                        </div>
                                    </td>
                                </TableRows>
                            )}
                        )
                    }
                </tbody>
            </table>
            {singleStepData.showPopup && renderSingleStepPopup()}
            {
                successMessages.message && (<FlashMessages messageTheme='success' duration={successMessages.duration} closeCallback={dataExportedMessage}>{successMessages.message}</FlashMessages>)
            }
        </div>
    )
}

export default OutboundTeamsStats
