import React from 'react'
import { gtag } from 'ga-gtag';
export const RecordGA = (props) => {
    return (
        gtag('event', 'click', {
        'event_category': props.label,
        'event_label': props.link
      })
    )
}

