import { createSlice } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";

export const currentTeamSlice = createSlice({
    name: "current_team",
    initialState: {
        team: {},
        isLoading: false,
        loadError: "",
    },
    reducers: {
        updateCurrentTeam: (state, action) => {
            let new_team = action.payload;
            let access_list = {};
            (new_team.access_list || []).forEach(element => {
                access_list[element] = true
            });
            new_team.access_list = access_list
            return { ...state, team: new_team };
        },
        updateTeamLoadingStage: (state, action) => {
            return { ...state, isLoading: action.payload };
        },
        teamlLoadError: (state, action) => {
            let cookies = new Cookies();
            cookies.remove("team_id", { path: "/" });
            return state;
        },
    },
});

export const { updateCurrentTeam, updateTeamLoadingStage, teamlLoadError } =
    currentTeamSlice.actions;

export default currentTeamSlice.reducer;
