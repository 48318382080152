import React, { useEffect, useState } from 'react'
import { apiAgent } from '../../../axiosConfig'
import { Button } from '../../cta_utils/AnButton'
import { HelpCenterLinks } from '../support_views/help_center/HelpCenterPage'
import AllFields from './allMappingFileds'


const AddProspectStep2 = (props) => {
    
    const [isLoading, setIsLoading] = useState(false)
    const defaultFields = AllFields()
    const [customFieldMappings, setCustomFieldMappings] = useState(defaultFields)//useReducer(() => {}, allFields)
    const handleOptionSelect = (e) => {
        let temp = [...props.mappedFields]
        temp[e.target.getAttribute('rowindex')] = e.target.value
        props.setMappedFields(temp);
    }

    useEffect(() => {
        apiAgent({
            method: 'get',
            url: '/team/custom_fields',
            params: {}
        }).then( res => {
            if(res.data.custom_fields.length > 0) setCustomFieldMappings( state => ([...state, ...res.data.custom_fields]))
        }).catch(err => {

        })
    }, [])

    const handleMapAndContinue = () => {
        setIsLoading(true)
        let fieldMappings = []
        props.step2Data.headers.forEach((field, index) => {
            fieldMappings.push({
                column_number: index,
                original_field: field,
                mapped_to: props.mappedFields[index]
            })
        });
        apiAgent({
            method: 'post',
            url: `/prospect_import/${props.step2Data.id}/map_fields`,
            data: {
                prospect_import: {
                    field_mappings: fieldMappings
                }
            }
        }).then(res => {
            setIsLoading(false);
            props.setStep3Data(res.data.data)
            props.setActiveStep('step_3')
        }).catch(err => {
            setIsLoading(false);
        })
    }

    return (
        <>
            <div style={{padding: 24, borderBottom: '1px solid #E7ECEB'}}>
                <div className="text_title">
                    Mapping user data
                </div>
                <div className="text_body_light">
                    Map the left side data using the right side dropdowns that matches your data. Unmapped columns will not be imported.
                </div>
                <a href={HelpCenterLinks['Adding Prospects'] + "&scrollTo=initiate_import"} className="text_link" target="_blank">Learn how to map user data</a>
            </div>
            <div style={{padding: 24}}>
                <div>
                    <span className="text_subtitle_big">Uploaded file: </span>
                    <a className="text_link" href={props.step2Data.original_file_url} style={{fontSize: 16}}>{props.step2Data.name}</a>  
                </div>
                <table className="map_step2_table" style={{textAlign: 'left', marginTop: 16}}>
                    <thead>
                        <tr className="text_subtitle_big" style={{background: 'rgba(67, 126, 254, 0.8)', color: '#fff'}}>
                            <th style={{width: '50%'}}>Column Header</th>
                            <th style={{width: '50%'}}>Mapping</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.step2Data.headers.map((header, index) => (
                                <tr key={index}>
                                    <td>{header}</td>
                                    <td className="an_input_box">
                                        <select style={{padding: '10px 14px'}} value={props.mappedFields[index]} rowindex={index} onChange={handleOptionSelect}>
                                        <option key='no_value' value="" >Select Field</option>
                                            {
                                                customFieldMappings.map((field, index) => (
                                                   <option key={field.id} value={field.id} style={{display: props.mappedFields.indexOf(field.id)!=-1 ? 'none': ''}}>{field.field_name}</option>
                                               )) 
                                            }
                                        </select>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            <div className='default_flex_container' style={{padding: 24, borderTop: '1px solid #E7ECEB', justifyContent: 'flex-end'}}>                
                <div style={{display: 'flex'}}>
                    <Button buttonSize='Medium' type="Secondary" onClick={() => {props.setActiveStep('step_1')}} buttonStyle={{zIndex: 99}} innerButtonStye={{padding: '8px 34px'}}>Back</Button>
                    <Button buttonSize='Medium' isLoading={isLoading} onClick={handleMapAndContinue} buttonStyle={{marginLeft: 16}} innerButtonStye={{padding: '8px 28px'}}>Map & Continue</Button>
                </div>
            </div>
        </>
    )
}

export default AddProspectStep2
