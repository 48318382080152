import React from 'react'
import ConditionalRender from '../../ConditionalRender'


export const AnInputRadioButton = ({
    labelStyle,
    labelClass="text_body_light",
    isChosen=false,
    ...props
  }) => {
    const renderLabel = (label) => {
      return typeof label === 'function' ? label() : label;
    };
    return(
      <div className="an_input_radio" style={props.style}>
        <label>          
          {props.children}
          <div className="radio">
            <div className="inner_radio">
            </div>
          </div>
          <ConditionalRender if={props.label}>
            <span className={isChosen ? 'text_body' : 'text_body_light'} style={{marginLeft: 8, ...labelStyle}}>
              {renderLabel(props.label)}
            </span>
          </ConditionalRender>
        </label>
        <ConditionalRender if={props.error}>
          <div className="text_caption_error" style={{marginTop: 8}}>{props.error}</div>
        </ConditionalRender>
      </div>
  )
}