import ConditionalRender from "../../../../../ConditionalRender";
import AnDropDown from "../../../../cta_utils/AnDropDown";
import { AnInputRadioButton } from "../../../../input_utils/AnInputRadioButton";
import { indexOfObject, swapItems } from "../../../../utils/ArrayUtils";
import { ALL_TABLE_ACTIONS, findLastDisplayTrueIndex } from "../../../new_temp_mapper/reducers/TableReducer";
import { useMapperFilterContext } from "../../../new_temp_mapper/contexts/mapper_filter_context";
import { ALL_MAPPER_FILTER_ACTIONS } from "../FiltersStateReducer";
import { setState } from "../../../../utils/CommonReducer";
import { useMemo } from "react";
import blackMenuDots from '../../../../../assets/directory/black_menu_dot.svg'
import greenUp from '../../../../../assets/GreenUpArrow.svg'
import greenDown from '../../../../../assets/GreenDownArrow.svg'
import Icon from "../../../../utils/Icon";
export const ColumnFilterDropDown = ({ column_index, field_key, tableState, tableStateReducer, positionRight = false, ...props }) => {
    const { filtersState, filtersStateReducer } = useMapperFilterContext();
    const { sortings } = filtersState
    const all_fields = useMemo(() => { return [...tableState.all_columns[column_index].fields] }, [])
    const field_index = indexOfObject(all_fields, "key", field_key)
    const currentField = all_fields[field_index]
    let ascChosen = sortings?.[field_key] === 'asc'
    let descChosen = sortings?.[field_key] === 'desc'
    const toggleDropDown = () => setState(tableStateReducer)({ dropDownList: {} })
    const handleSwap = (index1, index2) => tableStateReducer({ type: ALL_TABLE_ACTIONS['SWAP_COLUMN'], payload: { column_index: column_index, index1: index1, index2: index2 } })
    const handleRemoveSort = (field_key) => {
        let temp_sortings = { ...sortings }
        delete temp_sortings[field_key]
        filtersStateReducer({ type: ALL_MAPPER_FILTER_ACTIONS['SET_STATE'], payload: { sortings: temp_sortings, dropDownList: {} } })
        setState(tableStateReducer)({ dropDownList: {} })
    }
    const handleHeaderClick = () => {
        if (currentField.search_enabled && !currentField.dropDownEnabled) setState(tableStateReducer)({ searchBarActive: { [currentField.key]: true } })
        if (!currentField.dropDownEnabled) return;
        setState(tableStateReducer)({ dropDownList: { [currentField.key]: !tableState.dropDownList[currentField.key] } })
    }
    const renderDropDown = () => {
        return (
            <AnDropDown style={{
                position: 'absolute',
                marginLeft: positionRight ? '6px' : '0px',
                marginTop: 4,
                right: positionRight ? 'auto' : '0px',
                padding: '16px',
                background: 'white',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
                borderRadius: 4,
                width: 'max-content',
                zIndex: 99
            }}
                toggleDropDown={toggleDropDown}>
                <div
                    className="text_body_light"
                    style={{ textTransform: 'uppercase', marginBottom: 8 }}>Column property
                </div>
                <ConditionalRender if={currentField.sortingEnabled}>
                    <AnInputRadioButton label={() =>
                        <div className='default_flex_container'>
                            <Icon name="DownArrow"/>
                            <div className={ascChosen ? "text_body" : "text_body_light"} style={{ marginLeft: 8 }}>{currentField.sorting_type === 'text' ? "Sort ascending" : "Sort old to new"}</div>
                        </div>
                    }
                        style={{ marginBottom: 12 }}
                        isChosen={ascChosen}
                    >
                        <input type="radio"
                            checked={ascChosen}
                            onChange={(e) => filtersStateReducer({ type: ALL_MAPPER_FILTER_ACTIONS['SET_STATE'], payload: { sortings: { [field_key]: 'asc' } } })}
                        />
                    </AnInputRadioButton>
                    <AnInputRadioButton label={() =>
                        <div className='default_flex_container'>
                            <Icon name="UpArrow"/>
                            <div className={descChosen ? "text_body" : "text_body_light"} style={{ marginLeft: 8 }}>{currentField.sorting_type === 'text' ? "Sort descending" : "Sort new to old"}</div>
                        </div>
                    }
                        isChosen={descChosen}
                    >
                        <input type="radio"
                            checked={descChosen}
                            onChange={(e) => filtersStateReducer({ type: ALL_MAPPER_FILTER_ACTIONS['SET_STATE'], payload: { sortings: { [field_key]: 'desc' } } })}
                        />
                    </AnInputRadioButton>
                </ConditionalRender>
                <ConditionalRender if={ascChosen || descChosen}>
                    <div className='default_flex_container' style={{ marginTop: 12, cursor: 'pointer' }} onClick={() => handleRemoveSort(field_key)}>
                        <div style={{ background: '#F3F4F5', borderRadius: '50%' }}>
                            <Icon name="Cancel"/>
                        </div>
                        <div className='text_body' style={{ marginLeft: 8 }}>Remove Sort</div>
                    </div>
                </ConditionalRender>

                <ConditionalRender if={currentField.swapEnabled}>
                    <div className="line_seperator_ex_light_grey" style={{ margin: "16px -16px" }}></div>

                    <ConditionalRender if={!positionRight}>
                    <div className='default_flex_container' style={{ marginBottom: 12, cursor: 'pointer' }}
                        onClick={() => handleSwap(field_index - 1, field_index)}>
                        <Icon name="LeftArrow"/>
                        <div className='text_body' style={{ marginLeft: 8 }}>Move one column left</div>
                    </div>
                    </ConditionalRender>

                    <ConditionalRender if={findLastDisplayTrueIndex(all_fields) !== field_index}>
                    <div className='default_flex_container' style={{cursor: 'pointer'}} onClick={() => handleSwap(field_index, field_index + 1)}>
                        <Icon name="RightArrow"/>
                        <div className='text_body' style={{ marginLeft: 8 }}>Move one column right</div>
                    </div>
                    </ConditionalRender>
                </ConditionalRender>

                <ConditionalRender if={!tableState.all_columns[column_index].is_fixed}>
                    <div className='default_flex_container' style={{ marginTop: 12, cursor: 'pointer' }} onClick={() =>
                        tableStateReducer({ type: ALL_TABLE_ACTIONS['REMOVE_HIDDEN_COLUMN'], payload: { field_key: field_key } })
                    }>
                        <div style={{ background: '#F3F4F5', borderRadius: '50%' }}>
                          <Icon name="Cancel"/>
                        </div>
                        <div className='text_body' style={{ marginLeft: 8 }}>Remove Column</div>
                    </div>
                </ConditionalRender>

            </AnDropDown>
        )
    }
    return (
        <div style={{ position: 'relative' }}>
            <div style={{ cursor: 'pointer'}} onClick={() => handleHeaderClick()}>
            {ascChosen && <img src={greenDown} alt='↓' style={{ marginLeft: 6 }}></img>}
            {descChosen && <img src={greenUp} alt='↑' style={{ marginLeft: 6 }}></img>}
            {!ascChosen && !descChosen && <img className="grey_menu_dots" src={blackMenuDots}></img>}
            </div>
            {tableState.dropDownList?.[field_key] && renderDropDown()}
        </div>
    )
}