import { useCallback } from "react";
import { useSelector } from "react-redux";

const useCheckUserAccess = () => {
    const current_user = useSelector((state) => state.current_user.user);
    const current_team = useSelector((state) => state.current_team.team);

    const checkUserAccess = useCallback(
        (action_type) => {
            return !!(
                current_user?.access_list?.[action_type] ||
                current_team?.access_list?.[action_type]
            );
        },
        [current_team, current_user]
    );

    return checkUserAccess;
};

export default useCheckUserAccess;

// ALL ACTION TYPES 
// [
//     "news_publishing",
//     "post_editing",
//     "post_deleting",
//     "post_flagging",
//     "tag_adding",
//     "tag_importing",
//     "tag_creating",
//     "team_member_assigning",
//     "prospect_archiving",
//     "prospect_data_download",
//     "prospect_data_import",
//     "prospect_bulk_delete",
//     "custom_filed_adding",
//     "team_switch",
//     "team_creating",
//     "team_importing",
//     "team_member_importing",
//     "team_downloading",
//     "team_managing",
//     "team_notification",
//     "team_setting",
//     "team_important_fields",
//     "team_type",
//     "email_notification",
//     "auto_publishing_fields",
//     "enable_tracking_fields",
//     "enable_mid_confidence",
//     "super_admin_panel",
//     "page_smart_publish",
//     "page_teams",
//     "page_companies",
//     "page_rss_candidates",
//     "page_rss_sources",
//     "page_articles",
//     "page_news_queries",
//     "page_team_stats",
//     "page_team_user_activities",
//     "page_outbound_stats",
//     "page_prospect_mappings",
//     "page_flagged_articles",
//     "page_system_generated_posts",
//     "page_teams_dashboard",
//     "page_categories",
//     "page_article_dashboard",
//     "page_system_configurations",
//     "page_linkedin_prospect_mapping",
//     "enable_job_updates"
// ];