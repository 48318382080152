import { useMemo } from "react";

export const mappedFilters = [
    {label: "All profiles", key: "all"}, 
    {label: "Mapped", key: "mapped"}, 
    {label: "Unmapped", key: "unmapped"}
];

export const ORDERS = ['1','2','3','4','5']

export const defaultConfidences = [
    {cond: 'Above', min: 75, max: 100, label: '75% and above'},
    {cond: 'Above', min: 50, max: 100, label: '50% and above'},
    {cond: 'Above', min: 40, max: 100, label: '40% and above'},
    {cond: 'Above', min: 20, max: 100, label: '20% and above'},
    {cond: 'Above', min: 10, max: 100, label: '10% and above'},
]

export const CONDITIONS = ["Above", "Below",  "Between", "Equal"]
