import { AnConfirmPopup } from "../../../cta_utils/AnConfirmPopup"
import { export_mapper_sugg } from "../api"
import { useMapperFilterContext } from "../contexts/mapper_filter_context"
import { ALL_MAPPER_ACTIONS } from "../reducers/TempMapperReducer"

export const ExportPopup = ({pageDataReducer, handlePopupClose}) => {
    const {filtersState} = useMapperFilterContext()
    const handleExportMapperSuggestions = () => {
        let valueData = {...filtersState}
        let data_params = {
            tags: valueData.selectedTags,
            order: valueData.order,
            confidence: {min: valueData.range.min, max: valueData.range.max},
            sortings: valueData.sortings,
            name: valueData.search.name
            // list_name: valueData.list_name,
            // mappedFilter: valueData.mappedFilter
        }
        export_mapper_sugg(data_params).then((response) => {
            pageDataReducer({type: ALL_MAPPER_ACTIONS['SET_STATE'], payload: {successMessages:{
                message: "You will receive an email when the export is ready to download",
                duration: 3000000,
                from: "Data Exports"
            }}})
                handlePopupClose();
            })
    }
    return(
            <AnConfirmPopup 
                onSubmitCallback={() => handleExportMapperSuggestions()}
                onCancelCallback={handlePopupClose}
                name="Mapper Suggestions"
            >
            </AnConfirmPopup>
        )
}