const getDateUtil = (day=0, month=0, year=0) => {
    let date = new Date();
    date.setDate(date.getDate() + day);
    date.setMonth(date.getMonth() + month);
    date.setFullYear(date.getFullYear() + year);
    return date
}

const dateFormator = (date, format={type: "en-uk"}, delim="/") => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear()
    
    if(format.year === "short") year%=100;    
    
    if(format.type === "datepicker_object") {
        return{
            day: day,
            month: month,
            year: year
        }
    }else if(format.type==="en-uk"){
        return day + delim + month + delim + year
    }else if(format.type === "en-us"){
        return month + delim + day + delim + year
    }else if(format.type === "custom"){
        let arr = format.format.split("-");
        let date_str = arr.map(e => {
            if(e === "dd") return day;
            if(e === "mm") return month;
            if(e === "yyyy") return year;
        }).join(delim);
        return date_str
    }

    return date.toLocaleDateString().replaceAll("/", delim)
}

const datepicker_input_format = (date, format="dd-mm-yyyy", delim="-") => {
    if(!date) return ""
    let arr = format.split("-");
    let date_str = arr.map(e => {
        if(e == "dd") return date.day;
        if(e == "mm") return date.month;
        if(e == "yyyy") return date.year;
    }).join(delim);
    return date_str;
    return `${date.day}-${date.month}-${date.year}`
}
const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber - 1);
  
    return date.toLocaleString('en-US', { month: 'short' });
}
const checkValidDateString = (str, delim="-") => {
    if(!str) return NaN;
    // String should be in dd-mm-yyyy format
    return Date.parse(str.split(delim).reverse().join("-"));
}
const currentYear = new Date().getFullYear()
const currentMonth = new Date().getMonth()

const generateYears = (past_years) => {
    const years = [];
    for (let year = currentYear; year >= currentYear - past_years; year--) {
      years.push(year);
    }
    return years;
  };
export {getDateUtil, dateFormator, getMonthName, datepicker_input_format, checkValidDateString, currentYear, currentMonth, generateYears}