import React, {useCallback, useEffect, useState, useRef, useReducer} from 'react'

import { apiAgent } from '../../../axiosConfig.js';
import Pagination from "../../../Pagination.js";
import Post from '../../feed_components/Post.js';
import LowConfidencePost from '../../feed_components/low_confidence_post.js';
import {AnInputCheckBox} from "../../input_utils/AnInputCheckBox.js";
import {connect, useSelector} from "react-redux";
import ConditionalRender from "../../../ConditionalRender.js";
import {LoaderComponent} from "../../utils/LoaderComponent.js";
import FeedPostErrorBoundary from '../../error_handlers/FeedPostErrorBoundary.jsx';
import { indexOfObject } from '../../utils/ArrayUtils.js';
import { FlashMessages } from '../../cta_utils/FlashMessages.js';
import { datepicker_input_format, dateFormator, getDateUtil } from '../../utils/dateUtils.js';
import UnmappedJobUpdates from '../../admin_views/temp_mapper/_UnmappedJobUpdates.jsx';
import LowConfidenceTypesFilter from '../../feed_components/_lowConfidenceTypes.jsx';
import MatchingArticleDateFilters from '../../admin_views/filters/ArticleBasedFilters/filters/matchingArticleDateFilters.jsx';
import { newsFeedFilterReducer as filterReducer } from '../../admin_views/filters/ProspectBasedFilters/reducer/newsFeedFilterReducer.js';
const filterTypes = [{label: "Name Present", key: 'name_exists'}, {label: "Name + Company Present", key: 'company_exists'}];

const LowConfidenceResults = ({
    filtersState,
    filtersStateReducer,
    fetchUrl,
    displayFilterSection=true,
    confidenceType='low',
    ...props
}) => {
    const [lowConfidencePosts, setLowConfidencePosts] = useState([]);
    const [filterType, setFilterType] = useState("");
    // const [def, setDefault] = useState(true);
    const def = useRef("");
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const current_user = useSelector(state => state.current_user?.user);
    const current_team = useSelector(state => state.current_team?.team);
    const [isFetching, setIsFetching] = useState(false);
    const {dropDownFilterlist} = filtersState
    let {activeViewType} = dropDownFilterlist
    const setActiveViewType = (value) => {
        setDropDownFilterList({activeViewType: value})
    }
    const setDropDownFilterList = (filterlist) => {
        filtersStateReducer({payload: {dropDownFilterlist: {...dropDownFilterlist, ...filterlist}}, type: 'SET_STATE'})
    }
    const [successMessage, setSuccessMessage] = useState({
        message: "",
    })
    useEffect(() => {
        if(activeViewType === "news_results"){
            fetchLowConfidencePosts(1);
        }
    }, [filterType, filtersState.filtersSelected, filtersState.dateRanges, activeViewType]);

    const fetchLowConfidencePosts = (page) => {
        let {filtersSelected} = filtersState;
        let range_params = {}
        let temp = {dateRanges: {...filtersState.dateRanges}}
        if(def.current != confidenceType) {
            let from = dateFormator(getDateUtil(-14), {type: "datepicker_object"});
            let to = dateFormator(getDateUtil(), {type: "datepicker_object"});
            temp.dateRanges.created_at = {...temp.dateRanges.created_at}
            temp.dateRanges["created_at"].value = {...temp.dateRanges["created_at"].value}
            temp.dateRanges["created_at"].value.from = from;
            temp.dateRanges["created_at"].value.to = to;
            filtersStateReducer({
                type: "UPDATE_DATE_RANGE",
                payload: { 
                    key: "created_at",
                    subkey: "value",
                    value: temp.dateRanges.created_at.value
                }
            });
            def.current = confidenceType;
            return;
        }
        Object.keys(filtersState.dateRanges).forEach(k => {
            if(filtersState.dateRanges[k].value.from && filtersState.dateRanges[k].value.to){
                let from = filtersState.dateRanges[k].value.from
                let to = filtersState.dateRanges[k].value.to
                range_params[k] = {from: datepicker_input_format(from), to: datepicker_input_format(to)};
            }
        })
        setIsFetching(true);
        var params = {  
            page: page,
            date_ranges: range_params,
            tags: filtersSelected.tags,
            assigned: filtersSelected.assigns,
            title: filtersSelected.title,
            company: filtersSelected.company,
            college: filtersSelected.college,
            location: filtersSelected.location,
            mentioned_for: filtersSelected.mentioned_for,
            sort: filtersSelected.sort?.[0]                
        }
        params[filterType] = true
        if(current_team.displayable_features.display_mid_confidence) params.feed_article_condition = confidenceType
        apiAgent({
            method: 'get',
            url: fetchUrl,
            params: params
        }).then(
            (response) => {
                setIsFetching(false);
                let result = response.data;
                let low_posts = result.data.map(d => {
                    if(d.mentioned_for){
                        if(!d.matching_article_tags)
                            d.matching_article_tags = {};
                        d.mentioned_for.map((mf) => d.matching_article_tags[mf] = mf )    
                        d.categories.map((c) => d.matching_article_tags[c] = c )                                                                                                                                                
                    }
                    return d;
                })
                filtersStateReducer({type: "ASSIGN_AGGREGATES_DATA", payload: {aggregates: result.aggregates}})
                setLowConfidencePosts(low_posts);
                setTotalPages(result.total_pages);
                setCurrentPage(page);
                props.parentReducer({
                    payload: {
                        high_confidence_results_count: response.data.high_confidence_results_count,
                        mid_confidence_results_count: response.data.mid_confidence_results_count, 
                        low_confidence_results_count: response.data.low_confidence_results_count,
                        allFilterTabs: response.data.all_filter_tabs
                    },
                    type: 'SET_STATE'
                })
            },
            (error)  => {
                setIsFetching(false);
            }
        );
    }
    const onPublishCallback = useCallback((published_article) => {
        let ind = indexOfObject(lowConfidencePosts, "id", published_article.id);
        if(ind === -1) return;
        let temp = [...lowConfidencePosts];
        temp.splice(ind, 1);
        setLowConfidencePosts(temp)
        handleSuccessMessage("News article moved to high confidence")
    }, [lowConfidencePosts])

    const handleSuccessMessage = (message, duration=5000) => {
        setSuccessMessage({message: message, duration: duration})
    }
    const renderNewsResults = () => {
        return(
            <>
                {
                    !isFetching && (lowConfidencePosts.length === 0) && (
                        <div style={{textAlign: 'center', marginTop: '80px'}}>
                            <div className="text_subtitle_big">No <span style={{textTransform: 'capitalize'}}>{confidenceType}</span> Confidence results to show!</div>
                        </div>
                    )
                }
                <div>
                    { !isFetching && (totalPages !== 0) && (
                        <Pagination totalPages={totalPages}
                                    currentPage={currentPage}
                                    onPageChange={fetchLowConfidencePosts}
                                    style={{padding: '8px 8px 0px'}}
                        />)
                    }
                </div>
                { isFetching && (<LoaderComponent isLoading={true}  loaderStyle={{width: 32, margin: '36px auto', display: 'block'}}/>)}
                <div>
                    { !isFetching && lowConfidencePosts.map(item => (
                        <FeedPostErrorBoundary key={item.id}>
                            <LowConfidencePost item={item} callbacks={{onPublishCallback: onPublishCallback}} confidenceType={confidenceType}/>
                        </FeedPostErrorBoundary>
                    ))}
                </div>
                <div style={{marginBottom: "24px"}}>
                    { !isFetching && (totalPages !== 0) && (
                        <Pagination totalPages={totalPages}
                                    currentPage={currentPage}
                                    onPageChange={fetchLowConfidencePosts}
                        />)
                    }
                </div>
            </>
        )
    }
    const renderJobUpdates = () => {
        return <UnmappedJobUpdates 
        type="feed" 
        buttonLabel='Move to High Confidence' 
        setParentComponentState={(count_data) => props.parentReducer({type: "SET_STATE", payload: {low_confidence_results_count: count_data.unmappedCount}})}
        dropDownFilterlist={dropDownFilterlist}
        dateRanges={filtersState.dateRanges}
        />
    }
    return(
        <React.Fragment>
            <div style={{marginTop: '12px'}}>
                <ConditionalRender if={current_user.is_super_admin && displayFilterSection}>
                    <div style={{display: 'flex'}}>
                        {
                            filterTypes.map(type => (
                                <div className={filterType === type.key ? 'text_link type_box_highlight_green' : 'text_body type_box'} style={{marginRight: 12}} onClick={() => filterType !== type.key ? setFilterType(type.key) : setFilterType('')}>{type.label}</div>
                            ))
                        }
                    </div>
                    <div style={{clear: 'both'}}></div>
                </ConditionalRender>
                <ConditionalRender if={displayFilterSection}>
                <div className='flex_container_space_between' style={{margin: "16px 0px 8px"}}>
                    <ConditionalRender if={current_team.displayable_features.display_job_updates && confidenceType === 'low'}>
                        <LowConfidenceTypesFilter activeType={activeViewType} setActiveType={setActiveViewType} />
                    </ConditionalRender>
                    <ConditionalRender if={current_team.feed_job_updates_enabled}><div></div></ConditionalRender>
                    <div>
                        <MatchingArticleDateFilters
                            filtersState={filtersState}
                            filtersStateReducer={filtersStateReducer}
                            tempFilterReducer={filterReducer}
                            customStyle={{ position: "relative", width: 36, height: 36 }}
                            innerText=''
                            position={current_team.feed_job_updates_enabled ? 'left' : 'right'}
                            theme="GREEN"
                        />
                    </div>
                </div>
                </ConditionalRender>
                {   activeViewType === "news_results" && renderNewsResults()    }
                {   activeViewType === "job_updates" && renderJobUpdates()      }                
            </div>
            {successMessage.message && 
                (<FlashMessages 
                    duration={successMessage.duration}
                    closeCallback={handleSuccessMessage}
                    messageTheme="success"
                >
                    {successMessage.message}
                </FlashMessages>
                )}
        </React.Fragment>
    );
}

export default LowConfidenceResults;
