import { Button } from "./AnButton";
import AnDropDown from "./AnDropDown";

export const AnConfirmDropdown = ({
    message = "Are you sure you want to perform this action?",
    onSubmitCallback,
    onCancelCallback,
    btnLabels={submitLabel: 'Save', cancelLabel: 'Cancel'},
    ...props
}) => {
    let {submitLabel, cancelLabel} = btnLabels
    return(
        <AnDropDown style={{position: 'absolute',  right: 0, padding: '16px', background: 'white', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', borderRadius: 4, width: '218px', zIndex: 999}}
        toggleDropDown={() => onCancelCallback()}>
          <div className="text_subtitle_semi_bold" style={{marginBottom: 16, textAlign: 'center'}}>{message}</div>
          
          <div style={{display: 'flex', marginTop: 14, alignContent: 'center', justifyContent: 'center'}}>
            <Button buttonSize="Medium" buttonStyle={{width: 'max-content'}} innerButtonStye={{padding: '8px 20px'}}
                    onClick={() => {onSubmitCallback()}}>{submitLabel}</Button>
            <Button type="Secondary" buttonSize="Medium" buttonStyle={{width: 'max-content', marginLeft: 16}} innerButtonStye={{padding: '8px 20px'}}
                    onClick={() => {onCancelCallback();}}>{cancelLabel}</Button>
          </div>
        </AnDropDown>)
}