import { useEffect, useState } from "react";
import { fetch_rss_experiments } from "./api";
import Pagination from "../../../Pagination";
import TableHeads from "../../visualizers/tabular/TableHeads";
import TableRows from "../../visualizers/tabular/TableRows";
import { LoaderComponent } from "../../utils/LoaderComponent";
import ConditionalRender from "../../../ConditionalRender";

const RssExperiment = () => {
    const [pageData, setPageData] = useState({
        items: [],
        isFetching: false,
        totalPages: 0,
        currentPage: 1,
        totalCount: 0
    });
    useEffect(() => {
        fetchData(1)
    }, [])
    const fetchData = (page) => {
        setPageData({...pageData, isFetching: true});
        let data_params = {page: page}
        fetch_rss_experiments(data_params).then(
            (res) => {
                setPageData({
                    items: res.data.data,
                    totalPages: res.data.total_pages,
                    totalCount: res.data.total,
                    currentPage: page,
                    isFetching: false
                });
            },
            (error) => {
                setPageData({...pageData, isFetching: false});
            }
        );
    };
    const renderExperimentTable = () => {
        return (
            <table className="data_table" style={{textAlign: "left", marginTop: "24px"}}>
            <TableHeads typoGraphyClass="text_body_big_light" headerClass="table_header_row_seperated">
                <th style={{width: "15%"}}>Created At</th>
                <th style={{width: "20%"}}>Run Date</th>
                <th style={{width: "30%"}}>Url</th>
                <th style={{width: "5%"}}>Sharable article found</th>
                <th style={{width: "5%"}}>Article source found</th>
                <th style={{width: "5%"}}>Rss candidate found</th>
                <th style={{width: "20%"}}>Total rss sources count</th>
            </TableHeads>
            <tbody>
              {pageData.items.map(item => (
                <TableRows key={item.id} rowClass="table_content_row_seperated text_body">
                  <td>
                    {item.created_at}
                  </td>
                  <td>
                    {item.run_date}
                  </td>
                  <td>
                    {item.url}
                  </td>
                  <td>
                    {item.sharable_article_found}
                  </td>
                  <td>
                    {item.article_source_found}
                  </td>
                  <td>
                    {item.rss_candidate_found}
                  </td>
                  <td>
                    {item.total_rss_sources_count}
                  </td>
                  </TableRows>
              ))}
            </tbody>
          </table>
        )
    }

    return (
        <>
            <div className='default_flex_container'>
                <div className="text_subtitle_big">{pageData.totalCount} Rss Experiments</div>
                <div style={{flex: 1}}>
                    <Pagination totalPages={pageData.totalPages}
                                currentPage={pageData.currentPage}
                                onPageChange={fetchData}
                    />
                </div>
            </div>
            <div style={{clear: 'both'}}></div>
            <ConditionalRender if={pageData.isFetching}>
                <LoaderComponent isLoading={true}  loaderStyle={{width: 32, margin: '36px auto', display: 'block'}}/>
            </ConditionalRender>
            <ConditionalRender if={!pageData.isFetching}>
                {renderExperimentTable()}
            </ConditionalRender>
        </>
    )
}

export default RssExperiment