import { useState, useEffect, Fragment } from "react";
import { apiAgent } from "../../../axiosConfig.js";
import TableHeads from "../../visualizers/tabular/TableHeads.jsx";
import TableRows from "../../visualizers/tabular/TableRows.jsx";
import { LoaderComponent } from "../../utils/LoaderComponent.js";
import { AnInputCheckBox } from '../../input_utils/AnInputCheckBox';
import getApiUrl from "../../utils/UrlHelper.js";
import { Button } from "../../cta_utils/AnButton.js";
import AnPopup from "../../cta_utils/AnPopup.js";
import {AnInputBox} from '../../input_utils/AnInput.js'

const SystemCofigurationSettings = () => {

    const [data, setData] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [popupsList, setPopupsList] = useState({});
    const [emailType, setEmailType] = useState("");

    useEffect(() => {
        fetchData();
    },[]);

    const fetchData = () => {
        setIsFetching(true);
        apiAgent({
            method: "get",
            url: getApiUrl('email_trigger_status'),
        }).then(
            (res) => {
                setData(res.data.data);
                setIsFetching(false);
            },
            (error) => {
                setIsFetching(false);
            }
        );
    }

    const handleChange = (email) => {
        if(!window.confirm("Are you sure you want to change email trigger status?")) return;
        apiAgent({
            method: "post",
            url: getApiUrl('create_or_update_email_type'),
            params: {
                email_type: email.email_type,
                is_enabled: !(email.is_enabled)
            },
        }).then(
            (res) => {
                setData(res.data.data);
            },
            (error) => {
            }
        );
    }

    const handleOnClosePopup = () => setPopupsList({})

    const addEmailType = () => {
        apiAgent({
            method: "post",
            url: getApiUrl('create_or_update_email_type'),
            params: {
                email_type: emailType
            },
        }).then(
            (res) => {
                setData(res.data.data);
                setPopupsList({})
            },
            (error) => {
                setPopupsList({})
            }
        );
    }

    const addEmailTypePopup = () => {
        return (
            <AnPopup name="addEmailTypePopup" handleOnClosePopup={handleOnClosePopup}>
                <div className="text_title">Add Email Type</div>
                <AnInputBox >
                    <input type="text" name="Email Type" value={emailType} onChange={(e) => setEmailType(e.target.value)} placeholder="Email Type"></input>
                </AnInputBox>
                <div style={{display: 'flex', marginTop: 16}}>
                <Button buttonSize="Medium" buttonStyle={{width: 'max-content'}} innerButtonStye={{padding: '8px 20px'}} onClick={() => {addEmailType()}} >
                    Submit
                </Button>
                <Button type="Secondary" buttonSize="Medium" buttonStyle={{width: 'max-content', marginLeft: 16}} innerButtonStye={{padding: '8px 20px'}} onClick={() => {handleOnClosePopup()}}>
                    Cancel
                </Button>
                </div>
            </AnPopup>
        );
    }
    return (
            <div className="aggregate_container" style={{margin: "48px auto 137px 81px", maxWidth: "720px", float: "left"}}>
                <div class="flex_container_space_between" style={{gap: 215}}>
                    <div className="text_title_big" style={{marginBottom: "27px", float: 'left'}}>System Configurations</div>
                    <Button onClick={() => setPopupsList({addEmailTypePopup: true})} buttonSize="Medium" buttonStyle={{marginLeft: "12px", float: 'right', marginBottom: "25px"}}
                            innerButtonStye={{padding: "9px 16px"}} >
                        Create Email Type
                    </Button>
                </div>
                <table className="data_table" style={{textAlign: "left"}}>
                    <TableHeads typoGraphyClass="text_body_big_light" headerClass="table_header_row">
                        <th style={{width: "60%"}}>Email Type</th>
                        <th>Enable/Disable</th>
                    </TableHeads>
                    <tbody>
                        {
                            !isFetching ?
                            (<>
                                { data.map(email_data => ( <TableRows rowClass="table_content_row text_body">
                                    <td>{email_data.email_type}</td>
                                    <td style={{border: "1px black"}}>
                                        <AnInputCheckBox label="Enable" isChecked={email_data.is_enabled}>
                                            <input type="checkbox" checked={email_data.is_enabled} onClick={() => handleChange(email_data)}></input>
                                        </AnInputCheckBox>
                                    </td>
                                </TableRows>))}
                            </>) : 
                            (<LoaderComponent isLoading={false}  loaderStyle={{width: 32, margin: '36px auto', display: 'block'}}/>)
                        }
                    </tbody>
                </table>
                {   popupsList.addEmailTypePopup && addEmailTypePopup()  }
            </div>
    );
}

export default SystemCofigurationSettings;
