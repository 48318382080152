import { useCallback, useReducer, useState, useEffect } from "react";
import { ProspectListFiltersTabs } from "../../../../utils/AllFilterTabs";
import AnPopup from "../../../../cta_utils/AnPopup";
import { Button } from "../../../../cta_utils/AnButton";
import { AnInputCheckBox } from "../../../../input_utils/AnInputCheckBox";
import ConditionalRender from "../../../../../ConditionalRender";
import { FilterSuggestionBox } from "../../utils/FilterSuggestionBox";
import { prospectListFilterReducer } from "../reducer/prospectsFilterReducer";
import { AnInputBox } from "../../../../input_utils/AnInput";
import DatePicker, { Calendar } from "react-modern-calendar-datepicker";
import { checkValidDateString, dateFormator, datepicker_input_format } from "../../../../utils/dateUtils";
import AnDropDown from "../../../../cta_utils/AnDropDown";
import { AnInputRadioButton } from "../../../../input_utils/AnInputRadioButton";
import humanizeString from "../../../../utils/stringUtils";

export const ProspectBasedFilters = ({
    filtersState,
    filtersStateReducer,
    suggestionUrl = "/prospects/prospect_term_search",
    tempFilterReducer = prospectListFilterReducer,
    allFilterTabs,
    ...props
}) => {
    const [tempFiltersState, tempFiltersStateReducer] = useReducer(
        tempFilterReducer,
        { ...filtersState }
    );

    useEffect(() => {
        tempFiltersStateReducer({
            type: "SET_STATE",
            payload: {
                aggregatesData: filtersState.aggregatesData
            }
        })
    }, [filtersState.aggregatesData])

    let { aggregatesData, activeFilterTab } = tempFiltersState;
    let { filtersSelected } = tempFiltersState;
    const handleFilterCheckBoxChange = (e, filter_type) => {
        let val = e.target.value;
        let mappedKey = tempFiltersState.activeFilterTab.tab_key;
        let index = filtersSelected[mappedKey]?.indexOf(val);
        if (index === -1 || index === undefined) {
            // index -1 => yet to be added to array of selected filters
            // index 'undefined' => filter has no filters with 'mappedKey' because of custom fields.
            let new_val = [...(filtersSelected[mappedKey] || []), val];                        
            tempFiltersStateReducer({
                type: "SELECTED_FILTER",
                payload: { 
                    mappedKey: mappedKey, 
                    selections: new_val, 
                    selectedVal: val                    
                },
            });
        } else {
            let temp = [...filtersSelected[mappedKey]];
            temp.splice(index, 1);
            tempFiltersStateReducer({
                type: "SELECTED_FILTER",
                payload: { mappedKey: mappedKey, selections: temp },
            });
        }
    };

    const handleFilterApply = () => {
        filtersStateReducer({
            type: "APPLY_TEMP_FILTERS",
            payload: { 
                filtersSelected: tempFiltersState.filtersSelected , 
            },
        });
        handlePopupClose();
    };

    const handlePopupClose = () => {
        //do some compare logic to show maybe confirmation popup to discard changes in future
        filtersStateReducer({
            type: "SET_POPUP",
            payload: { showable: false },
        });
    };
    const handleFilterSubTabs = useCallback((e) => {
        e.stopPropagation();
        tempFiltersStateReducer({
            type: "SET_ACTIVE_FILTER_SUB_TAB",
            payload: { activeFilterTabKey: e.target.getAttribute("name"), allFilterTabs },
        });
    }, [allFilterTabs]);

    const renderActiveFilter = () => {
        if(activeFilterTab.tab_type === "sort_radio"){
            return <SortTypeFilters activeFilterTab={activeFilterTab} currentValue={filtersSelected[activeFilterTab.tab_key]} sortOptions={aggregatesData[activeFilterTab.tab_key]} filterReducer={tempFiltersStateReducer}/>
        }
        return (
            <>
                <div>
                    <ConditionalRender
                        if={tempFiltersState.activeFilterTab.search_bar}
                    >
                        <FilterSuggestionBox
                            suggestionUrl={suggestionUrl}
                            term={tempFiltersState.activeFilterTab}
                            style={{ marginBottom: 8, margintTop: "-6px" }}
                            filtersState={tempFiltersState}
                            filtersStateReducer={tempFiltersStateReducer}
                            placeholder={
                                tempFiltersState.activeFilterTab.search_bar
                                    ?.placeholder
                            }
                            allFilterTabs={allFilterTabs}
                            inputClass="an_search_box"
                        ></FilterSuggestionBox>
                    </ConditionalRender>
                    <div className="filter_aggregates_container" style={{gridTemplateColumns: "repeat(1, 1fr)", gridAutoRows: '0fr', height: 310, marginBottom: 8}}>
                        {aggregatesData[
                            tempFiltersState.activeFilterTab.tab_key
                        ]?.map((item, index) => {
                            let is_checked = filtersSelected[
                                tempFiltersState.activeFilterTab.tab_key
                            ]?.includes(item.term);
                            return (
                                <AnInputCheckBox
                                    key={item.term + index}
                                    label={`${humanizeString(item.term)} ${item.count ? ("(" + item.count + ")") : ""}`}
                                    labelStyle={{marginLeft: 8, textTransform: "capitalize"}}
                                    isChecked={is_checked}
                                    style={{ marginBottom: 16 }}
                                >
                                    <input
                                        checked={is_checked}
                                        type="checkbox"
                                        value={item.term}
                                        onChange={(e) => {
                                            handleFilterCheckBoxChange(
                                                e,
                                                tempFiltersState.activeFilterTab
                                                    .filter_field_mapping
                                            );
                                        }}
                                    />
                                </AnInputCheckBox>
                            );
                        })}
                        <ConditionalRender
                            if={
                                aggregatesData[
                                    tempFiltersState.activeFilterTab.tab_key
                                ]?.length == 0
                            }
                        >
                            <div className="text_body_light">
                                {tempFiltersState.activeFilterTab.empty_text}
                            </div>
                        </ConditionalRender>
                    </div>
                </div>
            </>
        );
    };

    const renderFiltersLeftPane = () => {
        return allFilterTabs.map((tab) => (
            <div
                key={tab.tab_key + tab.label}
                className={
                    tempFiltersState.activeFilterTab.tab_key == tab.tab_key
                        ? "text_subtitle"
                        : "text_subtitle_light"
                }
                style={{ cursor: "pointer", marginBottom: 20, position: "relative"}}
                name={tab.tab_key}
                onClick={handleFilterSubTabs}
            >
                <span name={tab.tab_key} style={{position: "relative"}}>
                    {tab.label}
                    <ConditionalRender if={tab.always_active}>
                        <div className="dot_mark" name={tab.tab_key} style={{position: "absolute", top: 3, right: "-8px"}}></div>
                    </ConditionalRender>
                </span>
            </div>
        ));
    };

    return (
        <AnPopup
            name="filtersPopup"
            handleOnClosePopup={handlePopupClose}
            maxWidth={640}
        >
            <div className="flex_container_space_between">
                <div className="text_title">Filters</div>
                <div
                    className="text_link"
                    onClick={() =>
                        tempFiltersStateReducer({
                            type: "CLEAR_SELECTED_FILTERS",
                            payload: {allFilterTabs}
                        })
                    }
                >
                    Clear filters
                </div>
            </div>
            <div
                className="line_seperator_ex_light_grey"
                style={{ marginTop: 16 }}
            ></div>
            <div
                className="default_flex_container"
                style={{
                    alignItems: "normal",
                    borderBottom: "1px solid #E7ECEB"
                }}
            >
                <div style={{ paddingTop: 24, paddingRight: 21, maxHeight: "400px", height: "100%", overflowX: "hidden", overflowY: "auto"}}>
                    {renderFiltersLeftPane()}
                </div>
                <div style={{ width: 1, background: "#E7ECEB" }}></div>
                <div style={{ marginLeft: 21, paddingTop: 24, width: "100%" }}>
                    {renderActiveFilter()}
                </div>
            </div>
            <div style={{ display: "flex", marginTop: 24 }}>
                <Button
                    buttonSize="Medium"
                    innerButtonStye={{ padding: "8px 30px" }}
                    onClick={handleFilterApply}
                    isLoading={false}
                >
                    Apply
                </Button>
                <Button
                    buttonSize="Medium"
                    type="Secondary"
                    buttonStyle={{ marginLeft: 20 }}
                    innerButtonStye={{ padding: "8px 28px" }}
                    onClick={handlePopupClose}
                    disabled={false}
                >
                    Cancel
                </Button>
            </div>
        </AnPopup>
    );
};


export const ProspectBasedDateFilter = ({
    filtersState,
    filtersStateReducer,
    tempFilterReducer,
    ...props
}) => {
    const [tempFiltersState, tempFiltersStateReducer] = useReducer(
        tempFilterReducer,
        { ...filtersState }
    );

    const [extraState, setExtraState] = useState({})

    const toggleDropDown = () => filtersStateReducer({
        type: "SET_POPUP",
        payload: { popup: "showDateFilterDropDown", showable: !filtersState.showDateFilterDropDown },
    })
    
    const applyDateRangefilters = (e) => {
        e.stopPropagation();
        let errors = {};
        if(!tempFiltersState.dateRanges.created_at.value.to || !tempFiltersState.dateRanges.created_at.value.from){
            errors.apply_filters = "Select complete date range";
        }
        setExtraState({...extraState, errors: errors});
        if(Object.keys(errors).length > 0) return;
        filtersStateReducer({
            type: "APPLY_TEMP_FILTERS",
            payload: { 
                filtersSelected: tempFiltersState.filtersSelected ,
                dateRanges: tempFiltersState.dateRanges 
            },
        });
        if(props.filter=="exportReport")
        props.exportReport(tempFiltersState);
        
        toggleDropDown();
    }
    const renderOptions = () => {
        let created_at_from = null;
        let created_at_to = null;
        let placeholderFrom = "1 June 2022";
        let placeholderTo = "30 June 2022";
        if(tempFiltersState.dateRanges.created_at.value.from)
            created_at_from = new Date(datepicker_input_format(tempFiltersState.dateRanges.created_at.value.from, "mm-dd-yyyy", "/")).toLocaleDateString("en-UK", 
            {year: 'numeric', month: 'long', day: 'numeric'});
        if(tempFiltersState.dateRanges.created_at.value.to){
            created_at_to = new Date(datepicker_input_format(tempFiltersState.dateRanges.created_at.value.to, "mm-dd-yyyy", "/")).toLocaleDateString("en-UK", 
            {year: 'numeric', month: 'long', day: 'numeric'});}
        return(
            <>
                <ConditionalRender if={props.filter=="exportReport"}>
                <div className="text_title">Download stats</div>
                <div  className="line_seperator_ex_light_grey" style={{ margin: "12px auto 16px" }}></div>  
                </ConditionalRender>
                <div className="text_body_light" style={{marginBottom: "6px"}}>
                    Selected range
                </div>
                <div className="default_flex_container text_body_big" 
                    style={{background: "#F3F4F5", padding: "9px 17px", gap: "4px", borderRadius: "4px"}}
                >
                    <span className={created_at_from ? "text_body" : "text_subtitle_light_grey_semi_bold"}>{created_at_from ? created_at_from : placeholderFrom} - </span>
                    <span className={created_at_to ? "text_body" : "text_subtitle_light_grey_semi_bold"}>{created_at_to ? created_at_to : placeholderTo}</span>
                </div>
                <div className="default_flex_container" style={{justifyContent: "center", marginTop: "16px"}}>
                    <Calendar
                        value={tempFiltersState.dateRanges.created_at.value}
                        onChange={(val) => {
                            tempFiltersStateReducer({
                                type: "UPDATE_DATE_RANGE",
                                payload:{
                                    key: "created_at",
                                    subkey: "value",
                                    value: val
                                }
                            })                            
                        }}   
                        calendarPopperPosition="bottom"  
                        calendarClassName='responsive-calendar'                                            
                        shouldHighlightWeekends
                        maximumDate={dateFormator(new Date(), {type:"datepicker_object"})}
                    >
                    </Calendar>
                </div>
                {props.filter=="exportReport" ? 
                 <div className="default_flex_container">
                 <Button
                     onClick={applyDateRangefilters}
                     buttonStyle={{width: "100%", marginTop: "16px"}}
                     error={extraState.errors?.apply_filters}                
                 >
                     Download
                 </Button>
                 </div>
                :
                <div className="default_flex_container">
                    <Button
                        onClick={applyDateRangefilters}
                        buttonStyle={{width: "50%", marginTop: "16px"}}
                        error={extraState.errors?.apply_filters}                
                    >
                        Apply
                    </Button>
                    <div
                        className="text_link"
                        style={{ marginTop: 15, marginLeft: 15}}
                        onClick={(e) => {
                                e.stopPropagation()
                                filtersStateReducer({
                                    type: "CLEAR_DATE_RANGES",
                                })
                                toggleDropDown();
                            }
                        }
                    >
                        Clear filter
                    </div>
                </div>
                }
            </>
        )
    }

    return(
        <AnDropDown style={{position: 'absolute', top: 40, right: 0, marginTop:"8px", padding: '20px', background: 'white', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', borderRadius: 4, width: 'max-content', zIndex: 99}}
            toggleDropDown={toggleDropDown}
        >
            {renderOptions()}
        </AnDropDown>
    )    
}



const SortTypeFilters = ({
    activeFilterTab,
    sortOptions=[],
    currentValue,
    onChangeCallback,
    ...props
}) => {
    return(
        <div>
            {
                sortOptions.map((item, index) => {
                    let is_checked = (item.value === currentValue[0]) || (currentValue.length === 0 && item.is_default);
                    return(
                        <AnInputRadioButton key={item.value} 
                            label={item.label}
                            style={{ marginBottom: 12 }}
                            isChosen={is_checked}
                        >
                            <input
                                type="radio"
                                style={{ width: "16px", height: "16px" }}
                                name="sort_option"
                                value={item.value}
                                checked={is_checked}
                                onChange={(e) => {
                                    props.filterReducer({
                                        type: "SELECTED_FILTER",
                                        payload: { 
                                            mappedKey: activeFilterTab.tab_key, 
                                            selections: [e.target.value], 
                                        },
                                    })
                                }}
                            />
                        </AnInputRadioButton>
                    )
                })
            }
        </div>
    )
}