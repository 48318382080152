import React from 'react'
import { Button } from '../../cta_utils/AnButton'
import Tick from "../../../assets/outbound/Logos/tick_black.png"
import BlueTick from "../../../assets/outbound/Logos/bluetick.png"
import BlueTickWhite from "../../../assets/outbound/Logos/blue_tick_whitebg.png"
import RightArrow from "../../../assets/outbound/Logos/rightarrow.png"
import {Link} from "react-router-dom"
import Bryant from "../../../assets/AlmaNews_landing_page/Trusted_by_the_best/Bryant.svg"
import Augsburg from "../../../assets/AlmaNews_landing_page/Trusted_by_the_best/Augsburg.svg"
import Carnegie from "../../../assets/AlmaNews_landing_page/Trusted_by_the_best/Carnegie.png"
import ColoradoCollege from "../../../assets/AlmaNews_landing_page/Trusted_by_the_best/ColoradoCollege.png"
import Marquette from "../../../assets/AlmaNews_landing_page/Trusted_by_the_best/Marquette.png"
import Pingry from "../../../assets/AlmaNews_landing_page/Trusted_by_the_best/PingrySchool.png"
import RoseHul from "../../../assets/AlmaNews_landing_page/Trusted_by_the_best/RoseHul.png"
import Ryerson from "../../../assets/AlmaNews_landing_page/Trusted_by_the_best/Ryerson.png"
import UniversityOfLiverpool from "../../../assets/AlmaNews_landing_page/Trusted_by_the_best/UniversityOfLiverpool.svg"
import Willamette from "../../../assets/AlmaNews_landing_page/Trusted_by_the_best/Willamette.svg"
import { isWidthDown } from '../../utils/designUtils'
import useWindowSize from '../../custom_hooks/useWindowSize'
import Collapsible from './Collapsible';

const FreePlan = () => {

    const windowDimensions = useWindowSize();

    const planBoxFP = () => {
        return (
            <div className='main_box default_flex_container'>
                <div className='plan_box_style'>
                    <div className="plan_box" style={{borderTop: "4px solid grey"}}>
                        <div className='text_title_big'> Free</div>
                        <div className='text_body_light' style={{margin: "10px 0px", fontSize: 16, lineHeight: "22px"}}>Access AlmaConnect News’ features. No credit card required.</div>
                        <a href="/subscribe">
                            <button className="feature_btn1">Get Started</button>
                        </a>
                        <div className='text_body_light'>  
                            <div className='list_item'> <img src={Tick} style={{maxWidth: 16, marginRight: 12}}></img>  Upto 10,000 prospects tracked</div>
                            <div className='list_item'> <img src={Tick} style={{maxWidth: 16, marginRight: 12}}></img>  Upto 5 news results per email</div>
                            <div className='list_item'> <img src={Tick} style={{maxWidth: 16, marginRight: 12}}></img>  Unlimited team members</div>
                        </div>
                        <div className='text_body' style={{margin: "58px 0px 36px", fontSize: "16px", fontWeight: 700, lineHeight: "22px"}}><a href="/learn_more"> Learn more about the free product<img src={RightArrow} style={{maxWidth: 30, marginLeft: 10}}></img> </a></div>
                    </div>
                </div>
                <div className='plan_box_style'>
                    <div className="plan_box" style={{border: "1px solid #437EFE", borderTop: "4px solid #437EFE"}}>
                        <div className='text_title_big' style={{color: "#437EFE"}}> Premium</div>
                        <div className='text_body_light' style={{margin: "10px 0px", fontSize: 16, lineHeight: "22px"}}>Access AlmaConnect News’ features. Get your quote</div>
                        <a href="https://calendly.com/almaconnect-news-scanner/custom-demo" target="_blank">
                            <button className='feature_btn2'>Get a demo</button>   
                        </a>
                        <div className='text_body_light'> 
                            <div className='list_item'> <img src={Tick} style={{maxWidth: 16, marginRight: 12}}></img>  Unlimited prospects tracked</div>
                            <div className='list_item'> <img src={Tick} style={{maxWidth: 16, marginRight: 12}}></img>  Unlimited news results</div>
                            <div className='list_item'> <img src={Tick} style={{maxWidth: 16, marginRight: 12}}></img>  Smart Notifications </div>
                            <div className='list_item'> <img src={Tick} style={{maxWidth: 16, marginRight: 12}}></img>  Platform accessibility</div>
                            <div className='list_item'> <img src={Tick} style={{maxWidth: 16, marginRight: 12}}></img>  Support</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const planBoxMobile = () => {
        return (
            <div className='main_box'>
                <div className='plan_box_style' style={{margin: "auto"}}>
                    <div className="plan_box" style={{borderTop: "4px solid grey", padding: "30px 22px"}}>
                        <div className='text_title_big' style={{fontSize: 24, lineHeight: "32px"}}> Free</div>
                        <div className='text_body_light' style={{margin: "10px 0px", fontSize: 16, lineHeight: "22px", fontWeight: 400}}>Access AlmaConnect News’ features. No credit card required.</div>
                        <a href="/subscribe">
                            <button className="feature_btn1">Get Started</button>
                        </a>
                        <div className='text_body_light'>  
                            <div className='list_item'> <img src={Tick} style={{maxWidth: 16, marginRight: 12}}></img>  Upto 10,000 prospects tracked</div>
                            <div className='list_item'> <img src={Tick} style={{maxWidth: 16, marginRight: 12}}></img>  Upto 5 news results per email</div>
                            <div className='list_item'> <img src={Tick} style={{maxWidth: 16, marginRight: 12}}></img>  Unlimited team members</div>
                        </div>
                        <div className='text_body' style={{margin: "58px 0px 36px", fontSize: "16px", fontWeight: 700, lineHeight: "22px"}}><a href="/learn_more"> Learn more about the free product<img src={RightArrow}></img> </a></div>
                    </div>
                </div>
                <div className='plan_box_style' style={{margin: "30px auto"}}>
                    <div className="plan_box" style={{border: "1px solid #437EFE", borderTop: "4px solid #437EFE", padding: "30px 22px"}}>
                        <div className='text_title_big' style={{color: "#437EFE", fontSize: 24, lineHeight: "32px"}}> Premium</div>
                        <div className='text_body_light' style={{margin: "10px 0px", fontSize: 16, lineHeight: "22px", fontWeight: 400}}>Access AlmaConnect News’ features. Get your quote</div>
                        <a href="https://calendly.com/almaconnect-news-scanner/custom-demo" target="_blank">
                            <button className="feature_btn2">Get a demo</button>
                        </a>
                        <div className='text_body_light'> 
                            <div className='list_item'> <img src={Tick} style={{maxWidth: 16, marginRight: 12}}></img>  Unlimited prospects tracked</div>
                            <div className='list_item'> <img src={Tick} style={{maxWidth: 16, marginRight: 12}}></img>  Unlimited news results</div>
                            <div className='list_item'> <img src={Tick} style={{maxWidth: 16, marginRight: 12}}></img>  Smart Notifications </div>
                            <div className='list_item'> <img src={Tick} style={{maxWidth: 16, marginRight: 12}}></img>  Platform accessibility</div>
                            <div className='list_item'> <img src={Tick} style={{maxWidth: 16, marginRight: 12}}></img>  Support</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const section1FP = () => {
        return (
            <section className='section_1'>
                <div className='content_container'>
                    <div className="banner_box">
                        <div className='text_box'>
                            <div className='heading_info'>
                               PLANS
                            </div>
                            <div className="heading" style={{fontWeight: "600"}}>
                                Ready to get started? 
                            </div>
                            <div style={{paddingTop: "17px", fontSize: "18px", lineHeight: "24px", fontWeight: 400, color: "#646F79"}}>
                               Choose your plan
                            </div>
                        </div>
                    </div>
                    {isWidthDown(windowDimensions.width, "md") ? planBoxMobile() : planBoxFP()}
                    {isWidthDown(windowDimensions.width, "md") ? compareBoxMobile() : compareBoxFP()}
                </div>
            </section>
        )
    }

    const compareBoxFP = () => {
        return (
            <div className="compare_box">
                <Collapsible label="Compare features across our Free and Premium plans">
                    <div className='flex_container_space_between' style={{alignItems: "flex-start", marginTop: 42}}>
                        <div className="features_column">
                            <div className='text_title'>Overview</div> 
                            <hr style={{width: "75%", border: "1px solid grey", margin: "4px 0px"}}/>
                            <div className='feature_list'>Daily News alerts </div>
                            <div className='feature_list'>Unlimited Team members</div>
                            <div className='feature_list'>Uses public information to fetch news results </div>
                            <div className='feature_list'>Unlimited prospects tracked</div>
                            <div className='feature_list'>Personalized Email digest</div>
                            <div className='feature_list'>View Similar articles</div>
                            <div className='feature_list'>Search News Sources</div>
                            <div className='feature_list'>Platform Accessibility</div>
                            <div className='text_title' style={{marginTop: 56}}>NewsFeed</div>
                            <hr style={{width: "75%", border: "1px solid grey", margin: "4px 0px"}}/>
                            <div className='feature_list'>Personalized feed</div>
                            <div className='feature_list'>Tags & Filters</div>
                            <div className='feature_list'>Search News results</div>
                            <div className='feature_list'>Share via Email</div>
                            <div className='feature_list'>Comments</div>
                            <div className='text_title' style={{marginTop: 58}}>Prospect Management</div>
                            <hr style={{width: "75%", border: "1px solid grey", margin: "4px 0px"}}/>
                            <div className='feature_list'> Import prospects in bulk</div>
                            <div className='feature_list'>Add Tags</div>
                            <div className='feature_list'> Assign Team members</div>
                            <div className='feature_list'>Prospect Profile </div>
                            <div className='feature_list'>Archive Prospects</div>
                            <div className='feature_list'>Data Export</div>

                            <div className='text_title' style={{marginTop: 58}}>Team Management</div>
                            <hr style={{width: "75%", border: "1px solid grey", margin: "4px 0px"}}/>
                            <div className='feature_list'>Invite Team members</div>
                            <div className='feature_list'>Manage Team Notifications</div>
                            <div className='feature_list'>Custom Fields</div>
                            <div className='feature_list'>Team Settings</div> 

                            <div className='text_title' style={{marginTop: 56}}>Support</div>
                            <hr style={{width: "50%", border: "1px solid grey", margin: "5px 0px"}}/>
                            <div className='feature_list' >Onboarding and support</div>
                            <div className='feature_list'>Data Deletion</div>
                            <div className='feature_list'>Dedicated Account Manager</div>
                        </div>
                        <div className='flex_container_space_between'>
                            <div className='tick_column' style={{border: "1px solid grey"}}> 
                                <div className='text_title'>Free</div> 
                                <hr style={{width: "100%"}} />
                                <div className='text_body_light first_tick'><img src={Tick} style={{maxWidth: 19}}></img></div>
                                <div className='tick_mark'><img src={Tick} style={{maxWidth: 19}}></img></div>
                                <div className='tick_mark'><img src={Tick} style={{maxWidth: 19}}></img></div>
                                <hr style={{width: "100%", marginTop: "1384px"}} />
                                <a href="/subscribe">
                                    <button className="feature_btn1 compare_btn" style={{padding: "12px", margin: "auto"}}>Get Started</button>
                                </a>
                            </div>
                            <div className='tick_column' style={{border: "1px solid #437EFE", backgroundColor: "rgba(67, 126, 254, 0.05)"}}>
                                <div className='text_title'>Premium</div> 
                                <hr style={{width: "100%"}} />
                                <div className='text_body_light first_tick'><img src={BlueTick} style={{maxWidth: 20}}></img></div>
                                <div className='tick_mark'><img src={BlueTick} style={{maxWidth: 20}}></img></div>
                                <div className='tick_mark'><img src={BlueTick} style={{maxWidth: 20}}></img></div>
                                <div className='tick_mark'><img src={BlueTick} style={{maxWidth: 20}}></img></div>
                                <div className='tick_mark'><img src={BlueTick} style={{maxWidth: 20}}></img></div>
                                <div className='tick_mark'><img src={BlueTick} style={{maxWidth: 20}}></img></div>
                                <div className='tick_mark'><img src={BlueTick} style={{maxWidth: 20}}></img></div>
                                <div className='tick_mark'><img src={BlueTick} style={{maxWidth: 20}}></img></div>
                                <div className='tick_mark'style={{marginTop: 111}}><img src={BlueTick} style={{maxWidth: 20}}></img></div>
                                <div className='tick_mark'><img src={BlueTick} style={{maxWidth: 20}}></img></div>
                                <div className='tick_mark'><img src={BlueTick} style={{maxWidth: 20}}></img></div>
                                <div className='tick_mark'><img src={BlueTick} style={{maxWidth: 20}}></img></div>
                                <div className='tick_mark'><img src={BlueTick} style={{maxWidth: 20}}></img></div>
                                <div className='tick_mark'style={{marginTop: 112}}><img src={BlueTick} style={{maxWidth: 20}}></img></div>
                                <div className='tick_mark'><img src={BlueTick} style={{maxWidth: 20}}></img></div>
                                <div className='tick_mark'><img src={BlueTick} style={{maxWidth: 20}}></img></div>
                                <div className='tick_mark'><img src={BlueTick} style={{maxWidth: 20}}></img></div>
                                <div className='tick_mark'><img src={BlueTick} style={{maxWidth: 20}}></img></div>
                                <div className='tick_mark'><img src={BlueTick} style={{maxWidth: 20}}></img></div>
                                <div className='tick_mark'style={{marginTop: 112}}><img src={BlueTick} style={{maxWidth: 20}}></img></div>
                                <div className='tick_mark'><img src={BlueTick} style={{maxWidth: 20}}></img></div>
                                <div className='tick_mark'><img src={BlueTick} style={{maxWidth: 20}}></img></div>
                                <div className='tick_mark'><img src={BlueTick} style={{maxWidth: 20}}></img></div>
                                <div className='tick_mark'style={{marginTop: 112}}><img src={BlueTick} style={{maxWidth: 20}}></img></div>
                                <div className='tick_mark'><img src={BlueTick} style={{maxWidth: 20}}></img></div>
                                <div className='tick_mark'><img src={BlueTick} style={{maxWidth: 20}}></img></div>
                                <hr style={{width: "100%"}} />
                                <a href="https://calendly.com/almaconnect-news-scanner/custom-demo" target="_blank">
                                    <button className="feature_btn2 compare_btn" style={{padding: "12px", margin: "auto"}}>Get a demo</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </Collapsible>
            </div>
        )
    }

    const compareBoxMobile = () => {
        return (
            <div>
                <div style={{padding: "0px 15px", marginTop: 64, fontWeight: 700, fontSize: 18, lineHeight: "24px"}}>Compare features across our Free and Premium plans</div>
                <div className="compare_box" style={{padding: "0px 20px", margin: "20px auto"}}>
                    <div className="grid-container">
                        <div className="grid-item text_title"></div>
                        <div className="grid-item text_title" style={{textAlign: "center", lineHeight: "20px", fontWeight: 700}}>Free</div>
                        <div className="grid-item text_title" style={{color: "#437EFE", textAlign: "center", lineHeight: "20px", fontWeight: 700}}>Premium</div>
                    </div>
                    <div className='grid-container' style={{padding: 0}}>
                        <hr style={{width: "100%", border: "1px solid white", margin: 0}}/>
                        <hr style={{width: "100%", border: "1px solid grey", margin: 0}}/>
                        <hr style={{width: "100%", border: "1px solid #0151fe", margin: 0}}/>
                    </div>
                    <div style={{textAlign: "left", paddingTop: "11px", fontWeight: 700, fontSize: 14, lineHeight: "20px"}}> Overview</div>
                    <hr style={{width: "100%", border: "1px solid #282A2C", margin: "11px 0px", opacity: 0.75}}/>
                    <div className="grid-container">
                        <div className='grid-item text_body_big_light' style={{textAlign: "left"}}> Daily News alerts </div>
                        <div className='grid-item text_body_big_light'><img src={Tick} style={{maxWidth: 18}}></img></div>
                        <div className='grid-item text_body_big_light'><img src={BlueTickWhite} style={{maxWidth: 20}}></img></div>
                    </div>
                    <div className="grid-container">
                        <div className='grid-item text_body_big_light' style={{textAlign: "left"}}> Unlimited Team members </div>
                        <div className='grid-item text_body_big_light'><img src={Tick} style={{maxWidth: 18}}></img></div>
                        <div className='grid-item text_body_big_light'><img src={BlueTickWhite} style={{maxWidth: 20}}></img></div>
                    </div>
                    <div className="grid-container">
                        <div className="grid-item text_body_big_light" style={{textAlign: "left"}}> Uses public information to fetch news results  </div>
                        <div className='grid-item text_body_big_light'><img src={Tick} style={{maxWidth: 18}}></img></div>
                        <div className='grid-item text_body_big_light'><img src={BlueTickWhite} style={{maxWidth: 20}}></img></div>
                    </div>
                    <div className="grid-container">
                        <div className="grid-item text_body_big_light" style={{textAlign: "left"}}> Unlimited prospects tracked </div>
                        <div className='grid-item text_body_big_light'></div>
                        <div className='grid-item text_body_big_light'><img src={BlueTickWhite} style={{maxWidth: 20}}></img></div>
                    </div>
                    <div className="grid-container">
                        <div className="grid-item text_body_big_light" style={{textAlign: "left"}}> Personalized Email digest </div>
                        <div className='grid-item text_body_big_light'></div>
                        <div className='grid-item text_body_big_light'><img src={BlueTickWhite} style={{maxWidth: 20}}></img></div>
                    </div>
                    <div className="grid-container">
                        <div className="grid-item text_body_big_light" style={{textAlign: "left"}}> View Similar articles </div>
                        <div className='grid-item text_body_big_light'></div>
                        <div className='grid-item text_body_big_light'><img src={BlueTickWhite} style={{maxWidth: 20}}></img></div>
                    </div>
                    <div className="grid-container">
                        <div className="grid-item text_body_big_light" style={{textAlign: "left"}}> Search News Sources </div>
                        <div className='grid-item text_body_big_light'></div>
                        <div className='grid-item text_body_big_light'><img src={BlueTickWhite} style={{maxWidth: 20}}></img></div>
                    </div>
                    <div className="grid-container">
                        <div className="grid-item text_body_big_light" style={{textAlign: "left"}}> Platform Accessibility </div>
                        <div className='grid-item text_body_big_light'></div>
                        <div className='grid-item text_body_big_light'><img src={BlueTickWhite} style={{maxWidth: 20}}></img></div>
                    </div>

                    <div style={{textAlign: "left", paddingTop: "20px", fontWeight: 700, fontSize: 14, lineHeight: "20px"}}> NewsFeed</div>
                    <hr style={{width: "100%", border: "1px solid #282A2C", margin: "10px 0px", opacity: 0.75}}/>
                    <div className="grid-container">
                        <div className="grid-item text_body_big_light" style={{textAlign: "left"}}> Personalized feed</div>
                        <div className='grid-item text_body_big_light'></div>
                        <div className='grid-item text_body_big_light'><img src={BlueTickWhite} style={{maxWidth: 20}}></img></div>
                    </div>
                    <div className="grid-container">
                        <div className="grid-item text_body_big_light" style={{textAlign: "left"}}> Tags & Filters</div>
                        <div className='grid-item text_body_big_light'></div>
                        <div className='grid-item text_body_big_light'><img src={BlueTickWhite} style={{maxWidth: 20}}></img></div>
                    </div>
                    <div className="grid-container">
                        <div className="grid-item text_body_big_light" style={{textAlign: "left"}}> Search News results </div>
                        <div className='grid-item text_body_big_light'></div>
                        <div className='grid-item text_body_big_light'><img src={BlueTickWhite} style={{maxWidth: 20}}></img></div>
                    </div>
                    <div className="grid-container">
                        <div className="grid-item text_body_big_light" style={{textAlign: "left"}}> Share via Email </div>
                        <div className='grid-item text_body_big_light'></div>
                        <div className='grid-item text_body_big_light'><img src={BlueTickWhite} style={{maxWidth: 20}}></img></div>
                    </div>
                    <div className="grid-container">
                        <div className="grid-item text_body_big_light" style={{textAlign: "left"}}> Comments</div>
                        <div className='grid-item text_body_big_light'></div>
                        <div className='grid-item text_body_big_light'><img src={BlueTickWhite} style={{maxWidth: 20}}></img></div>
                    </div>

                    <div style={{textAlign: "left", paddingTop: "20px", fontWeight: 700, fontSize: 14, lineHeight: "20px"}}> Prospect Management</div>
                    <hr style={{width: "100%", border: "1px solid #282A2C", margin: "10px 0px", opacity: 0.75}}/>
                    <div className="grid-container">
                        <div className="grid-item text_body_big_light" style={{textAlign: "left"}}> Import prospects in bulk </div>
                        <div className='grid-item text_body_big_light'></div>
                        <div className='grid-item text_body_big_light'><img src={BlueTickWhite} style={{maxWidth: 20}}></img></div>
                    </div>
                    <div className="grid-container">
                        <div className="grid-item text_body_big_light" style={{textAlign: "left"}}> Add Tags </div>
                        <div className='grid-item text_body_big_light'></div>
                        <div className='grid-item text_body_big_light'><img src={BlueTickWhite} style={{maxWidth: 20}}></img></div>
                    </div>
                    <div className="grid-container">
                        <div className="grid-item text_body_big_light" style={{textAlign: "left"}}> Assign Team members </div>
                        <div className='grid-item text_body_big_light'></div>
                        <div className='grid-item text_body_big_light'><img src={BlueTickWhite} style={{maxWidth: 20}}></img></div>
                    </div>
                    <div className="grid-container">
                        <div className="grid-item text_body_big_light" style={{textAlign: "left"}}> Prospect Profile </div>
                        <div className='grid-item text_body_big_light'></div>
                        <div className='grid-item text_body_big_light'><img src={BlueTickWhite} style={{maxWidth: 20}}></img></div>
                    </div>
                    <div className="grid-container">
                        <div className="grid-item text_body_big_light" style={{textAlign: "left"}}> Archive Prospects </div>
                        <div className='grid-item text_body_big_light'></div>
                        <div className='grid-item text_body_big_light'><img src={BlueTickWhite} style={{maxWidth: 20}}></img></div>
                    </div>
                    <div className="grid-container">
                        <div className="grid-item text_body_big_light" style={{textAlign: "left"}}> Data Export  </div>
                        <div className='grid-item text_body_big_light'></div>
                        <div className='grid-item text_body_big_light'><img src={BlueTickWhite} style={{maxWidth: 20}}></img></div>
                    </div>

                    <div style={{textAlign: "left", paddingTop: "20px", fontWeight: 700, fontSize: 14, lineHeight: "20px"}}> Team Management</div>
                    <hr style={{width: "100%", border: "1px solid #282A2C", margin: "10px 0px", opacity: 0.75}}/>
                    <div className="grid-container">
                        <div className="grid-item text_body_big_light" style={{textAlign: "left"}}> Invite Team members </div>
                        <div className='grid-item text_body_big_light'></div>
                        <div className='grid-item text_body_big_light'><img src={BlueTickWhite} style={{maxWidth: 20}}></img></div>
                    </div>
                    <div className="grid-container">
                        <div className="grid-item text_body_big_light" style={{textAlign: "left"}}> Manage Team Notifications </div>
                        <div className='grid-item text_body_big_light'></div>
                        <div className='grid-item text_body_big_light'><img src={BlueTickWhite} style={{maxWidth: 20}}></img></div>
                    </div>
                    <div className="grid-container">
                        <div className="grid-item text_body_big_light" style={{textAlign: "left"}}> Custom Fields</div>
                        <div className='grid-item text_body_big_light'></div>
                        <div className='grid-item text_body_big_light'><img src={BlueTickWhite} style={{maxWidth: 20}}></img></div>
                    </div>
                    <div className="grid-container">
                        <div className="grid-item text_body_big_light" style={{textAlign: "left"}}> Team Settings </div>
                        <div className='grid-item text_body_big_light'></div>
                        <div className='grid-item text_body_big_light'><img src={BlueTickWhite} style={{maxWidth: 20}}></img></div>
                    </div>

                    <div style={{textAlign: "left", paddingTop: "20px", fontWeight: 700, fontSize: 14, lineHeight: "20px"}}> Support </div>
                    <hr style={{width: "100%", border: "1px solid #282A2C", margin: "10px 0px", opacity: 0.75}}/>
                    <div className="grid-container">
                        <div className="grid-item text_body_big_light" style={{textAlign: "left"}}> Onboarding and support</div>
                        <div className='grid-item text_body_big_light'></div>
                        <div className='grid-item text_body_big_light'><img src={BlueTickWhite} style={{maxWidth: 20}}></img></div>
                    </div>
                    <div className="grid-container">
                        <div className="grid-item text_body_big_light" style={{textAlign: "left"}}> Data Deletion</div>
                        <div className='grid-item text_body_big_light'></div>
                        <div className='grid-item text_body_big_light'><img src={BlueTickWhite} style={{maxWidth: 20}}></img></div>
                    </div>
                    <div className="grid-container">
                        <div className="grid-item text_body_big_light" style={{textAlign: "left"}}> Dedicated Account Manager</div>
                        <div className='grid-item text_body_big_light'></div>
                        <div className='grid-item text_body_big_light'><img src={BlueTickWhite} style={{maxWidth: 20}}></img></div>
                    </div>

                    <hr style={{width: "100%", border: "1px solid #282A2C", margin: "10px 0px", opacity: 0.75}}/>
                    <div className="grid-container">
                        <div className='grid-item text_body_big_light'></div>
                        <div className='grid-item text_body' style={{fontSize: 14, margin: "auto"}}><a href="/subscribe">Get Started</a></div>
                        <div className='grid-item text_bosy_light' style={{fontSize: 14}}><a href="https://calendly.com/almaconnect-news-scanner/custom-demo" style={{color: "#437EFE"}}>Get a demo</a></div>
                    </div>
                </div>
            </div>
        )
    }

    const section2FP = () => {
        return(
            <>
                <div className='logos_first_line'>
                    <div className='logo_pill'>
                        <img src={Bryant} style={{width: 138, height: 44}}/>
                    </div>
                    <div className='logo_pill'>
                        <img src={Marquette} style={{width: 130, height: 29}}/>
                    </div>
                    <div className='logo_pill'>
                        <img src={Ryerson} style={{width: 100, height: 44}}/>
                    </div>
                    <div className='logo_pill'>
                        <img src={UniversityOfLiverpool} style={{width: 129, height: 32}}/>
                    </div>
                    <div className='logo_pill'>
                        <img src={Willamette} style={{width: 135, height: 33}}/>
                    </div>
                </div>
                <div className='logos_first_line' style={{marginTop: 20}}>
                    <div className='logo_pill'>
                        <img src={Carnegie} style={{width: 74, height: 49, margin: "auto 25px"}}/>
                    </div>
                    <div className='logo_pill'>
                        <img src={RoseHul} style={{width: 147, height: 23}}/>
                    </div>
                    <div className='logo_pill'>
                        <img src={Augsburg} style={{width: 108, height: 31}}/>
                    </div>
                    <div className='logo_pill'>
                        <img src={Pingry} style={{width: 127, height: 32}}/>
                    </div>
                    <div className='logo_pill'>
                        <img src={ColoradoCollege} style={{width: 61, height: 55, margin: "auto 25px"}}/>
                    </div>
                </div>
            </>
        )
    }

    const section2Mobile = () => {
        return(
            <>
                <div className='logos_first_line'>
                    <div className='logo_pill'>
                        <img src={Bryant} style={{width: 138, height: 44}}/>
                    </div>
                    <div className='logo_pill'>
                        <img src={Marquette} style={{width: 138, height: 29}}/>
                    </div>
                    <div className='logo_pill'>
                        <img src={Ryerson} style={{width: 100, height: 44}}/>
                    </div>
                    <div className='logo_pill'>
                        <img src={UniversityOfLiverpool} style={{width: 129, height: 32}}/>
                    </div>
                    <div className='logo_pill'>
                        <img src={Willamette} style={{width: 135, height: 33}}/>
                    </div>
                    <div className='logo_pill'>
                        <img src={Carnegie} style={{width: 74, height: 49}}/>
                    </div>
                </div>
                <div className='logos_first_line' style={{marginTop: 20}}>
                    <div className='logo_pill'>
                        <img src={RoseHul} style={{width: 147, height: 23}}/>
                    </div>
                    <div className='logo_pill'>
                        <img src={Augsburg} style={{width: 108, height: 31}}/>
                    </div>
                    <div className='logo_pill'>
                        <img src={Pingry} style={{width: 127, height: 32}}/>
                    </div>
                    <div className='logo_pill'>
                        <img src={ColoradoCollege} style={{width: 61, height: 55}}/>
                    </div>
                </div>
            </>
        )
    }

    

    return (
        <div className="plans_container">
            <section >
                {section1FP()}
            </section>
            <section className='section_2'>
            <div className='content_container'>
                <div className='who_uses'>
                    <div className='text_title_big' style={{textAlign: 'center', marginBottom: 32}}>
                        Trusted by the world’s best
                    </div>
                    {isWidthDown(windowDimensions.width, "lg") ? section2Mobile() : section2FP()}
                </div>
            </div>
        </section>
        </div>
    )
}

export default FreePlan;