import React, { useCallback, useEffect, useState } from 'react'
import { Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';

import './scss/App.scss';

import LoginScreen from './LoginScreen';
import {apiAgent} from './axiosConfig';
import ConditionalRender from './ConditionalRender';
import LoggedinHeader, {PartialLoggedinHeader} from './components/headers/loginHeader.js'
import { connect, useDispatch, useSelector } from 'react-redux'
import { updateCurrentUser, userLogOut } from './redux/currentUserSlice.js'
import { updateCurrentTeam } from './redux/currentTeamSlice.js'
import routes from './routes/Routes.js'
import RouteRenderer from './routes/RouteRenderer.js'
import LandingPage from './components/views/landing/LandingPage';
import SignupScreen from './signupScreen';
import LoggedoutHeader from './components/headers/logoutHeader';
import SwitchAccounts from './components/views/switch_accounts/SwitchAccounts';
import { fetchCurrentUser } from './actions/userSliceActions/actions';
import { current } from 'immer';
import CompleteProfile from './completeProfile';
import { fetchCurrentTeam } from './actions/teamSliceActions/actions';
import { LoaderComponent } from './components/utils/LoaderComponent';
import { COOKIE_SETTINGS } from './config/cookiesSettings';
import { InviteUserSignup } from './components/views/invitations/InviteUserSignup';
import { InviteJoinTeam } from './components/views/invitations/InviteJoinTeam';
import ForgotPassword from './components/views/password_screens/ForgotPassword';
import ResetPassword from './components/views/password_screens/ResetPassword';
import ConfirmMail from './PreAuthRender/ConfirmMail';
import ConfirmSuccess from './PreAuthRender/ConfirmMail/ConfirmSuccess';
import PostAuthMobileFooter from './components/footer/PostAuthMobileFooter';
import { isWidthDown } from './components/utils/designUtils';
import useWindowSize from './components/custom_hooks/useWindowSize';
import SubscribePage from './components/views/subscribe_unsubscribe/SubscribePage';
import Subscribed from './components/views/subscribe_unsubscribe/Subscribed';
import Unsubscribe from './components/views/subscribe_unsubscribe/Unsubscribe';
import YouWillBeMissed from './components/views/subscribe_unsubscribe/YouWillBeMissed';
import ErrorPage from './components/views/errors/ErrorPage';
import LearnMore from './components/views/subscribe_unsubscribe/LearnMore';
import AlmaNewsLanding from './components/landing_pages/alma_news_landing';
import Features from './components/views/subscribe_unsubscribe/Features';
import FreePlan from './components/views/subscribe_unsubscribe/FreePlan';
import ContactUs from './components/views/subscribe_unsubscribe/ContactUs';
import PremiumPlan from './components/views/subscribe_unsubscribe/PremiumPlan';
import NewsLandingHeader from './components/headers/NewsLandingHeader';
import NewsLandingFooter from './components/footer/NewsLandingFooter';
import AlmaDataMine from './components/landing_pages/alma_datamine';
import DataMineHeader from './components/headers/DataMineHeader';
import { dataminePrivacyPolicy } from './components/views/landing/dataminePrivacyPolicy';
import RecordingAnalytics from './RecordingAnalytics.js';
import EventReg from './components/views/events/event_reg';
import ThankYou from './components/views/events/thank_you';
import PipeDriveApp from './components/views/subscribe_unsubscribe/pipe_drive_app.js';
import ComponentErrorBoundary from "./BugsnagErrorReporting.jsx";
import GetPremium from './components/views/subscribe_unsubscribe/GetPremium.jsx';
import { PublicDataImport, ShareDataImport } from './components/views/public_pages/_ShareDataImport.jsx';
import { WhatsNew } from './components/views/public_pages/whats_new/_WhatsNew.jsx';
import PreSubscribe from './components/views/subscribe_unsubscribe/PreSubscribe.jsx';
import PipeDriveSignup from './components/views/pipedrive/PipeDriveSignup.jsx';

const landing_urls = ["/", "/learn_more", "/features", "/plans", "/premium", "/contact_us", "/get_premium"]
const datamine_landing_urls = ["/data_mine", "/data_mine/privacy_policy"]
const landing_url_ex_footer = ["/subscribe"]
const apra_landing_urls = ["/apra-form", "/thank_you", "/import_data"]
const pipedrive_landing_urls = ["/pipedrive-signup", "/pipedrive-permission", "/pipedrive-installation"]

export const App = (props) => {
  const _dispatch = useDispatch()
  const current_user = useSelector(state => state.current_user.user)
  const apiKeyStatus = useSelector(state => state.current_user.apiKeyStatus)
  const current_team = useSelector(state => state.current_team)
  const [hasLoggedOut, _setHasLoggedOut] = useState(false)
  const _history = useHistory()
  const location = useLocation()
  const windowDimensions = useWindowSize();

  let cookies = new Cookies()
  let api_key = cookies.get('api_key');

  useEffect(() => {
    validateUser()
  },[_dispatch])

  useEffect(() => {
    if(hasLoggedOut && !current_user.id){
      _setHasLoggedOut(false)
      _history.push('/login')
    }
    else if(current_user.id && !current_user.has_set_password){
      _history.push("/complete_profile")
    }
  }, [hasLoggedOut, current_user, _history])

  const validateUser = () => {
    if(!api_key){
      _dispatch(userLogOut())
      return
    }
    _dispatch(fetchCurrentUser())
    if(isPipeDriveLandingUrl()) return;
    setCurrentTeam() //Improve checking at this point it does not mean user is validated. Maybe put in useEffect
  }
  const setCurrentTeam = () => {
    let team_id = cookies.get('team_id')
    if(!team_id) return
    _dispatch(fetchCurrentTeam(team_id))
  }
  const onLoginSuccess = (response) => {
    const cookies = new Cookies();
    cookies.set('api_key', response.api_key, {path: '/', maxAge: COOKIE_SETTINGS['AUTH_DURATION']});
    let paramsString = window.location.search;
    let searchParams = new URLSearchParams(paramsString);
    const redirect_uri = searchParams.get('redirect_uri')
    const team_id = searchParams.get('team_id')
    if(team_id) cookies.set('team_id', team_id, {path: '/', maxAge: COOKIE_SETTINGS['AUTH_DURATION']});
    if(redirect_uri)
      window.location = redirect_uri;
    else
      _dispatch(updateCurrentUser(response))
  };

  const onSignupSucess = (response, redirect_path="/complete_profile") => {
    const cookies = new Cookies();
    cookies.set('api_key', response.api_key, {path: '/', maxAge: COOKIE_SETTINGS['AUTH_DURATION']});
    _dispatch(updateCurrentUser(response))
    _history.push(redirect_path)
  };

  const logout = () => {
    const cookies = new Cookies();
    cookies.remove('api_key', {path: '/'});
    cookies.remove('team_id', {path: '/'});
    _setHasLoggedOut(true)
    _dispatch(userLogOut())
    _dispatch(updateCurrentTeam({}))
  }

  const isLoggedIn = () => {
    return apiKeyStatus == 'validated'
  }

  const isLandingUrlExFooter = () => {
    return landing_url_ex_footer.includes(location.pathname)
  }
  const isLandingUrl = () => {
    return landing_urls.includes(location.pathname)
  }
  const isDatamineLandingUrl = () => {
    return datamine_landing_urls.includes(location.pathname)
  }
  const isApraLandingUrl = () => {
    return apra_landing_urls.includes(location.pathname)
  }
  const isPipeDriveLandingUrl = () => {
      return pipedrive_landing_urls.includes(location.pathname)
  }

  const renderAppHeader = () => {
    if(isDatamineLandingUrl()) return <DataMineHeader />;
    if(isLandingUrl()) return <NewsLandingHeader />;
    if(!isLoggedIn() || isApraLandingUrl() || isPipeDriveLandingUrl()) return <LoggedoutHeader alma_headers_urls={apra_landing_urls}/>;
    if(isLoggedIn() && !!current_team.team?.approved_at) return <LoggedinHeader logout={logout}/>  //make logouts callbacks to avoid re rendering
    if(isLoggedIn() && (!current_team.team?.id || (current_team.team.id && !current_team.team.approved_at))) return <PartialLoggedinHeader logout={logout} />
  }

  const renderAppFooter = () => {
    if(isLoggedIn() && !!current_team.team?.approved_at) return <PostAuthMobileFooter logout={logout}/>
  }

  const PreAuthRender = () => {    
    return(
      <div>
        <Switch>
          <RouteRenderer exact={true} path="/login" component={LoginScreen} 
            props={{onLoginSuccess: onLoginSuccess}}  layout={"pre_auth_layout"}/>
          <RouteRenderer exact={true} path="/" component={AlmaNewsLanding} 
            layout={"pre_auth_layout_no_space landing_header_no_overlap"}/>
          <RouteRenderer exact={true} path="/data_mine" component={AlmaDataMine} 
            layout={"pre_auth_layout_no_space landing_header_no_overlap"}/>
          <RouteRenderer exact={true} path="/data_mine/privacy_policy" component={dataminePrivacyPolicy} 
            layout={"pre_auth_layout_no_space landing_header_no_overlap"}/>
          <RouteRenderer exact={true} path="/pageviewing" component={RecordingAnalytics} 
            layout={"pre_auth_layout_no_space landing_header_no_overlap"}/>
          <RouteRenderer exact={true} path="/signup" component={SignupScreen} 
            layout={"pre_auth_layout"}/>
          <RouteRenderer exact={true} path="/confirm" component={ConfirmMail} 
            layout={"pre_auth_layout"}/>
          <RouteRenderer exact={true} path="/confirmation" component={ConfirmSuccess} 
            props={{onSignupSucess: onSignupSucess}} layout={"pre_auth_layout"}/>
          <RouteRenderer exact={true} path="/complete_profile" component={CompleteProfile} 
            layout={"pre_auth_layout"}/>
          <RouteRenderer exact={true} path="/signup_invite" component={InviteUserSignup} 
            layout={"pre_auth_layout"}/>
          <RouteRenderer exact={true} path="/join_invite" component={InviteJoinTeam} 
            layout={"pre_auth_layout"}/>
          <RouteRenderer exact={true} path="/forgot_password" component={ForgotPassword} 
            layout={"pre_auth_layout"}/>
          <RouteRenderer exact={true} path="/password_reset" component={ResetPassword} 
            props={{onLoginSuccess: onLoginSuccess}} layout={"pre_auth_layout"}/>
          <RouteRenderer exact={true} path="/learn_more" component={LearnMore} 
          layout={"pre_auth_layout_no_space landing_header_no_overlap"}/>
          <RouteRenderer exact={true} path="/pre_subscribe" component={PreSubscribe} 
            layout={"pre_auth_layout_no_space landing_header_no_overlap"}/>
          <RouteRenderer exact={true} path="/subscribe" component={SubscribePage} 
            layout={"pre_auth_layout_no_space landing_header_no_overlap"}/>
          <RouteRenderer exact={true} path="/features" component={Features} 
            layout={"pre_auth_layout_no_space landing_header_no_overlap"}/>
          <RouteRenderer exact={true} path="/plans" component={FreePlan} 
            layout={"pre_auth_layout_no_space landing_header_no_overlap"}/>
          <RouteRenderer exact={true} path="/contact_us" component={ContactUs} 
            layout={"pre_auth_layout_no_space landing_header_no_overlap"}/>
          <RouteRenderer exact={true} path="/premium" component={PremiumPlan} 
            layout={"pre_auth_layout_no_space landing_header_no_overlap"}/>
          <RouteRenderer exact={true} path="/get_premium" component={GetPremium}
            layout={"pre_auth_layout_no_space landing_header_no_overlap"} />
          <RouteRenderer exact={true} path="/subscribed" component={Subscribed} 
            layout={"pre_auth_layout"}/>
          <RouteRenderer exact={true} path="/unsubscribe" component={Unsubscribe} 
            layout={"pre_auth_layout"}/>
          <RouteRenderer exact={true} path="/unsubscribed" component={YouWillBeMissed} 
            layout={"pre_auth_layout"}/>
          <RouteRenderer exact={true} path="/apra-form" component={EventReg} 
            layout={"pre_auth_layout_no_space"}/>
          <RouteRenderer exact={true} path="/thank_you" component={ThankYou} 
            layout={"pre_auth_layout_no_space"}/>
          <RouteRenderer exact={true} path="/pipe_drive" component={PipeDriveApp} 
          layout={"pre_auth_layout_no_space"}/>
          <RouteRenderer exact={true} path="/import_data" component={ShareDataImport} 
            layout={"pre_auth_layout"}/>
          <RouteRenderer exact={true} path="/whats-new" component={WhatsNew} 
            layout={"pre_auth_layout"}/>
          <RouteRenderer exact={true} path="/pipedrive-signup" component={PipeDriveSignup}
            layout={"pre_auth_layout"}/>
          <Redirect from="/pipedrive-permission" to="/pipedrive-signup" />         
          <Redirect from="*" to="/login" />
        </Switch>
      </div>
    )
  }

  const PostAuthRender = () => { 
    let paramsString = window.location.search;
    let searchParams = new URLSearchParams(paramsString);
    const redirect_uri = searchParams.get('redirect_uri');
    if(redirect_uri)
    window.location = redirect_uri;
    else
    return(
      <>
        <Switch>
            <RouteRenderer path="/feed" component={LandingPage} protection={["LOGGED_IN"]} 
              exact={true} props={{logout: logout}}  layout={"feed_layout"}/>
            <RouteRenderer path="/switch_acc" component={SwitchAccounts} protection={["LOGGED_IN"]} 
              exact={true} props={{logout: logout}}  layout={"pre_auth_layout"}/>
              {
                routes.map((route, index) => (
                  <RouteRenderer {...route} key={index}/>
                ))
              }
            <Redirect from="*" to="/feed" />
          </Switch>
      </>
    )
  }

  if(apiKeyStatus == 'pending' || current_team.isLoading) return <LoaderComponent isLoading={true} type="FULL_PAGE" loaderStyle={{ width: 64}}/>
  if(apiKeyStatus === "failed" && api_key) 
  return (
      <>
        {renderAppHeader()}
        <ErrorPage />
      </>
  )
  
  return (
    <React.Fragment>
      {renderAppHeader()}
      <div className="app_container">{/* This prevents horizontal scroll in mobile but bcs of this sticky position on feed page side_column does not work */}
        {
          isLoggedIn() ? PostAuthRender() : PreAuthRender()
        }
      </div>
      {((isLandingUrl() || isDatamineLandingUrl()) && !isLandingUrlExFooter())? <NewsLandingFooter/> : (isWidthDown(windowDimensions.width, "lg") && renderAppFooter())}
    </React.Fragment>
  )
}
