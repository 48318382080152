const AnDropDown = (props) => {

    return (
        <>
            <div
                style={{ position: "fixed", inset: 0, zIndex: 1 }}
                onClick={(e) => {
                    e.stopPropagation();
                    props.toggleDropDown();
                }}
            ></div>
            <div className={props.containerClass} style={props.style} onMouseLeave={props.displayOnHover ? props.toggleDropDown : null}>
                <div style={{ position: "relative", zIndex: 99 }} >
                    {props.children}
                </div>
            </div>
        </>
    );
};

export default AnDropDown;

// TODO: ADD PROPTYPES
