import { PhoneInput } from "react-international-phone"

const AnPhoneInput = ({
    phone,
    setPhone,
    style,
    ...props
}) => {
    return (
        <PhoneInput value={phone} style={style}
        inputClassName="an_input_box"
        onChange={(phone)=>setPhone(phone)}/>
    )
}
export default AnPhoneInput