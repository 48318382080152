import { AnConfirmPopup } from "../../../cta_utils/AnConfirmPopup"
import { clear_suggestions } from "../api";
import { ALL_MAPPER_ACTIONS } from "../reducers/TempMapperReducer";

export const ClearSuggestionPopup = ({pageDataReducer, handlePopupClose}) => {
    const handleClearMapperSuggestions = () => {
        clear_suggestions({}).then((response) => {
            pageDataReducer({type: ALL_MAPPER_ACTIONS['SET_STATE'], payload: {successMessages:{
                message: "Suggestions cleared",
                duration: 3000
            }}})
            handlePopupClose();
        })
    }
    return(
        <AnConfirmPopup 
            onSubmitCallback={() => handleClearMapperSuggestions()}
            onCancelCallback={handlePopupClose}
            name="Clear Suggestions"
            confirm_message="Do you want to clear suggestions?"
            btnLabels={{submitLabel: 'Yes', cancelLabel: 'Cancel'}}
        >
        </AnConfirmPopup>
        )
}