import { useEffect, useState } from "react";
import AnPopup from "./AnPopup";

export const NameInitial = ({ name, width = 48, textStyle, profile_image }) => {
    const [popupsList, setPopupsList] = useState({});
    const [validImage, setValidImage] = useState(false);

    useEffect(() => {
       if(profile_image)
       checkImageValidity(profile_image)
    }, [])

    const checkImageValidity = (imageUrl) => {
        const img = new Image();
        img.src = imageUrl;
    
        img.onload = () => {
            setValidImage(true);
        };
    
        img.onerror = () => {
            setValidImage(false);
        };
      };
    if (name === null) {
        return <div></div>;
    }

    name = name.trim().toUpperCase();
    var name_initials = name.split(" ")[0][0];
    if (name.split(" ")[1]?.[0]) name_initials += name.split(" ")[1]?.[0];
    let defaultTextStyle = {
        color: "#4D99DF",
        fontWeight: 600,
        fontSize: 20,
    };
    const showProfileImagePopup = () => {
        return (
            <AnPopup 
                name="showProfileImagePopup" 
                handleOnClosePopup={() => setPopupsList({})} 
                maxWidth={360} 
                customStyle={{borderRadius: 8, height: 360, display: 'flex', justifyContent: 'center'}}
                customInnerStyle={{alignSelf: 'center'}}>
                <div style={{width: 248, height: 248, borderRadius: "50%"}}>
                    <img src={profile_image} alt={name_initials} style={{width: 248, height: 248, borderRadius: "50%"}}></img>
                </div>       
            </AnPopup>
        )
    }

    if(validImage){
        return(
            <>
            <div style={{width: width, height: width, borderRadius: "50%", cursor: 'pointer'}} onClick={() => setPopupsList({showProfileImagePopup: true})}>
                <img src={profile_image} alt={name_initials} style={{width, height: width, borderRadius: "50%"}}></img>
            </div>
            {popupsList.showProfileImagePopup && showProfileImagePopup()}
            </>
        )
    }
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: width,
                height: width,
                borderRadius: "50%",
                background: "rgba(67, 126, 254, 0.1)",
                cursor: "pointer",
            }}
        >
            <div style={{ ...defaultTextStyle, ...textStyle }}>
                {name_initials}
            </div>
        </div>
    );
};

// TODO: ADD PROPTYPES
