
const AnToolTip = ({ // Depricated, Use ToolTip component instead
    containerClass="an_tooltip text_caption",
    innerClass="text_caption",
    messageTheme='background-black top',
    innerStyle={marginLeft: -140},
    ...props
}) => {
    return (
        <div className={containerClass} style={props.style}>
            {props.children}
            <span className={`an_tooltiptext ${messageTheme} ${innerClass}`} style={innerStyle}>{props.tooltipText}</span> 
        </div>
    );
};

export default AnToolTip;
