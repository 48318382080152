import React from "react";
import datamine_logo from "../../assets/datamine/datamine_logo.png";
import { Button } from "../cta_utils/AnButton";
import { isWidthDown } from "../utils/designUtils";
import useWindowSize from '../custom_hooks/useWindowSize';
import AnDropDown from "../cta_utils/AnDropDown";
import { Link } from "react-router-dom";
import { useState } from "react";
import GreyDownArrow from '../../assets/GreyArrowDown.svg';
import { Products } from "../views/landing/Products";
import { RecordGA } from "../utils/RecordGA";
import { AmplitudeEventTrack } from "../landing_pages/amplitude_event_track";
const DataMineHeader = () => {
    const datamine_calendly = "https://calendly.com/almaconnect-sales/datamine"
    const windowDimensions = useWindowSize();
    const [isDisplayable, setIsDisplayable] = useState(false);
    const toggleDropDown = () => setIsDisplayable(d => !d);

    const productsOptions = () => {
        return (
            <AnDropDown style={{position: 'absolute', padding: '20px', background: 'white', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', borderRadius: 4, width: 'max-content', zIndex: 99, marginTop: 6}}
                toggleDropDown={toggleDropDown} displayOnHover={true}>
                    <Products customStyle={{textDecoration: 'none'}}/>
            </AnDropDown>
        )
    }
    const handleClick = () => {
    const gaProps = {
      label: 'Book a Meeting',
      link: datamine_calendly
    };
    RecordGA(gaProps);
    AmplitudeEventTrack('datamine_book_a_meeting')
      window.location.href = datamine_calendly;
    }
  return (
    <div className="nl_header_container">
      <div className="default_flex_container nl_header">
        <div className="default_flex_container">
          <a
            href="/data_mine"
            className="nl_header_text"
            style={{ marginRight: "44px", width: "max-content" }}
            onClick={() => AmplitudeEventTrack('datamine_header_logo')}
          >
            <img src={datamine_logo} style={{ width: 148 }} />
          </a>
          {!isWidthDown(windowDimensions.width, 940) && 
            <div className="default_flex_container text_subtitle_light">
              <div
                className="nl_header_item"
                onMouseOver={() => setIsDisplayable(true)}
              >
                Products
                <img
                  src={GreyDownArrow}
                  style={{ width: 20, height: 20 }}
                ></img>
                {isDisplayable && productsOptions()}
              </div>
              <a href="/contact_us" onClick={() => AmplitudeEventTrack('datamine_contact_us')} className="nl_header_item">Contact us</a>
            </div>
          }
        </div>
        <Button
            buttonSize="LessPadding"
            buttonStyle={{width: "max-content"}}
            innerButtonStye={{ height: 40, padding: "8px 20px" }}
            onClick={handleClick}
        >
            Book a Meeting
        </Button>
      </div>
    </div>
  );
};

export default DataMineHeader;
