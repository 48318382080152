import React, {createRef, useState} from 'react';

import {apiAgent} from './axiosConfig';
import { Button } from './components/cta_utils/AnButton';
import {FlashMessages} from "./components/cta_utils/FlashMessages";
import Uploader from './components/upload/uploader';
import ConditionalRender from './ConditionalRender';

class FileUpload extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      selectedFile: {
        files: [],
        names: [],
        uploaded: false,
        uploadedUrl: null,
      },
      type: props.type,
      success: null,
      isUploading: false,
      isLoading: false,
      errors: {}
    }
    this.inputFieldRef = createRef();
  }

	changeHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0]
    })
	};

  handleFileUploadButton = () => {
    this.inputFieldRef.current?.click();
  };
  onUploadBeginCallback = () => {
    this.setState({isUploading: true, errors: {}})
  }
  onUploadFinishCallback = () => {
    this.setState({isUploading: false})
    if(!this.props.renderSubmit) this.handleSubmission()
  }
  onUploadErrorCallback = () => {
    this.setState({
      selectedFile: {
        files: [],
        names: [],
        uploaded: false,
        uploadedUrl: null,
      },
      isUploading: false,
      errors: {
        import_file: "Oops! Something went wrong while uploading the file. Please try again"
      }
    })
  }

	handleSubmission = () => {
    let {selectedFile} = this.state;
    if(selectedFile.files.length == 0 || !selectedFile.uploadedUrl){
      this.setState({
        errors: {
          import_file: "Please Upload a .csv file to continue"
        }
      })
      return;
    }
    this.setState({
      errors:{},
      isLoading: true
    })

    apiAgent({
      method: 'post',
      url: this.props.submitUrl,
      data: {
        file_name: selectedFile.names[0],
        file_url: selectedFile.uploadedUrl,
        ...this.props.prop_params
      },
    }).then(
      (result) => {
          this.setState({
              success: this.props.submitSuccessMessage,
              errors: {},
              isLoading: false
          });
          this.props.onSubmitFinishCallback?.();
    }
    ).catch(
      (error) => {
				this.setState({          
          errors: {import_file: this.props.submitErrorMessage},
          isLoading: false
        });
        console.error('Error:', error);
			}
    );
	};

  render(){
    let {selectedFile, isUploading, errors} = this.state;
    let computed_style= this.props.style ||  { marginTop: 32, padding: "44px 0px 28px",maxWidth: "600px", minHeight: "120px" }  
    
    const uploaderPrimaryText = {
      content: "or drag a file here",
      typographyClass: "text_body_light",
      style: { marginTop: 8 },
      ...this.props.uploaderPrimaryText
    }
    const uploaderSecondaryText = {
      content: "Supported headers are: name, host, tags, country_codes, media_score",
      typographyClass: "text_caption_light",
      style: { marginTop: 8 },
      displayable: true,
      ...this.props.uploaderSecondaryText
    }
    return(
      <>
        <div
            className="file_upload_container"
            style={computed_style}
        >
            {selectedFile.uploaded ? (
                <div
                    className="text_body_light"
                    style={{ marginBottom: 10 }}
                >
                    {selectedFile.names[0]} File Uploaded
                </div>
            ) : null}
            <div style={{ zIndex: 99 }}>
                <Button
                    buttonSize="Medium"
                    onClick={this.handleFileUploadButton}
                    isLoading={isUploading}
                    innerButtonStye={{
                        padding: "8px 25px",
                        textTransform: "none",
                    }}
                >
                    {this.props.uploadButtonText}
                </Button>
            </div>

            <Uploader
                selectedFile={selectedFile}
                setSelectedFile={(newFileData) => this.setState({selectedFile: newFileData})}
                isRefedTo={this.inputFieldRef}
                onUploadBeginCallback={this.onUploadBeginCallback}
                onUploadFinishCallback={this.onUploadFinishCallback}
                onUploadErrorCallback={this.onUploadErrorCallback}
            />

            <div className={uploaderPrimaryText.typographyClass} 
                style={uploaderPrimaryText.style}>
                {uploaderPrimaryText.content}
            </div>
            <ConditionalRender if={uploaderSecondaryText.displayable}>
              <div
                  className={uploaderSecondaryText.typographyClass} 
                  style={uploaderSecondaryText.style}
                >
                  {uploaderSecondaryText.content}
              </div>
            </ConditionalRender>
            {errors.import_file && (
                <div className="text_caption_error">
                    {errors.import_file}
                </div>
            )}
        </div>
        {
          this.props.renderSubmit && <Button 
            buttonSize={this.props.submitButtonSize}
            buttonStyle={{marginTop: '16px', width: "max-content", ...this.props.submitbuttonStyle}}
            onClick={this.handleSubmission}
            isLoading={this.state.isLoading}
            innerButtonStye={{
                padding: "8px 25px",      
                ...this.props.innersubmitButtonStye    
              }}
            disabled={this.props.disabled}
            >
              {this.props.submitButtonText}          
          </Button>
        }
         {this.state.success && (
             <FlashMessages
                 messageTheme="success"
                 closeCallback={() =>
                     this.setState({ success: null })
                 }
             >
                 {this.state.success}
             </FlashMessages>
         )}
      </>
    )
  }
}
FileUpload.defaultProps = {
  uploadButtonText: "Upload File",
  renderSubmit: true,  
  submitUrl: "/rss_candidates/import_via_csv",
  submitSuccessMessage: "Data import complete",
  submitErrorMessage: "Something went wrong. Import failed!",
  submitButtonText: "Submit"
}
export default FileUpload;
