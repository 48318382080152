const ALL_API_ROUTES = {
    "outbound_email_preview": "/team_stats/outbound_email_preview",
    "team_member_unsubscribe": "/outbound_mail/unsubscribe",
    "export_teams_stats": "/team_stats/export_teams_stats",
    "team_subscriber_data": "/team_stats/team_subscriber_data",
    "team_stats": "/team_stats",
    "team_stats_update": "/team_stats/update",
    "rss_candidate_sources": "/rss_candidates/:id/rss_sources",
    "get_rss_candidate": "rss_candidates/:id",
    "get_rss_candidate_selectors": "/rss_candidates/find_url",
    "post_update_selectors": "/rss_candidates/update_selectors",
    "get_rss_candidate_preview": "/rss_candidates/find_content",
    "get_rss_candidate_external_preview": "/rss_candidates/fetch_external_url",
    "export_rss_candidates": "/rss_candidates/export_rss_candidates",
    "fetch_article_source": "/search/fetch_article_source",
    "flag_post": "/post/:post_id/flag",
    "flag_matching_article": "/aggregate/:article_id/flag",
    "get_post": "/post/:id",
    "get_feed": "/feed",
    "get_clubbed_posts": "/posts/clubbed_posts",
    "bulk_updates_companies": "/companies/bulk_update",
    "bulk_import_team_members": "/team/bulk_invite_team_members",
    "archive_prospect": "/prospects/:prospect_id/archive",
    "email_trigger_status": "/email_configuration",
    "create_or_update_email_type": "/email_configuration/create_or_update_email_type",
    "get_linkedin_prospects": "/prospects/ln_prospects",
    "add_rss_candidate": "/rss_candidates/create_or_update",
    "get_directory": "/linkedin_prospect/lists/directory",
    "get_data_mine_list_show": "/linkedin_prospect/list/:list_id",
    "get_data_mine_prospects_contacts": "/linkedin_prospect/fetch_contacts",
    "create_or_update_custom_fields": "/team/custom_fields",
    "add_fields_newsfeed_export": "/team/add_fields_newsfeed_export",
    "delete_custom_field": "/team/delete_custom_field",
    "download_marketing_pdf_data": "/linkedin_prospect/export_linkedin_list_pdf_data",
    "create_linkedin_list": "/linkedin_prospect/lists",
    "generate_mapping_suggestions": "/linkedin_prospect_suggestion/generate_suggestions",
    "event_record": "/record_response",
    "get_keyword_search_articles": "search/articles",
    "track_amplitude_event": "/amplitude/track_event",
    "lead_data_record": "/record_response",
    "get_search_spaces": "/search_spaces",
    "get_single_search_space": "/search_spaces/:id",
    "suggest_team_members": "suggestions/team_members",
    "delete_tag": "/team/tags/delete_tag",
    "get_save_or_remove_post": "/post/:id/save_or_remove_post",
    "get_saved_posts": "/prospects/:id/saved_posts",
    "create_new_folder": "/post_folder/create_or_update_folder",
    "add_post_to_folder": "/post_folder/:post_id/add_to_folder",
    "get_post_folders": "/post_folder/fetch_post_folders",
    "fetch_member_folders": "/post_folder/fetch_member_folders",
    "delete_folder": "/post_folder/delete_folder",
    "update_folder": "/post_folder/update_folder",
    "fetch_folder_articles": "/post_folder/:folder_id/fetch_folder_articles",
    "get_current_folder": "/post_folder/:folder_id",
    "create_prospects_from_list": "/linkedin_prospect/list/:id/create_prospects_from_list",
    "get_rss_experiments": "/rss_experiments",
    "get_temp_mapper": "/temp_mapper",
    "get_crm_matched_relationships": "/blackbaud/get_matched_relations",
    "post_crm_matched_relationships": "/blackbaud/create_update_relations",
    "get_export_mapper_sugg": "/temp_mapper/export_mapper_suggestions",
    "get_generate_temp_sugg": "/temp_mapper/generate_suggestions",
    "get_ln_prospect_map": "/temp_mapper/map",
    "clear_suggestions": "/temp_mapper/delete_suggestions",
    "get_mapped_unmapped_suggestions": "/temp_mapper/mapped_unmapped",
    "update_linkedin_list": "/linkedin_prospect/lists/update_list",
    "fetch_linkedin_lists": "/linkedin_prospect/fetch_team_lists",
    "create_ln_sync_urls": "/linkedin_prospects/create_linkedin_sync_urls",
    "get_temp_job_updates": "/temp_post",
    "map_and_post_job": "/temp_post/map",
    "export_mapper_posts": "/temp_post/export_mapper_posts",
    "get_all_aggregates": "/linkedin_prospects/fetch_all_aggregates",
    "pipe_drive_app_init": "/pipe_drive_app_init",
    "create_relation": "/blackbaud/create_relation",
    "merge_prospects": "/prospects/merge_prospects",
    "edit_past_education": "/prospects/edit_past_education",
    "remove_past_education": "/prospects/delete_past_education",
    "get_new_temp_mapper": "/new_temp_mapper",
    "get_new_mapped_unmapped_suggestions": "/new_temp_mapper/mapped_unmapped",
    "get_new_export_mapper_sugg": "/new_temp_mapper/export_mapper_suggestions",
    "post_review_update": "/post/:post_id/review_update"
}
const getApiUrl = (key, options=null) => {
    if(!key || !ALL_API_ROUTES[key]){
        throw new TypeError(`No api routes for the ${key} provided`);
    }
    let url = ALL_API_ROUTES[key]
    if(options){
        Object.keys(options).forEach((k) => {
            url = url.replace(k, options[k]);
        })
    }
    return url
}

export const apiStub = (response_data) => {
    return new Promise(resolve => setTimeout(() => resolve(response_data),1000))
}

export default getApiUrl