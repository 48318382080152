const AnToggleButton = ({
    toggleState=false,
    toggleText='On/Off',
    onChangeCallback,
    outerToggleStyle,
    ...props
}) => {

    const handleClick = () => {
        onChangeCallback(!toggleState);
    };
    return (
        <div className="default_flex_container" style={outerToggleStyle}>
            <div className={`toggle_button ${toggleState ? 'active' : ''}`} onClick={handleClick}>
                <span className="toggle_button_slider"></span>
            </div>
            <div className="text_body_light" style={{marginLeft: 4}}>{toggleText}</div>
        </div>
    )
}

export default AnToggleButton;