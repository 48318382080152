import { smartPublishTabs } from "../../../../utils/AllFilterTabs"
import { MENTION_TYPES } from "../../../../utils/AllMentionTypes";
import { dateFormator } from "../../../../utils/dateUtils";

export const initialFiltersState = {
    filtersSelected: {
        include:{
            score_tags: [
                {value: "company_matches", min: 1, max: 50},
                {value: "name_company_distance", min: 1, max: 150},
                {value: "non-generic"},
                {value: "english"},
            ],
            mentioned_for: [],
            categories: []
        },
        exclude: {
            score_tags: []
        }
    },
    dateRanges: {
        article_created_date: { value: {from: null, to: null}, label: "Articles created", maxDate: dateFormator(new Date(), {type:"datepicker_object"})},
        created_at: { value: {from: null, to: null}, label: "Articles matched", maxDate: dateFormator(new Date(), {type:"datepicker_object"})},

    },
    downloadDateRanges: {
        created_at: { value: {from: null, to: null}, label: "Articles matched", maxDate: dateFormator(new Date(), {type:"datepicker_object"})},
    },
    sortSelected: {"article_created_date": "desc", "label": "Date: Newest to Oldest"},
    aggregatesData: {
        include: {
            article_matching: [
                {term: "name_matches", range:{min: 1, max: 3}},
                {term: "company_matches", range:{min: 1, max: 50}},
                {term: "name_company_distance", range:{min: 1, max: 150}},
                {term: "similar_name_and_company"},
                {term: "company_partial_match"},
                {term: "company_exact_match"}
            ],
            generic: [
                {term: "generic"},
                {term: "non-generic"},
            ],
            linkedin_data: [
                {term: "name_in_linkedin", range:{min: 1, max: 3}},
                {term: "company_in_linkedin", range:{min: 1, max: 3}},
            ],
            language: [
                {term: "english"},
                {term: "non-english"},
                {term: "no-language"},
            ],
            prospect: [
                {term: "name_present"},
                {term: "name_absent"},
                {term: "key_designation"},
                {term: "author_designation"},
                {term: "normal_designation"},
                {term: "partial_author_designation"}
            ],
            miscellaneous: [            
                {term: "person_name_in_url"},
                {term: "company_name_in_url"},
                {term: "person_name_in_title"},
                {term: "company_name_in_title"},
                {term: "person_name_in_desc"},
                {term: "company_name_in_desc"},
                {term: "source_type_html"},
                {term: "sec_article"}
            ],
            analysis: [
                {term: "agg_via_nlp"},
                {term: "agg_via_date"}
            ],
            mentioned_for: MENTION_TYPES.map(mt => ({term: mt.text})),
            categories: [],
            media_score: [
                {term: "top_media"},
                {term: "mid_media"},
                {term: "low_media"},
            ]
        },
        exclude: {
            article_matching: [
                {term: "similar_name_and_company"},
            ],
            prospect: [
                {term: "author_designation"},
                {term: "partial_author_designation"}
            ],
        }     
    },
    showFilterPopup: false,
    activeFilterTab: smartPublishTabs["include"][0],
    activeFilterType: "include",
    appliedFiltersCount: 4,
}

export const matchingArticleFilterReducer = (state, action) => {
    let {payload} = action;
    switch (action.type) {
        case "ASSIGN_AGGREGATES_DATA": {
            let { mappedKey, filterType } = action.payload;
            let insertAt = "PREPEND"
            if(action.payload.insertAt) insertAt = action.payload.insertAt;
            let newAggregatesData = {...state.aggregatesData}
            newAggregatesData[filterType] = {...state.aggregatesData[filterType]}
            newAggregatesData[filterType][mappedKey] = [...state.aggregatesData[filterType][mappedKey]]

            newAggregatesData[filterType][mappedKey] = action.payload.aggregates[mappedKey]
            return {
                ...state,
                aggregatesData: newAggregatesData,
            };
        }
        case "APPLY_TEMP_FILTERS": {
            let appliedFiltersCount = Object.keys(action.payload.filtersSelected).reduce((prev, filterType)=> {
                return prev + Object.keys(action.payload.filtersSelected[filterType]).reduce((count, curr)=> {
                    return count + action.payload.filtersSelected[filterType][curr].length
                },0)
            }, 0)
            return {
                ...state,
                filtersSelected: { ...action.payload.filtersSelected },
                appliedFiltersCount: appliedFiltersCount,
            };
        }
        case "SELECTED_FILTER": {
            let filtersSelected = { ...state.filtersSelected };
            let {mappedKey} = action.payload
            let activeFilterType = state.activeFilterType
            let newFiltersSelected = {...filtersSelected}
            newFiltersSelected[activeFilterType] = {
                ...newFiltersSelected[activeFilterType],
                [mappedKey]: action.payload.selections,
            };
            return { ...state, 
                filtersSelected: newFiltersSelected, 
            };
        }

        case "SELECT_AND_MODIFY": {
            let { mappedKey } = action.payload;
            let insertAt = "PREPEND"
            if(action.payload.insertAt) insertAt = action.payload.insertAt
            let newFiltersSelected = [
                ...state.filtersSelected[mappedKey],
                action.payload.data.term,
            ];
            let finalFilters = {
                ...state.filtersSelected,
                [mappedKey]: newFiltersSelected,
            };

            let appendedAggregates = []
            if(insertAt == "PREPEND"){
                appendedAggregates = [
                    action.payload.data,
                    ...state.aggregatesData[mappedKey],
                ];
            }else{
                appendedAggregates = [
                    ...state.aggregatesData[mappedKey],
                    action.payload.data,
                ];
            }
            
            let finalAggregates = {
                ...state.aggregatesData,
                [mappedKey]: appendedAggregates,
            };

            return {
                ...state,
                filtersSelected: finalFilters,
                aggregatesData: finalAggregates,
            };
        }

        case "SET_ACTIVE_FILTER_SUB_TAB":
            
            let activeFilter = smartPublishTabs[state.activeFilterType].filter(
                (flt) => (flt.sub_key || flt.tab_key) == action.payload.activeFilterTabKey
            )[0];
            return { ...state, activeFilterTab: activeFilter };
        case "SET_ACTIVE_FILTER_TYPE":
            const {filterType} = action.payload;
            return { ...state, activeFilterType: filterType, activeFilterTab: smartPublishTabs[filterType][0]};
        case "SET_POPUP":
            return { ...state, showFilterPopup: action.payload.showable };
        case "CLEAR_SELECTED_FILTERS":
            return {
                ...state,
                filtersSelected: {include: {score_tags: [], mentioned_for: [], categories: []}, exclude: {score_tags: []}}//{score_tags: []}
            };
        case "SET_SORT_FILTER": {
            return{
                ...state,
                sortSelected: action.payload
            }
        }
        case "UPDATE_DATE_RANGE": {
            let {dateRanges} = state
            let temp = dateRanges[payload.key];
            return {
                ...state,
                dateRanges: {...dateRanges, [payload.key]: {...temp, [payload.subkey]: payload.value}}
            };
        }
        case "UPDATE_DOWNLOAD_DATE_RANGE": {
            let {downloadDateRanges} = state
            let temp = downloadDateRanges[payload.key];
            return {
                ...state,
                downloadDateRanges: {...downloadDateRanges, [payload.key]: {...temp, [payload.subkey]: payload.value}}
            };
        }
        case "APPLY_DATE_RANGES": {
            let new_state = {...state};            
            new_state.dateRanges = action.payload.dateRanges
            return new_state;
        }
        case "CLEAR_DATE_RANGES": {
            let new_state = {...state};            
            new_state.dateRanges = initialFiltersState.dateRanges
            return new_state;
        }
        default:
            return { ...state };
    }
};
