import React, { useEffect, useState } from 'react'
import EmailConfirmationIcon from './../../assets/emailConfirmation.png'
import { apiAgent } from '../../axiosConfig';
import { FlashMessages } from '../../components/cta_utils/FlashMessages';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export const ConfirmMail = () => {
  const [successMsg, setSuccessMsg] = useState(false);
  const location = useLocation();
  const [email, setEmail] = useState(()=>{
    if(location.state?.email) return location.state.email;
    const params = new URLSearchParams(window.location.search);
    return params.get("email");
  })
  const resend_mail = (email) => {
    return (
        apiAgent({
            method: 'post',
            url: '/resend_confirmation_mail',
            data: {
                email: email
            }
        }).then(
          (response) => {
            setSuccessMsg("Email sent")
          }
        )
    );
}
  return (
    <div className='confirmation_container' >
      <div>
        <img className='email_confirm_img' src={EmailConfirmationIcon}></img>
      </div>
      <div className='text_headline3' style={{ marginTop: 16 }}>
          Please verify your email
      </div>
      <div className='text_body_big_light' style={{lineHeight: '24px', marginTop: 8}}>
          Once you verify your email address, you and your team can get 
          started in AlmaConnect News.
      </div>
      <div className='text_body_big_light resend_mail'>
          Didn’t receive email?&nbsp; 
          <button onClick={() => resend_mail(email)} className='text_link_big' style={{background: 'none', border: 'none', padding: 0}}>
            Resend mail {email}
          </button>
      </div>
      { successMsg && (<FlashMessages
        messageTheme="success"
        closeCallback={() =>
            setSuccessMsg(false)
        }
      >
        {successMsg}
      </FlashMessages>)
      }
    </div>
  )
}

export default ConfirmMail