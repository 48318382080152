import { Augsburg, Bryant, Carnegie, Colorado, Marquette, Ryerson, University_of_liverpool, Rosehul, Pingry, Willamette, LSUShreveport, University_of_NH, Desmoines, iit_delhi, iit_kgp, iima, bits_pilani,
    continueArrow, UploadProspect, AI, Notify, play_button, Find_hidden, Get_your_timing, Share_in_community, Save_time_and_effort, 
    Personal_news, Import_const, Tags, Email_notif, Share_news, Intergrations, Try_out_the_free_version, Almaconnect_news, Google_alerts,
    Data_protection, Distributed_servers, Gdpr_compliant, Internal_data_sec_policies, iimu
  } from "./news_landing_assets";
export const trusted_by_the_best = {
    bryant: { asset: Bryant, style: { width: 125, height: 23  } },
    marquette: { asset: Marquette, style: { width: 128, height: 30 } },
    iima: { asset: iima, style: { width: 45, height: 45 } },
    willamette: { asset: Willamette, style: { width: 122, height: 29 } },
    ryerson: { asset: Ryerson, style: { width: 86, height: 41 } },
    iit_delhi: { asset: iit_delhi, style: { width: 123, height: 35 } },
    university_of_liverpool: { asset: University_of_liverpool, style: { width: 128, height: 32 } },
    pingry: { asset: Pingry, style: { width: 118, height: 29 } },
    iimu: { asset: iimu, style: { width: 45, height: 45 } },
    colarado: { asset: Colorado, style: { width: 113, height: 38 } },
    university_of_nh: { asset: University_of_NH, style: { width: 116, height: 30 } },
    augsburg: { asset: Augsburg, style: { width: 116, height: 26 } },
    rosehul: { asset: Rosehul, style: { width: 128, height: 21 } },
    bits_pilani: { asset: bits_pilani, style: { width: 105, height: 34 } },
  };