export const initialState = {
    error: null,
    isLoaded: false,
    items: [],
    tagsList: [],
    assigneeList: [],
    selectedTag: '',
    selectedAssignee: '',
    totalPages: 1,
    currentPage: 1,
    searchTerm: "",
    actualSearchTerm: "",
    dropDownsList: {},
    showJobs: false,
    success_message: {},
    memberFolders: [],
    folderAdded: false
}

export const highConfidenceDataReducer = (state, action) => {
    switch(action.type){
        case "SET_STATE":{
            return {...state, ...action.payload}
        }
        default: return {...state}
    }
}