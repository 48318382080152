import almaLogo from "../../almanews_logo.svg";
import { AmplitudeEventTrack } from "../landing_pages/amplitude_event_track";
import { Products } from "../views/landing/Products";

const NewsLandingFooter = (props) => {
    return (
        <div style={{position: 'relative', maxWidth: 1280,minWidth:320, margin: 'auto', padding: '0px 8px'}}>
        <div className="nl_footer_container">
            <div>
                <div className="nl_footer_item_container" style={{margin: '12px 24px', paddingRight: "96px", width: "max-content"}}>
                    <a href="/" className="nl_header_text">
                        <img src={almaLogo} style={{ width: 148 }} />
                    </a>
                </div>
                <div className="nl_footer_item_container" style={{margin: '12px 24px', width: 220}}>
                    <div className="nl_footer_item_section">Products</div>
                    <Products customClass={'nl_footer_item hover_under_line'}/>
                </div>
                <div className="nl_footer_item_container" style={{margin: '12px 24px', width: 120}}>
                    <div className="nl_footer_item_section">Company</div>
                        <a href="/contact_us" className="nl_footer_item hover_under_line" onClick={() => AmplitudeEventTrack('footer_contact_us')}>Contact Us</a>
                        <a href="https://www.almaconnect.com/terms-of-use" className="nl_footer_item hover_under_line" onClick={() => AmplitudeEventTrack('footer_terms_of_use')}>Terms of Use</a>
                        <a href="https://www.almaconnect.com/privacy-policy" className="nl_footer_item hover_under_line" onClick={() => AmplitudeEventTrack('footer_privacy_policy')}>Privacy Policy</a>
                </div>
                <div className="nl_footer_item_container" style={{margin: '12px 24px', width: 160}}>
                    <div className="nl_footer_item_section">Address</div>
                    <div className="nl_footer_item">
                    <div style={{padding: '0px 0px 2px 0px'}}>3411 Silverside Road, </div>
                    <div style={{padding: '2px 0px'}}>Wilmington, DE 19810</div>
                    <div style={{padding: '2px 0px'}}></div>
                    </div>
                </div>
                <div className="nl_footer_item_container" style={{margin: '12px 24px', width: 160}}>
                    <div className="nl_footer_item_section">Contact</div>
                    <div className="nl_footer_item" style={{padding: '2px 0px'}}>global.sales@almaconnect.com</div>
                    <div className="nl_footer_item" style={{padding: '2px 0px'}}>+1 (650) 668-5781</div>
                </div>
            </div>
            <div style={{clear: 'both'}}></div>
            <div className="line_seperator_ex_light_grey" style={{ margin: "56px auto 16px" }}></div>  
            <div className="text_subtitle_light_grey_semi_bold" style={{textAlign: 'center'}}>© Copyright AlmaConnect since 2011</div>
        </div>
        </div>
    );
};

export default NewsLandingFooter;
