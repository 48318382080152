import ConditionalRender from "../../../ConditionalRender";
import useCheckUserAccess from "../../custom_hooks/useCheckUserAccess"
import { AnInputBox } from "../../input_utils/AnInput";
import { AnInputCheckBox } from "../../input_utils/AnInputCheckBox";
import InfoIcon from "../../../assets/Info.svg"
import Tooltip from "../../cta_utils/Tooltip";
export const AdminSettings = ({
    setTeamData,
    teamData,
    handleDisplayableFeatures,
    handleInputChange,
    teamSettingsChange,
    initialData,
    ...props
}) => {
    const checkUserAccess = useCheckUserAccess();
    const trackingSettings = () => {
        return (
            <>
                <div className="text_body_light" style={{margin: '16px 0px 8px'}}>Tracking</div>
                <AnInputCheckBox
                    label="Enable Google News tracking"
                    isChecked={teamData.team.google_news_tracking}
                    style={{ marginBottom: "8px" }}
                >
                    <input
                        type="checkbox"
                        checked={teamData.team.google_news_tracking}
                        onChange={(e) => setTeamData({ google_news_tracking: !teamData.team.google_news_tracking })}>
                    </input>
                </AnInputCheckBox>
                <AnInputCheckBox
                    label="Enable names tracking"
                    isChecked={teamData.team.names_tracking}
                    style={{ marginBottom: "8px" }}

                >
                    <input
                        type="checkbox"
                        checked={teamData.team.names_tracking}
                        onChange={(e) => setTeamData({ names_tracking: !teamData.team.names_tracking })}
                    >
                    </input>
                </AnInputCheckBox>
                <AnInputCheckBox
                    label="Enable other companies tracking"
                    isChecked={teamData.team.custom_companies_enabled}
                    style={{ marginBottom: "8px" }}

                >
                    <input
                        type="checkbox"
                        checked={teamData.team.custom_companies_enabled}
                        onChange={(e) => setTeamData({ custom_companies_enabled: !teamData.team.custom_companies_enabled })}>
                    </input>
                </AnInputCheckBox>
                <AnInputCheckBox
                    label="Enable NLP analysis"
                    isChecked={teamData.team.nlp_enabled}
                    style={{ marginBottom: "8px" }}

                >
                    <input
                        type="checkbox"
                        checked={teamData.team.nlp_enabled}
                        onChange={(e) => setTeamData({ nlp_enabled: !teamData.team.nlp_enabled })}>
                    </input>
                </AnInputCheckBox>
            </>
        )
    }
    const newsfeedSettings = () => {
        return (
            <>
                <div className="text_body_light" style={{margin: '16px 0px 8px'}}>Newsfeed</div>
                <ConditionalRender if={checkUserAccess('enable_mid_confidence')}>
                    <AnInputCheckBox
                        label="Enable Mid confidence results"
                        isChecked={teamData.team.displayable_features.display_mid_confidence}
                        style={{ marginBottom: "8px" }}

                    >
                        <input
                            type="checkbox"
                            checked={teamData.team.displayable_features.display_mid_confidence}
                            onChange={(e) => handleDisplayableFeatures('display_mid_confidence', !teamData.team.displayable_features.display_mid_confidence)}>
                        </input>
                    </AnInputCheckBox>
                </ConditionalRender>
                <AnInputCheckBox
                        label="Enable news results"
                        isChecked={teamData.team.newsfeed_enabled}
                        style={{ marginBottom: "8px" }}
                    >
                        <input
                            type="checkbox"
                            checked={teamData.team.newsfeed_enabled}
                            disabled={true} 
                            onChange={(e) => setTeamData({ newsfeed_enabled: !teamData.team.newsfeed_enabled})}>
                        </input>
                    </AnInputCheckBox>
                    <AnInputCheckBox
                        label="Enable Mapper suggestions"
                        isChecked={teamData.team.displayable_features.display_mapper_suggestions}
                        style={{ marginBottom: "8px" }}

                    >
                        <input
                            type="checkbox"
                            checked={teamData.team.displayable_features.display_mapper_suggestions}
                            onChange={(e) => handleDisplayableFeatures('display_mapper_suggestions', !teamData.team.displayable_features.display_mapper_suggestions)}>
                        </input>
                    </AnInputCheckBox>
                    <AnInputCheckBox
                        label="Enable Job updates 2.0 in newsfeed "
                        isChecked={teamData.team.displayable_features.display_job_updates}
                        style={{ marginBottom: "8px" }}

                    >
                        <input
                            type="checkbox"
                            checked={teamData.team.displayable_features.display_job_updates}
                            onChange={(e) => handleDisplayableFeatures('display_job_updates', !teamData.team.displayable_features.display_job_updates)}>
                        </input>
                    </AnInputCheckBox>
                <ConditionalRender if={checkUserAccess('omf_enabled')}>
                    <AnInputCheckBox
                        label="Fetch Categories for High and Mid Confidence results"
                        isChecked={teamData.team.omf_enabled}
                        style={{ marginBottom: "8px" }}

                    >
                        <input
                            type="checkbox"
                            checked={teamData.team.omf_enabled}
                            onChange={(e) => setTeamData({ omf_enabled: !teamData.team.omf_enabled })}>
                        </input>
                    </AnInputCheckBox>
                </ConditionalRender>
                <ConditionalRender if={checkUserAccess('enable_job_updates')}>
                    <AnInputCheckBox
                        label="Enable job updates"
                        isChecked={teamData.team.enable_job_updates_in_feed}
                        style={{ marginBottom: "8px" }}
                    >
                        <input
                            type="checkbox"
                            checked={teamData.team.enable_job_updates_in_feed}
                            onChange={(e) => setTeamData({ enable_job_updates_in_feed: !teamData.team.enable_job_updates_in_feed })}>
                        </input>
                    </AnInputCheckBox>
                    <ConditionalRender if={teamData.team.enable_job_updates_in_feed}>
                        <div className="sub_setting_sec">
                            {linkedinSyncSettings()}
                        </div>
                    </ConditionalRender>
                </ConditionalRender>
                <ConditionalRender if={checkUserAccess('enable_job_updates')}>
                    <AnInputCheckBox
                        label="Show Directory tab in the header for normal users"
                        isChecked={teamData.team.show_directory_tab_in_header}
                        style={{ marginBottom: "8px" }}
                    >
                        <input
                            type="checkbox"
                            checked={teamData.team.show_directory_tab_in_header}
                            onChange={(e) => setTeamData({ show_directory_tab_in_header: !teamData.team.show_directory_tab_in_header })}>
                        </input>
                    </AnInputCheckBox>
                    <ConditionalRender if={teamData.team.show_directory_tab_in_header}>
                        <div className="sub_setting_sec">
                            <AnInputCheckBox
                                label="Allow normal users to download data from the Directory"
                                isChecked={teamData.team.displayable_features.display_directory_download}
                            >
                                <input
                                    type="checkbox"
                                    checked={teamData.team.displayable_features.display_directory_download}
                                    onChange={(e) => handleDisplayableFeatures('display_directory_download', !teamData.team.displayable_features.display_directory_download)}>
                                </input>
                            </AnInputCheckBox>
                            <AnInputBox label="Company IDs for Course, Branch and Graduation Year filters">
                                <input type="text" name="ln_aliases_urns" value={teamData.team.ln_aliases_urns} 
                                    onChange={handleInputChange}
                                />
                            </AnInputBox>
                        </div>
                    </ConditionalRender>
                </ConditionalRender>
            </>
        )
    }
    const linkedinSyncSettings = () => {
        return (
            <div className="default_flex_container text_body_light" style={{marginTop: -8, marginBottom: 0}}>
                    <span>Post initial job updates from the last</span>
                    <AnInputBox style={{margin: '0px 8px'}} error={teamData.errors.initial_job_update_duration}>
                            <input type="text" name="initial_job_update_duration" disabled={initialData.team_settings.initial_job_update_duration} value={teamData.team.team_settings.initial_job_update_duration} 
                                onChange={teamSettingsChange}
                                style={{maxWidth: 64}}     
                            />
                    </AnInputBox>
                    <span>days</span>
                    <Tooltip
                    tooltipText={() => "Max - 90 days"}
                    innerClass='text_body_light'
                    textTheme='background-light-black top'
                    innerStyle={{ padding: '8px', alignSelf: 'center', color: 'white', borderRadius: '8px' }}
                    showWithoutOverflowing={true}
                    tooltipPos="top"
                    >
                    <div style={{width: 16, height: 16}}>
                        <img src={InfoIcon} alt="info"style={{verticalAlign: 'unset', width: '100%', height: '100%'}}></img>
                    </div>
                    </Tooltip>
            </div>
        )
    }
    const SaveToCrmSettings = () => {
        return (
            <>
                <div className="text_body_light" style={{margin: '16px 0px 8px'}}>CRM Access</div>
                    <AnInputCheckBox
                        label="Save News Results to CRM"
                        isChecked={teamData.team.displayable_features.display_save_news_in_crm}
                        style={{ marginBottom: "8px" }}
                    >
                        <input
                            type="checkbox"
                            checked={teamData.team.displayable_features.display_save_news_in_crm}
                            onChange={(e) => handleDisplayableFeatures('display_save_news_in_crm', !teamData.team.displayable_features.display_save_news_in_crm)}>
                        </input>
                    </AnInputCheckBox>
                    <AnInputCheckBox
                        label="Save Job Updates to CRM"
                        isChecked={teamData.team.displayable_features.display_save_jobs_in_crm}
                        style={{ marginBottom: "12px" }}
                    >
                        <input
                            type="checkbox"
                            checked={teamData.team.displayable_features.display_save_jobs_in_crm}
                            onChange={(e) => handleDisplayableFeatures('display_save_jobs_in_crm', !teamData.team.displayable_features.display_save_jobs_in_crm)}>
                        </input>
                    </AnInputCheckBox>
            </>
        )
    }
    return (
        <>
            <ConditionalRender if={checkUserAccess('enable_tracking_fields')}>
                {trackingSettings()}
            </ConditionalRender>
            {newsfeedSettings()}
            <ConditionalRender if={teamData.team.save_to_crm_enabled}>
                {SaveToCrmSettings()}
            </ConditionalRender>
            {/* {linkedinSyncSettings()} */}
        </>
    )
}