import React, { useEffect, useState } from 'react'
import {alumni, mobile_banner} from './datamine_assets'
import { Button } from '../cta_utils/AnButton';
import useWindowSize from '../custom_hooks/useWindowSize';
import { isWidthDown } from '../utils/designUtils';
import { current_emp_data,data_privacy,streamline_ur_search,track_jobs} from './datamine_assets'
import { track_alumni, track } from './datamine_assets';
import { attributes_1,attributes_2,attributes_3,attributes_4,attributes_5,attributes_6,attributes_7,attributes_8,attributes_9,attributes_10,attributes_11,attributes_12 } from './datamine_assets'
import { success_outline, Intergrations, success_fully_captured } from './datamine_assets';
import { liverpool, augsburg,bryant,carnegie_mellon,colorado,des_moines,lsus,marqutte,new_hampshire, pingry, rose_hulman, willamette} from "./datamine_assets";
import { Data_protection, Distributed_servers, Gdpr_compliant, Internal_data_sec_policies } from './datamine_assets'
import { useInterval } from '../custom_hooks/useInterval';
import { upload, look_up, map_update, filter_download} from './datamine_assets'
import { SecuritySection } from './security_section';
import { RecordGA } from '../utils/RecordGA';
import { AmplitudeEventTrack } from './amplitude_event_track';
import DarkarrowDown from '../../assets/darkDropdown.svg'

const AlmaDataMine = () => {

  const datamine_calendly = "https://calendly.com/almaconnect-sales/datamine"
  const how_it_works = [
    {
      asset: upload, 
      title: "Upload your list of alumni or a subset of it, the one that needs updating",
    },
    {
      asset: look_up, 
      title: "We will look up Linkedin and other public data directories and fetch updated information for them",
    },
    {
      asset: map_update, 
      title: "We shall take into account Big Data techniques to map your alumni and update their info.",
    },
    {
      asset: filter_download, 
      title: "Use our advanced filters powered by Data Analytics to slice and dice your alumni and download data at one go.",
    }
  ]

  const how_helps = [
    {
      asset: current_emp_data, 
      title: "Stay up-to-date with current employment data",
      desc: 'Get real-time employment data of your alumni along with their job title and the current location'
    },
    {
      asset: track_jobs, 
      title: "Track alumni job changes with ease",
      desc: 'Receive updates on your alumni when they switch jobs, move up the ladder or transition into other careers'
    },
    {
      asset: streamline_ur_search, 
      title: "Streamline your search with advanced filters for precise results",
      desc: 'Navigate easily through vast amounts of alumni data with our advanced filters and unlock precise insights.'
    },
    {
      asset: data_privacy, 
      title: "Data Privacy & Compliance",
      desc: 'Your data is not shared with any other third party and we follow strict data privacy policies while adhering to other compliances'
    }
  ]

  const attribues_we_collect = [
    {asset: attributes_1, text: "CURRENT EMPLOYMENT"},
    {asset: attributes_2, text: "CURRENT DESIGNATION"},
    {asset: attributes_3, text: "CURRENT LOCATION"},
    {asset: attributes_4, text: "INDUSTRY SECTOR"},
    {asset: attributes_5, text: "FUNCTIONAL AREA"},
    {asset: attributes_6, text: "COMPANY SIZE"},
    {asset: attributes_7, text: "COMPANY TYPE"},
    {asset: attributes_8, text: "EMPLOYER ADDRESS"},
    {asset: attributes_9, text: "EDUCATION"},
    {asset: attributes_10, text: "LINKEDIN URL"},
    {asset: attributes_11, text: "VERIFIED EMAILS"},
    {asset: attributes_12, text: "VERIFIED PHONE"},
  ]

  const basic = ['Name', 'Designation', 'Company', 'Location', 'Industry', 'Linkedin URL']
  const plus = ['Name', 'Designation', 'Company', 'Location', 'Industry', 'Educational History', 'Linkedin URL', 'Email']
  const pro = ['Name', 'Designation', 'Company', 'Location', 'Industry', 'Educational History', 'Professional History', 'Linkedin URL', 'Email', 'Phone number']

  const pricing = [
    {type: 'BASIC', value: '0.25$', unit: 'per record', times: 'One time - without contact information', benefits: basic, color: '#00C4B5', price_button_label: 'Get Started'},
    {type: 'PLUS', value: '0.6$', unit: 'per record', times: 'One time - with contact information', benefits: plus, color: '#437EFE', price_button_label: 'Get Started'},
    {type: 'PRO', value: 'Custom', unit: 'pricing', times: 'Track data changes with monthly updates in real time', benefits: pro, color: '#FCA600', price_button_label: 'Request Pricing'}
  ]

  const price_list = {
    "Monthly": {duration: "Monthly", basic_value: "0.175", plus_value: "0.42", pro: "Custom"},
    "Quaterly": {duration: "Quaterly", basic_value: "0.2", plus_value: "0.48", pro: "Custom"},
    "Half-Yearly": {duration: "Half-Yearly", basic_value: "0.225", plus_value: "0.54", pro: "Custom"},
    "Yearly": {duration: "Yearly", basic_value: "0.25", plus_value: "0.6", pro: "Custom"}
  }

  const trusted_by_the_best = [
    { asset: willamette },
    { asset: bryant },
    { asset: augsburg },
    { asset: pingry },
    { asset: lsus },
    { asset: rose_hulman },
    { asset: colorado },
    { asset: des_moines },
    { asset: marqutte },
    { asset: new_hampshire },
    { asset: carnegie_mellon },
    { asset: liverpool },
  ];

  const security = {
    Data_protection: {
      asset: Data_protection,
      title: "Data Protection",
      desc: "We value your privacy and are committed to protecting your information. Our platform has been designed and built using ‘privacy-by-design’ methodologies.",
    },
    Internal_data_sec_policies: {
      asset: Internal_data_sec_policies,
      title: "Internal Data Security Policies",
      desc: "We strictly follow data security policies and restrict the data access to the admins.",
    },
    Gdpr_compliant: {
      asset: Gdpr_compliant,
      title: "GDPR Compliant",
      desc: "We are dedicated to ensuring that all customer data is treated in accordance with the General Data Protection Regulation (GDPR).",
    },
    Distributed_servers: {
      asset: Distributed_servers,
      title: "Distributed servers across US & Europe",
      desc: "We partnered with AWS for its hosting and data services allowing users to access our platform 24x7.",
    },
  };

  const windowsDimension = useWindowSize();
  const [activeTab, setActiveTab] = useState(0);
  const carousel_image = () => document.getElementById(`carousel_image${activeTab}`)
  const remove_curr_img = () => {if(!isWidthDown(windowsDimension.width, 'md')) carousel_image().classList.remove('carousel_current_image')}
  const add_curr_img = () => {carousel_image().classList.add('carousel_current_image')}
  const carousel_interval = () => {   
    let curr = (activeTab + 1)%how_it_works.length;
    remove_curr_img();
    setActiveTab(curr)
  }
  const [startInterval, stopInterval, intervalId] = useInterval(carousel_interval, 10000);
  const [period, setPeriod] = useState("Monthly");

  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    AmplitudeEventTrack('datamine_landing_track');
}, [])

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    const button = document.querySelector(".pricing_btn");
    const dropdown = document.querySelector(".pricing_dropdown_box");
    if (!button?.contains(event.target) && !dropdown?.contains(event.target)) {
      setIsOpen(false);
    }
  };
  
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handlePeriodChange = (value) => {
    setPeriod(value);
    setIsOpen(!isOpen);
    AmplitudeEventTrack(`${value}_price_clicked`)
  }

  useEffect(() => {
    add_curr_img();
    startInterval();
    return () => {stopInterval();remove_curr_img();};
  },[activeTab]);

  const handleClick = () => {
    const gaProps = {
      label: 'Book a Meeting',
      link: datamine_calendly
    };
    RecordGA(gaProps);
    AmplitudeEventTrack('datamine_book_a_meeting')
    window.location.href = datamine_calendly;
  }
  
  return (
    <div className='datamine_container'>
        <div className='banner_box'>  
            {isWidthDown(windowsDimension.width, 'md') ?
            <img src={mobile_banner} style={{width: '100%'}}></img> 
            :
            <img src={alumni} style={{width: '100%', height: 500}}></img>
            }
              <div className='inner_section' style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', textAlign: 'center', padding: '0px 16px'}}>
                <div className="headline1" style={{margin: '0px auto 20px', maxWidth: 643}}>Keep Your Alumni Directory Updated</div>
                <div className='text1' style={{margin: '0px auto 24px auto', maxWidth: '568px', textAlign: 'center'}}>Gather essential employment data and contact info of your alumni</div>
                <Button buttonSize="Bigger"
                buttonStyle={{margin: '0px auto',maxWidth: '214px'}}
                innerButtonStye={{padding: '8px 20px', width: '100%', textTransform: 'none'}}
                onClick={handleClick}
                >Book a Meeting
                </Button>    
              </div>
        </div>

        <div className='section how_it_works_datamine_container' style={{ margin: 'auto'}}>
          <div className='inner_section'>
          <div className='carousel_container'>
            <div style={{overflow: 'hidden'}}>
            <div className='text_headline3' style={{paddingBottom: 32,fontWeight: 800}}>How It Works</div>
            {how_it_works.map((item, i) => (
              <div id={i} className='carousel' style={{ maxWidth: 524}}>
                <div className={activeTab == i ? 'text_subtitle_semi_bold' : 'text_subtitle_light_grey_semi_bold'} 
                    style={{lineHeight: '24px', fontSize: 18,cursor: 'pointer'}}
                    onClick={() => {stopInterval(); setActiveTab(i)}}>{item.title}</div>
                <div className="progress_bar_parent" style={{margin: '24px 0px',}}>
                  <div className={activeTab == i ? 'progress_bar_child' : ''}></div>
                </div>  
                {isWidthDown(windowsDimension.width, 'md') && activeTab == i &&
                  <div className="carousel_image_container">
                    <div className='carousel_image_item' id={`carousel_image${activeTab}`}>
                      <img src={item.asset}  style={{width: '100%'}}></img>
                    </div>
                  </div>
                }
              </div>
            ))}
            </div>
            {!isWidthDown(windowsDimension.width, 'md') && 
            <div className="carousel_image_container">
              <div className='carousel_image_item' id="carousel_image0">
                <img src={upload}  style={{width: '100%'}}></img>
              </div>
              <div className='carousel_image_item' id="carousel_image1">
                <img src={look_up} style={{width: '100%'}}></img>
              </div>
              <div className='carousel_image_item' id="carousel_image2">
                <img src={map_update} style={{width: '100%'}}></img>
              </div>
              <div className='carousel_image_item' id="carousel_image3">
                <img src={filter_download} style={{width: '100%'}}></img>
              </div>
            </div>
            }

          </div>
          </div>
        </div>

        <div className='section' style={{ margin: 'auto'}}>
          <div className='inner_section'>
          <div className='text_headline3' style={{paddingBottom: 40,textAlign: 'center', fontWeight: 400}}>How DataMine <span style={{fontWeight: 800}}>Helps</span></div>
          <div className='how_it_helps_container'>
            {how_helps.map((item) => (
              <div style={{maxWidth: 422}}>
                <img src={item.asset} style={{width: '100%'}}></img>
                <div className='text_title_big' style={{margin: '20px 0px 8px 0px'}}>{item.title}</div>
                <div className='text_body_big' style={{marginBottom: 48, lineHeight: '24px'}}>{item.desc}</div>
              </div>
            ))}
          </div>
          </div>
        </div>

        <div style={{marginTop: 64}}>
          <div style={{position: 'relative', display: 'flex'}}>
            <img className='track_alumni' src={track_alumni} style={{display: 'flex', flex: 1}}></img>
              <div className='track_alumni_section' style={{position: 'absolute',  top: 0, width: '100%', height: '100%'}}>
                <div className='space_around_container inner_section' style={{alignItems: 'center', height: '100%'}}>
                    <div style={{marginBottom: 24}}>
                      <div className='headline3' style={{maxWidth: 537, marginBottom: 28}}>Tracking Alumni Career Progress In <span style={{fontWeight: 800}}>Real-Time</span></div>
                      <div className='text2' style={{maxWidth: 577, marginBottom: 21}}>
                      Your alumni are constantly on the move whether to new positions within their current company or switching to a different employer entirely. 
                      <span style={{fontWeight: 700}}>&nbsp;Be the first to know these career transitions in real-time.</span>
                      </div>
                      <div className='text2' style={{maxWidth: 577, marginBottom: 24}}>
                      Our AI monitors all your alumni who experience advancements, switch employers, or make other career transitions.
                      </div>
                      <a href='https://calendly.com/almaconnect-sales/datamine' onClick={() => {AmplitudeEventTrack('footer_contact_us')}}>
                      <Button buttonSize="Large" buttonStyle={{maxWidth: '196px', height: '48px'}} innerButtonStye={{padding: '8px 20px', width: '100%', height: '100%'}}>Get Started
                      </Button>
                      </a>
                    </div>
                    <img className='track' src={track}></img>
                </div>
              </div>  
          </div>
        </div>

        <div className='section'>
          <div className='inner_section'>
          <div className='text_headline3' style={{fontWeight: 400, textAlign: 'center', marginBottom: 40}}><span style={{fontWeight: 800}}>Attributes</span> We Collect</div>
          <div className='grid_container'>
          {
            attribues_we_collect.map((item, i) => (
              <div className='attribute' key={i}>
                <img src={item.asset} style={{maxWidth: 48, height: 40, marginBottom: 12}}></img>
                <div className='text_subtitle' style={{color: 'white'}}>{item.text}</div>
              </div>
            ))
          }
          </div>
          </div>
        </div>

        <div className='data_management_through' style={{background: '#F3F8F9', marginTop: 112}}>
          <div className='space_around_container inner_section'>
          <div>
              <div className='text_headline3' style={{fontWeight: 400, maxWidth: 415, marginBottom: 14}}>
              Data Management through<span style={{display: 'inline-block',fontWeight: 800}}>CRM Integrations</span> 
              </div>
              <div className='text_body_big' style={{lineHeight: '24px', fontWeight: 600, maxWidth: 525}}>
              Integrate with some of the most popular services in Higher Education, Blackbaud CRM, Salesforce and Ellucian to offer you 
              <span style={{fontWeight: 700}}>&nbsp;seamless delivery of valuable alumni data directly into your database, </span>
              without the hassle of manual import. 
              </div>
          </div>
          <img className='intergration' src={Intergrations} style={{maxWidth: 366, maxHeight: 165}}></img>
          </div>
        </div>

        <div style={{background: '#092526'}}>
          <div className='inner_section'>
          <div className='text_headline3' style={{color: 'white',fontWeight: 800,paddingTop: 96, textAlign: 'center', paddingBottom: 20}}>Pricing</div>
          <div className='text_headline3' style={{color: 'white',fontWeight: 800, textAlign: 'center', paddingBottom: 20}}>
            <div className="dropdown" style={{margin: "auto", position: "relative"}}>
              <button className="pricing_btn section_animated_arrow" onClick={toggleDropdown} style={{width: "190px", height: "56px"}}>
                <span className="pricing_text text_body_big_bold">{period}</span>
                <span style={{padding: '16px 22px 16px 0px', display: 'flex', justifyContent: 'end'}}>
                <img className={isOpen ? "to_arrow_up" : "to_arrow_down"} src={DarkarrowDown} style={{width: 24, height: 24, verticalAlign: 'middle'}}></img>
                </span>
              </button>
              {isOpen && (
                <ul className="pricing_dropdown_box" style={{width: 190, left: "42.5%"}}>
                  {Object.keys(price_list).map((key) => (
                    <div key={key}>
                      <li className="text_body_big_bold pricing_options" onClick={() => handlePeriodChange(price_list[key].duration)}>{price_list[key].duration}</li>
                    </div>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className='pricing_flex_container'>
            {pricing.map((item)=> (
              <item style={{background: '#263B3C', borderRadius: '24px', padding: '36px 30px', maxWidth: 300}}>
                <div className='text1' style={{fontWeight: 800, color: item.color}}>{item.type}</div>
                <span className='text_headline3' style={{color: 'white'}}>{(item.type == 'BASIC')? price_list[period].basic_value + "$" : ((item.type == 'PLUS')? price_list[period].plus_value + "$" : price_list[period].pro)}</span>
                <span className='text_subtitle_light_grey_semi_bold' style={{fontSize: 16, paddingLeft: 8}}>{item.unit}</span>
                <div className='text_subtitle_light_grey_semi_bold' style={{fontSize: 16, marginTop: 12, marginBottom: 24}}>{item.times}</div>
                <div className='pricing_button' style={{marginBottom: 28}}>
                <a className='pricing_button_innerstyle' href='https://calendly.com/almaconnect-sales/datamine' onClick={() => AmplitudeEventTrack('pricing_event')}>{item.price_button_label}
                </a>
                </div>
                <div>
                  {item.benefits.map((benefit) => 
                  <div className='text_subtitle_big' style={{color: 'white', fontWeight: 600, marginBottom: 24}}>
                    <img src={success_outline} style={{marginRight: 12}}></img>
                    {benefit}
                  </div>
                  )}
                </div>
              </item>
            ))}
          </div>
          </div>
        </div>

        <div className='container7'>
          <div className='inner_section space_around_container'>
          <div>
              <div className='text_headline3' style={{fontWeight: 800, maxWidth: 560, marginBottom: 16, color: 'white', marginBottom: 16}}>
              Successfully captured Alumni Job changes, what’s next?
              </div>
              <div className='text_body_big' style={{lineHeight: '24px', fontWeight: 600, maxWidth: 560, color: 'white', marginBottom: 16}}>
              Maximize the value of your alumni data with our purpose-built product, 'AlmaConnect News' service. Designed for Alumni Relations or Prospect Research professionals, it tracks alumni news from thousands of media sources using AI technology. 
              </div>
              <div className='text_body_big' style={{lineHeight: '24px', fontWeight: 600, maxWidth: 560, color: 'white', marginBottom: 24}}>
              With over a dozen sets of keywords matched for each alumni, our service delivers news results with over 97% accuracy.
              </div>
              <div className='pricing_button' style={{marginBottom: 28, maxWidth: 172, height: 52}}>
                <a className='learn_more_innerstyle' href='https://news.almaconnect.com/' onClick={()=>AmplitudeEventTrack('datamine_learn_more')}>Learn More</a>
              </div>
          </div>
          <img src={success_fully_captured} style={{maxWidth: 410, padding: '0px 20px'}}></img>
          </div>
        </div>

        <div className='container8'>
          <div className='inner_section space_around_container'>
          <div>
              <div className='text_headline3' style={{fontWeight: 400, marginBottom: 16, color: 'white', marginBottom: 16}}>
              <span style={{fontWeight: 800}}>Who</span> we are
              </div>
              <div className='text_title text3' style={{ fontWeight: 600, maxWidth: 564, color: 'white', marginBottom: 16}}>
                <span style={{fontWeight: 800}}>AlmaConnect</span> is an alumni networking platform that provides a suite of products and services that cater to 
                various needs of academic institutions with over 500 clients across the world. From large universities to private schools, public academies and not 
                for profit foundations utilize our product to track their alumni/ constituents/ prospects/ donors.
              </div>
              <div className='text_title text3' style={{ fontWeight: 600, maxWidth: 564, color: 'white', marginBottom: 24}}>
                It is purpose-built for Alumni Relations or Prospect Research professionals to track alumni news across thousands of media sources thus aiding prospect identification, cultivation, and engagement.
              </div>
          </div>
          <div className='grid_container2'>
            {
              trusted_by_the_best.map((item, i) => (
                <img key={i} src={item.asset} style={{width: 156, height: 64}}></img>
              ))
            }
          </div>
          </div>
        </div>

        <SecuritySection security={security}/>

    </div>
  )
}

export default AlmaDataMine