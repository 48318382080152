import React from 'react'
import {apiAgent} from '../axiosConfig';
import Pagination from '../Pagination';
import FileUpload from "../FileUpload";
import TableHeads from "../components/visualizers/tabular/TableHeads"
import TableRows from "../components/visualizers/tabular/TableRows"
import ConditionalRender from '../ConditionalRender';
import { LoaderComponent } from "../components/utils/LoaderComponent"
import { Button } from '../components/cta_utils/AnButton';
import { AnInputBox } from "../components/input_utils/AnInput"
import {FlashMessages} from "../components/cta_utils/FlashMessages";
import Tooltip from '../components/cta_utils/Tooltip';
import AnDropdownFilter from '../components/cta_utils/AnDropdownfilter';
import AnPopup from '../components/cta_utils/AnPopup.js';
import FiltersIcon from '../assets/FiltersIcon.svg'; 
import { indexOfObject } from '../components/utils/ArrayUtils';
import RefreshIcon from '../assets/RefreshIcon.png'
import ViewSourceArticlesPopup from './view_source_articles_popup.js';
import EditPencil from "../assets/EditPencil.svg";
import AnDropDown from '../components/cta_utils/AnDropDown.js';
import { AnInputRadioButton } from '../components/input_utils/AnInputRadioButton.js';
import CreateRssSourcePopup from "./create_rss_source_popup.js";
const feed_type_filters = [{label: "All", key: "all"}, {label: "Rss XML", key: "rss_xml"}, {label: "Rss HTML", key: "rss_html"}, {label: "Plugin Scrap", key: "plugin_scrap"}];
class RssSources extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      serverMessage: {
        message: "",
        type: ""
      } ,
      popupsList: {},
      currentSource: null,
      articles: [],
      idx: 0,
      feed_type_filter: "all",
      rss_feed_type: "all",
      disabled_days: null
    };
  }

  componentDidMount() {
    this.fetchRssSources(1);
  }

  fetchRssSources = (page) => {
    this.setState({
      isLoaded: false,      
    });
    apiAgent({
      method: 'get',
      url: '/rss_sources',
      params: {
        url: this.state.searchText,
        page: page,
        feed_type: this.state.feed_type_filter,
        disabled_days: this.state.disabled_days
      }
    }).then(
      (response) => {
        let result = response.data;
        let rss_sources = result.data.map(item => ({
          ...item,
          feed_type_popup: false
        }));
        this.setState({
          isLoaded: true,
          items: rss_sources,
          totalPages: result.total_pages,
          currentPage: page
        });
      },
      (error)  => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    );
  }

  fetchRssSourceArticles = (rss_source_id, page = 1) => {
    this.setState({
      popupsList: { viewSourceArticles: true },
      currentSource: rss_source_id
    });
    apiAgent({
        method: 'get',
        url: '/rss_sources/fetch_source_articles',
        params: {
            rss_source_id: rss_source_id,
            page: page,
        }
    }).then(
        (response) => {
            let result = response.data;
            this.setState({
              articles: result.data,
              totalArticlePages: result.total_pages,
              currentArticlePage: page
          });
        },
        (error) => {
        }
    );
  }


  handlePopupClose = () => {
    this.setState({
      popupsList: {}
    })
  }

  onTextInput = (event) => {
    if(event.charCode !== 13) return;
    this.setState({
      searchText: event.target.value
    }, () => this.fetchRssSources(1))
  }

  handleFeedTypeChange = (e) => {
    if(e.target.value === this.state.items[this.state.idx].feed_type) return;
    let rss_source_id = this.state.items[this.state.idx].id;
    if(!window.confirm(`Are you sure you want to change feed type to `+ e.target.value)) return;
    apiAgent({
      method: 'post',
      url: '/rss_sources/update_rss_source',
      data: {rss_source_id: rss_source_id, feed_type: e.target.value}
    }).then(res => {
      let rss_source = res.data.rss_source;
      rss_source = {
        ...rss_source,
        id: rss_source._id,
        created_at: rss_source.c_at
      };
      let updatedItems = [...this.state.items];
      updatedItems[this.state.idx] = rss_source;
      this.setState({items: updatedItems});
    }).catch(err => alert('Something went wrong! unable to update feed type'))
  }

  renderFeedTypeDD = () => {
    return(
      <AnDropDown style={{position: 'absolute', right: 0, padding: '16px', background: 'white', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', borderRadius: 4, width: 'max-content', zIndex: 99}}
          toggleDropDown={() => this.handleEditClick(this.state.idx)}>
          {feed_type_filters.map((filter, index) => (
              index > 0 &&
              <AnInputRadioButton key={index} label={filter.label} style={{marginTop: 8}}>
                  <input 
                      type="radio" 
                      value={filter.key}
                      checked={this.state.items[this.state.idx].feed_type === filter.key}
                      onClick={this.handleFeedTypeChange}
                  />
              </AnInputRadioButton>
          ))}
      </AnDropDown>
    )
  }

  disable = (rss_source) => {
    apiAgent({
      method: 'post',
      url: `/rss_sources/${rss_source.id}/disable`
    }).then(
      (result) => {
        this.setState({ serverMessage: {
          message:"Disabled", 
          type: "success"
        }})
        this.fetchRssSources(1);
      }
    ).catch(
      (error) => {
        this.setState({serverMessage: {
          message: "Something went wrong! Unable to add URL", 
          type: "warning"
        }})
      }
    );
  }

  reset = (rss_source) => {
    apiAgent({
      method: 'post',
      url: `/rss_sources/${rss_source.id}/reset`
    }).then(
      (result) => {
        this.setState({ serverMessage: {
          message:"Reset", 
          type: "success"
        }})
        this.fetchRssSources(1);
      }
    ).catch(
      (error) => {
        this.setState({ serverMessage: {
          message: "Something went wrong! Unable to add URL", 
          type: "warning"
        }})
      }
    );
  }

  setStateVariable = (payload) => {
    this.setState(payload);
  }

  handleEditClick = (index) => {
    const updatedItems = [...this.state.items];
    updatedItems[index].feed_type_popup = !updatedItems[index].feed_type_popup;
    this.setState({items: updatedItems, idx: index});
  }

  renderTableContent = () => {
    const {items} = this.state;
    return (
      <>
        <Pagination totalPages={this.state.totalPages}
            currentPage={this.state.currentPage}
            onPageChange={this.fetchRssSources}
            style={{padding: "16px 0px 0px"}}
        />
        <table className="data_table" style={{textAlign: "left", marginTop: "24px", maxWidth: "100%"}}>
          <TableHeads typoGraphyClass="text_body_big_light" headerClass="table_header_row_seperated_small">
              <th style={{width: "7%"}}>ID</th>
              <th style={{width: "8%"}}>Created At</th>
              <th style={{width: "12%"}}>Last Fetched at</th>
              <th style={{width: "20%"}}>URL</th>
              <th style={{width: "7%"}}>RSS Fetch No</th>
              <th style={{width: "7%"}}>Article Fetch No</th>
              <th style={{width: "5%"}}>Media Score</th>
              <th style={{width: "5%"}}>Source</th>
              <th style={{width: "9%"}}>Feed type</th>
              <th style={{width: "12%"}}>Disabled at</th>
              <th style={{width: "8%"}}>Actions</th>
          </TableHeads>
          <tbody>
            {items.map((item,index) => (
              <TableRows key={item.id} rowClass="table_content_row_seperated_small text_body">
                <td>
                  {item.id && item.id.match(/.{1,9}/g).map((substr, index) => (
                    <React.Fragment key={index}>
                      {substr}
                      <br />
                    </React.Fragment>
                  ))}
                </td>
                <td>
                  {item.created_at && item.created_at.match(/.{1,10}/g).map((substr, index) => (
                    <React.Fragment key={index}>
                      {substr}
                      <br />
                    </React.Fragment>
                  ))}
                </td>
                <td>
                  {item.lf_at && item.lf_at.match(/.{1,10}/g).map((substr, index) => (
                    <React.Fragment key={index}>
                      {substr}
                      <br />
                    </React.Fragment>
                  ))}
                </td>
                <td style={{wordBreak: 'break-all'}}>
                  {item.url}
                </td>
                <td>
                  {item.fetch_rss_count}
                </td>
                <td>
                  {item.fetch_article_count}
                </td>
                <td>
                  {item.media_score}
                </td>
                <td>
                  {item.source}
                </td>
                <td className="an_input_box" style={{padding: "14px 0px 14px 14px", border: 'none'}}>
                    <div className='text_body' style={{cursor: "default"}}>
                      <span>{item.feed_type}</span>
                      <img src={EditPencil} alt="edit" style={{marginLeft: "4px", cursor: "pointer"}} onClick={() => this.handleEditClick(index)}></img>
                      {item.feed_type_popup && this.state.idx === index && this.renderFeedTypeDD()}
                    </div>
                </td>
                <td>
                  <Tooltip
                    tooltipText={() => item.unscheduled_reason}
                    innerClass='text_body_light'
                    textTheme='background-light-black top'
                    innerStyle={{ padding: '8px', alignSelf: 'center', color: 'white', borderRadius: '8px', whiteSpace: 'break-spaces' }}
                    style={{ maxWidth: 200 }}
                    tooltipPos="top"
                    tooltTipOverlay={{x: 0,y: -7}}
                    showWithoutOverflowing={true}
                    >
                    <div>
                    {item.unscheduled_at && item.unscheduled_at.match(/.{1,10}/g).map((substr, index) => (
                      <React.Fragment key={index}>
                        {substr}
                        <br />
                      </React.Fragment>
                    ))}
                    </div>
                    </Tooltip>
                </td>
                <td>
                  <ConditionalRender if={item.unscheduled_at == null}>
                    <div className='hover_under_line' onClick={() => this.disable(item)} style={{cursor: 'pointer', color: '#00bfa9'}}>
                      Disable
                    </div>
                  </ConditionalRender>
                  <ConditionalRender if={item.unscheduled_at != null}>
                    <div className='hover_under_line' onClick={() => this.reset(item)} style={{cursor: 'pointer', color: '#00bfa9'}}>
                      Reset
                    </div>
                  </ConditionalRender>
                    <div className="text_link hover_under_line" style={{whiteSpace: 'nowrap'}} onClick={() => this.fetchRssSourceArticles(item.id, 1)}>
                        View Articles
                    </div>
                </td>
              </TableRows>
            ))}
          </tbody>
        </table>
      </>
    )
  }

  sourcesfilters = () => {
    return (
      <AnPopup handleOnClosePopup={this.handlePopupClose} maxWidth={320}>
        <div className='default_flex_container text_subtitle_light_grey_semi_bold' style={{fontWeight: 700}}>
        <span>Sources disabled in last</span>
        <AnInputBox style={{ margin: '0px 8px' }}>
          <input type="text" name="initial_job_update_duration" value={this.state.disabled_days}
            onChange={(e) => {this.setState({disabled_days: e.target.value})}}
            style={{ maxWidth: 64 }}
          />
        </AnInputBox>
        <span>days</span>
        </div>
        <div className='default_flex_container'>
          <Button
            buttonStyle={{ width: "50%", marginTop: "16px" }}
            onClick={() => {this.fetchRssSources(1);this.handlePopupClose()}}

          >Apply</Button>
          <div
            className="text_link"
            style={{ marginTop: 15, marginLeft: 15 }}
            onClick={(e) => {
              e.stopPropagation();
              this.setState({disabled_days: null})
              this.handlePopupClose();
            }
            }
          >
            Clear filter
          </div>
        </div>
      </AnPopup>
    )
  }


  render(){
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    }
    return (
      <div style={{margin: "33px 49px"}}>
        <div className='text_title_big' >RSS Sources</div>
        <div  className="line_seperator_ex_light_grey" style={{ margin: "8px auto 24px" }}></div>
        <div className='text_title' style={{marginBottom: "4px"}}>Import RSS sources in bulk</div>
        <div className='text_body_light'>
        Supported headers are: url, country_codes, media_score, candidate_host
        </div>

        <FileUpload 
          style={{marginTop: "12px", maxWidth: "600px"}}
          submitUrl="/rss_sources/import_via_csv" 
          uploaderSecondaryText={{content: "Supported headers are: url, country_codes, media_score, candidate_host"}}
        />
        <div  className="line_seperator_ex_light_grey"  style={{ margin: "24px 0px" }}></div>

        <div className='flex_container_space_between'>
          <AnInputBox style={{maxWidth: "364px"}}>
            <input type="text" id="search" onKeyPress={this.onTextInput} placeholder="Search host"/>
          </AnInputBox>
          <div className='default_flex_container'>
            <div className='text_link' style={{marginRight: 12}} onClick={() => this.setState({popupsList: { createRssSourcePopup: true }})}>Add RSS source</div>
            <div className="filters_button_box text_body_light" onClick={() => this.fetchRssSources(1)}
              style={{padding: "6px 8px", height: 36, margin: '0px 12px 0px 0px', border: '0.4px solid #B6B9BB'}}>
              <img src={RefreshIcon} style={{width: 24, height: 24}}></img>
            </div>
            <div className="filters_button_box text_body_light" onClick={() => this.setState({popupsList: { sourcesfilters: true }})}
            style={{padding: "6px 8px", height: 36, margin: '0px 12px 0px 0px', border: '0.4px solid #B6B9BB'}}>
              <img src={FiltersIcon} style={{width: 24, height: 24}}></img>
            </div>
            <AnDropdownFilter
                option={this.state.feed_type_filter}
                setOption={(filterKey) => {
                  this.setState({ feed_type_filter: filterKey }, () => this.fetchRssSources(1));
                }}
                options={feed_type_filters}
                style={{ position: 'relative', lineHeight: 0, marginRight: 6 }}
                innerStyle={{width: '124px'}}
            >
            </AnDropdownFilter>
          </div>
        </div>

        <ConditionalRender if={isLoaded}>
          {this.renderTableContent()}
        </ConditionalRender>
        <ConditionalRender if={!isLoaded}>
          <LoaderComponent isLoading={true}></LoaderComponent>
        </ConditionalRender> 
        <ConditionalRender if={this.state.serverMessage.message}>
          <FlashMessages
            messageTheme={this.state.serverMessage.type}
            closeCallback={() =>
                this.setState({ serverMessage: {message: "", type: ""} })
            }
          >
            {this.state.serverMessage.message}
          </FlashMessages>
        </ConditionalRender>   
        {this.state.popupsList.viewSourceArticles && (
        <ViewSourceArticlesPopup
          articles={this.state.articles}
          totalArticlePages={this.state.totalArticlePages}
          currentArticlePage={this.state.currentArticlePage}
          handlePopupClose={this.handlePopupClose}
          fetchRssSourceArticles={this.fetchRssSourceArticles}
          currentSource={this.state.currentSource}
        />
      )}
        {this.state.popupsList.sourcesfilters && this.sourcesfilters()}   
        {/* {this.state.popupsList.createRssSourcePopup && this.createRssSourcePopup()}   */}
        {this.state.popupsList.createRssSourcePopup && (
          <CreateRssSourcePopup
            feed_type_filters={feed_type_filters}
            toggleDropdown={this.handleInputClick}
            setStateVariable={this.setStateVariable}
          />
        )}        
      </div>
    );
  }
}

export default RssSources;
