import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import Cookies from 'universal-cookie'
import { apiAgent } from '../../../axiosConfig'
import ConditionalRender from '../../../ConditionalRender'
import { updateCurrentTeam } from '../../../redux/currentTeamSlice'
import NewsLanding from './NewsLanding'

const LandingPage = () => {
    const current_team = useSelector(state => state.current_team)
    const _history = useHistory()
    const _dispatch = useDispatch()

    if(current_team.team?.id && !current_team.team.approved_at) _history.push('/requested')
    else if(current_team.team?.id && current_team.team.approved_at) return <NewsLanding />
    else if(!current_team.team.id) _history.push('/switch_acc')
    return null
}

export default LandingPage