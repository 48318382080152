import {useSelector} from 'react-redux'

const RequestedToJoin = (props) => {
  return(
    <>
      <div className='requested_to_join_container'>
        <img src="/requestedToJoin.svg" style={{display: 'block', margin: 'auto', width: "100%"}}/>
        <div style={{textAlign: "center", marginTop: 36}}>
          <div className="text_title_big">
            Request Sent!
          </div>          
        </div>
      </div>
      <div style={{maxWidth: 650, textAlign: 'center', margin:' 8px auto 0px'}}>
        <div className="text_body_big_light">
            Your admin will approve your request to join this institution in AlmaNews
        </div>
      </div>
    </>
  )
}

export default RequestedToJoin
