import { useEffect, useState } from "react"
import { useInterval } from "../../../custom_hooks/useInterval"
import { indexOfObject } from "../../../utils/ArrayUtils"
import ConditionalRender from "../../../../ConditionalRender"
import useWindowSize from "../../../custom_hooks/useWindowSize"
import { isWidthDown } from "../../../utils/designUtils"
import { Carousel } from "react-bootstrap"
import job_post from "../../../../assets/features/whats_new/Job Post Real.png"
import newsletter from "../../../../assets/features/whats_new/Newsletter.png"
import mapping from "../../../../assets/features/whats_new/Mapping.png"
import folders from "../../../../assets/features/whats_new/Folders.png"
import directory from "../../../../assets/features/whats_new/Directory.png"
import save_to_crm from "../../../../assets/features/whats_new/Save to CRM.png"
import smart_desc from "../../../../assets/features/whats_new/Smart Dis.png"
import save_to_profile from "../../../../assets/features/whats_new/Saved to profile.png"

const LATEST_FEATURES = [
    {key: 'job_post', label: "Job Post", title: "Job Posts Hit 11K+", asset: job_post,  desc: "AlmaNews Job Updates Just Hit a New Milestone: Delivering 11,937 alumni career updates in the last quarter, empowering colleges to celebrate and leverage their alumni's professional successes like never before."},
    {key: 'newsletter', label: "Newsletter Growth", title: "Newsletters Grow 25%", asset: newsletter, desc: "Our free alumni newsletter's subscription soared by 25.89% last quarter, reflecting the growing trust in AlmaNews weekly insights into alumni achievements."},
    {key: 'mapping', label: "Mapping Precision", asset: mapping, title: "Mapping Gets 30% Sharper", desc: "AlmaNews Prospect Mapping algorithm is now 30% more accurate, ensuring every alumni job update is captured, so you never miss out on career milestones."},
    {key: 'folders', label: "News Folder", asset: folders, title: "Introducing Folders", desc: "Simplify how you follow alumni achievements. News can now be easily organized, stored, and shared updates with your fellow team members, ensuring your team stays informed and connected."},
    {key: 'directory', label: "Directory", asset: directory, title: "The Directory Just Got Smarter", desc: "Dive deeper with new filters for Seniority Level, Skills, Graduation Year, and Volunteering Roles & Types, refining your search to connect with the right alumni, right away."},
    {key: 'save_to_crm', label: "Save to CRM", asset: save_to_crm, title: "Click, Sync, and Update", desc: "AlmaNews upgraded two-way integration with Raiser’s Edge NXT instantly refreshes your CRM with the latest alumni successes, keeping your engagement strategies dynamic and informed."},
    {key: 'smart_description', label: "Smart Description", asset: smart_desc, title: "Get News Gist Instantly", desc: "Our new 'Smart Descriptions' summarize news articles, providing you with the essence of alumni updates at a glance."},
    {key: 'save_to_profile', label: 'Save to Profile', asset: save_to_profile, title: "Save News with One Click", desc: "With just a click, Almanews lets you save important prospect news directly to a Prospect's profile, creating a personalized record of milestones for future engagement and outreach."}
]
const FEATURE_INTERVAL = 15000
export const WhatsNew = () => {
    const windowsDimension = useWindowSize();
    const [activeFeature, setActiveFeature] = useState(LATEST_FEATURES[0])
    const feature_interval = () => {  
        let idxOfActiveFeature = indexOfObject(LATEST_FEATURES, "key", activeFeature.key);
        let curr = (idxOfActiveFeature + 1)%LATEST_FEATURES.length;
        setActiveFeature(LATEST_FEATURES[curr])
      }
    const [startInterval, stopInterval, intervalId] = useInterval(feature_interval, FEATURE_INTERVAL);
    useEffect(() => {
        startInterval();
        return () => {stopInterval();};
      },[activeFeature]);
    const renderFeature = () => {
        return (
            <div className="default_flex_container" style={{padding: '0px 128px', marginTop: 52, alignItems: 'flex-start'}}>
                <div style={{maxWidth: 200, width: '100%', border: '0.5px solid rgba(182, 185, 187, 0.6)'}}>
                    {LATEST_FEATURES.map((feature) => {
                        let active = activeFeature.key === feature.key
                        return (
                            <div style={{overflow: 'hidden'}}>
                                <div className={active ? "active_feature" : ""} onClick={() => setActiveFeature(feature)} style={{ cursor: 'pointer' }}>
                                    <div className={active ? "active_feature_text" : "text_body_big_light_semi_bold"} style={{ padding: '16px 20px 16px 20px' }}>{feature.label}</div>
                                </div>
                                <div className="progress_bar_parent">
                                    <div className={active ? 'progress_bar_child' : ''}></div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div style={{ marginLeft: 55 }}>
                    <div className="text_title">{activeFeature.title}</div>
                    <div className="text_body_big_light_semi_bold" style={{ lineHeight: '24px', marginTop: 12, textAlign: 'justify' }}>{activeFeature.desc}</div>
                    <div style={{margin: '32px auto', maxWidth: 650}}>
                        <img className="new_feature_img" src={activeFeature.asset} style={{width: '100%'}}></img>
                    </div>
                </div>
            </div>
        )
    }
    const renderFeatureMobile = () => {
        let activeIdx = indexOfObject(LATEST_FEATURES, "key", activeFeature.key);
        return (
            <div style={{ marginTop: 24}}>
                <Carousel interval={FEATURE_INTERVAL} indicators={false} activeIndex={activeIdx} touch={true}
                    prevIcon={<span/>}
                    nextIcon={<span/>}>
                {LATEST_FEATURES.map((feature, i) => {
                    let active = activeFeature.key === feature.key
                    return (
                    <Carousel.Item>
                        <div key={i} style={{ background: "#E7ECEB1A", border: "1px solid #E7ECEB", borderRadius: '12px', transition: 'transform .6s ease-in-out' }}>
                            <div><img className="new_feature_img" src={feature.asset}></img></div>
                            <div style={{ border: "2px solid #E7ECEB", marginTop: 24 }}></div>
                            <div style={{ padding: '12px 20px 30px 20px' }}>
                                <div className="text_body_big_bold" style={{ fontSize: '20px', lineHeight: '27px' }}>{feature.title}</div>
                                <div className="text_body_big_light_semi_bold" style={{ lineHeight: '24px', marginTop: 14 }}>{feature.desc}</div>
                            </div>
                        </div> 
                    </Carousel.Item>
                    )
                })}
                </Carousel>
                <div className="default_flex_container" style={{ gap: 12, justifyContent: 'center', marginTop: 24 }}>
                    {LATEST_FEATURES.map((feature) => {
                        let active = activeFeature.key === feature.key
                        return (
                        <div>
                        <ConditionalRender if={active}>
                            <div className="carousel_progress_bar_parent">
                                <div className="carousel_progress_bar_child"></div>
                            </div>
                        </ConditionalRender>
                        <ConditionalRender if={!active}>
                            <div className="carousel_dot" style={{cursor: 'pointer'}} onClick={() => setActiveFeature(feature)}></div>
                        </ConditionalRender>
                        </div>
                        )
                    })}
                </div>
            </div>
        )
    }
    return (
        <div>
            <div style={{textAlign: 'center', maxWidth: 704, margin: 'auto'}}>
                <div className="text_headline3">What's new</div>
                <div className="text_title_big" style={{fontWeight: 400, color: '#979797', marginTop: 4}}>Discover the latest feature releases, improvements, and updates on our product.</div>
            </div>
            {!isWidthDown(windowsDimension.width, 'md') ? renderFeature() : renderFeatureMobile()}
        </div>
    )
}