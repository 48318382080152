import React from 'react'
import ErrorImage from "../../../assets/SiteConstructor.png"
import { Button } from '../../cta_utils/AnButton'
const ErrorPage = () => {
  return (
    <div className="flex_container_space_between" style={{maxWidth: "860px", margin: "48px auto"}}>
        <div>
            <div className='text_title_big'>
                Something went wrong!
            </div>
            <div className='text_body_big_light' style={{marginTop: "4px"}}>
                We have encountered an error while trying to connect to the server.
            </div>
            <Button buttonSize='Big'
                buttonStyle={{marginTop: "26px"}}
                innerButtonStye={{padding: "9px 37px"}}
                onClick={() => window.location.reload()}
            >
                Retry
            </Button>
        </div>
        <img src={ErrorImage} style={{maxWidth: "378px"}}></img>
    </div>
  )
}

export default ErrorPage