import CompareBox from "./CompareBox"
import Clients from "./ClientsPage"
import useWindowSize from "../../custom_hooks/useWindowSize";
import { isWidthDown, isWidthUp } from "../../utils/designUtils";
const GetPremium = () => {
    const windowDimensions = useWindowSize();
    let calculatedMargin = !isWidthDown(windowDimensions.width, "md") ? '-250px' : '-200px';
    return (
        <div className="get_premium_container">
            <div>
                <div className="banner_box" style={{width: '100%'}}>
                    <div style={{textAlign: 'center'}}>
                    <div className="small_heading">
                        PLANS
                    </div> 
                    <div className="text_headline">
                        Get Premium. Deepen Relationships.
                    </div>
                    </div>
                </div>
                <div className="default_flex_container" style={{justifyContent: 'center', textAlign: 'unset', paddingTop: 44, position: 'relative', top: calculatedMargin, marginBottom: calculatedMargin}}>
                    <CompareBox/>
                </div>
            </div>
            <div style={{marginTop: 80}}>
                <Clients/>
            </div>
        </div>
    )
}
export default GetPremium