import { useEffect, useReducer, useRef, useState } from "react";
import { clear_suggestions, export_mapper_sugg, fetch_mapped_unmapped, fetch_temp_mappers, generate_suggestions, map_prospect } from "./api";
import Pagination from "../../../Pagination";
import TableHeads from "../../visualizers/tabular/TableHeads";
import TableRows from "../../visualizers/tabular/TableRows";
import { LoaderComponent } from "../../utils/LoaderComponent";
import ConditionalRender from "../../../ConditionalRender";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { AnInputBox } from "../../input_utils/AnInput";
import { Button, TabbedButton } from "../../cta_utils/AnButton";
import DownloadImage from "../../../assets/download.png";
import { FlashMessages } from "../../cta_utils/FlashMessages";
import AnCheckBoxDropdown from "../../cta_utils/AnCheckboxDropdown";
import { TextHighlighter } from "../../utils/textHighlighter";
import { AnConfirmPopup } from "../../cta_utils/AnConfirmPopup";
import AnPopup from "../../cta_utils/AnPopup";
import SuccessGreen from "../../../assets/SuccessGreen.svg"
import MapperSuggestionFilter, { initialFilterState } from "./MapperSuggestionFilter";
import MenuDots from "../../../assets/MenuDots.svg"
import AnDropDown from "../../cta_utils/AnDropDown";

const initialPageData = {
    items: [],
    isFetching: false,
    totalPages: 0,
    currentPage: 1,
    totalCount: 0,
    average_confidence_of_mapped: 0,
    no_of_no_sugg: 0,
    no_of_unmapped: 0,
    no_of_mapped: 0
}

const TempMapper = ({mappingType, fromDirectory, ...props}) => {
    const [pageData, setPageData] = useState(initialPageData);
    const [popupsList, setPopupsList] = useState({});
    const [mappedUnmappeCount, setMappedUnmappedCount] = useState({});
    const [currItem, setCurrItem] = useState({});
    const [successMessages, setSuccessMessages] = useState({
        message: "",
        duration: 0,
        from: ""
    });
    const [filtersState, setFiltersState] = useState(initialFilterState)
    // const [mappingType, setMappingType] = useState("algo_sugg");
    const loader = useRef();
    useEffect(() => {
        loader.current = 'not-loaded'
        fetchData(1)   
        return (() => loader.current = 'not-loaded')
    },[mappingType])

    const fetchData = (page) => {
        setPageData({...pageData, isFetching: true});
        let valueData = {...filtersState}
        var data_params = getMappedFunctionData(['q', 'page'], {...valueData, page: page})
        if(mappingType === 'mapped'){
            data_params = {
                ...data_params,
                ...getMappedFunctionData(['confidence', 'tags'], valueData),
                mapped: true
            }
            fetchMappedUnmapped(page, data_params)
        }
        else if(mappingType === 'unmapped'){
            data_params = {
                ...data_params,
                mapped: false
            }
            fetchMappedUnmapped(page, data_params)
        }
        else{
            data_params = {
                ...data_params,
                mapping_filter: getMappedFunctionData(['count', 'order', 'confidence', 'tags', 'list_name', 'status'], valueData)
            }
            fetchAlgoSugg(page, data_params)
        }
    }
    const fetchAlgoSugg = (page, data_params) => {
        loader.current = 'algo_sugg'
        fetch_temp_mappers(data_params).then(
            (res) => {
                if(loader.current === 'algo_sugg')
                setPageData({
                    items: res.data.data,
                    totalPages: res.data.total_pages,
                    totalCount: res.data.total,
                    currentPage: page,
                    isFetching: false,
                    no_of_mapped: res.data.no_of_mapped,
                    no_of_unmapped: res.data.no_of_unmapped,
                    average_confidence_of_mapped: res.data.average_confidence_of_mapped,
                    no_of_no_sugg: res.data.no_of_no_sugg
                });
                props.setParentState({
                    mappedTotalCount: res.data.total_mapped,
                    unMappedTotalCount: res.data.total_unmapped,
                })
            },
            (error) => {
                setPageData({...pageData, isFetching: false});
            }
        );
    };
    const fetchMappedUnmapped = (page, data_params) => {
        loader.current = data_params.mapped
        fetch_mapped_unmapped(data_params).then(
            (res) => {
                if(loader.current === data_params.mapped)
                setPageData({
                    items: res.data.data,
                    totalPages: res.data.total_pages,
                    totalCount: res.data.total,
                    currentPage: page,
                    isFetching: false
                });
            },
            (error) => {
                setPageData({...pageData, isFetching: false});
            }
        );
    };
    const handleExportMapperSuggestions = () => {
        let {confidence, count, order, list_name} = {...filtersState}
        let temp_tags = [...filtersState.selectedOptions]
        let status = {...filtersState.mappedFilter}
        let data_params = {
            mapping_filter: {
                count: count,
                order: order,
                confidence: confidence,
                tags: temp_tags,
                list_name: list_name,
                status: status.key
            }
        }
        export_mapper_sugg(data_params).then((response) => {
                setSuccessMessages({
                    message: "You will receive an email when the export is ready to download",
                    duration: 3000,
                    from: "Data Exports"
                })
                handleOnClosePopup();
            })
    }
    const handleGenerateMapperSuggestions = () => {
        generate_suggestions({}).then((response) => {
            setSuccessMessages({
                message: "Suggestion will be generated soon",
                duration: 3000
            })
            handleOnClosePopup();
        })
    }
    const handleClearMapperSuggestions = () => {
        clear_suggestions({}).then((response) => {
            setSuccessMessages({
                message: "Suggestions cleared",
                duration: 3000
            })
            handleOnClosePopup();
        })
    }
    const handleOnClosePopup = () => {
        setPopupsList({})
    }

    const map = (mapper_id) => {
        map_prospect({mapper_id: mapper_id}).then((response) => {
            setSuccessMessages({
                message: "Prospect mapped",
                duration: 3000
            })
            handleOnClosePopup();
            fetchData(pageData.currentPage)
        })
    }


    const mapConfirmationPopup = () => {
        return(
            <AnPopup 
                name="mapConfirmationPopup"
                handleOnClosePopup={handleOnClosePopup}
                maxWidth={480}
            >
                {currItem.mapped_url && (
                    <div className="text_body" style={{textAlign: 'center'}}>
                        <div>This profile is already mapped.</div>
                        <div style={{marginTop: 8}}>
                            <Link to={`/prospects/${currItem.prospect_id}`} target="_blank"> 
                                <span>{currItem.name}</span>
                            </Link> - 
                            <a href={currItem.mapped_url} target="_blank"> 
                                <span> {currItem.l_name}</span>
                            </a>
                        </div>
                    </div>
                )}
                <div className="text_body" style={{textAlign: 'center', marginTop: 8}}>Do you want to {currItem.mapped_url ? 'remap' : 'map'} this?</div>
                <div style={{display: 'flex', marginTop: 16, alignContent: 'center', justifyContent: 'center'}}>
                    <Button buttonSize="Medium" buttonStyle={{width: 'max-content'}} innerButtonStye={{padding: '8px 20px'}}
                            onClick={() => map(currItem.id)}>Map</Button>
                    <Button type="Secondary" buttonSize="Medium" buttonStyle={{width: 'max-content', marginLeft: 16}} innerButtonStye={{padding: '8px 20px'}}
                            onClick={() => handleOnClosePopup}>Cancel</Button>
                </div>
            </AnPopup>
            )
    }
    
    const renderDataTable = () => {
        return (
            <div style={{position:"relative", margin:"2rem auto", marginTop: "16px", outline: "0.4px solid #B6B9BB", borderRadius: "4px",overflow: 'hidden'}}>
            <div style={{overflowX:"scroll", overflowY:"hidden"}}>
            <table className="data_table" style={{textAlign: "center"}}>
            <TableHeads typoGraphyClass="text_body_big_light" headerClass="table_header_row_small" customStyles={{border: "0.4px solid #B6B9BB", borderRadius: "4px", height: '84px'}}>
                <th style={{width: "12%", whiteSpace: 'nowrap'}}>Name - Ln name</th>
                <th style={{width: "10%"}}>University -<br/><span style={{whiteSpace: 'nowrap'}}>Ln University</span></th>
                <th style={{width: "10%"}}>Grad Year -<br/><span style={{whiteSpace: 'nowrap'}}>Ln Grad Year</span></th>
                <th style={{width: "10%"}}>Course -<br/><span style={{whiteSpace: 'nowrap'}}>Ln Course</span></th>
                <th style={{width: "15%"}}>Companies -<br/><span style={{whiteSpace: 'nowrap'}}>Ln Companies</span></th>
                <th style={{width: "13%"}}>Locations -<br/><span style={{whiteSpace: 'nowrap'}}>Ln Locations</span></th>
                <th style={{width: "5%"}}>Confidence</th>
                <th style={{width: "13%"}}>Tags</th>
                <th style={{width: "5%"}}>Order</th>
                <th style={{width: "7%"}}>Suggestions</th>
                <th style={{width: "10%", position:"sticky",right:"0px", backgroundColor:"#f8f8f8", 
                borderLeft:"2px solid rgb(182, 185, 187)",zIndex:"2"}}>Status</th>
            </TableHeads>
            <tbody>
              {pageData.items.map(item => {
              let highlightStyle = "color: #FFAC12;text-transform: capitalize";
              let typography = {className: 'text_body'}
              return (
                <TableRows key={item.id} rowClass="table_content_row_small text_body centered_td" customStyles={{border: "0.4px solid #B6B9BB", borderRadius: "4px"}}>
                  <td>
                    <Link to={`/prospects/${item.prospect_id}`} target="_blank" className="default_flex_container" style={{justifyContent: 'center'}}> 
                        <TextHighlighter contentString={item.name} typography={typography} toMatch={item.all_matched_value?.name} highlightStyle={highlightStyle}/>
                        {item.prospect_mapped && (<img src={SuccessGreen} style={{marginLeft: 4, width: 16, height: 16}}></img>)}
                    </Link>- <br/>
                    <a href={item.linkedin_url} target="_blank"> 
                        <TextHighlighter contentString={item.l_name} typography={typography} toMatch={item.all_matched_value?.name} highlightStyle={highlightStyle}/>
                    </a>
                  </td>
                  <td>
                    <TextHighlighter contentString={item.university} typography={typography} toMatch={item.all_matched_value?.university} highlightStyle={highlightStyle}/> - <br/>
                    <TextHighlighter contentString={item.l_university} typography={typography} toMatch={item.all_matched_value?.university} highlightStyle={highlightStyle}/>
                  </td>
                  <td>
                    <TextHighlighter contentString={item.grad_year} typography={typography} toMatch={item.all_matched_value?.grad_year} highlightStyle={highlightStyle}/> - <br/>
                    <TextHighlighter contentString={item.l_grad_year} typography={typography} toMatch={item.all_matched_value?.grad_year} highlightStyle={highlightStyle}/>
                  </td>
                  <td>
                  <TextHighlighter contentString={item.course} typography={typography} toMatch={item.all_matched_value?.grad_year} highlightStyle={highlightStyle}/> - <br/>
                    <TextHighlighter contentString={item.l_course} typography={typography} toMatch={item.all_matched_value?.grad_year} highlightStyle={highlightStyle}/>
                  </td>
                  <td>
                    <div className="default_flex_container" style={{justifyContent: 'center'}}>
                    <TextHighlighter contentString={item.companies} typography={typography} toMatch={item.all_matched_value?.companies} highlightStyle={highlightStyle}/>
                    </div>-<br/>
                    <div className="default_flex_container" style={{justifyContent: 'center'}}>
                    <TextHighlighter contentString={item.l_companies} typography={typography} toMatch={item.all_matched_value?.companies} highlightStyle={highlightStyle}/>
                    </div>
                  </td>
                  <td>
                    <div className="default_flex_container" style={{justifyContent: 'center'}}>
                    <TextHighlighter contentString={item.locations} typography={typography} toMatch={item.all_matched_value?.locations} highlightStyle={highlightStyle}/>
                    </div>-<br/>
                    <div className="default_flex_container" style={{justifyContent: 'center'}}>
                    <TextHighlighter contentString={item.l_locations} typography={typography} toMatch={item.all_matched_value?.locations} highlightStyle={highlightStyle}/>
                    </div>
                  </td>
                  <td>
                    {item.confidence}{item.confidence && '%'}
                  </td>
                  <td>
                    {item.tags}
                  </td>
                  <td>
                    {item.order}
                  </td>
                  <td>
                    {item.count} 
                  </td>
                  <td style={{position:"sticky",backgroundColor:"white",right:"0px",
                borderLeft:"2px solid rgb(182, 185, 187)",zIndex:"2"}}>
                    {item.mapped || mappingType === 'mapped' ? (
                        <div className="text_subtitle" style={{color: '#17BF63', textAlign: 'center'}}>
                            Mapped
                        </div>
                    ) : mappingType === 'unmapped' ? (
                        <div className="text_subtitle" style={{color: '#17BF63', textAlign: 'center'}}>
                            Unmapped
                        </div>
                    ) : (
                        <Button 
                            buttonSize="Small"
                            innerButtonStye={{padding: "7px 22px"}}
                            buttonStyle={{textAlign: 'center'}}
                            onClick={() => {setCurrItem(item);setPopupsList({mapConfirmationPopup: true})}}
                        >
                            Map
                        </Button>
                    )} 
                  </td>
                  </TableRows>
              )})}
            </tbody>
          </table>
          </div>
          </div>
        )
    }
    const dataExportedMessage = (message) => {
        setSuccessMessages({...successMessages, message: message})
    }
    const exportPopup = () => {
        return(
            <AnConfirmPopup 
                onSubmitCallback={() => handleExportMapperSuggestions()}
                onCancelCallback={handleOnClosePopup}
                name="Mapper Suggestions"
            >
            </AnConfirmPopup>
            )
    }
    const generateSuggestionPopup = () => {
        return(
            <AnConfirmPopup 
                onSubmitCallback={() => handleGenerateMapperSuggestions()}
                onCancelCallback={handleOnClosePopup}
                name="Generate Suggestions"
                confirm_message="Do you want to generate suggestions?"
                btnLabels={{submitLabel: 'Generate', cancelLabel: 'Cancel'}}
            >
            </AnConfirmPopup>
            )
    }
    const clearSuggestionPopup = () => {
        return(
            <AnConfirmPopup 
                onSubmitCallback={() => handleClearMapperSuggestions()}
                onCancelCallback={handleOnClosePopup}
                name="Clear Suggestions"
                confirm_message="Do you want to clear suggestions?"
                btnLabels={{submitLabel: 'Yes', cancelLabel: 'Cancel'}}
            >
            </AnConfirmPopup>
            )
    }
    const summaryPopup = () => {
        return(
            <AnPopup
                name="summaryPopup"
                handleOnClosePopup={handleOnClosePopup}
                maxWidth={480}
            >
                <div className="text_title">Summary</div>
                <div  className="line_seperator_ex_light_grey"  style={{ margin: "16px -24px" }}></div>
                <div className="text_body_light">Average Confidence of all Mapped profiles - <span className="text_body">{pageData.average_confidence_of_mapped}%</span></div>
                <div className="text_body_light" style={{paddingTop: 12}}>Number of Mapped prospects - <span className="text_body">{pageData.no_of_mapped}</span></div>
                <div className="text_body_light" style={{paddingTop: 12}}>Number of Unmapped prospects - <span className="text_body">{pageData.no_of_unmapped}</span></div>
                <div className="text_body_light" style={{paddingTop: 12}}>Number of prospects with 0 suggestions - <span className="text_body">{pageData.no_of_no_sugg}</span></div>
            </AnPopup>
        )
    }
    const toggleDropDown = () => setFiltersState({...filtersState, dropDownList: {...initialFilterState.dropDownList}})
    const renderSuggestionDropdown = () => {
        return (
            <AnDropDown style={{position: 'absolute', right: 0, padding: '20px', background: 'white', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', borderRadius: 4, width: 'max-content', zIndex: 99}}
                toggleDropDown={toggleDropDown}>
                <div  className="an_dropdown_filter text_body" style={{cursor: 'pointer'}} onClick={() => setPopupsList({summaryPopup: true})}>Summary</div>
                <div  className="an_dropdown_filter text_body" style={{cursor: 'pointer'}} onClick={() => setPopupsList({generateSuggestionPopup: true})}>Generate Suggestions</div>
                {/* <div className="an_dropdown_filter text_body" style={{cursor: 'pointer'}} onClick={() => setPopupsList({exportPopup: true})}>Download</div> */}
                <div 
                    className="an_dropdown_filter text_body_light red"
                    style={{cursor: 'pointer'}} onClick={() => setPopupsList({clearSuggestionPopup: true})}>Clear Suggestions
                </div> 
            </AnDropDown>
        )
    }

    return (
        <div>
            <MapperSuggestionFilter 
                filtersState={filtersState}
                setFiltersState={setFiltersState}
                submitCallback={() => fetchData(1)}
                activeTab={mappingType}
            />
            <div style={{ clear: 'both' }}></div>
            <div className='default_flex_container' style={{ marginTop: 24 }}>
                <div className="text_subtitle_big">{pageData.totalCount} Suggestions</div>
                <div style={{ flex: 1 }}>
                    <Pagination totalPages={pageData.totalPages}
                        currentPage={pageData.currentPage}
                        onPageChange={fetchData}
                    />
                </div>
                <ConditionalRender if={mappingType === 'algo_sugg'}>
                    <div onClick={() => {setPopupsList({exportPopup: true})}} className="default_flex_container"
                         style={{ cursor: "pointer", width: "36px", height: "36px", border: '1px solid #e6e6e6', padding: '6px 6px', background: "#F8F8F8", borderRadius: 3}}>
                        <img src={DownloadImage} style={{height: 20, width: 20}}></img>
                    </div>
                    <ConditionalRender if={!fromDirectory}>
                    <div onClick={() => setFiltersState({...filtersState, dropDownList: { ...filtersState.dropDownList, suggestionDropdown: true } } )}
                        style={{ marginLeft: 8, position: 'relative', cursor: "pointer", width: "36px", height: "36px", border: '1px solid #e6e6e6', padding: '8px', background: "#F8F8F8", borderRadius: 3 }}>
                        <img src={MenuDots} style={{ width: 20, height: 20, verticalAlign: 'baseline' }}></img>
                        {filtersState.dropDownList.suggestionDropdown && renderSuggestionDropdown()}
                    </div>
                    </ConditionalRender>
                </ConditionalRender>
            </div>
            <ConditionalRender if={pageData.isFetching}>
                <LoaderComponent isLoading={true}  loaderStyle={{width: 32, margin: '36px auto', display: 'block'}}/>
            </ConditionalRender>
            <ConditionalRender if={!pageData.isFetching}>
                {renderDataTable()}
            </ConditionalRender>
            {popupsList.exportPopup && exportPopup()}
            {popupsList.summaryPopup && summaryPopup()}
            {popupsList.generateSuggestionPopup && generateSuggestionPopup()}
            {popupsList.mapConfirmationPopup && mapConfirmationPopup()}
            {popupsList.clearSuggestionPopup && clearSuggestionPopup()}
            {
                successMessages.message && (<FlashMessages messageTheme='success' duration={successMessages.duration} closeCallback={dataExportedMessage}>{successMessages.message}</FlashMessages>)
            }
        </div>
    )
}

const getMappedFunctionData = (keys, valueData) => {
    let res = {};
    keys.forEach((key) => {
      switch (key) {
        case 'tags':
          res = { ...res, [key]: [...valueData.selectedOptions] };
          break;
        case 'status':
          res = { ...res, [key]: { ...valueData.mappedFilter }.key };
          break;
        case 'q':
          res = { ...res, [key]: valueData.name };
          break;
        default:
          if (valueData[key]) {
            res = { ...res, [key]: valueData[key] };
          }
          break;
      }
    });
    return res;
}

export default TempMapper