import React, { useEffect, useMemo, useReducer, useState } from 'react'
import AnPopup from '../../../cta_utils/AnPopup'
import { AnInputBox } from '../../../input_utils/AnInput'
import { AnInputCheckBox } from '../../../input_utils/AnInputCheckBox'
import { apiAgent } from '../../../../axiosConfig'
import reducer from '../../../utils/CommonReducer'
import getApiUrl from '../../../utils/UrlHelper'
import { Button } from '../../../cta_utils/AnButton'
import ConditionalRender from '../../../../ConditionalRender'
import { FlashMessages } from '../../../cta_utils/FlashMessages'
import { AppliedFilters } from '../../../utils/AppliedFilters'

const CreateChildList = ({
    filterState,
    current_list,
    ...props
}) => {
    const {parent_list_id, is_master_list, list_title} = current_list
    const [state, stateDispatcher] = useReducer(reducer, {
        createListInfo: {
            name: ""
        },
        isLoading: {},
        popupsList: {},
        errors: {},
        successMessages: {}
    });

    const appliedTags = useMemo(() => {
        const filterType = "include"
        let tags_params = Object.keys(filterState.filtersSelected[filterType]).reduce((r,tab_key) => {
            r[tab_key] = filterState.filtersSelected[filterType][tab_key].map(v => (typeof v.value === "object" ? JSON.stringify(v.value) : v.value));
            return r;
        }, {});
        return tags_params
    }, [filterState.filtersSelected]);

    const setState = (payload) => {
        stateDispatcher({type: "SET_STATE", payload})
    }
    const handlePopupClose = () => {
        setState({popupsList: {}});
    }
    const handleInputChange = (e) => {
        setState({createListInfo: {...state.createListInfo, [e.target.name]: e.target.value}})
    }

    const handeOnSubmit = () => {
        let errors = {}, error = false;
        let info = {...state.createListInfo}
        if(!info.name) {
          errors["name"] = "Name can't be blank";
          error = true;
        }
        if(error) {            
            setState({errors: errors})
            return;
        }
        const filterType = "include"
        let tags_params = Object.keys(filterState.filtersSelected[filterType]).reduce((r,tab_key) => {
            r[tab_key] = filterState.filtersSelected[filterType][tab_key].map(v => (typeof v.value === "object" ? JSON.stringify(v.value) : v.value));
            return r;
        }, {});
        let params = {
            ...info,
            parent_list_id: parent_list_id,
            tags: appliedTags
        }
        setState({errors: errors, isLoading: {createListLoading: true}});
        apiAgent({
            method: "post",
            url: getApiUrl("create_linkedin_list"),
            params: params
          }).then(res => {
            handlePopupClose();
            window.location = "/team/lists"
          }).catch(err => {
            let error_message = err.response.data.error_message;
            let payload = {isLoading: {createListLoading: false}, errors: {name: error_message}}
            setState(payload)
          })
    }
    const checkFilterPresent = () => {
        return filterState.totalAppliedFiltersCount > 0
    }
    const renderNofilter = () => {
        return (
            <div style={{margin: 'auto', textAlign: 'center'}}>
                <div className="line_seperator_ex_light_grey" style={{margin: "12px 0px 16px"}}></div>
                <div style={{padding: 16}}>
                    <div className='text_body_big' style={{fontWeight: 600, marginBottom: 8}}>No filters applied!</div>
                    <div className='text_body_light'>Apply filters and create new list</div>
                </div>
            </div>
        )
    }
    const createListPopup = () => {
        var createListInfo = {...state.createListInfo};
       return ( 
            <AnPopup name="createListPopup" handleOnClosePopup={() => handlePopupClose()} maxWidth={480} customStyle={{borderRadius: 4}}>
                <div className="text_title">Create list</div>
                <ConditionalRender if={!checkFilterPresent()}>
                    {renderNofilter()}
                </ConditionalRender>
                <ConditionalRender if={checkFilterPresent()}>
                    <div className='text_body_light' style={{marginTop: "4px"}}><span className='text_body'>{props.alumniCount} Alumni</span> match your selected criteria</div>
                    <div className="line_seperator_ex_light_grey" style={{margin: "12px 0px 16px"}}></div>
                    <div>
                        <div className='text_body_light'>Filters applied</div>
                        <AppliedFilters 
                            outerClass={"show_applied_filter_item"}
                            innerTextClass='text_subtitle_light'
                            showCancel={false}
                            filtersState={filterState}
                        >
                        </AppliedFilters>
                    </div>
                    <AnInputBox label={"List name"} error={state.errors["name"]} style={{marginTop: "16px"}}>
                        <input type="text" name="name" value={createListInfo.name} onChange={handleInputChange} placeholder="Name"/>
                    </AnInputBox>
                    <Button
                        onClick={() => {
                            handeOnSubmit()
                        }}
                        isLoading={state.isLoading.createListLoading}
                        innerButtonStye={{width: "max-content", padding: "8px 21px", height: "unset"}}
                        buttonStyle={{marginTop: 12}}
                    >
                        + Create list
                    </Button>
                </ConditionalRender>
                <ConditionalRender if={false}>
                    <div className="line_seperator_ex_light_grey" style={{marginTop: "8px"}}></div>
                    <div style={{width: "max-content", margin: "36px auto 24px", textAlign: "center"}}>
                        <div className='text_subtitle_big'>No filters applied!</div>
                        <div className='text_body_light'>Apply filters and create new list</div>
                    </div>
                </ConditionalRender>
            </AnPopup>
       )
    }

    return (        
        <div>
            <Button innerButtonStye={{width: "max-content", padding: "8px 21px"}}
                onClick={() =>{setState({popupsList: {showCreateListPopup: true}, createListInfo: {name: is_master_list ? "" : list_title}})}}
            >
                {is_master_list ? "+ Create List" : "Save as new list"}
            </Button>
            {state.popupsList.showCreateListPopup && createListPopup()}
            {state.successMessages.message && <FlashMessages messageTheme='success' firstIcon={true} closeCallback={() =>
              setState({type: "SET_STATE", payload: { successMessages: {}}})}>{state.successMessages.message}</FlashMessages>}
        </div>
    )
}

export default CreateChildList