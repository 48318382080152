import React, { createContext, useContext } from 'react';

const HighConfidenceContext = createContext();

export const useHighConfidenceContext = () => useContext(HighConfidenceContext);

export const HighConfidenceProvider = ({ children, shortProfileFields }) => {
    return (
        <HighConfidenceContext.Provider value={{ shortProfileFields }}>
            {children}
        </HighConfidenceContext.Provider>
    );
};
export default HighConfidenceContext;