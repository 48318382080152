import Cross from '../../assets/Cancel.svg'
import { useCallback, useState } from 'react';
import {debounce} from 'lodash'
import { apiAgent } from '../../axiosConfig';
import ConditionalRender from '../../ConditionalRender';
import { NameInitial } from "../cta_utils/AnNameInitial.js";
import AnProspectProfileCard from '../cta_utils/AnProspectProfileCard.js';

const MINCHAR = 2

const AnMultipleSuggestionInputBox = (props) => {  

    /* Props accepted
        suggestionUrl -> api endpoint
        selectedOptions -> Array of options that are slected
        callback -> a caalback to setSelectedOptions
        label(optional) -> label above input box
        inputClass(optional) -> if you want to include search lens inside box
        placeholder(optional) -> placeholder for box
        style(optional) -> style the component
    */
  const [fetchedRes, setFetchedRes] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [isFetching, setIsFetching] = useState(false)
  const [activeOption, setActiveOption] = useState(0)
  const [showOptions, setShowOptions] = useState(false);
  let theme = props.theme || "default";

  const debouncedHandleChange = useCallback(
    debounce((ip) => {
      setIsFetching(true)
      apiAgent({
          method: 'get',
          url: props.suggestionUrl,
          params: {
              query: ip,
              full_profile_info: true
          }
      }).then((res) => {
          setFetchedRes(res.data.data)
          setIsFetching(false)
          setShowOptions(true)
      }).catch(e => {
          setIsFetching(false)
          setShowOptions(true)
        });
    }, 300), [props.suggestionUrl])

  const handleOnClick = (e) => {
    let temp = [...props.selectedOptions, fetchedRes[activeOption]]
    props.callback(temp)
    setFetchedRes([])
    setActiveOption(0)
    setShowOptions(false)
    setUserInput("")
  }
  const handleOnChange = (e) => {
    let userInput = e.target.value
    setShowOptions(false)
    setUserInput(userInput)
    if(userInput.length >= MINCHAR){
      debouncedHandleChange(userInput)
    }

  }

  const handleOnKeyDown = (e) => {
    if (e.keyCode === 13 && fetchedRes.length > 0) { // Return
      props.callback(props.name, [...props.selectedOptions, fetchedRes[activeOption]])
      setFetchedRes([])
      setActiveOption(0)
      setShowOptions(false)
      setUserInput("")
    } else if (e.keyCode === 38) { // Up
      if (activeOption === 0) {
        return;
      }
      setActiveOption(activeOption - 1);
    } else if (e.keyCode === 40) { // Down
      if (activeOption === fetchedRes.length - 1) {
        return;
      }
      setActiveOption(activeOption + 1);
    }
  }

  const handleDeselect = (index) => {
    let newOptions = [...props.selectedOptions]
    newOptions.splice(index, 1);
    props.callback(newOptions)
  }

  let computed_classes = props.inputClass || 'an_input_box';
  computed_classes = props.error ? (computed_classes + ' an_input_error') : computed_classes
  

  const renderOptionList = () => {
    if(showOptions && fetchedRes.length > 0){
      return(
        <ul className="suggestions_container" style={{listStyle: "none"}}>
          {
            fetchedRes.map((optionName, index) => {
                let className = 'suggested_item text_body_light';
                if (index === activeOption) {
                    className += ' active_option text_body_light';
                }
                return (
                <li className={className} key={optionName.id} onClick={handleOnClick} onMouseEnter={() => {setActiveOption(index)}}>
                    {optionName.name || optionName.company}
                </li>
                );
            })
          }
        </ul>
      )
    }
    else if(showOptions){
      return(
        <ul className="suggestions_container" style={{listStyle: "none"}}>
          <li className="suggested_item" key="NoResults">No Results</li>
        </ul>
      )
    }
  }

  const renderSelectedOptions = () => {
      return(
          <div style={{display: 'flex', alignItems:' center', flexWrap: "wrap"}}>
             {
             props.selectedOptions.map((opt, index) => (
                  <div key={opt.id + index} style={{padding: '8px 12px', marginTop: 8, display: 'flex', alignItems: 'center', border: '1px solid #E7ECEB', borderRadius: 4}}>
                    <div className="text_subtitle_light">
                        {opt.name || opt.company}
                    </div>
                    <img src={Cross} style={{marginLeft: 8, width: 20}} onClick={() => {handleDeselect(index)}}/>
                  </div>
              )
            ) 
            }
          </div>
      )
  }

  const renderModernSelectedOptions = () => {
    return(
        <div style={{display: 'flex', alignItems:' center', flexWrap: 'wrap', marginTop: 4 }}>
           {
           props.selectedOptions.map((opt, index) => (
                    <div className="grid-container" style={{boxSizing: 'border-box', marginTop: '16px', gridTemplateColumns: '6fr 0fr'}}>
                        <div className="grid-item">
                            <div className="default_flex_container">
                              <AnProspectProfileCard
                                prospect={opt}
                              >
                                <a href={`/prospects/${opt.id}`} target='_blank' style={{textDecoration: 'none'}}>
                                <NameInitial
                                    name={opt.name || opt.company}
                                />
                                </a>
                              </AnProspectProfileCard>
                              <AnProspectProfileCard
                                prospect={opt}
                              >
                              <div style={{ marginLeft: "16px"}}>
                                  <a href={`/prospects/${opt.id}`} target='_blank' className="default_flex_container hover_under_line">
                                      <div className="text_subtitle_big">
                                          {opt.name}
                                      </div>
                                  </a>
                                  <div className="text_body_light truncate">
                                      {opt.company}
                                  </div>
                              </div>
                              </AnProspectProfileCard>
                            </div>
                        </div>
                        <div className="grid-item text_link" style={{color: "#EF5D60", cursor: "pointer", textAlign: "right", margin: "auto"}} onClick={() => handleDeselect(index)}>
                          <img src={Cross} style={{marginLeft: 8, width: 20}}/>
                        </div>
                    </div>
            )
          )
          }
        </div>
    )
}
  return(
    <div style={props.style}>
      <ConditionalRender if={props.label}>
        <div className="text_caption_light">
          {props.label}
        </div>
      </ConditionalRender>
      <div className={computed_classes}>
        <div style={{position: 'relative'}}>
          <input type="text" value={userInput} placeholder={props.placeholder}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}></input>
            <ConditionalRender if={props.inputClass}>
              <span className="an_search_lens">
                <img src="/search.svg" />
              </span>
            </ConditionalRender>
            <ConditionalRender if={isFetching}>
              <div style={{position: "absolute", width: 20, right: 8, top: 0, height: "100%", textAlign: "center", fontSize: 0}}>
                <div className="vertical_middle"></div>
                <div style={{display: "inline-block", verticalAlign: "middle"}} className="loader-green4">
                  <div className="loader-box loader_box1"></div>
                  <div className="loader-box loader_box2"></div>
                  <div className="loader-box loader_box3"></div>
                  <div style={{clear: "both"}}></div>
                </div>
              </div>
            </ConditionalRender>
        </div>
        {renderOptionList()}
        {(theme == "default")? renderSelectedOptions() : renderModernSelectedOptions()}
      </div>
    </div>  
  )
}

export default AnMultipleSuggestionInputBox