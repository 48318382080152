import ArchiveProspect from "../../../../../assets/help_center/archive_prospects.png"
import ArchivedProspects from "../../../../../assets/help_center/archived_prospects.png"
import ArchivedPID from "../../../../../assets/help_center/archived_pid.png"
import ArchivedList from "../../../../../assets/help_center/archived_list.png"
import BulkArchive from "../../../../../assets/help_center/bulk_archive.png"
import AnScrollToLinkPage from "../../../../cta_utils/AnScrollToLinkPage";

export const ArchiveProspects = () => {
    return (
        <div style={{ margin: "24px 0px" }}>
        <div className="help_center_top_section"> 
            <div className="text_title_big" style={{marginBottom: 20}}>Archiving Prospects</div>
            <div style={{border: "1px solid grey", padding: "14px 20px", borderRadius: "4px", fontWeight: "700"}}>
                <div className="text_body" style={{color: "#707274", fontWeight: 700}}> SKIP AHEAD TO</div>
                <div style={{marginTop: "12px"}}>
                    <span style={{color: "#437EFE", fontSize: "14px"}}><AnScrollToLinkPage href="#archive_prospect"> Archive individual prospect </AnScrollToLinkPage></span>
                    <span style={{color: "#437EFE", marginLeft: 20, fontSize: "14px"}}><AnScrollToLinkPage href="#bulk_archive"> Archiving prospects in bulk </AnScrollToLinkPage></span>
                </div>
            </div>
        </div>
            <div className="help_center_notes" style={{marginTop: 24}}>
                <div className="help_center_item" style={{ padding: "11px 14px"}}> Note: This action is only available to users with Admin access.</div>
            </div>

            <div className="help_center" id="archive_prospect">
                Archive individual prospect
            </div>
            <div className="help_center_text">
                Single prospect can be archive by clicking on “Archive” in the drop down next to their name on the prospects page. 
            </div>
            <img className="help_center_img" src={ArchiveProspect} style={{maxWidth: "556px", maxHeight: 242, margin: "24px auto"}}></img>
            
            <div className="help_center_text">
                All the archived prospects are saved in the ‘Archived Prospects’ page on the prospects page. 
            </div>


            <img className="help_center_img" src={ArchivedProspects} style={{maxWidth: "435px", maxHeight: "170px", margin: "24px auto"}}></img>
            <div style={{border: "1px solid #E7ECEB"}}></div>

            <div className="help_center" id="bulk_archive">
                Archiving prospects in bulk:
            </div>
            <div className="help_center_text">
                Bulk Archiving prospects is possible from the Archived Prospects page
            </div>
            <div className="help_center_text">
                To archive a prospect, it's necessary to include the Unique Identification Number (UIN) in the file. 
            </div>

            <img className="help_center_img" src={ArchivedPID} style={{maxWidth: "526px", maxHeight: "235px", margin: "24px auto"}}></img>
            <div className="help_center_text">
                As a quick reminder, you can export your already imported data along with the UINs by clicking on <span style={{fontWeight: 700}}>Download data</span> from the prospects page.
            </div>

            <div style={{display: "flex", gap: "10px"}}>
                <div style={{fontSize: 16, fontWeight: 600, lineHeight: "22px", marginTop: 24, flexBasis: 72}}>Step 1:</div>
                <div className="help_center_item" style={{ marginTop: 24 }}>Once the file ready with the UINs of all the prospects who are to be archived, click on ‘Archive’ on the Archived prospects page.</div>
            </div>
            <img className="help_center_img" src={ArchivedList} style={{maxWidth: "448px", margin: "24px auto"}}></img>

            <div style={{display: "flex", gap: "10px"}}>
                <div style={{fontSize: 16, fontWeight: 600, lineHeight: "22px", marginTop: 24, flexBasis: 72}}>Step 2:</div>
                <div className="help_center_item" style={{ marginTop: 24 }}>It will prompt you to upload the file with the header ‘prospect_id’.</div>
            </div>
            <img className="help_center_img" src={BulkArchive} style={{maxWidth: "501px", maxHeight: "296px", margin: "24px auto"}}></img>
            <div className="help_center_text">
                After you have uploaded the file, it usually takes some time to archive the prospect depending on the number of rows in the spreadsheet and the file size.
            </div>
        </div>
    );
};