import React, { useEffect, useMemo, useReducer } from 'react'
import ConditionalRender from '../../ConditionalRender'
import reducer from '../utils/CommonReducer'
import darkDropdown from "../../assets/darkDropdown.svg";
import { indexOfObject } from '../utils/ArrayUtils';

const AnSelectBox = ({
    inputClass="an_input_box",
    selectedValue="",
    placeholder="",
    options=[],
    optionStyle={},
    callback,
    ...props
}) => {
    const [componentState, setComponentState] = useReducer(reducer, {
        showOptions: false
    })
    const setState = (payload) => {
        setComponentState({
            type: "SET_STATE",
            payload
        })
    }

    const displayVal = useMemo(() => {
        if(selectedValue){
            return options[indexOfObject(options, "value", selectedValue)]?.label
        }
        return placeholder
    }, [selectedValue, placeholder, options])
    
    return (
        <div style={props.style}>
            <ConditionalRender if={props.label}>
                <div className="text_body_light">
                {props.label}
                </div>
            </ConditionalRender>
            <div className='flex_container_space_between an_select_box' style={{margin: "8px 0px", position: "relative", cursor: "pointer", border: props.error ? "1px solid #ef5d60" : "1px solid #b6b9bb"}} onClick={() => {setState({showOptions: !componentState.showOptions})}}>
                <div className={selectedValue ? "text_body" : "text_body_placeholder"}>{displayVal}</div>
                <img src={darkDropdown} style={{marginLeft: "4px"}} alt="v"></img>
                <ConditionalRender if={componentState.showOptions}>
                    <div className='box-shadow-content dropdown_options' style={optionStyle}>
                        {options.map(item => (
                            <div key={item.value} className="text_body" style={{paddingBottom: "16px"}} onClick={() => callback(item.value)}>
                                {item.label}
                            </div>
                        ))}
                    </div>
                </ConditionalRender>
            </div>
            <ConditionalRender if={props.error}>
                <div className="text_caption_error" style={{marginTop: 8}}>{props.error}</div>
            </ConditionalRender>
            {/* 
            <ConditionalRender if={props.helper}>
                <div className="text_caption_light">
                {props.helper}
                </div>
            </ConditionalRender> */}
        </div>
    )
}

export default AnSelectBox