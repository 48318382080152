import { useRef } from 'react';

export const useInterval = (callback, delay) => {
  const intervalIdRef = useRef(null);

  const start = () => {
    intervalIdRef.current = setInterval(callback, delay);
  }

  const stop = () => {
    clearInterval(intervalIdRef.current);
  }

  return [start, stop, intervalIdRef.current];
}