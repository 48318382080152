import React, { useReducer, useState } from 'react'
import AnPopup from '../../../../cta_utils/AnPopup'
import FileUpload from '../../../../../FileUpload'
import { useSelector } from 'react-redux'
import SuccessImage from "../../../../../assets/SuccessIcon.svg"

const ShareWithUsPopup = (props) => {
    const [submitSuccess, setSubmitSuccess] = useState(false)
    const current_user = useSelector(state => state.current_user.user)
    const current_team = useSelector(state => state.current_team.team)

    const renderForm = () => {
        return(
            <>
                <div className='text_title'>Share data</div>
                <div className='text_body_light' style={{marginTop: 2}}>Upload your prospect data here, and we'll add it to the team</div>
                <div className='line_seperator_ex_light_grey' style={{margin: "10px 0px 24px"}}></div>
                <FileUpload
                    submitUrl="/share_import_data"
                    uploaderSecondaryText={{content: "Supported formats: Excel (.xls and .xlsx) and .csv"}}    
                    style={{maxheight: "160px"}}
                    submitButtonSize="Big"
                    submitbuttonStyle={{float: "right"}}
                    innersubmitButtonStye={{padding: "8px 49px"}}
                    submitButtonText="Share"
                    prop_params={{name: current_user.name, email: current_user.email, institution: current_team.name, type: "Share data"}}
                    onSubmitFinishCallback={() => setSubmitSuccess(true)}
                />
            </>
        )
    }
    const renderSubmitSuccess = () => {
        return (
            <div>
                <div style={{margin: 'auto', maxWidth: 405}}>
                    <img src={SuccessImage} style={{width: 37, height: 47, margin: "auto", display: "block"}} />
                    <div style={{textAlign: 'center', marginTop: 8}}>
                        <div className="text_subtitle_big">Your data has been received</div>
                        <div className="text_body_light" style={{marginTop: 4}}>Please wait for sometime while we import your data</div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <AnPopup handleOnClosePopup={props.handleOnClosePopup} maxWidth={480}>
            {!submitSuccess && renderForm()}
            {submitSuccess && renderSubmitSuccess()}
        </AnPopup>
    )
}

export default ShareWithUsPopup