import React from 'react'

import ConditionalRender from './ConditionalRender'

class Pagination extends React.Component{
  get_page_range = (page, total_pages, size) => {
    page = parseInt(page);
    let start = 1;
    let end = total_pages;
    end = page+size > end ? end : page+size;
    start = page-size < start ? start : page-size;
    if(end-start < (2*size)){
      end = (start===1) ? (start+(2*size) > total_pages ? total_pages : start+(2*size)) : end;
      start = end===total_pages ? (end-(2*size) < 1 ? 1 : end-(2*size)) : start;
    }

    let ret = [];
    if (!end) {
      end = start;
      start = 0;
    }
    for (let i = start; i <= end; i++) {
      ret.push(i);
    }
    return ret;
  };

  calcPageRanges = () => {
    let {currentPage, totalPages} = this.props;
    let pages = this.get_page_range(currentPage, totalPages, 3);
    let showFirstLink = (pages[0] !== 1);
    let showLastLink = (pages[pages.length - 1] !== totalPages);
    this.setState({
      pages: pages,
      showFirstLink: showFirstLink,
      showLastLink: showLastLink
    });
  };

  constructor(props){
    super(props);
    this.state = {};
  }

  componentWillMount(){
    this.calcPageRanges();
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.currentPage !== this.props.currentPage) || (prevProps.totalPages !== this.props.totalPages)) {
      this.calcPageRanges();
    }
  }

  render(){
    let {currentPage, totalPages, onPageChange} = this.props;
    let {pages, showFirstLink, showLastLink} = this.state;
    if(!totalPages) return null;
    if(totalPages <= 1) return null;

    var pageNumbers = [];
    pages.forEach(function (page) {
        pageNumbers.push(
          <span style={{padding: '0px 4px', cursor: 'pointer', margin: '0px 4px'}}
            onClick={() => onPageChange(page)} key={page} className={currentPage === page ? "text_body": "text_body_light"}>
            {page}
          </span>
        );
    });
    let computed_style = {textAlign: 'center', padding: '8px', ...this.props.style}

    return (
      <div style={computed_style} className="text_body_light">
        <ConditionalRender if={currentPage !== 1}>
          <span onClick={() => this.props.onPageChange(currentPage - 1)} style={{cursor: 'pointer', padding: '8px'}}>
            Prev
          </span>
        </ConditionalRender>

        <span style={{padding: '0px 24px'}}>
          <ConditionalRender if={showFirstLink}>
            <span style={{padding: '0px 4px', cursor: 'pointer', margin: '0px 4px'}} onClick={() => this.props.onPageChange(1)}>
              1
            </span>
          </ConditionalRender>

          <ConditionalRender if={pages[0] !== 2 && showFirstLink}>
            <span>
              ...
            </span>
          </ConditionalRender>

          {pageNumbers}

          <ConditionalRender if={pages[pages.length-1] !== totalPages-1 && showLastLink}>
            <span>
              ...
            </span>
          </ConditionalRender>

          <ConditionalRender if={showLastLink}>
            <span style={{padding: '0px 4px', cursor: 'pointer', margin: '0px 4px'}} onClick={() => this.props.onPageChange(totalPages)}>
              {totalPages}
            </span>
          </ConditionalRender>
        </span>

        <ConditionalRender if={currentPage !== totalPages}>
          <span onClick={() => this.props.onPageChange(currentPage + 1)} style={{cursor: 'pointer', padding: '8px'}}>
            Next
          </span>
        </ConditionalRender>
      </div>
    );
  }
}

export default Pagination;
