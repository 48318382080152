import { Pagination } from "react-bootstrap"
import FeedPostErrorBoundary from "../../error_handlers/FeedPostErrorBoundary"
import Post from "../../feed_components/Post"
import { highConfidenceDataReducer, initialState } from "./reducers/highConfidenceReducer"
import { useEffect, useReducer } from "react"
import { apiAgent } from "../../../axiosConfig"
import useWindowSize from "../../custom_hooks/useWindowSize"
import useCheckUserAccess from "../../custom_hooks/useCheckUserAccess"
import ConditionalRender from "../../../ConditionalRender"
import { indexOfObject } from "../../utils/ArrayUtils"
import { FlashMessages } from "../../cta_utils/FlashMessages"
import { LoaderComponent } from "../../utils/LoaderComponent"

const Posts = ({
    fetchUrl,
    data_params={},
    from,
    ...props
}) => {
    const [pageData, pageDataReducer] = useReducer(highConfidenceDataReducer, initialState)  
    const windowDimensions = useWindowSize();
    const checkUserAccess = useCheckUserAccess();  
    let {error, isLoaded, items, totalPages , currentPage,tagsList, assigneeList} = pageData;
    useEffect(() => {
        fetchFeed(1);
    }, [])
    const fetchFeed = (page=1) => {
        pageDataReducer({type: 'SET_STATE', payload: {isLoaded: false}})
        apiAgent({
            method: 'get',
            url: fetchUrl,
            params: data_params,
        }).then((response)=>{
            let result = response.data;  
            pageDataReducer({type: 'SET_STATE', payload: {
                isLoaded: true,
                items: result.data,
                totalPages: result.total_pages,
                currentPage: page,
                tagsList: result.team_tags,
                assigneeList: result.team_members
            }})
        },
        (error) => {

        }
        )
    }
    const removeSavedPostCallBack = (item) => {
        let temp = [...items];
        let index = indexOfObject(temp, "id", item.id);
        let success_msg = {}
        if(index !== -1)
        {
            temp.splice(index, 1);
            success_msg = {
                message: "Removed from the profile",
                duration: 3000
            }
        }
        pageDataReducer({type: 'SET_STATE', payload: {
            items: temp,
            success_message: success_msg
        }})
    }
    return (
        <div>
            <ConditionalRender if={pageData.isLoaded}>
                <ConditionalRender if={items.length === 0}>
                    <div style={{marginTop: '100px', textAlign: 'center'}}>
                        <div className="text_body_big_bold" style={{marginBottom: 8}}>Nothing Saved yet</div>
                        <div className="text_body_light">All the saved items will show up here</div>
                    </div>
                </ConditionalRender>
                <div style={{ marginTop: "-8px" }}>
                    {items.map(item => (
                        <FeedPostErrorBoundary key={item.id}>
                            <Post item={item} 
                            tagsList={tagsList} 
                            assigneeList={assigneeList} 
                            windowDimensions={windowDimensions} 
                            checkUserAccess={checkUserAccess} 
                            from={from}
                            removeSavedPostCallBack={removeSavedPostCallBack}
                            fetchFeedCallBack={(curr_page = currentPage) => fetchFeed(curr_page)}
                            />
                        </FeedPostErrorBoundary>
                    ))}
                </div>
            </ConditionalRender>
            <div style={{ marginBottom: "24px" }}>
                <Pagination totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={fetchFeed}
                />
            </div>
            <ConditionalRender if={!pageData.isLoaded}>
                <LoaderComponent isLoading={!pageData.isLoaded}></LoaderComponent>
            </ConditionalRender>
            {pageData.success_message.message && <FlashMessages messageTheme='success' firstIcon={true} closeCallback={() =>
              pageDataReducer({type: 'SET_STATE', payload: {
                success_message: {}
            }})}>{pageData.success_message.message}</FlashMessages>}
        </div>
    )
}
export default Posts