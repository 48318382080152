const indexOfObject = (arr=[], key, toMatch) => {
    if(toMatch === undefined || toMatch === null) return -1;
    return arr.map(el => el[key]).indexOf(toMatch)
}

const swapItems = (arr=[], index1, index2) => {
    if (index1 >= 0 && index1 < arr.length && index2 >= 0 && index2 < arr.length) {
        [arr[index1], arr[index2]] = [arr[index2], arr[index1]];
    }
    return arr;
}

export { indexOfObject, swapItems }