import React, {useState, useEffect, useCallback, useReducer, useRef, useMemo} from 'react'
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux'
import { apiAgent } from '../../../../axiosConfig.js';
import { Button } from '../../../cta_utils/AnButton.js';
import { AnInputBox } from '../../../input_utils/AnInput.js';
import { AnInputCheckBox } from '../../../input_utils/AnInputCheckBox.js';
import Prospect from './components/Prospect.jsx'
import AnPopup from '../../../cta_utils/AnPopup.js';
import Pagination from "../../../../Pagination";
import AnDropDown from '../../../cta_utils/AnDropDown.js';
import FiltersIcon from '../../../../assets/FiltersIcon.svg'
import NoProspectsIcon from '../../../../assets/3UsersIcon.svg'
import info from '../../../../assets/Info.svg'
import { LoaderComponent } from '../../../utils/LoaderComponent.js';
import { Link } from 'react-router-dom';
import { AnInputRadioButton } from '../../../input_utils/AnInputRadioButton.js';
import { FlashMessages } from '../../../cta_utils/FlashMessages.js';
import { ProspectListFiltersTabs } from '../../../utils/AllFilterTabs.js';
import Uploader from '../../../upload/uploader.js';
import AdminProspectsOptions from './components/AdminProspectsOptions';
import ConditionalRender from '../../../../ConditionalRender.js';

import { initialFiltersState, prospectListFilterReducer as filterReducer} from '../../../admin_views/filters/ProspectBasedFilters/reducer/prospectsFilterReducer.js';
import { ProspectBasedFilters } from '../../../admin_views/filters/ProspectBasedFilters/filter/ProspectBasedFilters.js';
import { ProspectsListReducer, initialListState } from './reducers/prospectsListReducer.js';
import ALL_LIST_ACTIONS from './actions/prospectListActionTypes.js';
import { HelpCenterLinks } from '../../support_views/help_center/HelpCenterPage.js';
import { indexOfObject } from '../../../utils/ArrayUtils.js';
import SuccessMessagePopup from '../../../cta_utils/SuccessMessagePopup.js';
import { applyUrlFilters, getFilterUrl } from '../../../utils/ApplyUrlFilters.js';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min.js';
import useCheckUserAccess from '../../../custom_hooks/useCheckUserAccess.js';
import { AddTagPopup } from '../../../cta_utils/CommonPopups.js/AddTagPopup.js';
import { AddAssigneePopup } from '../../../cta_utils/CommonPopups.js/AddAssigneePopup.js';
import ShareWithUsPopup from './components/_ShareWithUsPopup.jsx';
import { MergeProspectPopup } from '../../profiles/prospectProfile/MergeProspectPopup.js';

const initFilters = (initState) => {
    return applyUrlFilters({...initState}, []);
}

const ProspectList = (props) => {
    const current_team = useSelector(state => state.current_team.team)
    const current_user = useSelector(state => state.current_user.user)
    // TODO: move archive change initial state to sideffects
    const [prospectsListData, prospectsListDispatcher] = useReducer(ProspectsListReducer, {...initialListState, archived: props.match.path.includes('archived') ? true : null})
    const [filtersState, filtersStateReducer] = useReducer(filterReducer , initialFiltersState, initFilters)
    const uploadFileRef = useRef(null);
    const compInitLoaded = useRef();

    const history = useHistory()
    const historyLocation = useLocation();
    const checkUserAccess = useCheckUserAccess();

    useEffect(() => {
        let new_url = getFilterUrl(filtersState.filtersSelected, historyLocation.pathname, [], {})
        history.replace(new_url, null);
    }, [filtersState.filtersSelected]);
    useEffect(() => {
        fetchProspects();
    },[prospectsListData.prospectType , filtersState.filtersSelected, prospectsListData.archived])

    const handleOnChange = (e) => {
        prospectsListDispatcher({type: ALL_LIST_ACTIONS['SET_STATE'], payload: {searchTerm: e.target.value}})
    }
    const handleOnKeyDown = (e) => {
        if(e.key == 'Enter'){
            fetchProspects(prospectsListData.currentPage);
        }
    }
    const fetchProspects = (page=1) => {
        if(!compInitLoaded.current && historyLocation.state?.filtersState){
            filtersStateReducer({
                type: "SET_STATE",
                payload: historyLocation.state.filtersState
            })
            compInitLoaded.current=true;
            return
        }
        prospectsListDispatcher({
            type: ALL_LIST_ACTIONS['SET_STATE'],
            payload: {isFetching: true}
        })
        let {filtersSelected} = filtersState
        let data_params = {
            query: prospectsListData.searchTerm,
            tags: filtersSelected.tags,
            assigned: filtersSelected.assigns,
            title: filtersSelected.title,
            company: filtersSelected.company,
            college: filtersSelected.college,
            location: filtersSelected.location,
            miscellaneous: filtersSelected.miscellaneous,
            type: prospectsListData.prospectType,
            archived: prospectsListData.archived,
            page: page
        }
        prospectsListData.allFilterTabs.filter(item => item.is_custom).forEach(element => {
            if(filtersSelected[element.tab_key]?.length > 0){
                if(!data_params["custom_answers"]) data_params["custom_answers"] = {};
                data_params["custom_answers"][element.tab_key] = filtersSelected[element.tab_key]
            }
        });        

        apiAgent({
            method: 'get',
            url: prospectsListData.archived ? '/prospects/archived_prospects' : '/prospects',
            params: {...data_params}

        }).then(res => {
            let payload = {
                isFetching: false,
                prospectList: res.data.data || [],
                currentPage: page,
                totalPages: res.data.total_pages,
                allFilterTabs: res.data.all_filter_tabs,
                total: res.data.total
            };
            if(prospectsListData.tagsList.length == 0) payload.tagsList = res.data.team_tags;
            if(prospectsListData.assigneeList.length == 0) payload.assigneeList = res.data.team_members;
            prospectsListDispatcher({
                type: ALL_LIST_ACTIONS['SET_STATE'],
                payload: payload
            })
            filtersStateReducer({type: "ASSIGN_AGGREGATES_DATA", payload: {aggregates: res.data.aggregates}})
        }).catch(err => {
            prospectsListDispatcher({
                type: ALL_LIST_ACTIONS['SET_STATE'],
                payload: {
                    isFetching: false
                }
            })
            console.log("From prosPectList comp bcz of this", err);
        })
    }

    const downloadProspects = () => {
        prospectsListDispatcher({
            type: ALL_LIST_ACTIONS['SET_STATE'],
            payload: {isLoading: true}
        })
        let {filtersSelected} = filtersState
        let data_params = {
            query: prospectsListData.searchTerm,
            tags: filtersSelected.tags,
            assigned: filtersSelected.assigns,
            title: filtersSelected.title,
            company: filtersSelected.company,
            college: filtersSelected.college,
            miscellaneous: filtersSelected.miscellaneous,
            location: filtersSelected.location,
            type: prospectsListData.prospectType
        }
        if(prospectsListData.archived) data_params.archived = true
        prospectsListData.allFilterTabs.filter(item => item.is_custom).forEach(element => {
            if(filtersSelected[element.tab_key]?.length > 0){
                if(!data_params["custom_answers"]) data_params["custom_answers"] = {};
                data_params["custom_answers"][element.tab_key] = filtersSelected[element.tab_key]
            }
        });
        data_params["custom_answers"] = data_params["custom_answers"] ? JSON.stringify(data_params["custom_answers"]) : null
        apiAgent({
            method: 'post',
            url: '/prospects/download',
            data: {
                ...data_params
            }
        }).then(res => {
            let payload = {
                isLoading: false, //TODO: change from isLoading to maybe something meaningful
                successMessages:{
                    message: "You will receive an email when the export is ready to download",
                    duration: 3000,
                    from: "Data Exports"
                },
            }
            prospectsListDispatcher({
                type: ALL_LIST_ACTIONS['SET_STATE'],
                payload: payload
            })
        }).catch(err => {
            prospectsListDispatcher({
                type: ALL_LIST_ACTIONS['SET_STATE'],
                payload: {
                    isLoading: false
                }
            })
        })
    }

    const onBulkDeleteUploadFinishCallback = () => {
        apiAgent({
            method: "post",
            url: "/prospects/bulk_delete",
            data: {
                csv_url: prospectsListData.importFile.uploadedUrl,
            },
        })
            .then((res) => {
                let payload = {
                    isUploading: false,
                    successMessages: {
                        message: "Bulk deletion requested. This may take some time.",
                        from: "Bulk Delition"
                    },
                    popupsList: {},
                    importFile:{
                        files: [],
                        names: [],
                        uploaded: false,
                        uploadedUrl: null
                    }
                }
                prospectsListDispatcher({
                    type: ALL_LIST_ACTIONS['SET_STATE'],
                    payload: payload
                })
            })
            .catch((err) => {
                prospectsListDispatcher({
                    type: ALL_LIST_ACTIONS['SET_STATE'],
                    payload: {
                        isUploading: false
                    }
                })
            });
    }

    const handleProspectType = (e) => {
        prospectsListDispatcher({
            type: ALL_LIST_ACTIONS['SET_STATE'],
            payload: {
                prospectType: e.target.getAttribute('value')
            }
        })
    }
    const handleNewProspectInputChange = (e) => {
        prospectsListDispatcher({
            type: ALL_LIST_ACTIONS['SET_NEW_PROSPECT'],
            payload: {
                [e.target.name]: e.target.value
            }
        })
    }

    const handlePopupClose = () => {
        prospectsListDispatcher({
            type: ALL_LIST_ACTIONS['SET_STATE'],
            payload: {
                popupsList: {},
                currentTags: prospectsListData.currentProspect?.tags?.map(val => val.id) || []
            }
        })
    }

    const archiveProspect = () => {
        prospectsListDispatcher({
            type: ALL_LIST_ACTIONS['SET_STATE'],
            payload: {
                isLoading: true,
            }
        })
        apiAgent({
            method: 'post',
            url: `/prospects/${prospectsListData.currentProspect.id}/archive`,
            data: {}
        }).then(res => {
            let ind = indexOfObject(prospectsListData.prospectList, "id", prospectsListData.currentProspect?.id)
            let temp = [...prospectsListData.prospectList]
            if(ind != -1) temp.splice(ind, 1);
            let payload = {
                isLoading: false,
                successMessages: {
                    message: "Prospect archived",
                    from: "Prospect Archived",
                },
                prospectList: temp
            }
            prospectsListDispatcher({
                type: ALL_LIST_ACTIONS['SET_STATE'],
                payload: payload
            })
        }).catch(err => {
            prospectsListDispatcher({
                type: ALL_LIST_ACTIONS['SET_STATE'],
                payload: {
                    isLoading: false,
                }
            })
        })
    }

    const onAddProspect = () => {
        let errors = validateNewProspectForm()
        if(Object.keys(errors).length > 0){
            prospectsListDispatcher({
                type: ALL_LIST_ACTIONS['SET_STATE'],
                payload: {
                    errors: errors,
                }
            });
            return;
        }else{
            prospectsListDispatcher({
                type: ALL_LIST_ACTIONS['SET_STATE'],
                payload: {
                    errors: errors,
                    addingProspect: true
                }
            });
        }
        apiAgent({
            method: 'post',
            url: '/prospects',
            data: {...prospectsListData.newProspect, team_id: current_team?.id}
        }).then(res => {
            let payload = {
                popupsList: {},
                prospectList: [...prospectsListData.prospectList , {...res.data}],
                newProspect: {},
                addingProspect: false,
                errors: {},
                successMessages:{
                    message: "Prospect added",
                    from: "Prospect Added",
                },
            }
            prospectsListDispatcher({
                type: ALL_LIST_ACTIONS['SET_STATE'],
                payload: payload
            })
        }).catch(err => {
            let error_message = err.response.data.error_message;
            prospectsListDispatcher({
                type: ALL_LIST_ACTIONS['SET_STATE'],
                payload: {
                    errors: {serverError: error_message},
                    addingProspect: false
                }
            })
        })
    }

    const handleSettingCurrentProspect = (prospect) => {
        let payload = {
            prospectDropDown: !prospectsListData.prospectDropDown,
            currentProspect: prospect,
            currentTags: prospect?.tags?.map(val => val.id) || [],
            currentAssigns: prospect?.assigns?.map(val => val.id) || [],
        }
        prospectsListDispatcher({
            type: ALL_LIST_ACTIONS['SET_STATE'],
            payload: payload
        })
    }

    const handleInputChange = (e) => {
        prospectsListDispatcher({
            type: ALL_LIST_ACTIONS['SET_STATE'],
            payload: {
                currentProspect: {...prospectsListData.currentProspect, [e.target.name]: e.target.value }
            }
        })
    }

    const onAddedTagCallback = (callbackData) => {
        let ind = indexOfObject(prospectsListData.prospectList, "id", prospectsListData.currentProspect?.id)
        let temp = [...prospectsListData.prospectList]
        temp[ind].tags = prospectsListData.tagsList.filter(tag => prospectsListData.currentTags.indexOf(tag.id) != -1 )
        temp[ind] = {...temp[ind], ...callbackData}
        let payload = {
            isLoading: false,
            successMessages: {
                message: `${temp[ind].tags.length <= 1 ? "Tag" : "Tags"} added to prospect`,
                from: "Tags added",
            },
            popupsList: {},
            prospectList: temp
        }
        prospectsListDispatcher({
            type: ALL_LIST_ACTIONS['SET_STATE'],
            payload: payload
        })
    }

    const onAddedAssigneeCallback = () => {
        let ind = indexOfObject(prospectsListData.prospectList, "id", prospectsListData.currentProspect?.id)
        let temp = [...prospectsListData.prospectList]
        temp[ind].assigns = prospectsListData.assigneeList.filter(a => prospectsListData.currentAssigns.includes(a.id))
        let payload = {
            isLoading: false,
            successMessages: {
                message: `Team ${temp[ind].assigns.length > 1 ? "members" : "member"} assigned to prospect`,
                from: "Assigned",
            },
            popupsList: {},
            prospectsListData: temp
        }
        prospectsListDispatcher({
            type: ALL_LIST_ACTIONS['SET_STATE'],
            payload: payload
        })
    }

    const onAddboost = () => {
        prospectsListDispatcher({
            type: ALL_LIST_ACTIONS['SET_STATE'],
            payload: {
                isLoading: true
            }
        })
        apiAgent({
            method: 'post',
            url: '/prospects/update',
            data: {
                boost: prospectsListData.currentProspect.boost,
                prospect_id: prospectsListData.currentProspect.id
            }
        }).then(res => {
            let ind = indexOfObject(prospectsListData.prospectList, "id", prospectsListData.currentProspect?.id)
            let temp = [...prospectsListData.prospectList]
            temp[ind].boost = prospectsListData.currentProspect.boost
            let payload = {
                isLoading: false,
                successMessages: {
                    message: 'Boost added',
                    from: "Boost added",
                },
                prospectsListData: temp,
                popupsList: {},
            }

            prospectsListDispatcher({
                type: ALL_LIST_ACTIONS['SET_STATE'],
                payload: payload
            })

        }).catch(err => {
            prospectsListDispatcher({
                type: ALL_LIST_ACTIONS['SET_STATE'],
                payload: {
                    isLoading: false
                }
            })
        })
    }

    const bulkArchiveFinishPopup = () => {
        return (
            <SuccessMessagePopup maxWidth={440}>
                <div className='text_body_light'>
                    We are processing your request. Please check back later.
                </div>
            </SuccessMessagePopup>
        )
    }

    const onBulkArchiveFinishCallback  = () => {
        let data_params = {
          csv_url: prospectsListData.importFile.uploadedUrl
        }
        if(prospectsListData.archived) data_params.action_type = prospectsListData.bulk_archive_type;
        apiAgent({
            method: "post",
            url: "/prospects/bulk_archive",
            data: data_params,
        })
            .then((res) => {
                prospectsListDispatcher({
                    type: ALL_LIST_ACTIONS['SET_STATE'],
                    payload: {
                        isUploading: false,
                        popupsList: {bulkArchiveFinishPopup: true},
                        importFile: {
                            files: [],
                            names: [],
                            uploaded: false,
                            uploadedUrl: null,
                        }
                    }
                })
                setPopupsList({bulkArchiveFinishPopup: true})
            })
            .catch((err) => {
                prospectsListDispatcher({
                    type: ALL_LIST_ACTIONS['SET_STATE'],
                    payload: {
                        isUploading: false
                    }
                })
            });
    }

    const validateNewProspectForm = () => {
        let errors = {}
        if(!prospectsListData.newProspect.name) errors.name = "Name can't be blank"
        return errors;
    }

    const setPopupsList = (popup) => {
        prospectsListDispatcher({
            type: ALL_LIST_ACTIONS['SET_STATE'],
            payload: {
                popupsList: {...popup}
            }
        })
    }

    const setImportFile = (new_file) => {
        prospectsListDispatcher({
            type: ALL_LIST_ACTIONS['SET_STATE'],
            payload: {
                importFile: {...new_file}
            }
        })
    }

    const setIsUploading = (status) => {
        prospectsListDispatcher({
            type: ALL_LIST_ACTIONS['SET_STATE'],
            payload: {
                isUploading: status
            }
        })
    }

    const setProspectAddedMessage = (message) => {
        let payload = {
            successMessages: {...prospectsListData.successMessages, message: message},
        }

        prospectsListDispatcher({
            type: ALL_LIST_ACTIONS['SET_STATE'],
            payload: payload
        })
    }

    const renderSearchBar = () => {
        return(
            <div className="an_search_box" style={{fontSize: 14, width: "100%"}}>
                <div style={{position: 'relative'}}>
                    <input type="text" value={prospectsListData.searchTerm} placeholder="Search name, company or unique ID" onChange={handleOnChange} onKeyDown={handleOnKeyDown}/>
                    <span className="an_search_lens">
                        <img src="/search.svg" />
                    </span>
                </div>
            </div>
        )
    }

    const renderProspectDropdown = () => {
        return(
            <AnDropDown style={{position: 'absolute', right: 0, padding: '20px', background: 'white', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', borderRadius: 4, width: 'max-content', zIndex: 99}}
            toggleDropDown={() => handleSettingCurrentProspect({})}>
                <ConditionalRender if={checkUserAccess("merge_prospect")}>
                    <div style={{marginBottom: 16,cursor: 'pointer'}} onClick={() => setPopupsList({mergeProspectPopup: true})}>
                        Merge prospect
                    </div>
                </ConditionalRender>
                <div style={{marginBottom: 16, cursor: 'pointer'}} onClick={() => {setPopupsList({addTagPopup: true})}}>
                Add Tag
                </div>
                <div style={{cursor: 'pointer'}} onClick={() => {setPopupsList({addAssigneePopup: true})}}>
                Assign Team Member
                </div>
                <ConditionalRender if={checkUserAccess("prospect_archiving")}>
                    <div style={{marginTop: 16, cursor: 'pointer'}} onClick={() => {archiveProspect()}}>
                        Archive
                    </div>
                </ConditionalRender>
                <ConditionalRender if={current_user.is_super_admin || current_user.is_publisher}>
                <div style={{marginTop: 16, cursor: 'pointer'}} onClick={() => {setPopupsList({boostPopup: true})}}>
                    Boost
                </div>
                </ConditionalRender>
            </AnDropDown>
        )
    }

    const renderExtraOptions = () => {
        if(prospectsListData.archived) return renderExtraArchivedDropdown();
        return renderExtraProspectDropdown();
    }

    const renderExtraProspectDropdown = () => {
        return(
          <div className='extra_option_container'>
            <ConditionalRender if={checkUserAccess("prospect_archiving")}>
            <div>
            <Link to="/prospects/archived" className="text_body_big" style={{ textDecoration: 'none', display: 'block'}}>
                Archived Prospects
            </Link>
            </div>
            </ConditionalRender>
            <ConditionalRender if={checkUserAccess("prospect_data_download")}>
            <div className="text_body_big" style={{ cursor: 'pointer'}} onClick={() => {downloadProspects()}}>
                Download Prospects
            </div>
            </ConditionalRender>
            <ConditionalRender if={checkUserAccess("prospect_bulk_delete")}>
                <div className="text_body_big" style={{cursor: 'pointer'}} onClick={() => setPopupsList({bulkDelitionPopup: true})}>
                    Bulk Delete Prospects
                </div>
            </ConditionalRender>
          </div>
        )
      }

    const renderExtraArchivedDropdown = () => {
            return(
                <div className='extra_option_container'>
                    <ConditionalRender if={checkUserAccess("prospect_bulk_archive")}>
                    <div className="text_body_big" style={{ cursor: 'pointer'}}
                        onClick={() => {
                            prospectsListDispatcher({
                                type: ALL_LIST_ACTIONS['SET_STATE'],
                                payload: {
                                    bulk_archive_type: 'unarchive',
                                    popupsList: {
                                        bulkArchivePopup: true
                                    }
                                }
                            })}
                    }>
                        Unarchive prospects
                    </div>
                    </ConditionalRender>
                    <ConditionalRender if={checkUserAccess("prospect_data_download")}>
                    <div className="text_body_big" style={{cursor: 'pointer'}} onClick={downloadProspects}>
                        Download archived data
                    </div>
                    </ConditionalRender>
                </div>
            )
    }

    const addProspectPopup = () => {
        let {errors} = prospectsListData
        return(
            <AnPopup name="addProspectPopup" handleOnClosePopup={handlePopupClose}>
                <div className="text_title">Add Prospect</div>
                <div className="text_body" style={{marginTop: 4}}>You can add your custom fields in <Link className="text_link hover_under_line" to="/settings/data_fields" >data fields section</Link></div>
                <div style={{margin: '16px 0px', height: 1, background: '#E7ECEB'}}></div>
                {/*
                DONT FORGET TO USE ANINPUTRADIO component when you make this live again
                <AnInputBox label="I want to add">
                    <div style={{display: 'flex'}}>
                        <input type="radio" id="for_individual" name="prospect_type" checked={newProspect.type == 'Individual'} onChange={(e) => {setNewProspect({...newProspect, type: 'Individual'})}}/>
                        <label htmlFor="for_individual" className="text_subtitle_semi_bold">Individual</label>
                        <input type="radio" id="for_organization" name="prospect_type" style={{marginLeft: 24}} checked={newProspect.type == 'Organization'} onChange={(e) => {setNewProspect({...newProspect, type: 'Organization'})}}/>
                        <label htmlFor="for_organization" className="text_subtitle_semi_bold">Organization</label>
                    </div>
                </AnInputBox> */}
                <AnInputBox label="Name*" error={prospectsListData.errors.name}>
                    <input type="text" onChange={handleNewProspectInputChange} name="name" value={prospectsListData.newProspect.name}></input>
                </AnInputBox>
                <div style={{maxWidth: 240}}>
                    <AnInputBox label="Company">
                        <input type="text" onChange={handleNewProspectInputChange} name="company" value={prospectsListData.newProspect.company}></input>
                    </AnInputBox>
                    <AnInputBox label="Title/Position">
                        <input type="text" onChange={handleNewProspectInputChange} name="title" value={prospectsListData.newProspect.title}></input>
                    </AnInputBox>
                    <AnInputBox label="College">
                        <input type="text" onChange={handleNewProspectInputChange} name="college" value={prospectsListData.newProspect.college}></input>
                    </AnInputBox>
                    <AnInputBox label="Location">
                        <input type="text" onChange={handleNewProspectInputChange} name="location" value={prospectsListData.newProspect.location}></input>
                    </AnInputBox>
                </div>
                <Button buttonStyle={{marginTop: 22}} onClick={onAddProspect} isLoading={prospectsListData.addingProspect}>Add Prospect</Button>
                <ConditionalRender if={errors.serverError}>
                    <div className="text_caption_error" style={{marginTop: 8}}>{errors.serverError}</div> 
                </ConditionalRender>
            </AnPopup>
        )
    }

    const boostPopup = () => {
        return(
            <AnPopup name="boostPopup" handleOnClosePopup={handlePopupClose}>
                <div className="text_title">
                    Edit Boost
                </div>
                <div style={{marginTop: 16, marginBottom: 8, height: 1, background: '#E7ECEB'}}></div>
                <AnInputBox label="Boost">
                    <input type="text" name="boost" value={prospectsListData.currentProspect.boost} onChange={handleInputChange}></input>
                </AnInputBox>
                <div style={{display: 'flex', marginTop: 16}}>
                    <Button onClick={onAddboost} isLoading={prospectsListData.isLoading}>Save</Button>
                    <Button type="Secondary" onClick={handlePopupClose} buttonStyle={{marginLeft: 8}}>Cancel</Button>
                </div>
            </AnPopup>
        )
    }

    const renderBulkDelitionPopup = () => {
        return(
            <AnPopup name="bulkDeletePopup" handleOnClosePopup={handlePopupClose}>
                <div className="text_title">
                    Bulk Delete
                </div>
                <div className="text_body" style={{marginTop: 8}}>
                  Uploaded .csv file should have a 'id' column with ids of all the prospects to be deleted.
                </div>
                <div className='file_upload_container' style={{ marginTop: 32, padding: "44px 0px 28px" }}>
                    {prospectsListData.importFile.uploaded ? (
                            <div
                                className="text_body_light"
                                style={{ marginBottom: 10 }}
                            >
                                {prospectsListData.importFile.names[0]} File Uploaded
                            </div>
                        ) : null}
                        <div style={{ zIndex: 99 }}>
                            <Button
                                buttonSize="Medium"
                                onClick={() => uploadFileRef.current?.click()}
                                isLoading={prospectsListData.isUploading}
                                innerButtonStye={{
                                    padding: "8px 25px",
                                    textTransform: "none",
                                }}
                            >
                                Upload file
                            </Button>
                        </div>
                    <Uploader
                        selectedFile={prospectsListData.importFile}
                        setSelectedFile={setImportFile}
                        isRefedTo={uploadFileRef}
                        onUploadBeginCallback={() => setIsUploading(true)}
                        onUploadFinishCallback={onBulkDeleteUploadFinishCallback}
                    >
                    </Uploader>
                    <div className="text_body_light" style={{ marginTop: 8 }}>
                        or drag a file here.
                    </div>
                </div>
            </AnPopup>
        )
    }

    const renderProspectList = () => {
        if(prospectsListData.prospectList.length == 0){
            return(
                <div style={{margin: "124px auto"}}>
                    <img src={NoProspectsIcon} style={{width: 200, display: 'block', margin: 'auto'}}>
                    </img>
                    <div className="text_body_big_light_bold" style={{marginTop: 28, textAlign: 'center'}}>
                        No prospects added yet
                    </div>
                    { !prospectsListData.archived &&
                    <div className='text_subtitle_light' style={{textAlign: 'center', marginTop: 8}}>
                        <ConditionalRender if={checkUserAccess("prospect_data_import")}>
                        <Link to="/import_prospects" className='text_link'>Import prospects</Link>
                        </ConditionalRender>
                        <span> or </span>
                        <span className='text_link' style={{textDecoration: 'underline'}}
                            onClick={() => setPopupsList({addProspectPopup: true})}>
                            Add prospect
                        </span>
                    </div>
                    }
                </div>
            )
        }
        return(
            prospectsListData.prospectList.map((item, index) => (
                <Prospect key={item.id} prospect={item}
                  currentProspect={prospectsListData.currentProspect}
                  setCurrentProspect={handleSettingCurrentProspect}
                  prospectDropDown={prospectsListData.prospectDropDown}
                  renderProspectDropdown={renderProspectDropdown}
                  archived={prospectsListData.archived}
                  prospectsListDispatcher={prospectsListDispatcher}
                  filtersState={filtersState}
                >
                </Prospect>
            ))
        )
    }
    const renderBulkArchivePopup = () => {
      let popup_title = "Bulk Archive";
      let instruction = "Fill up the Prospect IDs of those who are to be archived. Prospect IDs can be downloaded from the Prospects page."
      if(prospectsListData.bulk_archive_type == 'unarchive'){
        popup_title = "Bulk Unarchive";
        instruction = "Fill up the Prospect IDs of those who are to be unarchived. Archived prospect IDs can be downloaded from the Archived prospects page.";
      }
      return(
            <AnPopup name="bulkArchivePopup" handleOnClosePopup={handlePopupClose}>
                <div className="text_title">
                    {popup_title}
                </div>
                <div style={{height: 1, background: '#E7ECEB', margin: '16px 0px'}}></div>
                <div className="text_subtitle" style={{marginTop: 8}}>
                  Procedure for bulk archive through csv:
                </div>
                <ol className="text_body_light" style={{paddingLeft: 20, marginTop: 8}}>
                  <li>
                    Create an excel file with header ‘prospect_id’.
                  </li>
                  <li>
                    {
                        instruction
                    }
                  </li>
                  <li>
                    Save as CSV & Upload.
                  </li>
                </ol>
                <div className='file_upload_container' style={{ marginTop: 16, padding: "44px 0px 28px" }}>
                    {prospectsListData.importFile.uploaded ? (
                            <div
                                className="text_body_light"
                                style={{ marginBottom: 10 }}
                            >
                                {prospectsListData.importFile.names[0]} File Uploaded
                            </div>
                        ) : null}
                        <div style={{ zIndex: 99 }}>
                            <Button
                                buttonSize="Medium"
                                onClick={() => uploadFileRef.current?.click()}
                                isLoading={prospectsListData.isUploading}
                                innerButtonStye={{
                                    padding: "8px 25px",
                                    textTransform: "none",
                                }}
                            >
                                Upload file
                            </Button>
                        </div>
                    <Uploader
                        selectedFile={prospectsListData.importFile}
                        setSelectedFile={setImportFile}
                        isRefedTo={uploadFileRef}
                        onUploadBeginCallback={() => setIsUploading(true)}
                        onUploadFinishCallback={onBulkArchiveFinishCallback}
                    >
                    </Uploader>
                    <div className="text_body_light" style={{ marginTop: 8 }}>
                        or drag a file here.
                    </div>
                </div>
            </AnPopup>
        )
    }
    const renderMainButton = () => {
      if(!prospectsListData.archived){
        return (
            checkUserAccess('prospect_data_import') ? 
          <Button  buttonSize="LessPadding" dropOptions={[
              {
                  checkUserAccess: checkUserAccess('prospect_data_import'),
                  label: 'Import prospect data',
                  onClick: () => {history.push('/import_prospects')}
              },
              {
                  checkUserAccess: true,
                  label: 'Add prospect',
                  onClick: () => {setPopupsList({addProspectPopup: true})}
              },
              {
                checkUserAccess: true,
                label: 'Share your data with us',
                onClick: () => {setPopupsList({shareWithUsPopup: true})}
              }
          ]}
          dropDownTextClass="text_subtitle_medium"
          >Add Prospect</Button>
          : 
                      <Button buttonSize="LessPadding"
                onClick={() =>
                    prospectsListDispatcher({
                        type: ALL_LIST_ACTIONS['SET_STATE'],
                        payload: {
                            popupsList: {addProspectPopup: true}
                        }
                    })}
            >
                Add prospect
            </Button>
        
        )
      }else{
        return (
            <ConditionalRender if={checkUserAccess('prospect_bulk_archive')}>
                <Button buttonSize="LessPadding"
                    onClick={() =>
                        prospectsListDispatcher({
                            type: ALL_LIST_ACTIONS['SET_STATE'],
                            payload: {
                                bulk_archive_type: 'archive',
                                popupsList: {bulkArchivePopup: true}
                            }
                        })}
                >
                    Archive
                </Button>
            </ConditionalRender>
        )
      }
    }

    const extraOptionCond = useMemo(() => {
        if(prospectsListData.archived) 
        return checkUserAccess('prospect_bulk_archive') || checkUserAccess('prospect_data_download')

        return checkUserAccess('prospect_archiving') || checkUserAccess('prospect_data_download') || checkUserAccess('prospect_bulk_delete')
    }, [prospectsListData.archived, checkUserAccess])

    return (
        <div>
            <div className="prospect_container">
                <div className='prospect_sub_container'>
                    <div >
                        <div className="text_title_big" style={{marginBottom: '4px'}}>{prospectsListData.archived ? 'Archived': ''} Prospects</div>
                        { prospectsListData.archived &&
                        <div style={{display: 'flex', marginBottom: 12}}>
                            <img src={info}></img>
                            <Link
                                className="text_link"
                                to={HelpCenterLinks["Archive prospects"] + "&scrollTo=archive_prospect"}
                                target="_blank" style={{marginLeft: '5px'}}>
                                Learn More
                            </Link>
                        </div>
                        }
                        <div className="text_body_big">{prospectsListData.total} prospects</div>
                    </div>
                    <div className='default_flex_container'>
                        {renderMainButton()}
                        <ConditionalRender if={extraOptionCond}>
                            <AdminProspectsOptions
                                renderOptions={renderExtraOptions}
                            >
                            </AdminProspectsOptions>
                        </ConditionalRender>
                    </div>
                </div>
                <div className="line_seperator_ex_light_grey" style={{margin: '16px 0px 8px'}}></div>
                <div>
                    
                    <ConditionalRender if={!prospectsListData.archived}>
                        <div className="default_flex_container" style={{marginTop: "16px"}}>
                            {renderSearchBar()}
                            {/* make class for css */}
                            <div className="flex_container_space_between filters_button_box text_body_light"
                                onClick={() => {filtersStateReducer({type: "SET_POPUP", payload: {showable: true}})}}>
                                {
                                    filtersState.appliedFiltersCount > 0 ? (
                                        <div className='applied_filters_box default_flex_container' style={{justifyContent: 'center'}}>
                                            <span >{filtersState.appliedFiltersCount}</span>
                                        </div>
                                    ):(
                                        <img src={FiltersIcon} style={{width: 24, height: 24}}></img>
                                    )
                                }

                                <div style={{marginLeft: 8}}>Filters</div>
                            </div>
                        </div>
                    </ConditionalRender>
                    {!prospectsListData.isFetching && (<Pagination totalPages={prospectsListData.totalPages}
                        currentPage={prospectsListData.currentPage}
                        onPageChange={fetchProspects}
                        style={{marginBottom: prospectsListData.archived ? 0 : 8}}
                    />)}
                    {/* <div className="text_tag" style={{display: 'flex', marginBottom: '24px'}}>
                        <div className={!prospectType ? 'text_tag_active' : ''} value="" onClick={handleProspectType} style={{padding: '9px 19px', cursor: 'pointer'}}>All</div>
                        <div className={prospectType=='Individual' ? 'text_tag_active' : ''} value="Individual" onClick={handleProspectType} style={{padding: '9px 19px', cursor: 'pointer'}}>Individuals</div>
                        <div className={prospectType=='Organization' ? 'text_tag_active' : ''} value="Organization" onClick={handleProspectType} style={{padding: '9px 19px', cursor: 'pointer'}}>Organization</div>
                    </div> */}
                    <div>
                        {
                            !prospectsListData.isFetching && renderProspectList()
                        }
                    </div>
                    <LoaderComponent isLoading={prospectsListData.isFetching} loaderStyle={{margin: 'auto', display: 'block', width: 32}}/>
                    {!prospectsListData.isFetching && (<Pagination totalPages={prospectsListData.totalPages}
                                currentPage={prospectsListData.currentPage}
                                onPageChange={fetchProspects}
                    />)}
                </div>
            </div>
            {
                prospectsListData.popupsList.addProspectPopup && addProspectPopup()
            }
            {
               prospectsListData.popupsList.addTagPopup && (
                <AddTagPopup
                    prospectData={{
                        tagsList: prospectsListData.tagsList, 
                        currentTags: prospectsListData.currentTags, 
                        profileTags: prospectsListData.currentProspect.tags,
                        prospect_id: prospectsListData.currentProspect.id,
                        isLoading: prospectsListData.isLoading
                    }}
                    dispatcher={(payload) => prospectsListDispatcher({
                        type: ALL_LIST_ACTIONS['SET_STATE'],
                        payload: payload
                    })}
                    handlePopupClose={handlePopupClose}
                    onAddedTagCallback={onAddedTagCallback}
                >
                </AddTagPopup>
               )
            }
            {
                prospectsListData.popupsList.addAssigneePopup && (
                    <AddAssigneePopup
                    prospectData={{
                        assigneeList: prospectsListData.assigneeList, 
                        currentAssigns: prospectsListData.currentAssigns, 
                        profileAssigns: prospectsListData.currentProspect.assigns,
                        prospect_id: prospectsListData.currentProspect.id,
                        isLoading: prospectsListData.isLoading,
                        lockedAssigns: prospectsListData.currentProspect.locked_assigns
                    }}
                    dispatcher={(payload) => prospectsListDispatcher({
                        type: ALL_LIST_ACTIONS['SET_STATE'],
                        payload: payload
                    })}
                    handlePopupClose={handlePopupClose}
                    onAddedAssigneeCallback={onAddedAssigneeCallback}
                    >
                    </AddAssigneePopup>
                )
            }
            {
                prospectsListData.popupsList.boostPopup && boostPopup()
            }
            {
                prospectsListData.popupsList.mergeProspectPopup && <MergeProspectPopup handlePopupClose={handlePopupClose} prospect_id={prospectsListData.currentProspect.id} setSuccessMessageCallback={(msg)=>prospectsListDispatcher({
                    type: ALL_LIST_ACTIONS['SET_STATE'],
                    payload: {
                        successMessages: {message: msg},
                    }
                }) }/>
            }
            {
              prospectsListData.popupsList.bulkArchivePopup && renderBulkArchivePopup()
            }
            {
                prospectsListData.popupsList.bulkDelitionPopup && renderBulkDelitionPopup()
            }
            {
                filtersState.showFilterPopup &&
                (<ProspectBasedFilters
                    filtersState={filtersState}
                    filtersStateReducer={filtersStateReducer}
                    fetchProspects={fetchProspects}
                    allFilterTabs={prospectsListData.allFilterTabs || ProspectListFiltersTabs}
                    tempFilterReducer={filterReducer}
                />)
            }
            {
                prospectsListData.successMessages.message && (<FlashMessages messageTheme='success' duration={prospectsListData.successMessages.duration} closeCallback={setProspectAddedMessage}>{prospectsListData.successMessages.message}</FlashMessages>)
            }
            {
                prospectsListData.popupsList.bulkArchiveFinishPopup && bulkArchiveFinishPopup()                
            }                
            {   prospectsListData.popupsList.shareWithUsPopup && <ShareWithUsPopup handleOnClosePopup={handlePopupClose}/>}
        </div>
    );
}

export default ProspectList;
