import { useEffect, useReducer } from 'react'
import { AnInputBox, AnSuggestionBox } from '../../input_utils/AnInput';
import {Button, TabbedButton} from "../../cta_utils/AnButton";
import ConditionalRender from '../../../ConditionalRender';
import getApiUrl from '../../utils/UrlHelper.js';
import { apiAgent } from '../../../axiosConfig.js';
import AnDropdownFilter from '../../cta_utils/AnDropdownfilter';
import darkDropdown from "../../../assets/darkDropdown.svg"
import FileUpload from '../../../FileUpload.js';
import Cross from "../../../assets/Cancel.svg";
import { FlashMessages } from '../../cta_utils/FlashMessages';
import { indexOfObject } from '../../utils/ArrayUtils.js';

const dashboard_types = [{label: 'Add LinkedIn Sync URL', key: 'add_linkedin_sync_url'}, {label: "Import LinkedIn Sync URLs", key: 'import_linkedIn_sync_urls'}];
const LinkedinSyncUrlPopup = (props) => {

    const [linkedinSyncUrlsData, linkedinSyncUrlsDataReducer ] = useReducer(reducer, {
        syncUrls: [{url: ""}],
        selectedTeam: null,
        option: null,
        dropdownOptions: [],
        selectedOption: {key: '', label: ''},
        dashboardType: 'add_linkedin_sync_url',
        is_addingUrl: false,
        successMsg: null
    })

    const setState = (payload) => {
        linkedinSyncUrlsDataReducer({
            type: "SET_STATE",
            payload: payload
        });
    }
    const selectSuggestionCallback = (name, value) => {
        if (value == null){
            return;
        }
        apiAgent({
            method: "get",
            url: getApiUrl("fetch_linkedin_lists"),
            params: {
                team_id: value._id
            },
        }).then(
            (res) => {
                let options = res.data.data.map(item => ({
                    key: item.id,
                    label: item.title
                  }));
                if (options.length === 0) {
                    options = [{ key: "", label: "" }];
                }
                setState({
                    dropdownOptions: options,
                    selectedOption: {key: options[0].key, label: options[0].label} 
                })
            },
            (error) => {
            }
        );

    }

    useEffect(() => {
        if(linkedinSyncUrlsData.option){
            const foundObject = linkedinSyncUrlsData.dropdownOptions.find(item => item.key === linkedinSyncUrlsData.option);
            setState({selectedOption: foundObject })
        }
    }, [linkedinSyncUrlsData.option]);
    
    const handleSubmit = () => {
        if(linkedinSyncUrlsData.selectedOption.key == null){
            return;
        }
        let ln_sync_urls = linkedinSyncUrlsData.syncUrls.map(item => item.url);
        setState({isLoading: true});
        let ln_list_id = linkedinSyncUrlsData.selectedOption.key;
        apiAgent({
            method: "post",
            url: getApiUrl("create_ln_sync_urls"),
            data: {
                ln_sync_urls: ln_sync_urls,
                ln_list_id: ln_list_id
            }
        }).then(res => {
            props.setSuccessMessages( {
                message: "Linkedin Sync URLs added",
                duration: 3000
            });
            props.handleOnClose();
            setState({isLoading: false});
            let index = indexOfObject(props.allLists, "id", ln_list_id);
            if (index !== -1) {
                const newArray = res.data.ln_sync_urls.map(item => ({
                    id: item._id,
                    ...item
                }));
                const updatedLists = [...props.allLists];
                updatedLists[index] = {
                    ...updatedLists[index],
                    linkedin_sync_urls: newArray
                };
                props.setAllLists(updatedLists);
            } else {
                props.getData(1);
            }
        }).catch(err => {
            console.log("error!");
            setState({isLoading: false});
        });
    }

    const handleSyncUrlInputChange = (e, index) => {
        const updatedSyncUrls = [...linkedinSyncUrlsData.syncUrls];
        updatedSyncUrls[index] = { url: e.target.value };
        setState({syncUrls: updatedSyncUrls});
    }

    const addSyncUrl = () => {
        setState({syncUrls: [...linkedinSyncUrlsData.syncUrls, {url: ""}]})
    }

    const removeSyncUrl = (index) => {
        const updatedSyncUrls = [...linkedinSyncUrlsData.syncUrls];
        updatedSyncUrls.splice(index, 1);
        setState({syncUrls: updatedSyncUrls});
    }

    const setOption = (val) => {
        setState({option: val})
    };    

    return (
        <>
            <div className='text_title' style={{marginBottom: 4}}>Add LinkedIn Sync URL</div>
            <div  className="line_seperator_ex_light_grey"  style={{ margin: "16px auto 8px" }}></div>
            <div className='default_flex_container'>
                {
                    dashboard_types.map(type => (
                        <div key={type.key} style={{marginBottom: 12}}>
                            <TabbedButton className='text_subtitle' buttonStyle={{padding: '10px', borderBottom: "none"}} isActive={linkedinSyncUrlsData.dashboardType === type.key} 
                            onClick={() => linkedinSyncUrlsDataReducer({
                                type: "SET_STATE",
                                payload: {
                                    dashboardType: type.key
                                }
                                })}
                            >
                                {type.label}
                            </TabbedButton>
                        </div>
                    ))
                }
            </div>
            <ConditionalRender if={linkedinSyncUrlsData.dashboardType === "add_linkedin_sync_url"}>
                <div className='text_body_light' style={{marginTop: 12, marginBottom: 5}}> Team Name</div>
                <AnSuggestionBox inputClass="an_search_box" suggestionUrl="/suggestions/teams" placeholder="Search Team" callback={selectSuggestionCallback}/>
                <ConditionalRender if={linkedinSyncUrlsData.dropdownOptions.length> 0}>
                    <div className='text_body_light' style={{marginTop: 12, marginBottom: 5}}> List Name</div>
                    <AnDropdownFilter
                        options={linkedinSyncUrlsData.dropdownOptions}
                        option={linkedinSyncUrlsData.selectedOption.key}
                        innerStyle={{ left: "24px", width: "90%"}}
                        setOption={setOption}
                    >
                        <div className='flex_container_space_between text_body_light filters_button_box'
                            style={{marginLeft: 'auto', padding: '9px 12px', cursor: 'pointer', position: 'relative', height: 40, border: 'none', justifyContent: 'space-between', whiteSpace: 'nowrap',background: '#F8F8F8'}}
                            >
                            <span>{linkedinSyncUrlsData.selectedOption.label}</span>
                            <img src={darkDropdown} style={{marginLeft: 4}}/>
                        </div>
                    </AnDropdownFilter>
                    {linkedinSyncUrlsData.syncUrls.map((syncUrl, index) => (
                        <div key={index} className='default_flex_container' style={{ marginTop: index == 0 ? "18px" : "12px" }}>
                            <AnInputBox label={index > 0 ? "" : "LinkedIn Sync URL"} error={syncUrl.errors?.url} style={{width: "90%"}}>
                                <input type="text" id={`url_${index}`} name={`url_${index}`} value={syncUrl.url} onChange={(e) => handleSyncUrlInputChange(e, index)} placeholder="linkedin sync url" />
                            </AnInputBox>
                            {index > 0 && (
                                <img src={Cross} style={{marginLeft: 8, width: 20, cursor: "pointer"}} onClick={() => removeSyncUrl(index)}/>
                            )}
                        </div>
                    ))}
                    <div onClick={addSyncUrl} className='text_link'>
                        +Add
                    </div>
                </ConditionalRender>
                <div className='default_flex_container' style={{marginTop: "12px"}}>
                    <Button onClick={handleSubmit}
                        buttonStyle={{width: "max-content"}}
                        isLoading={linkedinSyncUrlsData.isAddingUrl}
                    >
                        Save
                    </Button>
                    <Button type="Secondary" buttonSize="Medium" buttonStyle={{width: 'max-content', marginLeft: 16}} innerButtonStyle={{padding: '8px 20px'}} onClick={() => props.handleOnClose()}>
                        Cancel
                    </Button>
                </div>
            </ConditionalRender>
            <ConditionalRender if={linkedinSyncUrlsData.dashboardType === "import_linkedIn_sync_urls"}>
                <div className='text_subtitle'>Follow the steps to import LinkedIn Sync URLs:</div>
                <div className='text_body_light' style={{marginTop: 4}}>
                    1. Download this sample file - <a className='text_link' href='https://d3sr7cc30ek8f4.cloudfront.net/raw/prod/65c33f1dca197600113f6010/ImportLinkedinSyncUrls.csv'>Click here</a>.<br/>
                    2. Fill up the details.<br/>
                    3. Save as CSV & Upload.<br/>
                </div>
                <FileUpload 
                    style={{marginTop: "12px", maxWidth: "600px"}}
                    submitUrl="/linkedin_prospects/import_linkedin_sync_urls_from_csv"
                    uploaderSecondaryText={{content: ""}}
                />
            </ConditionalRender>
            {linkedinSyncUrlsData.successMsg && (
                <FlashMessages
                    messageTheme="success"
                    closeCallback={() => setState({successMsg: null})}
                >
                    {linkedinSyncUrlsData.successMsg}
                </FlashMessages>
            )}
        </>
    )
    
}
export default LinkedinSyncUrlPopup;

const reducer = (state, action) => {
    switch(action.type){
        case "SET_STATE": {
            return {...state, ...action.payload}
        }
        default: return state
    }
}