import ProspectList from "../../../../../assets/help_center/prospect_list.png"
import prospect_profile from "../../../../../assets/help_center/prospect_profile.png"
import ProspectNewsfeed from "../../../../../assets/help_center/prospect_newsfeed.png"
import DownloadProspects from "../../../../../assets/help_center/download_prospects.png"
import AnScrollToLinkPage from "../../../../cta_utils/AnScrollToLinkPage";

export const Prospects = () => {
    return (
        <div style={{ margin: "24px 0px" }}>
        <div className="help_center_top_section">
            <div className="text_title_big" style={{marginBottom: 20}}>Prospects</div>
            <div style={{border: "1px solid grey", padding: "14px 20px", borderRadius: "4px", fontWeight: "700"}}>
                <div className="text_body" style={{color: "#707274", fontWeight: 700}}> SKIP AHEAD TO</div>
                <div style={{marginTop: "12px"}}>
                    <span style={{color: "#437EFE", fontSize: "14px"}}><AnScrollToLinkPage href="#prospet_list"> Prospect list </AnScrollToLinkPage></span>
                    <span style={{color: "#437EFE", marginLeft: 20, fontSize: "14px"}}><AnScrollToLinkPage href="#download_prospects"> Downloading the prospect data </AnScrollToLinkPage></span>
                    <span style={{color: "#437EFE", marginLeft: 20, marginRigt: 77, fontSize: "14px"}}><AnScrollToLinkPage href="#prospect_profile"> Going to prospect profile page </AnScrollToLinkPage></span>
                </div>
            </div>
        </div>
            <div className="help_center" id="prospet_list">
                Prospects list
            </div>
            <div className="help_center_text">
                Prospect list page allows you to view all the existing prospects that have been imported into the system, and whose news articles are currently being fetched by the system. This page provides various filtering options to help you narrow down the search.
            </div>
            <img className="help_center_img" src={ProspectList} style={{maxWidth: "650px", maxHeight: "450px", margin: "24px auto"}}></img>

            <div style={{ color: '#282A2C', fontSize: '16px', marginTop: "12px", lineHeight: '22px' }}>
                By applying filters such as Tags, Company or Location, you can quickly and easily find the prospects that are most relevant to your needs. In addition to the Name, Designation and company of prospects, you can also view the number of high and low confidence results currently available for each prospect.
            </div>

            <div className="help_center" id="download_prospects">
                Downloading the prospect data
            </div>
            <div className="help_center_text">
                You can also download prospect data in CSV format by clicking on the Download data option from the drop-down menu next to the "Add prospects" button. Once the request is made, the data file is generated and sent via email within a few minutes. 
            </div>
            <img className="help_center_img" src={DownloadProspects} style={{maxWidth: "650px", maxHeight: 301, margin: "12px auto 0px"}}></img>
            
            <div className="help_center_notes" style={{marginTop: 24}}>
                <div className="help_center_item" style={{ padding: "11px 14px"}}> This feature is only available to Admins</div>
            </div>

            <div className="help_center" id="prospect_profile">
                Going to Prospect profile page
            </div>
            <div className="help_center_text">
                A profile is created for every prospect to store all the imported information. You can go to the prospect in 3 ways - 
                <ul style={{marginLeft: -4}}>
                    <li>You can click on the name of the prospect from the newsfeed, </li>
                    <li>You can click on the name of the prospect from the prospects page</li>
                    <li>You can search for the name manually from the search box present in the header</li>
                </ul>
            </div>

            <div className="help_center">
                Going to Prospect profile page
            </div>
            <div className="help_center_text">
            In the prospect profile, there are 2 tabs - <br/> 
            i. Profile <br/>
            ii. Newsfeed
            </div>

            <div className="help_center_item" style={{ fontWeight: 600, marginTop: 12 }}>
                i. Profile
            </div>

            <div className="help_center_item" style={{ marginTop: 8 }}>
                Profile contains all the imported information of the prospect from both default and custom fields. You can update any information by clicking on the Edit icon corresponding to a field. 
            </div>
            <img className="help_center_img" src={ProspectList} style={{maxWidth: "650px", maxHeight: "400px", margin: "12px auto 0px"}}></img>            

            <div className="help_center_item" style={{ fontWeight: 600, marginTop: 12 }}>
                ii. Newsfeed
            </div>

            <div className="help_center_item" style={{ marginTop: 8 }}>
                Newsfeed is a personalized feed page for the prospect. The layout is similar to the main feed page with both High and Low confidence results. 
            </div>
            <img className="help_center_img" src={ProspectNewsfeed} style={{maxWidth: "650px", maxHeight: 592, margin: "12px auto 0px"}}></img>  
            

        </div>
    );
};