import { useState, useEffect } from "react";
import { apiAgent } from "../../../axiosConfig.js";
import { FlashMessages } from "../../cta_utils/FlashMessages.js";
import getApiUrl from "../../utils/UrlHelper.js";
import PipeDriveImage from "../../../assets/pipe_drive_image.png";
import PipeDriveAlmaLogo from "../../../assets/pipe_drive_alma_logo.png";
import { Button } from "../../../components/cta_utils/AnButton.js";

const PipeDriveApp = () => {
    const [successMessages, setSuccessMessages] = useState({
        message: "",
        duration: 0,
        from: ""
    });

    useEffect(() => {
        let paramsString = window.location.search;
        let searchParams = new URLSearchParams(paramsString);
        const pipe_drive_code = searchParams.get('code');
        if (pipe_drive_code == null) return;

        apiAgent({
            method: "post",
            url: getApiUrl("pipe_drive_app_init"),
            params: {
                pipedrive_code: pipe_drive_code
            },
        }).then(
            (res) => {
                setSuccessMessages({
                    message: "Pipedrive app installed",
                    duration: 3000,
                    theme:"success",
                    from: ""
                });
            },
            (error) => {
                setSuccessMessages({
                    message: "Pipedrive app installation failed",
                    duration: 3000,
                    theme: "error",
                    from: ""
                });
            }
        );
    }, []);

    const appInstalledMessage = (message) => {
        setSuccessMessages({message: message});
    }

    return (
        <div>
            <div className="aggregate_container" style={{ marginTop: "48px", marginBottom: "64px", maxWidth: "1280px"}}>
                <div style={{display: "block", margin: "auto", width: 640, backgroundColor: "white", border: "1px solid hsla(168, 12%, 92%, 1)", borderRadius: 12}}>
                    <div style={{padding: "48px 40px 24px", textAlign: "center"}}>
                        <div style={{ backgroundImage: `url(${PipeDriveImage})`, width: 450, height: 169, position: 'relative', margin: "auto" }}>
                            <img src={PipeDriveAlmaLogo} style={{ width: 215, height: 60, position: 'absolute', top: '68%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }} alt="Foreground Image" />
                        </div>

                        <div className="text_title_big" style={{marginTop: 32}}>Installation Complete</div>
                        <div className="text_body_light" style={{marginTop: 16, width: 560, fontWeight: 600, color: "hsla(210, 2%, 45%, 1)"}}>
                            AlmaConnect News is now integrated and ready to streamline your Pipedrive experience with news updates directly in the notes section of your Contacts.
                        </div>
                        <div className="line_seperator_ex_light_grey" style={{ margin: "32px auto 16px" }}></div>
                        <div className="text_caption_small_light" style={{fontWeight: 600}}>
                            If you have any questions, encounter any issues, or would like to learn  more about our product, please don't hesitate to contact us via email at <span style={{color: "hsla(204, 6%, 24%, 1)"}}>global.sales@almaconnect.com</span>
                        </div>  
                    </div>
                </div>
            </div>
            {
                successMessages.message && (<FlashMessages messageTheme={successMessages.theme} duration={successMessages.duration} closeCallback={appInstalledMessage}>{successMessages.message}</FlashMessages>)
            }
        </div>
    );
};

export default PipeDriveApp;