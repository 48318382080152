import React, { useEffect, useReducer, useState } from 'react'
import { getDatasetAtEvent } from 'react-chartjs-2';
import DatePicker from 'react-modern-calendar-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { apiAgent } from '../../../axiosConfig';
import ConditionalRender from '../../../ConditionalRender';
import { Button } from '../../cta_utils/AnButton';
import { FlashMessages } from '../../cta_utils/FlashMessages';
import { AnInputBox } from '../../input_utils/AnInput';
import { AnInputCheckBox } from '../../input_utils/AnInputCheckBox';
import { AnInputRadioButton } from '../../input_utils/AnInputRadioButton'
import { TEAM_COUNTRIES } from '../../utils/countryUtils';
import { dateFormator, datepicker_input_format, getDateUtil } from '../../utils/dateUtils';
import { ALL_TIME_ZONES } from '../../utils/timeUtils';
import AnPopup from "../../cta_utils/AnPopup"
import GreyArrowDown from "../../../assets/GreyArrowDown.svg"
import useCheckUserAccess from '../../custom_hooks/useCheckUserAccess';
import { updateCurrentTeam } from '../../../redux/currentTeamSlice';
import AnMultiSelectDropdown from "../../input_utils/AnMultiSelectDropdown";
import AnMultipleSelectDropdown from "../../input_utils/AnMultipleSelectDropdown";
import { AdminSettings } from './AdminSettings';

const optionsList = [
    {label: 'Unique Id', key: 'external_id'},
    {label: 'Full Name', key: 'full_name'},
    {label: 'Spouse Name', key: 'spouse_name'},
    {label: 'Other Companies', key: 'custom_companies'},
    {label: 'Education', key: 'college'},
    {label: 'Course', key: 'course'},
    {label: 'Branch', key: 'branch'},
    {label: 'Graduation Year', key: 'graduation_year'},
    {label: 'City', key: 'city'},
    {label: 'State', key: 'state'},
    {label: 'Country', key: 'country'},
    {label: 'Location', key: 'location'},
    {label: 'Linkedin URL', key: 'linkedin_url'},
    {label: 'Alias Names', key: 'alias_names'},
    {label: "Assigned Team members", key: "assigns"},
    {label: "Relationships", key: "relationships"},
    {label: "Assigned Tags", key: "tags"}    
];


const TeamSettings = (props) => {
    const current_user = useSelector(state => state.current_user.user);
    const [selectedValues, setSelectedValues] = useState([]);
    const [customfields, setCustomFields] = useState([]);
    const current_team = useSelector(state => state.current_team.team);
    const [date, setDate] = useState();    
    const [initialData, setInitialData] = useState({});
    const [teamData, teamDataDispatcher] = useReducer(teamSettingsReducer, {
        team: {
            name: "",
            linkedin_url: "",
            team_time_zone: "",
            country: "",
            aliases: ["","",""],
            verified: false,
            premium: false,
            trial_expiring_on: null,
            google_news_tracking: false,
            names_tracking: false,
            custom_companies_enabled: false,
            score_threshold: null,
            team_settings: {},
            displayable_features: {},
            short_profile_fields: {},
        },
        activeSectionTab: "",
        isLoading: false,
        successMessage: null,
        popupsList: {},
        errors: {}
    });
    const checkUserAccess = useCheckUserAccess();
    const _dispatch = useDispatch();

    useEffect(() => {
        props.setActiveSettingTab('team_settings')
        apiAgent({
            method: 'get',
            url: '/team/team_details',
            params: {}
        }).then(res => {
            teamDataDispatcher({
                type: ALL_TEAM_ACTIONS['SET_TEAM'],
                payload: {...res.data.data, aliases: [...res.data.data.aliases || [], "", "", ""], displayable_features: current_team.displayable_features}
            })
            loadInitialDate(res.data.data.trial_expiring_on);
            setInitialData({team_settings: res.data.data.team_settings})
            const convertedArray = Object.entries(res.data.data.short_profile_fields).map(([key, label]) => ({
                key: key,
                label: label,
              }));
            setSelectedValues(convertedArray);
            
        }).catch(err => console.log("Error fetching team details"))
    }, []);

    useEffect(() => {
        apiAgent({
            method: "get",
            url: "/team/custom_fields",
            params: {},
        })
            .then((res) => {
                const keyValueArray = res.data.custom_fields.map((field) => ({
                    key: field.id,
                    label: field.field_name
                }));
                setCustomFields(keyValueArray);
            })
            .catch((err) => {});
    }, []);

    const handleInputChange = (e) => {
        let payload = {
            [e.target.name]: e.target.value
        }
        teamDataDispatcher({
            type: ALL_TEAM_ACTIONS['SET_TEAM'],
            payload
        })
    }

    const handleOnAliasesChange = (event, index) => {
        let temp = [...teamData.team.aliases]
        if(!event.target.value){
            temp.splice(index, 1);
        }else{
            temp[index] = event.target.value;
        }
        teamDataDispatcher({
            type: ALL_TEAM_ACTIONS['SET_TEAM'],
            payload: {
                aliases: temp
            }
        })
    }
    const setTeamData = (payload) => {
        teamDataDispatcher({
            type: ALL_TEAM_ACTIONS['SET_TEAM'],
            payload: payload
        })
    }
    const handleVerified = (verify=!teamData.team.verified) => {
        let payload={verified: verify};
        let defaultExpireDate = dateFormator(getDateUtil(+30), {type: "datepicker_object"});

        if(teamData.team.verified){ 
            payload={
                verified: verify,
                trial_expiring_on: null
            } 
        } 
        else{
            payload={
                verified: verify,
                trial_expiring_on: defaultExpireDate
            }
            setDate(defaultExpireDate);
        }

        teamDataDispatcher({
            type: ALL_TEAM_ACTIONS['SET_TEAM'],
            payload: payload
        })
    }
    const handleTrialExpiringOn = (e) => {
        let res=new Date(e.year,e.month-1,e.day);
        res=dateFormator(res, {type: "datepicker_object"});
        teamDataDispatcher({
            type: ALL_TEAM_ACTIONS['SET_TEAM'],
            payload: {
                trial_expiring_on: res
            }
        })
        setDate(e);
    }
    const loadInitialDate = (trial_expiring_on) => {
        let res=trial_expiring_on;
        if(res)
        {
            let dateParts = res.toString().split("-");
            let dateObject=new Date(dateParts[2],dateParts[1]-1,dateParts[0])
            res = dateFormator(dateObject, {type: "datepicker_object"});
        }
        else
        {
            res = dateFormator(getDateUtil(+30), {type: "datepicker_object"});
        }
        teamDataDispatcher({
            type: ALL_TEAM_ACTIONS['SET_TEAM'],
            payload: {
                trial_expiring_on: res
            }
        })
        setDate(res);
    }
    const receiveDateString = () => {
        let val=teamData.team.trial_expiring_on;
        if(val)
        {
            val=new Date(val.year,val.month-1,val.day).toString();
        }
        return val;
    }

    const handleAutoPublish = () => {
        var auto_publish_enabled = !teamData.team.auto_publish_enabled;
        var payload = {auto_publish_enabled: auto_publish_enabled};
        if(auto_publish_enabled) {
            payload['auto_publish_score'] = 12;
            payload['auto_publish_tags'] = "company_matches(1-50), name_company_distance(1-150), non-generic";
            payload['auto_publish_excl_tags'] = 'similar_name_and_company';
        }
        setTeamData(payload);
    }

    const handleZoneChange = (e) => {
        let time_zone = e.target.value;
        teamDataDispatcher({
            type: ALL_TEAM_ACTIONS['SET_TEAM'],
            payload: {
                team_time_zone: time_zone
            }
        })
    }

    const handleSectionToggle = (section_name) => {
        let payload = {...teamData};
        if(payload.activeSectionTab === section_name)
            payload.activeSectionTab = "";
        else
            payload.activeSectionTab = section_name;
        setState(payload)
    }

    const setState = (payload) => {
        teamDataDispatcher({type: ALL_TEAM_ACTIONS['SET_STATE'], payload})
    }
    const setPopupsList = (popupsList={}) => {
        setState({popupsList: popupsList})
    }
    const teamSettingsChange = (e) => setTeamData({...teamData, team_settings: {...teamData.team_settings, [e.target.name]: e.target.value}})

    const validateForm = () => {
        let errors = {}
        if(!teamData.team.name) errors.name = "Team name should not be empty";
        if(teamData.team.team_settings.initial_job_update_duration > 90) errors.initial_job_update_duration = true
        return errors
    }

    const handleSubmit = () => {
        let errors = validateForm();
        if(Object.keys(errors).length > 0) {
            teamDataDispatcher({
                type: ALL_TEAM_ACTIONS['SET_ERRORS'],
                payload: errors
            });
            return;
        }else{
            setState({
                errors: errors,
                isLoading: true,
                successMessage: null,
                serverError: null
            })
        }
        const short_profile_fields = selectedValues.reduce((map, item) => {
            map[item.key] = item.label;
            return map;
          }, {});
        
        let data_params = {...teamData.team};
        data_params.short_profile_fields = short_profile_fields;
        data_params.aliases = teamData.team.aliases.filter(a=>a);
        data_params.trial_expiring_on = receiveDateString();
        apiAgent({
            method: 'post',
            url: '/team/update',
            data: data_params
        }).then(res => {
            teamDataDispatcher({
                type: ALL_TEAM_ACTIONS["SET_REQUEST_STATUS"],
                payload: {
                    errors: errors,
                    isLoading: false,
                    successMessage: "Changes saved",
                    serverError: null
                }
            });
            _dispatch(updateCurrentTeam(res.data.team))
        }).catch(err => {
            teamDataDispatcher({
                type: ALL_TEAM_ACTIONS["SET_REQUEST_STATUS"],
                payload: {
                    errors: errors,
                    isLoading: false,
                    successMessage: null,
                    serverError: "Something went wrong! Please try again later"
                }
            });
        })

    }
    let flash_type = "success";
    if(teamData.serverError) flash_type = "warning";
    
    const renderTeamALiasesPopup = () => {
        return (
            <AnPopup name="aliasesPopup" handleOnClosePopup={setPopupsList}>
                <div className="text_title">Team Aliases</div>
                <div
                    className="line_seperator_ex_light_grey"
                    style={{ margin: "16px auto" }}
                ></div>
                {
                    teamData.team.aliases.map((alias, index) => (
                        <AnInputBox key={index} error={(!alias && teamData.errors["aliases"]) ? teamData.errors["aliases"] : null}>
                            <input type="text" value={alias}
                                onChange={(e) => handleOnAliasesChange(e, index)}
                                placeholder="Team alias"
                            />
                        </AnInputBox>
                    ))
                }
                <div className="text_link" style={{cursor: 'pointer'}}
                    onClick={() => {
                        teamDataDispatcher({
                            type: ALL_TEAM_ACTIONS['SET_TEAM'],
                            payload: {
                                aliases: [...teamData.team.aliases, '']
                            }
                        })
                        teamDataDispatcher({
                            type: ALL_TEAM_ACTIONS['SET_ERRORS'],
                            payload: {}
                        });
                    }
                }>
                    +Add {teamData.team.aliases.length == 0 ? 'alias' : 'another'}
                </div>                
                <Button buttonSize="LessPadding" isLoading={teamData.isLoading}
                    innerButtonStye={{padding: '8px 16px'}}
                    buttonStyle={{marginTop: "20px", width: 'max-content'}}
                    onClick={handleSubmit}
                    >
                        Save changes
                </Button>
            </AnPopup>
        )
    }

    let defaultList = [{"Default fields": optionsList}, {"Custom fields": customfields}];
    const handleDisplayableFeatures = (name, value) => {
        let payload = {displayable_features: {...teamData.team.displayable_features, [name]: value}}
        setTeamData(payload)
    }
    return (
        <>
            <div className='flex_container_space_between' style={{marginBottom: 12}}>
                <div className="text_title">Team Settings</div>
                <div className='text_link hover_under_line' onClick={() => setPopupsList({aliasesPopup: true})}>Team Aliases</div>
            </div>
            <ConditionalRender if={checkUserAccess('team_important_fields')}>
            <AnInputBox label="Team Name" error={teamData.errors.name}>
                <input type="text" name="name" value={teamData.team.name}
                    onChange={handleInputChange}
                    placeholder="Team name"
                />
            </AnInputBox>
            <AnInputBox label="Linkedin URL">
                <input type="text" name="linkedin_url" value={teamData.team.linkedin_url}
                       onChange={handleInputChange}
                       placeholder="Linkedin URL"
                />
            </AnInputBox>
            <AnInputBox label="Country" style={{maxWidth: "480px"}}>
                <select placeholder="Select the country" value={teamData.team.country} onChange={(e) => setTeamData({country: e.target.value})}>
                    <option key="null_valued" value="">
                        Select a country
                    </option>
                    {
                        Object.keys(TEAM_COUNTRIES).map(country => (
                            <option key={country} value={TEAM_COUNTRIES[country]}>
                                {country}
                            </option>
                        ))
                    }   
                </select>
            </AnInputBox>
            <AnInputBox label="Timezone" style={{maxWidth: "300px"}}>
                <select value={teamData.team.team_time_zone} onChange={handleZoneChange}>                    
                    {
                        Object.keys(ALL_TIME_ZONES).map(tz => (
                            <option key={tz} value={ALL_TIME_ZONES[tz]}>
                                {tz}
                            </option>
                        ))
                    }   
                </select>
            </AnInputBox>
            </ConditionalRender>
            <ConditionalRender if={checkUserAccess('team_type')}>
                <div className="line_seperator_ex_light_grey" style={{margin: "12px 0px 20px"}}></div>
                    <div className='flex_container_space_between'
                        onClick={() => handleSectionToggle("Team Type")}
                        style={{cursor: "pointer"}}
                    >
                        <div className="text_subtitle" style={{marginBottom: '12px'}}>
                            Team Type
                        </div>
                        <img
                            src={GreyArrowDown}
                            className={
                                teamData.activeSectionTab === "Team Type"
                                    ? "to_arrow_up"
                                    : "to_arrow_down"
                            }
                            style={{ width: 24 }}
                        />
                    </div>
                    <ConditionalRender if={teamData.activeSectionTab === "Team Type"}>
                        <div style={{marginBottom: 20, opacity: teamData.team.verified && teamData.team.premium ? 0.4 : 1}}>
                            <div className='default_flex_container'>
                                <AnInputCheckBox
                                    label="Verified"
                                    isChecked={teamData.team.verified} 
                                >
                                    <input
                                        type="checkbox"
                                        disabled={teamData.team.verified}
                                        checked={teamData.team.verified}
                                        onChange={() => handleVerified()}
                                    >
                                    </input>
                                </AnInputCheckBox>
                                <AnInputCheckBox
                                    label="Premium"
                                    isChecked={teamData.team.premium} 
                                    style={{marginLeft: "29px"}}
                                >
                                    <input
                                        type="checkbox"
                                        disabled={teamData.team.verified && teamData.team.premium}
                                        checked={teamData.team.premium}
                                        onChange={() => {setTeamData({premium: !teamData.team.premium}); handleVerified(true)}}
                                    >
                                    </input>
                                </AnInputCheckBox>
                            </div>
                            <ConditionalRender if={teamData.team.verified}>
                                <div className='text_body_light' style={{marginTop: 16}}>
                                    Reverting to non-verified on
                                </div>
                                <div className='text_body' style={{pointerEvents: teamData.team.verified && teamData.team.premium ? 'none' : 'auto'}}>
                                    <AnInputBox style={{maxWidth: "120px"}}>
                                        <DatePicker
                                            value={date}
                                            onChange={handleTrialExpiringOn}
                                            inputPlaceholder="Enter Expiry Date"
                                            formatInputText={() => datepicker_input_format(teamData.team.trial_expiring_on)}
                                            calendarPopperPosition="bottom"
                                            shouldHighlightWeekends
                                        >
                                        </DatePicker>
                                    </AnInputBox>
                                    <ConditionalRender if={!teamData.team.premium}>
                                        <AnInputBox label="Prospect limit" style={{maxWidth: "250px"}}>
                                            <input type="number"
                                                name="max_prospect_limit"
                                                value={teamData.team.max_prospect_limit}
                                                onChange={handleInputChange}
                                                min="1"
                                                placeholder="Unlimited Prospect Limit"/>
                                        </AnInputBox>
                                    </ConditionalRender>
                                </div>
                            </ConditionalRender>
                        </div>
                        <div className='flex_container_space_between' style={{flexWrap: "wrap"}}>
                            <AnInputBox label="Individual High Score Threshold" style={{maxWidth: "250px", flex: 1}}>
                                <input type="number"
                                    name="score_threshold"
                                    value={teamData.team.score_threshold}
                                    onChange={handleInputChange}
                                    min="1"
                                    max="100"
                                    placeholder="Score for smart publish"/>
                            </AnInputBox>
                            <AnInputBox label="Organization High Score Threshold" style={{maxWidth: "250px", flex: 1}}>
                                <input type="number"
                                    name="organization_score_threshold"
                                    value={teamData.team.organization_score_threshold}
                                    onChange={handleInputChange}
                                    min="1"
                                    max="100"
                                    placeholder="Score for organization smart publish"/>
                            </AnInputBox>
                        </div>
                        <div className='flex_container_space_between' style={{flexWrap: "wrap"}}>
                            <AnInputBox label="Individual Mid Score Threshold" style={{maxWidth: "250px", flex: 1}}>
                                <input type="number"
                                       name="mid_score_threshold"
                                       value={teamData.team.mid_score_threshold}
                                       onChange={handleInputChange}
                                       min="1"
                                       max="100"
                                       placeholder="Score for smart publish"/>
                            </AnInputBox>
                            <AnInputBox label="Organization MId Score Threshold" style={{maxWidth: "250px", flex: 1}}>
                                <input type="number"
                                       name="organization_mid_score_threshold"
                                       value={teamData.team.organization_mid_score_threshold}
                                       onChange={handleInputChange}
                                       min="1"
                                       max="100"
                                       placeholder="Score for organization smart publish"/>
                            </AnInputBox>
                        </div>
                        <div className='flex_container_space_between' style={{flexWrap: "wrap"}}>
                            <AnInputBox label="Mark spam when article count exceeds" style={{maxWidth: "250px", flex: 1}}>
                                <input type="number"
                                    name="max_limit_per_day"
                                    value={teamData.team.max_limit_per_day}
                                    min="1"
                                    onChange={handleInputChange}
                                    placeholder="Max Limit per day"/>
                            </AnInputBox>
                        </div>
                    </ConditionalRender>
                </ConditionalRender>
                <ConditionalRender if={checkUserAccess('email_notification')}>
                <div className="line_seperator_ex_light_grey" style={{margin: "12px 0px 20px"}}></div>
                <div className='flex_container_space_between' 
                    onClick={() => handleSectionToggle("Email Settings")}
                    style={{cursor: "pointer"}}
                >
                    <div className="text_subtitle" style={{marginBottom: '6px'}}>
                        Email notifications
                    </div>
                    <img
                        src={GreyArrowDown}
                        className={
                            teamData.activeSectionTab === "Email Settings"
                                ? "to_arrow_up"
                                : "to_arrow_down"
                        }
                        style={{ width: 24 }}
                    />
                </div>
                <ConditionalRender if={teamData.activeSectionTab === "Email Settings"}>
                    <AnInputRadioButton label="Chronological order" isChosen={teamData.email_chronological_order}>
                            <input type="radio"                            
                                value="created_at"
                                name="news_email_sort"
                                checked={teamData.team.team_settings.news_email_sort === "created_at"}
                                onChange={teamSettingsChange}
                            />
                    </AnInputRadioButton>
                    <AnInputRadioButton label="Alphabetical order" style={{marginTop: 8}} isChosen={teamData.email_alphabetical_order}>
                            <input type="radio"
                                value="name"
                                name="news_email_sort"
                                checked={teamData.team.team_settings.news_email_sort === "name"}
                                onChange={teamSettingsChange}
                            />
                    </AnInputRadioButton>
                    <ConditionalRender if={checkUserAccess('enable_external_id_in_mail')}>
                        <AnInputCheckBox
                            label="Show Unique ID in email"
                            isChecked={teamData.team.displayable_features.display_external_id}
                            style={{marginTop: "8px"}}

                        >
                            <input
                                type="checkbox"
                                checked={teamData.team.displayable_features.display_external_id}
                                onChange={(e) => handleDisplayableFeatures('display_external_id', !teamData.team.displayable_features.display_external_id)}>
                            </input>
                        </AnInputCheckBox>
                    </ConditionalRender>
                </ConditionalRender>
            </ConditionalRender>            
            
            <ConditionalRender if={checkUserAccess('short_profile_data_fields')}>
                <div className="line_seperator_ex_light_grey" style={{margin: "12px 0px 20px"}}></div>
                <div className='flex_container_space_between'
                    onClick={() => handleSectionToggle("Profile hover cards")}
                    style={{cursor: "pointer"}}
                >
                    <div className="text_subtitle" style={{marginBottom: '6px'}}>
                        Profile hover cards
                    </div>
                    <img
                        src={GreyArrowDown}
                        className={
                            teamData.activeSectionTab === "Profile hover cards"
                                ? "to_arrow_up"
                                : "to_arrow_down"
                        }
                        style={{ width: 24 }}
                    />
                </div>
            </ConditionalRender>
            <ConditionalRender if={teamData.activeSectionTab === "Profile hover cards"}>
                <div className='text_body' style={{color: "#707274", paddingBottom: "4px"}}>
                    Choose fields to be shown on hovering prospect’s profile. (Choose upto 5)
                </div>
                <AnMultipleSelectDropdown 
                    options={defaultList} 
                    maxSelections={5} 
                    selectedValues={selectedValues} 
                    setSelectedValues={setSelectedValues}
                    dropdownStyle={{maxHeight: 200, overflowY: "scroll", overflowX: "hidden",marginRight: -10}}
                />
            </ConditionalRender>

            

            <div className="line_seperator_ex_light_grey" style={{margin: "12px 0px 20px"}}></div>
            <ConditionalRender if={checkUserAccess('auto_publishing_fields')}>
                <div className='flex_container_space_between'
                    onClick={() => handleSectionToggle("Auto Publishing")}
                    style={{cursor: "pointer"}}
                >
                    <div className="text_subtitle" style={{marginBottom: '6px'}}>
                    Auto Publishing
                    </div>
                    <img
                        src={GreyArrowDown}
                        className={
                            teamData.activeSectionTab === "Auto Publishing"
                                ? "to_arrow_up"
                                : "to_arrow_down"
                        }
                        style={{ width: 24 }}
                    />
                </div>
            </ConditionalRender>
            <ConditionalRender if={teamData.activeSectionTab === "Auto Publishing"}>
                <div style={{marginBottom: 20}}>
                    <AnInputCheckBox
                        label="Auto Publish"
                        isChecked={teamData.team.auto_publish_enabled}
                        style={{marginBottom: "12px"}}
                    >
                        <input
                            type="checkbox"
                            checked={teamData.team.auto_publish_enabled}
                            onChange={handleAutoPublish}
                        >
                        </input>
                    </AnInputCheckBox>
                    <ConditionalRender if={teamData.team.auto_publish_enabled}>
                        <AnInputBox label="Score Threshold" style={{maxWidth: "200px"}}>
                            <input type="number"
                                    name="auto_publish_score"
                                    value={teamData.team.auto_publish_score}
                                    onChange={handleInputChange}
                                    min="1"
                                    max="100"
                                    placeholder="Score for auto publish"/>
                        </AnInputBox>
                        <AnInputBox label="Contains tags (Comma separated)">
                            <input type="text"
                                    name="auto_publish_tags"
                                    value={teamData.team.auto_publish_tags}
                                    onChange={handleInputChange}
                                    placeholder="company_matches(1-50), name_company_distance(1-150), non-generic"/>
                        </AnInputBox>
                        <AnInputBox label="Doesn’t contain tags (Comma separated)">
                            <input type="text"
                                   name="auto_publish_excl_tags"
                                   value={teamData.team.auto_publish_excl_tags}
                                   onChange={handleInputChange}
                                   placeholder="partial_author_designation"/>
                        </AnInputBox>
                    </ConditionalRender>
                </div>
            </ConditionalRender>
            <ConditionalRender if={current_user.is_admin}>
                <div className="line_seperator_ex_light_grey" style={{margin: "12px 0px 20px"}}></div>
                <div className='flex_container_space_between' 
                    onClick={() => handleSectionToggle("Admin Settings")}
                    style={{cursor: "pointer"}}
                >
                    <div className="text_subtitle" style={{marginBottom: '6px'}}>
                        Admin Settings
                    </div>
                    <img
                        src={GreyArrowDown}
                        className={
                            teamData.activeSectionTab === "Admin Settings"
                                ? "to_arrow_up"
                                : "to_arrow_down"
                        }
                        style={{ width: 24 }}
                    />
                </div>
                <ConditionalRender if={teamData.activeSectionTab === "Admin Settings"}>
                        <AdminSettings
                            teamData={teamData}
                            setTeamData={setTeamData}
                            handleDisplayableFeatures={handleDisplayableFeatures}
                            handleInputChange={handleInputChange}
                            teamSettingsChange={teamSettingsChange}
                            initialData={initialData}
                        >
                        </AdminSettings>
                </ConditionalRender>
            </ConditionalRender>
            <Button buttonSize="LessPadding" isLoading={teamData.isLoading && !teamData.popupsList.aliasesPopup}
                innerButtonStye={{padding: '8px 16px'}}
                buttonStyle={{marginTop: "20px", width: 'max-content'}}
                onClick={handleSubmit}
                >
                    Save changes
            </Button>
            {
                (teamData.successMessage || teamData.serverError) && (<FlashMessages
                    messageTheme={flash_type}
                    closeCallback={() => {
                        teamDataDispatcher({
                            type: ALL_TEAM_ACTIONS["SET_REQUEST_STATUS"],
                            payload: {
                                successMessage: null,
                                serverError: null,
                            }
                        });
                    }}
                >
                    {teamData.successMessage || teamData.serverError}
                </FlashMessages>)
            }
            {
                teamData.popupsList.aliasesPopup && renderTeamALiasesPopup()
            }
        </>
  )
}

export default TeamSettings

const teamSettingsReducer = (state, action) => {
    switch(action.type){
        case ALL_TEAM_ACTIONS["SET_STATE"]:{
            return {...state, ...action.payload}
        }
        case ALL_TEAM_ACTIONS["SET_TEAM"]: {
            return {...state, team: {
                ...state.team,
                ...action.payload
            }}
        }
        case ALL_TEAM_ACTIONS["SET_ERRORS"]:{
            return {...state, errors: {...action.payload}}
        }
        case ALL_TEAM_ACTIONS["SET_REQUEST_STATUS"]:{
            return {
                ...state,
                ...action.payload
            }
        }
        default: return {...state}
    }
}

const ALL_TEAM_ACTIONS = {
    "SET_STATE": "SET_STATE",
    "SET_TEAM": "SET_TEAM",
    "SET_ERRORS": "SET_ERRORS",
    "SET_REQUEST_STATUS": "SET_REQUEST_STATUS"
}
