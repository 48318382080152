import {apiAgent} from '../../../axiosConfig.js'
import {useEffect, useState} from 'react'

const InvitedTeamMembers = () => {

  const [invitedMembers, setInvitedMembers] = useState([])

  useEffect(() => {
      apiAgent({
        menthod: 'get',
        url: '/team_members/invites',
        params: {
        }
      }).then(res => {
          if(res.data){
              setInvitedMembers(res.data.data);
          }
      }).catch(err => {
      })
  }, [])

  return (
    <>
      <table className='manage_team_table_container'>
        <thead>
          <tr className="text_body_big_light" style={{border: "0.4px solid #B6B9BB", background: "#F8F8F8", padding: "14px 24px"}}>
            <th style={{padding: "14px 24px", width: "45%", border: "none"}}>Name</th>
            <th style={{padding: "14px 24px", width: "45%", border: "none"}}>Type</th>
            <th style={{padding: "14px 24px", width: "10%", border: "none"}}></th>
          </tr>
        </thead>
        <tbody>
          {
            invitedMembers.map((member) => (
              <tr key={member.id} style={{border: "0.4px solid #B6B9BB", padding: "14px 24px"}}>
                <td style={{padding: "12px 24px", border: 'none'}}>
                  {member.email}
                </td>
                <td style={{padding: "12px 24px", border: 'none'}}>Regular</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </>
  )
}

export default InvitedTeamMembers
