import { createContext, useContext } from "react";

const TempMapperContext = createContext();

export const useTempMapperContext = () => useContext(TempMapperContext);

export const TempMapperProvider = ({ children, pageData, pageDataReducer, handlePopupClose, toggleDropDown, mappingType }) => {
    return (
        <TempMapperContext.Provider value={{ pageData, pageDataReducer, handlePopupClose, toggleDropDown, mappingType }}>
            {children}
        </TempMapperContext.Provider>
    );
};
export default TempMapperContext;
