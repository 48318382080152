import React, { useLayoutEffect } from 'react'
import { useState, useReducer } from "react";
import { Button } from "../../../../cta_utils/AnButton";
import ConditionalRender from "../../../../../ConditionalRender";
import CalendarIcon from '../../../../../assets/CalendarIcon.svg'
import GreenDot from '../../../../../assets/GreenDot.svg'
import GreyArrowDown from '../../../../../assets/GreyArrowDown.svg'
import AnDropDown from '../../../../cta_utils/AnDropDown';
import { Calendar } from 'react-modern-calendar-datepicker';
import { datepicker_input_format } from '../../../../utils/dateUtils';
import Icon from '../../../../utils/Icon';
import Tooltip from '../../../../cta_utils/Tooltip';


const MatchingArticleDateFilters = ({
    filtersState,
    filtersStateReducer,
    tempFilterReducer,
    customStyle = { padding: "9px 16px", position: "relative", margin: 0 },
    innerText = 'Date Range',
    position = 'center',
    theme, // themes = ["GREEN"]
    ...props
}) => {
    const [tempFiltersState, tempFiltersStateReducer] = useReducer(tempFilterReducer, { ...filtersState })
    const [componentState, setComponentState] = useState({
        isDisplayable: false,
        activeDateTab: "created_at",
        errors: {},
        currentDates: {
            from: null,
            to: null
        }
    })
    useLayoutEffect(() => {
            let calender_week_rows_collection = document.getElementsByClassName("Calendar__weekRow") 
            let calender_week_rows = Array.from(calender_week_rows_collection);
            calender_week_rows.forEach((week_row) => {
                let range_start = document.getElementsByClassName("-selectedStart")
                if(!range_start) week_row.classList.add("calender_week_row")
            })
    },[tempFiltersState.dateRanges])
    const dateRangeApplied = Object.keys(filtersState.dateRanges).filter(k => filtersState.dateRanges[k].value.from && filtersState.dateRanges[k].value.to).length > 0;
    const toggleDropDown = (e) => {
        if (!componentState.isDisplayable)
            tempFiltersStateReducer({
                type: "APPLY_DATE_RANGES",
                payload: {
                    dateRanges: filtersState.dateRanges
                },
            });
        setComponentState(s => ({ ...s, isDisplayable: !s.isDisplayable }))
    }
    const handleActiveTab = (e, field) => {
        e.stopPropagation();
        if (field == componentState.activeDateTab) {
            field = null;
        }
        setComponentState({ ...componentState, activeDateTab: field });
    }

    const applyDateRangefilters = (e) => {
        e.stopPropagation();
        let errors = {};
        if (!tempFiltersState.dateRanges[componentState.activeDateTab].value.to || !tempFiltersState.dateRanges[componentState.activeDateTab].value.from) {
            errors.apply_filters = "Select complete date range";
        }
        setComponentState({ ...componentState, errors: errors });
        if (Object.keys(errors).length > 0) return;
        filtersStateReducer({
            type: "APPLY_DATE_RANGES",
            payload: {
                dateRanges: tempFiltersState.dateRanges
            },
        });
        toggleDropDown();
    }
    const renderOptions = () => {
        return Object.keys(tempFiltersState.dateRanges).map(k => {
            let option = tempFiltersState.dateRanges[k];
            let from = null;
            let placeholderFrom = "1 June 2022";
            let placeholderTo = "30 June 2022";
            if (option.value.from)
                from = new Date(datepicker_input_format(option.value.from, "mm-dd-yyyy", "/")).toLocaleDateString("en-UK",
                    { year: 'numeric', month: 'long', day: 'numeric' });
            let to = null;
            if (option.value.to)
                to = new Date(datepicker_input_format(option.value.to, "mm-dd-yyyy", "/")).toLocaleDateString("en-UK",
                    { year: 'numeric', month: 'long', day: 'numeric' });
            return (
                <div key={option.label} style={{ marginTop: "16px" }}>
                    <div className='flex_container_space_between' onClick={(e) => handleActiveTab(e, k)}>
                        <div className='default_flex_container'>
                            <div className='text_subtitle' style={{ marginRight: "8px" }}>
                                {option.label}
                            </div>
                            <ConditionalRender if={option.value.from && option.value.to}>
                                <img src={GreenDot}></img>
                            </ConditionalRender>
                        </div>
                        <img src={GreyArrowDown}
                            className={
                                componentState.activeDateTab == k ?
                                    "to_arrow_up"
                                    : "to_arrow_down"
                            }
                            style={{ width: 24 }}
                        ></img>
                    </div>
                    <ConditionalRender if={componentState.activeDateTab == k}>
                        <div className="text_body_light" style={{ margin: "12px auto 6px" }}>
                            Selected range
                        </div>
                        <div className="default_flex_container text_body_big"
                            style={{ background: "#F3F4F5", padding: "9px 17px", gap: "4px", borderRadius: "4px" }}
                        >
                            <span className={from ? "text_body" : "text_subtitle_light_grey_semi_bold"}>{from ? from : placeholderFrom} - </span>
                            <span className={to ? "text_body" : "text_subtitle_light_grey_semi_bold"}>{to ? to : placeholderTo}</span>
                        </div>
                        <div style={{ marginTop: "16px" }}>
                            <Calendar
                                value={tempFiltersState.dateRanges[k].value}
                                onChange={(val) => {
                                    tempFiltersStateReducer({
                                        type: "UPDATE_DATE_RANGE",
                                        payload: {
                                            key: k,
                                            subkey: "value",
                                            value: val
                                        }
                                    })
                                }}
                                calendarPopperPosition="bottom"
                                calendarClassName='responsive-calendar'
                                colorPrimary='#00C4B5'
                                colorPrimaryLight='#00C4B51A'
                                calendarRangeBetweenClassName="date_range_calender"
                                shouldHighlightWeekends
                                maximumDate={option.maxDate}
                            >
                            </Calendar>
                        </div>
                        <div className='default_flex_container'>
                            <Button
                                buttonStyle={{ width: "100%", marginTop: "16px" }}
                                onClick={applyDateRangefilters}

                            >Apply</Button>
                        </div>
                        <ConditionalRender if={componentState.errors.apply_filters}>
                            <div className="text_caption_error" style={{ marginTop: 8 }}>
                                {componentState.errors.apply_filters}
                            </div>
                        </ConditionalRender>
                    </ConditionalRender>
                    <div className="line_seperator_ex_light_grey" style={{ marginTop: "16px" }}></div>
                </div>
            )
        })
    }
    const renderDropDown = () => {
        let style = { left: -180, right: 'auto' }
        if (position === 'left') style = { left: 'auto', right: 0 }
        if (position === 'right') style = { left: 0, right: 'auto' }
        return (
            <AnDropDown containerClass="an_dropdown_box date_calendar_box"
                style={{ ...style, width: 368 }}
                toggleDropDown={toggleDropDown}
            >
                <div className='flex_container_space_between'>
                    <div className='text_title'>Date Range</div>
                    <div className='text_link' onClick={(e) => {
                        e.stopPropagation()
                        filtersStateReducer({
                            type: "CLEAR_DATE_RANGES",
                        })
                        toggleDropDown();
                    }
                    }
                    >Clear</div>
                </div>
                <div className="line_seperator_ex_light_grey" style={{ margin: "12px auto 16px" }}></div>
                <div onClick={(e) => e.stopPropagation()}>
                    {renderOptions()}
                </div>
            </AnDropDown>
        )
    }
    return (
        <>
            {theme === 'GREEN' ?
                <div style={{ position: 'relative' }}>
                    <Tooltip
                        tooltipText={() => 'Date Range'}
                        innerClass='text_body_light'
                        textTheme='background-black bottom'
                        innerStyle={{ padding: '8px', alignSelf: 'center', color: 'white', borderRadius: '8px', opacity: '70%' }}
                        style={{ marginTop: 4 }}
                        showWithoutOverflowing={true}
                    >
                        <div className={(dateRangeApplied ? "applied_filter_icon " : "") + "flex_center icon_box_container"} style={{ position: 'static', ...customStyle }} onClick={toggleDropDown}>
                            <Icon name="CalendarIcon" className={dateRangeApplied ? "white_image" : ""} />
                        </div>
                    </Tooltip>
                    {
                        componentState.isDisplayable && renderDropDown()
                    }
                </div>
                :

                <div className="flex_container_space_between filters_button_box text_body_light" style={customStyle}
                    onClick={toggleDropDown}>
                    <img src={CalendarIcon} style={{ width: 22, height: 22 }}></img>
                    {innerText && <div style={{ marginLeft: "8px" }}>{innerText}</div>}
                    <ConditionalRender if={dateRangeApplied}>
                        <img src={GreenDot} style={{ width: 6, position: 'absolute', top: 4, right: 2 }} ></img>
                    </ConditionalRender>
                    {
                        componentState.isDisplayable && renderDropDown()
                    }
                </div>
            }
        </>
    )
}

export default MatchingArticleDateFilters