import React, { useState } from "react"
import AnDropDown from "./AnDropDown"
import GreyDownArrow from "../../assets/GreyArrowDown.svg";
const CommentDropdown = (props) => {
    const [showdropdown, setShowdropdown] = useState(false);
    const handleCommentEdit = () => {
        return (
            <AnDropDown
                    style={{
                        position: "absolute",
                        padding: "14px",
                        background: "white",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                        borderRadius: 4,
                        width: "max-content",
                        zIndex: 99,
                    }}
                    toggleDropDown={() => setShowdropdown(false)}
                >
                    <div
                        id={props.commentID}
                        className="an_dropdown_filter text_body_light"
                        onClick={() => props.editCommentSection(props.commentID, props.prevComment)}
                    >
                        Edit Comment
                    </div>
                    <div
                        id={props.commentID}
                        className="an_dropdown_filter text_body_light"
                        onClick={() => props.deleteComment(props.commentID)}
                    >
                        Delete Comment
                    </div>
            </AnDropDown>
        )
    }
    return (
        <>
            <div onClick={() => setShowdropdown(!showdropdown)}>
            <img src={GreyDownArrow} style={{cursor: "pointer"}}></img>
            { showdropdown && handleCommentEdit() }
            </div>   
        </>
    )
}

export default CommentDropdown;