import MoveToHigh from "../../../../../assets/help_center/move_to_high.png"
import MoveToHighInfo from "../../../../../assets/help_center/move_to_high_info.png"
import AnScrollToLinkPage from "../../../../cta_utils/AnScrollToLinkPage";

export const LowConfidenceResults = () => {
    return (
        <div style={{ margin: "24px 0px" }}>
        <div className="help_center_top_section"> 
            <div className="text_title_big" style={{marginBottom: 20}}>Low confidence results</div>
            <div style={{border: "1px solid grey", padding: "14px 20px", borderRadius: "4px", fontWeight: "700"}}>
                <div className="text_body" style={{color: "#707274", fontWeight: 700}}> SKIP AHEAD TO</div>
                <div style={{marginTop: "12px"}}>
                    <span style={{color: "#437EFE", fontSize: "14px"}}><AnScrollToLinkPage href="#move_to_high"> Move to high confidence </AnScrollToLinkPage></span>
                </div>
            </div>
        </div>
            <img className="help_center_img" src={MoveToHigh} style={{maxWidth: "650px", maxHeight: "470px", margin: "24px auto"}}></img>


            <div style={{ color: '#282A2C', fontSize: '16px', marginTop: "32px", lineHeight: '22px' }}>
                Low confidence results refer to those that are less certain or have a higher degree of uncertainty, and may require further information to confirm their accuracy. These are usually indicated by a lower probability or likelihood of accuracy. Just like High Confidence results, you can view the Name, Company and designation of the prospect.
                <br/><br/> If you are certain that a news article is accurate and should be considered reliable, you have the option to move it from Low Confidence to High Confidence by clicking on the designated button. This feature can help improve the accuracy of the system's results by providing more reliable data for future analysis. However, it is important to exercise caution when using this feature, as misclassifying articles can also result in less accurate results. Therefore, it is recommended to only move articles to High Confidence after carefully reviewing their content and verifying their accuracy.  
            </div>

            <div className="help_center" id="move_to_high">
                Move to High Confidence
            </div>

            <img className="help_center_img" src={MoveToHighInfo} style={{maxWidth: "720px", maxHeight: "696px", margin: "24px auto 0px"}}></img>
        </div>
    );
};