import React from 'react'

import {apiAgent} from './axiosConfig';
import Pagination from './Pagination';
import ConditionalRender from './ConditionalRender'
import { AnInputBox } from './components/input_utils/AnInput';
import { Button, TabbedButton } from './components/cta_utils/AnButton';
import ArticleContent from './components/feed_components/ArticleContent';
import { LoaderComponent } from "./components/utils/LoaderComponent"
import getApiUrl from "./components/utils/UrlHelper.js";

class Articles extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      url: "",
      person_name: "",
      company_name: "",
      error: null,
      isLoading: false,
      items: [],
      active: "Articles",
      isLoading: false,
      isFetching: false,
      data: {},
    };
  }
  fetchArticleSource = () => {
    this.setState({
      isLoading: true,
      isFetching: true,
    })
    apiAgent({
        method: "get",
        url: getApiUrl("fetch_article_source"),
        params: {
            q: this.state.searchUrl
        },
    }).then(
        (res) => {
            this.setState({
              isLoading: false,
              isFetching: false,
              data: res.data.data,
            })
        },
        (error) => {
          this.setState({
            isLoading: false,
            isFetching: false,
          })
        }
    );
  }

  fetchArticles = (page) => {
    this.setState({
      isLoading: true,
    });

    apiAgent({
      method: 'get',
      url: '/search/articles',
      params: {
        person: this.state.person_name,
        company: this.state.company_name,
        url: this.state.url,
        page: page
      }
    }).then(
      (response) => {
        let result = response.data;
        this.setState({
          isLoading: false,
          items: result.results,
          totalPages: result.total_pages,
          currentPage: page
        });
      },
      (error)  => {
        this.setState({
          isLoading: false,
          error
        });
      }
    );
  }

  onInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  changeActiveTab = (tab) => {
    this.setState({
      active: tab
    })
  }

  handleOnKeyDown = (e) => {
      if (e.key === "Enter") {
          this.fetchArticleSource();
      }
  };

  render(){
    const { items } = this.state;
    return (
      <div style={{margin: "33px 49px"}}>
        <div className='text_title_big'>Search Articles</div>
        <div style={{display: 'flex', justifyContent: 'flex-start', width: 350}}>
            <TabbedButton buttonStyle={{padding: '16px 0px 12px'}} isActive={this.state.active==='Articles'} 
            onClick={() => this.changeActiveTab("Articles")}>
                Search news
            </TabbedButton>
            <TabbedButton buttonStyle={{padding: '16px 16px 12px'}}isActive={this.state.active === 'Article_Search'} 
            onClick={() => this.changeActiveTab("Article_Search")}>
                Search article information
            </TabbedButton>
        </div>
        <ConditionalRender if={this.state.active === "Articles"}>
          <div className='text_body' style={{padding: "16px 0px 12px"}}>Search by</div>        
          <div className="text_body default_grid_container" style={{gridTemplateColumns: "1fr 3fr", maxWidth: "500px", columnGap: "26px"}}>
            <div>URL:</div>
            <AnInputBox>
              <input type="text" name="url" value={this.state.url} onChange={this.onInputChange} placeholder="Enter URL"/>
            </AnInputBox>
            <div>Person Name:</div>
            <AnInputBox>
              <input type="text" name="person_name" value={this.state.person_name} onChange={this.onInputChange} placeholder="Enter Person Name"/>
            </AnInputBox>
            <div>Company Name:</div>
            <AnInputBox>
              <input type="text" name="company_name" value={this.state.company_name} onChange={this.onInputChange} placeholder="Enter Company Name"/>
            </AnInputBox>
          </div>
          <Button 
            buttonStyle={{marginTop: "24px"}}
            innerButtonStye={{padding: "8px 57px"}}
            onClick={() => this.fetchArticles(1)}
          >
            Search
          </Button>
          <div  className="line_seperator_ex_light_grey"  style={{ margin: "24px auto" }}></div>

          <ConditionalRender if={items && items.length > 0 && !this.state.isLoading}>
            <div style={{maxWidth: "780px"}}>
              <div className='text_title' style={{marginBottom: "24px"}}>
                Search Results
              </div>
              <Pagination totalPages={this.state.totalPages}
                currentPage={this.state.currentPage}
                onPageChange={this.fetchArticles}
                style={{padding: "0px", marginBottom: "24px"}}
              />
                {
                  items.map(item => (
                    <ArticleContent key={item.id} 
                      item={{shared_link: item}}
                      containerClass="default_flex_container news_container "
                      style={{marginBottom: "16px", alignItems: "flex-start"}}/>
                  ))
                }
            </div>          
          </ConditionalRender>
        </ConditionalRender>
        <ConditionalRender if={this.state.active === "Article_Search"}>
        <>
          <div className="default_flex_container" style={{maxWidth: "700px", margin: "16px 0px 24px"}}>
            <div className="an_search_box" style={{flexBasis: "100%"}}>
              <div style={{ position: "relative" }}>
                <input
                    type="text"
                    name="searchUrl"
                    value={this.state.searchUrl}
                    placeholder="Search Article"
                    onChange={this.onInputChange}
                    onKeyDown={this.handleOnKeyDown}
                />
                <span className="an_search_lens">
                    <img src="/search.svg" />
                </span>
              </div>
            </div>
            <Button buttonStyle={{marginLeft: "12px"}}
                innerButtonStye={{padding: "9px 44px"}}
                onClick={() => this.fetchArticleSource()}                    
            >
                Search
            </Button>
          </div>
          <div className="text_title">Article Information</div>
          <div className="default_flex_container" style={{marginTop: 20}}  >
              <div className="text_body_light" style={{width: 300}}>Rss Source</div>
              <div className="text_body">{this.state.data.rss_source}</div>
          </div>
          <div className="default_flex_container" style={{marginTop: 20}}  >
              <div className="text_body_light" style={{width: 300}}>Created At</div>
              <div className="text_body">{this.state.data.created_at}</div>
          </div>
          <div className="default_flex_container" style={{marginTop: 20}}  >
              <div className="text_body_light" style={{width: 300}}>Published at</div>
              <div className="text_body">{this.state.data.published_at}</div>
          </div>
          <div className="default_flex_container" style={{marginTop: 20}}  >
              <div className="text_body_light" style={{width: 300}}>Unscheduled At</div>
              <div className="text_body">{this.state.data.unscheduled_at}</div>
          </div>
          <div className="default_flex_container" style={{marginTop: 20}}  >
              <div className="text_body_light" style={{width: 300}}>Unscheduled Reason</div>
              <div className="text_body" style={{maxWidth: "950px", width: "100%"}}>{this.state.data.unscheduled_reason}</div>
          </div>
            </>
        </ConditionalRender>
        <LoaderComponent isLoading={this.state.isLoading} ></LoaderComponent>        
      </div>
    );
  }
}

export default Articles;
