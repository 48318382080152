import React, { useEffect } from 'react'
import { LoaderComponent } from './components/utils/LoaderComponent'
import { gtag } from 'ga-gtag';

const RecordingAnalytics = () => {
    
    useEffect(() => {
        let paramsString = window.location.search;
        let searchParams = new URLSearchParams(paramsString);
        let redirect_url = searchParams.get("redirect_url")
        gtag('config', 'G-2G4DKNZVWE', {
          'page_title': redirect_url,
          'page_location': window.location.pathname
        });

        window.location = redirect_url;
    })
    
  return (
    <LoaderComponent isLoading={true}></LoaderComponent>
  )
}

export default RecordingAnalytics