import React, { useEffect, useState } from 'react'
import AnDropDown from "./AnDropDown.js";

const SORT_OPTIONS = [
    {
        sort_type: 'asc',
        label: "Open Rate: Ascending",
        key: {last_week: "last_week_asc", overall: "overall_asc"}
    },
    {
        sort_type: 'desc',
        label: "Open Rate: Descending",
        key: {last_week: "last_week_desc", overall: "overall_desc"}
    }
]
const AnSortFilter = ({
    email_type='last_week',
    sortSelected,
    onChangeCallback,
    toggleDropDown,
    ...props
}) => {
    
    const handleChange = (option) => {
        onChangeCallback({email_type: email_type, sort_type: option.sort_type , val: option.key[email_type]});
        toggleDropDown();
    }

    const handleRemoveSortFilter = () => {
        onChangeCallback({});
        toggleDropDown();
    }

    const renderDropDown = () => {
        return(
            <AnDropDown style={{position: 'absolute', marginTop:"12px", padding: '20px', background: 'white', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', borderRadius: 4, width: 'max-content', zIndex: 99}}
                toggleDropDown={toggleDropDown}>
                {   
                    SORT_OPTIONS.map((option, index) => 
                    <div 
                        key={index + option.label}
                        className={ sortSelected.val == option.key[email_type] ? "an_dropdown_filter text_body" : "an_dropdown_filter text_body_light" }
                        style={{cursor: 'pointer'}} onClick={(e) => handleChange(option)}>{option.label}
                    </div>        
                )}      
                <div  className="line_seperator_ex_light_grey" style={{ margin: "8px auto" }}></div>
                <span className='text_body_bold'
                 style={{cursor: 'pointer'}}
                 onClick={handleRemoveSortFilter}
                  >Remove Sort</span>   
            </AnDropDown>
        )
    }
    return (
        <>
        { renderDropDown() }
        </>
    )
}

export default AnSortFilter;