import { ProspectListFiltersTabs } from "../../../../utils/AllFilterTabs";
import { indexOfObject } from "../../../../utils/ArrayUtils"
import ALL_ACTIONS from "../actions/prospectListActionTypes"

export const initialListState = {
    prospectList: [],
    tagsList: [],
    assigneeList: [],
    searchTerm: '', 
    prospectType: '',
    isFetching: false,
    popupsList: {},
    newProspect: {},
    addingProspect: false,
    totalPages: 0,
    total: 0,
    isLoading: false, //processingSingleProspect: false, //TODO: change from isLoading to maybe something meaningful
    currentPage: 0,
    currentProspect: {},
    currentTags: [],
    currentAssigns: [],
    prospectDropDown: null,
    errors: {},
    archived: null,
    bulk_archive_type: 'archive',
    importFile: {
        files: [],
        names: [],
        uploaded: false,
        uploadedUrl: null,
    },
    isUploadingFile: false,
    successMessages: {
        message: "",
        from: "",
    },
    allFilterTabs: ProspectListFiltersTabs
}


export const ProspectsListReducer = (state, action) => {
    let {payload} = action;
    switch(action.type){
        case ALL_ACTIONS["SET_STATE"]: {
            return {...state, ...action.payload}
        }
        case ALL_ACTIONS["SET_NEW_PROSPECT"]:{
            return {
                ...state, 
                newProspect:{
                    ...state.newProspect,
                    ...action.payload
            }}
        }
        case ALL_ACTIONS["UPDATE_PROSPECT_LIST"]:{
            let prospect_id = payload.prospect_id;
            let ind = indexOfObject(state.prospectList, "id", prospect_id)
            let temp = [...state.prospectList]
            if(ind != -1) temp.splice(ind, 1);
            
            return {
                ...state,
                prospectList: temp,
                successMessages: payload.successMessages
            }         
        }
        default:{
            return {...state}
        }
    }
}