import  React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import {Button} from '../../cta_utils/AnButton.js'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import CreatTeamIcon from '../../../assets/connect_team1.svg'
import FindTeamIcon from '../../../assets/connect_team2.png'
import {apiAgent} from "../../../axiosConfig";
import {LoaderComponent} from "../../utils/LoaderComponent";
import Cookies from "universal-cookie";
import {COOKIE_SETTINGS} from "../../../config/cookiesSettings";
import {updateCurrentTeam} from "../../../redux/currentTeamSlice";
import { AnInputRadioButton } from '../../input_utils/AnInputRadioButton.js'

const ChooseTeam = (props) => {
  const [selectedOption, setSelectedOption] = useState("create_team");
  const [selectedState, setSelectedState] = useState("invite");
  const [currentTeam, setCurrentTeam] = useState({});
  const [isLoading, setIsLoading] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  let _history = useHistory()
  let current_user = useSelector(state => state.current_user.user)

  useEffect(() => {
    setIsFetching(true)
    apiAgent({
      method: 'get',
      url: `/users/team_invites`,
      params: {
      }
    }).then(res => {
      var teams = res.data.data;
      if(teams.length > 0) {
        setCurrentTeam(teams[0]);
      }
      else{
        setSelectedState("connect");
      }
      setIsFetching(false)
    }).catch(err => {
    })
  }, [])

  const onContinue = () => {
    if(selectedOption == 'create_team'){
      _history.push('./create_team')
    }else{
      _history.push('./find_team')
    }
  }

  const onAccept = () => {
    setIsLoading(true)
    apiAgent({
      method: 'post',
      url: '/team_members/accept_invite',
      data: {
        team_id: currentTeam.id
      }
    }).then(res => {
      setIsLoading(false)
      let cookies = new Cookies()
      cookies.set('team_id', currentTeam.id, {path: '/', maxAge: COOKIE_SETTINGS['AUTH_DURATION']})
      _history.push('/')
    }).catch(err => {
      setIsLoading(false)
    })
  }

  const onReject = () => {
    setIsLoading(true)
    apiAgent({
      method: 'post',
      url: '/team_members/reject_invite',
      data: {
        team_id: currentTeam.id
      }
    }).then(res => {
      setSelectedState("connect")
    }).catch(err => {
      setIsLoading(false)
    })
  }

  if(selectedState === 'invite'){
    if(isFetching === true){
      return(<LoaderComponent isLoading={isFetching} loaderStyle={{margin: 'auto', display: 'block', width: 32}}/> );
    }
    else {
      return (
          <div style={{width: '480px', margin: '64px auto'}}>
            <div>
              You have been requested {currentTeam.requester_name ? 'by '+currentTeam.requester_name : ''} to join {currentTeam.name} team on AlmaConnect News.
            </div>
            <div style={{display: 'flex'}}>
              <Button buttonSize="Big" onClick={onAccept} isLoading={isLoading}
                      buttonStyle={{width: "100%", marginTop: 16}}>
                Join Team
              </Button>
              <Button type="Secondary" buttonSize="Big" onClick={onReject} isLoading={isLoading}
                      buttonStyle={{width: "100%", marginTop: 16, marginLeft: '16px'}}>
                Reject
              </Button>
            </div>
            <div style={{textAlign: 'center', marginTop: '16px'}}>
              or <span className="text_link" onClick={()=>{setSelectedState("connect")}}>Create a new team on AlmaConnect News</span>
            </div>
          </div>
      );
    }
  }
  else {
    return (
        <>
          <div className="" style={{maxWidth: 460, boxSizing: "border-box", margin: "32px auto 0px"}}>
            <div className="text_title_big" style={{textAlign: 'center'}}>
              Hey, Is your team already on AlmaNews?
            </div>
            <div style={{maxWidth: 400, margin: "auto"}}>
              <div className="box-shadow-content" style={{
                maxWidth: 400,
                marginTop: 32,
                position: "relative",
                border: (selectedOption == 'create_team' ? '2px solid #00c4b5' : 'none')
              }}>
                <AnInputRadioButton style={{position: "absolute", right: 24}}>
                  <input type="radio" id="create_team" name="proceed_options" value="create_team"
                        checked={selectedOption == 'create_team'}
                        onClick={(e) => {
                          setSelectedOption(e.target.value)
                        }}
                  />
                </AnInputRadioButton>
                <label htmlFor="create_team"
                       style={{display: "flex", justifyContent: "flex-start", alignItems: "center", cursor: 'pointer'}}>
                  <img src={CreatTeamIcon} style={{maxWidth: 80, borderRadius: "50%"}}/>
                  <div style={{marginLeft: 20}}>
                    <div className="text_body_bold">
                      I'm first from my team
                    </div>
                    <div className='text_caption_light' style={{marginTop: 4}}>
                      Create a new network for your institution
                    </div>
                  </div>
                </label>
              </div>

              <div className="box-shadow-content" style={{
                maxWidth: 400,
                marginTop: 20,
                position: "relative",
                border: (selectedOption == 'choose_team' ? '2px solid #00c4b5' : 'none')
              }}>
                <AnInputRadioButton style={{position: "absolute", right: 24}}>
                  <input type="radio" id="choose_team" name="proceed_options" value="choose_team"
                        onClick={(e) => {
                          setSelectedOption(e.target.value)
                        }}                        
                  />
                </AnInputRadioButton>
                <label htmlFor="choose_team"
                       style={{display: "flex", justifyContent: "flex-start", alignItems: "center", cursor: 'pointer'}}>
                  <img src={FindTeamIcon} style={{maxWidth: 80, borderRadius: "50%"}}/>
                  <div style={{marginLeft: 20}}>
                    <div className="text_body_bold">
                      I want to join my teammates
                    </div>
                    <div className='text_caption_light' style={{marginTop: 4}}>
                      Join an already existing network
                    </div>
                  </div>
                </label>
              </div>
              <Button buttonSize="Big" onClick={onContinue} isLoading={isLoading}
                      buttonStyle={{width: "100%", marginTop: 32}}>
                continue
              </Button>
            </div>
          </div>
        </>
    )
  }
}

export default ChooseTeam
