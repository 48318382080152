import AnPopup from "../../../cta_utils/AnPopup";

export const SummaryPopup = ({pageData, handlePopupClose}) => {
    return(
        <AnPopup
            name="summaryPopup"
            handleOnClosePopup={handlePopupClose}
            maxWidth={480}
        >
            <div className="text_title">Summary</div>
            <div  className="line_seperator_ex_light_grey"  style={{ margin: "16px -24px" }}></div>
            <div className="text_body_light">Average Confidence of all Mapped profiles - <span className="text_body">{pageData.average_confidence_of_mapped}%</span></div>
            <div className="text_body_light" style={{paddingTop: 12}}>Number of Mapped prospects - <span className="text_body">{pageData.no_of_mapped}</span></div>
            <div className="text_body_light" style={{paddingTop: 12}}>Number of Unmapped prospects - <span className="text_body">{pageData.no_of_unmapped}</span></div>
            <div className="text_body_light" style={{paddingTop: 12}}>Number of prospects with 0 suggestions - <span className="text_body">{pageData.no_of_no_sugg}</span></div>
        </AnPopup>
    )
}