import React, { useReducer } from 'react'
import reducer from '../../utils/CommonReducer'
import UnmappedJobUpdates from './_UnmappedJobUpdates'
import MappedJobUpdates from './_MappedJobUpdates'

const PublishJobUpdates = () => {
    const [componentData, setComponentData] = useReducer(reducer, {tabType: "unmapped", mappedCount: 0, unmappedCount: 0})
    const setComponentState = (payload) => {
        setComponentData({type: "SET_STATE", payload})
    }
    const {tabType, mappedCount, unmappedCount} = componentData;
    return (
        <div className='publish_job_container' style={{maxWidth: "640px", marginTop: "24px"}}>
            <div className="newsfeed_tabs">
                <div onClick={() => setComponentState({tabType: "unmapped"})} className={tabType==='unmapped' ? "active":""} style={{padding: '13px 0px', textAlign: 'center', width: '50%'}}>
                    <div>Unmapped</div>
                    <div className='text_caption_light' style={{fontWeight: 700}}>({unmappedCount})</div>
                    {/* <div>High Confidence Results</div>
                    <ConditionalRender if={!isWidthDown(windowDimensions.width, "md") && newsFeedState.high_confidence_results_count > 0}>
                        <div className='text_caption_light' style={{fontWeight: 700}}> ({newsFeedState.high_confidence_results_count})</div>
                    </ConditionalRender> */}
                </div>
                <div onClick={() => setComponentState({tabType: "mapped"})} className={tabType==='mapped' ? "active":""} style={{padding: '13px 0px', textAlign: 'center', width: '50%'}}>
                    <div>Mapped And Posted</div>
                    <div className='text_caption_light' style={{fontWeight: 700}}>({mappedCount})</div>                    
                </div>               
            </div>
            {tabType === "unmapped" && <UnmappedJobUpdates setParentComponentState={setComponentState}/>}
            {tabType === "mapped" && <MappedJobUpdates />}
        </div>
    )
}

export default PublishJobUpdates