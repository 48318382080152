import { useState } from "react"
import AnDropDown from "./AnDropDown"
import { AnInputCheckBox } from "../input_utils/AnInputCheckBox"
import { AnInputBox } from "../input_utils/AnInput"
import darkDropdown from "../../assets/darkDropdown.svg"
const AnCheckBoxDropdown = ({
    label,
    options,
    selectedOptions,
    setSelectedOptions,
    innerStyle,
    inputClass = 'filter_input_box mapper_filter_input',
    ...props
}) => {
    const [showdropdown, setShowdropdown] = useState(false)
    const handleOnChange = (val) => {
        let index = selectedOptions.indexOf(val)
        let temp = []
        if(index==-1) {
          temp = [...selectedOptions, val]
        }
        else{
          temp = [...selectedOptions]
          temp.splice(index, 1)
        }
        setSelectedOptions(temp)
    }
    const toggleDropDown = () => setShowdropdown(d => !d);

    const renderDropdown = () => {
        return (
            <AnDropDown
            style={{
                position: "absolute",
                right: 0,
                padding: "14px",
                background: "white",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                borderRadius: 4,
                width: "100%",
                zIndex: 99,
                ...innerStyle
            }}
            toggleDropDown={toggleDropDown}
        >
            {
                options.map((option) => {
                  let isChecked = selectedOptions.includes(option.key)
                return (
                    <AnInputCheckBox label={option.label} isChecked={isChecked} style={{marginBottom: 14}}>
                        <input type="checkbox" checked={isChecked} onChange={() => handleOnChange(option.key)}></input>
                    </AnInputCheckBox>
                )})
            }
    </AnDropDown>
        )
    }
    return (
        <>
            <div style={props.style}>
                <div className="text_body_light">
                    {label}
                </div>
                <div className={inputClass} style={{position: 'relative', paddingTop: 6, paddingBottom: 12}}>
                    <div className={'flex_container_space_between text_body_light ' + (selectedOptions.length > 0 ? 'selectedOptions' : 'defaultPlaceholder')}
                        style={{ width: '100%', padding: '8px 12px', cursor: 'pointer', position: 'relative', height: 40, border: 'none', whiteSpace: 'nowrap', background: '#F8F8F8' }}
                        onClick={toggleDropDown}
                    >
                        <span className="truncate">{selectedOptions.map(option => option).join(", ") || "Select tags"}</span>
                        <img src={darkDropdown} style={{ marginLeft: 4 }} />
                    </div>
                    {showdropdown && renderDropdown()}
                </div>
            </div>
        </>
    )
}

export default AnCheckBoxDropdown