import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Cookies from 'universal-cookie';
import { apiAgent } from '../../../axiosConfig';
import { COOKIE_SETTINGS } from '../../../config/cookiesSettings';
import { Button } from '../../cta_utils/AnButton';
import { AnInputBox } from '../../input_utils/AnInput';

export const InviteJoinTeam = (props) => {
    const [invitationId, setInvitationId] = useState(null);
    const [inviteInfo, setInviteInfo] = useState({});
    const [teamId, setTeamId] = useState({});
    const [signupForm, setSignupForm] = useState({
        name: '',
        email: '',
        position: '',
        password: ''
    })
    const [errors, setErrors] = useState({})
    const [isSigningUp, setIsSigningUp] = useState(false);
    const history = useHistory();

    useEffect(() => {
        let paramsString = window.location.search;
        let searchParams = new URLSearchParams(paramsString);
        const invitation = searchParams.get('invitation')
        if(!invitation) history.push("/")
        else setInvitationId(invitation)
        const team_id = searchParams.get('team_id')
        setTeamId(team_id);
    }, [])
    useEffect(() => {
        if(invitationId && teamId){
            apiAgent({
                method: 'get',
                url: '/fetch_join_info',
                params: {id: invitationId, team_id: teamId}
            }).then( res => {
                setInviteInfo(res.data);
                if(res.data.email)
                    setSignupForm({...signupForm, email: res.data.email, name: res.data.name, position: res.data.position})
            }).catch(err => {
            })
        }
    }, [invitationId, teamId])

    const handleInputChange = (e) => {
        setSignupForm({...signupForm, [e.target.name]: e.target.value})
        if(errors[e.target.name]) setErrors({...errors, [e.target.name]: ''})
    }
    const validateData = () => {
        let errors = {};
        if(!signupForm.email) errors.email = "Please enter an email address";
        if(!signupForm.name) errors.name = "Please enter your name";
        if(!signupForm.password) errors.password = "Please enter a password";
        return errors;
    }
    const handleFormSubmit = () => {
        let errors = validateData();
        setErrors(errors);
        if(Object.keys(errors).length) return;
        setIsSigningUp(true)
        apiAgent({
            method: 'post',
            url: '/join_invite',
            data: {
                ...signupForm,
                team_id: inviteInfo.id
            }
        }).then(res => {
            setIsSigningUp(false);
            const cookies = new Cookies()
            cookies.set('api_key', res.data.api_key, {path: '/', maxAge: COOKIE_SETTINGS['AUTH_DURATION']})
            if(res.data.teams && res.data.teams.id)
                cookies.set('team_id', res.data.teams.id, {path: '/', maxAge: COOKIE_SETTINGS['AUTH_DURATION']})
            window.location = "/feed"
        }).catch(e => {
            setIsSigningUp(false);
        })
    }


    return (
        <div className="invite_signup_container">
            <div style={{textAlign: 'center'}}>
                <span className='text_title_big'>Joining <span style={{color: '#00C4B5'}}>{inviteInfo.team_name}</span> team</span>
                {
                    inviteInfo.email && inviteInfo.requester_name && (
                        <div className='text_body_light' style={{marginTop: 8}}>You’ve accepted {inviteInfo.requester_name}’s invite to AlmaConnect News</div>)
                }
            </div>
            <div className='invite_signup_form_container'>
                <AnInputBox label="Your Name" style={{marginTop: 8}} error={errors.name}>
                    <input type="text" name="name" value={signupForm.name} placeholder='Enter name'
                           onChange={handleInputChange} disabled/>
                </AnInputBox>
                <AnInputBox label="Email" error={errors.email}>
                    <input type="text" name="email" value={signupForm.email} placeholder='Enter email'
                           onChange={handleInputChange} disabled/>
                </AnInputBox>
                <AnInputBox label="Your Title/Position" style={{marginTop: 8}}>
                    <input type="text" name="position" value={signupForm.position} placeholder='Associate Dean'
                           onChange={handleInputChange}/>
                </AnInputBox>
                <AnInputBox label="Choose a password" style={{marginTop: 8}} error={errors.password}>
                    <input type="password" name="password" placeholder='Enter password'
                           onChange={handleInputChange}/>
                </AnInputBox>
                <Button buttonSize='Big' buttonStyle={{width: "100%", marginTop: 24}}
                        isLoading={isSigningUp} onClick={handleFormSubmit}>
                    Sign up
                </Button>
                <div className="text_body_light privacy_policy_container">
                    By continuing, you are agreeing to AlmaConnect News <a href="https://www.almaconnect.com/terms-of-use" className='text_link'>Terms Of Use</a> and&nbsp;
                    <a href="https://www.almaconnect.com/privacy-policy" className='text_link'>Privacy Policy</a>.
                </div>
            </div>
        </div>
    )
}
