import { useMemo, useState } from "react"
import  { MONTHS, getMonthLabel } from "../utils/MonthsUtils"
import { Button } from "./AnButton"
import AnDropDown from "./AnDropDown"
import { AnInputBox } from "../input_utils/AnInput"
import { currentMonth, currentYear, generateYears } from "../utils/dateUtils"

export const DateFilter = ({
    selectedDate,
    setSelectedDate,
    handleOnClose,
    handleOnFilterSave,
    curr_rel_id,
    ...props
}) => {
    const {fromMonth, fromYear, toMonth, toYear} = selectedDate
    const get_months = (year) => {
        return (year === currentYear) ? MONTHS.filter(month => month.value <= currentMonth) : MONTHS
    }
    return (
      <AnDropDown style={{position: 'absolute',  right: 0, padding: '16px', background: 'white', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', borderRadius: 4, width: '240px', zIndex: 999}}
          toggleDropDown={() => handleOnClose()}>
            <div className="default_flex_container">
            <AnInputBox label="From" style={{maxWidth: "86px", marginRight: 8}}>
                <select placeholder="Select year" value={fromYear} onChange={(e) => setSelectedDate({...selectedDate, fromYear: e.target.value})}>
                    <option key='no_value' value={""}>Year</option>
                    {
                        generateYears(50).map((year, index) => {
                            return (
                            <option key={index} value={year}>
                                {year}
                            </option>
                        )})
                    }
                </select>
            </AnInputBox>
            <AnInputBox label="" style={{maxWidth: "120px", marginTop: 20}}>
                <select placeholder="Select month" value={fromMonth} onChange={(e) => setSelectedDate({...selectedDate, fromMonth: e.target.value})}>
                    <option key='no_value' value={""}>Month</option>
                    {
                        get_months(fromYear).map((month, index) => {
                            return (
                            <option key={index} value={month.value}>
                                {getMonthLabel(month.value, "short")}
                            </option>
                        )})
                    }   
                </select>
            </AnInputBox>
            </div>
            <div className="default_flex_container" style={{marginTop: 8}}>
            <AnInputBox label="To" style={{maxWidth: "86px", marginRight: 8}}>
                <select placeholder="Select year" value={toYear} onChange={(e) => setSelectedDate({...selectedDate, toYear: e.target.value})}>
                    <option key='no_value' value={""}>Year</option>
                    {
                        generateYears(currentYear - fromYear).map((year, index) => {
                            return (
                            <option key={index} value={year}>
                                {year}
                            </option>
                        )})
                    }
                </select>
            </AnInputBox>
            <AnInputBox label="" style={{maxWidth: "120px", marginTop: 20}}>
                <select placeholder="Select month" value={toMonth} onChange={(e) => setSelectedDate({...selectedDate, toMonth: e.target.value})}>
                    <option key='no_value' value={""}>Month</option>
                    {
                        get_months(toYear).map((month, index) => {
                            return (
                            <option key={index} value={month.value}>
                                {getMonthLabel(month.value, "short")}
                            </option>
                        )})
                    }   
                </select>
            </AnInputBox>
            </div>
            <div style={{marginTop: 8}}>
            <Button buttonSize="Medium" buttonStyle={{width: 'max-content'}} innerButtonStye={{padding: '8px 20px'}}
                    onClick={() => handleOnFilterSave(curr_rel_id)}>Save</Button>
            </div>
      </AnDropDown>
    )
  }