import { useState, useEffect} from "react";
import { apiAgent } from "../../../axiosConfig.js";
import TableHeads from "../../visualizers/tabular/TableHeads";
import TableRows from "../../visualizers/tabular/TableRows";
const TeamVisitActivity = () => {
    const [loginStats, setLoginStats] = useState([]);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        fetchLoginStats();
    }, [])

    const fetchLoginStats = (page) => {
        setIsFetching(true)
        apiAgent({
            method: "get",
            url: `/team/visit_activity_log`,
            params: {
            },
        }).then(
            (res) => {
                setLoginStats(res.data.activity_log);
                setIsFetching(false)
            },
            (error) => {
            }
        );
    };

    const renderVisitActivity = () => {
        return(
            <>
                <div className="text_title" style={{border: "1px solid #E5E5E5", borderRadius: "4px", padding: "16px 24px"}}>Login Activity</div>
                <table className="data_table" style={{textAlign: "left"}}>
                    <TableHeads typoGraphyClass="text_body_big_light" headerClass="table_header_row">
                        <th style={{width: "33%"}}>Member Name</th>
                        <th style={{width: "33%"}}>Last Visited</th>
                        <th style={{width: "33%"}}>Joined on</th>
                    </TableHeads>
                    <tbody>
                    {
                        loginStats && loginStats.map(member => (
                            <TableRows key={member.id} rowClass="table_content_row text_body">
                                <td>{member.name}</td>
                                <td>{member.last_visited}</td>
                                <td>{member.joined}</td>
                            </TableRows>
                        ))
                    }
                    </tbody>
                </table>
            </>
        )
    }

    return (
        <div>
            <div className="text_title_big" style={{marginBottom: "16px"}}>
                Activity
            </div>
            {renderVisitActivity()}
        </div>
    );
};

export default TeamVisitActivity;