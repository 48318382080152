import React from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'

import ContinueArrow from "../../assets/ContinueGreenArrow.svg";
import ContinueArrowGrey from "../../assets/ContinueArrow.svg";
import ConditionalRender from '../../ConditionalRender';

const GoBack = ({
        text="Go back",
        arrow=true,
        ...props
    }) => {
        const history = useHistory();
        const location = useLocation();
        const navigateBack = () => {
            if(location.state) history.push(location.state.from, {filtersState: location.state.filtersState});
            else history.goBack();
        }
        return (
            <div className={props.className} style={{cursor: "pointer", ...props.style}}
                onClick={navigateBack}
            >
                <ConditionalRender if={arrow}>
                    <img src={(props.arrow_color == "green")? ContinueArrow : ContinueArrowGrey} style={{transform: "rotate(180deg)", marginRight: 8}}></img>
                </ConditionalRender>
                <span>{text}</span>
            </div>
        )
}

export default GoBack