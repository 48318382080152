import { useState, useEffect, Fragment } from "react";
import { apiAgent } from "../../../axiosConfig.js";
import Pagination from "../../../Pagination";
import { useHistory } from "react-router-dom";
import { switchCurrentTeam } from "../../../actions/teamSliceActions/actions.js";
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom'
import { Button } from "../../cta_utils/AnButton.js";
import TableHeads from "../../visualizers/tabular/TableHeads.jsx";
import TableRows from "../../visualizers/tabular/TableRows.jsx";
import { LoaderComponent } from "../../utils/LoaderComponent.js";
import ConditionalRender from "../../../ConditionalRender.js";
import { useSelector } from "react-redux";
import AnPopup from "../../cta_utils/AnPopup.js";
import FileUpload from "../../../FileUpload.js";
import SuccessMessagePopup from "../../cta_utils/SuccessMessagePopup.js";
import AnDropdownFilter from "../../cta_utils/AnDropdownfilter.js";
import DownloadImage from "../../../assets/download.png";
import { FlashMessages } from '../../cta_utils/FlashMessages';
import useCheckUserAccess from "../../custom_hooks/useCheckUserAccess.js";

const plans = [{label: "All", key: "all"}, {label: "Premium", key: "premium"}, {label: "Verified", key: "verified"}, {label: "Unverified", key: "unverified"}, {label: "Outbound", key: "outbound"}];
const regular_team_import_sample = "https://d3sr7cc30ek8f4.cloudfront.net/raw/prod/6566d1b3f7f0fb0010d22ebe/Sample-file-to-import-teams.csv"
const outbound_team_import_sample = "https://d3sr7cc30ek8f4.cloudfront.net/raw/prod/663249ebb96d0100107059b4/SampleOutboundImport-Teams-import-ou.csv"
const team_update = "https://d3sr7cc30ek8f4.cloudfront.net/raw/prod/65bcc03edc675f00105f238b/Team-update1.csv"
const TeamsList = () => {
    let history = useHistory();
    const current_user = useSelector(state => state.current_user.user)
    const [teams, setTeams] = useState([]);
    const [plan, setPlan] = useState("all");
    const [isFetching, setIsFetching] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [popupsList, setPopupsList] = useState({});
    const [searchTerm, setSearchTerm] = useState("");
    const _dispatch = useDispatch();
    const [successMessages, setSuccessMessages] = useState({
        message: "",
        duration: 0,
        from: ""
    });
    const checkUserAccess = useCheckUserAccess();

    useEffect(() => {
        fetchTeams(1);
    }, [plan]);

    const handleOnChange = (e) => {
        setSearchTerm(e.target.value);
    };
    const handleOnKeyDown = (e) => {
        if (e.key == "Enter") {
            fetchTeams(1);
        }
    };
    const forceTeamSwitch = (team) => {
        _dispatch(switchCurrentTeam(team));
        window.location = "/feed";
    };
    const fetchTeams = (page) => {
        setIsFetching(true);
        apiAgent({
            method: "get",
            url: `/teams?q=${searchTerm}`,
            params: {
                page: page,
                plan: plan
            },
        }).then(
            (res) => {
                setTeams(res.data.data);
                setTotalPages(res.data.total_pages);
                setTotal(res.data.total);
                setIsFetching(false);
                setCurrentPage(page);
            },
            (error) => {
                setIsFetching(false);
            }
        );
    };

    const discardTeam = (team) => {
        apiAgent({
            method: "delete",
            url: "/teams",
            data: {
                id: team.id,
            },
        })
            .then((response) => {
                setIsLoading(false);
                history.push("/admin/teams");
            })
            .catch((error) => {
                console.log(`Request failed bcz of this error`, error);
                setIsLoading(false);
            });
    };

    const verifyTeam = (team) => {
        if (!window.confirm("Are you sure you want to verify this team?"))
            return;
        setIsLoading(true);
        apiAgent({
            method: "post",
            url: "/team/verify",
            data: {
                id: team.id,
            },
        })
            .then((response) => {
                setIsLoading(false);
                history.push("/admin/teams");
            })
            .catch((error) => {
                console.log(`Request failed `, error);
                setIsLoading(false);
            });
    }

    const handlePopupClose = (newSet={}) => setPopupsList(newSet);

    const handleExportTeams = () => {
        apiAgent({
            method: "post",
            url: "/teams/export_teams_stats",
        })
            .then((response) => {
                setSuccessMessages({
                    message: "You will receive an email when the export is ready to download",
                    duration: 3000,
                    from: "Data Exports"
                })
            })
    }
    const dataExportedMessage = (message) => {
        setSuccessMessages({...successMessages, message: message})
    }

    const renderSearchBar = () => {
        return (
            <div className="default_flex_container" style={{maxWidth: "700px", marginBottom: "24px"}}>
                <div className="an_search_box" style={{flexBasis: "100%"}}>
                    <div style={{ position: "relative" }}>
                        <input
                            type="text"
                            value={searchTerm}
                            placeholder="Search Team"
                            onChange={handleOnChange}
                            onKeyDown={handleOnKeyDown}
                        />
                        <span className="an_search_lens">
                            <img src="/search.svg" />
                        </span>
                    </div>
                </div>
                <Button buttonStyle={{marginLeft: "12px"}}
                    innerButtonStye={{padding: "9px 44px"}}
                    onClick={() => fetchTeams(1)}                    
                >
                    Search
                </Button>
            </div>

        );
    };
    const renderSuccessMessage = () => {
        return (
            <SuccessMessagePopup maxWidth={440}>
                <div className='text_body_light'>
                    We are processing your request. Please check back later.
                </div>
            </SuccessMessagePopup>
        )
    }

    const renderImportTeamsPopup = () => {
        return(
            <AnPopup name="importTeamsPopup" handleOnClosePopup={handlePopupClose} maxWidth={512}>
                <div className="text_title">Import Teams</div>                    
                <div
                    className="line_seperator_ex_light_grey"
                    style={{ margin: "12px 0px 14px" }}
                ></div>
                <div className="text_subtitle">
                    Procedure for importing teams through csv:
                </div>
                <ol className="text_body_light" style={{paddingLeft: 20, marginTop: 8}}>
                  <li>
                    Download the respective sample file - <a href={regular_team_import_sample} className="text_link hover_under_line">Regular team</a>, <a href={outbound_team_import_sample} className="text_link hover_under_line">Outbound team</a> 
                  </li>
                  <li>
                    Fill up the details
                  </li>
                  <li>
                    Save as CSV & Upload.
                  </li>
                </ol>
                <div className="text_body_light">
                    Note: Fields present in this <a href={team_update} className="text_link hover_under_line">sample file</a> can be updated using Team ID + Team Name combination key.  
                </div>
                <FileUpload
                    submitUrl="/team/import_teams"
                    submitSuccessMessage={null}
                    onSubmitFinishCallback={() => {handlePopupClose({renderSuccessMessage: true});}}
                    submitButtonText="Import"
                    uploaderSecondaryText={{displayable: false}}
                    style={{padding: "21px"}}
                />
            </AnPopup>
        )
    }
    const renderImportTeamMembersPopup = () => {
        return(
            <AnPopup name="importTeamMembersPopup" handleOnClosePopup={handlePopupClose}>
                <div className="text_title">Import Team Members</div>                    
                <div
                    className="line_seperator_ex_light_grey"
                    style={{ margin: "12px 0px 14px" }}
                ></div>
                <div className="text_subtitle">
                    Procedure for importing team members through csv:
                </div>
                <ol className="text_body_light" style={{paddingLeft: 20, marginTop: 8}}>
                  <li>
                    Download this sample file - <a href="https://d3sr7cc30ek8f4.cloudfront.net/raw/prod/636e5900f6e2b5001021e534/SampleTeammembersfile-Sheet1.csv" className="text_link hover_under_line">Click here.</a>
                  </li>
                  <li>
                    Fill up the details
                  </li>
                  <li>
                    Save as CSV & Upload.
                  </li>
                </ol>
                <FileUpload
                    submitUrl="/team/import_team_members"
                    submitSuccessMessage={null}
                    onSubmitFinishCallback={() => {handlePopupClose({renderSuccessMessage: true});}}
                    submitButtonText="Import"
                    uploaderSecondaryText={{displayable: false}}
                    style={{padding: "21px"}}
                />
            </AnPopup>
        )
    }
    const renderTableContent = () => (
        <table className="data_table" style={{textAlign: "left"}}>
            <TableHeads typoGraphyClass="text_body_big_light" headerClass="table_header_row">
                <th style={{width: "45%"}}>Name</th>
                <th style={{width: "15%"}}>Plan</th>
                <th style={{width: "10%"}}>Members</th>
                <th style={{width: "10%"}}>Prospects</th>
                <th style={{width: "10%"}}>Created&nbsp;At</th>
                <th style={{width: "10%"}}>Switch</th>
            </TableHeads>
            <tbody>
                {
                    teams.map(team => (
                        <TableRows key={team.id} rowClass="table_content_row text_body">
                            <td style={{wordBreak: 'break-word'}}>{team.name}</td>
                            <td>{team.premium ? "Premium" : team.verified ? "Verified" : "Unverified"}</td>
                            <td>{team.members_count}</td>
                            <td>{team.prospects_count}</td>
                            <td>{team.created_at}</td>
                            <td>
                                <div
                                    className="text_link"
                                    style={{ width: "48px" }}
                                    onClick={() => forceTeamSwitch(team)}
                                >
                                    Switch
                                </div>
                            </td>
                        </TableRows>
                    ))
                }
            </tbody>
        </table>
    )

    return (
        <div>
            <div className="aggregate_container" style={{marginTop: "48px", maxWidth: "1120px"}}>
                <div>
                    <div className="text_title_big" style={{marginBottom: "10px", float: 'left'}}>Teams</div>
                    <ConditionalRender if={checkUserAccess('team_creating')}>
                      <Button buttonSize="Medium" buttonStyle={{marginLeft: "12px", float: 'right'}}
                              innerButtonStye={{padding: "9px 16px"}}
                              dropOptions={[{
                                  checkUserAccess: checkUserAccess('team_creating'),
                                  label: "Create team",
                                  onClick: () => history.push("/admin/create_team")
                              },
                              {
                                  checkUserAccess: checkUserAccess('team_importing'),
                                  label: "Import teams",
                                  onClick: () => setPopupsList({importTeamsPopup: true})
                              },
                              {
                                checkUserAccess: checkUserAccess('team_member_importing'),
                                label: "Import team members",
                                onClick: () => setPopupsList({importTeamMembersPopup: true})
                              }
                          ]}
                      >
                          Create Team
                      </Button>
                    </ConditionalRender>
                    <div style={{clear: 'both'}}></div>
                </div>
                <AnDropdownFilter
                    option = {plan}
                    setOption = {setPlan}
                    options = {plans}
                    style = {{position: 'relative', top: 11, float: 'right'}}
                >
                </AnDropdownFilter>
                {renderSearchBar()}
                <div className="default_flex_container" style={{margin: "24px auto 14px"}}>
                    <div className="text_subtitle_big">{total} Teams</div>
                    <div style={{flex: 1}}>
                        <Pagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            onPageChange={fetchTeams}
                        />
                    </div>
                    <ConditionalRender if={checkUserAccess('team_downloading')}>
                    <div onClick={handleExportTeams} style={{position: 'relative', display: 'flex', alignItems: 'center', cursor: "pointer"}}>
                        <img src={DownloadImage} style={{maxWidth: 15, maxHeight: 15}}></img>
                        <span className='text_link' style={{marginLeft: 6 }}>Download</span>
                    </div>
                    </ConditionalRender>
                </div>
                {
                    !isFetching ? 
                        renderTableContent() :
                        (<LoaderComponent isLoading={true}  loaderStyle={{width: 32, margin: '36px auto', display: 'block'}}/>)

                }
            </div>
            {   popupsList.importTeamsPopup && renderImportTeamsPopup()  }
            {   popupsList.importTeamMembersPopup && renderImportTeamMembersPopup()  }
            {   popupsList.renderSuccessMessage && renderSuccessMessage()  }
            {
                successMessages.message && (<FlashMessages messageTheme='success' duration={successMessages.duration} closeCallback={dataExportedMessage}>{successMessages.message}</FlashMessages>)
            }
        </div>
    );
};

export default TeamsList;
