import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { apiAgent } from '../../../axiosConfig';
import { Button } from '../../cta_utils/AnButton';
import { FlashMessages } from '../../cta_utils/FlashMessages';
import { AnInputBox } from '../../input_utils/AnInput';

const ResetPassword = (props) => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [token, setToken] = useState("");
    const [submissionState, setSubmissionState] = useState({
        isLoading: false,
        errors: {},
        successMessage: null,
    });
    const history = useHistory();

    useEffect(() => {
        let paramsString = window.location.search;
        let searchParams = new URLSearchParams(paramsString);
        const reset_password_token = searchParams.get('token')
        setToken(reset_password_token);
        if(!reset_password_token) history.push("/");
    }, [])

    const onFormSubmit = () => {
        let errors = {};
        if (!password) errors["password"] = "Password can't be blank";
        if(password !== confirmPassword) errors["confirmPassword"] = "Confirmation password should be same as new password";
        if (Object.keys(errors).length > 0) {
            setSubmissionState({
                ...submissionState,
                errors: { ...errors },
                successMessage: null,
            });
            return;
        } else {
            setSubmissionState({
                ...submissionState,
                errors: { ...errors },
                isLoading: true,
                successMessage: null,
            });
        }
        apiAgent({
            method: "post",
            url: "/update_reset_password",
            data: {
                password,
                reset_password_token: token,
            },
        })
            .then((res) => {
                if(res.status == 400){
                    setSubmissionState({
                        ...submissionState,
                        isLoading: false,
                        errors: { submitError: "Invalid token" },
                    });
                }else{
                    props.onLoginSuccess(res.data)
                }
            })
            .catch((err) => {
                setSubmissionState({
                    ...submissionState,
                    isLoading: false,
                    errors: { submitError: "Something went wrong. Can't reset password at the moment" },
                });
            });
    };
    return (
        <>
            <div className="login_component_container">
                <div className="text_title_big" style={{ textAlign: "center" }}>
                    Reset password
                </div>
                <div style={{ marginTop: "24px" }}>
                    <AnInputBox
                        label={"Enter password"}
                        error={submissionState.errors["password"]}
                    >
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter new password"
                        />
                    </AnInputBox>
                </div>
                <div style={{ marginTop: "24px" }}>
                    <AnInputBox
                        label={"Re-enter password"}
                        error={submissionState.errors["confirmPassword"]}
                    >
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            placeholder="Re-enter password"
                        />
                    </AnInputBox>
                </div>
                <div style={{ marginTop: "16px" }}>
                    <Button
                        buttonSize="Big"
                        onClick={onFormSubmit}
                        isLoading={submissionState.isLoading}
                        error={submissionState.errors.submitError}
                        buttonStyle={{ width: "100%" }}
                    >
                        Reset password
                    </Button>
                </div>
                <div
                    className="text_body_light"
                    style={{ marginTop: "16px", textAlign: "center" }}
                >
                    Back to&nbsp;
                    <a href="/" className="text_link hover_under_line">
                        Login
                    </a>
                </div>
            </div>
            {submissionState.successMessage && (
                <FlashMessages
                    messageTheme="success"
                    closeCallback={() =>
                        setSubmissionState({
                            ...submissionState,
                            successMessage: null,
                        })
                    }
                >
                    {submissionState.successMessage}
                </FlashMessages>
            )}
        </>
    );

}

export default ResetPassword
