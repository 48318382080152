import { useState } from "react"
import { TextHighlighter } from "./textHighlighter"

const Seemore = (props) => {
    const [showall, setShowall]=useState(false);
    let toMatch = Array.isArray(props.person_name) ? props.person_name : [props.person_name];
    if(props.searchTerm)
    toMatch = {...toMatch, toMatchText: props.searchTerm, highlightCustomStyle: "background: rgba(0, 196, 181, 0.35); text-transform: capitalize"}
    
    const showDescription = (contentString) => {
        return (
                <TextHighlighter
                contentString={
                    contentString
                }
                toMatch={toMatch}
                typography={{ className: "text_body_light see_more" }}
            />
        )
    }
    const ShowSeeMore = () => {
        if(!showall)
        return (
            <div className="see_more">
                {showDescription(props.short_description)}
                <div 
                    className='text_subtitle_light' 
                    style={{textDecoration: 'underline', cursor: 'pointer'}}
                    onClick={(e) => {
                        setShowall(!showall);
                        e.preventDefault()
                        e.stopPropagation();
                        }}
                    >   
                        Read more
                </div>
            </div>
        )
        else
        return (
            <div className="see_more">
                {showDescription(props.description)}&nbsp;
                <div 
                    className='text_subtitle_light' 
                    style={{textDecoration: 'underline', cursor: "pointer"}} 
                    onClick={(e) => {
                        setShowall(!showall);
                        e.preventDefault();
                        e.stopPropagation()
                    }}>
                        Read less
                </div>
            </div>
        )
    }
    return (
        <>
            {props.description && props.description.length>170 ? ShowSeeMore() : showDescription(props.short_description)}
        </>
    )
}

export default Seemore
