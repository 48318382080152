import React from 'react'

export const TableHeads = ({
    headerClass,
    typoGraphyClass,
    customStyles={},
    typoGraphyStyles={},
    ...props
}) => {
  return (
    <thead>
        <tr className={`${headerClass} ${typoGraphyClass}`} style={{...customStyles, ...typoGraphyStyles}}>
            {props.children}
        </tr>
    </thead>
  )
}

export default TableHeads