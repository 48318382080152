import { Link } from "react-router-dom/cjs/react-router-dom.min"

export const Relationships = ({containerStyle, spouse_relationships=[], other_relationships=[]}) => {
    return (
        <div style={{containerStyle}}>
            <div style={{ display: 'flex' }}>
                <div className="text_caption_light" style={{ display: 'inline', paddingRight: '6px', paddingTop: '2px' }}>Spouse: </div>
                <div>{spouse_relationships && spouse_relationships.length > 0 && spouse_relationships.map((item, index) => {
                    return <Link key={index} className="text_link" style={{ textDecoration: "none" }} to={"/prospects/" + item.id} target="_blank">{index >= 1 && ",  "}{item.name}</Link>
                })}</div>
            </div>
            <div style={{ display: 'flex' }}>
                <div className="text_caption_light" style={{ display: 'inline', paddingRight: '14px', paddingTop: '2px' }}>Other: </div>
                <div>{other_relationships && other_relationships.length > 0 && other_relationships.map((item, index) => {
                    return <Link key={index} className="text_link" style={{ textDecoration: "none" }} to={"/prospects/" + item.id} target="_blank">{index >= 1 && ",  "}{item.name}</Link>
                })}</div>
            </div>
        </div>
    )
}