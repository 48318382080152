const PROFILE_INFOS = [
    {label: 'Unique Id', key: 'external_id', editable: false, displayable: false},
    {label: 'CRM Id', key: 'crm_primary_id', editable: false, displayable: false},
    {label: 'Name', key: 'name', editable: true, displayable: true},
    {label: 'Company', key: 'company', editable: true, displayable: true},
    {label: 'Full Name', key: 'full_name', editable: true, displayable: true},
    {label: 'Spouse Name', key: 'spouse_name', editable: true, displayable: true},
    {label: 'Other Companies', key: 'custom_companies', editable: true, displayable: true, inputType: 'text_area', placeholder: 'Enter company names line seperated'},
    {label: 'Education', key: 'college', editable: true, displayable: true},
    {label: 'Course', key: 'course', editable: true, displayable: true},
    {label: 'Branch', key: 'branch', editable: true, displayable: true},
    {label: 'Graduation Year', key: 'graduation_year', editable: true, displayable: true},
    {label: 'Past Education', key: 'past_educations', editable: true, displayable: true},
    {label: 'City', key: 'city', editable: true, displayable: true},
    {label: 'State', key: 'state', editable: true, displayable: true},
    {label: 'Country', key: 'country', editable: true, displayable: true},
    {label: 'Location', key: 'location', editable: true, displayable: true},
    {label: 'Title', key: 'title', editable: true, displayable: true},
    {label: 'Linkedin URL', key: 'linkedin_url', editable: true, displayable: true},
    {label: 'Alias Names', key: 'alias_names', editable: true, displayable: true},
    {label: 'Relationships', key: 'relationships', editable: false, displayable: false},
]
export {PROFILE_INFOS}