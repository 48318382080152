import React, { useState } from "react";
import { apiAgent } from "../../../axiosConfig";
import { Button } from "../../cta_utils/AnButton";
import { FlashMessages } from "../../cta_utils/FlashMessages";
import { AnInputBox } from "../../input_utils/AnInput";
import EmailConfirmation from "../../../assets/emailConfirmation.png";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [submissionState, setSubmissionState] = useState({
        isLoading: false,
        errors: {},
        successMessage: null,
    });
    const onFormSubmit = () => {
        let errors = {};
        if (!email) errors["email"] = "Enter a valid email address";
        if (Object.keys(errors).length > 0) {
            setSubmissionState({
                ...submissionState,
                errors: { ...errors },
                successMessage: null,
            });
            return;
        } else {
            setSubmissionState({
                ...submissionState,
                errors: { ...errors },
                isLoading: true,
                successMessage: null,
            });
        }
        apiAgent({
            method: "post",
            url: "/send_reset_password",
            data: {
                email,
            },
        })
            .then((res) => {
                if(res.status == 400){
                    setSubmissionState({
                        ...submissionState,
                        isLoading: false,
                        errors: { submitError: "User not found for this email id" },
                    });
                }else{
                    setSubmissionState({
                        ...submissionState,
                        isLoading: false,
                        successMessage:
                            "Reset password mail has been sent to this email id",
                    });
                }
            })
            .catch((err) => {
                setSubmissionState({
                    ...submissionState,
                    isLoading: false,
                    // errors: { submitError: "Something went wrong!. Unable to send password reset email at the moment. Please try after some time" },
                    errors: { submitError: "User not found" },
                });
            });
    };

    if(submissionState.successMessage){
        return(
            <>
                <div style={{margin: '80px auto', maxWidth: '520px', textAlign: 'center'}}>
                    <img src={EmailConfirmation} style={{maxWidth: '108px', margin: '0px auto', display: 'block'}} />
                    <div style={{marginTop: '14px'}} className='text_headline3'>
                        Please check your email
                    </div>
                    <div style={{marginTop: '8px'}} className="text_body_big">
                        Password reset link was sent successfully. 
                    </div>
                    <div style={{marginTop: '4px'}} className="text_body_light">
                        You’ll receive this email within 5 minutes. Be sure to check your spam folder, too.
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <div className="login_component_container">
                <div className="text_title_big" style={{ textAlign: "center" }}>
                    Forgot password?
                </div>
                <div style={{ marginTop: "24px" }}>
                    <AnInputBox
                        label={"Enter your email"}
                        error={submissionState.errors["email"]}
                    >
                        <input
                            type="text"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="johndoe@domain.com"
                        />
                    </AnInputBox>
                </div>
                <div style={{ marginTop: "16px" }}>
                    <Button
                        buttonSize="Big"
                        onClick={onFormSubmit}
                        isLoading={submissionState.isLoading}
                        error={submissionState.errors.submitError}
                        buttonStyle={{ width: "100%" }}
                    >
                        Request password reset
                    </Button>
                </div>
                <div
                    className="text_body_light"
                    style={{ marginTop: "16px", textAlign: "center" }}
                >
                    Back to&nbsp;
                    <a href="/" className="text_link hover_under_line">
                        Login
                    </a>
                </div>
            </div>
            {/* {submissionState.successMessage && (
                <FlashMessages
                    messageTheme="success"
                    closeCallback={() =>
                        setSubmissionState({
                            ...submissionState,
                            successMessage: null,
                        })
                    }
                >
                    {submissionState.successMessage}
                </FlashMessages>
            )} */}
        </>
    );
};

export default ForgotPassword;
