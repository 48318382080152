import React from 'react'
import NewsfeedImage from "../../../assets/features/newsfeed_image.png"
import AddProspects from "../../../assets/features/add_prospects.png"
import Crm from "../../../assets/features/crm.png"
import SocialMedia from "../../../assets/features/socialmedia.png"
import Comment from "../../../assets/features/comment.png"
import Notification from "../../../assets/features/notification.png"
import TeamNotification from "../../../assets/features/team_notification.png"
import ExportData from "../../../assets/features/export_data.png"
import AssignMember from "../../../assets/features/assign_member.png"
import ProspectProfile from "../../../assets/features/prospect_profile.png"
import Filters from "../../../assets/features/filters.png"
import SearchNews from "../../../assets/features/search_news.png"
import InviteMember from "../../../assets/features/invite_member.png"
import ManageProspects from "../../../assets/features/manage_prospects.png"
import { Button } from '../../cta_utils/AnButton'

import { isWidthDown } from '../../utils/designUtils'
import useWindowSize from '../../custom_hooks/useWindowSize'


const Features = () => {

    const windowDimensions = useWindowSize();

    const section2FP = () => {
        return(
            <section className='section_2'>
                <div className='content_container' style={{backgroundColor: "#FFFFFF"}}>
                    <div className='text_headline3' style={{marginBottom: "24px", marginTop: 30, textAlign: "center"}}>
                        Software Features
                    </div>
                    <div className='image_box default_flex_container' style={{marginTop: 40}}>
                        <div className='single_box'>
                            <img src={NewsfeedImage} style={{marginBottom: "20px"}}/>
                            <div className="text_title_big" style={{marginBottom: "5px"}}>NewsFeed</div>
                            <div className='text_body_big_light'>
                                Browse through the news articles on a constantly updating persoanlized newsfeed
                            </div>
                        </div>
                        <div className='single_box'>
                            <img src={AddProspects} style={{marginBottom: "20px"}}/>
                            <div className="text_title_big" style={{marginBottom: "5px"}}>Add Prospects</div>
                            <div className='text_body_big_light'>
                                Add prospects either individually or in bulk by importing them from a CSV file
                            </div>
                        </div>
                    </div>

                    <div className='image_box default_flex_container'>
                        <div className='single_box'>
                            <img src={AssignMember} style={{marginBottom: "20px"}}/>
                            <div className="text_title_big" style={{marginBottom: "5px"}}>Assign Team members</div>
                            <div className='text_body_big_light'>
                                In order to personalize the newsfeed, assign team members to prospects
                            </div>
                        </div>
                        <div className='single_box'>
                            <img src={ProspectProfile} style={{marginBottom: "20px"}}/>
                            <div className="text_title_big" style={{marginBottom: "5px"}}>Prospect profile</div>
                            <div className='text_body_big_light'>
                                View/Update prospect profile information to get accurate news results
                            </div>
                        </div>
                    </div>

                    <div className='image_box default_flex_container'>
                        <div className='single_box'>
                            <img src={Filters} style={{marginBottom: "20px"}}/>
                            <div className="text_title_big" style={{marginBottom: "5px"}}>Filters</div>
                            <div className='text_body_big_light'>
                                Narrow down your search for news by applying the Smart Filters on the Newsfeed
                            </div>
                        </div>
                        <div className='single_box'>
                            <img src={SearchNews} style={{marginBottom: "20px"}}/>
                            <div className="text_title_big" style={{marginBottom: "5px"}}>Search news sources</div>
                            <div className='text_body_big_light'>
                                Search articles published by a specific news source 
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    const section2Mobile = () => {
        return(
            <section className='section_2'>
                <div className='content_container' style={{backgroundColor: "#FFFFFF"}}>
                    <div className='text_headline3' style={{marginBottom: "24px", marginTop: 30, textAlign: "center"}}>
                        Software Features
                    </div>
                    <div className='image_box'>
                        <div className='single_box' style={{margin: "32px auto"}}>
                            <img src={NewsfeedImage} style={{marginBottom: "20px"}}/>
                            <div className="text_title_big" style={{marginBottom: "5px"}}>NewsFeed</div>
                            <div className='text_body_big_light'>
                                Browse through the news articles on a constantly updating persoanlized newsfeed
                            </div>
                        </div>
                        <div className='single_box' style={{margin: "32px auto"}}>
                            <img src={AddProspects} style={{marginBottom: "20px"}}/>
                            <div className="text_title_big" style={{marginBottom: "5px"}}>Add Prospects</div>
                            <div className='text_body_big_light'>
                                Add prospects either individually or in bulk by importing them from a CSV file
                            </div>
                        </div>
                    </div>

                    <div className='image_box'>
                        <div className='single_box' style={{margin: "32px auto"}}>
                            <img src={AssignMember} style={{marginBottom: "20px"}}/>
                            <div className="text_title_big" style={{marginBottom: "5px"}}>Assign Team members</div>
                            <div className='text_body_big_light'>
                                In order to personalize the newsfeed, assign team members to prospects
                            </div>
                        </div>
                        <div className='single_box' style={{margin: "32px auto"}}>
                            <img src={ProspectProfile} style={{marginBottom: "20px"}}/>
                            <div className="text_title_big" style={{marginBottom: "5px"}}>Prospect profile</div>
                            <div className='text_body_big_light'>
                                View/Update prospect profile information to get accurate news results
                            </div>
                        </div>
                    </div>

                    <div className='image_box'>
                        <div className='single_box' style={{margin: "32px auto"}}>
                            <img src={Filters} style={{marginBottom: "20px"}}/>
                            <div className="text_title_big" style={{marginBottom: "5px"}}>Filters</div>
                            <div className='text_body_big_light'>
                                Narrow down your search for news by applying the Smart Filters on the Newsfeed
                            </div>
                        </div>
                        <div className='single_box' style={{margin: "32px auto"}}>
                            <img src={SearchNews} style={{marginBottom: "20px"}}/>
                            <div className="text_title_big" style={{marginBottom: "5px"}}>Search news sources</div>
                            <div className='text_body_big_light'>
                                Search articles published by a specific news source 
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    const section3FP = () => {
        return(
            <section className='section_2'>
                <div className='content_container' style={{backgroundColor: "#FAFBFB"}}>
                    <div className='text_headline3' style={{marginBottom: "24px", marginTop: 30, textAlign: "center"}}>
                        Admin Controls
                    </div>
                    <div className='image_box default_flex_container'>
                        <div className='single_box'>
                            <img src={InviteMember} style={{marginBottom: "20px"}}/>
                            <div className="text_title_big" style={{marginBottom: "5px"}}>Invite team members</div>
                            <div className='text_body_big_light'>
                                Designate team admins who can add, remove, and manage members and their settings,
                            </div>
                        </div>
                        <div className='single_box'>
                            <img src={ManageProspects} style={{marginBottom: "20px"}}/>
                            <div className="text_title_big" style={{marginBottom: "5px"}}>Manage Prospects</div>
                            <div className='text_body_big_light'>
                                Add tags to the prospects to categorize and keep a better track on them
                            </div>
                        </div>
                    </div>

                    <div className='image_box default_flex_container'>
                        <div className='single_box'>
                            <img src={TeamNotification} style={{marginBottom: "20px"}}/>
                            <div className="text_title_big" style={{marginBottom: "5px"}}>Team notifications</div>
                            <div className='text_body_big_light'>
                                Designate team admins to manage notifications for all the team members
                            </div>
                        </div>
                        <div className='single_box'>
                            <img src={ExportData} style={{marginBottom: "20px"}}/>
                            <div className="text_title_big" style={{marginBottom: "5px"}}>Export news articles</div>
                            <div className='text_body_big_light'>
                                Export news artciles to CSV if you want to slice and dice the  data and share it with others
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    const section3Mobile = () => {
        return(
            <section className='section_2'>
                <div className='content_container' style={{backgroundColor: "#FAFBFB"}}>
                    <div className='text_headline3' style={{marginBottom: "24px", marginTop: 30, textAlign: "center"}}>
                        Admin Controls
                    </div>
                    <div className='image_box'>
                        <div className='single_box' style={{margin: "32px auto"}}>
                            <img src={InviteMember} style={{marginBottom: "20px"}}/>
                            <div className="text_title_big" style={{marginBottom: "5px"}}>Invite team members</div>
                            <div className='text_body_big_light'>
                                Designate team admins who can add, remove, and manage members and their settings,
                            </div>
                        </div>
                        <div className='single_box' style={{margin: "32px auto"}}>
                            <img src={ManageProspects} style={{marginBottom: "20px"}}/>
                            <div className="text_title_big" style={{marginBottom: "5px"}}>Manage Prospects</div>
                            <div className='text_body_big_light'>
                                Add tags to the prospects to categorize and keep a better track on them
                            </div>
                        </div>
                    </div>

                    <div className='image_box'>
                        <div className='single_box' style={{margin: "32px auto"}}>
                            <img src={TeamNotification} style={{marginBottom: "20px"}}/>
                            <div className="text_title_big" style={{marginBottom: "5px"}}>Team notifications</div>
                            <div className='text_body_big_light'>
                                Designate team admins to manage notifications for all the team members
                            </div>
                        </div>
                        <div className='single_box' style={{margin: "32px auto"}}>
                            <img src={ExportData} style={{marginBottom: "20px"}}/>
                            <div className="text_title_big" style={{marginBottom: "5px"}}>Export news articles</div>
                            <div className='text_body_big_light'>
                                Export news artciles to CSV if you want to slice and dice the  data and share it with others
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    const section4FP = () => {
        return(
            <section className='section_2'>
                <div className='content_container' >
                    <div className='text_headline3' style={{marginBottom: "24px", marginTop: 30, textAlign: "center"}}>
                        Communication
                    </div>
                    <div className='image_box default_flex_container'>
                        <div className='single_box'>
                            <img src={Notification} style={{marginBottom: "20px"}}/>
                            <div className="text_title_big" style={{marginBottom: "5px"}}>Set Notification Frequency</div>
                            <div className='text_body_big_light'>
                                Set your personalized email notification frequency and other alert settings.
                            </div>
                        </div>
                        <div className='single_box'>
                            <img src={Comment} style={{marginBottom: "20px"}}/>
                            <div className="text_title_big" style={{marginBottom: "5px"}}>Comment and Share articles</div>
                            <div className='text_body_big_light'>
                            Comment directly on the news articles to specify exactly what needs to be done, and @-mention team members so everything stays connected.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    const section4Mobile = () => {
        return(
            <section className='section_2'>
                <div className='content_container' >
                    <div className='text_headline3' style={{marginBottom: "24px", marginTop: 30, textAlign: "center"}}>
                        Communication
                    </div>
                    <div className='image_box'>
                        <div className='single_box' style={{margin: "32px auto"}}>
                            <img src={Notification} style={{marginBottom: "20px"}}/>
                            <div className="text_title_big" style={{marginBottom: "5px"}}>Set Notification Frequency</div>
                            <div className='text_body_big_light'>
                                Set your personalized email notification frequency and other alert settings.
                            </div>
                        </div>
                        <div className='single_box' style={{margin: "32px auto"}}>
                            <img src={Comment} style={{marginBottom: "20px"}}/>
                            <div className="text_title_big" style={{marginBottom: "5px"}}>Comment and Share articles</div>
                            <div className='text_body_big_light'>
                            Comment directly on the news articles to specify exactly what needs to be done, and @-mention team members so everything stays connected.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    const section5FP = () => {
        return(
            <section className='section_2'>
                <div className='content_container' style={{backgroundColor: "#FAFBFB"}}>
                    <div className='text_headline3' style={{marginBottom: "24px", marginTop: 30, textAlign: "center"}}>
                        Integrations
                    </div>
                    <div className='image_box default_flex_container' style={{marginBottom: 20}}>
                        <div className='single_box'>
                            <img src={Crm} style={{marginBottom: "20px"}}/>
                            <div className="text_title_big" style={{marginBottom: "5px"}}>CRM</div>
                            <div className='text_body_big_light'>
                                Connect with your CRM to directly update prospect records
                            </div>
                        </div>
                        <div className='single_box'><img src={SocialMedia} style={{marginBottom: "20px"}}/>
                            <div className="text_title_big" style={{marginBottom: "5px"}}>Social Media</div>
                            <div className='text_body_big_light'>
                                Deep social media integrations to leverage all social network to full potential
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    const section5Mobile = () => {
        return(
            <section className='section_2'>
                <div className='content_container' style={{marginBottom: 0, backgroundColor: "#FAFBFB"}}>
                    <div className='text_headline3' style={{marginBottom: "24px", marginTop: 30, textAlign: "center"}}>
                        Integrations
                    </div>
                    <div className='image_box'>
                        <div className='single_box' style={{margin: "32px auto"}}>
                            <img src={Crm} style={{marginBottom: "20px"}}/>
                            <div className="text_title_big" style={{marginBottom: "5px"}}>CRM</div>
                            <div className='text_body_big_light'>
                                Connect with your CRM to directly update prospect records
                            </div>
                        </div>
                        <div className='single_box' style={{margin: "32px auto"}}>
                            <img src={SocialMedia} style={{marginBottom: "20px"}}/>
                            <div className="text_title_big" style={{marginBottom: "5px"}}>Social Media</div>
                            <div className='text_body_big_light'>
                                Deep social media integrations to leverage all social network to full potential
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    return (
        <div className="features_container">
            <section className="section_1">
                <div className='content_container'>
                    <div className="banner_box">
                        <div className='text_box'>
                            <div className="heading">
                                AlmaConnect News <span style={{fontWeight: 300}}>features </span>
                            </div>
                            <div className='heading_info'>
                                Access our features and never miss a mention
                            </div>
                            <a href="https://calendly.com/almaconnect-news-scanner/custom-demo" target="_blank">
                                <button className="feature_btn" style={{maxWidth: "204px", width: "100%"}}>Get Started</button>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            {isWidthDown(windowDimensions.width, "md") ? section2Mobile() : section2FP()}
            {isWidthDown(windowDimensions.width, "md") ? section3Mobile() : section3FP()}
            {isWidthDown(windowDimensions.width, "md") ? section4Mobile() : section4FP()}
            {isWidthDown(windowDimensions.width, "md") ? section5Mobile() : section5FP()}
        </div>
    )
}

export default Features;