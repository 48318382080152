import UnarchiveProspects1 from "../../../../../assets/help_center/unarchive_prospects1.png"
import UnarchiveDropdown from "../../../../../assets/help_center/unarchive_dropdown.png"
import BulkUnarchive from "../../../../../assets/help_center/bulk_unarchive.png"


export const UnarchiveProspects = () => {
    return (
        <div style={{ margin: "24px 0px" }}>
        <div className="help_center_top_section">
            <div className="text_title_big" style={{marginBottom: 20}}>Unarchiving Prospects</div>
        </div>
            <div className="help_center_text">
                From the Archived Prospects page, you can manually unarchive prospect using the ‘Unarchive’ button to once again start getting news results for a particular prospect. 
            </div>
            <img className="help_center_img" src={UnarchiveProspects1} style={{maxWidth: "448px", maxHeight: 236, margin: "24px auto"}}></img>

            <div className="help_center_text">
                Similar to archiving, Bulk Unarchiving is also possible. To archive a prospect, again it's necessary to include the Unique Identification Number (UIN) in the file.
            </div>
            <div className="help_center_text">
                You can export your already archived data along with the UINs by clicking on Download archived data from the prospects page.
            </div>

            <div style={{display: "flex", gap: "10px"}}>
                <div style={{fontSize: 16, fontWeight: 600, lineHeight: "22px", marginTop: 24, flexBasis: 92}}>Step 1:</div>
                <div className="help_center_item" style={{ marginTop: 24 }}>Once the file ready with the UINs of all the prospects who are to be archived, click on ‘Unarchive prospects’ from the drop down on the Archived prospects page</div>
            </div>
            <img className="help_center_img" src={UnarchiveDropdown} style={{maxWidth: "506px", maxHeight: "240px", margin: "24px auto"}}></img>

            <div style={{display: "flex", gap: "10px"}}>
                <div style={{fontSize: 16, fontWeight: 600, lineHeight: "22px", marginTop: 24, flexBasis: 52}}>Step 2:</div>
                <div className="help_center_item" style={{ marginTop: 24 }}>It will prompt you to upload the file with the header ‘prospect_id’.</div>
            </div>
            <img className="help_center_img" src={BulkUnarchive} style={{maxWidth: "501px", maxHeight: "304px", margin: "24px auto"}}></img>

            <div className="help_center_text">
                After you have uploaded the file, it usually takes some time to unarchive the prospects depending on the number of rows in the spreadsheet and the file size.
            </div>
            <div className="help_center_text">
                Once the prospects are unarchived, you will start getting their news results
            </div>
        </div>
    );
};