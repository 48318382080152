import React from 'react'
import { AmplitudeEventTrack } from '../../landing_pages/amplitude_event_track'

export const product_lists = [
    {label: "Prospects & Alumni in News", key: "https://www.almaconnect.com/alumni/news"}, 
    {label: "Essential Alumni and Donor Data", key: "https://news.almaconnect.com/data_mine"},
    {label: "Alumni Network For Institutions", key: "https://www.almaconnect.com/for-institutions"}, 
    {label: "Alumni Network For Corporates", key: "https://www.almaconnect.com/for-companies"}
    ]
export const Products = ({
    customClass = "an_dropdown_filter text_body_light",
    customStyle = {},
    ...props
}) => {
  return (
    <div>
    {   
        product_lists.map((option, index) => 
        <a
            href={option.key} 
            key={option.key}
            className={customClass}
            style={customStyle}
            onClick={() => {AmplitudeEventTrack(option.key)}}>{option.label}
        </a>        
    )}  
    </div>
  )
}

