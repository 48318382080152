import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiAgent } from "../../../axiosConfig";
import { updateCurrentTeam } from "../../../redux/currentTeamSlice";
import ContinueArrow from "../../../assets/ContinueArrow.svg";
import { useHistory } from "react-router";
import Cookies from "universal-cookie";
import { LoaderComponent } from "../../utils/LoaderComponent";
import { COOKIE_SETTINGS } from "../../../config/cookiesSettings";

const SwitchAccounts = (props) => {
    const current_user = useSelector((state) => state.current_user.user);
    const current_team = useSelector((state) => state.current_team.team);
    const [isFetching, _setIsFetching] = useState(false);
    const [allTeams, _setAllTeams] = useState([]);
    const _dispatch = useDispatch();
    const _history = useHistory();
    useEffect(() => {
        _setIsFetching(true);
        apiAgent({
            method: "get",
            url: "/users/teams",
        })
            .then((res) => {
                _setIsFetching(false);
                _setAllTeams(res.data.teams);
            })
            .catch((err) => {
                _setIsFetching(false);
                console.log("Error from comp did mount SwitchAcc");
            });
    }, []);

    const handleTeamSelect = (index) => {
        const cookies = new Cookies();
        cookies.set("team_id", allTeams[index].id, {
            path: "/",
            maxAge: COOKIE_SETTINGS["AUTH_DURATION"],
        });
        window.location = "/feed";
    };
    const renderAllTeam = () => {
        return allTeams.map((team, index) => {
            return (
                <div
                    key={index}
                    onClick={() => {
                        handleTeamSelect(index);
                    }}
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: index > 0 ? 20 : 0,
                        cursor: "pointer",
                    }}
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                            style={{
                                display: "flex",
                                fontSize: 20,
                                color: "#437EFE",
                                alignItems: "center",
                                justifyContent: "center",
                                width: 48,
                                height: 48,
                                borderRadius: "50%",
                                background: "rgba(67, 126, 254, 0.1)",
                                cursor: "pointer",
                            }}
                        >
                            {team.name?.[0].toUpperCase()}
                        </div>
                        <div
                            className="text_subtitle_semi_bold"
                            style={{ marginLeft: 8 }}
                        >
                            {team.name}
                        </div>
                    </div>
                    <img src={ContinueArrow} style={{}} />
                </div>
            );
        });
    };
    return (
        <div
            style={{
                maxWidth: 600,
                padding: "0px 24px",
                margin: "8px auto",
                textAlign: "center",
            }}
        >
            <div className="text_title_big">Welcome back!</div>
            <div className="text_body_light" style={{ marginTop: 4 }}>
                Choose your account to get started
            </div>
            <div
                className="box-shadow-content"
                style={{ maxWidth: 480, margin: "24px auto", padding: 0 }}
            >
                <div style={{ padding: 24 }}>
                    <div
                        className="text_subtitle_semi_bold"
                        style={{ textAlign: "left" }}
                    >
                        Accounts related to{" "}
                        <span className="text_subtitle">
                            {current_user.email}
                        </span>
                    </div>
                </div>
                <div style={{ height: 1, background: "#E7ECEB" }}></div>
                <div style={{ padding: 24 }}>
                    <LoaderComponent loaderStyle={{ width: 32 }} />
                    {isFetching ? (
                        <LoaderComponent
                            isLoading={true}
                            loaderStyle={{ width: 32 }}
                        />
                    ) : (
                        renderAllTeam()
                    )}
                </div>
            </div>
            <div
                className="text_link"
                onClick={props.logout}
                style={{
                    width: "max-content",
                    margin: "24px auto 0px",
                    cursor: "pointer",
                }}
            >
                Login using different email address
            </div>
        </div>
    );
};

export default SwitchAccounts;
