import React, { useReducer } from 'react';
import { AnInputBox } from "../components/input_utils/AnInput";
import { Button } from '../components/cta_utils/AnButton';
import AnDropDown from '../components/cta_utils/AnDropDown.js';
import DownArrow from "../assets/dropdown.png";
import AnPopup from '../components/cta_utils/AnPopup.js';
import {apiAgent} from '../axiosConfig';

const reducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_RSS_URL':
      return { ...state, rss_url: action.payload };
    case 'TOGGLE_DROPDOWN':
      return { ...state, showDropdown: !state.showDropdown };
    case 'START_ADDING_URL':
      return { ...state, isAddingUrl: true };
    case 'SELECT_OPTION':
      return {
        ...state,
        selectedOption: action.payload,
        rss_feed_type: action.payload.key,
        showDropdown: !state.showDropdown
      };
    case 'URL_ADD_ERROR':
      return {...state, isAddingUrl: false };
    default:
      return state;
  }
};

const CreateRssSourcePopup = ({ feed_type_filters, setStateVariable }) => {
  const initialState = {
    rss_url: '',
    selectedOption: feed_type_filters[0],
    showDropdown: false,
    rss_feed_type: '',
    isAddingUrl: false
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const changeHandler = (event) => {
    dispatch({ type: 'CHANGE_RSS_URL', payload: event.target.value });
  };

  const handleInputClick = () => {
    dispatch({ type: 'TOGGLE_DROPDOWN' });
  };

  const handleOptionClick = (filter) => {
    dispatch({ type: 'SELECT_OPTION', payload: filter });
  };

  const handleSubmission = () => {
    const { rss_url, rss_feed_type } = state;
    if (!rss_url || rss_feed_type === "all") return;

    dispatch({ type: 'START_ADDING_URL' });
    apiAgent({
      method: 'post',
      url: '/rss_sources/add_rss',
      data: { rss_url: rss_url, feed_type: rss_feed_type }
    }).then(
      (result) => {
        dispatch({ type: 'URL_ADDED' });
        setStateVariable({
          serverMessage: {
            message:"URL added successfully", 
            type: "success"
          },
          popupsList: {createRssSourcePopup: false}
        })
      }
    ).catch(
      (error) => {
        dispatch({ type: 'URL_ADD_ERROR' });
        setStateVariable({serverMessage: {
          message: "Something went wrong! Unable to add URL", 
          type: "warning"
        }})
      }
    );
  };

  const handlePopupClose = () => {
    setStateVariable({popupsList: {}})
  }

  return (
    <AnPopup handleOnClosePopup={handlePopupClose} maxWidth={452}>
      <div className='text_title'>Add Rss Source</div>
      <div className="line_seperator_ex_light_grey" style={{ margin: "20px auto" }}></div>

      <div className='text_body_light'>URL</div>
      <AnInputBox style={{ maxWidth: "415px" }}>
        <input
          type="text"
          name="url"
          value={state.rss_url}
          onChange={changeHandler}
          placeholder="Add URL"
        />
      </AnInputBox>

      <div className='text_body_light' style={{ marginTop: 12 }}>Feed type</div>
      <div className='an_input_box' style={{ maxWidth: "169px" }}>
        <AnInputBox style={{ maxWidth: "169px" }}>
          <div style={{ position: 'relative' }}>
            <input
              type="text"
              name="url"
              value={state.selectedOption.label}
              onClick={handleInputClick}
              placeholder="Feed type"
              readOnly
            />
            <span className="an_search_lens" style={{ right: 4, left: "auto" }}>
              <img src={DownArrow} />
            </span>
          </div>
        </AnInputBox>

        {state.showDropdown && (
          <AnDropDown
            style={{
              position: 'absolute',
              padding: '8px',
              background: 'white',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
              borderRadius: 4,
              width: '169px',
              zIndex: 99
            }}
            toggleDropDown={handleInputClick}
          >
            {feed_type_filters.map((filter, index) => (
              index > 0 && (
                <div
                  key={filter.key}
                  value={filter.key}
                  className='text_subtitle_semi_bold'
                  style={{ padding: 8, cursor: "pointer" }}
                  onClick={() => handleOptionClick(filter)}
                >
                  {filter.label !== "All" && filter.label}
                </div>
              )
            ))}
          </AnDropDown>
        )}
      </div>
      <div className='default_flex_container'>
        <Button
          onClick={handleSubmission}
          buttonStyle={{ marginTop: "4px" }}
          innerButtonStye={{
            padding: "8px 22px",
          }}
          isLoading={state.isAddingUrl}
        >
          Add
        </Button>
        <div
          className='text_link'
          onClick={handlePopupClose}
          style={{ marginTop: 4, marginLeft: 8 }}
        >
          Cancel
        </div>
      </div>
    </AnPopup>
  );
};

export default CreateRssSourcePopup;