import { apiAgent } from "../../../axiosConfig"
import getApiUrl from "../../utils/UrlHelper"

export const fetch_temp_mappers = (data_params) => {
    return apiAgent({
        method: "get",
        url: getApiUrl('get_temp_mapper'),
        params: data_params
    })
}

export const fetch_mapped_unmapped = (data_params) => {
    return apiAgent({
        method: "get",
        url: getApiUrl('get_mapped_unmapped_suggestions'),
        params: data_params
    })
}

export const export_mapper_sugg = (data_params) => {
    return apiAgent({
        method: "post",
        url: getApiUrl('get_export_mapper_sugg'),
        data: data_params
    })
}

export const generate_suggestions = (data_params) => {
    return apiAgent({
        method: "post",
        url: getApiUrl('get_generate_temp_sugg'),
        data: data_params
    })
}

export const clear_suggestions = (data_params) => {
    return apiAgent({
        method: "post",
        url: getApiUrl('clear_suggestions'),
        data: data_params
    })
}

export const map_prospect = (data_params) => {
    return apiAgent({
        method: "post",
        url: getApiUrl('get_ln_prospect_map'),
        data: data_params
    })
}

export const fetch_unmapped_job_updates = (data_params) => {
    return apiAgent({
        method: "get",
        url: getApiUrl('get_temp_job_updates'),
        params: data_params
    })
}

export const fetch_job_posts = (data_params) => {
    return apiAgent({
        method: "get",
        url: getApiUrl("get_feed"),
        params: data_params
    })
}

export const map_and_post_job = (data_params) => {
    return apiAgent({
        method: "post",
        url: getApiUrl("map_and_post_job"),
        data: data_params
    })
}

export const export_mapper_posts = (data_params) => {
    return apiAgent({
        method: "post",
        url: getApiUrl("export_mapper_posts"),
        data: data_params
    })
}