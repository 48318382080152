import { useState, Fragment, useEffect } from "react";
import React from "react";
import ConditionalRender from "../../../../ConditionalRender.js";
import HelpCenterImage from "../../../../assets/HelpCenterImage.svg";
import GreyArrowDown from "../../../../assets/GreyArrowDown.svg";
import { PrivacyPolicy } from "./PrivacyPolicy.js";
import { AddingProspects }  from "./getting_started/adding_prospects.jsx";
import { ImportFile } from "./getting_started/import_file.jsx";
import { DataFields } from "./getting_started/data_fields.jsx";
import { Keywords } from "./getting_started/keywords.jsx";
import { AssigningTagsandTeamMembers } from "./prospect_management/assigning_tags_and_team_members.jsx";
import { ArchiveProspects } from "./prospect_management/archive_prospects.jsx";
import { UpdatingProspectData } from "./prospect_management/updating_prospect_data.jsx";
import { UnarchiveProspects } from "./prospect_management/unarchive_prospects.jsx";
import { Prospects } from "./navigating_news_feed/prospects.jsx";
import { HighConfidenceResults } from "./navigating_news_feed/high_confidence_results.jsx";
import { LowConfidenceResults } from "./navigating_news_feed/low_confidence_results.jsx";
import { ManagingTeam } from "./account_settings/managing_team.jsx";
import { PersonalSettings } from "./account_settings/personal_settings.jsx";
import { SettingsBasics } from "./account_settings/settings_basics.jsx";
import { useHistory } from "react-router-dom";
import { isWidthDown } from "../../../utils/designUtils.js";
import useWindowSize from "../../../custom_hooks/useWindowSize.js";

const MAIN_SECTIONS = {
    "Getting Started": ["Creating a file to import", "Adding Prospects", "Data Fields", "Keywords"],
    "Prospect Management": ["Assigning tags and team members", "Updating prospect data", "Archive prospects", "Unarchive prospects"],
    "Navigating News feed": ["High confidence results", "Low confidence results",  "Prospects"],
    "Account Settings": ["Settings Basics", "Personal Settings", "Managing a team"],
    "Privacy Policy": [],
};
const SUB_TABS = {
    "Creating a file to import": ImportFile,
    "Adding Prospects": AddingProspects,
    "Data Fields": DataFields,
    "Keywords": Keywords,
    "Assigning tags and team members": AssigningTagsandTeamMembers,
    "Updating prospect data": UpdatingProspectData,
    "Archive prospects": ArchiveProspects,
    "Unarchive prospects": UnarchiveProspects,
    "High confidence results": HighConfidenceResults,
    "Low confidence results": LowConfidenceResults,
    "Prospects": Prospects,
    "Settings Basics": SettingsBasics,
    "Personal Settings": PersonalSettings,
    "Managing a team": ManagingTeam, 
    "Privacy Policy": PrivacyPolicy,
};

const loadOnceInitialState = () => {
    let paramsString = window.location.search;
    let searchParams = new URLSearchParams(paramsString);
    let mainTabs = searchParams.get("mainTab")
        ? [searchParams.get("mainTab")]
        : ["Getting Started"];
    let subTab = searchParams.get("subTab") || "Creating a file to import";
    let subTabComponent = SUB_TABS[subTab] || ImportFile;
    return { mainTabs, subTab, subTabComponent };
};

export const HelpCenterPage = () => {
    const [activeTab, setActiveTab] = useState(loadOnceInitialState);
    const [activeMainTab, setActiveMainTab] = useState("Getting Started");
    const [activeSection, setActiveSection] = useState("Creating a file to import")
    const windowDimensions = useWindowSize();
    const _history = useHistory();
    let keysWithActiveMainTab = MAIN_SECTIONS[activeMainTab] || MAIN_SECTIONS["Getting Started"];
    
    useEffect(() => {
        if(!isWidthDown(windowDimensions.width, 1032)){
            toggleMobileSection(activeTab.subTab);
            setActiveMainTab(activeTab.mainTabs[0]);
            setActiveSection(activeTab.subTab);
        }
        let paramsString = window.location.search;
        let searchParams = new URLSearchParams(paramsString);
        let scrollTo = searchParams.get("scrollTo");
        let timeout = null;
        if (scrollTo) { // REFER TO COMMENT AT BOTTOM OF THIS FILE
            timeout = setTimeout(() => {
                let elem = document.getElementById(scrollTo);
                if (elem) {
                    const elementTop = elem.getBoundingClientRect().top + window.scrollY;
                    window.scrollTo({
                      top: elementTop - 90,
                      behavior: "smooth",
                    });
                }
            }, 1000);
        }
        return () => window.clearTimeout(timeout);
    }, [(isWidthDown(windowDimensions.width, 1032))]);

    useEffect(() => {
        let url = HelpCenterLinks[String(activeSection)];
        if(activeSection == null){
            url = "/help_center?mainTab=" + activeMainTab;
        } else{
            setActiveTab({mainTabs: activeMainTab,subTab: activeSection,subTabComponent: SUB_TABS[activeSection]})
        }
        window.history.pushState(null, null, url);
    },[activeSection, activeMainTab])

    const toggleMainTab = (mainTab) => {
        if (!MAIN_SECTIONS[mainTab].length) {
            setActiveTab({
                ...activeTab,
                subTab: mainTab,
                subTabComponent: SUB_TABS[mainTab],
            });
            _history.push(`${HelpCenterLinks[mainTab]}`)
            return;
        }
        let temp = [...activeTab.mainTabs];
        let activeIndex = temp.indexOf(mainTab);
        if (activeIndex != -1) {
            temp.splice(activeIndex, 1);
        } else {
            temp.push(mainTab);
        }
        setActiveTab({ ...activeTab, mainTabs: temp });
    };

    const renderSubSections = (mainTab) => {
        let subTabs = MAIN_SECTIONS[mainTab];
        return subTabs.map((subTab) => {
            return (
                <div
                    key={subTab}
                    onClick={() => {
                        setActiveTab({
                            ...activeTab,
                            subTab: subTab,
                            subTabComponent: SUB_TABS[subTab],
                        });
                        _history.push(`${HelpCenterLinks[subTab]}`)
                    }}
                    className={`sub_tab_container ${
                        activeTab.subTab == subTab
                            ? "text_link"
                            : "text_subtitle_light"
                    }`}
                >
                    {subTab}
                </div>
            );
        });
    };

    const renderMainTabs = () => {
        let mainTabs = Object.keys(MAIN_SECTIONS);
        return mainTabs.map((mainTab) => {
            return (
                <Fragment key={mainTab}>
                    <div className="main_tab_container">
                        <div
                            className="flex_container_space_between"
                            style={{ cursor: "pointer" }}
                            onClick={() => toggleMainTab(mainTab)}
                        >
                            <div
                                className={
                                    activeTab.subTab == mainTab
                                        ? "text_link_big"
                                        : "text_title"
                                }
                            >
                                {mainTab}
                            </div>
                            <ConditionalRender
                                if={MAIN_SECTIONS[mainTab].length}
                            >
                                <img
                                    src={GreyArrowDown}
                                    className={
                                        activeTab.mainTabs.includes(mainTab)
                                            ? "to_arrow_up"
                                            : "to_arrow_down"
                                    }
                                    style={{ width: 24 }}
                                />
                            </ConditionalRender>
                        </div>
                        {activeTab.mainTabs.includes(mainTab) &&
                            renderSubSections(mainTab)}
                    </div>
                    <div className="line_seperator_ex_light_grey"></div>
                </Fragment>
            );
        });
    };

    const renderMainTabsMobile = () => {
        let mainTabs = Object.keys(MAIN_SECTIONS);
        return mainTabs.map((mainTab) => {
            const isActive = activeMainTab === mainTab;
    
            return (
                <Fragment key={mainTab}>
                    <div className={`main_tab_container ${isActive ? 'active' : ''}`}>
                        <div
                            className="flex_container_space_between"
                            style={{ cursor: "pointer" }}
                            onClick={() => setActiveMainTab(mainTab)}
                        >
                            <div
                                className={`mainTab ${isActive ? 'activeTab' : ''}`}
                            >
                                {mainTab}
                            </div>
                            <div style={{borderBottom: "1px solid #E7ECEB"}}></div>
                        </div>
                    </div>
                    <div className={`line_seperator_ex_light_grey ${isActive ? 'activeLine' : ''}`}></div>
                </Fragment>
            );
        });
    };

    const toggleMobileSection = (key) => {
        if(activeMainTab == "Privacy Policy"){
            setActiveSection("Privacy Policy")
        }
        if(key == activeSection) {
            setActiveSection(null);
        } else setActiveSection(key);
    }
    return (
        <div style={{marginTop: !isWidthDown(windowDimensions.width, 1032)? 80 : 0}}>
        <div style={{backgroundColor: "#F3F4F5"}}>
                <div className="text_body" style={{padding: 8, textAlign: "center"}}>
                    If you need further assistance, you can reach out to us via email at <span style={{fontWeight: 700, color: "#00C4B5",}}>global.sales@almaconnect.com</span>
                </div>
            </div>
        <div className="help_center_container">
            <div className="flex_container_space_between">
                <div className="text_headline3">
                    AlmaConnect News Help center
                </div>
                {!isWidthDown(windowDimensions.width, 1032)? <img src={HelpCenterImage} style={{ width: 200 }}></img> : <></>}
            </div>
            <div className="line_seperator_ex_light_grey"></div>
            {!isWidthDown(windowDimensions.width, 1032)?
                <div className="default_flex_container" style={{ alignItems: "flex-start" }}>
                    <div className="help_center_left_panel">
                        {renderMainTabs()}
                    </div>
                    <div className="main_content_container">
                        <activeTab.subTabComponent />
                    </div>
                </div>
                :
                <>
                    <div className="help_center_left_panel">
                        {renderMainTabsMobile()}
                    </div>
                    <div className="main_content_container" style={{padding: "24px 0px 60px"}}>
                    {keysWithActiveMainTab.length === 0 && (
                        <Fragment>
                            <PrivacyPolicy />
                            <div className="line_seperator_ex_light_grey"></div>
                        </Fragment>
                    )}
                    {
                        keysWithActiveMainTab.map((key, index) => (
                            <Fragment key={key}>
                                <div style={{padding: "8px 0px"}} onClick={() => toggleMobileSection(key)}>
                                    <div
                                        className="flex_container_space_between"
                                        style={{ cursor: "pointer"}}>
                                        <div className="text_title" style={{fontSize: 16, lineHeight: "20px", color: (activeSection === key)? "#00C4B5" : "#707274"}}>
                                            {key}
                                        </div>
                                        <img
                                            src={GreyArrowDown}
                                            className={
                                                (activeSection == key)
                                                    ? "to_arrow_up"
                                                    : "to_arrow_down"
                                            }
                                            style={{ width: 24 }}
                                        />

                                    </div>
                                </div>
                                <div className="line_seperator_ex_light_grey"></div>
                                {activeSection === key && (
                                    <Fragment>
                                        {React.createElement(SUB_TABS[key])}
                                        <div className="line_seperator_ex_light_grey"></div>
                                    </Fragment>
                                )}
                            </Fragment>
                        ))
                    }
                    </div>
                </>
            }
        </div>
        </div>
    );
};

export const HelpCenterLinks = {
    "Creating a file to import": 
                "/help_center?mainTab=Getting Started&subTab=Import File",
    "Adding Prospects": 
                "/help_center?mainTab=Getting Started&subTab=Adding Prospects",
    "Data Fields": 
                "/help_center?mainTab=Getting Started&subTab=Data Fields",
    "Keywords":
                "/help_center?mainTab=Getting Started&subTab=Keywords",
    "Assigning tags and team members":   
                "/help_center?mainTab=Prospect Management&subTab=Assigning tags and team members" ,
    "Updating prospect data": 
                "/help_center?mainTab=Prospect Management&subTab=Updating prospect data",
    "Archive prospects": 
                "/help_center?mainTab=Prospect Management&subTab=Archive prospects",
    "Unarchive prospects": 
                "/help_center?mainTab=Prospect Management&subTab=Unarchive prospects",    
    "High confidence results": 
                "/help_center?mainTab=Navigating News feed&subTab=High confidence results",
    "Low confidence results": 
                "/help_center?mainTab=Navigating News feed&subTab=Low confidence results",
    "Prospects": 
                "/help_center?mainTab=Navigating News feed&subTab=Prospects",
    "Settings Basics": 
                "/help_center?mainTab=Account Settings&subTab=Settings Basics",
    "Personal Settings": 
                "/help_center?mainTab=Account Settings&subTab=Personal Settings",
    "Managing a team": 
                "/help_center?mainTab=Account Settings&subTab=Managing a team",
    "Privacy Policy":
                "/help_center?mainTab=Privacy Policy&subTab=Privacy Policy",
};
// BELOW IS AN EXAMPLE OF HOW TO SCROLL TO SPECIFIC ID SECTION INSIDE SUBTAB
/*"Updating Prospects":
        "/help_center?mainTab=Getting Started&subTab=Adding Prospects&scrollTo=updating_prospects",*/