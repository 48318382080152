import React from 'react'
import { useCallback } from 'react'
import { useEffect } from 'react'
import { useReducer } from 'react'
import { apiAgent } from '../../../axiosConfig'
import ConditionalRender from '../../../ConditionalRender'
import Pagination from '../../../Pagination'
import { Button } from '../../cta_utils/AnButton'
import { AnInputBox } from '../../input_utils/AnInput'
import { LoaderComponent } from '../../utils/LoaderComponent'
import getApiUrl from '../../utils/UrlHelper'
import TableHeads from '../../visualizers/tabular/TableHeads'
import TableRows from '../../visualizers/tabular/TableRows'

const LinkedinProspects = () => {
    const [pageData, setState] = useReducer(reducer, {
        list: [],
        query: null,
        isLoading: {isFetching: true},
        totalPages: 0,
        totalCount: 0,
        currentPage: 1
    })
    const fetchList = useCallback((page) => {
        setState({payload: {isLoading: {isFetching: true}}, type: "SET_STATE"})
        apiAgent({
            method: "get",
            url: getApiUrl("get_linkedin_prospects"),
            params: {page: page, query: pageData.query}
        }).then(res => {
            setState({payload: {
                list: res.data.data,
                totalCount: res.data.total_count,
                totalPages: res.data.total_pages,
                currentPage: page,
                isLoading: {}
            }, type: "SET_STATE"})
        }).catch(err => setState({payload: {isLoading: {}}, type: "SET_STATE"})        )
    }, [pageData.query])
    
    useEffect(() => {
        fetchList(pageData.currentPage);
    }, [pageData.currentPage])
    
    
    const renderTableContent = () => {
        return(
            <div>
                <Pagination totalPages={pageData.totalPages}
                    currentPage={pageData.currentPage}
                    onPageChange={(page)=> setState({payload: {currentPage: page}, type: "SET_STATE"})} 
                />
                <table className='data_table' style={{textAlign: "left", marginTop: "24px"}}>
                    <TableHeads typoGraphyClass="text_body_big_light" headerClass="table_header_row_seperated">
                        <th style={{width: "30%"}}>Name</th>
                        <th style={{width: "20%"}}>Title</th>
                        <th style={{width: "10%"}}>Location</th>
                        <th style={{width: "10%"}}>Emails</th>
                        <th style={{width: "10%"}}>Phones</th>
                        <th style={{width: "20%"}}>Company</th>
                    </TableHeads>
                    <tbody>
                        {pageData.list.map(item => {
                            return(
                                <TableRows key={item.id} rowClass="table_content_row_seperated text_body">
                                    <td>
                                        <a href={item.linkedin_url} className="text_link hover_under_line" target="_blank">{item.name}</a>                                        
                                    </td>
                                    <td>
                                        {item.title}
                                    </td>
                                    <td>
                                        {item.location}
                                    </td>                                    
                                    <td>
                                        {item.emails?.map(email => <div key={email}>{email}</div>)}
                                    </td>
                                    <td>
                                        {item.phones?.map(phone => <div key={phone}>{phone}</div>)}
                                    </td>
                                    <td style={{textAlign: "center"}}>                                        
                                        <ConditionalRender if={!!item.company}>                                            
                                            <div>
                                                <span>{item.designation} @ </span>
                                                <a href={item.company?.company_url} className="text_link hover_under_line" style={{display: "inline"}} target="_blank">{item.company?.name}</a>
                                            </div>
                                        </ConditionalRender>
                                    </td>
                                </TableRows>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
    if(pageData.isLoading.isFetching)
        return <LoaderComponent isLoading="true" type="FULL_PAGE"></LoaderComponent>
    return (
        <div style={{margin: "33px 49px"}}>
            <div className='text_title'> Total Prospects {pageData.totalCount}</div>
            <AnInputBox label="Name" style={{maxWidth: 400, marginTop: "24px"}}>
                <input type="text" value={pageData.query} onChange={(e) => setState({payload: {query: e.target.value}, type: "SET_STATE"})} placeholder="Search name"></input>
            </AnInputBox>
            <Button 
                buttonStyle={{marginTop: "4px"}}
                innerButtonStye={{padding: "8px 16px"}}
                onClick={() => fetchList(1)}>
                Search
            </Button>
            {renderTableContent()}
        </div>
    )
}

const reducer = (state, action) => {
    switch(action.type){
        case "SET_STATE": {
            return {
                ...state,
                ...action.payload
            }
        }
        default: return state;
    }
}
      

export default LinkedinProspects