import ConditionalRender from "../../../ConditionalRender"

export const SuggestionTags = ({ tags, ...props }) => {
    return (
        <ConditionalRender if={tags}>
            <div style={{ display: "flex" }}>
                <div className='text_caption' style={{ margin: "4px 12px 0px 0px" }}>Tags:</div>
                <div style={{ display: "flex", flexWrap: "wrap", gridGap: "12px 6px" }}>
                    {tags?.filter(tag => !tag.includes("_not_")).map(tag => (
                        <div key={tag} className='text_link_small' style={{ padding: "4px 8px", backgroundColor: "rgba(0, 196, 181, 0.1)" }}>
                            {tag}
                        </div>))}
                    {tags?.filter(tag => tag.includes("_not_")).map(tag => (
                        <div key={tag} className='text_caption_error' style={{ fontWeight: 700, padding: "4px 8px", backgroundColor: "rgba(249, 69, 69, 0.1)" }}>
                            {tag}
                        </div>))}
                </div>
            </div>
        </ConditionalRender>
    )
}