import { apiAgent } from "../../axiosConfig"
import getApiUrl from "../utils/UrlHelper"

export const AmplitudeEventTrack = (props) => {
    const event_type = props || ""
    apiAgent({
        method: "post",
        url: getApiUrl("track_amplitude_event"),
        data: {
            event_type: event_type
        }
    }).then(res => {
        
    }).catch(err => {
    }) 
}