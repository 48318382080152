import React, { useEffect } from "react"
import { useReducer } from "react"
import ConditionalRender from "../../../ConditionalRender"
import Pagination from "../../../Pagination"
import { Button } from "../../cta_utils/AnButton"
import AnPopup from "../../cta_utils/AnPopup"
import { FlashMessages } from "../../cta_utils/FlashMessages"
import { AnInputBox } from "../../input_utils/AnInput"
import { AnInputCheckBox } from "../../input_utils/AnInputCheckBox"
import { indexOfObject } from "../../utils/ArrayUtils"
import { LoaderComponent } from "../../utils/LoaderComponent"
import TableHeads from "../../visualizers/tabular/TableHeads"
import TableRows from "../../visualizers/tabular/TableRows"
import { categoriesFetcher, categoryCreateOrEdit } from "./apiHitters"


export const CategoriesPage = () => {
    const [categoryPageData, categoryPageDataReducer] = useReducer(reducer, {
        categoryList: [],
        tempCategory: {title: "", primary_terms: "", secondary_terms: ""},
        totalPages: 0,
        totalCompanies: 0,
        currentPage: 1,
        isLoading: {
            isFetching: true
        },
        query: {
            search_term: "",
        },
        popupsList: {},
    })
    useEffect(() => {
        fetchCategories()
    }, [])

    const fetchCategories = (page=1) => {
        categoryPageDataReducer({
            type: "SET_STATE",
            payload: {
                isLoading: {isFetching: true}
            }
        });
        let data_params = {
            page: page,
            ...categoryPageData.query
        }
        categoriesFetcher(data_params).then( res =>
            categoryPageDataReducer({
                type: "SET_STATE",
                payload: {
                    categoryList: res.data.data,
                    isLoading: {},
                    currentPage: page,
                    totalPages: res.data.total_pages,
                    totalCompanies: res.data.total
                }
            })
        ).catch(e => categoryPageDataReducer({
                type: "SET_STATE",
                payload: {
                    currentPage: page,
                    isLoading: {}
                }
            })
        )
    }

    const handleUpdate = () => {
        categoryPageDataReducer({
            type: "SET_STATE",
            payload: {
                isLoading: {isSaving: true}
            }
        })
        categoryCreateOrEdit({...categoryPageData.tempCategory}).then(res => {
            let {tempCategory, categoryList, currentPage} = categoryPageData;
            let index = indexOfObject(categoryList, "_id", tempCategory._id);
            categoryList[index] = {...tempCategory};
            let payload = {
                categoryList: [...categoryList],
                successMessage: "Updated",
                isLoading: {},
                popupsList: {}
            }
            categoryPageDataReducer({
                type: "SET_STATE",
                payload: payload
            })
            fetchCategories(currentPage);
        }).catch(e => {
            categoryPageDataReducer({
                type: "SET_STATE",
                payload: {
                    isLoading: {}
                }
            })
        })
    }

    const handleOnChange = (e) => {
        let payload = {...categoryPageData.query}
        payload[e.target.name] = e.target.value;
        categoryPageDataReducer({
            type: "SET_STATE",
            payload: {
                query: payload
            }
        })
    }
    const handleCategoryEdit = (e) => {
        let tempComp = {...categoryPageData.tempCategory}
        tempComp[e.target.name] = e.target.value
        categoryPageDataReducer({
            type: "SET_STATE",
            payload: {
                tempCategory: tempComp
            }
        })
    }
    const closeFlash = () => {
        categoryPageDataReducer({type: "SET_STATE", payload: {successMessage: null}})

    }
    const setPopupsList = (popupsList={}) => {
        categoryPageDataReducer({type: "SET_STATE", payload: {popupsList: popupsList}})
    }

    const renderCreateorEditPopup = () =>{
        const {tempCategory, title_editable} = categoryPageData
        return (
            <AnPopup handleOnClosePopup={setPopupsList}>
                <div className="text_title">{title_editable ? 'Create' : 'Edit'}</div>
                <div
                    className="line_seperator_ex_light_grey"
                    style={{ margin: "16px auto" }}
                ></div>
                <AnInputBox label="Title">
                    <input type="text" name="title" value={tempCategory.title} onChange={handleCategoryEdit} disabled={!title_editable}></input>
                </AnInputBox>
                <AnInputBox label="Primary Terms">
                    <input type="text" name="primary_terms" value={tempCategory.primary_terms} onChange={handleCategoryEdit}></input>
                </AnInputBox>
                <AnInputBox label="Secondary Terms">
                    <input type="text" name="secondary_terms" value={tempCategory.secondary_terms} onChange={handleCategoryEdit}></input>
                </AnInputBox>
                <Button onClick={handleUpdate}
                        isLoading={categoryPageData.isLoading.isSaving}
                        buttonStyle={{marginTop: "12px", width: "max-content"}}
                >
                    Save
                </Button>
            </AnPopup>
        )
    }

    const renderTableContent = () => {
        let {categoryList, totalPages, currentPage} = categoryPageData;
        return(
            <>
                <Pagination totalPages={totalPages}
                            currentPage={currentPage}
                            onPageChange={fetchCategories}
                            style={{padding: "16px 0px 0px"}}
                />

                <table className="data_table" style={{textAlign: "left", marginTop: "24px"}}>
                    <TableHeads typoGraphyClass="text_body_big_light" headerClass="table_header_row">
                        <th style={{width: "30%"}}>Title</th>
                        <th style={{width: "30%"}}>Primary Terms</th>
                        <th style={{width: "30%"}}>Secondary Terms</th>
                        <th style={{width: "10%"}}>Edit</th>
                    </TableHeads>
                    <tbody>
                    {
                        categoryList.map((comp, index) => (
                            <TableRows key={comp._id} rowClass="table_content_row text_body">
                                <td>{comp.title}</td>
                                <td>{comp.primary_terms}</td>
                                <td>{comp.secondary_terms}</td>
                                <td className="text_link" onClick={() => {
                                    categoryPageDataReducer({
                                        type: "SET_STATE",
                                        payload: {
                                            popupsList: {editPopup: true},
                                            title_editable: false,
                                            tempCategory: categoryPageData.categoryList[index]
                                        }
                                    })
                                }}>Edit</td>
                            </TableRows>
                        ))
                    }
                    </tbody>
                </table>
            </>
        )
    }
    return(
        <div style={{margin: "33px 49px"}}>
            <div className="text_title">Categories</div>
            <div style={{margin: "16px auto"}}>
                <AnInputBox label="Search Title" style={{maxWidth: "300px"}}>
                    <input type="text"
                           value={categoryPageData.query.title}
                           name="title"
                           onChange={handleOnChange}
                           placeholder="Search"></input>
                </AnInputBox>
                <Button onClick={() => fetchCategories(1)}>
                    Search
                </Button>
            </div>
            <div style={{float: 'right'}}>
                <Button onClick={() => categoryPageDataReducer({
                    type: "SET_STATE",
                    payload: {
                            popupsList: {editPopup: true}, title_editable: true}})}>
                    Create
                </Button>
            </div>
            <div style={{clear: 'both'}}></div>
            <LoaderComponent isLoading={categoryPageData.isLoading.isFetching} />
            <ConditionalRender if={!categoryPageData.isLoading.isFetching}>
                {renderTableContent()}
            </ConditionalRender>
            {categoryPageData.popupsList.editPopup && renderCreateorEditPopup()}
            {categoryPageData.successMessage && (
                <FlashMessages messageTheme="success"
                               closeCallback={closeFlash}
                >{categoryPageData.successMessage}</FlashMessages>
            )}
        </div>
    )
}

const reducer = (state, action) => {
    switch(action.type){
        case "SET_STATE": {
            return {...state, ...action.payload}
        }
        default: return state
    }
}