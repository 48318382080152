import React, { useEffect, useReducer } from 'react'
import reducer from '../../utils/CommonReducer'
import Pagination from '../../../Pagination'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { NameInitial } from '../../cta_utils/AnNameInitial'
import { Button } from '../../cta_utils/AnButton'
import MapAndPostJobPopup from './_MapAndPostJobPopup'
import { LoaderComponent } from '../../utils/LoaderComponent'
import { export_mapper_posts, fetch_unmapped_job_updates } from './api'
import Tooltip from '../../cta_utils/Tooltip'
import { indexOfObject } from '../../utils/ArrayUtils'
import { FlashMessages } from '../../cta_utils/FlashMessages'
import ConditionalRender from '../../../ConditionalRender'
import { AnConfirmPopup } from '../../cta_utils/AnConfirmPopup'
import DownloadImage from '../../../assets/download.png'
import LinkedinIcon from '../../../assets/LinkedinIcon.png'
import { JobUpdateDesc } from '../../feed_components/JobUpdateDesc'
import AnDropdownGroupFilter from '../../cta_utils/AnDropdownGroupfilter'
import { TempPostFilterOptions } from '../../utils/PostFilterOptions'
import { datepicker_input_format } from '../../utils/dateUtils'

const UnmappedJobUpdates = ({type = 'default', setParentComponentState, buttonLabel="Map and Save", dateRanges, ...props}) => {

    const [componentData, setComponentData] = useReducer(reducer, {
        jobUpdates: [],
        selectedJobUpdate: {},
        totalPages: null,
        totalCount: null,
        currentPage: 1,
        isLoading: {},
        successMessage: "",
        popupsList: {},
        dropDownFilterlist: {containSuggestion: 'all'},
    })

    const setComponentState = (payload) => setComponentData({type: "SET_STATE", payload})
    const {totalPages, currentPage, jobUpdates, popupsList, successMessage } = componentData
    let {dropDownFilterlist} = componentData
    if(props.dropDownFilterlist) dropDownFilterlist = props.dropDownFilterlist
    useEffect(() => {
        setComponentState({isLoading: {isFetching: true}})
        let data_params = get_data_params();
        data_params["page"] = currentPage
        let range_params = {}
        let date_ranges = {}
        if(dateRanges) date_ranges = {...dateRanges}
        Object.keys(date_ranges).forEach(k => {
            if(date_ranges[k].value.from && date_ranges[k].value.to){
                let from = date_ranges[k].value.from;
                let to = date_ranges[k].value.to;
                range_params[k] = {from: datepicker_input_format(from), to: datepicker_input_format(to)};
            }
        })
        data_params["date_ranges"] = range_params
        fetch_unmapped_job_updates(data_params).then(res => {
            setComponentState({
                totalPages: res.data.total_pages,
                totalCount: res.data.total,
                jobUpdates: [...res.data.data],
                isLoading: {},
            })
            setParentComponentState?.({unmappedCount: res.data.total, mappedCount: res.data.mapped_count})
        })
    }, [currentPage, dropDownFilterlist, dateRanges])

    const get_data_params = () => {
        let data_params = {}
        if(dropDownFilterlist.containSuggestion == 'contains_suggestions'){
            data_params["has_sugg"] = true;
        }
        else if(dropDownFilterlist.containSuggestion == 'no_suggestions'){
            data_params["has_sugg"] = false;
        } 
        return data_params
    }
    const onFinishPosting = (mapped_id) => {
        let index = indexOfObject(jobUpdates, "id", mapped_id)
        if(index !== -1){
            let temp = [...jobUpdates]
            temp.splice(index, 1)
            let success_message = buttonLabel == 'Move to High Confidence' ? "Job update moved to high confidence" : "Prospect mapped and post saved."
            setComponentState({jobUpdates: temp, successMessage: success_message, popupsList: {}})
        }
    }
    const renderLinkedinProspect = (linkedin_prospect) => {
        return(            
            <div className="default_flex_container" style={{flexWrap: "wrap-reverse", alignItems: "flex-end"}}>
                    <a href={linkedin_prospect.linkedin_url}
                        className="default_flex_container"
                        style={{alignItems: 'flex-start',textDecoration: "none"}}
                    >
                        <NameInitial
                            name={linkedin_prospect.name}
                            profile_image={linkedin_prospect.profile_image}
                        />
                        <div style={{marginLeft: 8, width: "140px"}}>
                            <div className='default_flex_container'>
                                <Tooltip
                                    tooltipText={() => linkedin_prospect.name}
                                    innerClass='text_body_light'
                                    textTheme='background-light-black bottom'
                                    innerStyle={{ padding: '8px', alignSelf: 'center', color: 'white', borderRadius: '8px' }}
                                    style={{ marginTop: 6 }}
                                >
                                    <div className="text_subtitle_big truncate" style={{flex: 'none', width: "100%"}}>{linkedin_prospect.name}</div>
                                </Tooltip>
                        </div>
                        <Tooltip
                            tooltipText={() => { return `${linkedin_prospect.designation}, ${linkedin_prospect.company}` }}
                            innerClass='text_body_light'
                            textTheme='background-light-black bottom'
                            innerStyle={{ padding: '8px', alignSelf: 'center', color: 'white', borderRadius: '8px' }}
                            style={{ marginTop: 6 }}
                        >
                            <div className="text_body_light truncate">{linkedin_prospect.designation}, {linkedin_prospect.company}</div>
                            </Tooltip>
                        </div>
                    </a>                                                                                           
            </div>
        )
    }
    const setPopupsList = (payload) => {
        setComponentState({popupsList: payload})
    }
    const submitMapperJobUpdate = () => {
        export_mapper_posts({mapper_type: 'TempPost', ...get_data_params()}).then(res => {
            setComponentState({successMessage: "You will receive an email when the export is ready to download", popupsList: {}})
        })
    }
    
    const exportPopup = () => {
        return(
            <AnConfirmPopup 
                onSubmitCallback={() => submitMapperJobUpdate()}
                onCancelCallback={() => setPopupsList({})}
                name="unmapped posts"
            >
            </AnConfirmPopup>
            )
    }
    if(componentData.isLoading.isFetching) return(
        <LoaderComponent isLoading={true} />
    )
    return (
        <div style={{maxWidth: "640px"}}>
            <ConditionalRender if={type === 'feed' && !componentData.isLoading.isFetching && jobUpdates.length === 0}>
                <div style={{textAlign: 'center', marginTop: '80px'}}>
                    <div className="text_subtitle_big">No Low Confidence results to show!</div>
                </div>
            </ConditionalRender>
            <ConditionalRender if={!componentData.isLoading.isFetching && jobUpdates.length > 0}>
            <div className='flex_container_space_between' style={{ marginTop: 16, marginBottom: -8 }}>
                <div></div>
                <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={(page) => setComponentState({ currentPage: page })} style={{ padding: 0 }} />
                <div>
                    <ConditionalRender if={type !== 'feed'}>
                        <div className='default_flex_container'>
                            <AnDropdownGroupFilter
                                filterList={dropDownFilterlist}
                                onChangeCallback={(group, option) => setComponentState({ dropDownFilterlist: { ...dropDownFilterlist, [group]: option } })}
                                group_options={TempPostFilterOptions}
                                customContainerClass={"filter_gap"}
                                customStyle={{ position: 'relative', lineHeight: 0, marginRight: 10 }}
                            >
                            </AnDropdownGroupFilter>
                            <div onClick={() => { setPopupsList({ exportPopup: true }) }} className="default_flex_container"
                                style={{ cursor: "pointer", width: "36px", height: "36px", border: '1px solid #e6e6e6', padding: '6px 6px', borderRadius: 4 }}>
                                <img src={DownloadImage} style={{ height: 20, width: 20 }}></img>
                            </div>
                        </div>
                    </ConditionalRender>
                </div>
            </div>
            {
                jobUpdates.map((jobUpdate) => {
                    let maxConfidence = jobUpdate.prospect_suggestions?.length > 0 ? Math.max(...jobUpdate.prospect_suggestions.map(item=>item.similarity)) : null;
                    return(
                        <div key={jobUpdate.id} className="article_container" style={{padding: '24px', marginTop: 24}}>
                            <div className='flex_container_space_between'>
                                {renderLinkedinProspect(jobUpdate.linkedin_prospect)}
                                <div className='default_flex_container'>  
                                    <ConditionalRender if={maxConfidence}>                 
                                        <div className='text_caption_light' style={{marginRight: 12, fontWeight: 700}}>Top confidence: <span className='text_caption_bold'>{maxConfidence}%</span></div>
                                    </ConditionalRender> 
                                    <Button innerButtonStye={{padding: 8, width: "max-content"}} onClick={() => setComponentState({selectedJobUpdate: jobUpdate, popupsList: {mapAndPostPopup: true}})}><div className="text_caption" style={{color: "white"}}>{buttonLabel}</div></Button>                                    
                                    <Tooltip
                                        tooltipText={() => jobUpdate.formatted_created_at}
                                        innerClass='text_body_light'
                                        textTheme='background-light-black top'
                                        innerStyle={{ padding: '8px', alignSelf: 'center', color: 'white', borderRadius: '8px' }}
                                        style={{  }}
                                        showWithoutOverflowing={true}
                                        tooltipPos="top"
                                        tooltTipOverlay={{x: 0,y: -7}}
                                    >
                                        <div style={{marginLeft: 12, cursor: "default"}} className='text_caption'>{jobUpdate.created_at_time}</div>
                                    </Tooltip> 
                                </div>
                            </div>
                            <div className="line_seperator_ex_light_grey" style={{margin: "16px 0px 22px"}}></div>
                            <JobUpdateDesc job_update={{...jobUpdate, name: jobUpdate.linkedin_prospect.name}} />
                        </div>
                    )
                })
            }
            </ConditionalRender>
            {popupsList.mapAndPostPopup && <MapAndPostJobPopup handleOnClose={() => setComponentState({popupsList: {}})}
                onFinishCallback={onFinishPosting}
                selectedJobUpdate={componentData.selectedJobUpdate}
            />}
            {
                componentData.successMessage && (<FlashMessages messageTheme='success' closeCallback={() => setComponentState({successMessage: ""})}>   
                    {componentData.successMessage} 
                </FlashMessages>)
            }
            {popupsList.exportPopup && exportPopup()}
        </div>
    )
}

export default UnmappedJobUpdates