import React, { useEffect, useRef, useState } from 'react'

import { Button } from '../../cta_utils/AnButton'
import ConditionalRender from '../../../ConditionalRender'
import AddProspectStep1 from './addProspectStep1'
import AddProspectStep2 from './addProspectStep2'
import AddProspectStep3 from './addProspectStep3'
import ImportHistory from './ImportHistory'
import ImportSuccess from './assets/ImportSuccess.svg'
import { Link } from 'react-router-dom'
const initial_state = {
            "id": "",
            "name": "",
            "created_at": "",
            "completed_at": null,
            "imported": 0,
            "imports_failed": 0,
            "mapped_at": null,
            "headers": [],
            "field_mappings": []
        }

const ImportProspects = () => {
    const [activeStep, setActiveStep] = useState('step_1')
    const [step2Data, setStep2Data] = useState(initial_state);
    const [step3Data, setStep3Data] = useState(initial_state);
    const [mappedFields, setMappedFields] = useState([])

    const tempShape = (
        <div style={{color: 'white', position: 'relative'}}>
            <div style={{zIndex: 100}}>
                Step1
            </div>
            <div style={{position: 'absolute', inset: 0}}>
                <div className="nGon" style={{color: 'white'}}>
                </div>
            </div>
        </div>
    )

    const ImportStatusTabs = (
        <div style={{display: 'flex'}}>
            <div className={`import_tab ${activeStep == 'step_1' ? 'active_step' : ''}`} style={{width: '33.33%'}}>
                <div className="tab_text">Step 1: Upload File</div>
                <div className="arrow_1"></div>
                <div className="arrow_2"></div>
            </div>
            <div className={`import_tab ${activeStep == 'step_2' ? 'active_step' : ''}`} style={{width: '33.33%'}}>
                <div className="tab_text">Step 2: Mapping Data</div>
                <div className="arrow_1"></div>
                <div className="arrow_2"></div>
            </div>
            <div className={`import_tab ${activeStep == 'step_3' ? 'active_step' : ''}`} style={{width: '33.33%'}}>
                <div className="tab_text">Step 3: Preview and Finish</div>
            </div>
        </div>
    )

    const renderImportHistory = () => {
        return(
            <> 
                {
                    activeStep == 'finished' ? (
                        <div style={{maxWidth: 984, width: '100%', margin: '24px auto 0px',background: 'rgba(23, 191, 99, 0.1)', border: '1px solid #17BF63', borderRadius: 4, boxSizing: 'border-box'}}>
                            <div style={{padding: 24, display: 'flex', alignItems: 'center'}}>
                                <img src={ImportSuccess} style={{width: 56}}></img>
                                <div>
                                    <div className=".text_title">
                                        Import Success!
                                    </div>
                                    <div className=".text_subtitle_semi_bold">
                                        Check the import history below. You can always view your import history from your profile dropdown.
                                    </div>
                                </div>
                            </div>
                        </div>
                    ): null
                } 
                <ImportHistory></ImportHistory>                
                {
                    activeStep == 'finished' ? (
                        <div style={{maxWidth: 984, width: '100%', margin: '24px auto'}}>
                            <div className=".text_subtitle_semi_bold">
                                You can view the list of added prospects from the <a href="/prospects" className="text_link" style={{display: 'inline', textDecoration: 'none'}}>prospects page</a>. Your news results will be updated within a day
                            </div>
                            <div style={{marginTop: 38, textAlign: 'center'}}>
                                <Link to="/" className="an_primary_button medium_btn" style={{textDecoration: 'none', padding: '8px 22px'}}>Back to NewsFeed</Link>                                
                            </div>
                        </div>
                    ) : null
                }
            </>
        )
    }
    return (
        <>
            <ConditionalRender if={activeStep != 'finished'}>
                <div className="light_grey_border_container" style={{margin: '24px auto 0px'}}>
                        <div style={{padding: 24}}>
                            <div className="text_title_big">
                                Import prospect data
                            </div>
                            <div className="text_body_big_light" style={{marginTop: 4}}>
                                Import people, organizations by simply uploading a .csv file with the data you need.
                            </div>
                        </div>
                        {ImportStatusTabs}
                    
                    <ConditionalRender if={activeStep == 'step_1'}>
                        <AddProspectStep1 setActiveStep={setActiveStep} setStep2Data={setStep2Data}/>                
                    </ConditionalRender>
                    <ConditionalRender if={activeStep == 'step_2'}>
                        <AddProspectStep2 step2Data={step2Data} setActiveStep={setActiveStep} mappedFields={mappedFields} 
                            setMappedFields={setMappedFields}
                            setStep3Data={setStep3Data}
                        />
                    </ConditionalRender>
                    <ConditionalRender if={activeStep == 'step_3'}>
                        <AddProspectStep3 setActiveStep={setActiveStep} step3Data={step3Data}/>
                    </ConditionalRender>
                </div>
            </ConditionalRender>
           {
               (activeStep == 'step_1' || activeStep == 'finished') && renderImportHistory()
           }
        </>
    )
}

export default ImportProspects