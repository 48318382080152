import React, { useState, useEffect, useReducer, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { apiAgent } from '../../../../axiosConfig'
import ConditionalRender from '../../../../ConditionalRender'
import AnPopup from '../../../cta_utils/AnPopup.js'
import {AnInputBox} from '../../../input_utils/AnInput.js'
import {Button, TabbedButton} from '../../../cta_utils/AnButton'
import {Link} from "react-router-dom";
import {AnInputCheckBox} from "../../../input_utils/AnInputCheckBox";
import Post from "../../../feed_components/Post";
import { AnInputRadioButton } from '../../../input_utils/AnInputRadioButton'
import { NameInitial } from '../../../cta_utils/AnNameInitial'
import MenuDots from "../../../../assets/MenuDots.svg";
import AnDropDown from "../../../cta_utils/AnDropDown";
import AnMultipleSuggestionBox from "../../../input_utils/AnMultipleSuggestionBox";
import ProspectShowReducer, { initialProspectShowState } from './reducers/prospectShowReducer'
import ALL_PROSPECT_SHOW_ACTIONS from './actions/prospectProfileShowActions'
import NewsFeed from '../../news_feed/news_feed'
import { isWidthDown } from '../../../utils/designUtils'
import useWindowSize from '../../../custom_hooks/useWindowSize'
import { FlashMessages } from '../../../cta_utils/FlashMessages.js'
import GoBack from '../../../cta_utils/GoBack'
import { indexOfObject } from "../../../utils/ArrayUtils.js";
import useCheckUserAccess from '../../../custom_hooks/useCheckUserAccess'
import HighConfidenceResults from '../../news_feed/high_confidence_results'
import Posts from '../../news_feed/posts'
import getApiUrl from '../../../utils/UrlHelper'
import { AddTagPopup } from '../../../cta_utils/CommonPopups.js/AddTagPopup'
import { AddAssigneePopup } from '../../../cta_utils/CommonPopups.js/AddAssigneePopup'
import { PastEducationEditPopup } from './PastEducationEditPopup.js'
import { MergeProspectPopup } from './MergeProspectPopup.js'
import { PROFILE_INFOS } from './profile_infos.js'
import { NoProspectFound } from './NoProspectFound.js'
import { PastEducation } from './PastEducation.js'
import { LoaderComponent } from '../../../utils/LoaderComponent.js'

const multiInputQuestionTypes = ['Check Box']
const ProspectShow = (props) => {

    const [profileData, profileDataDispatcher] = useReducer(ProspectShowReducer, initialProspectShowState)
    const windowDimensions = useWindowSize();
    let current_user = useSelector(state => state.current_user.user)
    let current_team = useSelector(state => state.current_team.team)
    const [responseMessage, setResponseMessage] = useState({});
    const checkUserAccess = useCheckUserAccess();
    useEffect(() => {
        if (current_team.save_to_crm_enabled) {
            let index = indexOfObject(PROFILE_INFOS, 'key', 'crm_primary_id')
            PROFILE_INFOS[index].displayable = true;
        }
        const paramsString = new URLSearchParams(window.location.search);
        let Type=paramsString.get("tabType");
        profileDataDispatcher({
            type: ALL_PROSPECT_SHOW_ACTIONS['SET_STATE'],
            payload: {tabType: Type || "profile"}
        })
    },[props.match.params.prospect_id])

    const setProspectResponse = useCallback((res, successMessage) => {
        let index = indexOfObject(PROFILE_INFOS, 'key', 'external_id')
        let should_display = PROFILE_INFOS[index].displayable
        if(!res.data.external_id && should_display){
            PROFILE_INFOS[index].displayable = false;
        }
        if(res.data.external_id && !should_display){
            PROFILE_INFOS[index].displayable = true;
        }
        let payload = {
            prospectProfile: res.data,
            tempProfile: res.data,
            currentTags: res.data.tags?.map(val => val.id) || [],
            currentAssigns: res.data.assign_ids,
            isFetching: {fetchingProspect: false},
            isLoading: false
        }
        payload.tagsList =  res.data.team_tags;
        payload.assigneeList = res.data.team_members;
        if(res.data.successMessage || successMessage){
            payload.messageTheme = 'success';
            payload.successMessage = res.data.successMessage || successMessage
        }
        profileDataDispatcher({
            type: ALL_PROSPECT_SHOW_ACTIONS['SET_STATE'],
            payload: payload
        })
    }, [])

    const fetchProspectProfile = useCallback(() => {
        profileDataDispatcher({
            type: ALL_PROSPECT_SHOW_ACTIONS['SET_STATE'],
            payload: {isFetching: {fetchingProspect: true}}
        })
        apiAgent({
            method: 'get',
            url: `/prospects/${props.match.params.prospect_id}`,
            params: {}
        }).then(res => {
            setProspectResponse(res)
        }).catch(err => {
            profileDataDispatcher({
                type: ALL_PROSPECT_SHOW_ACTIONS['SET_STATE'],
                payload: {isFetching:  {fetchingProspect: false}}
            })
        })
    }, [props.match.params.prospect_id, setProspectResponse])

    useEffect(() => {
        if(profileData.tabType == "profile"){
            fetchProspectProfile();
        }
    }, [props.match.params.prospect_id, profileData.tabType, fetchProspectProfile])        

    const setPopupsList = (popup) => {
        profileDataDispatcher({
            type: ALL_PROSPECT_SHOW_ACTIONS['SET_STATE'],
            payload: {
                popupsList: {...popup}
            }
        })
    }

    const setProspectDropdown = (status) => {
        profileDataDispatcher({
            type: ALL_PROSPECT_SHOW_ACTIONS['SET_STATE'],
            payload: {
                prospectDropdown: status
            }
        })
    }

    const handleInputChange = (e) => {
        profileDataDispatcher({
            type: ALL_PROSPECT_SHOW_ACTIONS['SET_STATE'],
            payload: {
                tempProfile:{
                    ...profileData.prospectProfile,
                    [e.target.name]: e.target.value
                }
            }
        })
    }

    const setTempProfileData = (payload) => {
        profileDataDispatcher({
            type: ALL_PROSPECT_SHOW_ACTIONS['SET_STATE'],
            payload: {
                tempProfile:{
                    ...profileData.tempProfile,
                    ...payload
                }
            }
        })
    }
    const handleProfileUpdate = () => {
        if(profileData.prospectProfile["custom_companies"]!= profileData.tempProfile["custom_companies"]){
            let other_companies = profileData.tempProfile["custom_companies"].split("\n");
            other_companies = other_companies.filter((company) => {
                return !(company.includes(profileData.tempProfile["company"]));
            }).join("\n")
            if(profileData.tempProfile["custom_companies"] != other_companies){
                setResponseMessage({type: "warning", message: "Company name already added!", firstIcon: true});
                profileData.tempProfile["custom_companies"] = other_companies
            }
        }
        profileDataDispatcher({
            type: ALL_PROSPECT_SHOW_ACTIONS['TOGGLE_LOADER'],
        })
        apiAgent({
            method: 'post',
            url: '/prospects/update',
            data: {
                ...profileData.tempProfile,
                prospect_id: profileData.prospectProfile.id
            }
        }).then(res => {
            setProspectResponse(res, "Profile updated");
            handlePopupClose()
        }).catch(err => {
            profileDataDispatcher({
                type: ALL_PROSPECT_SHOW_ACTIONS['TOGGLE_LOADER'],
            })
        })
    }

    const handleRelationshipUpdate = () => {
        profileDataDispatcher({
            type: ALL_PROSPECT_SHOW_ACTIONS['TOGGLE_LOADER'],
        })
        setResponseMessage({});
        let relationships = {"Spouse": profileData.tempProfile.spouse_relationships, "Other": profileData.tempProfile.other_relationships}
        apiAgent({
            method: 'post',
            url: '/prospects/update_relationship',
            data: {
                ...profileData.tempProfile,
                relationships: relationships,
                prospect_id: profileData.prospectProfile.id
            }
        }).then(res => {
            profileDataDispatcher({
                type: ALL_PROSPECT_SHOW_ACTIONS['SET_STATE'],
                payload: {
                    isLoading: false,
                    prospectProfile: {...res.data},
                    successMessage: "Changes saved",
                    messageTheme: 'success'
                }
            })
            handlePopupClose();
        }).catch(err => {
            profileDataDispatcher({
                type: ALL_PROSPECT_SHOW_ACTIONS['TOGGLE_LOADER'],
            })
        })
    }

    const archiveProspect = () => {
        profileDataDispatcher({
            type: ALL_PROSPECT_SHOW_ACTIONS['TOGGLE_LOADER'],
        })
        apiAgent({
            method: 'post',
            url: `/prospects/${profileData.prospectProfile.id}/archive`,
            data: {}
        }).then(res => {
            profileDataDispatcher({
                type: ALL_PROSPECT_SHOW_ACTIONS['SET_STATE'],
                payload: {
                    isLoading: false,
                    successMessage: res.data.message,
                    messageTheme: 'success'
                }
            })
        }).catch(err => {
            profileDataDispatcher({
                type: ALL_PROSPECT_SHOW_ACTIONS['TOGGLE_LOADER'],
            })
        })
    }

    const markHighFreq = () => {
        profileDataDispatcher({
            type: ALL_PROSPECT_SHOW_ACTIONS['TOGGLE_LOADER'],
        })
        apiAgent({
            method: 'post',
            url: `/prospects/${profileData.prospectProfile.id}/mark_high_freq`,
            data: {}
        }).then(res => {
            var messageTheme = res.data.success ? 'success': 'warning';
            var msg = res.data.success ? 'Prospect will be marked high frequency' : res.data.message;
            profileDataDispatcher({
                type: ALL_PROSPECT_SHOW_ACTIONS['SET_STATE'],
                payload: {
                    isLoading: false,
                    successMessage: msg,
                    messageTheme: messageTheme
                }
            })
        }).catch(err => {
            profileDataDispatcher({
                type: ALL_PROSPECT_SHOW_ACTIONS['TOGGLE_LOADER'],
            })
        })
    }
    
    const onAddedTagCallback = (payload) => {
        let newTags = profileData.tagsList.filter(tag => profileData.currentTags.indexOf(tag.id) !== -1)
        let newAssigns = payload.assign_ids || [];
        profileDataDispatcher({
            type: ALL_PROSPECT_SHOW_ACTIONS['SET_STATE'],
            payload: {
                prospectProfile: {...profileData.prospectProfile, ...payload, tags: [...newTags]},
                currentAssigns: newAssigns,
                isLoading: false,
                successMessage: "Tags updated.",
                messageTheme: "success"
            }
        })
        handlePopupClose();
    }
    const boostPopup = () => {
        return(
            <AnPopup name="boostPopup" handleOnClosePopup={handlePopupClose}>
                <div className="text_title">
                    Edit Boost
                </div>
                <div style={{marginTop: 16, marginBottom: 8, height: 1, background: '#E7ECEB'}}></div>
                <AnInputBox label="Boost">
                    <input type="text" name="boost" value={profileData.tempProfile.boost} onChange={handleInputChange}></input>
                </AnInputBox>
                <div style={{display: 'flex', marginTop: 16}}>
                    <Button onClick={handleProfileUpdate} disabled={profileData.tempProfile.boost===profileData.prospectProfile.boost} isLoading={profileData.isLoading}>Save</Button>
                    <Button type="Secondary" onClick={handlePopupClose} buttonStyle={{marginLeft: 8}}>Cancel</Button>
                </div>
            </AnPopup>
        )
    }
    const unarchiveProspect = () => {
        profileDataDispatcher({
            type: ALL_PROSPECT_SHOW_ACTIONS['TOGGLE_LOADER'],
        })
        apiAgent({
            method: 'post',
            url: `/prospects/${profileData.prospectProfile.id}/unarchive`,
            data: {}
        }).then(res => {
            window.location.reload()
        }).catch(err => {
            profileDataDispatcher({
                type: ALL_PROSPECT_SHOW_ACTIONS['TOGGLE_LOADER'],
            })
        })
    }

    const handleRelationshipData = (val) => {
      let payload = {...profileData.tempProfile}
      let item = (val.length === 0)? null : val[(val.length -1)].id
      let index = indexOfObject(val, "id", item);
      if(index !== val.length-1){
        setResponseMessage({type: "warning", message: "User already added!", firstIcon: true});
        val.pop();
        return;
      }
      if(val.length>0 && (val[val.length - 1].id === profileData.prospectProfile.id)){
        setResponseMessage({type: "warning", message: "User cannot be added!", firstIcon: true});
        val.pop();
        return;
      }
      if(profileData.activeRelationTab === 'Spouse') payload.spouse_relationships = val
      else payload.other_relationships = val
        profileDataDispatcher({
          type: ALL_PROSPECT_SHOW_ACTIONS['SET_STATE'],
          payload: {tempProfile: payload}
        })
    };

    const addRelationshipSavedisabled = useMemo(() => {
        let temp_spouse_relationships = profileData.tempProfile.spouse_relationships
        let temp_other_relationships = profileData.tempProfile.other_relationships
        let profile_spouse_relationships = profileData.prospectProfile.spouse_relationships
        let profile_other_relationships = profileData.prospectProfile.other_relationships
        if(!profile_spouse_relationships && !profile_other_relationships) return false
        if(temp_spouse_relationships?.length !== profile_spouse_relationships?.length) return false
        if(temp_other_relationships?.length !== profile_other_relationships?.length) return false
        let disabled = true
        temp_spouse_relationships.forEach((spouse) => {
            let index = indexOfObject(profile_spouse_relationships, "name", spouse.name);
            if(index === -1)
                disabled = false
        })
        temp_other_relationships.forEach((spouse) => {
            let index = indexOfObject(profile_other_relationships, "name", spouse.name);
            if(index === -1)
                disabled = false
        })
        return disabled
        
    }, [profileData.tempProfile.spouse_relationships, profileData.tempProfile.other_relationships])

    const handleCurrentAnswerToTempProfile = (value) => {
        var temp_answers = [...profileData.tempProfile.custom_answers];
        var currAnswer = {...profileData.currentAnswer}
        var temp_answer = [...currAnswer.answer]
        if(multiInputQuestionTypes.includes(profileData.currentAnswer.question_type))
        {
            var index = temp_answer.indexOf(value);
            if(index==-1) {
                temp_answer.push(value);
            }
            else{
                temp_answer.splice(index, 1)
            }
        }
        else 
        {
            temp_answer[0] = value
            temp_answer.splice(1); //to remove other elements if any because it should be a single value
        }
        
        temp_answers.forEach((answer, index) => {
            if(answer.id === currAnswer.id) {
                temp_answers[index] = {...currAnswer, answer: temp_answer};
            }
        })
        profileDataDispatcher({
            type: ALL_PROSPECT_SHOW_ACTIONS['SET_STATE'],
            payload:{
                currentAnswer: {...currAnswer, answer: temp_answer},
                tempProfile: {
                    ...profileData.tempProfile,
                    custom_answers: temp_answers
                }
            }
        })
    }

    const handlePopupClose = () => {
        setPopupsList({})
    }
    const closeFlash = () => {
        profileDataDispatcher({type: "SET_STATE", payload: {successMessage: ""}})
    }

    const renderEditItemPopup = (item) => {
        return(
            <AnPopup name='editItemPopup' handleOnClosePopup={handlePopupClose}>
                <div className="text_title">
                    Edit {item.label}
                </div>
                <div style={{marginTop: 16, marginBottom: 8, height: 1, background: '#E7ECEB'}}></div>
                <AnInputBox label={item.label}>
                { item.inputType == 'text_area' ? 
                    <textarea name={item.key} value={profileData.tempProfile[item.key]} onChange={handleInputChange}
                        placeholder={item.placeholder}
                    ></textarea> 
                    :
                    <input type="text" name={item.key} value={profileData.tempProfile[item.key]} onChange={handleInputChange}></input>
                }
                </AnInputBox>
                <div style={{display: 'flex', marginTop: 16}}>
                    <Button onClick={handleProfileUpdate} disabled={profileData.tempProfile[item.key]===profileData.prospectProfile[item.key]} isLoading={profileData.isLoading}>Save</Button>
                    <Button type="Secondary" onClick={handlePopupClose} buttonStyle={{marginLeft: 8}}>Cancel</Button>
                </div>
            </AnPopup>
        )
    }

    const renderCustomQuestion = () => {
        if(profileData.currentAnswer.question_type == 'Radio Button'){
            return(
                profileData.currentAnswer.question_options.map(option =>{
                    const currAnschecked = profileData.currentAnswer.answer?.includes(option);
                    return (
                        <AnInputRadioButton key={option} label={option} style={{marginBottom: 12}}>
                            <input type="radio" name="member" id={option} style={{marginRight: 6}} value={option}
                                    checked={currAnschecked} onChange={(e) => {handleCurrentAnswerToTempProfile(e.target.value)}}/>
                        </AnInputRadioButton>
                    )
                })
            );
        }
        else if(profileData.currentAnswer.question_type == 'Check Box'){
            return(
                profileData.currentAnswer.question_options.map(option =>{
                    const currAnschecked = profileData.currentAnswer.answer?.includes(option);
                    return (
                    <AnInputCheckBox key={option} label={option}
                        isChecked={currAnschecked} style={{marginBottom: 16}}>
                        <input type="checkbox" value={option}
                        checked={currAnschecked} onChange={(e) => {handleCurrentAnswerToTempProfile(e.target.value)}}/>
                    </AnInputCheckBox>
                    )
                })
            );
        }
        else if(profileData.currentAnswer.question_type == 'Select Box'){
            return(
                <AnInputBox>
                    <select value={profileData.currentAnswer.answer[0]} onChange={(e) => {handleCurrentAnswerToTempProfile(e.target.value)}}>
                        {profileData.currentAnswer.question_options.map((answer) => (
                            <option value={answer}>{answer}</option>
                        ))}
                    </select>
                </AnInputBox>
            );
        }
        else if(profileData.currentAnswer.question_type == 'Text Area'){
            return(
                <AnInputBox label={profileData.currentAnswer.question}>
                    <textarea type="text" name="title" value={profileData.currentAnswer.answer[0]} onChange={(e) => {handleCurrentAnswerToTempProfile(e.target.value)}} />
                </AnInputBox>
            );
        }
        else{
            return(
                <AnInputBox label={profileData.currentAnswer.question}>
                    <input type="text" name="title" value={profileData.currentAnswer.answer[0]} onChange={(e) => {handleCurrentAnswerToTempProfile(e.target.value)}}></input>
                </AnInputBox>
            );
        }
    }
    const submitBtnDisabled = useMemo(() => {
        if(profileData.prospectProfile.custom_answers === undefined || profileData.prospectProfile.custom_answers === null) return false
        let custom_answers = [...profileData.prospectProfile.custom_answers]
        let index = indexOfObject(custom_answers, "id", profileData.currentAnswer.id)
        let currentAnswer = [...profileData.currentAnswer?.answer]
        let profileCurrentAnswer = [...custom_answers[index]?.answer]
        let disabled = true
        currentAnswer.forEach((answer) => {
            if(!profileCurrentAnswer.includes(answer)){
                disabled = false
            }
        })
        return disabled
    }, [profileData.currentAnswer.answer])
    const renderEditCustomAnswerPopup = () => {
        return(
            <AnPopup name="editTitlePopup" handleOnClosePopup={handlePopupClose}>
                <div className="text_title">
                    Edit {profileData.currentAnswer.question}
                </div>
                <div style={{marginTop: 16, marginBottom: 8, height: 1, background: '#E7ECEB'}}></div>
                {renderCustomQuestion()}
                <div style={{display: 'flex', marginTop: 16}}>
                    <Button onClick={handleProfileUpdate} disabled={submitBtnDisabled} isLoading={profileData.isLoading}>Save</Button>
                    <Button type="Secondary" onClick={handlePopupClose} buttonStyle={{marginLeft: 8}}>Cancel</Button>
                </div>
            </AnPopup>
        )
    }

    const addRelationshipPopup = () => {
        return(
            <AnPopup name="addRelationshipPopup" handleOnClosePopup={handlePopupClose}>
                <div className="text_title" style={{paddingBottom: '16px'}}>
                    Edit Relationships
                </div>
                <div style={{padding: '0px 4px', borderTop: '1px solid #E5E5E5', display: 'flex'}} className="show-tabs">
                    <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                        <TabbedButton buttonStylestyle={{padding: '12px 16px'}} isActive={profileData.activeRelationTab==='Spouse'} 
                        onClick={() => profileDataDispatcher({
                            type: ALL_PROSPECT_SHOW_ACTIONS['SET_STATE'],
                            payload: {activeRelationTab: "Spouse"}
                        })}>
                            Spouse
                        </TabbedButton>
                        <TabbedButton isActive={profileData.activeRelationTab === 'Others'} 
                        onClick={() => profileDataDispatcher({
                            type: ALL_PROSPECT_SHOW_ACTIONS['SET_STATE'],
                            payload: {activeRelationTab: "Others"}
                        })}>
                            Others
                        </TabbedButton>
                    </div>
                </div>
                <div style={{ maxWidth: 500, marginTop: '18px' }}>
                    <AnMultipleSuggestionBox
                        callback={handleRelationshipData}
                        suggestionUrl="/prospects/search?team_search=true"
                        placeholder="Search Prospects"
                        inputClass="an_search_box"
                        name="Prospect_search"
                        theme="modern"
                        selectedOptions={(profileData.activeRelationTab==='Spouse' ? profileData.tempProfile.spouse_relationships : profileData.tempProfile.other_relationships) || []}
                    />
                    <div style={{display: 'flex', marginTop: 16}}>
                        <Button onClick={handleRelationshipUpdate} isLoading={profileData.isLoading} disabled={addRelationshipSavedisabled}>Save</Button>
                        <Button type="Secondary" onClick={handlePopupClose} buttonStyle={{marginLeft: 8}}>Cancel</Button>
                    </div>
                </div>
            </AnPopup>
        )
    }

    const renderProspectDropdown = () => {
        return(
            <AnDropDown style={{position: 'absolute', right: 0, padding: '12px 20px', background: 'white', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', borderRadius: 4, width: 'max-content', zIndex: 99}}
                        toggleDropDown={() => setProspectDropdown(false)}>
                <div className='extra_option_container'>
                <ConditionalRender if={checkUserAccess("merge_prospect")}>
                    <div style={{cursor: 'pointer'}} onClick={() => setPopupsList({mergeProspectPopup: true})}>
                        Merge prospect
                    </div>
                </ConditionalRender>
                <ConditionalRender if={checkUserAccess("prospect_archiving")}>
                    <div style={{cursor: 'pointer'}} onClick={() => {archiveProspect()}}>
                        Archive
                    </div>
                </ConditionalRender>
                <ConditionalRender if={current_user.is_super_admin || current_user.is_publisher}>
                    <div style={{cursor: 'pointer'}} onClick={() => {setPopupsList({boostPopup: true})}}>
                        Boost
                    </div>
                    <ConditionalRender if={current_team.high_freq_enabled && current_user.is_super_admin}>
                        <div style={{cursor: 'pointer'}} onClick={() => {markHighFreq()}}>
                            Mark High Freq
                        </div>
                    </ConditionalRender>
                </ConditionalRender>
                </div>
            </AnDropDown>
        )
    }

    const renderProfileTabMobileView = () => (
        <div style={{background: '#FFFFFF', padding: 24, maxWidth: 640, margin: '24px auto 84px', border: '1px solid #E5E5E5', boxSizing: 'border-box', borderRadius: 8}}>
            <div className="text_subtitle_big" style={{marginBottom: 16}}>
                Profile Information
            </div>
            <div style={{marginTop: 8}}>
                {PROFILE_INFOS.map(item => {
                    return (
                    item.key === 'past_educations' ?
                    (<div style={{ borderBottom: '1px solid #E7ECEB' }}>
                        <span className="text_caption_light" style={{  fontWeight: 700, textTransform: 'uppercase' }}>{item.label}</span>
                        <div className="text_label gap_10_container" style={{  padding: '4px 0px 8px', border: 'none' }}>{
                            profileData.prospectProfile?.past_educations.map(edu => {
                                return (
                                    <div className='flex_container_space_between'>
                                        <div>
                                            <div>{edu.institute_name}</div>
                                            <div className='text_body_light'>
                                                {edu.course}{edu.branch && <span>, </span>}
                                                {edu.branch}{edu.from_year && <span>, </span>}
                                                {edu.from_year}{edu.to_year && <span> - </span>}
                                                {edu.to_year}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        </div>
                        <ConditionalRender if={(current_user.is_super_admin || current_team.is_admin) && item.editable}>
                            <div className='flex_container_space_between'>
                                <div></div>
                                <div className="text_label" style={{ cursor: 'pointer' }}
                                    onClick={() => { setPopupsList({ editPastEducationPopup: true }) }}>
                                    <img src="/edit.svg" />
                                </div>
                            </div>
                        </ConditionalRender>
                    </div>)
                    :
                    (<div style={{ borderBottom: '1px solid #E7ECEB' }}>
                        <span className="text_caption_light" style={{ fontWeight: 700, textTransform: 'uppercase' }}>{item.label}</span>
                        <div className="flex_container_space_between text_label" style={{ padding: '4px 0px 8px' }}>
                            <span>{profileData.prospectProfile[item.key]}</span>
                            <ConditionalRender if={current_user.is_super_admin || current_team.is_admin}>
                                <span className="text_label" style={{ cursor: 'pointer' }}
                                    onClick={() => { setPopupsList({ [item.key]: true }) }}>
                                    <img src="/edit.svg" />
                                </span>
                            </ConditionalRender>
                        </div>
                    </div>)
                    )
                    }
                    )
                }
                <div style={{borderBottom: '1px solid #E7ECEB'}}>
                    <span className="text_caption_light" style={{ fontWeight: 700, textTransform:'uppercase'}}>ASSIGNED TAGS</span>
                    <div className="flex_container_space_between text_label" style={{padding: '4px 0px 8px'}}>
                        <span>{profileData.prospectProfile.tags.map(tag => tag.name).join(", ")} </span>
                        <ConditionalRender if={current_user.is_super_admin || current_team.is_admin}>
                                <span className="text_label" style={{  cursor: 'pointer'}}
                                    onClick={() => {setPopupsList({addTagPopup: true})}}>
                                    <img src="/edit.svg" />
                                </span>
                        </ConditionalRender>
                    </div>
                </div>
                <div style={{borderBottom: '1px solid #E7ECEB'}}>
                    <span className="text_caption_light" style={{ fontWeight: 700, textTransform:'uppercase'}}>ASSIGNED TEAM MEMBERS</span>
                    <div className="flex_container_space_between text_label" style={{ padding: '4px 0px 8px'}}>
                        <span>{profileData.prospectProfile.assigns.map(assign => assign.name).join(", ")}</span>
                        <ConditionalRender if={current_user.is_super_admin || current_team.is_admin}>
                                <span className="text_label" style={{ cursor: 'pointer'}}
                                    onClick={() => {setPopupsList({addAssigneePopup: true})}}>
                                    <img src="/edit.svg" />
                                </span>
                        </ConditionalRender>
                    </div>
                </div>
                {
                    profileData.prospectProfile.custom_answers.map(answer => (
                        <div key={answer.id} style={{borderBottom: '1px solid #E7ECEB'}}>
                            <span className="text_caption_light" style={{ fontWeight: 700, textTransform:'uppercase'}}>{answer.question}</span>
                            <div className="text_label" style={{display: 'flex', justifyContent: answer.answer ? 'space-between' : 'end', wordBreak: 'break-all', padding: '4px 0px 8px'}}>{answer.answer?.join(', ')}
                            <ConditionalRender if={current_user.is_super_admin || current_team.is_admin}>
                                <span className="text_label" style={{  cursor: 'pointer'}}
                                    onClick={() => {
                                        profileDataDispatcher({
                                            type: ALL_PROSPECT_SHOW_ACTIONS['SET_STATE'],
                                            payload: {
                                                currentAnswer: answer,
                                                popupsList: {editCustomAnswerPopup: true}
                                            }
                                        })
                                    }}>
                                    <img src="/edit.svg" />
                                </span>
                            </ConditionalRender>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )

    const renderProfileTab = () => (
        <div style={{background: '#FFFFFF', padding: 24, maxWidth: 640, margin: '24px auto 0px', border: '1px solid #E5E5E5', boxSizing: 'border-box', borderRadius: 8}}>
            <div className="text_subtitle_big">
                Profile Information
            </div>
            <div style={{marginTop: 24}}>
                <table>
                    <colgroup>
                        <col span="1" style={{width: "33%"}} />
                        <col span="1" style={{width: "60%"}} />
                        <col span="1" style={{width: "7%"}} />
                    </colgroup>
                    <tbody>
                        {PROFILE_INFOS.map(item => 
                        { 
                            let value = item.key ==='custom_companies' ? profileData.prospectProfile[item.key]?.replaceAll("\n", ", ") : profileData.prospectProfile[item.key]
                            return (  
                            <ConditionalRender if={item.displayable}>
                                {item.key === 'past_educations' ? 
                                (
                                    <tr style={{ borderBottom: '1px solid #E7ECEB', verticalAlign: 'top' }}>
                                    <td className="text_caption_light" style={{ padding: '16px 0px', border: 'none', fontWeight: 700, textTransform: 'uppercase' }}>{item.label}</td>
                                    <td className="text_label gap_10_container" style={{ padding: '16px 0px', border: 'none' }}>{
                                        profileData.prospectProfile.past_educations?.map(edu => {
                                            return(
                                                <div>
                                                    <PastEducation edu={edu}/>
                                                </div>
                                            )
                                        } )
                                    }
                                    </td>
                                    <ConditionalRender if={(current_user.is_super_admin || current_team.is_admin) && item.editable}>
                                    <td className="text_label" style={{padding: '16px 0px', border: 'none', textAlign: 'right', cursor: 'pointer' }}
                                        onClick={() => {setPopupsList({ editPastEducationPopup: true}) }}>
                                        <img src="/edit.svg" />
                                    </td>
                                    </ConditionalRender>
                                </tr>
                                ) 
                                :       
                                <tr id="edit_info" key={item.key} name={item.key} style={{borderBottom: '1px solid #E7ECEB', wordBreak: 'break-all'}}>
                                    <td className="text_caption_light" style={{padding: '16px 0px', border: 'none', fontWeight: 700, textTransform:'uppercase'}}>{item.label}</td>
                                    <td className="text_label" style={{padding: '16px 0px', border: 'none'}}>{value}</td>
                                    <ConditionalRender if={(current_user.is_super_admin || current_team.is_admin) && item.editable}>
                                        <td className="text_label" style={{padding: '16px 0px', border: 'none', textAlign: 'right', cursor: 'pointer'}}
                                            onClick={() => {setPopupsList({[item.key]: true})}}>
                                            <img src="/edit.svg" />
                                        </td>
                                    </ConditionalRender>
                                </tr>
                            }
                            </ConditionalRender>
                        )})}
                    <tr style={{borderBottom: '1px solid #E7ECEB'}}>
                        <td className="text_caption_light" style={{padding: '16px 0px', border: 'none', fontWeight: 700, textTransform:'uppercase'}}>ASSIGNED TAGS</td>
                        <td className="text_label" style={{padding: '16px 0px', border: 'none'}}>{profileData.prospectProfile.tags.map(tag => tag.name).join(", ")}</td>
                        <ConditionalRender if={current_user.is_super_admin || current_team.is_admin}>
                            <td className="text_label" style={{padding: '16px 0px', border: 'none', textAlign: 'right', cursor: 'pointer'}}
                                onClick={() => {setPopupsList({addTagPopup: true})}}>
                                <img src="/edit.svg" />
                            </td>
                        </ConditionalRender>
                    </tr>
                    <tr style={{borderBottom: '1px solid #E7ECEB'}}>
                        <td className="text_caption_light" style={{padding: '16px 0px', border: 'none', fontWeight: 700, textTransform:'uppercase'}}>ASSIGNED TEAM MEMBERS</td>
                        <td className="text_label" style={{padding: '16px 0px', border: 'none'}}>{profileData.prospectProfile.assigns.map(assign => assign.name).join(", ")}</td>
                        <ConditionalRender if={current_user.is_super_admin || current_team.is_admin}>
                            <td className="text_label" style={{padding: '16px 0px', border: 'none', textAlign: 'right', cursor: 'pointer'}}
                                onClick={() => {setPopupsList({addAssigneePopup: true})}}>
                                <img src="/edit.svg" />
                            </td>
                        </ConditionalRender>
                    </tr>
                    <tr style={{borderBottom: '1px solid #E7ECEB'}}>
                        <td className="text_caption_light" style={{padding: '16px 0px', border: 'none', fontWeight: 700, textTransform:'uppercase'}}>RELATIONSHIPS</td>
                        <td className="text_label" style={{padding: '16px 0px', border: 'none'}}>
                          <ConditionalRender if={profileData.prospectProfile.spouse_relationships.length>0 || profileData.prospectProfile.other_relationships.length>0}>
                            <div style={{display: 'flex'}}>
                                <div className="text_caption_light" style={{display: 'inline', paddingRight: '6px', paddingTop: '2px'}}>Spouse: </div>
                                <div>{profileData.prospectProfile.spouse_relationships && profileData.prospectProfile.spouse_relationships.length>0 && profileData.prospectProfile.spouse_relationships.map((item,index)=>{
                                    return  <Link className="text_link" style={{textDecoration: "none"}} to={"/prospects/"+item.id} target="_blank">{index>=1 && ",  "}{item.name}</Link>
                                })}</div>
                            </div>
                            <tr>
                                <div style={{display: 'flex'}}>
                                    <div className="text_caption_light" style={{display: 'inline',paddingRight: '14px', paddingTop: '2px'}}>Other: </div>
                                    <div>{profileData.prospectProfile.other_relationships && profileData.prospectProfile.other_relationships.length>0 && profileData.prospectProfile.other_relationships.map((item,index)=>{
                                        return <Link className="text_link" style={{textDecoration: "none"}} to={"/prospects/"+item.id} target="_blank">{index>=1 && ",  "}{item.name}</Link>
                                    })}</div>
                                </div>
                            </tr>
                            </ConditionalRender>
                            <ConditionalRender if={profileData.prospectProfile.spouse_relationships.length==0 && profileData.prospectProfile.other_relationships.length==0}>
                                <div className="text_caption" style={{display: 'inline', fontSize: '14px'}}> Not Added Yet</div>
                            </ConditionalRender>
                        </td>
                        <ConditionalRender if={current_user.is_super_admin || current_team.is_admin}>
                            <td className="text_label" style={{padding: '16px 0px', border: 'none', textAlign: 'right', cursor: 'pointer'}}
                                onClick={() => {setPopupsList({addRelationshipPopup: true})}}>
                                <img src="/edit.svg" />
                            </td>
                        </ConditionalRender>
                    </tr>
                    {
                        profileData.prospectProfile.custom_answers.map(answer => (
                            <tr key={answer.id} style={{borderBottom: '1px solid #E7ECEB'}}>
                                <td className="text_caption_light" style={{padding: '16px 0px', border: 'none', fontWeight: 700, textTransform:'uppercase'}}>{answer.question}</td>
                                <td className="text_label" style={{wordBreak: 'break-all', padding: '16px 0px', border: 'none'}}>{answer.answer?.join(', ')}</td>
                                <ConditionalRender if={current_user.is_super_admin || current_team.is_admin}>
                                    <td className="text_label" style={{padding: '16px 0px', border: 'none', textAlign: 'right', cursor: 'pointer'}}
                                        onClick={() => {
                                            profileDataDispatcher({
                                                type: ALL_PROSPECT_SHOW_ACTIONS['SET_STATE'],
                                                payload: {
                                                    currentAnswer: answer,
                                                    popupsList: {editCustomAnswerPopup: true}
                                                }
                                            })
                                        }}>
                                        <img src="/edit.svg" />
                                    </td>
                                </ConditionalRender>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
        </div>
    )

    const renderProspectFeed = () => {
        return(
            <div style={{maxWidth: 640, margin: '24px auto 0px'}}>
                <NewsFeed
                    lowConfidenceUrl={`/prospects/${props.match.params.prospect_id}/aggregates`}
                    highConfidenceUrl={`/prospects/${props.match.params.prospect_id}/feed`}
                    displayFilterSection={false}
                    displaySideColumn={false}
                    filtersInUrl={false}
                />
            </div>
        )
    }
    const renderProspectSavedPosts = () => {    
        return (
            <div style={{maxWidth: 640, margin: '24px auto 0px'}}>
                <Posts 
                    fetchUrl={getApiUrl("get_saved_posts", {":id": props.match.params.prospect_id})}
                    from={'saved_posts'}
                >
                </Posts>
            </div>
        )
    }

    return (
        <div style={{maxWidth: 640, margin: 'auto'}}>
            <ConditionalRender if={profileData.isFetching.fetchingProspect}>
                <LoaderComponent isLoading={true}></LoaderComponent>
            </ConditionalRender>
            <ConditionalRender if={!profileData.isFetching.fetchingProspect}>
            {!('id' in profileData.prospectProfile) ? <NoProspectFound/> : (<div>
            <GoBack className="default_flex_container text_link hover_under_line" arrow_color={"green"} style={{margin: "24px 0px 10px"}} text="Back"/>
            <div className='prospect_profile_container'>
                <ConditionalRender if={profileData.prospectProfile.archived}>
                    <div className='archived_label'>
                        <span className='text_caption' style={{padding: '4px 10px', fontWeight: 700}}>Archived</span>
                    </div>
                </ConditionalRender>
                <div style={{padding: 24, display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex'}}>
                        <NameInitial name={profileData.prospectProfile.name || profileData.prospectProfile.company} 
                        width={80} 
                        textStyle={{color: '#4D99DF', fontWeight: 600, fontSize: 32}}
                        profile_image={profileData.prospectProfile.profile_image}
                        ></NameInitial>
                        <div style={{marginLeft: 12}}>
                            <div className="text_title">
                                {profileData.prospectProfile.name || profileData.prospectProfile.company}
                            </div>
                            <div className="text_body_light">
                                {profileData.prospectProfile.name && profileData.prospectProfile.company}
                            </div>
                            <div className="text_caption_light" style={{marginTop: 10}}>
                                Prospect ID: {profileData.prospectProfile.id}
                            </div>
                        </div>
                    </div>
                    {
                        !profileData.prospectProfile.archived ?
                            (
                                <ConditionalRender if={checkUserAccess("merge_prospect") || checkUserAccess("prospect_archiving")}>
                                <span style={{position: 'relative'}} onClick={() => setProspectDropdown(true)}>
                                    <img src={MenuDots} style={{cursor: 'pointer'}}/>
                                    {profileData.prospectDropdown && renderProspectDropdown()}
                                </span>
                                </ConditionalRender>
                            )
                            :
                            (
                                <Button buttonSize="LessPadding" type="Secondary" isLoading={profileData.isLoading} onClick={unarchiveProspect} buttonStyle={{float: 'right', marginLeft: 16}}>
                                    Unarchive
                                </Button>
                            )
                    }
                </div>
                <div style={{padding: '0px 24px', borderTop: '1px solid #E5E5E5', display: 'flex'}} className="show-tabs">
                    <div className= {profileData.tabType==='profile' ?'active-tab show_tab_container':'show_tab_container'}
                        onClick={() => profileDataDispatcher({
                            type: ALL_PROSPECT_SHOW_ACTIONS['SET_STATE'],
                            payload: {tabType: "profile"}
                        })}
                    >
                        Profile
                    </div>
                    <div className={profileData.tabType==='feed' ?'active-tab show_tab_container':'show_tab_container'}
                        onClick={() => profileDataDispatcher({
                            type: ALL_PROSPECT_SHOW_ACTIONS['SET_STATE'],
                            payload: {tabType: "feed"}
                        })}
                    >
                        Newsfeed
                    </div>
                    <div className={profileData.tabType==='saved_posts' ?'active-tab show_tab_container':'show_tab_container'}
                        onClick={() => profileDataDispatcher({
                            type: ALL_PROSPECT_SHOW_ACTIONS['SET_STATE'],
                            payload: {tabType: "saved_posts"}
                        })}
                    >
                        Saved
                    </div>
                </div>
            </div>
            {
                profileData.tabType === 'profile' && (isWidthDown(windowDimensions.width, "md") ? renderProfileTabMobileView() : renderProfileTab())
            }
            {
                profileData.tabType === 'feed' && renderProspectFeed()
            }
            {
                profileData.tabType === 'saved_posts' && renderProspectSavedPosts()
            }
            {
                PROFILE_INFOS.map(item => 
                profileData.popupsList[item.key] && renderEditItemPopup(item)
                )
            }
            {
                profileData.popupsList.editCustomAnswerPopup && renderEditCustomAnswerPopup()
            }
            {
                profileData.popupsList.addTagPopup && (
                    <AddTagPopup
                        prospectData={{
                            tagsList: profileData.tagsList, 
                            currentTags: profileData.currentTags, 
                            profileTags: profileData.prospectProfile.tags,
                            prospect_id: props.match.params.prospect_id,
                            isLoading: profileData.isLoading
                        }}
                        dispatcher={(payload) => profileDataDispatcher({
                            type: ALL_PROSPECT_SHOW_ACTIONS['SET_STATE'],
                            payload: payload
                        })}
                        handlePopupClose={handlePopupClose}
                        onAddedTagCallback={onAddedTagCallback}
                    >
                    </AddTagPopup>
                )
            }
            {
                profileData.popupsList.editPastEducationPopup && (
                    <PastEducationEditPopup
                    handlePopupClose={handlePopupClose}
                    profileData={profileData}
                    profileDataDispatcher={profileDataDispatcher}
                    />
                )
            }
            {
                profileData.popupsList.addAssigneePopup && (
                    <AddAssigneePopup
                    prospectData={{
                        assigneeList: profileData.assigneeList, 
                        currentAssigns: profileData.currentAssigns, 
                        profileAssigns: profileData.prospectProfile.assigns,
                        prospect_id: props.match.params.prospect_id,
                        isLoading: profileData.isLoading,
                        lockedAssigns: profileData.prospectProfile.locked_assigns
                    }}
                    dispatcher={(payload) => profileDataDispatcher({
                        type: ALL_PROSPECT_SHOW_ACTIONS['SET_STATE'],
                        payload: payload
                    })}
                    handlePopupClose={handlePopupClose}
                    onAddedAssigneeCallback={() => window.location.reload()}
                >
                </AddAssigneePopup>
                )
            }
            {
                profileData.popupsList.addRelationshipPopup && addRelationshipPopup()
            }
            {
                profileData.popupsList.boostPopup && boostPopup()
            }
            {
                profileData.popupsList.mergeProspectPopup && <MergeProspectPopup handlePopupClose={handlePopupClose} prospect_profile={profileData.prospectProfile} setSuccessMessageCallback={(msg)=>profileDataDispatcher({
                    type: ALL_PROSPECT_SHOW_ACTIONS['SET_STATE'],
                    payload: {
                        successMessage: msg,
                        messageTheme: 'success'
                    }
                }) }/>
            }
            {
              profileData.successMessage && <FlashMessages messageTheme={profileData.messageTheme} closeCallback={closeFlash} >{profileData.successMessage}</FlashMessages>
            }      
            {
                responseMessage.type && (<FlashMessages closeCallback={() => setResponseMessage({})} messageTheme={responseMessage.type} firstIcon={responseMessage.firstIcon} style={{zIndex: 99}}>{responseMessage.message}</FlashMessages>)
            }      
            </div>)}
            </ConditionalRender>
        </div>
    )
}

export default ProspectShow
