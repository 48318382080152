import { Button } from "./AnButton";
import AnPopup from "./AnPopup";

export const AnConfirmPopup = ({
    name="data",
    onSubmitCallback,
    onCancelCallback,
    confirm_message,
    btnLabels={submitLabel: 'Export', cancelLabel: 'Cancel'},
    ...props
}) => {
    let message = confirm_message || `Do you want to export ${name}`
    let {submitLabel, cancelLabel} = btnLabels
    return(
        <AnPopup name="exportPopup" handleOnClosePopup={() => {onCancelCallback();}} maxWidth={480}>
          <div className="text_title" style={{marginBottom: 16, textAlign: 'center'}}>{message}</div>
          
          <div style={{display: 'flex', marginTop: 16, alignContent: 'center', justifyContent: 'center'}}>
            <Button buttonSize="Medium" buttonStyle={{width: 'max-content'}} innerButtonStye={{padding: '8px 20px'}}
                    onClick={() => {onSubmitCallback()}}>{submitLabel}</Button>
            <Button type="Secondary" buttonSize="Medium" buttonStyle={{width: 'max-content', marginLeft: 16}} innerButtonStye={{padding: '8px 20px'}}
                    onClick={() => {onCancelCallback();}}>{cancelLabel}</Button>
          </div>
        </AnPopup>)
}