import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ConditionalRender from "../../../ConditionalRender";
import { apiAgent } from "../../../axiosConfig";
import useCheckUserAccess from "../../custom_hooks/useCheckUserAccess";
import AnPopup from "../AnPopup";
import { AnInputCheckBox } from "../../input_utils/AnInputCheckBox";
import { Button } from "../AnButton";
import { useMemo } from "react";
import { indexOfObject } from "../../utils/ArrayUtils";

export const AddTagPopup = ({
    prospectData,
    dispatcher,
    handlePopupClose,
    onAddedTagCallback,
    ...props
}) => {
    const checkUserAccess = useCheckUserAccess();
    let {tagsList, currentTags, prospect_id, isLoading, profileTags} = prospectData;
    const submitBtnDisabled = useMemo(() => {
        if(profileTags?.length !== currentTags?.length) return false
        currentTags.forEach((tag) => {
            let index = indexOfObject(profileTags, "id", tag.id);
            if(index === -1)
                return false
        })
        return true
    }, [currentTags])
    const onAddTags = () => {
        dispatcher({isLoading: true})
        apiAgent({
            method: 'post',
            url: '/prospects/add_tags',
            data: {
            prospect_id: prospect_id,
            tag_ids: currentTags
        }
        }).then(res => {
            onAddedTagCallback(res.data);
        }).catch(err => {
            dispatcher({isLoading: false})
        })
    }
    const handleTagsChange = (e) => {
        let val = e.target.value
        let index = currentTags.indexOf(val)
        let temp = []
        if(index===-1) {
          temp = [...currentTags, val]
        }
        else{
          temp = [...currentTags]
          temp.splice(index, 1)
        }
        dispatcher({currentTags: temp})
      }

    return(
        <AnPopup name="addTagPopup" handleOnClosePopup={handlePopupClose}>
            <div className="flex_container_space_between">
                <div className="text_title">
                    Add Tags
                </div>
                <ConditionalRender if={checkUserAccess('tag_creating')}>
                <Link className="text_link hover_under_line" to="/manage/tags">
                    +Create Tag
                </Link>
                </ConditionalRender>
            </div>
            <div style={{height: 1, background: '#E7ECEB', margin: '16px 0px'}}></div>
            {
              tagsList.length > 0 ? (<div className="text_body_light" style={{marginBottom: 12}}>Available Tags</div>)
                : (<div style={{textAlign: 'center', margin: '64px 0px 64px'}} className="text_subtitle">No Tags are present.</div>)
            }
            {
              tagsList.map((tag, index) => {
                let is_checked = currentTags.includes(tag.id)
                return (
                    <AnInputCheckBox label={tag.name} isChecked={is_checked} style={{marginBottom: 12}}>
                        <input type="checkbox" value={tag.id} checked={is_checked} onChange={handleTagsChange}/>
                    </AnInputCheckBox>
                )
            })
            }
            <ConditionalRender if={tagsList.length > 0}>
            <div style={{display: 'flex', marginTop: 4}}>
                <Button buttonSize="Medium" innerButtonStye={{padding: '8px 36px'}}
                    onClick={onAddTags} isLoading={isLoading} disabled={tagsList.length==0 || submitBtnDisabled}>Add</Button>
                <Button buttonSize="Medium" type="Secondary" buttonStyle={{marginLeft: 20}} innerButtonStye={{padding: '8px 28px'}}
                    onClick={handlePopupClose} isLoading={false}>Cancel</Button>
            </div>
            </ConditionalRender>
        </AnPopup>
    )
}