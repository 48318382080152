import React, { useEffect } from "react";
import { Button } from "../cta_utils/AnButton";
import { Link } from "react-router-dom";
import ConditionalRender from "../../ConditionalRender";
import { Augsburg, Bryant, Carnegie, Colorado, Marquette, Ryerson, University_of_liverpool, Rosehul, Pingry, Willamette, LSUShreveport, University_of_NH, Desmoines, iit_delhi, iit_kgp, iima, bits_pilani,
  continueArrow, UploadProspect, AI, Notify, play_button, Find_hidden, Get_your_timing, Share_in_community, Save_time_and_effort, 
  Personal_news, Import_const, Tags, Email_notif, Share_news, Intergrations, Try_out_the_free_version, Almaconnect_news, Google_alerts,
  Data_protection, Distributed_servers, Gdpr_compliant, Internal_data_sec_policies
} from "./news_landing_assets";
import TableHeads from "../visualizers/tabular/TableHeads";
import TableRows from "../visualizers/tabular/TableRows";
import { isWidthDown, isWidthUp } from "../utils/designUtils";
import useWindowSize from "../custom_hooks/useWindowSize.js";
import AnPopup from "../cta_utils/AnPopup";
import { useState } from "react";
import TestimonialsCarousel from "../views/landing/TestimonialsCarousel";
import { SecuritySection } from "./security_section";
import { RecordGA } from "../utils/RecordGA";
import { AmplitudeEventTrack } from "./amplitude_event_track";
import { trusted_by_the_best } from "./clients_assets.js";
const AlmaNewsLanding = () => {
  const windowsDimensions = useWindowSize();
  const [popupsList, setPopupsList] = useState({});
  const learn_more_embedded_link = "//www.youtube.com/embed/UL97nr6ZeXg"
  const almanews_calendly = "https://calendly.com/almaconnect-news-scanner/custom-demo"

  const how_it_works = {
    Upload: {
      asset: UploadProspect,
      title: "1. Upload list of Prospects",
      desc: "Upload the list of prospects you'd like to track news for. You can add them in a bulk by uploading a csv or add individually.",
      url: { key: "", label: "" },
    },
    AI: {
      asset: AI,
      title: "2. AI powered algorithms scan news sources",
      desc: "Our AI searches for news mentions of these prospects in more than 400,000+ sources.",
      url: { key: "", label: "",
      },
    },
    Notify: {
      asset: Notify,
      title: "3. Get notified with news articles ",
      desc: "You get news results featuring your prospects in your feed and also a mail based on the frequency set by you.",
      url: { key: "", label: "" },
    },
  };

  const seize_opp = {
    Find_hidden: {
      asset: { source: Find_hidden, style: { width: 40, height: 40 } },
      title: "Find hidden prospects",
      desc: "Prospects featured in news are 5x more likely to respond to requests for donations and sponsorships",
    },
    Get_your_timing: {
      asset: { source: Get_your_timing, style: { width: 50, height: 40 } },
      title: "Get your timing right",
      desc: "Reaching out with a topical message to congratulate a prospect strengthens the bond",
    },
    Share_in_community: {
      asset: { source: Share_in_community, style: { width: 60, height: 40 } },
      title: "Share in your community",
      desc: "Current students, faculty, as well as other alumni are proud to see an alum being felicitated",
    },
    Save_time_and_effort: {
      asset: { source: Save_time_and_effort, style: { width: 30, height: 40 } },
      title: "Save time and effort",
      desc: "Process for identifying relevant alumni for campus events, mentorship programs, job placement becomes faster, cheaper and better",
    },
  };

  const AlmaNews_features = {
    Personal_news: {
      asset: Personal_news,
      title: "Personalized news feed",
      desc: "Browse through the news articles on a constantly updating personalized news feed",
    },
    Import_const: {
      asset: Import_const,
      title: "Import constituents",
      desc: "Add prospects either individually or in bulk by importing them from a CSV file",
    },
    Tags: {
      asset: Tags,
      title: "Tags",
      desc: "Add tags to your prospects to keep track on them",
    },
    Email_notif: {
      asset: Email_notif,
      title: "Email Notifications",
      desc: "Set your personalized email notification frequency and other alert settings.",
    },
    Share_news: {
      asset: Share_news,
      title: "Share news",
      desc: "Current students, faculty, as well as other alumni are proud to see an alum being felicitated",
    },
    Intergrations: {
      asset: Intergrations,
      title: "Integrations",
      desc: "Connect with your CRM to directly update prospect records.",
    },
  };

  const security = {
    Data_protection: {
      asset: Data_protection,
      title: "Data Protection",
      desc: "We value your privacy and are committed to protecting your information. Our platform has been designed and built using ‘privacy-by-design’ methodologies.",
    },
    Internal_data_sec_policies: {
      asset: Internal_data_sec_policies,
      title: "Internal Data Security Policies",
      desc: "We strictly follow data security policies and restrict the data access to the admins.",
    },
    Gdpr_compliant: {
      asset: Gdpr_compliant,
      title: "GDPR Compliant",
      desc: "We are dedicated to ensuring that all customer data is treated in accordance with the General Data Protection Regulation (GDPR).",
    },
    Distributed_servers: {
      asset: Distributed_servers,
      title: "Distributed servers across US, Europe & India",
      desc: "We partnered with AWS for its hosting and data services allowing users to access our platform 24x7.",
    },
  };

    const more_results =
    [
        {
          label: "News Sources",
          alma_news: "400,000+",
          google: "~15,000",
        },
        {
          label: "Prospects Tracked daily",
          alma_news: "100%",
          google: "<2%",
        },
        {
          label: "Search Keywords",
          alma_news: "All Combinations",
          google: "Name + College",
        },
        {
          label: "Probability",
          alma_news: "High",
          google: "Low",
        },
    ]
    const auto_handling =
    [
        {
          label: "Getting results",
          alma_news: "Auto",
          google: "2-3 Hrs",
        },
        {
          label: "Notifying Departments",
          alma_news: "Tracks and Alerts",
          google: "Manual",
        },
    ]
  const line_seperator_ex_light_grey = (
    <tr>
      <td style={{paddingLeft: 24, paddingRight: 0}}><div className="line_seperator_ex_light_grey"></div></td> 
      <td style={{paddingLeft: 0, paddingRight: 0}}><div className="line_seperator_ex_light_grey"></div></td> 
      <td style={{paddingLeft: 0, paddingRight: 24}}><div className="line_seperator_ex_light_grey"></div></td>   
    </tr>
  )
  const line_seperator_grey = (
    <tr>
      <td style={{paddingLeft: 24, paddingRight: 0}}><div className="line_seperator_grey"></div></td> 
      <td style={{paddingLeft: 0, paddingRight: 0}}><div className="line_seperator_grey"></div></td> 
      <td style={{paddingLeft: 0, paddingRight: 24}}><div className="line_seperator_grey"></div></td>   
    </tr>
  )

  useEffect(() => {
    AmplitudeEventTrack('alma_news_landing');
}, [])


  const learnMorePopup = () => {
    return(
        <AnPopup name="learnMorePopup" handleOnClosePopup={() => {setPopupsList({});}} maxWidth={'90%'} height={'100%'}>
            <iframe style={{ width: "100%", height: "100%"}}src={learn_more_embedded_link} frameborder="0"  
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
            </iframe>      
        </AnPopup>)
}

const handleClick = () => {
  const gaProps = {
    label: 'Get a free demo',
    link: almanews_calendly
  };
  RecordGA(gaProps);
  AmplitudeEventTrack('almanews_get_a_free_demo')
  window.location.href = almanews_calendly;
}

  return (
    <div>
      <div className="landing_container_1" style={{background: "linear-gradient(180.04deg, rgba(140, 194, 244, 0.2) 0.04%, rgba(235, 247, 246, 0.2) 99.96%)"}}>
        <div className="landing_page_head">
          <div style={{margin: "auto", marginRight: isWidthDown(windowsDimensions.width, 1240) ? "auto" : "36px"}}>
            <div className="landing_page_heading">
              <div>
                <span style={{ color: "#437EFE" }}>Get notified</span> when
              </div>
              <div>prospects & alumni make news</div>
            </div>
            <div className="text_title sub_section_1" style={{fontWeight: 600 }}>
              Discover potential donors and strengthen bonds
            </div>
            <div className="landing_btn_container">
                  <Button
                    buttonSize="Bigger"
                    buttonStyle={{
                      width: "max-content",
                      boxShadow: "0px 8px 16px rgba(0, 196, 181, 0.2)",
                    }}
                    innerButtonStye={{ width: 208, padding: "8px 24px" }}
                    onClick={handleClick}
                  >
                    Get a free demo
                    <img src={continueArrow} style={{ marginLeft: 8, filter: "brightness(0) invert(1)" }}></img>
                  </Button>
                <a href="/learn_more" onClick={() => AmplitudeEventTrack('almanews_learn_more')}>
                  <Button
                    type="Secondary"
                    buttonSize="Bigger"
                    buttonStyle={{ width: "max-content"}}
                    innerButtonStye={{background: "transparent",  padding: "8px 20px" }}
                  >
                    Subscribe To Free News Alerts
                  </Button>
                </a>
            </div>
          </div>
          <div style={{margin: "auto"}}>
            <iframe className="landing_video" src="https://www.youtube.com/embed/UL97nr6ZeXg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
        </div>
        <div style={{margin: "auto", maxWidth: 1160, width: "100%"}}>
          <div className="trusted_text">
            Trusted by the world’s best
          </div>
          <div className="trusted_grid_layout">
            <div className="trusted_grid">
              {Object.keys(trusted_by_the_best).map((key, index) => (
                <span className="grid_section">
                <img
                  key={key}
                  src={trusted_by_the_best[key].asset}
                  style={trusted_by_the_best[key].style}
                ></img>
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="how_it_works_container">
        <div
          className="text_headline3 sub_sec_1"
          style={{ textAlign: "center" }}
        >
          How it Works
        </div>
        <div className="default_flex_container" style={{ justifyContent: "center" }}>
          <div className="sub_section_grid_container">
            {Object.keys(how_it_works).map((key, index) => (
              <div key={key}>
                <img
                  src={how_it_works[key].asset}
                  style={{ width: '100%', height: 340, marginBottom: 32 }}
                ></img>
                <div className="landing_title" style={{ marginBottom: 8 }}>
                  {how_it_works[key].title}
                </div>
                <div
                  className="text_subtitle_big"
                  style={{ marginBottom: 8, fontWeight: 600 }}
                >
                  {how_it_works[key].desc}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="seize_opp_container" style={{ background: "#F7FAFC" }}>
        <div className="seize_heading text_position">
            Seize an opportunity to strengthen your relationship with prospects & alumni&nbsp;
            <span style={{ color: "#437EFE", fontWeight: 800 }}>
              who matter
            </span>
        </div>
        <div className="sub_sec_1">
          <div
            className="default_flex_container"
            style={{ justifyContent: "center" }}
          >
            <div className="seize_info_container">
              {Object.keys(seize_opp).map((key, index) => (
                <div key={key}>
                  <div style={{ marginBottom: 16 }}>
                    <img
                      src={seize_opp[key].asset.source}
                      style={seize_opp[key].asset.style}
                    ></img>
                  </div>
                  <div className="landing_title" style={{ marginBottom: 8 }}>
                    {seize_opp[key].title}
                  </div>
                  <div
                    className="text_subtitle_big"
                    style={{ fontWeight: 400 }}
                  >
                    {seize_opp[key].desc}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="alma_features_container" style={{ textAlign: "center" }}>
        <div className="text_headline3">
          <span style={{ color: "#00C4B5" }}>AlmaConnect News</span>
          &nbsp;<span style={{ fontWeight: 400 }}>Features</span>
        </div>
        <div
          className="default_flex_container sub_sec_1"
          style={{ justifyContent: "center" }}
        >
          <div
            className="sub_section_grid_container"
          >
            {Object.keys(AlmaNews_features).map((key, index) => (
              <div key={key}>
                <img
                  src={AlmaNews_features[key].asset}
                  style={{ width: 64, height: 64, marginBottom: 16 }}
                ></img>
                <div className="text_title" style={{ marginBottom: 8, fontSize: !isWidthDown(windowsDimensions.width, "md") ? '18px' : '24px' }}>
                  {AlmaNews_features[key].title}
                </div>
                <div className="text_body_big_light_semi_bold">
                  {AlmaNews_features[key].desc}
                </div>
              </div>
            ))}
          </div>
        </div>
        <a href="/features"
          className="default_flex_container"
          style={{ justifyContent: "center", marginTop: 56 }}
          onClick={() => {AmplitudeEventTrack('almanews_see_all_features')}}
        >
          <Button
            buttonSize="Bigger"
            buttonStyle={{
              width: "max-content",
              boxShadow: "0px 8px 16px rgba(0, 196, 181, 0.2)",
            }}
            innerButtonStye={{ width: 206, padding: "8px 20px" }}
          >
            See all features
            <img
              src={continueArrow}
              style={{ marginLeft: 16, filter: "brightness(0) invert(1)" }}
            ></img>
          </Button>
        </a>
      </div>

      {!isWidthDown(windowsDimensions.width, 872) ? 
      <div style={{ margin: "0px 48px", position: 'relative' }}>
        <img src={Try_out_the_free_version} style={{ maxWidth: "100%" }}></img>
          <a href="/learn_more" onClick={() => AmplitudeEventTrack('almanews_learn_more')}>
            <Button
              buttonSize="Bigger"
              buttonStyle={{
                width: "max-content",
                top: '60%', 
                left: '4.5%',
                position: 'absolute'
              }}
              innerButtonStye={{ padding: "8px 20px", width: 206, height: '48px' }}
            >
              Learn More
            </Button>
          </a>        
      </div>

      :

      <div style={{padding: '0px 16px', margin: 'auto'}}>
        <div style={{ padding: '38px 28px', background: '#3A4459', borderRadius: 16, width: '100%', height: '100%' }}>
          <div className="text_title_big" style={{fontWeight: 800, color: 'white'}}>Try out the free version of our product</div>
          <div className="text_body_big" style={{ margin: '12px 0px 24px 0px', color: 'white'}}>Receive emails when your prospects are mentioned by subscribing to our free email digest</div>
          <a href="/learn_more" onClick={() => {AmplitudeEventTrack('almanews_learn_more')}}>
            <Button
              buttonSize="Bigger"
              buttonStyle={{
                width: "max-content",
              }}
              innerButtonStye={{ padding: "8px 20px" }}
            >
              Learn More
            </Button>
          </a>
        </div>
      </div>
      }

      <div className="compare_container" style={{ margin: "auto", maxWidth: 822 }}>
        <div className="text_headline2" style={{ lineHeight: "66px" }}>
          Compare
        </div>
        <div
          className="text_title_big"
          style={{ fontWeight: 400, color: "#707274" }}
        >
          How AlmaConnect News delivers
        </div>
        <div className="table_scroll" style={{ marginTop: "24px", border: '1px solid #E7ECEB', borderRadius: '12px'}}>
        <div className="table_wrap">
        <table
          className="data_table"
          style={{ textAlign: "left" }}
        >
          <TableHeads
            typoGraphyClass="text_body_big_light"
            headerClass="table_header_row_seperated"
            customStyles={{border: 'none', borderRadius: '12px', height: 96}}
          >
            <th style={{ width: "40%" }}></th>
            <th style={{ width: "30%" }}>
              <img
                src={Almaconnect_news}
                style={{ width: 176, height: 44 }}
              ></img>
            </th>
            <th style={{ width: "30%" }}>
              <img src={Google_alerts} style={{ width: 116, height: 40 }}></img>
            </th>
          </TableHeads>
          <tbody>
            <td className="text_title_big original" style={{padding: '14px 24px 0px 24px'}}>More Results</td>
            {more_results.map((item, indx) => (
              <>
              <TableRows
                key={item.label}
                rowClass="table_content_row_seperated text_body"
                customStyles={{ border: 'none' }}
              >
                <td className="text_title original" style={{fontWeight: 600}}>{item.label}</td>
                <td className="text_title" style={{color: "#437EFE"}}>{item.alma_news}</td>
                <td className="text_title" style={{fontWeight: 600}}>{item.google}</td>
              </TableRows>
              {indx != more_results.length - 1 && line_seperator_ex_light_grey}
              </>
            ))}
            {line_seperator_grey}
            <td className="text_title_big original" style={{padding: '14px 24px 0px 24px'}}>Auto Handling</td>
            
            {auto_handling.map((item, indx) => (
              <>
              <TableRows
                key={item.label}
                rowClass="table_content_row_seperated text_body"
                customStyles={{ border: 'none'}}
              >
                <td className="text_title original" style={{fontWeight: 600}}>{item.label}</td>
                <td className="text_title" style={{color: "#437EFE"}}>{item.alma_news}</td>
                <td className="text_title" style={{fontWeight: 600}}>{item.google}</td>
              </TableRows>
              {indx != auto_handling.length - 1 && line_seperator_ex_light_grey}
              </>
            ))}
            {line_seperator_grey}
            <TableRows
              rowClass="table_content_row_seperated text_body"
              customStyles={{ border: 'none'}}
            >
              <td className="text_title_big original">Customization</td> 
              <td className="text_title" style={{color: "#437EFE"}}>Yes</td> 
              <td className="text_title" style={{fontWeight: 600}}>No</td>   
            </TableRows>
          </tbody>
        </table>
        </div>


        <table
          className="data_table clone"
          style={{ textAlign: "left" }}
        >
          <TableHeads
            typoGraphyClass="text_body_big_light"
            headerClass="table_header_row_seperated"
            customStyles={{border: 'none', borderRadius: '12px', height: 96}}
          >
            <th className="fixed_side" style={{ width: "40%" }}></th>
            <th style={{ width: "30%" }}>
              <img
                src={Almaconnect_news}
                style={{ width: 176, height: 44 }}
              ></img>
            </th>
            <th style={{ width: "30%" }}>
              <img src={Google_alerts} style={{ width: 116, height: 40 }}></img>
            </th>
          </TableHeads>
          <tbody>
            <td className="text_title_big fixed_side" style={{padding: '14px 24px 0px 24px'}}>More Results</td>
            {more_results.map((item, indx) => (
              <>
              <TableRows
                key={item.label}
                rowClass="table_content_row_seperated text_body"
                customStyles={{ border: 'none' }}
              >
                <td className="text_title fixed_side" style={{fontWeight: 600}}>{item.label}</td>
                <td className="text_title" style={{color: "#437EFE"}}>{item.alma_news}</td>
                <td className="text_title" style={{fontWeight: 600}}>{item.google}</td>
              </TableRows>
              {indx != more_results.length - 1 && line_seperator_ex_light_grey}
              </>
            ))}
            {line_seperator_grey}
            <td className="text_title_big fixed_side" style={{padding: '14px 24px 0px 24px'}}>Auto Handling</td>
            
            {auto_handling.map((item, indx) => (
              <>
              <TableRows
                key={item.label}
                rowClass="table_content_row_seperated text_body"
                customStyles={{ border: 'none'}}
              >
                <td className="text_title fixed_side" style={{fontWeight: 600}}>{item.label}</td>
                <td className="text_title" style={{color: "#437EFE"}}>{item.alma_news}</td>
                <td className="text_title" style={{fontWeight: 600}}>{item.google}</td>
              </TableRows>
              {indx != auto_handling.length - 1 && line_seperator_ex_light_grey}
              </>
            ))}
            {line_seperator_grey}
            <TableRows
              rowClass="table_content_row_seperated text_body"
              customStyles={{ border: 'none'}}
            >
              <td className="text_title_big fixed_side">Customization</td> 
              <td className="text_title" style={{color: "#437EFE"}}>Yes</td> 
              <td className="text_title" style={{fontWeight: 600}}>No</td>   
            </TableRows>
          </tbody>
        </table>
        </div>
      </div>
      <section>
        <TestimonialsCarousel style={{backgroundColor: "#F7F0D6"}} 
          colorPalette={{mainText: "#282A2C", secondaryText: "#707274", variant: "dark"}}
        />
      </section>
      <SecuritySection security={security}/>
      {popupsList.learnMorePopup && learnMorePopup()}
    </div>
  );
};

export default AlmaNewsLanding;
