import React, {useState, useEffect} from 'react'
import {apiAgent} from '../../../axiosConfig.js'
import {AnInputBox} from '../../input_utils/AnInput.js'
import {Button} from '../../cta_utils/AnButton.js'
import userIcon from '../../../assets/user.svg'
import userCredentialIcon from '../../../assets/exit.svg'
import { FlashMessages } from '../../cta_utils/FlashMessages.js'

const ManageProfile = (props) => {
  const [userDetails, setUserDetails] = useState({
    name: null,
    email: null,
    phone: null,
    position: null,
    institute: null,
  })
  const [userCredentials, setUserCredentials] = useState({
    current_password: null,
    new_password: null,
    re_new_password: null,
  })
  const [errors, setErrors] = useState({})
  const [successMessage, setSuccessMessage] = useState()
  const [loadingGS, setLoadingGS] = useState(false)
  const [loadingLP, setLoadingLP] = useState(false)

  useEffect(() => {
    props.setActiveSettingTab('profile')
    apiAgent({
      method: 'get',
      url: '/users/profile',
      params: {}
    }).then(res => {
      if(res.data.user){
        setUserDetails(res.data.user);
      }else if(res.data.server_message){
        // Do something bcz of profile not found
      }
    }).catch(err => {

    })
  }, [])

  const onGeneralInputChange = (event) => {
    setUserDetails({...userDetails, [event.target.name]: event.target.value})
  }

  const onCredentialInputChange = (event) => {
    setUserCredentials({...userCredentials, [event.target.name]: event.target.value})
  }

  const onGeneralDetailsSubmit = () => {
    let errors = validateGeneralForm()
    setErrors(errors)
    if(Object.keys(errors).length > 0) return
    setLoadingGS(true)
    apiAgent({
      method: 'post',
      url: '/users/update_profile',
      data: {userDetails}
    }).then(res => {
      setLoadingGS(false)
      // window.location.reload()
      setSuccessMessage("Changes saved")
    }).catch(err => {
      setLoadingGS(false)
    })
  }

  const validateGeneralForm = () => {
    let errors = {}
    if(!userDetails.email) errors.email = "Email can't be blank"
    return errors
  }

  const onCredentialDetailsSubmit = () => {
    let errors = validateCredentialsForm()
    setErrors(errors)
    if(Object.keys(errors).length > 0) return
    setLoadingLP(true)
    apiAgent({
      method: 'post',
      url: '/users/update_credentials',
      data: {userCredentials}
    }).then(res => {
      setLoadingLP(false)
      if(res.data.server_message){
        setErrors({...errors, current_password: res.data.server_message})
      }else{
        setUserCredentials({
          current_password: null,
          new_password: null,
          re_new_password: null,
        })
      }
    }).catch(err => {
      setLoadingLP(false)
    })
  }
  const validateCredentialsForm = () => {
    let errors = {}
    if(!userCredentials.current_password){
      errors.current_password = "Current Password can't be blank"
      return errors;
    }
    if(!userCredentials.new_password){
      errors.new_password = "New Password can't be blank"
    }
    if(!userCredentials.re_new_password){
      errors.re_new_password = "Please Re-enter the new password"
    }
    if(userCredentials.new_password && userCredentials.re_new_password && userCredentials.new_password!=userCredentials.re_new_password){
      errors.re_new_password = "Re-entered password does not match"
    }
    return errors
  }

  return (
      <>
      <div className="text_title" style={{marginBottom: 16}}>Profile Settings</div>
      <div className="border_box1">
        <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 24}}>
          <img src={userIcon} style={{width: 20, height: 20}}/>
          <div className="text_subtitle_big" style={{marginLeft: 10}}>General</div>
        </div>
        <div style={{marginBottom: 8}}>
          <AnInputBox label="Name" error={errors.name}>
            <input type="text" name="name" value={userDetails.name} onChange={(event) => {onGeneralInputChange(event)}} placeholder="Name"/>
          </AnInputBox>
        </div>

        <div style={{marginBottom: 8}}>
          <AnInputBox label="Email">
            <input type="text" name="phone" value={userDetails.email} onChange={(event) => {onGeneralInputChange(event)}} placeholder="+91 9898998989" disabled/>
          </AnInputBox>
        </div>

        <div style={{marginBottom: 8}}>
          <AnInputBox label="Phone" error={errors.phone}>
            <input type="text" name="phone" value={userDetails.phone} onChange={(event) => {onGeneralInputChange(event)}} placeholder="+91 9898998989"/>
          </AnInputBox>
        </div>
        <div style={{marginBottom: 8}}>
          <AnInputBox label="Title/Position" error={errors.position}>
            <input type="text" name="position" value={userDetails.position} onChange={(event) => {onGeneralInputChange(event)}} placeholder="Director"/>
          </AnInputBox>
        </div>
        <div style={{marginBottom: 16}}>
          <AnInputBox label="Institute">
            <input type="text" name="phone" value={userDetails.institute} onChange={(event) => {onGeneralInputChange(event)}} placeholder="New York University" disabled/>
          </AnInputBox>
        </div>
        <Button buttonSize="LessPadding" isLoading={loadingGS} onClick={onGeneralDetailsSubmit} innerButtonStye={{padding: '8px 16px'}}>
          Save changes
        </Button>
      </div>
      <div className="line_light"></div>
      <div className="border_box2">
        <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 24}}>
          <img src={userCredentialIcon} style={{width: 20, height: 20}}/>
          <div className="text_subtitle_big" style={{marginLeft: 10}}>Login and Password</div>
        </div>
        <div style={{marginBottom: 8}}>
          <AnInputBox label="Current Password" error={errors.current_password}>
            <input type="password" name="current_password" value={userCredentials.current_password} onChange={(event) => {onCredentialInputChange(event)}} placeholder="Enter your current password"/>
          </AnInputBox>
        </div>
        <div style={{marginBottom: 8}}>
          <AnInputBox label="New Password" error={errors.new_password}>
            <input type="password" name="new_password" value={userCredentials.new_password} onChange={(event) => {onCredentialInputChange(event)}} placeholder="Enter new password"/>
          </AnInputBox>
        </div>
        <div style={{marginBottom: 16}}>
          <AnInputBox label="Re-enter New Password" error={errors.re_new_password}>
            <input type="password" name="re_new_password" value={userCredentials.re_new_password} onChange={(event) => {onCredentialInputChange(event)}} placeholder="Re-enter New Password"/>
          </AnInputBox>
        </div>
        <Button buttonSize="LessPadding" isLoading={loadingLP} onClick={onCredentialDetailsSubmit} innerButtonStye={{padding: '8px 16px'}}>
          Save changes
        </Button>
      </div>
      {successMessage && <FlashMessages messageTheme='success' firstIcon={true} closeCallback={setSuccessMessage}>{successMessage}</FlashMessages>}
      </>
  )
}

export default ManageProfile
