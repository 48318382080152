import { useState, useEffect, Fragment, useReducer } from "react";
import { apiAgent } from "../../../axiosConfig.js";
import Pagination from "../../../Pagination";
import ConditionalRender from "../../../ConditionalRender";
import { useHistory } from "react-router-dom";
import { switchCurrentTeam } from "../../../actions/teamSliceActions/actions.js";
import { useDispatch } from "react-redux";
import Calendar from 'react-calendar';
import {Button} from "../../cta_utils/AnButton";
import { AnInputCheckBox } from "../../input_utils/AnInputCheckBox.js";
import {LoaderComponent} from '../../utils/LoaderComponent.js'
import VerifiedTick from '../../../assets/Verified.png'
import TableRows from "../../visualizers/tabular/TableRows.jsx";
import TableHeads from "../../visualizers/tabular/TableHeads.jsx";
import AnDropdownFilter from "../../cta_utils/AnDropdownfilter.js";

const plans = [{label: "All", key: "all"}, {label: "Premium", key: "premium"}, {label: "Verified", key: "verified"}, {label: "Unverified", key: "unverified"}, {label: "Outbound", key: "outbound"}];

const TeamAdminStats = () => {
    const history = useHistory();
    const [plan, setPlan] = useState("premium");
    const [statsData, setStatsData] = useReducer(statsReducer, {
        stats: [],
        isFetching: true,
        companyPresent: true,
        totalPages: 1,
        total: 0,
        currentPage: 1,
    })

    useEffect(() => {
        fetchStats(1);
    }, [plan, statsData.companyPresent])

    const fetchStats = (page) => {
        setStatsData({type: 'SET_STATE', payload:{
            isFetching: true
        }})
        apiAgent({
            method: "get",
            url: `/teams/result_stats`,
            params: {
                page: page,
                plan: plan,
                companyPresent: statsData.companyPresent,
            },
        }).then(
            (res) => {
                setStatsData({type: 'SET_STATE', payload: {
                    stats: res.data.stats,
                    currentPage: page,
                    isFetching: false,
                    totalPages: res.data.total_pages
                }})
            },
            (error) => {
                setStatsData({type: 'SET_STATE', payload: {
                    isFetching: false
                }})
            }
        );
    };

    const renderRows = () => {
      return(
        <table className="data_table" style={{textAlign: "left"}}>
            {/* <div className="text_body">{statsData.total} teams</div> */}
            <TableHeads typoGraphyClass="text_subtitle_light" headerClass="table_header_row">
                <th style={{width: "20%"}}>Name</th>
                <th style={{width: "10%"}}>last 24hours</th>
                <th style={{width: "10%"}}>last 4d</th>
                <th style={{width: "10%"}}>last 7d</th>
                <th style={{width: "10%"}}>last 1 month</th>
                <th style={{width: "15%"}}>last visited</th>
                <th style={{width: "10%"}}>prospects</th>
                <th style={{width: "15%"}}>last email opened</th>
            </TableHeads>
            <tbody>
                {!statsData.isFetching &&
                statsData.stats.map((team) => (
                    <TableRows key={team.team_metadata.id} rowClass="table_content_row text_body">
                          <td style={{display: "flex"}}>
                            {team.team_metadata.name} 
                          <ConditionalRender if={team.team_metadata.isVerified}>
                            <img src={VerifiedTick} style={{width: 16, marginLeft: 8, alignSelf: 'flex-start'}}/>
                          </ConditionalRender>
                          </td>
                        {Object.keys(team.count_types).map(count_type => {
                            return(
                                <td className="text_body table_content_row" key={count_type}>
                                    <span>
                                        {team.count_types[count_type]['High confidence'] || 0}/
                                    </span>
                                    <span>
                                        {team.count_types[count_type]['Low confidence'] || 0}
                                    </span>
                                </td>
                            )
                        })}
                        <td className="text_body table_content_row">
                            {team.other_stats["last_visitor"] || "-"}
                            <ConditionalRender if={team.other_stats["last_visited_at"]}>
                                <br/>({team.other_stats["last_visited_at"]})
                            </ConditionalRender>
                        </td>
                        <td className="text_body table_content_row">
                            {team.other_stats["prospects_count"]}   
                        </td>
                        <td className="text_body table_content_row">
                            {team.other_stats["last_opened_by"] || "-"}
                            <ConditionalRender if={team.other_stats["last_opened_at"]}>
                                <br/>({team.other_stats["last_opened_at"]})
                            </ConditionalRender>   
                        </td>
                    </TableRows>
                ))}
            </tbody>
        </table>
      )
    }

    return (
        <div>
            <div className="aggregate_container" style={{maxWidth: "1120px", border: '1px solid #E5E5E5', borderRadius: 4}}>
                <div className="flex_container_space_between" style={{padding: 24}}>
                    <div className="text_title_big">Team Stats</div>
                    <div className="default_flex_container">
                        <AnDropdownFilter
                            option = {plan}
                            setOption = {setPlan}
                            options = {plans}
                            style={{ position: 'relative', lineHeight: 0}}
                        >
                        </AnDropdownFilter>
                        <AnInputCheckBox
                            label="Company present"
                            isChecked={statsData.companyPresent}
                            style={{marginLeft: 8}}
                        >
                            <input
                                type="checkbox"
                                defaultChecked={statsData.companyPresent}
                                onChange={(e) => setStatsData({type: 'SET_STATE', payload: {companyPresent: !statsData.companyPresent}})}
                            >
                            </input>
                        </AnInputCheckBox>
                    </div>
                </div>
                {/* <div className='line_seperator_ex_light_grey' ></div> */}
                <Pagination
                    totalPages={statsData.totalPages}
                    currentPage={statsData.currentPage}
                    onPageChange={fetchStats}
                />
                <ConditionalRender if={!statsData.isFetching}>
                  {renderRows()}
                </ConditionalRender>
                <ConditionalRender if={statsData.isFetching}>
                  <LoaderComponent isLoading={statsData.isFetching} loaderStyle={{width: 32, display: 'block', margin: '32px auto'}}/>
                </ConditionalRender>
            </div>
        </div>
    );
};

const statsReducer = (state, action) =>  {
    switch(action.type){
        case "SET_STATE":{
            return {
                ...state,
                ...action.payload
            }
        }
        default:{
            return {...state}
        }
    }
}

export default TeamAdminStats;
