import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { LoaderComponent } from '../../utils/LoaderComponent';
import ReCAPTCHA from 'react-google-recaptcha';
import { apiAgent } from '../../../axiosConfig';

const PreSubscribe = () => {
    const _history = useHistory()
    const reCaptchaRef =  useRef();
    useEffect(() => {
        let searchParams = new URLSearchParams(window.location.search);
        let uniqId = searchParams.get("id");
        let captchaRef = reCaptchaRef.current
        const verifyUser = async () => {
            const token = await reCaptchaRef.current.executeAsync();
            apiAgent({
                url: "users/verify_bot",
                method: "post",
                data: {recap_token: token}
            }).then(res => {
                let redirect_path = uniqId ? `/subscribe?id=${uniqId}`: `/subscribe`;
                if(res.data.success)    _history.push(redirect_path)
                else _history.push("/")
            }).catch(err => _history.push("/"))
        }
        verifyUser()
        return () => {captchaRef.reset()}
    }, [])
    
    return (
        <>
            <ReCAPTCHA                
                sitekey={"6LfBfPIpAAAAAKt8j_GczyhBrkwKVga35N6l6Tn4"}
                size="invisible"
                ref={reCaptchaRef}
            />
            <LoaderComponent isLoading={true} type="FULL_PAGE" loaderStyle={{ width: 64}}></LoaderComponent>
        </>
    )
}

export default PreSubscribe