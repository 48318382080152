import { indexOfObject } from "../../../utils/ArrayUtils";

export const initialListState = {
    prospectList: [],
    mappingList: [],
    isFetching: false,
    totalPages: 0,
    total: 0,
    currentPage: 1,
    successMessages: {}
}
export const ProspectMappingReducer = (state, action) => {
    let {payload} = action;
    switch(action.type){
        case ALL_ACTIONS["SET_STATE"]: {
            return {...state, ...action.payload}
        }
        case ALL_ACTIONS["UPDATE_PROSPECT_LIST"]:{
            let id = payload.id;
            let ind = indexOfObject(state.prospectList, "id", id)
            let temp = [...state.prospectList]
            if(ind != -1) temp.splice(ind, 1);
            
            return {
                ...state,
                prospectList: temp,
                total: state.total - 1
            }         
        }
        default:{
            return {...state}
        }
    }
}
export const ALL_ACTIONS = {
    SET_STATE: "SET_STATE",
    UPDATE_PROSPECT_LIST: "UPDATE_PROSPECT_LIST"
}
