import React from 'react'
import SadFace from '../../../assets/SadFace.png';

const YouWillBeMissed = () => {
  return (
    <div className='you_will_be_missed'>
        <img src={SadFace} style={{width: "199px", height: "124px"}}/>
        <div className='text_title_big' style={{fontWeight: 600, marginTop: 24}}>You will be missed :{"("}</div>
        <div className='text_body_big_light' style={{marginTop: 8}}>You have been unsubscribed from AlmaConnect’s mailing list.</div>
        <div className='text_body_big' style={{marginTop: 32}}>If you wish to know more about our product sometime later, 
            please schedule a call with us directly by clicking here
        </div>
        <a
            href="/contact_us"
            className='an_primary_button big_btn'
            style={{display: "inline-block", marginTop: 16, padding: "9px 20px", textDecoration: "none"}}
        >
            Reach out to us
        </a>
    </div>
  )
}

export default YouWillBeMissed