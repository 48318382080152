import React, {useCallback, useEffect, useReducer, useRef, useState} from 'react'
import { apiAgent } from '../../../../axiosConfig'
import ConditionalRender from '../../../../ConditionalRender'
import Pagination from '../../../../Pagination'
import DataMineProspectsFilters from '../../../admin_views/filters/DataMineBasedFilters/DataMineProspectsFilters.jsx'
import { initialState, prospectListDataMineReducer as filterReducer } from '../../../admin_views/filters/DataMineBasedFilters/reducers/DataMineProspectsListFiltersReducer'
import { LoaderComponent } from '../../../utils/LoaderComponent'
import getApiUrl from '../../../utils/UrlHelper'
import TableHeads from '../../../visualizers/tabular/TableHeads'
import TableRows from '../../../visualizers/tabular/TableRows'
import DownloadImage from "../../../../assets/download.png";
import {FlashMessages} from "../../../cta_utils/FlashMessages";
import { indexOfObject, swapItems } from '../../../utils/ArrayUtils'
import AnPopup from '../../../cta_utils/AnPopup'
import { Button } from '../../../cta_utils/AnButton';
import { AnInputBox } from '../../../input_utils/AnInput'
import { AnInputCheckBox } from '../../../input_utils/AnInputCheckBox'
import ContinueGreenArrow from "../../../../assets/ContinueGreenArrow.svg";
import GreyDownload from '../../../../assets/datamine/GreyDownload.svg'
import MenuDots from "../../../../assets/MenuDots.svg"
import { AnConfirmPopup } from '../../../cta_utils/AnConfirmPopup'
import AnDropDown from '../../../cta_utils/AnDropDown'
import DarkDropDown from "../../../../assets/darkDropdown.svg"
import External_Link from "../../../../assets/External_Link.svg"
import CreateChildList from './_CreateChildList'
import { Link } from 'react-router-dom';
import reducer from '../../../utils/CommonReducer'
import { initialDataState } from '../../../utils/CommonReducer'
import GreyRightArrow from '../../../../assets/Chevron_Right_MD.svg'
import useCheckUserAccess from '../../../custom_hooks/useCheckUserAccess'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import DataNotFoundImage from "../../../../assets/Data_Not_Found.png"
import GreyDataNotFoundImage from "../../../../assets/GreyDataNotfound.png"
import { autoBatchEnhancer } from '@reduxjs/toolkit'
import Tooltip from '../../../cta_utils/Tooltip.js'
import greenUp from '../../../../assets/GreenUpArrow.svg'
import greenDown from '../../../../assets/GreenDownArrow.svg'
import { AnInputRadioButton } from '../../../input_utils/AnInputRadioButton.js'
import { ReactComponent as BlackMenuDots }  from '../../../../assets/directory/black_menu_dot.svg'
import Icon from '../../../utils/Icon.js'
const DataMineProspectsList = (props) => {
    
    const [pageData, setState] = useReducer(reducer, initialDataState)
    const filterType = "include";
    const [filterState, setFilterState] = useReducer(filterReducer, initialState)
    const [successMessage, setSuccessMessage] = useState("");
    const def = useRef("not-loaded"); //not-loaded, requested, Loaded
    const initialLoader = useRef(true);
    const [list_id, setListId] = useState(props.match.params.list_id);
    const checkUserAccess = useCheckUserAccess();
    const current_team = useSelector(state => state.current_team.team);
    const current_user = useSelector(state => state.current_user.user);
    const fetchList = useCallback((page=1) => {   
        setState({type:"SET_STATE", payload: { isLoading: {isFetching: true}}});
        let data_params = Object.keys(filterState.filtersSelected[filterType]).reduce((r,tab_key) => {
            r[tab_key] = filterState.filtersSelected[filterType][tab_key].map(v => (typeof v.value === "object" ? JSON.stringify(v.value) : v.value));
            return r;
        }, {});
        let url = list_id ? getApiUrl("get_data_mine_list_show", {":list_id": list_id}) : getApiUrl("get_directory");
        let should_apply = false
        if(def.current === "not-loaded")
        {
            should_apply = true
            def.current = "requested" 
        }
        let params = {
            page,
            ...data_params,
            applied_filters: should_apply,
            query: pageData.term_search,
            sortings: {...pageData.sortings}
        }
        setState({type: "SET_STATE", payload: {data_params: params}})
        apiAgent({
            method: "get",
            url: url,
            params: params
        }).then(({data}) => {
            let aggregates = data.aggregates;
            let list = data.list;
            let tags = list.tags;
            setFilterState({type: "ASSIGN_AGGREGATES", payload: {aggregates, filterType}})
            if(!list.is_parent_list && def.current === 'requested')
            {
                const filterType = 'include';
                setFilterState({
                    type: "APPLIED_FILTER",
                    payload: { 
                        tags: tags,
                        filterType: filterType
                    },
                });
                def.current="loaded"
            }
            initialLoader.current = false
            if(data.list.is_master_list)
            setListId(data.list.id)

            setState({type:"SET_STATE", payload: {
                isLoading: {},
                list: data.data,
                list_info: data.list,
                totalCount: data.total_count,
                totalPages: data.total_pages,
                currentPage: page,
                lnProspectCount: data.total_count,
                appliedFilters: tags,
                suggestion_institute_id: data.suggestion_institute_id,
                external_suggestion: data.external_suggestion
            }});

        }).catch(err => {
            initialLoader.current = false
            console.log("Error while fetching")
            setState({type:"SET_STATE", payload: {isLoading: {}}});
        })
    }, [filterState.filtersSelected, pageData.term_search, pageData.sortings]);

    useEffect(() => {
        setListId(props.match.params.list_id)
        def.current = 'not-loaded'
        return (() => setFilterState({type: 'CLEAR_ALL_FILTERS'}))
    }, [props.match.params.list_id])
    useEffect(() => {

        if(def.current == 'loaded')
        def.current="completed";
        else
        fetchList(1);
    }, [fetchList])

    if (!(current_team.show_directory_tab_in_header || current_user.is_publisher)) {
        return <Redirect to="/"></Redirect>
    }

    const download_list = () => {
        let data_params = Object.keys(filterState.filtersSelected[filterType]).reduce((r,tab_key) => {
            r[tab_key] = filterState.filtersSelected[filterType][tab_key].map(v => (typeof v.value === "object" ? JSON.stringify(v.value) : v.value));
            return r;
        }, {});
        apiAgent({
            method: "post",
            url: "/linkedin_prospect/export_linkedin_list",
            data: {
                id: list_id,
                ...data_params
            },
        }).then(
            (res) => {
                handlePopupClose();
                setSuccessMessage("list will be downloaded soon.")
            },
            (error) => {
            }
        );
    }

    

    const generateSuggestion = () => {
        let errors = {}
        if(!pageData.suggestion_institute_id) errors.suggestion_institute_id = "This field can not be blank";
        setState({type: "SET_STATE", payload: {
            errors: errors,
            isLoading: {generatingSuggestion: Object.keys(errors).length === 0}
        }})

        apiAgent({
            method: "post",
            url: getApiUrl("generate_mapping_suggestions"),
            data: {
                list_id: list_id,
                suggestion_institute_id: pageData.suggestion_institute_id,
                external_suggestion: pageData.external_suggestion
            }
        }).then(res => {
            setState({type: "SET_STATE", payload: {
                isLoading: {},
                popupsList: {}
            }})
            setSuccessMessage(res.data.server_msg)
        }).catch(err => {
            setState({type: "SET_STATE", payload: {                
                isLoading: {}
            }})
        })
    }

    const onInputChange = (e) => {
        setState({type:"SET_STATE", payload: {[e.target.name]: e.target.value}});
    }

    const handleMonthChange = (duration) => {
        setState({type:"SET_STATE", payload: {downloadDuration: duration}});
    };

    const handlePopupClose = () => {
        setState({type:"SET_STATE", payload: {popupsList: {}}})
    }

    const marketingPdfPopup = () => {
        return (
          <AnPopup name="marketingPdfPopup" handleOnClosePopup={handlePopupClose} maxWidth={480}>
            <div className="text_title" style={{ marginBottom: 16 }}>Download Marketing Pdf</div>
            <AnInputBox><input type="text" name="lnProspectCount" value={pageData.lnProspectCount} onChange={onInputChange} placeholder="Enter prospect count" /></AnInputBox>
            <div style={{ display: "flex", marginTop: 16 }}>
              <AnInputCheckBox isChecked={pageData.downloadDuration === "30"} label="Last 30 days">
                <input type="checkbox" checked={pageData.downloadDuration === "30"} onChange={() => handleMonthChange("30")} />
              </AnInputCheckBox>
              <AnInputCheckBox isChecked={pageData.downloadDuration === "90"} label="Last 90 days" style={{ marginLeft: 12 }}>
                <input type="checkbox" checked={pageData.downloadDuration === "90"} onChange={() => handleMonthChange("90")} />
              </AnInputCheckBox>
            </div>
            <div style={{ display: "flex", marginTop: 16, alignContent: "center" }}>
              <Button buttonSize="Medium" buttonStyle={{ width: "max-content" }} innerButtonStye={{ padding: "8px 20px" }} onClick={() => { download_pdf() }}>Export Data</Button>
              <Button type="Secondary" buttonSize="Medium" buttonStyle={{ width: "max-content", marginLeft: 16 }} innerButtonStye={{ padding: "8px 20px" }} onClick={handlePopupClose}>Cancel</Button>
            </div>
          </AnPopup>
        );
    };

    const suggestionPopup = () => {
        return (
            <AnPopup name="suggestionPopup" handleOnClosePopup={handlePopupClose} maxWidth={480}>
                <div className="text_title" style={{ marginBottom: 16 }}>List Details</div>
                <AnInputCheckBox isChecked={pageData.external_suggestion} label="AlmaConnect List">
                    <input type="checkbox" checked={pageData.external_suggestion} onChange={(e) => setState({type: "SET_STATE", payload: {external_suggestion: !pageData.external_suggestion}})} />
                </AnInputCheckBox>                
                <AnInputBox label={pageData.external_suggestion ? "Institute Id" : "Team Id"} style={{marginTop: 8}}>
                    <input type="text" name="suggestion_institute_id" value={pageData.suggestion_institute_id} onChange={onInputChange} placeholder="Enter Id" />
                </AnInputBox>                
                <div style={{ display: "flex", marginTop: 16, alignContent: "center" }}>
                    <Button buttonSize="Medium" buttonStyle={{ width: "max-content" }} innerButtonStye={{ padding: "8px 20px" }} onClick={generateSuggestion}>Generate</Button>
                    <Button type="Secondary" buttonSize="Medium" buttonStyle={{ width: "max-content", marginLeft: 16 }} innerButtonStye={{ padding: "8px 20px" }} onClick={handlePopupClose}>Cancel</Button>
                </div>
            </AnPopup>
        );
    }

    const exportPopup = () => {
        return(
            <AnConfirmPopup 
            onSubmitCallback={() => download_list()}
            onCancelCallback={() => handlePopupClose()}
            name="List"
            >
            </AnConfirmPopup>
            )
    }

    const download_pdf = () => {
        apiAgent({
            method: "post",
            url: getApiUrl("download_marketing_pdf_data"),
            data: {
                id: list_id,
                downloadDuration: pageData.downloadDuration,
                lnProspectCount: pageData.lnProspectCount
            },
        }).then(
            (res) => {
                setSuccessMessage("Pdf data will be downloaded soon.")
                handlePopupClose();
            },
            (error) => {
            }
        );
    }
    
    const fetchContactInfo = (id) => {
        setState({type:"SET_STATE", payload: {isLoading: {...pageData.isLoading, [id]: true}} });
        apiAgent({
            method: "get",
            url: getApiUrl("get_data_mine_prospects_contacts"),
            params: {
                ln_prospect_id: id,
            },
        }).then(
            (res) => {
                let temp =[...pageData.list]
                let index = indexOfObject(pageData.list, "id", id)
                temp[index].emails.push(res.data.data)
                setState({type:"SET_STATE", payload: {
                    isLoading: {...pageData.isLoading, [id]: false},
                    list: temp
                }});
            },
            (error) => {
            }
        );
    }
    const handleOnChange = (e) => {
        setState({type: "SET_STATE", payload: {...pageData, query: e.target.value}})
    }
    const handleOnKeyDown = (e) => {
        if(e.key == 'Enter'){
            setState({type: "SET_STATE", payload: {...pageData, term_search: pageData.query}})
        }
    }
    const renderSearchBar = () => {
        return(
            <div className="filter_search_box" style={{fontSize: 14, maxWidth: 200, padding: 0}}>
                <div style={{position: 'relative'}}>
                    <input type="text" value={pageData.query} style={{height: '36px'}} placeholder="Search" onChange={handleOnChange} onKeyDown={handleOnKeyDown}/>
                    <span className="an_search_lens" style={{top: 6, left: 8, width: 24, height: 24}}>
                        <img src="/search.svg" />
                    </span>
                </div>
            </div>
        )
    }
    const toggleDropDown = () => { setState({type:'SET_STATE', payload: { dropDownList: {}}})}
    const toggleColumnFilterDropdown = (column_key) =>  setState({ type: "SET_STATE", payload: { dropDownList: { [column_key]: !pageData.dropDownList[column_key] } } })
    const renderOptionDropdown = () => {
        return (
            <AnDropDown style={{position: 'absolute', right: 0, padding: '20px', background: 'white', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', borderRadius: 4, width: 'max-content', zIndex: 99}}
                toggleDropDown={toggleDropDown}>
                <ConditionalRender if={checkUserAccess('generate_suggestions')}>
                <div 
                    className="an_dropdown_filter text_body_light"
                    style={{cursor: 'pointer'}} onClick={() => setState({type:"SET_STATE", payload: {popupsList: {suggestionPopup: true}}})}>Generate suggestions
                </div> 
                </ConditionalRender>
                <ConditionalRender if={checkUserAccess('list_download_pdf')}>
                <div 
                    className="an_dropdown_filter text_body_light"
                    style={{cursor: 'pointer'}} onClick={() => setState({type:"SET_STATE", payload: {popupsList: {marketingPdfPopup: true}}})}>Download pdf
                </div>     
                </ConditionalRender>
            </AnDropDown>
        )
    }
    const handleAssociatedDropdown = () => {

    }
    const moveToMasterList = () => {
        setFilterState({type: "CLEAR_ALL_FILTERS"});
    }
    const handleSwap = (index1, index2, column_key) => {
        let headers = [...pageData.datamine_headers]
        setState({type: 'SET_STATE', payload: { datamine_headers: swapItems(headers, index1, index2), dropDownList: {}}})
    }
    const handleRemoveSort = (column_key) => {
        let temp_sortings = {...pageData.sortings}
        delete temp_sortings[column_key]
        setState({type: 'SET_STATE', payload: {sortings: temp_sortings, dropDownList: {}}})
    }
    const renderColumnDropdown = (column_key) => {
        let sortings = {...pageData.sortings}
        let column_no = indexOfObject([...pageData.datamine_headers], "key", column_key)
        let datamine_headers = [...pageData.datamine_headers]
        let ascChosen = sortings?.[column_key] === 'asc'
        let descChosen = sortings?.[column_key] === 'desc'
        return (
            <AnDropDown style={{ position: 'absolute', right: column_no === 0 ? 'auto' : 0, marginLeft: column_no === 0 ? '8px' : '0px', padding: '16px', background: 'white', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', borderRadius: 4, width: 'max-content', zIndex: 99 }}
                toggleDropDown={() => toggleColumnFilterDropdown(column_key)}>
                <div
                    className="text_body_light"
                    style={{textTransform: 'uppercase', marginBottom: 8}}>Column property
                </div>
                <AnInputRadioButton label={() => 
                <div className='default_flex_container'>
                    <Icon name="DownArrow"/>
                    <div className={ascChosen ? "text_body" : "text_body_light"} style={{marginLeft: 8}}>{datamine_headers[column_no].type === 'text' ? "Sort ascending" : "Sort old to new"}</div>
                </div>
                }
                    style={{marginBottom: 12}}
                    isChosen={ascChosen}
                >
                    <input type="radio"
                        checked={ascChosen}
                        onChange={(e) => setState({type: 'SET_STATE', payload: {sortings: { [column_key]: 'asc'}, dropDownList: { [column_key]: !pageData.dropDownList[column_key] }}})}
                    />
                </AnInputRadioButton>
                <AnInputRadioButton label={() => 
                <div className='default_flex_container'>
                    <Icon name="UpArrow"/>
                    <div className={descChosen ? "text_body" : "text_body_light"} style={{marginLeft: 8}}>{datamine_headers[column_no].type === 'text' ? "Sort descending" : "Sort new to old"}</div>
                </div>
                }
                    isChosen={descChosen}
                >
                    <input type="radio"
                        checked={descChosen}
                        onChange={(e) => setState({type: 'SET_STATE', payload: {sortings: {[column_key]: 'desc'}, dropDownList: { [column_key]: !pageData.dropDownList[column_key] }}})}
                    />
                </AnInputRadioButton>
                <ConditionalRender if={ascChosen || descChosen}>
                <div className='default_flex_container' style={{marginTop: 12, cursor: 'pointer'}} onClick={() => handleRemoveSort(column_key)}>
                    <div style={{background: '#F3F4F5', borderRadius: '50%'}}>
                        <Icon name="Cancel"/>
                    </div>
                    <div className='text_body' style={{marginLeft: 8}}>Remove Sort</div>
                </div>
                </ConditionalRender>

                <div  className="line_seperator_ex_light_grey"  style={{ margin: "16px -16px" }}></div>

                <ConditionalRender if={column_no !== 0}>
                <div className='default_flex_container' style={{marginBottom: 12, cursor: 'pointer'}}
                    onClick={() => handleSwap(column_no - 1, column_no)}>
                    <Icon name="LeftArrow"/>
                    <div className='text_body' style={{marginLeft: 8}}>Move one column left</div>
                </div>
                </ConditionalRender>

                <ConditionalRender if={column_no !== datamine_headers.length - 1}>
                <div className='default_flex_container' style={{cursor: 'pointer'}} onClick={() => handleSwap(column_no, column_no + 1)}>
                    <Icon name="RightArrow"/>
                    <div className='text_body' style={{marginLeft: 8}}>Move one column right</div>
                </div>
                </ConditionalRender>
                    
            </AnDropDown>
        )
    }
    const renderColumnFilter = (column_key) => {
        let sortings = {...pageData.sortings}
        let ascChosen = sortings?.[column_key] === 'asc'
        let descChosen = sortings?.[column_key] === 'desc'
        return (
            <div style={{ position: 'relative'}}>
                <div style={{cursor: 'pointer', marginLeft: 8}} onClick={() => toggleColumnFilterDropdown(column_key)}>
                {ascChosen && <img src={greenDown} alt='↓'></img>}
                {descChosen && <img src={greenUp} alt='↑'></img>}
                {!ascChosen && !descChosen && <div><BlackMenuDots/></div>}    
                </div>  
                {pageData.dropDownList[column_key] && renderColumnDropdown(column_key)}
            </div>
        )
    }
    return (
        <div className='default_flex_container' style={{alignItems: "flex-start", background: "#FFF", maxHeight: "calc(100vh - 80px)", overflow: "hidden"}}>
            <ConditionalRender if={initialLoader.current}>
                <LoaderComponent isLoading={true}></LoaderComponent>
            </ConditionalRender>
            <ConditionalRender if={!initialLoader.current && !list_id}>
            <div style={{maxWidth: 640, margin: '32px auto', textAlign: 'center', padding: 16}}>
                <img src={DataNotFoundImage} style={{maxWidth: 522, margin: 'auto'}}/>
                <div className="text_title" style={{marginTop: 24}}>No data found!</div>
            </div>
                
            </ConditionalRender>
            <ConditionalRender if={!initialLoader.current && list_id}>
            <div style={{maxWidth: 280, width: "100%", maxHeight: "90vh", overflowY: "scroll", padding: "30px 0px", background: "#ffffff"}}>
               <DataMineProspectsFilters filterState={filterState} setFilterState={setFilterState} list_id={list_id} is_parent_list={pageData.list_info.is_parent_list} applied_filters={pageData.appliedFilters} props_params={pageData.data_params}/>
            </div>
            <div style={{padding: "36px 0px 0px", width: "100%", overflowX: "scroll", margin: "0px auto 80px"}}>                
                <div className='flex_container_space_between' style={{alignItems: "flex-start", padding: '0px 24px'}}>
                    <div>
                        <div className='default_flex_container' style={{marginBottom: 12}}>
                            <ConditionalRender if={pageData.list_info.parent_list_title}>
                                <Link to={`/team/lists/${pageData.list_info.parent_list_id}`} className='text_body_light'>{pageData.list_info.parent_list_title}</Link>  
                                <img src={GreyRightArrow} alt='>' width={24} height={24}></img>
                            </ConditionalRender>
                            <div className='text_title_big' style={{ lineHeight: '20px'}}>{pageData.list_info.title}</div>  
                        </div>                     
                        <div className='text_body_big_light_bold' style={{marginBottom: 8, float: 'left'}}>{pageData.totalCount} Alumni</div>
                    </div>
                    <div className='default_flex_container' style={{gap: "22px"}}>
                        <ConditionalRender if={pageData.list_info.is_master_list || !pageData.list_info.is_parent_list}>
                            <CreateChildList 
                            filterState={filterState} 
                            current_list={{
                                parent_list_id: pageData.list_info.parent_list_id || pageData.list_info.id,
                                is_parent_list: pageData.list_info.is_parent_list,
                                is_master_list: pageData.list_info.is_master_list,
                                list_title: pageData.list_info.title
                            }} 
                                alumniCount={pageData.totalCount}/>
                        </ConditionalRender>
                        <div className='default_flex_container'>                        
                            <Link to="/team/lists/" className='text_link' style={{textDecoration: 'none', marginLeft: 6}}>Lists
                            <img src={External_Link}></img>
                            </Link>
                        </div>
                    </div>
                </div>
                <div  className="line_seperator_ex_light_grey"  style={{ margin: "16px auto" }}></div>
                <div className='flex_container_space_between' style={{padding: '0px 24px', marginBottom: 24}}>
                    <div>{renderSearchBar()}</div>
                    {(<Pagination totalPages={pageData.totalPages}
                        currentPage={pageData.currentPage}
                        onPageChange={fetchList} 
                    />)}
                    <div className='default_flex_container'>
                        <ConditionalRender if={current_team.displayable_features.display_mapper_suggestions}>
                        <div className='default_flex_container'>                        
                            <Link to={{pathname: "/mapper-suggestions"}} className='text_link' style={{textDecoration: 'none', marginLeft: 6}}>Mapper Suggestions
                            <img src={External_Link}></img>
                            </Link>
                        </div>
                        </ConditionalRender>
                        <ConditionalRender if={checkUserAccess('list_download') || (current_user.teams.is_admin && current_team.displayable_features.display_directory_download)}>
                                <Tooltip
                                    tooltipText={() => 'Export in a CSV file \n(filters applicable)'}
                                    innerClass='text_body_light'
                                    textTheme='background-light-black bottom'
                                    innerStyle={{ padding: '4px 16px', alignSelf: 'center', color: 'white', borderRadius: '8px' }}
                                    style={{ marginTop: 6, textAlign: 'center', paddingRight: 122 }}
                                    showWithoutOverflowing={true}
                                >
                                    <div onClick={() => setState({ type: "SET_STATE", payload: { popupsList: { exportPopup: true } } })}
                                        className='default_flex_container'
                                        style={{ position: 'relative', justifyContent: 'center', cursor: "pointer", width: "36px", height: "36px", border: '1px solid #e6e6e6', padding: '8px', background: "#F8F8F8", borderRadius: 3, marginLeft: 16 }}>
                                        <img src={GreyDownload} style={{ maxWidth: 20, maxHeight: 20 }}></img>
                                    </div>
                                </Tooltip>
                        </ConditionalRender>
                        <ConditionalRender if={checkUserAccess('list_download_pdf') || checkUserAccess('generate_suggestions')}>
                        <div onClick={() => setState({type:"SET_STATE", payload: {dropDownList: { optionDropdown: true}}})} 
                            style={{ position: 'relative', cursor: "pointer",  width: "36px",  height: "36px", border: '1px solid #e6e6e6', padding: '8px', background: "#F8F8F8", borderRadius: 3, marginLeft: 12}}>
                            <img src={MenuDots} style={{width: 20, height: 20, verticalAlign: 'baseline'}}></img>
                            {pageData.dropDownList.optionDropdown && renderOptionDropdown()}
                        </div>
                        </ConditionalRender>
                    </div>
                </div>
                <ConditionalRender if={pageData.isLoading.isFetching}>
                    <LoaderComponent isLoading={true}></LoaderComponent>
                </ConditionalRender>
                <ConditionalRender if={!pageData.isLoading.isFetching}>
                    <div style={{height: '100vh', overflow: 'scroll'}}>
                    <table className='linkedin_prospects_data_table' style={{textAlign: "left", background: "white", width: "100%", overflowX: "auto"}}>
                        <TableHeads typoGraphyClass="text_body_big" headerClass="table_header_row_seperated" customStyles={{height: '60px', position: 'sticky', top: 0}}>
                            {
                                pageData.datamine_headers.map((header) => {
                                    return (
                                        <ConditionalRender if={header.access ? checkUserAccess(header.access) : true}>
                                        <th key={header.key}  style={header.style}><div className='default_flex_container'><span>{header.label}</span><ConditionalRender if={header.columnFilterEnabled}>{renderColumnFilter(header.key)}</ConditionalRender></div></th>
                                        </ConditionalRender>
                                    )
                                })
                            }
                        </TableHeads>
                        <ConditionalRender if={pageData.list?.length > 0}>
                        <tbody>
                            {pageData.list.map(item => {
                                return(
                                    <TableRows key={item.id} rowClass="table_content_row_seperated text_body_big_light">
                                        {pageData.datamine_headers.map((header) => (
                                            <ConditionalRender if={header.access ? checkUserAccess(header.access) : true}>
                                                <td key={header.key}>
                                                    {header.key === 'name' ? (
                                                    <a href={item.linkedin_url} className="text_link_big hover_under_line" target="_blank">{item.name}</a>
                                                    ) : header.key === 'company' ? (
                                                    item.company && (
                                                        <a href={item.company.company_url} className="text_link_big hover_under_line" style={{ display: "inline" }} target="_blank">{item.company.name}</a>
                                                    )
                                                    ) : (
                                                    item[header.key]
                                                    )}
                                                </td>
                                        </ConditionalRender>
                                        ))}                                    
                                    </TableRows>
                                )
                            })}
                        </tbody>
                        </ConditionalRender>
                    </table>
                    </div>
                        <ConditionalRender if={pageData.list?.length === 0}>
                            <div style={{textAlign: 'center', marginTop: 72}}>
                                <img src={GreyDataNotFoundImage} style={{ width: 86, height: 83 }}></img>
                                <div className='text_body_big_light_semi_bold' style={{marginTop: 20}}>No data found</div>
                            </div>
                        </ConditionalRender>
                </ConditionalRender>
                {successMessage && <FlashMessages messageTheme='success' firstIcon={true} closeCallback={setSuccessMessage}>{successMessage}</FlashMessages>}
                {pageData.popupsList.marketingPdfPopup && marketingPdfPopup()}
                {pageData.popupsList.suggestionPopup && suggestionPopup()}
                {pageData.popupsList.exportPopup && exportPopup()}
            </div>
            </ConditionalRender>
        </div>
    )
}

export default DataMineProspectsList
