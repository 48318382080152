import { useState, useEffect, Fragment } from "react";
import { apiAgent } from "../../../axiosConfig.js";
import Pagination from "../../../Pagination";
import { useHistory } from "react-router-dom";
import { switchCurrentTeam } from "../../../actions/teamSliceActions/actions.js";
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom'
import { Button } from "../../cta_utils/AnButton.js";
import TableHeads from "../../visualizers/tabular/TableHeads.jsx";
import TableRows from "../../visualizers/tabular/TableRows.jsx";
import { LoaderComponent } from "../../utils/LoaderComponent.js";
import ConditionalRender from "../../../ConditionalRender.js";
import { useSelector } from "react-redux";
import AnPopup from "../../cta_utils/AnPopup.js";
import {AnInputBox} from '../../input_utils/AnInput.js'
import FileUpload from "../../../FileUpload.js";
import SuccessMessagePopup from "../../cta_utils/SuccessMessagePopup.js";
import AnDropdownFilter from "../../cta_utils/AnDropdownfilter.js";
import DownloadImage from "../../../assets/download.png";
import { FlashMessages } from '../../cta_utils/FlashMessages';
import { current } from "@reduxjs/toolkit";
import info from '../../../assets/Info.svg';
import AnToolTip from "../../cta_utils/AnTooltip.js";
import AnSortFilter from "../../cta_utils/AnSortFilter.js";
import GreyDownArrow from '../../../assets/GreyArrowDown.svg';
import GreenUpArrow from '../../../assets/GreenUpArrow.svg';
import GreenDownArrow from '../../../assets/GreenDownArrow.svg';
import Download from '../../../assets/Download.svg'
import AnDropDown from "../../cta_utils/AnDropDown.js";
import ArticleContent from "../../feed_components/ArticleContent";
import getApiUrl from "../../utils/UrlHelper.js";
import {indexOfObject} from "../../utils/ArrayUtils"
import { useCallback } from "react";
import { JobUpdatePost } from "../../feed_components/jobUpdatePost.js";

const plans = [{label: "All", key: "all"}, {label: "Premium", key: "premium"}, {label: "Verified", key: "verified"}, {label: "Unverified", key: "unverified"}];
const plan_type = [{label: "Regular Teams", key: "non_outbound"}, {label: "Outbound Teams", key: "outbound"}];
const email_sent_data = [{label: "All", key: "all"}, {label: "Email Sent", key: "email_sent"}, {label: "Subscribed", key: "subscribe"}, {label: "Unsubscribed", key: "unsubscribe"}];
const download_options = [{label: "Download teams data", key: "outbound_teams_data"}, {label: "Download subscribers data", key: "outbound_sub_data"}]

const TeamsDashboard = () => {
    let history = useHistory();
    const current_user = useSelector(state => state.current_user.user)
    const [teams, setTeams] = useState([]);
    const [singleTeam, setSingleTeam] = useState();
    const [plan, setPlan] = useState("all");
    const [planType, setPlanType] = useState("non_outbound");
    const [isFetching, setIsFetching] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState({});
    const [popupsList, setPopupsList] = useState({});
    const [searchTerm, setSearchTerm] = useState("");
    const [emaildata, setEmailData] = useState("all");
    const [remarks,setRemarks] = useState("");
    const [subscribersData, setSubscribersData] = useState();
    const _dispatch = useDispatch();

    const [successMessages, setSuccessMessages] = useState({
        message: "",
        duration: 0,
        from: ""
    });
    const [sortSelected, setSortSelected] = useState({email_type: '', sort_type: '', val: ''})
    useEffect(() => {
        fetchTeams(1);
    }, [plan, planType, emaildata, sortSelected.val]);

    const handleOnChange = (e) => {
        setSearchTerm(e.target.value);
    };
    const handleOnKeyDown = (e) => {
        if (e.key == "Enter") {
            fetchTeams(1);
        }
    };

    const fetchTeams = (page) => {
        setIsFetching(true);
        apiAgent({
            method: "get",
            url: getApiUrl('team_stats'),
            params: {
                q: searchTerm,
                page: page,
                plan: plan,
                plan_type: planType,
                emaildata: emaildata,
                sort: sortSelected.val
            },
        }).then(
            (res) => {
                setTeams(res.data.data);
                setTotalPages(res.data.total_pages);
                setTotal(res.data.total);
                setIsFetching(false);
                setCurrentPage(page);
            },
            (error) => {
                setIsFetching(false);
            }
        );
    };
    
    const fetchOutboundPreview = useCallback((team) => {
        setPopupsList({emailPreviewPopup: true});
        setIsLoading({fetchingOutboundPreview: true});
        apiAgent({
            method: "get",
            url: getApiUrl("outbound_email_preview"),
            params: {team_id: team.id}              
        }).then(res => {
            let temp_team = {...team};
            temp_team.email_preview = {...res.data.email_preview};
            setSingleTeam(temp_team);
            setIsLoading({});
        }).catch(err => {
            setIsLoading({});            
        })
    }, [singleTeam])

    const renderSearchBar = () => {
        return (
            <div className="default_flex_container" style={{maxWidth: "700px", marginBottom: "24px"}}>
                <div className="an_search_box" style={{flexBasis: "100%"}}>
                    <div style={{ position: "relative" }}>
                        <input
                            type="text"
                            value={searchTerm}
                            placeholder="Search Team"
                            onChange={handleOnChange}
                            onKeyDown={handleOnKeyDown}
                        />
                        <span className="an_search_lens">
                            <img src="/search.svg" />
                        </span>
                    </div>
                </div>
                <Button buttonStyle={{marginLeft: "12px"}}
                    innerButtonStye={{padding: "9px 44px"}}
                    onClick={() => fetchTeams(1)}                    
                >
                    Search
                </Button>
            </div>

        );
    };
    const renderSuccessMessage = () => {
        return (
            <SuccessMessagePopup maxWidth={440}>
                <div className='text_body_light'>
                    We are processing your request. Please check back later.
                </div>
            </SuccessMessagePopup>
        )
    }

    const updateRemarks = () => {
        apiAgent({
            method: 'post',
            url: getApiUrl('team_stats_update'),
            params: {
                remarks: remarks,
                selected_team_id: singleTeam.id
            },
        }).then(
            (res) => {
                let temp = [...teams]
                temp.map((team) => {
                    if(team.id == singleTeam.id)
                    team.remarks = remarks
                })
                setTeams(temp)
                setPopupsList({});
            },
            (error) => {
                setIsFetching(false);
            }
        );
    }

    const handleExportTeams = (val = 'outbound_teams_data') => {
        if(!window.confirm("Are you sure you want to download teams stats?")) return;
        apiAgent({
            method: "post",
            url: getApiUrl("export_teams_stats"),
            params: {
                plan_type: planType, 
                plan: plan,
                outbound_type: val,
                outbound_filter: emaildata,
                sort: sortSelected.val
            }
        })
            .then((response) => {
                setSuccessMessages({
                    message: "You will receive an email when the export is ready to download",
                    duration: 3000,
                    from: "Data Exports"
                })
            })
            handleOnClosePopup();
    }

    const dataExportedMessage = (message) => {
        setSuccessMessages({...successMessages, message: message})
    }

    const handleOnClosePopup = () => setPopupsList({})

    const fetchTeamSubscriberData = (team) => {
        setSingleTeam(team);
        apiAgent({
            method: "get",
            url: getApiUrl('team_subscriber_data'),
            params: {
                team_id: team.id
            }
        })
            .then((response) => {
                setSubscribersData(response.data.subscribe_email_data);
                setPopupsList({showEmailDataPopup: true});
            })
    }

    const showEmailDataPopup = () => {
        return (
            <AnPopup name="showEmailDataPopup" handleOnClosePopup={handleOnClosePopup} maxWidth={800}>
                <div className="text_title">{singleTeam.name} - Subscribe</div>
                <table className="data_table" style={{textAlign: "left", marginTop: 13}}>
                    <TableHeads typoGraphyClass="text_body_big_light" headerClass="table_header_row">
                <th style={{width: "20%"}}>User</th>
                <th style={{width: "20%"}}>Subscribed On</th>
                <th style={{width: "15%"}}>Emails Stats<br/><div className="text_body_light">(Last week)</div></th>
                <th style={{width: "15%"}}>Emails Stats<br/><div className="text_body_light">(Overall)</div></th>
                    </TableHeads>
                    <tbody>
                        {
                            subscribersData.map(data => (
                                <TableRows key={data.id} rowClass="table_content_row text_body">
                                    <td className="text_body_light">
                                        <span className="text_body_big_bold">{data.user}</span><br/>
                                        {data.position}<br/>
                                        {data.email}<br/>
                                        {data.phone}</td>
                                    <td>{data.subscribed_on}</td>
                                    <td>{emailStats(data.email_opened_past_week, data.email_sent_past_week, data.last_week_percentage)}</td>
                                    <td>{emailStats(data.total_email_opened, data.total_email_sent, data.total_percentage)}</td>
                                </TableRows>
                            ))
                        }
                    </tbody>
                </table>
            </AnPopup>
        )
    }

    const addRemarksPopup = () => {
        return (
            <AnPopup name="addRemarksPopup" handleOnClosePopup={handleOnClosePopup}>
                <div className="text_title">{singleTeam.name} -  Remarks</div>
                <AnInputBox >
                    <textarea type="text" name="Remrks" value={remarks} onChange={(e) => setRemarks(e.target.value)} placeholder="Add Remarks"></textarea>
                </AnInputBox>
                <div style={{display: 'flex', marginTop: 16}}>
                <Button buttonSize="Medium" buttonStyle={{width: 'max-content'}} innerButtonStye={{padding: '8px 20px'}} onClick={() => {updateRemarks();}} >
                    Submit
                </Button>
                <Button type="Secondary" buttonSize="Medium" buttonStyle={{width: 'max-content', marginLeft: 16}} innerButtonStye={{padding: '8px 20px'}} onClick={() => {handleOnClosePopup()}}>
                    Cancel
                </Button>
                </div>
            </AnPopup>
        )
    }
    
    const emailStats = (numerator, denominator, percentage) => {
        if(numerator == '0' && denominator == 0)
        return 0;
        
        return (
            <span>{numerator} / {denominator} ({percentage}%)</span>
        )
    }

    const handleDownload = () => {
        return (
            <AnDropDown style={{position: 'absolute', right: 0, padding: '20px', background: 'white', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', borderRadius: 4, width: 'max-content', zIndex: 99}}
                toggleDropDown={handleOnClosePopup}>
                {   
                    download_options.map((option, index) => 
                    <div 
                    key={option.key}
                    className="an_dropdown_filter text_body_light"
                    style={{cursor: 'pointer'}} onClick={(e) => handleExportTeams(option.key)}>{option.label}
                    </div>        
                )}         
            </AnDropDown>
        )
    }

    const renderEmailPreviewPopup = () => {
        if(isLoading.fetchingOutboundPreview) return(
            <AnPopup name="emailPreviewPopup" handleOnClosePopup={handleOnClosePopup} maxWidth={640}>
                <LoaderComponent isLoading={true}></LoaderComponent>
            </AnPopup>
        )
        if(!singleTeam.email_preview) return null;
        return(
            <AnPopup name="emailPreviewPopup" handleOnClosePopup={handleOnClosePopup} maxWidth={640}>
                <div className="flex_container_space_between">
                    <div className="text_title" style={{maxWidth: "70%"}}>{singleTeam.name}</div>            
                    <div id="subscribed_posts" className="text_link_small"
                        onClick={() => document.getElementById("outbound_posts")?.scrollIntoView({behavior: "smooth", block: "center"})}   
                    >
                        Go to Outbound emails
                    </div>
                </div>
                <div className="text_title" style={{margin: "16px 0px"}}>{singleTeam.email_preview.subscribed_posts.length} News articles for Subscriber emails -
                    <ConditionalRender if={singleTeam.email_preview.subscribed_posts.length >= 1}>
                        <span style={{color: "#17BF63"}}> Email will be triggered</span>
                    </ConditionalRender>
                    <ConditionalRender if={singleTeam.email_preview.subscribed_posts.length < 1}>
                        <span style={{color: "#FB662B"}}> Email won't be triggered</span>
                    </ConditionalRender>
                </div>
                <div className="flex_container_space_between">
                    <div className="text_caption">Last Email: {singleTeam.email_preview.subscribed_last_email}</div>
                    <div className="text_caption">Next Email: {singleTeam.email_preview.subscribed_next_email}</div>
                </div>
                {singleTeam.email_preview.subscribed_posts.map(post => (
                    <div>
                        {post.post_type == 'Post::JobUpdates' ?
                            <div>
                                <JobUpdatePost
                                    jobUpdate={post}
                                />
                            </div>
                            :
                            <div key={post.id} className="article_container" style={{ position: 'relative' }}>
                                <ArticleContent key={post.id}
                                    item={post}
                                    showProspect={true}
                                    showPostFooter={true}
                                    containerClass="default_flex_container news_container "
                                    style={{ marginBottom: "16px", alignItems: "flex-start" }}
                                    actions="true"
                                    callbacks={{ deletionCallback: setPopupsList }}
                                />
                            </div>
                        }
                    </div>
                ))}
                <div  className="line_seperator_ex_light_grey"  style={{ margin: "24px auto", height: 10 }}></div>
                
                <div className="flex_container_space_between">
                    <div className="text_title" style={{maxWidth: "70%"}}>{singleTeam.name}</div>            
                    <div id="outbound_posts" className="text_link_small"
                        onClick={() => document.getElementById("subscribed_posts")?.scrollIntoView({behavior: "smooth", block: "center"})}   
                    >
                        Go to Subscriber emails
                    </div>
                </div>

                <div className="text_title" style={{margin: "16px 0px"}}>{singleTeam.email_preview.outbound_posts.length} News articles for Outbound emails -
                    <ConditionalRender if={singleTeam.email_preview.outbound_posts.length >= 1}>
                        <span style={{color: "#17BF63"}}> Email will be triggered</span>
                    </ConditionalRender>
                    <ConditionalRender if={singleTeam.email_preview.outbound_posts.length < 1}>
                        <span style={{color: "#FB662B"}}> Email won't be triggered</span>
                    </ConditionalRender>
                </div>
                <div className="flex_container_space_between">
                    <div className="text_caption">Last Email: {singleTeam.email_preview.outbound_last_email}</div>
                    <div className="text_caption">Next Email: {singleTeam.email_preview.outbound_next_email}</div>
                </div>
                {singleTeam.email_preview.outbound_posts.map(post => (
                    <div key={post.id} className="article_container" style={{position: 'relative'}}>                        
                        <ArticleContent key={post.id}
                            item={post}
                            showProspect={true}
                            showPostFooter={true}
                            containerClass="default_flex_container news_container "
                            style={{marginBottom: "16px", alignItems: "flex-start"}}
                            actions="true"
                            callbacks={{deletionCallback: setPopupsList, archiveCallback: () => setSuccessMessages({message: "Prospect archived", duration: 5000})}}
                        />                        
                    </div>
                ))}
            </AnPopup>
        )
    }

    const renderTableContent = () => (
        <table className="data_table" style={{textAlign: "left"}}>
            <TableHeads typoGraphyClass="text_body_big_light" headerClass="table_header_row">
                <th style={{width: "20%"}}>Team</th>
                <th style={{width: "15%"}}>Plan type</th>
                <th style={{width: "15%"}}>Team members</th>
                <th style={{width: "15%"}}>Emails sent<br/>
                    <div className="text_body_light">
                        (Last week)
                    </div>
                </th>
                <th style={{width: "15%"}}>Emails opened<br/><div className="text_body_light">(Last week)</div></th>
                <th style={{width: "20%"}}>Remarks</th>
            </TableHeads>
            <tbody>
                {
                    teams.map(team => (
                        <TableRows key={team.id} rowClass="table_content_row text_body">
                            <td style={{wordBreak: 'break-word'}}>{team.name}</td>
                            <td>{team.premium ? "Premium" : team.verified ? "Verified" : "Unverified"}</td>
                            <td>{team.team_total}</td>
                            <td> {team.emails_sent_past_week}</td>
                            <td>{team.team_opened_emails_past_week}</td>
                            <td>{team.remarks} <div className="text_link" onClick={() => {setSingleTeam(team); setRemarks(team.remarks); setPopupsList({addRemarksPopup: true});}}> Edit <img src="/edit.svg" style={{width: 15, height: 15, marginRight: 8}}/></div></td>
                        </TableRows>
                    ))
                }
            </tbody>
        </table>
    )

    const renderTableContentOutBound = () => (
        <table className="data_table" style={{textAlign: "left"}}>
            <TableHeads typoGraphyClass="text_body_big_light" headerClass="table_header_row">
                <th style={{width: "15%"}}>Team</th>
                <th style={{width: "10%"}}>Team mem</th>
                <th style={{width: "10%", whiteSpace: 'nowrap'}}>Emails Stats
                <span style={{marginLeft: 5, cursor: 'pointer'}} onClick={() => {setPopupsList({last_week_sort: true});}}>
                    <img className={(sortSelected.email_type=='last_week' && sortSelected.sort_type == 'asc') ? 'sort_disabled' : ''} src={GreenDownArrow} style={{marginTop: 16, position: 'absolute'}}></img>
                    <img className={(sortSelected.email_type=='last_week' && sortSelected.sort_type == 'desc') ? 'sort_disabled' : ''} src={GreenUpArrow} style={{marginLeft: 8}}></img>
                </span> 
                {   popupsList.last_week_sort && 
                   (<AnSortFilter
                        email_type={'last_week'}
                        sortSelected={sortSelected}
                        onChangeCallback={setSortSelected}
                        toggleDropDown={handleOnClosePopup}
                    >
                    </AnSortFilter>)
                }<br/>
                    <div className="text_body_light">
                        (Last week)
                        <AnToolTip
                            tooltipText={'Opened/Sent\n(% of emails opened)'}
                            innerClass='text_body_light'
                            messageTheme='background-white bottom'
                            innerStyle={{backgroundColor: '#FFFFFF', marginLeft: -80, marginTop: 8, padding: '4px 12px', alignSelf: 'center'}}
                        >
                            <img src={info}></img>
                        </AnToolTip>   
                    </div>
                </th>
                <th style={{width: "10%", whiteSpace: 'nowrap'}}>Emails Stats
                <span style={{marginLeft: 5, cursor: 'pointer'}} onClick={() => {setPopupsList({overall_sort: true});}}>
                    <img className={(sortSelected.email_type=='overall' && sortSelected.sort_type == 'asc') ? 'sort_disabled' : ''} src={GreenDownArrow} style={{marginTop: 16, position: 'absolute'}}></img>
                    <img className={(sortSelected.email_type=='overall' && sortSelected.sort_type == 'desc') ? 'sort_disabled' : ''} src={GreenUpArrow} style={{marginLeft: 8}}></img>
                </span> 
                {   popupsList.overall_sort && 
                   (<AnSortFilter
                        email_type={'overall'}
                        sortSelected={sortSelected}
                        onChangeCallback={setSortSelected}
                        toggleDropDown={handleOnClosePopup}
                    >
                    </AnSortFilter>)
                }<br/>
                    <div className="text_body_light">
                        (Overall)
                        <AnToolTip
                            tooltipText={'Opened/Sent\n(% of emails opened)'}
                            innerClass='text_body_light'
                            messageTheme='background-white bottom'
                            innerStyle={{backgroundColor: '#FFFFFF', marginLeft: -80, marginTop: 8, padding: '4px 12px', alignSelf: 'center'}}
                        >
                            <img src={info}></img>
                        </AnToolTip>       
                    </div>
                </th>
                <th>Sub/UnSub</th>
                <th style={{width: "15%"}}>Unique Prospects<br/>
                    <div className="text_body_light">
                        (Last Week)
                        <AnToolTip
                            tooltipText={'Unique Articles for\nUnique Prospects\npublished in last week'}
                            innerClass='text_body_light'
                            messageTheme='background-white bottom'
                            innerStyle={{backgroundColor: '#FFFFFF', marginLeft: -80, marginTop: 8, padding: '4px 12px', alignSelf: 'center'}}
                        >
                            <img src={info}></img>
                        </AnToolTip>
                    </div>
                </th>
                <th style={{width: "15%"}}>Unique Posts
                    <div className="text_body_light">
                        <AnToolTip
                            tooltipText={'No. of news articles sent in last 2 days / No. of news articles sent in last 7 days'}
                            innerClass='text_body_light'
                            messageTheme='background-white bottom'
                            innerStyle={{backgroundColor: '#FFFFFF', marginLeft: -80, marginTop: 8, padding: '4px 12px', alignSelf: 'center'}}
                        >
                            <img src={info}></img>
                        </AnToolTip>
                    </div>
                </th>
                <th style={{width: "15%"}}>No. of days emails sent
                    <div className="text_body_light">
                        <AnToolTip
                            tooltipText={'No. of days emails were sent last week'}
                            innerClass='text_body_light'
                            messageTheme='background-white bottom'
                            innerStyle={{backgroundColor: '#FFFFFF', marginLeft: -80, marginTop: 8, padding: '4px 12px', alignSelf: 'center'}}
                        >
                            <img src={info}></img>
                        </AnToolTip>
                    </div>
                </th>
                <th style={{width: "15%"}}>Remarks</th>
                <th style={{width: "10%"}}>Preview</th>
            </TableHeads>
            <tbody>
                {
                    teams.map(team => (
                        <TableRows key={team.id} rowClass="table_content_row text_body">
                            <td style={{wordBreak: 'break-word'}}>{team.name}</td>
                            <td>{team.team_total}</td>
                            <td>{emailStats(team.team_opened_emails_past_week, team.emails_sent_past_week, team.last_week_percentage)}</td>
                            <td>{emailStats(team.total_email_opened, team.total_email_sent, team.overall_percentage)}</td>
                            <td>
                                <div style={{display: 'flex'}}>
                                    <div className="text_link" onClick={() => {fetchTeamSubscriberData(team);}}>{team.team_subscribe_count}</div>
                                    <div style={{marginLeft: 3}}>/ {team.team_unsubscribe_count}</div>
                                </div>
                            </td>
                            <td>{team.total_unique_articles}</td>
                            <td>{team.total_two_days_unique_articles} / {team.total_five_days_unique_articles}</td>
                            <td>{team.days_emails_sent}</td>
                            <td>{team.remarks} <div className="text_link" onClick={() => {setSingleTeam(team); setRemarks(team.remarks); setPopupsList({addRemarksPopup: true});}}> Edit <img src="/edit.svg" style={{width: 15, height: 15, marginRight: 8}}/></div></td>
                            <td className="text_link hover_under_line" onClick={() => {fetchOutboundPreview(team);}}>posts</td>
                        </TableRows>
                    ))
                }
            </tbody>
        </table>
    )

    return (
        <div>
            <div className="aggregate_container" style={{marginTop: "48px", maxWidth: "1270px"}}>
                <div>
                    <div className="text_title_big" style={{marginBottom: "10px", float: 'left'}}>Teams Dashboard</div>
                    <div style={{clear: 'both'}}></div>
                </div>
                <div style={{display: 'flex', marginBottom: "10px"}}>
                    {
                        plan_type.map(type => (
                            <div key={type.key} className={planType === type.key ? 'text_link box_border_highlight' : 'text_body'} style={{cursor: 'pointer', padding: '8px 16px'}} onClick={() => {setPlanType(type.key);setPlan("all"); setEmailData("all")}}>{type.label}</div>
                        ))
                    }
                </div>
                {(planType == "non_outbound") && (<AnDropdownFilter
                    option = {plan}
                    setOption = {setPlan}
                    options = {plans}
                    style = {{position: 'relative', top: 11, float: 'right'}}
                >
                </AnDropdownFilter>)}
                {(planType != "non_outbound") && (<AnDropdownFilter
                    option = {emaildata}
                    setOption = {setEmailData}
                    options = {email_sent_data}
                    style = {{position: 'relative', top: 11, float: 'right'}}
                >
                </AnDropdownFilter>)}
                {renderSearchBar()}
                <div className="default_flex_container" style={{margin: "24px auto 14px"}}>
                    <div className="text_subtitle_big">{total} Teams</div>
                    <div style={{flex: 1}}>
                        <Pagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            onPageChange={fetchTeams}
                        />
                    </div>
                    {planType=='outbound' ?
                        <div style={{position: 'relative'}}>
                            <div className='default_flex_container text_body_light filters_button_box'
                                style={{padding: 8, cursor: 'pointer', position: 'relative'}}
                                onClick={() => setPopupsList({downloadDropdown: true})}
                                >
                                <img src={Download} style={{marginRight: 4}}/>
                                <span className="text_body_light">Download</span>
                                <img src={GreyDownArrow} style={{marginLeft: 4}}/>
                            </div>
                        {   popupsList.downloadDropdown && handleDownload()   }
                        </div>
                        :
                        <div onClick={() => handleExportTeams()} style={{position: 'relative', display: 'flex', alignItems: 'center', cursor: "pointer"}}>
                            <img src={DownloadImage} style={{maxWidth: 15, maxHeight: 15}}></img>
                        <span className='text_link' style={{marginLeft: 6 }}>Download</span>
                    </div>
                    }
                </div>
                {
                    !isFetching && (planType == "non_outbound") ? 
                        renderTableContent() :
                        (<LoaderComponent isLoading={false}  loaderStyle={{width: 32, margin: '36px auto', display: 'block'}}/>)
                }
                {
                    !isFetching && (planType != "non_outbound") ? 
                        renderTableContentOutBound() :
                        (<LoaderComponent isLoading={false}  loaderStyle={{width: 32, margin: '36px auto', display: 'block'}}/>)
                }
            </div>
            {   popupsList.renderSuccessMessage && renderSuccessMessage()  }
            {   popupsList.addRemarksPopup && addRemarksPopup()  }
            {   popupsList.showEmailDataPopup && showEmailDataPopup()  }
            {   popupsList.emailPreviewPopup && renderEmailPreviewPopup()  }
            {
                successMessages.message && (<FlashMessages messageTheme='success' duration={successMessages.duration} closeCallback={dataExportedMessage}>{successMessages.message}</FlashMessages>)
            }
        </div>
    );
};

export default TeamsDashboard;
