import { useEffect, useReducer } from "react";
import MapperReducer, { ALL_MAPPER_ACTIONS, initialMapperData } from "./reducers/TempMapperReducer";
import { export_mapper_sugg, fetch_mapped_unmapped, fetch_temp_mappers } from "./api";
import MapperFiltersReducer, { ALL_MAPPER_FILTER_ACTIONS, initialMapperFilterState } from "../filters/MapperFilters/FiltersStateReducer";
import { MapperFilterProvider } from "./contexts/mapper_filter_context";
import { TempMapperProvider } from "./contexts/temp_mapper_context";
import TableData from "./_TableData";
import Pagination from "../../../Pagination";
import ConditionalRender from "../../../ConditionalRender";
import { FlashMessages } from "../../cta_utils/FlashMessages";
import { LoaderComponent } from "../../utils/LoaderComponent";
import { setState } from "../../utils/CommonReducer";


const UnmappedSuggestion = ({...props}) => {
    const [pageData, pageDataReducer] = useReducer(MapperReducer, initialMapperData);
    const [filtersState, filtersStateReducer] = useReducer(MapperFiltersReducer, initialMapperFilterState);
    const {popupsList, successMessages} = pageData
    const setPageData = (payload) => pageDataReducer({type: ALL_MAPPER_ACTIONS['SET_STATE'], payload: payload})
    const setSuccessMessages = (payload) => setPageData({successMessages: payload})
    const handlePopupClose = () => setPageData({popupsList: {}})
    const toggleDropDown = () => setPageData({dropDownList: {...initialMapperData.dropDownList}})
    useEffect(() => {
        fetchUnmapped(1)   
    },[])
    const fetchUnmapped = (page) => {
        let valueData = {...filtersState}
        let data_params = {
            page: page,
            // tags: valueData.selectedTags,
            // order: valueData.order,
            // confidence: {min: valueData.range.min, max: valueData.range.max},
            // sortings: valueData.sortings,
            name: valueData.search.name,
            mapped: false
            // list_name: valueData.list_name,
            // mappedFilter: valueData.mappedFilter
        }
        setPageData({isFetching: {loadingPageData: true}})
        fetch_mapped_unmapped(data_params).then(
            (res) => {
                setPageData({
                    items: res.data.data,
                    totalPages: res.data.total_pages,
                    totalCount: res.data.total,
                    currentPage: page,
                    isFetching: {loadingPageData: false},
                });
                setState(filtersStateReducer)({suggestionTags: [...res.data.all_suggestion_tags].map((tag) => ({...tag, is_selected: false}))})
            },
            (error) => {
                setPageData({...pageData, isFetching: {loadingPageData: false}});
            }
        );
    };
    return (
        <div>
            <MapperFilterProvider filtersState={filtersState} filtersStateReducer={filtersStateReducer} handleFilterApply={() => fetchUnmapped(1)}>
            <div style={{padding: '0px 32px 0px'}}>
            <div className='default_flex_container' style={{ marginTop: 24 }}>
                <div className="text_subtitle_big">{pageData.totalCount} Suggestions</div>
                <div style={{ flex: 1 }}>
                    <Pagination totalPages={pageData.totalPages}
                        currentPage={pageData.currentPage}
                        onPageChange={fetchUnmapped}
                    />
                </div>
            </div>
            <ConditionalRender if={pageData.isFetching.loadingPageData}>
                <LoaderComponent isLoading={true}  loaderStyle={{width: 32, margin: '36px auto', display: 'block'}}/>
            </ConditionalRender>
            </div>
            <ConditionalRender if={!pageData.isFetching.loadingPageData}>
                <TempMapperProvider pageData={pageData} pageDataReducer={pageDataReducer} mappingType={"unmapped"}>
                    <TableData/>
                </TempMapperProvider>
            </ConditionalRender>
            {
                successMessages.message && (<FlashMessages messageTheme='success' duration={successMessages.duration} closeCallback={(message) => setSuccessMessages({...successMessages, message: message})}>{successMessages.message}</FlashMessages>)
            }
            </MapperFilterProvider>
        </div>
    )
}
export default UnmappedSuggestion