import { useEffect, useState } from "react";
import { Button } from "../../cta_utils/AnButton";
import AnCheckBoxDropdown from "../../cta_utils/AnCheckboxDropdown";
import { AnInputBox } from "../../input_utils/AnInput";
import AnPostTypeFilter from "../../cta_utils/AnPostTypeFilter";
import AnDropdownFilter from "../../cta_utils/AnDropdownfilter";
import darkDropdown from "../../../assets/darkDropdown.svg"
import { indexOfObject } from "../../utils/ArrayUtils";
import ConditionalRender from "../../../ConditionalRender";

const ActiveTabFilter = {
   "mapped": ['name'],
   "unmapped": ['name', 'confidence', 'tags']
}
export const initialFilterState = {
    name: "",
    count: '', 
    order: '',
    confidence: '',
    tags: [],
    selectedOptions: [],
    tagsDropdown: false,
    isLoading: {filterSubmission: false},
    list_name: '',
    mappedFilter:  {label: "All profiles", key: "all"},
    dropDownList: {suggestionDropdown: false},
}
export const TAGS_OPTIONS = [
    {key: 'name_exact_matched', label: 'Name exact matched'}, 
    {key: 'name_partial_matched', label: 'Name partial matched'}, 
    {key: 'name_not_matched', label: 'Name not matched'}, 

    {key: 'first_name_matched', label: 'First name exact matched'}, 
    {key: 'first_name_not_matched', label: 'First name not matched'}, 

    {key: 'last_name_matched', label: 'Last name exact matched'}, 
    {key: 'last_name_not_matched', label: 'Last name not matched'},

    {key: 'alias_name_exact_matched', label: 'Alias name exact matched'},
    {key: 'alias_first_name_matched', label: 'Alias first name exact matched'}, 
    {key: 'alias_last_name_matched', label: 'Alias last name exact matched'}, 
    {key: 'alias_names_not_matched', label: 'Alias name not matched'},

    {key: 'grad_year_matched', label: 'Grad year matched'}, 
    {key: 'grad_year_partial_matched', label: 'Grad year partial matched'}, 
    {key: 'grad_year_not_matched', label: 'Grad year not matched'}, 

    {key: 'university_matched', label: 'University matched'}, 
    {key: 'university_not_matched', label: 'University not matched'}, 

    {key: 'companies_matched', label: 'Company matched'},
    {key: 'companies_not_matched', label: 'Company not matched'},

    {key: 'title_matched', label: 'Title Matched'},
    {key: 'title_partial_matched', label: 'Title matched partially'},
    {key: 'title_not_matched', label: 'Title not matched'},

    {key: 'location_matched', label: 'Location matched'},
    {key: 'location_not_matched', label: 'Location not matched'},
] 
const mappedFilters = [
    {label: "All profiles", key: "all"}, 
    {label: "Mapped", key: "mapped"}, 
    {label: "Unmapped", key: "unmapped"}
];
const MapperSuggestionFilter = ({
    filtersState,
    setFiltersState,
    submitCallback,
    activeTab,
    ...props
}) => {
    const [clearFilter, setClearFilter] = useState(false);
    useEffect(() => {

        if(clearFilter)
        submitCallback()
    return (() =>  setClearFilter(false))
    }, [clearFilter])
    const handleFilterChange = (name, e) => {
        let val = e.target.value;
        setFiltersState({...filtersState, [name]: val})
    }
    const handleClearFilter = () => {
        setClearFilter(true)
        setFiltersState((state) => state = initialFilterState);
    }
    return (
        <>
            <div className="default_flex_container" style={{flexWrap: 'wrap', alignItems: 'end'}}>
                <div className="default_flex_container mapper_container" style={{flexBasis: activeTab === 'algo_sugg' ? '100%' : 'auto'}}>
                <AnInputBox label="Name" inputClass='filter_input_box mapper_filter_input' style={{flexGrow: activeTab === 'algo_sugg' ? 1 : 0, width: activeTab !== 'algo_sugg' ? 240 : 'auto', flexShrink: 0}}>
                    <input type="text" style={{ height: 40, border: 'none' }} value={filtersState.name} name="name" onChange={(e) => handleFilterChange('name', e)} placeholder="John Doe" />
                </AnInputBox>
                <ConditionalRender if={activeTab === 'algo_sugg'}>
                <AnInputBox label="Order" inputClass='filter_input_box mapper_filter_input' style={{ flexBasis: 108}}>
                    <input type="text" style={{ height: 40, border: 'none' }} value={filtersState.order} name="order" onChange={(e) => handleFilterChange('order', e)} placeholder="2"></input>
                </AnInputBox>
                <AnInputBox label="Suggestions" inputClass='filter_input_box mapper_filter_input' style={{ flexBasis: 108}}>
                    <input type="text" style={{ height: 40, border: 'none' }} value={filtersState.count} name="count" onChange={(e) => handleFilterChange('count', e)} placeholder="5"></input>
                </AnInputBox>
                </ConditionalRender>
                <ConditionalRender if={activeTab !== 'unmapped'}>
                <AnInputBox label="Confidence (≥)" inputClass='filter_input_box mapper_filter_input' style={{ width: 108,flexShrink: 0 }}>
                    <input type="text" style={{ height: 40, border: 'none' }} value={filtersState.confidence} name="confidence" onChange={(e) => handleFilterChange('confidence', e)} placeholder="70"></input>
                </AnInputBox>
                <AnCheckBoxDropdown
                    label={"Tags"}
                    options={TAGS_OPTIONS}
                    selectedOptions={filtersState.selectedOptions}
                    setSelectedOptions={(val) => { setFiltersState({ ...filtersState, selectedOptions: val }) }}
                    style={{width: '250px',flexShrink: 0 }}
                >
                </AnCheckBoxDropdown>
                </ConditionalRender>
                <ConditionalRender if={activeTab === 'algo_sugg'}>
                <AnInputBox label="List Name" inputClass='filter_input_box mapper_filter_input' style={{ flexGrow: 1, marginRight: 0}}>
                    <input type="text" style={{ height: '40px', border: 'none' }} value={filtersState.list_name} name="list_name" onChange={(e) => handleFilterChange('list_name', e)} placeholder="University of Richmond" />
                </AnInputBox>
                </ConditionalRender>
                </div>
                <div className="default_flex_container" style={{alignItems: 'end'}}>
                <ConditionalRender if={activeTab === 'algo_sugg'}>
                <div style={{width: "180px", marginRight: 24 }}>
                    <div className="text_body_light">
                        Status
                    </div>
                    <div style={{position: 'relative', paddingTop: 6, paddingBottom: 12}}>
                    <AnDropdownFilter
                        options={mappedFilters}
                        option={filtersState.mappedFilter.key}
                        innerStyle={{ right: "0px", width: "100%" }}
                        setOption={(val) => {
                            let index = indexOfObject(mappedFilters, "key", val);
                            if(index !== -1) setFiltersState({...filtersState, mappedFilter: {...mappedFilters[index]}})
                        }}
                    >
                        <div className='default_flex_container text_body_light filters_button_box'
                            style={{marginLeft: 'auto', padding: '9px 12px', cursor: 'pointer', position: 'relative', height: 40, border: 'none', justifyContent: 'space-between', whiteSpace: 'nowrap',background: '#F8F8F8'}}
                            >
                            <span>{filtersState.mappedFilter.label}</span>
                            <img src={darkDropdown} style={{marginLeft: 4}}/>
                        </div>
                    </AnDropdownFilter>
                    </div>
                </div>
                </ConditionalRender>
                <Button
                    buttonStyle={{marginBottom: 12, width: "max-content", marginLeft: activeTab === 'algo_sugg' ? 0 : 24, marginRight: 16 }}
                    onClick={submitCallback}
                    isLoading={filtersState.isLoading?.filterSubmission}
                    innerButtonStye={{
                        padding: "8px 25px",
                        width: 140,
                        height: 40
                    }}
                >
                    Search
                </Button>
                <Button
                    buttonSize="Medium"
                    type="Secondary"
                    buttonStyle={{marginBottom: 12, width: "max-content" }}
                    innerButtonStye={{ padding: "8px 28px", width: 100, height: 40 }}
                    onClick={handleClearFilter}
                    disabled={false}
                >
                    Clear
                </Button>
                </div>
                </div>

        </>
    )
}
export default MapperSuggestionFilter