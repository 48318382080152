import React, { Fragment, useReducer } from 'react'
import { useEffect } from 'react';
import { apiAgent } from '../../axiosConfig';
import ConditionalRender from '../../ConditionalRender';
import { Button } from '../cta_utils/AnButton';
import useExternalArticleFetch from '../custom_hooks/useExternalArticleFetch';
import { AnInputBox } from '../input_utils/AnInput';
import { AnInputCheckBox } from '../input_utils/AnInputCheckBox';
import { AnInputRadioButton } from '../input_utils/AnInputRadioButton';
import AnMultipleSuggestionInputBox from '../input_utils/AnMultipleSuggestionBox';
import { MENTION_TYPES } from '../utils/AllMentionTypes';
import { LoaderComponent } from '../utils/LoaderComponent';


const ManualPublishPosts = (props) => {
    const [postData, postDataReducer] = useReducer(manualPostReducer, {
        mentionType: "",
        onlyCurrentTeamProspects: true,
        taggedProspects: [],
        sharable_article: {
            description: "",
            external_url: "",
            image_url: "",
            title: ""
        },
        errors: {}
    })

    const setTaggedProspects = (newProspects) => {
        postDataReducer({
            type: 'SET_STATE',
            payload: {
                taggedProspects: [...newProspects]
            }
        })
    }

    const responseCallback = (type, response) =>{
        switch(type){
            case "LOADING": {
                postDataReducer({
                    type: 'SET_SHARABLE_ARTICLE',
                    payload: {
                        fetchingDetails: true
                    }
                })
                break;
            }
            case "SUCCESS":{
                let response_data = response.data.data
                postDataReducer({
                    type: 'SET_SHARABLE_ARTICLE',
                    payload: {
                        ...response_data.page_attr,
                        fetchingDetails: false
                    }
                })
                break;
            }
            case "ERROR":{
                postDataReducer({
                    type: 'SET_SHARABLE_ARTICLE',
                    payload: {
                        fetchingDetails: false
                    }
                })
                break;
            }
            default:{                
                postDataReducer({
                    type: 'SET_SHARABLE_ARTICLE',
                    payload: {
                        fetchingDetails: false
                    }
                })
                break;
            }
        }
    }

    const createPost = () => {
        let dataParams = prepareData(postData);
        let errors = {};
        if(dataParams.tagged_prospect_ids.length === 0) errors["submitError"] = "It is mandatory to tag prospects to create post";
        if(!dataParams.shared_description) errors["description"] = "Description can't be empty";
        if(!dataParams.shared_external_url) errors["external_url"] = "Article url can't be empty";
        postDataReducer({
            type: 'SET_STATE',
            payload: {
                errors: errors
            }
        })
        if(Object.keys(errors).length > 0) return;
        postDataReducer({
            type: 'SET_STATE',
            payload: {
                isCreatingPost: true
            }
        })

        apiAgent({
            method: 'post',
            url: '/posts',
            data: dataParams
        }).then(res => {
            props.createPostSuccess?.();
        }).catch(err => {
            postDataReducer({
                type: 'SET_STATE',
                payload: {
                    isCreatingPost: false,
                    errors: {
                        submitError: "Post was not created successfully"
                    }
                }
            })
        })
    }

    useExternalArticleFetch("/posts/fetch_external_data" , postData.sharable_article.external_url, responseCallback);
    let prospect_search_url = `/prospects/search${postData.onlyCurrentTeamProspects ? '?team_search=true': ''}`

    return (
        <>
            <div className="text_title" style={{paddingBottom: '8px'}}>
                Post News About Alums
            </div>
            {/* <AnInputCheckBox 
                label="Search prospects only in current team"
                style={{padding: '8px 0px'}}
                isChecked={postData.onlyCurrentTeamProspects}
            >
                <input 
                    type="checkbox"
                    value={postData.onlyCurrentTeamProspects}
                    onChange={() => postDataReducer({
                        type: 'SET_STATE',
                        payload: {
                            onlyCurrentTeamProspects: !postData.onlyCurrentTeamProspects
                        }
                    })}
                />
            </AnInputCheckBox> */}
            <AnMultipleSuggestionInputBox 
                label="Add prospects related to news article"
                inputClass="an_search_box"
                placeholder="Search Prospects"
                suggestionUrl= {prospect_search_url}
                selectedOptions={postData.taggedProspects}
                callback={setTaggedProspects}
            />
            <div
                    style={{
                        margin: "8px 0px 16px",
                        borderTop: "0.5px solid #B6B9BB",
                    }}
                ></div>
            <div className="text_body">
                <span className="text_body_light">Mentioned for:</span>{" "}
            </div>

            {MENTION_TYPES.map((mention_type) => {
                return (
                    <Fragment key={mention_type.text}>
                        <AnInputRadioButton
                            label={mention_type.text}
                            style={{ marginTop: 12 }}
                            isChosen={postData.mentionType == mention_type.value}                            
                        >
                            <input
                                type="radio"
                                name="mention"
                                value={mention_type.value}
                                onChange={() => {
                                    postDataReducer({
                                        type: 'SET_STATE', 
                                        payload: {
                                            mentionType: mention_type.value
                                        }
                                    }
                                    );
                                }}
                            />
                        </AnInputRadioButton>
                    </Fragment>                            
                );
            })}
            {postData.mentionType && (
                    <div
                        style={{ marginTop: "16px", padding: "10px" }}
                        className="text_body news_container"
                    >
                        {postData.mentionType}
                    </div>
            )}
            <div
                style={{
                    margin: "16px 0px",
                    borderTop: "0.5px solid #B6B9BB",
                }}
            ></div>            
            <AnInputBox label={"Paste Link of the News Article"} style={{ marginTop: "16px" }} error={postData.errors["external_url"]}>
                <textarea
                    value={postData.sharable_article.external_url}
                    onChange={(e) => {
                        postDataReducer({
                            type: 'SET_SHARABLE_ARTICLE',
                            payload: {
                                external_url: e.target.value
                            }
                        });
                    }}
                ></textarea>
            </AnInputBox>            
            <LoaderComponent isLoading={postData.sharable_article.fetchingDetails} loaderStyle={{width: 32, margin: '16px auto', display:'block'}}/>
            <ConditionalRender if={postData.sharable_article.external_url && !postData.sharable_article.fetchingDetails}>
                <AnInputBox label={"Description"} style={{ marginTop: "16px" }} error={postData.errors["description"]}>
                    <textarea
                        type="text"
                        id="metaDescription"
                        value={postData.sharable_article.description}
                        onChange={(e) => {
                            postDataReducer({
                                type: 'SET_SHARABLE_ARTICLE',
                                payload: {
                                    description: e.target.value
                                }
                            });
                        }}
                    ></textarea>
                </AnInputBox>
                <div className="text_body" style={{ marginTop: "16px" }}>
                    <div
                        style={{ marginBottom: "8px" }}
                        className="text_body_light"
                    >
                        Article Preview:
                    </div>
                    <a
                        href={postData.sharable_article.external_url}
                        target="_blank"
                        className="news_container"
                    >
                        { postData.sharable_article.image_url &&
                        <div style={{ width: "144px", float: "left" }}>
                            <img
                                src={
                                    postData.sharable_article.image_url
                                }
                                className="post_image"
                            />
                        </div>
                        }
                        <div style={{ 
                            width: postData.sharable_article.image_url? "calc(100% - 144px)" : 'auto',  
                            float: postData.sharable_article.image_url? "right": "left"
                            }}>
                            <div
                                className="text_subtitle_big"
                                style={{ marginBottom: "4px" }}
                            >
                                {postData.sharable_article.title}
                            </div>
                            <div className="text_body_light">
                                {postData.sharable_article.description}
                            </div>
                        </div>
                        <div style={{ clear: "both" }}></div>
                    </a>
                </div>
                <Button
                        onClick={() => {
                            createPost();
                        }}
                        isLoading={postData.isCreatingPost}
                        error={postData.errors["submitError"]}
                        buttonStyle={{ marginTop: "16px" }}
                    >
                        Create Post
                </Button>
            </ConditionalRender>
        </>
    )
}

const prepareData = (postData) => {
    return {
        tagged_prospect_ids: postData.taggedProspects.map(tp => tp.id),
        shared_title: postData.sharable_article.title,
        shared_description: postData.sharable_article.description,
        shared_image_url: postData.sharable_article.image_url,
        shared_external_url: postData.sharable_article.external_url,
        shared_published_time: postData.sharable_article.published_time,
        shared_sitename: postData.sharable_article.sitename,
        mentioned_for: postData.mentionType
    }
}

// REDUCER
const manualPostReducer = (state, action) => {
    switch(action.type){
        case "SET_SHARABLE_ARTICLE":{
            return {...state, sharable_article: {...state.sharable_article, ...action.payload}}
        }
        case "SET_STATE":{
            return {...state, ...action.payload}
        }
    }
}

export default ManualPublishPosts