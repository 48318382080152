import React, { useEffect, useReducer } from 'react'
import reducer from '../../utils/CommonReducer'
import Pagination from '../../../Pagination'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { NameInitial } from '../../cta_utils/AnNameInitial'
import { LoaderComponent } from '../../utils/LoaderComponent'
import { export_mapper_posts, fetch_job_posts } from './api'
import { POST_TYPE_MAP } from '../../views/news_feed/high_confidence_results'
import Tooltip from '../../cta_utils/Tooltip'
import ConditionalRender from '../../../ConditionalRender'
import { SuggestionTags } from './_SuggestionTags'
import DownloadImage from '../../../assets/download.png'
import { AnConfirmPopup } from '../../cta_utils/AnConfirmPopup'
import { JobUpdateDesc } from '../../feed_components/JobUpdateDesc'

const MappedJobUpdates = ({...props}) => {

    const [componentData, setComponentData] = useReducer(reducer, {
        jobUpdates: [],        
        totalPages: null,
        totalCount: null,
        currentPage: 1,
        isLoading: {},
        popupsList: {}
    })

    const setComponentState = (payload) => setComponentData({type: "SET_STATE", payload})
    const {totalPages, currentPage, jobUpdates, popupsList } = componentData
    
    useEffect(() => {
        setComponentState({isLoading: {isFetching: true}})        
        fetch_job_posts({page: currentPage, post_type: POST_TYPE_MAP["Job_Updates"]}).then(res => {
            setComponentState({
                totalPages: res.data.total_pages,
                totalCount: res.data.count,
                jobUpdates: [...res.data.data],
                isLoading: {},
            })
        })
    }, [currentPage])

    const setPopupsList = (payload) => {
        setComponentState({popupsList: payload})
    }

    const renderProspect = (prospect) => {
        return(            
            <div className="default_flex_container" style={{flexWrap: "wrap-reverse", alignItems: "flex-end"}}>
                    <Link to={{ pathname: `/prospects/${prospect.id}` }}
                        className="default_flex_container"
                        style={{alignItems: 'flex-start',textDecoration: "none"}}
                    >
                        <NameInitial
                            name={prospect.name}
                            profile_image={prospect.profile_image}
                        />
                        <div style={{marginLeft: 8, maxWidth: "360px"}}>
                            <Tooltip
                                tooltipText={() => prospect.name}
                                innerClass='text_body_light'
                                textTheme='background-light-black top'
                                innerStyle={{ padding: '8px', alignSelf: 'center', color: 'white', borderRadius: '8px' }}
                                style={{  }}
                                tooltipPos="top"
                                tooltTipOverlay={{x: 0,y: -7}}
                            >
                                <div className="text_subtitle_big truncate">{prospect.name}</div>
                            </Tooltip>
                            <Tooltip
                                tooltipText={() => prospect.designation}
                                innerClass='text_body_light'
                                textTheme='background-light-black top'
                                innerStyle={{ padding: '8px', alignSelf: 'center', color: 'white', borderRadius: '8px' }}
                                style={{  }}
                                tooltipPos="top"
                                tooltTipOverlay={{x: 0,y: -7}}
                            >
                                <div className="text_body_light truncate">{prospect.designation}</div>
                            </Tooltip>
                        </div>
                    </Link>                                                                                           
            </div>
        )
    }
    const submitMapperJobUpdate = () => {
        export_mapper_posts({mapper_type: 'Post'}).then(res => {
            setComponentState({successMessage: "You will receive an email when the export is ready to download", popupsList: {}})
        })
    }
    const exportPopup = () => {
        return(
            <AnConfirmPopup 
                onSubmitCallback={() => submitMapperJobUpdate()}
                onCancelCallback={() => setPopupsList({})}
                name="mapped posts"
            >
            </AnConfirmPopup>
            )
    }
    if(componentData.isLoading.isFetching) return(
        <LoaderComponent isLoading={true} />
    )
    return (
        <div style={{maxWidth: "640px"}}>
            <div className='flex_container_space_between' style={{marginTop: 16, marginBottom: -8}}>
                <div></div>
                <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={(page) => setComponentState({ currentPage: page })} style={{ padding: 0 }} />
                <div onClick={() => { setPopupsList({ exportPopup: true }) }} className="default_flex_container"
                    style={{ cursor: "pointer", width: "36px", height: "36px", border: '1px solid #e6e6e6', padding: '6px 6px',  borderRadius: 4 }}>
                    <img src={DownloadImage} style={{ height: 20, width: 20 }}></img>
                </div>
            </div>
            {
                jobUpdates.map((jobUpdate) => {
                    let prospect = jobUpdate.tagged_prospects?.[0] || {};
                    return(
                        <div className="article_container" style={{padding: '24px', marginTop: 24}}>
                            <div className='flex_container_space_between'>
                                {renderProspect(prospect)}
                                <div className='default_flex_container'>
                                <ConditionalRender if={prospect.ln_confidence}>                 
                                    <div className='text_caption_light' style={{marginRight: 12, fontWeight: 700}}>confidence: <span className='text_caption_bold'>{prospect.ln_confidence}%</span></div>
                                </ConditionalRender> 
                                <div className='default_flex_container'>                                                                                            
                                    <Tooltip
                                        tooltipText={() => jobUpdate.formatted_created_at}
                                        innerClass='text_body_light'
                                        textTheme='background-light-black top'
                                        innerStyle={{ padding: '8px', alignSelf: 'center', color: 'white', borderRadius: '8px' }}
                                        style={{  }}
                                        showWithoutOverflowing={true}
                                        tooltipPos="top"
                                        tooltTipOverlay={{x: 0,y: -7}}
                                    >
                                        <div style={{marginLeft: 12, cursor: "default"}} className='text_caption'>{jobUpdate.created_at_time}</div>
                                    </Tooltip>                                    
                                </div>
                                </div>
                            </div>
                            <div className="line_seperator_ex_light_grey" style={{margin: "16px 0px 22px"}}></div>
                            <JobUpdateDesc job_update={{...jobUpdate, name: prospect.name}} />
                            <div style={{marginTop: 24}}>
                            <SuggestionTags tags={prospect.ln_tags}/>
                            </div>
                        </div>
                    )
                })
            }            
            {popupsList.exportPopup && exportPopup()}
        </div>
    )
}

export default MappedJobUpdates