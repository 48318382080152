import React from "react";
export const SecuritySection = (props) => {
    const {security} = props
  return (
    <div className="security_container">
      <div style={{ textAlign: "center" }}>
        <div
          className="text_title"
          style={{ color: "#B6B9BB", marginBottom: 8 }}
        >
          SECURITY
        </div>
        <div className="text_headline3" style={{ marginBottom: 8 }}>
          A secured system, externally as well as internally
        </div>
        <div className="text_title_big" style={{ fontWeight: 400 }}>
        Our security measures for data privacy and protection
        </div>
      </div>
      <div style={{ paddingTop: 52 }}>
        <div
          className="default_flex_container"
          style={{ justifyContent: "center" }}
        >
          <div className="security_info_container">
            {Object.keys(security).map((key, index) => (
              <div key={key}>
                <div style={{ marginBottom: 16 }}>
                  <img
                    src={security[key].asset}
                    style={{ width: 124, height: 124 }}
                  ></img>
                </div>
                <div className="text_title" style={{ fontSize: '18px', marginBottom: 8 }}>
                  {security[key].title}
                </div>
                <div className="text_subtitle_big" style={{ fontSize: '16px', fontWeight: 400 }}>
                  {security[key].desc}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
