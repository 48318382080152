import React from 'react'
import { useState } from 'react'
import ConditionalRender from '../../../../ConditionalRender'
import AnDropDown from '../../../cta_utils/AnDropDown'
import { AnInputRadioButton } from '../../../input_utils/AnInputRadioButton'
import { indexOfObject } from '../../../utils/ArrayUtils'
import SortIcon from '../../../../assets/SortIcon.svg'
import DownArrow from '../../../../assets/GreyArrowDown.svg'

const SORT_OPTIONS = [
    {
        group_label: "", //can be gropu nam of radios
        group_key: "article_created_date_sort",
        options: [
            {
                option_label: "Date: Newest to Oldest",
                field: "article_created_date",
                value: {article_created_date: "desc"}
            },
            {
                option_label: "Date: Oldest to Newest",
                field: "article_created_date",
                value: {article_created_date: "asc"}
            }
        ]
    },
    {
        group_label: "",
        group_key: "score_sort",
        options: [
            {
                option_label: "Score: High to Low",
                field: "score",
                value: {"score": "desc"}
            },
            {
                option_label: "Score: Low to High",
                field: "score",
                value: {"score": "asc"}
            }
        ]
    }
]
const SmartPublishSortDropDown = ({onChangeCallback, sortSelected, ...props}) => {
    const [isDisplayable, setIsDisplayable] = useState(false);
    
    const toggleDropDown = () => setIsDisplayable(d => !d);

    const handleChange = (option) => {
        let payload = {...option.value}
        payload.label = option.option_label;
        onChangeCallback({
            type: "SET_SORT_FILTER",
            payload: payload
        })
        toggleDropDown();
    }
    
    const renderOptions = () => SORT_OPTIONS.map((group, ind) => (
        <div key={group.group_key + sortSelected.label}>
            <ConditionalRender if={ind != 0}>
                <div  className="line_seperator_ex_light_grey"  style={{ marginBottom: "12px" }}></div>
            </ConditionalRender>
            <div>
                {   
                    group.options.map((option, index) => (
                        <AnInputRadioButton key={option.option_label} label={option.option_label}
                            isChosen={sortSelected[option.field] && sortSelected[option.field] == option.value[option.field]}
                            style={{ marginBottom: 12 }}    
                        >
                            <input type="radio"
                                checked={sortSelected[option.field] && sortSelected[option.field] == option.value[option.field]}
                                onChange={(e) => handleChange(option)}
                            />
                        </AnInputRadioButton>
                    ))
                }
            </div>
        </div>
    ))
    const renderDropDown = () => {
        return(
            <AnDropDown style={{position: 'absolute', left: 0, marginTop:"8px", padding: '20px', background: 'white', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', borderRadius: 4, width: 'max-content', zIndex: 99}}
                toggleDropDown={toggleDropDown}>
               {renderOptions()}     
          </AnDropDown>
        )
    }

    return(
        <div style={{position: 'relative'}}>
          <div className='default_flex_container text_body_light filters_button_box' 
                style={{padding: "8px 12px", marginLeft: 0}}
                onClick={toggleDropDown}
            >
                <img src={SortIcon} style={{cursor: 'pointer'}}/>
                <div style={{marginLeft: "8px", maxWidth: "110px"}} className="truncate">{sortSelected.label}</div>
                <img src={DownArrow} style={{marginLeft: 4, cursor: 'pointer'}}/>
          </div>
          {isDisplayable && renderDropDown()}
        </div>
    )
}

export default SmartPublishSortDropDown