import React from 'react';
import AnPopup from '../components/cta_utils/AnPopup';
import TableHeads from "../components/visualizers/tabular/TableHeads";
import TableRows from "../components/visualizers/tabular/TableRows";
import Pagination from '../Pagination';

const ViewSourceArticlesPopup = ({ articles, totalArticlePages, currentArticlePage, handlePopupClose, fetchRssSourceArticles, currentSource }) => {
  return (
    <AnPopup handleOnClosePopup={handlePopupClose} maxWidth={900}>
      <div className='text_title'>Articles</div>
      <Pagination
          totalPages={totalArticlePages}
          currentPage={currentArticlePage}
          onPageChange={(page) => fetchRssSourceArticles(currentSource, page)}
          style={{ padding: "16px 0px 0px" }}
      />
      {articles.length > 0 ? (
        <table className="data_table" style={{ textAlign: "left", marginTop: "24px", maxWidth: "100vw" }}>
          <TableHeads typoGraphyClass="text_body_big_light" headerClass="table_header_row_seperated">
            <th style={{ width: "50%" }}>Article Link</th>
            <th style={{ width: "10%" }}>Created At</th>
          </TableHeads>
          <tbody>
            {articles.map(item => (
              <TableRows key={item.id} rowClass="table_content_row_seperated text_body">
                <td style={{wordBreak: 'break-all'}}>
                  {item.url}
                </td>
                <td>
                  {item.created_at}
                </td>
              </TableRows>
            ))}
          </tbody>
        </table>
      ) : (
        <div>No articles found.</div>
      )}
    </AnPopup>
  );
};

export default ViewSourceArticlesPopup;