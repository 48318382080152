import { useEffect, useMemo, useReducer, useRef, useState } from "react"
import AnPopup from "../../../../cta_utils/AnPopup";
import ConditionalRender from "../../../../../ConditionalRender";
import { Button } from "../../../../cta_utils/AnButton";
import { AnInputCheckBox } from "../../../../input_utils/AnInputCheckBox";
import { fetch_all_aggregates } from "../api";
import humanizeString from "../../../../utils/stringUtils";
import { indexOfObject } from "../../../../utils/ArrayUtils";
import { LoaderComponent } from "../../../../utils/LoaderComponent";
import { FilterSuggestionBox } from "./FiltersSuggestionBox";
import { initialState, prospectListDataMineReducer as filterReducer } from '../reducers/DataMineProspectsListFiltersReducer'
import selectAll from '../../../../../assets/directory/selectAll.svg'
import { AggregatesList } from "./AggregatesList";
import { AggregatesListProvider } from "./aggregates_list_context";
export const SeeAllFilters = ({filterTab, filtersSelected, props_params, setFilterState, handleOnClose, ...props}) => {
    const [currfilterState, setCurrFilterState] = useReducer(filterReducer, initialState)
    const [isLoading, setIsLoading] = useState({submitLoader: false, tagsListLoader: false});
    const [allSelect, setAllSelect] = useState(false);
    const [showsearchedRes, setShowSearchedRes] = useState(false);
    const filterType = "include";
    const mappedKey = filterTab.tab_key
    let {filtersSelected: currfiltersSelected} = currfilterState;

    useEffect(() => {
        let aggregateLength = showsearchedRes ? currfilterState.searchedAggregatesData[filterType][mappedKey].length : currfilterState.aggregatesData[filterType][mappedKey].length - 1
        if(aggregateLength === currfiltersSelected[filterType][mappedKey].length){
            setAllSelect(true)
        }
    }, [currfiltersSelected, currfilterState])

    useEffect(() => {
        setCurrFilterState({
            type: "APPLY_TEMP_FILTERS",
            payload: { 
                filtersSelected: filtersSelected
            },
        });
        fetchAllAggregates();
    }, [])

    const fetchAllAggregates = () => {
        setIsLoading({tagsListLoader: true})
        fetch_all_aggregates({term: filterTab.search_term, ...props_params}).then(
            (res) => {
                let aggregates = res.data.aggregates;
                setCurrFilterState({type: "ASSIGN_AGGREGATES", payload: {aggregates, filterType}})
                setIsLoading({tagsListLoader: false})
            },
            (err) => {

            }
        )
    }
    const onAddTags = () => {
        let selections = [...currfiltersSelected[filterType][mappedKey]];                        
        setFilterState({
            type: "SELECTED_FILTER",
            payload: { 
                mappedKey: mappedKey,
                filterType, 
                selections: selections, 
            },
        });
        handleOnClose();
    }
    const tagsList = useMemo(() => {
        return showsearchedRes ? currfilterState.searchedAggregatesData[filterType][mappedKey] : currfilterState.aggregatesData[filterType][mappedKey]
      }, [showsearchedRes, currfilterState]) 
    const selectAllFilters = () => {
        let selections = tagsList.filter(tag => tag.term !== null && tag.term !== undefined).map(tag => ({value: tag.term}))
        if(allSelect) selections = [];
        setCurrFilterState({
            type: "SELECTED_FILTER",
            payload: { 
                mappedKey: filterTab.tab_key,
                filterType: filterType, 
                selections: selections, 
            },
        })
        setAllSelect(!allSelect)
    }
    const renderSeeAllPopup = () => {
        return (
            <AnPopup handleOnClosePopup={handleOnClose} maxWidth={480}>
                <div className="flex_container_space_between">
                    <div className="text_title">
                        {filterTab.label}
                    </div>
                </div>
                <div  className="line_seperator_ex_light_grey"  style={{ margin: "16px auto 4px" }}></div>
                <ConditionalRender if={filterTab.search_enabled}>
                    <AggregatesListProvider currfilterState={currfilterState}
                        setCurrFilterState={setCurrFilterState}
                        isLoading={isLoading}
                        filterTab={filterTab}
                        handleOnClose={handleOnClose}
                        onAddTags={onAddTags}>
                    <FilterSuggestionBox suggestionUrl={filterTab.suggestion_url}
                        filtersState={currfilterState}
                        filtersStateReducer={setCurrFilterState}
                        filterTab={filterTab}
                        inputClass="filter_search_box"
                        customInputStyle={{height: 36, paddingLeft: 38}}
                        lens_style={{top: 6, left: 8, width: 24, height: 24}}
                        placeholder={filterTab.placeholder}
                        prop_params={{id: props_params.id}}
                        suggestionRenderer={{component: AggregatesList}}
                        setShowSearchedRes={setShowSearchedRes}
                    ></FilterSuggestionBox>
                    </AggregatesListProvider>
                </ConditionalRender>
                <div  className="line_seperator_ex_light_grey"  style={{ margin: "0px auto 16px" }}></div>
                    <div className="flex_container_space_between">
                        <div className="select_all_btn_container" style={{cursor: 'pointer'}} onClick={() => selectAllFilters()}>
                            <img src={selectAll}></img>
                            <div className="text_body_light" style={{marginLeft: 8}}>{allSelect ? "Unselect all" : "Select all"}</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 4 }}>
                            <Button buttonSize="Medium" type="Secondary" innerButtonStye={{ padding: '8px 28px' }}
                                onClick={handleOnClose} isLoading={false}>Cancel</Button>
                            <Button buttonSize="Medium" buttonStyle={{ marginLeft: 20 }} innerButtonStye={{ padding: '8px 36px' }}
                                onClick={onAddTags} isLoading={isLoading.submitLoader} disabled={tagsList.length == 0}>Apply</Button>
                        </div>
                    </div>
            </AnPopup>
        )
    }
    return (
        <div>
            {renderSeeAllPopup()}
        </div>
    )
}