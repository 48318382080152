import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { apiAgent } from "../../../axiosConfig";
import { NameInitial } from "../../cta_utils/AnNameInitial";
import {
    NEVER,
    NOTIFICATIONS_FREQUENCIES,
    NOTIFICATIONS_TYPES,
} from "../../utils/AllNotificationOptions";
import {Button} from '../../cta_utils/AnButton.js'
import {FlashMessages} from '../../cta_utils/FlashMessages.js'
import ConditionalRender from "../../../ConditionalRender.js";
export const TeamNotifications = (props) => {
    const [allTeamMembers, setAllTeamMembers] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState({
      isLoading: false,
      responseMessage: null,
      messageTheme: null,
      firstIcon: false,
    });

    const current_user = useSelector((state) => state.current_user.user);

    useEffect(() => {
        props.setActiveSettingTab("team_notifications");
        apiAgent({
            menthod: "get",
            url: "/team_members/get_notifications",
            params: {
                approval_status: "accepted",
            },
        })
            .then((res) => {
                if (res.data.team_users) setAllTeamMembers(res.data.team_users);
            })
            .catch((err) => {
                console.log(
                    "Error from Team Notifications component did mount",
                    err
                );
            });
    }, []);

    const handleOptionSelect = (event) => {
      //Maybe pass index in params as well idk which is way more performant
      let index = event.target.getAttribute('rowindex');
      let name = event.target.name
      let temp = [...allTeamMembers]
      temp[index][name] = event.target.value
      setAllTeamMembers(temp)
    }

    const saveChanges = useCallback(() => {
      setLoadingStatus({
        isLoading: true,
        responseMessage: null,
        messageTheme: null,
        firstIcon: false
      })
      apiAgent({
          method: "post",
          url: "/team_members/update_notifications",
          data: {
              team_users: allTeamMembers
          },
      }).then(res => {
        if(res.data.team_users) setAllTeamMembers(res.data.team_users);
        setLoadingStatus({
          isLoading: false,
          responseMessage: "Changes saved",
          messageTheme: "success",
          firstIcon: false
        })
      }).catch(err => {
        setLoadingStatus({
          isLoading: false,
          responseMessage: "Notification Preferrences can't be updated",
          messageTheme: "warning",
          firstIcon: true
        })
      })
    }, [allTeamMembers])
    return (
        <>
            <div className="text_title" style={{ marginBottom: 16 }}>
                Team Notifications
            </div>
            <div style={{maxWidth: 640}}>
              <table style={{ marginTop: 24, textAlign: "left", width: "100%" }}>
                  <thead>
                      <tr
                          className="text_body_big_light"
                          style={{
                              border: "0.4px solid #B6B9BB",
                              background: "#F8F8F8",
                              padding: "14px 24px",
                              width: "100%",
                          }}
                      >
                          <th
                              width="35%"
                              style={{ padding: "14px 24px", border: "none" }}
                          >
                              Name
                          </th>
                          <th
                              width="35%"
                              style={{ padding: "14px 24px", border: "none" }}
                          >
                              Notification Frequency
                          </th>
                          <th
                              width="30%"
                              style={{ padding: "14px 24px", border: "none" }}
                          >
                              Notify
                          </th>
                      </tr>
                  </thead>
                  <tbody>
                      {allTeamMembers.map((member, index) => (
                        <ConditionalRender if={current_user.is_publisher || !member.is_super_member}>
                          <tr
                              key={member.id}
                              style={{
                                  border: "0.4px solid #B6B9BB",
                              }}
                          >
                              <td
                                  style={{ padding: "12px 24px", border: "none", boxSizing: "border-box", maxWidth: 213 }}
                              >
                                  <div className="text_subtitle_big" title={member.name} style={{overflow: "hidden", textOverflow: 'ellipsis'}}>
                                      {member.name}
                                  </div>
                                  <div className="text_body_light" title={member.email} style={{overflow: "hidden", textOverflow: 'ellipsis'}}>
                                      {member.email}
                                  </div>
                              </td>
                              <td className="an_input_box" style={{ padding: "12px 24px", border: "none", boxSizing: "border-box", maxWidth: 213  }}>
                                  <select
                                      name="notifications_frequency"
                                      value={member.notifications_frequency}
                                      rowindex={index}
                                      onChange={handleOptionSelect}
                                  >
                                      {NOTIFICATIONS_FREQUENCIES.map((field, index) => (
                                          <option
                                              key={field}
                                              value={field}
                                          >
                                              {field}
                                          </option>
                                      ))}
                                  </select>
                              </td>
                              <td className="an_input_box" style={{ padding: "12px 24px", border: "none", boxSizing: "border-box", maxWidth: 213  }}>
                                  <select
                                      name="notifications_type"
                                      value={member.notifications_type}
                                      rowindex={index}
                                      onChange={handleOptionSelect}
                                      disabled={member.notifications_frequency == NEVER}
                                  >
                                      {NOTIFICATIONS_TYPES.map((field, index) => (
                                          <option
                                              key={field}
                                              value={field}
                                          >
                                              {field}
                                          </option>
                                      ))}
                                  </select>
                              </td>
                          </tr>
                          </ConditionalRender>
                      ))}
                  </tbody>
              </table>
            </div>

            <Button buttonSize="Small" buttonStyle={{marginTop: 32, width: 'max-content'}} innerButtonStye={{padding: '6px 18px'}}
              isLoading={loadingStatus.isLoading}
              onClick={saveChanges}
              >
              Save All
            </Button>
            {loadingStatus.responseMessage && (
                <FlashMessages
                    messageTheme={loadingStatus.messageTheme}
                    closeCallback={() =>
                        setLoadingStatus({...loadingStatus, responseMessage: null, messageTheme: null, firstIcon: false})
                    }
                >
                    {loadingStatus.responseMessage}
                </FlashMessages>
            )}
        </>
    );
};
