import { Link } from "react-router-dom/cjs/react-router-dom.min";
import useWindowSize from "../../custom_hooks/useWindowSize";
import { isWidthDown } from "../../utils/designUtils";
import NewCompareBox from "./NewCompareBoxTable";
const CompareBox = () => {
    const windowDimensions = useWindowSize();
    const compareBoxFP = () => {
        return (
            <div className="compare_box premium_compare_box" style={{borderRadius: 'inherit'}}>
                <div label="Compare features across our Free and Premium plans" style={{borderRadius: 'inherit'}}>
                    <div className='flex_container_space_between position_sticky' style={{ background: 'white', borderRadius: 'inherit'}}>
                        <div>
                            <div className="compare_feature_text">Compare features across our Free and Premium plans</div>
                            <Link to="/features" className="text_link underline">View all features</Link>
                        </div>
                        <div className="premium_header_container" style={{marginTop: isWidthDown(windowDimensions.width, "md") ? 20 : 'auto'}}>
                            <div className='premium_tick_column' style={{ border: "1px solid #B6B9BB", marginTop: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, paddingBottom: 0, marginBottom: 0 }}>
                                <div className='text_title btn_text'>Free</div>
                                    {isWidthDown(windowDimensions.width, "md") ? <div className="text_title" style={{ fontSize: '12px', color: '#707274' }}>Current plan</div> : <button className="feature_btn3 compare_btn" style={{ padding: "12px", margin: "auto" }}>Current plan</button>}
                                <div style={{ paddingBottom: 32 }}></div>
                            </div>
                            <div className='premium_tick_column' style={{ border: "1px solid #437EFE", backgroundColor: "rgba(67, 126, 254, 0.05)", marginTop: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, paddingBottom: 0, marginBottom: 0 }}>
                                <div className='text_title btn_text' style={{ color: '#437EFE' }}>Premium</div>
                                <a href="https://calendly.com/almaconnect-news-scanner/custom-demo" target="_blank">
                                    {isWidthDown(windowDimensions.width, "md") ? <div className="text_title" style={{ textDecoration: 'underline', color: '#437EFE', fontSize: '12px' }}>Get a demo</div> : <button className="feature_btn2 compare_btn" style={{ padding: "12px", margin: "auto" }}>Get a demo</button>}
                                </a>
                                <div style={{ paddingBottom: 32 }}></div>
                            </div>
                        </div>
                    </div>
                    <NewCompareBox/>
                </div>
            </div>
        )
    }

    return(
        <div className="premium_plans_container">
        <section className='section_1' style={{ borderRadius: 'inherit'}}>
            <div className='content_container' style={{padding: 0, borderRadius: 'inherit'}}>
                {compareBoxFP()}
            </div>
        </section>
        </div>
    )
}
export default CompareBox