import React from 'react'
import { useReducer } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { NameInitial } from '../../cta_utils/AnNameInitial'
import { LoaderComponent } from '../../utils/LoaderComponent'
import { fetchProspectArticles } from './feedApiHitters'
import LowConfidencePost from "../../feed_components/low_confidence_post"

const ProspectInsightArticles = (props) => {
    const [matchingArticlesData, pageReducer] = useReducer(reducer, {
        articleList: [],
        prospect: {},
        isLoading: {fetchingArticles: true},
        serverMessages:{}
    })

    useEffect(() => {
        fetchProspectArticles({prospect_id: props.match.params.prospect_id}, {})
        .then(res => {
            if(!res.data.data.prospect) {window.location = "/"}
            pageReducer({type: "SET_STATE", payload: {
                articleList: res.data.data.matching_articles, 
                prospect: res.data.data.prospect,
                isLoading: {fetchingArticles: false}
            }});
        }).catch(err => {
            pageReducer({type: "SET_LOADING", payload: {fetchingArticles: false}})
            console.log("Cant fetch articles", err);
        })
    }, []);

    const renderProspect = () => {
        const {prospect} = matchingArticlesData;
        let {name, designation, company} = prospect
        name = name || company || "";
        
        return (
            <>
                <a href={`/prospects/${prospect.id}`} style={{display: "flex", textDecoration: 'none'}}>
                    <NameInitial
                        name={name}
                    />
                    <div style={{ marginLeft: "16px", maxWidth: 320 }} className="default_flex_container">
                        <div>
                            <div className="text_subtitle_big">{name}</div>
                            <div className="text_body_light truncate">{designation}</div>
                        </div>
                        <div className="truncate" style={{marginLeft: 16, alignSelf: "flex-start"}}>
                            {
                                prospect.tags?.map((tag) => <span key={tag.id} className="text_caption_small_light" style={{textTransform: 'uppercase', marginRight: 8}}>{tag.name}</span>)
                            }
                        </div>
                    </div>
                </a>                            
                <div style={{ clear: "both" }}></div>                
            </>
        );
    };

    if(matchingArticlesData.isLoading.fetchingArticles)
        return <LoaderComponent isLoading={true} type="FULL_PAGE" loaderStyle={{width: 32, margin: "auto"}}/>

    return (
        <div className="similar_articles_container" style={{marginTop: "32px"}}>
            <div className='text_title' style={{marginBottom: "12px"}}>Articles featuring</div>            
            <div className='flex_container_space_between'>
                {renderProspect()}
                <Link to="/" className='text_link hover_under_line'>Back to newsfeed</Link>
            </div>
            <div className="line_seperator_ex_light_grey" style={{margin: "16px 0px"}}></div>
            {matchingArticlesData.articleList.map(article => (
                <LowConfidencePost item={article} id={article.id} renderPostHeader={false}/>
            ))}
        </div>
    )
}
export default ProspectInsightArticles

const reducer = (state, action) => {
    switch(action.type){
        case "SET_STATE": {
            return {...state, ...action.payload}
        }
        case "SET_LOADING": {
            return {...state, isLoading: {...state.isLoading, ...action.payload}}
        }
    }
}