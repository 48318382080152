import React, { useEffect, useReducer } from 'react'
import PipeDriveAlmaLogo from "../../../assets/PipeDriveAlma.svg"
import ConditionalRender from '../../../ConditionalRender'
import { Button } from '../../cta_utils/AnButton'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import reducer from '../../utils/CommonReducer'
import { apiAgent } from '../../../axiosConfig'
import getApiUrl from '../../utils/UrlHelper'
import { useDispatch } from 'react-redux'
import { switchCurrentTeam } from '../../../actions/teamSliceActions/actions'
import { LoaderComponent } from '../../utils/LoaderComponent'
const ALL_STATUSES = {
    "installation_complete": {
        title: "Installation complete",
        description: "AlmaConnect News is now integrated and ready to streamline your Pipedrive experience with news updates directly in the notes section of your Contacts.",
        display_cta: true
    }
}

const PipeDriveStatus = ({status="installation_complete", ...props}) => {
  
    const _dispatch = useDispatch();
    const history = useHistory();    
    const [pageData, setPageData] = useReducer(reducer, null, () => ({
        isLoading: {isInstallingPipeDrive: true},
        team_id: null,
    }))
    const setState = (payload) => {
        setPageData({
            type: "SET_STATE",
            payload: payload
        })
    }
    
    useEffect(() => {
        let searchParams = new URLSearchParams(window.location.search);
        let pipedriveToken = searchParams.get("code");
        apiAgent({
            method: "post",
            url: getApiUrl("pipe_drive_app_init"),
            data: {
                pipedrive_code: pipedriveToken
            }
        }).then(res => {
            setState({isLoading: {}, team_id: res.data.team_id})            
        }).catch(err => {
            setState({isLoading: {}})
        })
    }, [])
    if(pageData.isLoading.isInstallingPipeDrive) return (
        <LoaderComponent isLoading={true} type="FULL_PAGE" loaderStyle={{ width: 64}}/>
    )
    return (
        <div className='pipedrive-box-shadow-content'>
            <img src={PipeDriveAlmaLogo} style={{maxWidth: "450px", width: "100%", margin: "24px auto 32px", display: "block"}}></img>
            <div className='content-container'>
                <div className='text_title_big'>
                    {ALL_STATUSES[status].title}
                </div>
                <div className='text_body' style={{marginTop: "16px"}}>
                    {ALL_STATUSES[status].description}
                </div>
                <ConditionalRender if={ALL_STATUSES[status].display_cta}>
                    <div className='text_body' style={{margin: "16px auto 24px"}}>
                        You can also check news updates on AlmaConnect News
                    </div>
                    <div className='default_flex_container' style={{justifyContent: "center", gap: "12px", flexWrap: "wrap"}}>
                        <a className="an_primary_button big_btn no_decoration" href="https://app.pipedrive.com/" target="_blank" style={{padding: "8px 12px", width: "max-content"}} >Go back to Pipedrive</a>
                        <Button type="Secondary" buttonSize="Big" buttonStyle={{width: 'max-content'}} innerButtonStye={{padding: '8px 12px'}}
                            onClick={() => {
                                if(!pageData.team_id) return;
                                _dispatch(switchCurrentTeam({id: pageData.team_id}, true));
                                history.push("/feed");
                            }}>Go to AlmaConnect News</Button>
                    </div>
                </ConditionalRender>
            </div>
            <div className="line_seperator_ex_light_grey" style={{ margin: "32px auto 16px" }}></div>  
            <div className='text_caption_small_light content-container' style={{maxWidth: "441px", fontWeight: "600"}}>If you have any questions, encounter any issues, or would like to learn  more about our product, please don't hesitate to contact us via email at <span className='text_caption_small'>global.sales@almaconnect.com</span></div>        
        </div>
    )
}

export default PipeDriveStatus