import React from 'react'
import { useHistory } from 'react-router'
import { Button } from '../../cta_utils/AnButton'
import NoNewsBanner from './assets/NoNewsBanner.png'

export const NoNewsLanding = () => {
    const history = useHistory()
    return (
        <div style={{maxWidth: 640, margin: '32px auto', textAlign: 'center'}}>
            <img src={NoNewsBanner} style={{maxWidth: 522, margin: 'auto'}}/>
            <div className="text_title" style={{marginTop: 24}}>We’re sorry! News hasn’t been found with the given data</div>
            <div style={{marginTop: 18}}>
                <span className="text_body_light">Please ensure you’ve added enough prospects to get news results. </span>
            </div>
            <Button innerButtonStye={{padding: '10px 48px'}} buttonStyle={{marginTop: 34, height: '40px'}} onClick={() => {history.push('/import_prospects')}}>Upload more prospects</Button>
        </div>
    )
}
