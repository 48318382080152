export const DATAMINE_HEADERS = [
    {label: "Name", key: "name", style: {width: '20%'}, access: '', columnFilterEnabled: true, type: 'text'},
    {label: "Designation", key: "designation", style: {width: '20%'}, access: '', columnFilterEnabled: true, type: 'text'},
    {label: "Company", key: "company", style: {width: '15%'}, access: '', columnFilterEnabled: true, type: 'text'},
    {label: "Location", key: "location", style: {width: '15%'}, access: '', columnFilterEnabled: true, type: 'text'},
    {label: "Industry", key: "industry", style: {width: '15%'}, access: '', columnFilterEnabled: true, type: 'text'},
    {label: "Last Synced At", key: "last_synced_at", style: {whiteSpace: 'nowrap', width: '15%'}, access: 'list_last_synced_at', columnFilterEnabled: false, type: 'date'},
    {label: "Last Job Changed At", key: "recent_job_changed_at", style: {whiteSpace: 'nowrap', width: '15%'}, access: '', columnFilterEnabled: true, type: 'date'},
]
export const initialDataState = {
    list: [],
    list_info: {title: "",  parent_list_title: ""},
    query: null,
    term_search: null,
    isLoading: {isFetching: true, id: false},
    totalPages: 0,
    totalCount: 0,
    currentPage: 1,
    lnProspectCount: 0,
    downloadDuration: "90",
    popupsList: {},
    appliedFilters: {},
    errors: {},
    dropDownList: {},
    data_params: {},
    datamine_headers: DATAMINE_HEADERS,
    sortings: {}
}
const reducer = (state, action) => {
    switch(action.type){
        case "SET_STATE": {
            return {
                ...state,
                ...action.payload
            }
        }
        default: return state;
    }
}
export const setState = (reducer) => {
    return (payload) => {
        reducer({type: 'SET_STATE', payload: payload})
    }
}

export default reducer