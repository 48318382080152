import React from 'react';

function SmoothScrollLink({ href, children }) {
  const handleClick = (event) => {
    event.preventDefault();
    const targetId = href.substring(1);
    const target = document.getElementById(targetId);
    if (target) {
      const elementTop = target.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: elementTop - 90,
        behavior: 'smooth',
      });
    }
  };

  return (
    <a href={href} onClick={handleClick} style={{textDecoration: "none"}}>
      {children}
    </a>
  );
}

export default SmoothScrollLink;