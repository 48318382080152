import React from 'react'
import NewsFeed from "./news_feed";
import DefaultSource from "../../../assets/default_source.png"

const NewsSourceFeed = (props) => {

    return (
        <div>
            <div style={{maxWidth: 640, margin: '24px auto 0px'}}>
                <div className="text_body_bold">Showing articles featured in</div>
                <div className="text_title" style={{marginTop: 12}}><img src={DefaultSource} style={{marginRight: 8}} />{props.match.params.source}</div>
                <div style={{margin: '16px auto', border: '1px solid #E7ECEB'}}></div>
                <NewsFeed
                    lowConfidenceUrl={`/news_source/aggregates?source=${props.match.params.source}`}
                    highConfidenceUrl={`/news_source/posts?source=${props.match.params.source}`}
                    displayFilterSection={false}
                    displaySideColumn={false}
                />
            </div>
        </div>
    );
}

export default NewsSourceFeed