import React from 'react'
import TopProspectImage from "../../assets/top_prospect.svg";
import GreyArrowDown from "../../assets/GreyArrowDown.svg";
import ConditionalRender from '../../ConditionalRender';
import { useReducer } from 'react';
import { useEffect } from 'react';
import { apiAgent } from '../../axiosConfig';
import getApiUrl from '../utils/UrlHelper';
import { useCallback } from 'react';
import { LoaderComponent } from '../utils/LoaderComponent';
import { TextHighlighter } from '../utils/textHighlighter';
import Seemore from '../utils/SeemoreUtils';
import { Link } from 'react-router-dom';

const ClubbedPost = (props) => {
    const [clubbedData, setClubbedData] = useReducer(reducer, {
        posts: [],
        isLoading: {}
    })
    const fetchClubbedPosts = useCallback(() => {
        setClubbedData({type: "SET_STATE", payload: {isLoading: {fetchingPosts: true}}})
        apiAgent({
            method: "get",
            url: getApiUrl("get_clubbed_posts"),
            params: {post_id: props.item.id}
        }).then(res => {
            setClubbedData({type: "SET_STATE", payload: {posts: res.data.data, isLoading: {}}})
        }).catch(err => {
            setClubbedData({type: "SET_STATE", payload: {isLoading: {}}})
        })
    }, [props.item.id])

    useEffect(() => {
        if(props.expanded === true && clubbedData.posts.length === 0){
            fetchClubbedPosts();
        }
    }, [props.expanded, clubbedData.posts, fetchClubbedPosts])
    
    return (
        <>
            <ConditionalRender if={props.expanded === false}>
                <div className="flex_container_space_between post_padding">
                    <div className="default_flex_container">
                        <img src={TopProspectImage} style={{width: 20, marginRight: 6}}></img>
                        <div className="text_body_light">{props.item.tagged_prospects?.[0]?.name || "This prospect"} frequently gets featured in news.</div>
                    </div>
                    <div className="default_flex_container" style={{cursor: "pointer"}} onClick={props.toggleExpansionCallback}>
                        <div className="text_subtitle_light" style={{textDecoration: "underline", marginRight: "4px"}}>Click here to view articles</div>
                        <img src={GreyArrowDown}></img>
                    </div>
                </div>
            </ConditionalRender>
            <ConditionalRender if={props.expanded}>
                <ConditionalRender if={clubbedData.isLoading.fetchingPosts}>
                    <LoaderComponent isLoading={true} />
                </ConditionalRender>
                <ConditionalRender if={!clubbedData.isLoading.fetchingPosts}>
                 <div className="post_padding">
                    {clubbedData.posts.map((post, index) => {
                        return (
                            <a
                                href={post.shared_link.url}
                                target="_blank"
                                className="news_container"
                                key={post.id}
                                style={{marginTop: index > 0 ? "16px" : "0px", display: "block"}}
                            >
                                {post.shared_link.image_url &&
                                <div className="article_img">
                                    <img
                                        src={post.shared_link.image_url}
                                        className="post_image"
                                    />
                                </div>
                                }
                                <div className={post.shared_link.image_url ? "article_desc_with_img" : "article_desc_without_img"}>
                                    <TextHighlighter
                                        contentString={
                                            post.shared_link.title
                                        }
                                        toMatch={
                                            [post.tagged_prospects?.[0]?.name, post.tagged_prospects?.[0]?.company]
                                        }
                                        typography={{ className: "text_subtitle_big" }}
                                        style={{ marginBottom: 4 }}
                                    />
                                    <Seemore
                                        short_description = {post.shared_link.short_description}
                                        description = {post.shared_link.description}
                                        person_name = {[post.tagged_prospects?.[0]?.name, post.tagged_prospects?.[0]?.company]}
                                    >
                                    </Seemore>
                                    <div
                                        className="text_caption_light"
                                        style={{ marginTop: "8px" }}
                                    >
                                        <Link to={"/news_source/"+post.shared_link.source+"/feed"} className="text_link_small">{post.shared_link.source}</Link>{" "}
                                        {post.shared_link.published_at
                                            ? `| ${post.shared_link.published_at}`
                                            : ""}
                                    </div>
                                </div>
                                <div style={{ clear: "both" }}></div>
                            </a>
                        )
                    })}
                    {props.renderSimilarArticles()}
                    </div>
                    <div className="line_seperator_ex_light_grey" style={{marginTop: 16}}></div>
                    <div className='post_padding'>
                    {props.renderCTAs()}
                    {props.renderCommentsSection()}
                    </div>
                    <div  className="line_seperator_ex_light_grey" style={{ margin: "16px auto" }}></div>
                    <div className="default_flex_container" style={{cursor: "pointer", justifyContent: "flex-end"}} onClick={props.toggleExpansionCallback}>
                        <div className="text_subtitle_light" style={{textDecoration: "underline"}}>Hide articles</div>
                        <img src={GreyArrowDown} style={{transform: "rotate(180deg", marginRight: "4px"}}></img>
                    </div>
                </ConditionalRender>
            </ConditionalRender>
        </>

    )
}

const reducer = (state, action) => {
    switch(action.type){
        case "SET_STATE": {
            return {...state, ...action.payload}
        }
        default: return state
    }
}
export default ClubbedPost