import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import almaLogo from "../../almanews_logo.svg";
import SearchIcon from "../../assets/search.svg";
import ContinueArrow from "../../assets/ContinueArrow.svg";
import { AnSuggestionBox } from '../input_utils/AnInput';
import { useState } from 'react';
import ConditionalRender from '../../ConditionalRender';

const SmallLoginHeader = () => {

    const [headerState, setHeaderState] = useState({
        displaySearchBar: false
    });
    const _history = useHistory();

    const handleRedirectToProspectShow = (name, val) => {
        if (val?.id) _history.push(`/prospects/${val.id}`);
    };
    const toggleSearchBar = () => setHeaderState(s => ({...s, displaySearchBar: !s.displaySearchBar}))
    
    const renderSearchBar = () => {
        return (
            <div className='default_flex_container smooth_transitioner'>
                <img src={ContinueArrow}
                    onClick={toggleSearchBar}
                    style={{transform: "rotateY(180deg)", marginRight: "8px"}}
                />
                <div style={{width: "100%", marginRight: 26, cursor: "pointer"}}>
                    <AnSuggestionBox
                        callback={handleRedirectToProspectShow}
                        suggestionUrl="/prospects/search?team_search=true"
                        placeholder="Search Prospects"
                        inputClass="an_search_box"
                    />
                </div>
            </div>
        )
    }
    if(headerState.displaySearchBar) return(
        <div className='mobile_overlay_container'>
            <div className='login_header default_flex_container'>
                {renderSearchBar()}
            </div>
        </div>
    )
    return (
        <div className="login_header">
            <div className="flex_container_space_between" style={{position: "relative", height: "100%"}}>
                <Link to="/feed"
                    className='login_header_text'
                >
                    <img src={almaLogo}
                        className="login_header_logo"
                    ></img>
                </Link>
                <div className='default_flex_container'>
                    <img
                        src={SearchIcon}
                        width="24px"
                        onClick={toggleSearchBar}
                    />
                </div>
            </div>
        </div>
    )
}

export default SmallLoginHeader