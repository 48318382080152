import {useState, useEffect} from 'react'
import {apiAgent} from '../../../axiosConfig.js'
import ManageTeam from '../../../assets/ManageTeam.png'
import NotificationManage from '../../../assets/NotificationManage.png'
import AnPopup from '../../cta_utils/AnPopup.js'
import {AnInputBox} from '../../input_utils/AnInput.js'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ConditionalRender from '../../../ConditionalRender.js'
import useCheckUserAccess from '../../custom_hooks/useCheckUserAccess.js'

const ProfileShow = (props) => {
  const [userDetails, setUserDetails] = useState({
    name: null,
    email: null,
    phone: null,
    position: null,
    institute: null,
  })

  const [popupsList, setPopupsList] = useState({
    profileEditPopup: false
  })

  const errors = useState({})
  const current_user = useSelector(state => state.current_user?.user)
  const current_team = useSelector(state => state.current_team?.team)
  const canEditProfile = (current_user?.id == props.match.params.profile_id) 
  const checkUserAccess = useCheckUserAccess();
  //TODO: Access to edit user profile to super admins only
  //TODO: Change backend api so it gets profile related to profile id sent rather than current_user
  useEffect(() => {
    apiAgent({
      method: 'get',
      url: `/users/profile`,
      params: {
        profile_id: props.match.params.profile_id
      }
    }).then(res => {
      if(res.data.user){
        setUserDetails(res.data.user);
      }else if(res.data.server_message){
        // Do something bcz of profile not found
      }
    }).catch(err => {
      console.log("Cant fetch profile details")
    })
  }, [])

  const renderManageYourTeam = () => {
    return(
      <div style={{maxWidth: 312, minWidth: 200, width: '100%', border: '1px solid #E5E5E5', borderRadius: 16, boxSizing: 'border-box', background: '#FDFDFD'}}>
        <div style={{ padding: 24, borderBottom: '1px solid #E5E5E5'}}>
          <img src={ManageTeam} style={{width: 48}}/>
          <div className="text_title" style={{marginTop: 16}}>
            Manage your team
          </div>
          <div className="text_body" style={{marginTop: 4}}>
            Add more team members and assign prospects
          </div>
        </div>
        <Link to="/settings/manage_teams" style={{display: 'block', padding: '16px 24px', cursor: 'pointer'}} className="text_link hover_under_line">
          <span style={{verticalAlign: 'middle'}}>Manage team</span>
          <img src="/long_right.svg" style={{verticalAlign: 'middle', width: 16, marginLeft: 8}}/>
        </Link>
      </div>
    )
  }

  const renderNotificationPreferences = () => {
    return(
      <div style={{maxWidth: 312, minWidth: 200, width: '100%', border: '1px solid #E5E5E5', borderRadius: 16, boxSizing: 'border-box', background: '#FDFDFD'}}>
        <div style={{ padding: 24, borderBottom: '1px solid #E5E5E5'}}>
          <img src={NotificationManage} style={{width: 52}}/>
          <div className="text_title" style={{marginTop: 16}}>
            Notification preferences
          </div>
          <div className="text_body" style={{marginTop: 4}}>
            Set notification preferences for your newsfeed and emails
          </div>
        </div>
        <Link to="/settings/notifications" style={{display: 'block', padding: '16px 24px', cursor: 'pointer'}} className="text_link hover_under_line">
          <span style={{verticalAlign: 'middle'}}>See notification settings</span>
          <img src="/long_right.svg" style={{verticalAlign: 'middle', width: 16, marginLeft: 8}}/>
        </Link>
      </div>
    )
  }

  const handlePopupClose = () => {
    setPopupsList({});
  }

  const EditInfoPopup = () => {
    return(
    <AnPopup name="profileEditPopup" handleOnClosePopup={handlePopupClose}>
      <div className="text_title">This is title of popup</div>
      <AnInputBox label="Name" error={errors.name}>
        <input type="text" name="name" value={userDetails.name} onChange={(event) => {setUserDetails({...userDetails, name: event.target.value})}} placeholder="Pablo Chocobar"/>
      </AnInputBox>
    </AnPopup>)
  }

  return(
    <>
      <div style={{maxWidth: 640, width: '100%', margin: '24px auto 0px', border: '1px solid #E5E5E5', borderRadius: 8, boxSizing: 'border-box'}}>
        <div style={{padding: 24, display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #E7ECEB'}}>
          <div className="text_title_big">
            My Profile
          </div>          
          <ConditionalRender if={canEditProfile}>
            <Link to="/settings/profile" className="text_link" style={{display: 'flex', alignItems: 'center', textDecoration: 'none'}}>
              <img src="/edit.svg" style={{width: 20, height: 20, marginRight: 8}}/>
              <div>Edit Profile</div>
            </Link>
          </ConditionalRender>
        </div>
        <div style={{padding: 24}}>
          <table>
            <colgroup>
              <col span="1" style={{width: "33%"}} />
              <col span="1" style={{width: "33%"}} />
            </colgroup>
            <tbody>
              <tr style={{borderBottom: '1px solid #E7ECEB'}}>
                <td className="text_caption_light" style={{padding: '16px 0px', border: 'none'}}>NAME</td>
                <td className="text_body_big" style={{padding: '16px 0px', border: 'none'}}>{userDetails.name}</td>
              </tr>
              <tr style={{borderBottom: '1px solid #E7ECEB'}}>
                <td className="text_caption_light" style={{padding: '16px 0px', border: 'none'}}>TITLE</td>
                <td className="text_body_big" style={{padding: '16px 0px', border: 'none'}}>{userDetails.position}</td>
              </tr>
              <tr style={{borderBottom: '1px solid #E7ECEB'}}>
                <td className="text_caption_light" style={{padding: '16px 0px', border: 'none'}}>INSTITUTION</td>
                <td className="text_body_big" style={{padding: '16px 0px', border: 'none'}}>{userDetails.institute}</td>
              </tr>
              <tr style={{borderBottom: '1px solid #E7ECEB'}}>
                <td className="text_caption_light" style={{padding: '16px 0px', border: 'none'}}>EMAIL</td>
                <td className="text_body_big" style={{padding: '16px 0px', border: 'none'}}>{userDetails.email}</td>
              </tr>
              <tr style={{borderBottom: '1px solid #E7ECEB'}}>
                <td className="text_caption_light" style={{padding: '16px 0px', border: 'none'}}>PHONE</td>
                <td className="text_body_big" style={{padding: '16px 0px', border: 'none'}}>{userDetails.phone}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div style={{display: 'flex', maxWidth: 640, width: '100%', margin: '24px auto 0px', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap'}}>
        { checkUserAccess('team_managing') &&  renderManageYourTeam()}
        { canEditProfile && renderNotificationPreferences()}
      </div>
    </>
  )
}

export default ProfileShow
