import { useEffect, useRef, useState } from "react";
import SuccessGreen from "../../assets/SuccessGreen.svg";
import Cross from "../../assets/Cancel.svg";
import WarningExclamation from "../../assets/WarningExclamation.svg";
import ErrorExclamation from "../../assets/ErrorExclamation.svg"
import PropTypes from "prop-types";

const ICONS_MAP = {
    warning: WarningExclamation,
    success: SuccessGreen,
    error: ErrorExclamation,
};

export const FlashMessages = ({
    duration = 5000,
    closeCallback,
    pauseOnHover = true,
    messageTheme = "warning",
    typoGraphy = { className: "text_body" },
    firstIcon = true,
    ...props
}) => {
    const [isDisplayable, setIsDisplayable] = useState(true);
    const resumedAt = useRef(null);
    const remainingDuration = useRef(duration || 5000);
    const countDownTimer = useRef(null);

    useEffect(() => {
        resumeDuration();
        return () => {
            clearTimeout(countDownTimer.current);
        };
    }, []);

    const resumeDuration = () => {
        clearTimeout(countDownTimer.current);
        resumedAt.current = new Date();
        countDownTimer.current = setTimeout(
            () => forceCancel(),
            remainingDuration.current
        );
    };

    const pauseDuration = () => {
        if (pauseOnHover) {
            clearTimeout(countDownTimer.current);
            remainingDuration.current =
                remainingDuration.current - (new Date() - resumedAt.current);
        }
    };

    const forceCancel = () => {
        clearTimeout(countDownTimer.current);
        closeCallback(null);
        // setIsDisplayable(false);
    };

    return isDisplayable ? (
        <div
            onMouseEnter={pauseDuration}
            onMouseLeave={resumeDuration}
            className={`flash_message_container ${messageTheme} ${typoGraphy.className}`}
            style={props.style}
        >
            {firstIcon ? (
                <img
                    src={ICONS_MAP[messageTheme]}
                    style={{ marginRight: 10, width: 20 }}
                />
            ) : null}
            <div style={{ marginRight: 8 }}>{props.children}</div>
            <img
                src={Cross}
                onClick={forceCancel}
                style={{ cursor: "pointer", width: 20 }}
            />
        </div>
    ) : null;
};

FlashMessages.propTypes = {
    duration: PropTypes.number,
    closeCallback: PropTypes.func.isRequired,
    pauseOnHover: PropTypes.bool,
    messageTheme: PropTypes.string,
    typoGraphy: PropTypes.object,
    firstIcon: PropTypes.bool,
};
