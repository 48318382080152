import React, { useReducer } from 'react'
import DarkDropDown from "../../../../assets/darkDropdown.svg"//"../../../../assets/darkDropDown.svg";
import ConditionalRender from '../../../../ConditionalRender';
import { indexOfObject } from '../../../utils/ArrayUtils';
import { AnInputCheckBox } from '../../../input_utils/AnInputCheckBox';
import { FilterSuggestionBox } from './utils/FiltersSuggestionBox';
import { AnInputBox } from '../../../input_utils/AnInput';
import { FlashMessages } from '../../../cta_utils/FlashMessages';
import { AppliedFilters } from '../../../utils/AppliedFilters';
import { SeeAllFilters } from './utils/SeeAllFilters';

const initialListInfo = {name: '', description: ''}
const DataMineProspectsFilters = ({filterState, setFilterState, list_id, applied_filters, is_parent_list, props_params, ...props}) => {
    const [pageData, setState] = useReducer(reducer, {
        createListInfo: initialListInfo,
        isLoading: {createListLoading: false},
        errors: false,
        popupsList: {},
        successMessages: {},
        activeTab: {}
    })
    const filterType = 'include';
    const {filtersSelected} = filterState;
    const appliedFilters = {...applied_filters}
    const handleTabToggle = (e, tabKey, val) => {
        setFilterState({type: "TOGGLE_TAB", payload: {tabKey, val}});
    }
    const handleFilterCheckBoxChange = (e, mappedKey) => {
        let val = e.target.value;
        if(val === "reset_filter"){
            setFilterState({
                type: "SELECTED_FILTER",
                payload: { mappedKey: mappedKey, selections: [], filterType },
            });
            return;
        }
        let index = indexOfObject(filtersSelected[filterType][mappedKey], "value", val);

        if (index === -1) {
            let new_filt = {value: val};
            let new_val = [...filtersSelected[filterType][mappedKey], new_filt];                        
            setFilterState({
                type: "SELECTED_FILTER",
                payload: { 
                    mappedKey: mappedKey,
                    filterType, 
                    selections: new_val, 
                },
            });
        } else {
            let temp = [...filtersSelected[filterType][mappedKey]];
            temp.splice(index, 1);
            setFilterState({
                type: "SELECTED_FILTER",
                payload: { mappedKey: mappedKey, selections: temp, filterType },
            });
        }
    };
    const handleFilterRangeChange = (e, filterTab, index) => {
        let {name, value} = e.target;
        const mappedKey =  filterTab.tab_key
        let new_filters = [...filtersSelected[filterType][mappedKey]];
        let new_val = {...new_filters[index]};
        let errors = {}, error = false;
        value ? new_val.value[name] = value : new_val.value[name]="";
        new_filters[index] = new_val;
        error = Number(new_val.value['min']) > Number(new_val.value['max'])
        if(error)
        {
            errors["range"] = "Invalid range"
            setState({type:"SET_STATE", payload: { errors: errors}})
            return;
        }
        setFilterState({
            type: "SELECTED_FILTER",
            payload: { mappedKey: mappedKey, selections: new_filters, filterType },
        });
        setState({type:"SET_STATE", payload: {errors: {}}})
    }


    const renderRangeFilter = (filterTab) => {
        return (
            <div className='filter_padding' style={{paddingLeft: 22}}>
                {                    
                    filterState.filtersSelected[filterType][filterTab.tab_key].map((flt, index) => {
                        return(
                        <div key={index}>
                            {/* <ConditionalRender if={flt.label}>
                                <div className='text_body_light'>{flt.label}</div>
                            </ConditionalRender> */}
                            <div className="default_flex_container" 
                                style={{gap: "8px", alignItems: 'flex-start'}}>
                                <AnInputBox inputClass='filter_input_box' error={pageData.errors["range"]} style={{maxWidth: "100px"}}>
                                    <input type="number" disabled={flt.applied} style={{height: 36}} value={flt.value.min} name="min" onChange={(e) => handleFilterRangeChange(e, filterTab, index)} placeholder="Min"></input>
                                </AnInputBox>
                                <span className="text_body_light" style={{marginTop: 20}}>-</span>
                                <AnInputBox inputClass='filter_input_box' style={{maxWidth: "100px"}}>
                                    <input type="number" disabled={flt.applied} style={{height: 36}} value={flt.value.max} name="max" onChange={(e) => handleFilterRangeChange(e, filterTab, index)} placeholder="Max"></input>
                                </AnInputBox>
                                <div style={{clear: "both" }}></div>
                            </div>
                        </div>)
                    })
                }
            </div>
        )
    }
    const renderActiveFilter = (filterTab) => {
        if(filterTab.type === 'range') return renderRangeFilter(filterTab);
        if(!filterState.aggregatesData[filterType]?.[filterTab.tab_key]) return <></>;
        let filter_count = filterState.aggregatesData[filterType][filterTab.tab_key].find((item) => item.total_count != undefined)?.total_count
        return(
            <div className='filter_padding'>
                <ConditionalRender if={filterTab.search_enabled}>
                    <FilterSuggestionBox suggestionUrl={filterTab.suggestion_url}
                        filtersState={filterState}
                        filtersStateReducer={setFilterState}
                        filterTab={filterTab}
                        inputClass="filter_search_box"
                        customInputStyle={{height: 36, paddingLeft: 38}}
                        lens_style={{top: 6, left: 8, width: 24, height: 24}}
                        placeholder={filterTab.placeholder}
                        prop_params={{id: list_id}}
                    ></FilterSuggestionBox>
                </ConditionalRender>
                {!filterTab.search_enabled && (<div style={{marginBottom: 8}}></div>)}
                <ConditionalRender if={filterTab.reset_label}>
                    <div>
                        <AnInputCheckBox
                            label={filterTab.reset_label}
                            isChecked={filterState.filtersSelected[filterType][filterTab.tab_key].length === 0}
                            style={{ marginBottom: '8px'}}
                        >
                            <input type="checkbox" checked={filterState.filtersSelected[filterType][filterTab.tab_key].length === 0} value={"reset_filter"}
                                disabled={appliedFilters[filterTab.tab_key] ? appliedFilters[filterTab.tab_key]?.length !== 0 : false} onChange={(e) => handleFilterCheckBoxChange(e, filterTab.tab_key)}
                            />
                        </AnInputCheckBox>
                    </div>
                </ConditionalRender>
                {
                    filterState.aggregatesData[filterType][filterTab.tab_key].map((item, index) => {
                        if(!item.term) return null;
                        let is_checked = indexOfObject(filtersSelected[filterType][filterTab.tab_key], "value", item.term) !== -1
                        return(
                            <div key={item.term + index}>
                                <AnInputCheckBox
                                    label={`${item.label} ${item.count ? ("(" + item.count + ")") : ""}`}
                                    isChecked={is_checked}
                                    style={{ marginBottom: '8px'}}
                                >
                                    <input type="checkbox" checked={is_checked} value={item.term} disabled={item.applied}
                                        onChange={(e) => handleFilterCheckBoxChange(e, filterTab.tab_key)}
                                    />
                                </AnInputCheckBox>
                            </div>
                        )
                    })
                }
                <ConditionalRender if={filterTab.search_enabled && filter_count > 5}>
                    <div className="text_link" style={{marginTop: 4}} onClick={()=>setState({type: 'SET_STATE', payload: {activeTab: filterTab, popupsList: {[filterTab.tab_key]: true}}})}>See all</div>
                 </ConditionalRender>
            </div>
        )
        
    }

    return (
        <div style={{width: "100%"}}>
            <div className="flex_container_space_between filter_padding">
                <div className='text_title'>Filters</div>
                <ConditionalRender if={is_parent_list}>
                <div className='text_link_small' onClick={()=> setFilterState({type: 'CLEAR_SELECTED_FILTERS'})} style={{lineHeight: '20px'}}>CLEAR ALL</div>
                </ConditionalRender>
            </div>
            <div className='filter_padding'>
                <AppliedFilters filtersState={filterState} filtersStateReducer={setFilterState}></AppliedFilters>
            </div>
            <div  className="line_seperator_ex_light_grey"  style={{ margin: "11px auto 20px" }}></div>
            {
                filterState.allFilterTabs.map((filterTab, index) => {
                    return(
                        <div key={filterTab.tab_key} >
                            <div className="flex_container_space_between section_animated_arrow filter_padding" style={{ cursor: "pointer"}}
                                onClick={e => handleTabToggle(e, filterTab.tab_key, !filterTab.active)}>
                                <div className="text_subtitle_big">{filterTab.label}</div>
                                <img src={DarkDropDown}
                                    className={filterTab.active ? "to_arrow_up" : "to_arrow_down"}
                                    style={{ width: 24 }} alt="^" 
                                />                            
                            </div>
                            {filterTab.active ? renderActiveFilter(filterTab) : <></>}
                            <ConditionalRender if={index !== filterState.allFilterTabs.length-1}>
                                <div  className="line_seperator_ex_light_grey"  style={{ margin: "20px auto" }}></div>
                            </ConditionalRender>
                        </div>
                    )
                })
            }
            {pageData.popupsList[pageData.activeTab.tab_key] && <SeeAllFilters
                    handleOnClose={() => setState({type: "SET_STATE", payload: {popupsList: {},activeTab: {}}})}
                    filtersSelected={filtersSelected} 
                    filterTab={pageData.activeTab} 
                    props_params={{id: list_id, ...props_params}}
                    setFilterState={setFilterState}/>}
            {pageData.successMessages.message && <FlashMessages messageTheme='success' firstIcon={true} closeCallback={() =>
              setState({type: "SET_STATE", payload: { successMessages: {}}})}>{pageData.successMessages.message}</FlashMessages>}
        </div>
    )
}
const reducer = (state, action) => {
    switch(action.type){
        case "SET_STATE": {
            return {
                ...state,
                ...action.payload
            }
        }
        default: return state;
    }
}
export default DataMineProspectsFilters
