import React, { useState, useEffect } from "react";
import { apiAgent } from "../../../axiosConfig.js";
import Pagination from "../../../Pagination";
import TableHeads from "../../visualizers/tabular/TableHeads.jsx";
import TableRows from "../../visualizers/tabular/TableRows.jsx";
import { LoaderComponent } from "../../utils/LoaderComponent.js";

const SystemGeneratedPosts = () => {
    const [posts, setPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        fetchSystemGeneratedPosts(currentPage);
    }, []);

    const fetchSystemGeneratedPosts = (page) => {
        setIsLoading(true);
        apiAgent({
            method: "get",
            url: '/posts/system_generated_posts',
            params: {
                page: page,
            },
        }).then(
            (result) => {
              console.log(result.data);
                setPosts(result.data.posts_data);
                setTotalPages(result.data.total_pages);
                setIsLoading(false);
                setCurrentPage(page);
            },
            (error) => {
                setIsLoading(false);
            }
        );
    };

    const renderSystemGeneratedPosts = () => {
        return (
          <>
              <Pagination totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={fetchSystemGeneratedPosts}
                style={{padding: "16px 0px 0px"}}
              />
              <table className="data_table" style={{textAlign: "left", marginTop: "24px"}}>
                <TableHeads typoGraphyClass="text_body_big_light" headerClass="table_header_row_seperated">
                  <th style={{width: "40%"}}>Team Name</th>
                  <th style={{width: "60%"}}>Post URL</th>
                </TableHeads>
                <tbody>
                  {posts.map(post => (
                    <TableRows key={post._id} rowClass="table_content_row_seperated text_body">
                    <td>
                      {post.team_name}
                    </td>
                    <td>
                      {"https://news.almaconnect.com/" + "post/" + post.post_id}
                    </td>
                  </TableRows>
                  ))}
                </tbody>
              </table>
          </>
        );
    }

    return (
        <div style={{margin: "25px"}}>
            <div className="text_title_big" style={{margin: "20px 0px"}}>System Generated Posts</div>
            {
                !isLoading ?
                    renderSystemGeneratedPosts() :
                    (<LoaderComponent isLoading={true}  loaderStyle={{width: 32, margin: '36px auto', display: 'block'}}/>)
            }
        </div>
    );
};

export default SystemGeneratedPosts;
