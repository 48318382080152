import React from 'react'
import UnitedStates from "../../../assets/outbound/Logos/united_states.svg"


const ContactUs = () => {
    return (
        <div style={{maxWidth: "1128px", margin: "0px auto"}}>
            <div className="text_title_big" style={{textAlign: "center", paddingTop: 48}}>Contact Us</div>
            <div className='default_flex_container'>
                <img src={UnitedStates} style={{width: 32, height: 32, borderRadius: 16, float: "left"}}></img>
                <div className="text_title" style={{float: "left", marginLeft: 12}}>
                    US
                </div>
            </div>
            <div style={{marginTop: 24}}>
                <div className="default_flex_container" style={{justifyContent: "flex-start"}}>
                    <div style={{flex: "50%"}}>
                        <div style={{display: "flex", justifyContent: "flex-start", flexWrap: "wrap"}}>
                            <div className="text_body" style={{height: "120px", flex: 200, marginRight: 75, marginBottom: 20, lineHeight: "24px"}}>
                                <strong>Address 1:</strong><br/>
                                Delaware<br/>
                                3411 Silverside Rd, Ste 104<br/>
                                Wilmington, New Castle,<br/>
                                DE 19810 USA
                            </div>
                            <div className="text_body" style={{height: "120px", flex: 200, marginBottom: 20, lineHeight: "24px"}}>
                                <strong>Address 2:</strong><br/>
                                California<br/>
                                1928 Oak Knoll Dr,<br/>
                                Belmont, CA 94002 USA<br/>
                            </div>
                        </div>
                        <div classNamw="text_title">
                            Contact
                        </div>
                        <div className='default_flex_container' style={{justifyContent: "flex-start", margiTop: 10, marginBottom: 10}}>
                            <div className="text_body website_body" style={{flex: 200, marginRight: 75, lineHeight: "24px"}}>
                                <strong>Sales & Support:</strong><br/>
                                <a style={{color: "#03f"}} href="mailto:global.sales@almaconnect.com">global.sales@almaconnect.com</a><br/>
                                <a style={{color: "#03f"}} href="tel:+16504075189">+1 (650) 668-5781</a>
                            </div>
                        </div>
                    </div>
                    <div style={{flex: "50%", textAlign: "center", marginBottom: "10px"}}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2695.157542704016!2d-122.29471468940488!3d37.521217611261626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808f9f6bbe3cef15%3A0xfb0f65060d59ecbd!2s1928%20Oak%20Knoll%20Dr%2C%20Belmont%2C%20CA%2094002%2C%20USA!5e0!3m2!1sen!2sin!4v1621494694739!5m2!1sen!2sin"
                                style={{width:"100%", height: "248px", frameborder: "0", border:0}}></iframe>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ContactUs;