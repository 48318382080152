import React, {useState, useEffect, useReducer} from 'react'
import {apiAgent} from '../../../axiosConfig.js'
import {AnInputBox} from '../../input_utils/AnInput.js'
import {Button} from '../../cta_utils/AnButton.js'
import AnPopup from "../../cta_utils/AnPopup";
import AnMultipleSuggestionInputBox from "../../input_utils/AnMultipleSuggestionBox";
import AnDropDown from '../../cta_utils/AnDropDown.js'
import { LoaderComponent } from '../../utils/LoaderComponent.js'
import { FlashMessages } from '../../cta_utils/FlashMessages.js'
import FileUpload from '../../../FileUpload.js'
import SuccessMessagePopup from '../../cta_utils/SuccessMessagePopup.js'
import useCheckUserAccess from '../../custom_hooks/useCheckUserAccess.js'
import ConditionalRender from '../../../ConditionalRender.js'
import getApiUrl from '../../utils/UrlHelper.js'
import { Link } from 'react-router-dom/cjs/react-router-dom.min.js'
import InfoIcon from "../../../assets/Info.svg"
import MenuDots from '../../../assets/MenuDots.svg'
import NoTagsIcon from '../../../assets/3UsersIcon.svg'
import EditPencil from "../../../assets/EditPencil.svg"
import reducer from '../../utils/CommonReducer.js';
import { AnInputCheckBox } from '../../input_utils/AnInputCheckBox.js';
const ManageTags = () => {

    const [pageData, setPageData] = useReducer(reducer, {
        tags: [],
        popupsList: {},
        teamMembers: [],
        isLoading: {},
        selectedTag: {name: '', id: '', index: -1, assigned_members: []},
        responseMessage: {message: "", type: "", firstIcon: ""},
    })

    const setState = (payload) => {
        setPageData({type: "SET_STATE", payload})
    }
    let {tags, teamMembers, popupsList, isLoading, responseMessage, selectedTag} = pageData;
    const checkUserAccess = useCheckUserAccess();
    
    const fetchTagsData = (searchTerm) => {        
        setState({isLoading: {isFetching: true}})
        apiAgent({
            method: 'get',
            url: '/team/tags',
            params: {query: searchTerm}
        }).then(res => {
            setState({isLoading: {}, tags: res.data.data, teamMembers: res.data.team_members})
        }).catch(err => {
            setState({isLoading: {}})
        })
    }
    useEffect(() => {
        fetchTagsData()
    }, []);
    const handleTagInputChange = e => setState({selectedTag: {...selectedTag, [e.target.name]: e.target.value}})

    const handleShowPopup = name => setState({popupsList: {[name]: true}})
    
    const handleOnKeyDown = (e) => {
        if(e.key === 'Enter'){
            fetchTagsData(e.target.value?.trim());
        }
    }

    const handlePopupClose = () => {
        setState({popupsList: {}, selectedTag: {name: '', id: '', index: -1, assigned_members: []}})
    }

    const handleTagClick = (event, index) => {
        setState({selectedTag: {...tags[index], index: index}})
        handleToggleDropdown()
    }

    const handleToggleDropdown = (e) => {
        e?.stopPropagation()        
        setState({popupsList: {...popupsList, tagDropDown: popupsList.tagDropDown ? false : true}})
    }

    const createTag = () => {
        setState({isLoading: {createTag: true}});
        apiAgent({
            method: 'post',
            url: '/team/tags/create_or_update',
            data: {
                tagForm: {
                    name: selectedTag.name,
                    assigned_members: selectedTag.assigned_members?.map(member => member.id) || []
                }
            }
        }).then((res) => {
                setState({isLoading: {}, tags: [...tags, res.data.data[0]], responseMessage: {type: "success", message: "Tag created", firstIcon: true}, popupsList: {}})                
            },
            (error)  => {
                setState({isLoading: {}});
            }
        );
    }

    const updateTag = () => {
        setState({isLoading: {updatingTag: true}});
        apiAgent({
            method: 'post',
            url: '/team/tags/create_or_update',
            data: {
                tagForm: {
                    id: selectedTag.id,
                    name: selectedTag.name,
                    assigned_members: selectedTag.assigned_members?.map(member => member.id) || []
                }
            }
        }).then((res) => {                
                let temp = [...tags]
                temp[selectedTag.index] = res.data.data[0]                
                setState({isLoading: {}, tags: [...temp], responseMessage: {type: "success", message: "Tag updated", firstIcon: true}, popupsList: {}})
            },
            (error)  => {
                setState({isLoading: {}});
            }
        );
    }

    const deleteTag = () => {
        setState({isLoading: {deletingTag: true}})
        apiAgent({
            method: 'post',
            url: getApiUrl("delete_tag"),
            data: {
                tagForm: {
                    id: selectedTag.id,
                }
            }
        }).then((res) => {                
                let temp = [...tags]
                temp.splice(selectedTag.index, 1);
                setState({isLoading: {}, tags: [...temp], responseMessage: {type: "success", message: "Tag and corresponding data will be deleted soon.", firstIcon: true}, popupsList: {}})
            },
            (error)  => {
                setState({isLoading: {}});
            }
        );
    }

    const bulkImportTagsPopup = () => {
        return (
           <SuccessMessagePopup
                maxWidth={440}
           >
            <div className='text_body_light'>
                We are processing your request. Please check back later.
            </div>
           </SuccessMessagePopup>
        )
    }

    const renderSearchBar = () => {
        return(
            <div className="an_search_box" style={{fontSize: 14, width: "100%", maxWidth: "400px", padding: "16px 24px 0px"}}>
                <div style={{position: 'relative'}}>
                    <input type="text" placeholder="Search Tags" onKeyDown={handleOnKeyDown}/>
                    <span className="an_search_lens">
                        <img src="/search.svg" />
                    </span>
                </div>
            </div>
        )
    }

    const renderBulkUploadTags = () => {
        return(
            <AnPopup name="bulkUploadTags" handleOnClosePopup={handlePopupClose} maxWidth={480}>
                <div className='text_title'>Bulk import tags</div>
                <div style={{height: 1, background: '#E7ECEB', margin: '16px 0px'}}></div>
                <div className="text_subtitle" style={{marginTop: 8}}>
                  Procedure for bulk archive through csv:
                </div>
                <ol className="text_body_light" style={{paddingLeft: 20, marginTop: 8}}>
                  <li>
                    Create an excel file with header ‘tags’
                  </li>
                  <li>
                    Fill up the tags that are to be imported.
                  </li>
                  <li>
                    Save as CSV & Upload.
                  </li>
                </ol>
                <FileUpload renderSubmit={false} 
                    uploaderSecondaryText={{style: {display: "none"}}}    
                    submitUrl="/team/tags/bulk_update"
                    submitSuccessMessage={null}
                    onSubmitFinishCallback={() => {
                        setState({popupsList: {bulkImportTagsPopup: true}})
                    }}
                    style={{maxheight: "200px"}}
                />
            </AnPopup>
        )
    }

    const createTagPopup = () => {
        return(
            <AnPopup name="createTagPopup" handleOnClosePopup={handlePopupClose} maxWidth={480}>
                <div className="text_title" style={{marginBottom: 16}}>Create Tag</div>
                <AnInputBox label={"Name"}>
                    <input type="text" name="name" value={selectedTag.name} onChange={handleTagInputChange} placeholder="Top Prospect"/>
                </AnInputBox>                
                <div style={{display: 'flex', marginTop: 16}}>
                    <Button buttonSize="Medium" buttonStyle={{width: 'max-content'}} innerButtonStye={{padding: '8px 20px'}}
                        onClick={() => {createTag()}} isLoading={isLoading.creatingTag}>Create</Button>
                    <Button type="Secondary" buttonSize="Medium" buttonStyle={{width: 'max-content', marginLeft: 16}} innerButtonStye={{padding: '8px 20px'}}
                        onClick={handlePopupClose}>Cancel</Button>
                </div>
            </AnPopup>)
    }

    const updateTagPopup = () => {
        return(
            <AnPopup name="createTagPopup" handleOnClosePopup={handlePopupClose} maxWidth={480}>
                <div className="text_title" style={{marginBottom: 16}}>Edit Tag</div>
                <AnInputBox label={"Name"}>
                    <input type="text" name="name" value={selectedTag.name} onChange={handleTagInputChange} placeholder="Top Prospect"/>
                </AnInputBox>
                <div style={{display: 'flex', marginTop: 16}}>
                    <Button buttonSize="Medium" buttonStyle={{width: 'max-content'}} innerButtonStye={{padding: '8px 20px'}}
                        onClick={() => {updateTag()}} isLoading={isLoading.updatingTag}>Update</Button>
                    <Button type="Secondary" buttonSize="Medium" buttonStyle={{width: 'max-content', marginLeft: 16}} innerButtonStye={{padding: '8px 20px'}}
                        onClick={handlePopupClose}>Cancel</Button>
                </div>
            </AnPopup>)
    }

    const renderAssignMembersPopup = () => {
        return(
            <AnPopup name="assignMembersPopup" handleOnClosePopup={handlePopupClose} maxWidth={480}>
                <div className="text_title" style={{marginBottom: 16}}>Assigned team members</div>
                {
                    teamMembers.map(member => {
                        let isChecked = selectedTag.assigned_members?.filter(m => m.id === member.id).length > 0
                        return(
                            <AnInputCheckBox
                                key={member.id}
                                label={member.name}
                                style={{ marginBottom: 12 }}
                                isChecked={isChecked}
                            >
                                <input
                                    type="checkbox"
                                    style={{ marginRight: 6 }}
                                    value={member.id}
                                    checked={isChecked}
                                    onChange={() => {
                                        let temp = [...selectedTag.assigned_members]
                                        if(!isChecked) temp.push(member)
                                        else temp = temp.filter(m => m.id !== member.id)
                                        setState({selectedTag: {...selectedTag, assigned_members: temp}})
                                    }}
                                >
                                    
                                </input>
                            </AnInputCheckBox>
                        )
                    })
                }
                <div style={{display: 'flex', marginTop: 16}}>                    
                    <Button buttonSize="Medium" buttonStyle={{width: 'max-content'}} innerButtonStye={{padding: '8px 20px'}}
                        onClick={() => {setState({popupsList: {assignMembersConfirmation: true}})}} isLoading={isLoading.updatingTag}>Update</Button>
                    <Button type="Secondary" buttonSize="Medium" buttonStyle={{width: 'max-content', marginLeft: 16}} innerButtonStye={{padding: '8px 20px'}}
                        onClick={handlePopupClose}>Cancel</Button>
                </div>
            </AnPopup>
        )
    }

    const renderAssignedMembers = (tag, index) => {
        return (
        <div className='default_flex_container'>
            <div className="text_body default_flex_container">
                <div className="truncate" style={{maxWidth: "120px"}}>{tag.assigned_members[0].name}</div>
                {(tag.assigned_members.length > 1) && (
                    <div className="text_link" style={{paddingLeft: 4, position: 'relative'}} onClick={() => {setState({selectedTag: {...tag, index: index}, popupsList: {membersDropDown: true}})}}>
                        +{(tag.assigned_members.length - 1)}
                    </div>
                )}
            </div>
        </div>)
    }
    const renderSingleTagDropDown = () => {
        return(
            <AnDropDown style={{position: 'absolute', right: "28px", padding: '20px', background: 'white', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', borderRadius: 4, width: '120px', zIndex: 99}}
                toggleDropDown={handleToggleDropdown}>
                <div style={{cursor: 'pointer', marginBottom: "16px"}} className="text_subtitle_semi_bold" onClick={(e) => {e.stopPropagation(); setState({popupsList: { updateTagPopup: true}})}}>
                    Edit tag
                </div>
                <div style={{cursor: 'pointer'}} className="text_subtitle_semi_bold" onClick={(e) => {e.stopPropagation();setState({popupsList: {deleteTagConfirmation: true}})}}>
                    Delete tag
                </div>
            </AnDropDown>
        )
    }
    const renderMembersDropDown = () => {
        return(
            <AnDropDown style={{position: 'absolute', right: 0, top: "100%", width: "max-content", padding: '20px', background: 'white', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', borderRadius: 4, zIndex: 99}}
                toggleDropDown={() => setState({popupsList: {}})}>
                    {
                        selectedTag.assigned_members.map((member, index) => index === 0 ? (<></>):(
                            <div key={member.id} className='text_subtitle_semi_bold' style={{marginBottom: (index === selectedTag.assigned_members.length-1) ? 0 : "16px"}}>
                                {member.name}
                            </div>
                        ))
                    }
            </AnDropDown>
        )
    }

    const renderSingleTag = (tag, index) => {
        return(
            <tr key={tag.id}>
                <td className="text_body">{tag.name}</td>
                <td className="text_body">
                  {tag.count || 0}
                </td>
                <td className='text_body'>
                    <div className='default_flex_container' style={{position: "relative", width: "max-content"}}>
                        {tag.assigned_members?.length > 0 ? renderAssignedMembers(tag, index) : <div className='text_body_light'>Not Assigned</div>} 
                        <img src={EditPencil} alt="edit" style={{cursor: "pointer", marginLeft: 4}} onClick={() => setState({popupsList: {assignMembersPopup: true}, selectedTag: {...tag, index}})}></img>
                        { popupsList.membersDropDown && index === selectedTag.index ? renderMembersDropDown() : null}
                    </div>
                </td>
                <ConditionalRender if={checkUserAccess('tag_updating')}>
                <td style={{position: 'relative'}} onClick={(event) => handleTagClick(event, index)}>
                    <img src={MenuDots} style={{cursor: 'pointer', width: 20, height: 20}}/>
                    { popupsList.tagDropDown && index === selectedTag.index ? renderSingleTagDropDown() : null}
                </td>
                </ConditionalRender>
            </tr>
        )
    }

    const renderConfirmationPopup = ((confirmationType) => {
        let onSubmit, text, loading, buttonText = null;
        if(confirmationType === "assign_members"){
            onSubmit = updateTag;
            text = "When team members are assigned to a tag, they are added to all the prospects who have the tag. Do you still want to do it?"
            loading = "updatingTag"
            buttonText = "Assign"
        }else{
            onSubmit = deleteTag;
            text = "Deleting this Tag will remove the tag and the team members assigned to prospects using the tag from all the prospects. Are you sure you want to delete it?"
            loading = "deletingTag"
            buttonText = "Delete"
        }
        return () => (
            <AnPopup name="confirmationPopup" handleOnClosePopup={handlePopupClose} maxWidth={480}>
                <div className='text_body' style={{textAlign: "center"}}>{text}</div>
                <div className='default_flex_container' style={{justifyContent: "center", marginTop: 16}}>
                    <Button buttonSize="Medium" buttonStyle={{width: 'max-content'}} innerButtonStye={{padding: '8px 20px'}}
                            onClick={onSubmit} isLoading={isLoading[loading]}>{buttonText}</Button>
                    <Button type="Secondary" buttonSize="Medium" buttonStyle={{width: 'max-content', marginLeft: 16}} innerButtonStye={{padding: '8px 20px'}}
                        onClick={handlePopupClose}>Cancel</Button>
                </div>
            </AnPopup>
        )
    })
    const renderNoTags = () => {
        return(
            <div style={{marginTop: 48}}>
                <img src={NoTagsIcon} style={{width: 200, display: 'block', margin: 'auto'}} />                
                <div className="text_body_big_light_bold" style={{marginTop: 28, textAlign: 'center'}}>
                    No tags to show
                </div>
            </div>
        )
    }

    const renderTags = () => {
        if(tags.length > 0) {
            return (
                <table className="modern_data_table" style={{textAlign: 'left', marginTop: 24}}>
                    <thead>
                        <tr className="text_subtitle_light" style={{background: '#F3F4F5'}}>
                            <th style={{width: '34%'}}>Tag Name</th>
                            <th style={{width: '27%'}}>Prospect Count</th>
                            <th style={{width: '38%'}}>Assigned Team members</th>
                            <ConditionalRender if={checkUserAccess('tag_updating')}>
                            <th style={{width: '1%'}}></th>
                            </ConditionalRender>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tags.map((tag, index) => (
                                renderSingleTag(tag, index)
                            ))
                        }
                    </tbody>
                </table>
            );
        }
        else{
            return renderNoTags()
        }
    }

    return (
        <React.Fragment>
            <div className="manage_tags_page">
                <div className="flex_container_space_between" style={{padding: '24px 24px 0px', alignItems: 'flex-start'}}>
                    <div>
                        <div className="text_title">Tags</div>
                        <div className='default_flex_container' style={{marginTop: 4}}>
                            <img src={InfoIcon} width="20px" height="20px" alt='i' style={{marginRight: "4px"}}></img>
                            <span className='text_body'>
                                <Link to="" className="text_link">Learn more</Link>
                                <span> about assigning tags to prospects</span>
                            </span>
                        </div>
                    </div>
                    <ConditionalRender if={checkUserAccess('tag_creating')}>
                        <Button buttonSize="Medium" 
                            dropOptions={[
                                {
                                    checkUserAccess: checkUserAccess('tag_importing'),
                                    label: 'Import tags',
                                    onClick: () => {handleShowPopup('bulkUploadTags')}
                                },
                                {
                                    checkUserAccess: checkUserAccess('tag_adding'),
                                    label: 'Add tag',
                                    onClick: () => {setState({selectedTag: {}, popupsList: {createTagPopup: true}})}
                                },
                            ]}
                            dropContainerStyles={{width: "100%", marginTop: 4}}
                            innerButtonStye={{padding: '8px 20px'}}>
                            Create Tag
                        </Button>
                    </ConditionalRender>
                </div>                
                <div className='line_seperator_ex_light_grey' style={{marginTop: 16}}></div>
                {renderSearchBar()}
                <div style={{padding: '0px 24px 24px'}}>
                    {isLoading.isFetching ? (<LoaderComponent isLoading={true} loaderStyle={{width: 32, display: 'block', margin: '24px auto'}}/>) : renderTags()}
                </div>
                {popupsList.createTagPopup && createTagPopup()}
                {popupsList.updateTagPopup && updateTagPopup()}
                {popupsList.bulkUploadTags && renderBulkUploadTags()}
                {popupsList.assignMembersPopup && renderAssignMembersPopup()}
                {popupsList.assignMembersConfirmation && renderConfirmationPopup("assign_members")()}
                {popupsList.deleteTagConfirmation && renderConfirmationPopup("delete_tag")()}
                {responseMessage.message && (<FlashMessages closeCallback={() => setState({responseMessage: {}})} messageTheme={responseMessage.type} firstIcon={responseMessage.firstIcon}>{responseMessage.message}</FlashMessages>)}
                {popupsList.bulkImportTagsPopup && bulkImportTagsPopup()}
            </div>
        </React.Fragment>
    )
}

export default ManageTags
