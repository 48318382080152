import { useCallback, useEffect, useMemo, useReducer, useState } from "react"
import { NameInitial } from "../../../cta_utils/AnNameInitial"
import AnPopup from "../../../cta_utils/AnPopup"
import AnMultipleSuggestionInputBox from "../../../input_utils/AnMultipleSuggestionBox"
import { AnSuggestionBox } from "../../../input_utils/AnInput"
import { PROFILE_INFOS } from "./profile_infos"
import { AnInputRadioButton } from "../../../input_utils/AnInputRadioButton"
import ConditionalRender from "../../../../ConditionalRender"
import { Button } from "../../../cta_utils/AnButton"
import info from "../../../../assets/Info.svg"
import getApiUrl from "../../../utils/UrlHelper"
import { apiAgent } from "../../../../axiosConfig"
import { Relationships } from "../Relationships"
import ProspectShowReducer, { initialProspectShowState } from "./reducers/prospectShowReducer"
import { LoaderComponent } from "../../../utils/LoaderComponent"
import ContiueArrow from '../../../../assets/ContinueGreenArrow.svg'
export const MergeProspectPopup = ({
    handlePopupClose,
    prospect_profile,
    prospect_id,
    setSuccessMessageCallback,
    ...props
}) => {
    const [primaryProfileData, primaryProfileDataDispatcher] = useReducer(ProspectShowReducer, initialProspectShowState)
    const [secondaryProfileData, secondaryProfileDataDispatcher] = useReducer(ProspectShowReducer, initialProspectShowState)
    const {prospectProfile: secondaryProspectProfile, mergingFields, preview, isLoading: loading} = secondaryProfileData;
    const setState = (payload) => secondaryProfileDataDispatcher({type: 'SET_STATE', payload: payload})
    const setSecondaryProspectProfile = (prospect) => setState({prospectProfile: prospect});
    const setMergingFields = (fields) => setState({mergingFields: fields});
    const setPreview = (val) => setState({preview: val});
    const setIsLoading = (loading) => setState({isLoading: loading});
    const {prospectProfile} = primaryProfileData
    useEffect(() => {
        if(prospect_id)
        fetchProspectProfile();
        else
        primaryProfileDataDispatcher({type: 'SET_STATE', payload: {prospectProfile: prospect_profile}})
    }, [])
    const fetchProspectProfile = () => {
        primaryProfileDataDispatcher({type: 'SET_STATE', payload: {isLoading: true}})
        apiAgent({
            method: 'get',
            url: `/prospects/${prospect_id}`,
            params: {}
        }).then(res => {
            primaryProfileDataDispatcher({type: 'SET_STATE', payload: {prospectProfile: res.data, isLoading: false}})
        }).catch(err => {

        })
    }
    const handleSelectProspectCallback = (name, prospect) => {
        if(!prospect) return;
        setSecondaryProspectProfile(prospect)
        let tempFields = [...mergingFields]
        let custom_field_ids = [...mergingFields[custom_answer_index].custom_field_ids]
        PROFILE_INFOS.map((item) => {
            if (item.key === 'relationships' && prospectProfile.spouse_relationships?.length === 0 && prospectProfile.other_relationships?.length === 0 && (prospect.spouse_relationships?.length > 0 || prospect.other_relationships?.length > 0))
                tempFields.push(item.key)
            else if (!prospectProfile[item.key] && prospect[item.key])
                tempFields.push(item.key)
        })
        prospect.custom_answers?.map((answer) => {
            let secondary_val = answer?.answer?.join(', ')
            let primary_ans = prospectProfile.custom_answers?.find(ans => ans.id === answer.id)
            let primary_val = primary_ans?.answer?.join(', ')
            if(!primary_val && (primary_val !== secondary_val)){
                let index = custom_field_ids.indexOf(answer.id)
                if(index === -1)
                custom_field_ids.push(answer.id)     
            }
        })
        tempFields[custom_answer_index].custom_field_ids = custom_field_ids
        setMergingFields(tempFields)
    }
    const custom_answer_index = useMemo(() => {
        let index = mergingFields.findIndex(element => {
            return typeof element === 'object' && element !== null && 'custom_field_ids' in element;
          });
        return index;
    }, [mergingFields])
    const submitMergeProspect = () => {
        setIsLoading({ submitMergeLoader: true })
        apiAgent({
            method: 'post',
            url: getApiUrl("merge_prospects"),
            data: { primary_prospect_id: prospectProfile.id, secondary_prospect_id: secondaryProspectProfile.id, merging_fields: [...mergingFields] }
        }).then(res => {
            setIsLoading({ submitMergeLoader: false })
            setSuccessMessageCallback("Merge request submitted and will be processed shortly")
            handlePopupClose();
        }).catch(err => {

        })
    }
    const handleInputChange = (field) => {
        let tempFields = [...mergingFields]
        let index = tempFields.indexOf(field)
        if (index !== -1)
            tempFields.splice(index, 1)
        else
            tempFields.push(field)

        setMergingFields(tempFields)
    }
    const handleCustomFieldChange = (custom_id) => {
        let tempFields = [...mergingFields]
        let custom_field_ids = [...mergingFields[custom_answer_index].custom_field_ids]
        let index = custom_field_ids.indexOf(custom_id)
        if(index !== -1)
            custom_field_ids.splice(index, 1)
        else
            custom_field_ids.push(custom_id)
        tempFields[custom_answer_index].custom_field_ids = custom_field_ids
        setMergingFields(tempFields)
    }
    let prospect_company = useMemo(() => {
        let index = mergingFields.indexOf('company');
        let company = index !== -1 ? secondaryProspectProfile?.company : prospectProfile.company
        return company
    }, [mergingFields])
    const renderRelationships = (item) => {
        let pr_radio_btn = secondaryProspectProfile?.id && (prospectProfile.spouse_relationships?.length > 0 || prospectProfile.other_relationships?.length > 0) ? true : false
        let sec_radio_btn = secondaryProspectProfile.spouse_relationships?.length > 0 || secondaryProspectProfile.other_relationships?.length > 0 ? true : false
        let primary_val = <Relationships containerStyle={{marginLeft: 8, maxWidth: '200px', wordBreak: 'break-all' }} spouse_relationships={prospectProfile.spouse_relationships} other_relationships={prospectProfile.other_relationships}/>
        let secondary_val = <Relationships containerStyle={{marginLeft: 8, maxWidth: '240px', wordBreak: 'break-all' }} spouse_relationships={secondaryProspectProfile.spouse_relationships} other_relationships={secondaryProspectProfile.other_relationships}/>
        return (
            <div key={item.key} className="default_flex_container" style={{ padding: '16px 0px', alignItems: 'flex-start' }}>
                <div className="text_caption_light" style={{ width: '20%', fontWeight: 700, lineHeight: '20px', textTransform: 'uppercase' }}>{item.label}</div>
                <div style={{ width: '40%' }}>
                    <div style={{marginLeft: pr_radio_btn ? 0 : 16 }}>
                    <ConditionalRender if={!preview && pr_radio_btn}>
                        <AnInputRadioButton key={item.key}
                            label={() => primary_val}
                            isChosen={!mergingFields.includes(item.key)}
                            style={{ marginLeft: "16px" }}
                            display_radio_btn={pr_radio_btn}
                        >
                            <input type="radio"
                                checked={!mergingFields.includes(item.key)}
                                onChange={(e) => handleInputChange(item.key)}
                            />
                        </AnInputRadioButton>
                    </ConditionalRender>
                    <ConditionalRender if={!preview && !pr_radio_btn}>
                        <div className="text_body_light">{primary_val}</div>
                    </ConditionalRender>
                    </div>
                    <ConditionalRender if={preview}>
                        <div className="text_subtitle_charcoal_semi_bold">{
                        mergingFields.includes(item.key) ? 
                            <Relationships spouse_relationships={secondaryProspectProfile.spouse_relationships} other_relationships={secondaryProspectProfile.other_relationships}/>
                         : 
                            <Relationships spouse_relationships={prospectProfile.spouse_relationships} other_relationships={prospectProfile.other_relationships}/>
                         }
                         </div>
                    </ConditionalRender>
                </div>
                <div style={{ width: '40%' }}>
                    <div style={{marginLeft: sec_radio_btn ? 0 : 16 }}>
                    <ConditionalRender if={!preview && sec_radio_btn}>
                        <AnInputRadioButton key={item.key}
                            label={() => secondary_val}
                            isChosen={mergingFields.includes(item.key)}
                            style={{ marginLeft: "16px" }}
                            display_radio_btn={sec_radio_btn}
                        >
                            <input type="radio"
                                checked={mergingFields.includes(item.key)}
                                onChange={(e) => handleInputChange(item.key)}
                            />
                        </AnInputRadioButton>
                    </ConditionalRender>
                    <ConditionalRender if={!preview && !sec_radio_btn}>
                        <div className="text_body_light">{secondary_val}</div>
                    </ConditionalRender>
                    </div>
                </div>
            </div>
        )
    }
    const renderCustomAnswers = () => {
        return (
                prospectProfile.custom_answers?.map(answer => {
                    let primary_val = answer.answer?.join(', ')
                    let secondary_answer = secondaryProspectProfile?.custom_answers?.find(sec_ans => sec_ans.id == answer.id)
                    let secondary_val = secondary_answer?.answer?.join(', ')
                    let pr_radio_btn = primary_val && primary_val !== secondary_val && secondaryProspectProfile?.id ? true : false
                    let sec_radio_btn = secondary_val && primary_val !== secondary_val ? true : false
                    let custom_field_ids = mergingFields[custom_answer_index]?.custom_field_ids || []
                    return (
                    <div key={answer.id} className="default_flex_container" style={{ padding: '16px 0px', alignItems: 'flex-start' }}>
                    <div className="text_caption_light" style={{ width: '20%', fontWeight: 700, lineHeight: '20px', textTransform: 'uppercase' }}>{answer.question}</div>
                    <div style={{ width: '40%'}}>
                        <div style={{marginLeft: pr_radio_btn ? 0 : 16 }}>
                        <ConditionalRender if={!preview && pr_radio_btn}>
                            <AnInputRadioButton key={answer.id} label={primary_val} labelStyle={{ maxWidth: '200px', wordBreak: 'break-all' }}
                                isChosen={!custom_field_ids.includes(answer.id)}
                                style={{ marginLeft: "16px" }}
                            >
                                <input type="radio"
                                    checked={!custom_field_ids.includes(answer.id)}
                                    onChange={(e) => handleCustomFieldChange(answer.id)}
                                />
                            </AnInputRadioButton>
                        </ConditionalRender>
                        <ConditionalRender if={!preview && !pr_radio_btn}>
                            <div className="text_body_light">{primary_val}</div>
                        </ConditionalRender>
                        </div>
                        <ConditionalRender if={preview}>
                            <div className="text_subtitle_charcoal_semi_bold">{custom_field_ids.includes(answer.id) ? secondary_val : primary_val}</div>
                        </ConditionalRender>
                    </div>
                    <div style={{ width: '40%' }}>
                        <div style={{marginLeft: sec_radio_btn ? 0 : 16}}>
                        <ConditionalRender if={!preview && sec_radio_btn}>
                            <AnInputRadioButton key={answer.id} label={secondary_val} labelStyle={{ maxWidth: '240px', wordBreak: 'break-all' }}
                                isChosen={custom_field_ids.includes(answer.id)}
                                style={{ marginLeft: "16px" }}
                                display_radio_btn={sec_radio_btn}
                            >
                                <input type="radio"
                                    checked={custom_field_ids.includes(answer.id)}
                                    onChange={(e) => handleCustomFieldChange(answer.id)}
                                />
                            </AnInputRadioButton>
                        </ConditionalRender>
                        <ConditionalRender if={!preview && !sec_radio_btn}>
                            <div className="text_body_light">{secondary_val}</div>
                        </ConditionalRender>
                        </div>
                    </div>
                </div>
                )})
        )
    }
    return (
        <AnPopup name="MergeProspectPopup" handleOnClosePopup={handlePopupClose} maxWidth={900}>
        <ConditionalRender if={primaryProfileData.isLoading}>
            <LoaderComponent isLoading={primaryProfileData.isLoading} ></LoaderComponent>        
        </ConditionalRender>
        <ConditionalRender if={!primaryProfileData.isLoading}>
            <div className="text_title">
                Merge Prospects
            </div>
            <div className="line_seperator_ex_light_grey" style={{ margin: "16px -24px" }}></div>
            <div className="primary_label"><span className="text_caption">Primary</span></div>
            <div className="flex_container_space_between" style={{ padding: '12px 0px 20px', alignItems: 'start' }}>
                <div style={{ display: 'flex' }}>
                    <NameInitial name={prospectProfile.name || prospect_company || ""}
                        width={80}
                        textStyle={{ color: '#4D99DF', fontWeight: 600, fontSize: 32 }}
                        profile_image={prospectProfile.profile_image}
                    ></NameInitial>
                    <div style={{ marginLeft: 12 }}>
                        <div className="text_title">
                            {prospectProfile.name || prospect_company}
                        </div>
                        <div className="text_body_light">
                            {prospectProfile.name && prospect_company}
                        </div>
                        <div className="text_caption_light" style={{ marginTop: 10 }}>
                            Prospect ID: {prospectProfile.id}
                        </div>
                    </div>
                </div>
                <div style={{ maxWidth: '334px', width: '100%' }}>
                    <ConditionalRender if={!preview}>
                        <AnSuggestionBox
                            callback={handleSelectProspectCallback}
                            suggestionUrl="/prospects/search?team_search=true"
                            placeholder="Search Prospects"
                            inputClass="an_search_box"
                            name="Prospect_search"
                            params={{full_profile_info: true, exclude_ids: [prospectProfile.id]}}
                        />
                        {secondaryProspectProfile?.id ?
                            <div className="text_caption_light">
                                Prospect ID: {secondaryProspectProfile.id}
                            </div>
                            :
                            <div className="text_caption">Choose another prospect to merge</div>
                        }
                    </ConditionalRender>
                </div>
            </div>
            <ConditionalRender if={!preview && secondaryProspectProfile?.id}>
                <div className="text_body_light" style={{ paddingBottom: 20 }}>Choose which data to retain from both the prospects:</div>
            </ConditionalRender>
            <div className="merge_prospect_container" style={{ maxHeight: 280, overflowY: 'scroll' }}>
                {
                    PROFILE_INFOS.map((item) => {
                        if (item.key === 'past_educations' || item.key === 'name') return;
                        let primary_val = prospectProfile?.[item.key];
                        let secondary_val = secondaryProspectProfile?.[item.key];
                        let pr_radio_btn = primary_val && primary_val !== secondary_val && secondaryProspectProfile?.id ? true : false
                        let sec_radio_btn = secondary_val && primary_val !== secondary_val ? true : false
                        if (item.key === 'relationships') {
                           return renderRelationships(item)
                        }
                        return (
                            <div key={item.key} className="default_flex_container merge_prospect_item" style={{ alignItems: 'flex-start' }}>
                                <div className="text_caption_light" style={{ width: '20%', fontWeight: 700, lineHeight: '20px', textTransform: 'uppercase' }}>{item.label}</div>
                                <div style={{ width: '40%'}}>
                                    <div style={{marginLeft: pr_radio_btn ? 0 : 16 }}>
                                    <ConditionalRender if={!preview && pr_radio_btn}>
                                        <AnInputRadioButton key={item.key} label={primary_val} labelStyle={{ maxWidth: '200px', wordBreak: 'break-all' }}
                                            isChosen={!mergingFields.includes(item.key)}
                                            style={{ marginLeft: "16px" }}
                                        >
                                            <input type="radio"
                                                checked={!mergingFields.includes(item.key)}
                                                onChange={(e) => handleInputChange(item.key)}
                                            />
                                        </AnInputRadioButton>
                                    </ConditionalRender>
                                    <ConditionalRender if={!preview && !pr_radio_btn}>
                                        <div className="text_body_light">{primary_val}</div>
                                    </ConditionalRender>
                                    </div>
                                    <ConditionalRender if={preview}>
                                        <div className="text_subtitle_charcoal_semi_bold">{mergingFields.includes(item.key) ? secondary_val : primary_val}</div>
                                    </ConditionalRender>
                                </div>
                                <div style={{ width: '40%' }}>
                                    <div style={{marginLeft: sec_radio_btn ? 0 : 16}}>
                                    <ConditionalRender if={!preview && sec_radio_btn}>
                                        <AnInputRadioButton key={item.key} label={secondary_val} labelStyle={{ maxWidth: '240px', wordBreak: 'break-all' }}
                                            isChosen={mergingFields.includes(item.key)}
                                            style={{ marginLeft: "16px" }}
                                        >
                                            <input type="radio"
                                                checked={mergingFields.includes(item.key)}
                                                onChange={(e) => handleInputChange(item.key)}
                                            />
                                        </AnInputRadioButton>
                                    </ConditionalRender>
                                    <ConditionalRender if={!preview && !sec_radio_btn}>
                                        <div className="text_body_light">{secondary_val}</div>
                                    </ConditionalRender>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {renderCustomAnswers()}
            </div>
            <ConditionalRender if={preview}>
                <div className="default_flex_container" style={{ padding: '11px 24px', margin: "8px -24px 16px", background: 'rgba(67, 126, 254, 0.1)', alignItems: 'flex-start' }}>
                    <img src={info} style={{ marginRight: 8, width: 20, height: 20 }}></img>
                    <div className="text_subtitle_light">All the high confidence results from the other prospect will be transferred to this prospect. The other prospect will be permanently deleted.</div>
                </div>
            </ConditionalRender>
                <div className="line_seperator_ex_light_grey" style={{ margin: "8px -24px 24px" }}></div>
                <div className="flex_container_space_between">
                    <div>
                        <ConditionalRender if={preview}>
                            <Button type="Secondary" buttonSize="Medium" buttonStyle={{ width: 'max-content', marginRight: 16 }} innerButtonStye={{ padding: '6px 12px', textTransform: 'none' }}
                                onClick={() => { setPreview(false) }}>
                                <img src={ContiueArrow} style={{transform: "rotate(180deg)", marginRight: 4}}></img>    
                                <span>Back to Editing</span>
                                </Button>
                        </ConditionalRender>
                    </div>
                    <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'end' }}>
                        <Button type="Secondary" buttonSize="Medium" buttonStyle={{ width: 'max-content', marginRight: 24 }} innerButtonStye={{ padding: '8px 20px' }}
                            onClick={() => { setMergingFields([]); handlePopupClose() }}>Cancel</Button>
                        {!preview ?
                            <Button disabled={!secondaryProspectProfile?.id} buttonSize="Medium" buttonStyle={{ width: 'max-content' }} innerButtonStye={{ padding: '8px 20px' }}
                                onClick={() => setPreview(true)}>Preview</Button>
                            :
                            <Button buttonSize="Medium" buttonStyle={{ width: 'max-content' }} innerButtonStye={{ padding: '8px 20px' }}
                                onClick={() => { submitMergeProspect() }} isLoading={loading.submitMergeLoader}>Merge</Button>
                        }
                    </div>
                </div>
            </ConditionalRender>
        </AnPopup>
    )
}