import almaLogo from "../../almanews_logo.svg";
import { Button } from "../cta_utils/AnButton";
import { isWidthDown } from "../utils/designUtils";
import useWindowSize from '../custom_hooks/useWindowSize';
import AnDropDown from "../cta_utils/AnDropDown";
import { Link } from "react-router-dom";
import { useState } from "react";
import GreyDownArrow from '../../assets/GreyArrowDown.svg';
import { useSelector } from "react-redux";
import ConditionalRender from "../../ConditionalRender";
import { Products } from "../views/landing/Products";
import { RecordGA } from "../utils/RecordGA";
import { AmplitudeEventTrack } from "../landing_pages/amplitude_event_track";
const NewsLandingHeader = (props) => {
    const almanews_calendly = "https://calendly.com/almaconnect-news-scanner/custom-demo"
    const windowDimensions = useWindowSize();
    const [isDisplayable, setIsDisplayable] = useState(false);
    const toggleDropDown = () => setIsDisplayable(d => !d);
    const apiKeyStatus = useSelector((state) => state.current_user.apiKeyStatus);

    const productsOptions = () => {
        return (
            <AnDropDown style={{position: 'absolute', padding: '20px', background: 'white', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', borderRadius: 4, width: 'max-content', zIndex: 99, marginTop: 6}}
                toggleDropDown={toggleDropDown} displayOnHover={true}>
                    <Products customStyle={{textDecoration: 'none'}}/>
            </AnDropDown>
        )
    }
    const handleClick = () => {
        const gaProps = {
            label: 'Get a free demo',
            link: almanews_calendly
          };
          RecordGA(gaProps);
          AmplitudeEventTrack('newsheader_get_a_free_demo');
        window.location.href = almanews_calendly;
      }
    return (
        <div className="nl_header_container">
            <div className="default_flex_container nl_header">
            <div className="default_flex_container">
                <a href="/" className="nl_header_text" style={{marginRight: "44px", width: "max-content"}}
                onClick={() => AmplitudeEventTrack('header_almalogo_click')}>
                    <img src={almaLogo} style={{ width: 148 }} />
                </a>
                {!isWidthDown(windowDimensions.width, 940) && 
                <div className="default_flex_container text_subtitle_light">
                    <div className="nl_header_item" onMouseOver={() => setIsDisplayable(true)}>
                        Products
                        <img src={GreyDownArrow} style={{width: 20, height: 20}}></img>
                        {isDisplayable && productsOptions()}
                    </div>                    
                    <a href="/features" onClick={() => AmplitudeEventTrack('header_features')} className="nl_header_item">Features</a>
                    <a href="/plans" onClick={() => AmplitudeEventTrack('header_plans')} className="nl_header_item">Plans</a>
                    <a href="/contact_us" onClick={() => AmplitudeEventTrack('header_contact_us')} className="nl_header_item">Contact us</a>
                </div>
                }
            </div>
            <ConditionalRender if={apiKeyStatus !== "validated"}>
                <div className="default_flex_container">
                    <a href="/login" onClick={() => AmplitudeEventTrack('news_header_login')}>
                        <Button
                            type="Secondary"
                            buttonSize="LessPadding"
                            buttonStyle={{ width: "max-content"}}
                            innerButtonStye={{ height: 40, marginRight: 16, padding: "8px 20px" }}
                        >
                            Login
                        </Button>
                    </a>
                    {!isWidthDown(windowDimensions.width, 940) && 
                        <Button
                            buttonSize="LessPadding"
                            buttonStyle={{width: "max-content"}}
                            innerButtonStye={{ height: 40, padding: "8px 20px" }}
                            onClick={handleClick}
                        >
                            Get a free demo
                        </Button>
                    }
                </div>
            </ConditionalRender>
            <ConditionalRender if={apiKeyStatus === "validated"}>
                <a href="/feed" onClick={() => AmplitudeEventTrack('news_header_go_to_feed')}>
                    <Button
                        type="Secondary"
                        buttonSize="LessPadding"
                        buttonStyle={{ width: "max-content"}}
                        innerButtonStye={{ height: 40, marginRight: 16, padding: "8px 20px" }}
                    >
                        Go to newsfeed
                    </Button>
                </a>
            </ConditionalRender>
            </div>
        </div>
    );
};

export default NewsLandingHeader;
