import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import SubscribeMail from '../../../assets/SubscribeMail.png';
import { apiAgent } from '../../../axiosConfig';
import ConditionalRender from '../../../ConditionalRender';
import { Button } from '../../cta_utils/AnButton';
import { AnInputBox } from '../../input_utils/AnInput';
import { AnInputCheckBox } from '../../input_utils/AnInputCheckBox';
import { LoaderComponent } from '../../utils/LoaderComponent';
import { subscribeApi } from './apiHitters';

const SubscribePage = () => {

    const [uniqId, setUniqId] = useState();
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        team_id: "",
        phone: "",
        title: "",
        institution: "",
        consent: false,
        loading: false,
        errors: {

        }
    });
    const [verifying, setVerifying] = useState(true);
    const history = useHistory();
    useEffect(() => {
        if(!uniqId){
            let searchParams = new URLSearchParams(window.location.search);
            if(searchParams.get('id')) setUniqId(searchParams.get('id'));
            setFormData({...formData, name: searchParams.get('name'), email: searchParams.get('email'), team_id: searchParams.get('team_id')})
        }else{
            subscribeApi({id: uniqId})
            .then(res => {
                if(res.data.subscribed) history.push("/subscribed");
                else setVerifying(false);
            }).catch(err => {
                setVerifying(false);
            })
        }
    }, [uniqId])

    useEffect(() => {
        const image = new Image();
        image.onload = () => setIsImageLoaded(true);
        image.src = SubscribeMail;
    
        return () => {
          image.onload = null;
        };
      }, []);

    const handleFormSubmit = () => {
        let errors = {};
        if(!formData.name) errors["name"] = "Name can't be blank";
        if(!formData.email) errors["email"] = "Email can't be blank";
        if(!formData.consent) errors["consent"] = "Please agree to the conditions."
        if(!uniqId){
            if(!formData.phone) errors["phone"] = "Phone no. can't be blank";
            if(!formData.title) errors["title"] = "Title can't be blank";
            if(!formData.institution) errors["institution"] = "Institution can't be blank";
        }
        if(Object.keys(errors).length > 0){
            setFormData({...formData, errors: errors});
            return;
        }
        setFormData({...formData, errors: errors, loading: true});
        let data_params = (!uniqId)? {name: formData.name, email: formData.email, phone: formData.phone, title: formData.title, institution: formData.institution}
                        : {name: formData.name, email: formData.email, team_id: formData.team_id}

        if(uniqId){
            apiAgent({
                method: 'post',
                url: '/outbound_mail/create',
                data: data_params
            }).then(res => {
                if(res.data.success) history.push("/subscribed")
                else{
                    setFormData({...formData, errors: {server: "User already exists with this email"}});
                }
            }).catch(err => {
                setFormData({...formData, errors: {server: "Something went wrong! Could not subscribe"}});
            })
        }else{
            apiAgent({
                method: 'post',
                url: '/outbound_mail/user_subscribe',
                data: data_params
            }).then(res => {
                if(res.data.success) history.push("/subscribed")
            }).catch(err => {
                setFormData({...formData, errors: {server: "Something went wrong! Could not subscribe"}});
            })
        }
    }

    const handleFormChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value})
    }

    if(uniqId && verifying) return <LoaderComponent isLoading={true} type="FULL_PAGE" loaderStyle={{ width: 64}}/>

    if (!isImageLoaded) {
        return null;
    }
    return (
        <div className='subscribe_container'>
            <img src={SubscribeMail} style={{maxHeight: 624}}></img>
            <div style={{width: "100%", maxWidth: 440}}>
                <div className='text_title_big' style={{textAlign: 'center'}}>Subscribe to free news alerts</div>
                <AnInputBox label="Name" style={{margin: "8px auto 16px"}} error={formData.errors.name}>
                    <input type="text" placeholder="Name" 
                        onChange={handleFormChange}
                        name="name"
                        value={formData.name}
                    ></input>
                </AnInputBox>
                <AnInputBox label="Email" error={formData.errors.email}>
                    <input type="text" placeholder="Email" 
                        onChange={handleFormChange}
                        name="email"
                        value={formData.email}
                    ></input>
                </AnInputBox>
                <ConditionalRender if={!uniqId}>
                    <AnInputBox label="Phone" error={formData.errors.phone}>
                        <input type="text" placeholder="+1 (650) 668-5781" 
                            onChange={handleFormChange}
                            name="phone"
                            value={formData.phone}
                        ></input>
                    </AnInputBox>
                    <AnInputBox label="Title" error={formData.errors.title}>
                        <input type="text" placeholder="Director" 
                            onChange={handleFormChange}
                            name="title"
                            value={formData.title}
                        ></input>
                    </AnInputBox>
                    <AnInputBox label="Institution" error={formData.errors.institution}>
                        <input type="text" placeholder="Harvard Univeristy" 
                            onChange={handleFormChange}
                            name="institution"
                            value={formData.institution}
                        ></input>
                    </AnInputBox>
                </ConditionalRender>
                <AnInputCheckBox 
                    style={{marginTop: "8px"}}
                    label="I want to subscribe to free email service from AlmaConnect News">
                    <input type="checkbox"  
                        onChange={() => setFormData({...formData, consent: !formData.consent})}></input>
                </AnInputCheckBox>
                <Button buttonStyle={{marginTop: "24px"}} buttonSize="Big" 
                    onClick={handleFormSubmit}
                    isLoading={formData.loading}
                    error={formData.errors.consent || formData.errors.server}>
                    Subscribe
                </Button>
            </div>
        </div>
    )
}

export default SubscribePage