import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import SadEmail from '../../../assets/SadEmail.png';
import { apiAgent } from '../../../axiosConfig';
import ConditionalRender from '../../../ConditionalRender';
import { Button } from '../../cta_utils/AnButton';
import { AnInputBox } from '../../input_utils/AnInput';
import { AnInputCheckBox } from '../../input_utils/AnInputCheckBox';
const ALL_REASONS = [
    {
        label: "I don’t see myself utilising your product later",
        key: "not_useful"
    },{
        label: "I don’t find the content relevant enough",
        key: "is_irrelevant"
    },{
        label: "I receive too many emails from AlmaConnect",
        key: "too_many_mails"
    },{
        label: "Others",
        key: "other"
    }
]
const Unsubscribe = () => {
    const [reason, setReasons] = useState("");
    const [detailedReason, setDetailedReason] = useState("");
    const [uniqId, setUniqId] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const history = useHistory();

    useEffect(() => {
        let searchParams = new URLSearchParams(window.location.search);
        if(searchParams.get('id')) setUniqId(searchParams.get('id'));
    }, [])

    const handleReasonChange = (e) => {
        let {value} = e.target;
        let temp = value;
        if(reason === value)
            temp = null;
        setReasons(temp);
    }

    const submitUnsubs = () => {
        if(reason === "other" && !detailedReason){
            setError({details: "Please fill up the details"})
            return;
        }
        if(!reason)
        {
            setError({formError: "Please select one of the reasons."});
            return;
        }
        var overall_reason = reason;
        if(reason === "other")
            overall_reason += " - " + detailedReason
        setError({});
        setLoading(true);
        apiAgent({
            method: "post",
            url: "outbound_mail/unsubscribe",
            data: {id: uniqId, reason: overall_reason }
        }).then(res => {
            if(res.data.success) history.push("/unsubscribed");
            else    setError({formError: "Something went wrong! Can't unsubscribe"})
            setLoading(true);
        }).catch(err => {
            setError({formError: "Something went wrong! Can't unsubscribe"})
            setLoading(true);            
        })

    }
    return (
        <div className='unsubscribe_container'>
            <img src={SadEmail} style={{height: 165, width: 155, margin: "auto"}}></img>
            <div className='text_title_big' style={{marginTop: 24}}>Unsubscribe</div>
            <div className='text_subtitle_big' style={{margin: "14px auto 12px"}}>Please take a moment to tell us why you dont wish to hear from us -</div>
            {
                ALL_REASONS.map((r) => {
                    let is_checked = (reason === r.key);                    
                    return(
                        <div key={r.key} style={{marginBottom: "12px"}}>
                            <AnInputCheckBox 
                                label={r.label}
                                isChecked={is_checked} 
                            >
                                <input type="checkbox" value={r.key} checked={is_checked} onChange={handleReasonChange}></input>
                            </AnInputCheckBox>
                        </div>
                    )
                })
            }
            <ConditionalRender if={reason === "other"}>
                <AnInputBox style={{ marginLeft: "24px"}} error={error.details}>
                    <textarea
                        type="text"
                        value={detailedReason}
                        onChange={(e) => {
                            setDetailedReason(e.target.value);
                        }}
                    ></textarea>
                </AnInputBox>
            </ConditionalRender>
            <Button 
                buttonStyle={{marginTop: "40px"}}
                buttonSize='Big'
                innerButtonStye={{padding: "9px 32px"}}
                onClick={submitUnsubs}
                isLoading={loading}
                error={error.formError}
            >Unsubscribe</Button>
        </div>
    )
}

export default Unsubscribe