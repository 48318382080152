import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react'
import { apiAgent } from '../../../axiosConfig.js';
import Pagination from "../../../Pagination.js";
import Post from '../../feed_components/Post.js';
import { highConfidenceDataReducer, initialState } from './reducers/highConfidenceReducer.js';
import { HighConfidenceProvider } from './high_confidence_context.js';
import { LoaderComponent } from '../../utils/LoaderComponent.js';
import {debounce} from 'lodash'
import FeedPostErrorBoundary from '../../error_handlers/FeedPostErrorBoundary.jsx';
import { datepicker_input_format } from '../../utils/dateUtils.js';
import useWindowSize from '../../custom_hooks/useWindowSize.js';
import AnDropdownFilter from "../../cta_utils/AnDropdownfilter.js";
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min.js';
import { getFilterUrl } from '../../utils/ApplyUrlFilters.js';
import DownloadImage from "../../../assets/download.png";
import SearchLensSad from "../../../assets/SearchLensSad.svg";
import JobUpdatesIcon from "../../../assets/JobupdatesIcon.svg";

import AnPopup from "../../cta_utils/AnPopup.js";
import {Button, TabbedButton} from '../../cta_utils/AnButton.js';
import { FlashMessages } from '../../cta_utils/FlashMessages.js';
import { AnInputBox, AnSuggestionBox } from '../../input_utils/AnInput.js';
import ConditionalRender from '../../../ConditionalRender.js';
import { useSelector } from 'react-redux';
import AnToolTip from "../../cta_utils/AnTooltip.js";
import useCheckUserAccess from '../../custom_hooks/useCheckUserAccess.js';
import AnDropDown from '../../cta_utils/AnDropDown.js';
import { AnInputCheckBox } from '../../input_utils/AnInputCheckBox.js';
import { NewPostFilterOptions } from '../../utils/PostFilterOptions.js';
import getApiUrl from '../../utils/UrlHelper.js';
import { postTypes } from './utils/newsFeedUtils.js';
import { setState } from '../../utils/CommonReducer.js';
import FiltersIcon from '../../../assets/FiltersIcon.svg'
import MatchingArticleDateFilters from '../../admin_views/filters/ArticleBasedFilters/filters/matchingArticleDateFilters.jsx';
import { newsFeedFilterReducer as filterReducer, initialDropDownFilterList } from '../../admin_views/filters/ProspectBasedFilters/reducer/newsFeedFilterReducer.js';
import { isWidthDown } from '../../utils/designUtils.js';
import Icon from '../../utils/Icon.js';
import NewDropdownGroupFilter from '../../cta_utils/NewDropDownGroupFilter.js';
import Tooltip from '../../cta_utils/Tooltip.js';
import { indexOfObject } from '../../utils/ArrayUtils.js';

export const POST_TYPE_MAP = {"Job_Updates": "Post::JobUpdates", "articles": "Post"}

const HighConfidenceResults = ({
    filtersState,
    filtersStateReducer,
    fetchUrl,
    trackViewsEnabled=true,
    displaySearchBar=false,
    newsFeedItems,
    displayFilterSection=true,
    total_count,
    ...props
    }) => {
        const {allFilterTabs} = newsFeedItems
        const {postType, dropDownFilterlist} = filtersState
        const [pageData, pageDataReducer] = useReducer(highConfidenceDataReducer, initialState)    
        const allPostsRef = useRef({})        
        const toTrackPostsIds = useRef({});
        const windowDimensions = useWindowSize();
        const compInitLoaded = useRef();
        const history = useHistory();
        const historyLocation = useLocation();
        const [successMessages, setSuccessMessages] = useState({
            message: "",
            duration: 0,
            from: ""
        });
        
        const [popupsList, setPopupsList] = useState({});
        const checkUserAccess = useCheckUserAccess();
        const current_user = useSelector(state => state.current_user.user)
        const current_team = useSelector(state => state.current_team.team)
        const [shortProfileFields, setShortProfileFields] = useState();
        const controller = useRef();
        let toggleMobile = isWidthDown(windowDimensions.width, "md") && current_team.feed_job_updates_enabled

        useEffect(() => {            
            if(trackViewsEnabled) document.addEventListener("scroll", handleOnScroll);
            return () => document.removeEventListener('scroll', handleOnScroll)
        }, [])

        useEffect(() => {
            let newFiltersSelected = {...filtersState.filtersSelected, post_type: (postType !== 'all') ? [postType] : [] };
            if(props.filtersInUrl){
                let new_url = getFilterUrl(newFiltersSelected, historyLocation.pathname, [], {});
                history.replace(new_url, null);
            }
        }, [filtersState.filtersSelected, props.filtersInUrl, postType])
        useEffect(() => {
            let new_controller = new AbortController();
            controller.current = new_controller
            fetchFeed(1)   
            return () => {
                new_controller.abort()
            }
        }, [filtersState.filtersSelected, filtersState.dateRanges, dropDownFilterlist, pageData.actualSearchTerm, postType])
        
        useEffect(() => {
            apiAgent({
                method: "get",
                url: getApiUrl("get_post_folders"),
                params: {}
            }).then((res) => {
                let folders = res.data.folders;
                const newArray = folders.map((item) => {
                    return { ...item, selected_folder: false };
                });
                pageDataReducer({type: 'SET_STATE', payload: {
                    memberFolders: newArray
                }})

            })
            .catch((err) => {});
        }, [pageData.folderAdded]);

        useEffect(() => {
            apiAgent({
                method: 'get',
                url: '/team/team_details',
                params: {}
            }).then(res => {
                let short_fields = res.data.data.short_profile_fields || [];
                const convertedArray = Object.keys(short_fields).map((key) => ({
                    key: key,
                    label: short_fields[key],
                }));
                setShortProfileFields({prospectFields: convertedArray, fieldsFetched: (convertedArray.length === 0)? false : true})
            }).catch(err => {
                setShortProfileFields({prospectFields: [], fieldsFetched: false});
                console.log("Error fetching team details");
            });
        },[])

        const trackPosts = useCallback(debounce((trackIds) => {
            apiAgent({
                method: "post",
                url: "/posts/mark_viewed",
                data: {
                    trackable_ids: Object.keys(trackIds)
                }
            })
        }, 300), [])

        const handleOnScroll = useCallback(() => {
            for(let singlePostId in allPostsRef.current){
                let elem = allPostsRef.current[singlePostId];
                if(elem.current){
                    const boundTop = elem.current.getBoundingClientRect().top
                    // can be fine tuned as per need
                    if(boundTop >= 0 && boundTop <= window.innerHeight - 200){ 
                        let newId = elem.current.getAttribute('trackid'); 
                        if(!toTrackPostsIds.current[newId]){                            
                            toTrackPostsIds.current[newId] = true
                            trackPosts(toTrackPostsIds.current)
                        }
                    }
                }
            }
        }, [])                
        
        const fetchFeed = (page=1) => {
            if(!compInitLoaded.current && historyLocation.state?.filtersState){
                let {filtersSelected} = historyLocation.state.filtersState;  
                let {filtersState} = historyLocation.state   
                filtersStateReducer({
                    type: "SET_STATE",
                    payload: {...filtersState, filtersSelected: {...filtersSelected}}
                })
                compInitLoaded.current=true;
                return
            }
            compInitLoaded.current=true;
            let {filtersSelected} = filtersState;
            let range_params = {}
            Object.keys(filtersState.dateRanges).forEach(k => {
                if(filtersState.dateRanges[k].value.from && filtersState.dateRanges[k].value.to){
                    let from = filtersState.dateRanges[k].value.from;
                    let to = filtersState.dateRanges[k].value.to;
                    range_params[k] = {from: datepicker_input_format(from), to: datepicker_input_format(to)};
                }
            })
            
            pageDataReducer({type: 'SET_STATE', payload: {isLoaded: false}})
            
            let data_params = {            
                tags: filtersSelected.tags,
                assigned: filtersSelected.assigns,
                title: filtersSelected.title,
                company: filtersSelected.company,
                college: filtersSelected.college,
                location: filtersSelected.location,
                mentioned_for: filtersSelected.mentioned_for,
                miscellaneous: filtersSelected.miscellaneous,
                score_tags: filtersSelected.score_tags,
                page: page,
                view_type: dropDownFilterlist.viewType,
                date_ranges: range_params,
                searchTerm: pageData.searchTerm,
                sort: filtersSelected.sort?.[0]                
            }
            data_params.post_type = POST_TYPE_MAP[postType];

            allFilterTabs?.filter(item => item.is_custom).forEach(element => {
                if(filtersSelected[element.tab_key]?.length > 0){
                    if(!data_params["custom_answers"]) data_params["custom_answers"] = {};
                    data_params["custom_answers"][element.tab_key] = filtersSelected[element.tab_key]
                }
            }); 

            if(dropDownFilterlist.containComment == 'contains_comments'){
                data_params["has_comments"] = true;
            }
            else if(dropDownFilterlist.containComment == 'no_comments'){
                data_params["has_comments"] = false;
            }

            if(dropDownFilterlist.review == 'reviewed')
                data_params["reviewed"] = true;
            else if(dropDownFilterlist.review == 'not_reviewed')
                data_params["reviewed"] = false;
            
            apiAgent({
                method: 'get',
                url: fetchUrl,
                params: data_params,
                signal: controller.current.signal
            }).then(
                (response) => {
                    let result = response.data;  
                    filtersStateReducer({type: "ASSIGN_AGGREGATES_DATA", payload: {aggregates: result.aggregates}})
                    allPostsRef.current = [];
                    toTrackPostsIds.current = [];
                    pageDataReducer({type: 'SET_STATE', payload: {
                        isLoaded: true,
                        items: result.data,
                        totalPages: result.total_pages,
                        currentPage: page,
                        tagsList: result.team_tags,
                        assigneeList: result.team_members
                    }})
                    toTrackPostsIds.current = []
                    props.parentReducer({
                        payload: {
                            high_confidence_results_count: response.data.total,
                            mid_confidence_results_count: response.data.mid_confidence_results_count, 
                            low_confidence_results_count: response.data.low_confidence_results_count,
                            allFilterTabs: response.data.all_filter_tabs
                        },
                        type: 'SET_STATE'
                    })
                    setTimeout(handleOnScroll, 500)
                },
                (error)  => {
                    if(error.name !== 'CanceledError')
                    {
                        allPostsRef.current = [];
                        toTrackPostsIds.current = [];
                        pageDataReducer({type: 'SET_STATE', payload: {
                            isLoaded: true,
                            error: error                    
                        }})
                    }
                }
            );
        }  


        const confirmPopup = () => {
            return(
                <AnPopup name="confirmPopup" handleOnClosePopup={() => {setPopupsList({});}} maxWidth={480}>
                  <div className="text_title" style={{marginBottom: 16, textAlign: 'center'}}>Do you want to export news articles</div>
                  
                  <div style={{display: 'flex', marginTop: 16, alignContent: 'center', justifyContent: 'center'}}>
                    <Button buttonSize="Medium" buttonStyle={{width: 'max-content'}} innerButtonStye={{padding: '8px 20px'}}
                            onClick={() => {handleNewsfeedExport()}}>Export</Button>
                    <Button type="Secondary" buttonSize="Medium" buttonStyle={{width: 'max-content', marginLeft: 16}} innerButtonStye={{padding: '8px 20px'}}
                            onClick={() => {setPopupsList({});}}>Cancel</Button>
                  </div>
                </AnPopup>)
        }
        
        const handleNewsfeedExport = () => {
            let {filtersSelected} = filtersState;
            let range_params = {}
            Object.keys(filtersState.dateRanges).forEach(k => {
                if(filtersState.dateRanges[k].value.from && filtersState.dateRanges[k].value.to){
                    let from = filtersState.dateRanges[k].value.from;
                    let to = filtersState.dateRanges[k].value.to;
                    range_params[k] = {from: datepicker_input_format(from), to: datepicker_input_format(to)};
                }
            })            
            let data_params = {            
                tags: filtersSelected.tags,
                assigned: filtersSelected.assigns,
                title: filtersSelected.title,
                company: filtersSelected.company,
                college: filtersSelected.college,
                location: filtersSelected.location,
                mentioned_for: filtersSelected.mentioned_for,
                miscellaneous: filtersSelected.miscellaneous,
                view_type: dropDownFilterlist.viewType,
                date_ranges: range_params
            }
            data_params.post_type = POST_TYPE_MAP[postType];
            apiAgent({
                method: "post",
                url: "/post/export_newsfeed_data",
                params: data_params
            }).then((response) => {
                setSuccessMessages({
                    message: "You will receive an email when the export is ready to download",
                    duration: 3000,
                    from: "Data Exports"
                })
                setPopupsList({});
            }).catch(
                (error) => {
                    console.log(error);
                }
            );
        }

        const dataExportedMessage = (message) => {
            setSuccessMessages({...successMessages, message: message})
        }

        const handleSearchChange = (e) => {
            pageDataReducer({type: 'SET_STATE', payload: {searchTerm: e.target.value}})
        }

        const handleSearchKeyDown = (e) => {
            if(e.key === 'Enter'){
                pageDataReducer({type: 'SET_STATE', payload: {actualSearchTerm: pageData.searchTerm}})
            }
        }
        const renderSearchBar = () => {
            if(!displaySearchBar)return null;
            let placeholder = current_team.feed_job_updates_enabled ? "Search news articles or job updates" : "Search for news articles"
            return(
                <>
                    <div className='default_flex_container' style={{gap: "12px"}}>
                        <div className="an_search_box" style={{fontSize: 14, width: "100%"}}>
                            <div style={{position: 'relative'}}>
                                <input type="text" value={pageData.searchTerm} placeholder={placeholder} onChange={handleSearchChange} onKeyDown={handleSearchKeyDown}/>
                                <span className="an_search_lens">
                                    <img src="/search.svg" />
                                </span>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        let {error, isLoaded, items, totalPages , currentPage,tagsList, assigneeList} = pageData
        const discardCallBack = (curr_post_id, next_post) => {
            let new_items = [...items]
            let curr_post_index = indexOfObject(new_items, "id", curr_post_id)
            if(curr_post_index !== -1)
                new_items.splice(curr_post_index, 1)
            new_items = [...new_items, next_post]
            pageDataReducer({type: 'SET_STATE', payload: {
                items: new_items,
            }})
            props.parentReducer({
                payload: {
                    high_confidence_results_count: total_count - 1,
                },
                type: 'SET_STATE'
            })
        }
        if (error) {
            return <div>Error: {error.message}</div>;
        } else {
            return (
                <React.Fragment>                
                    <div style={{clear: 'both'}}></div>
                    <ConditionalRender if={displayFilterSection}>
                    <div className='flex_container_space_between' style={{margin: '16px 0px 8px 0px'}}>
                            <ConditionalRender if={current_team.feed_job_updates_enabled}>
                            <div className="default_flex_container" style={{ width: "max-content" }}>
                                {
                                    postTypes.map(type => {
                                        let is_active = filtersState.postType === type.key
                                        return (
                                            <div key={type.key} className={is_active ? 'text_link box_border_highlight' : 'text_subtitle_light_grey_semi_bold'} style={{ cursor: 'pointer', padding: '8px 16px', fontWeight: 700 }} onClick={() => setState(filtersStateReducer)({ postType: type.key })}>
                                                {type.label}
                                            </div>
                                        )
                                    })
                                }
                                </div>
                            </ConditionalRender>
                        <div className='default_flex_container' style={{gap: 12}}>
                            <Tooltip
                                tooltipText={() => 'Filters'}
                                innerClass='text_body_light'
                                textTheme='background-black bottom'
                                innerStyle={{ padding: '8px', alignSelf: 'center', color: 'white', borderRadius: '8px', opacity: '70%' }}
                                style={{ marginTop: 4 }}
                                showWithoutOverflowing={true}
                                >
                            <div className={(filtersState.appliedFiltersCount > 0 ? "applied_filter_icon " : "") + "flex_center text_body_light icon_box_container"} style={{ width: 36, height: 36 }}
                                onClick={() => { filtersStateReducer({ type: "SET_POPUP", payload: { showable: true } }) }}>
                                <Icon name="FiltersIcon" className={filtersState.appliedFiltersCount > 0 ? "white_image" : ""}/>
                            </div>
                            </Tooltip>
                            <div>
                                <MatchingArticleDateFilters
                                    filtersState={filtersState}
                                    filtersStateReducer={filtersStateReducer}
                                    tempFilterReducer={filterReducer}
                                    customStyle={{  position: "relative", width: 36, height: 36 }}
                                    innerText=''
                                    position={current_team.feed_job_updates_enabled ? 'left' : 'right'}
                                    theme="GREEN"
                                />
                            </div>
                            <div>
                                <div>
                                    <NewDropdownGroupFilter
                                        filterList = {filtersState.dropDownFilterlist}
                                        onChangeCallback = {(group, option) => filtersStateReducer({type: 'SET_STATE', payload: {dropDownFilterlist: {...filtersState.dropDownFilterlist, [group]: option}}})}
                                        clearFilterCallBack = {() => setState(filtersStateReducer)({dropDownFilterlist: initialDropDownFilterList})}
                                        group_options = {NewPostFilterOptions}
                                        customContainerClass={(NewPostFilterOptions.filter((group) => filtersState.dropDownFilterlist[group.group_key] !== 'all').length > 0  ? "applied_filter_icon " : "") + "flex_center icon_box_container"}
                                        customStyle={{ position: 'relative', width: 36, height: 36}}
                                        positionRight={ !current_team.feed_job_updates_enabled}>
                                    </NewDropdownGroupFilter>
                                </div>
                            </div>
                            <div>
                            <ConditionalRender if={current_user.is_admin || current_user.teams.is_admin}>
                            <AnToolTip
                                tooltipText={'Export in a CSV file \n(filters applicable)'}
                                innerClass='text_body_light'
                                messageTheme='background-black bottom'
                                innerStyle={{marginLeft: -80, marginTop: 8, padding: '4px 16px', alignSelf: 'center', color: 'white', borderRadius: '8px', opacity: '70%'}}
                            >
                                <div className='flex_center icon_box_container' style={{width: 36, height: 36}}
                                onClick={() => {setPopupsList({confirmPopup: true});}}>
                                    <Icon name="Download"/>
                                </div>
                            </AnToolTip> 
                        </ConditionalRender>
                            </div>
                        </div>
                    </div>
                    </ConditionalRender>
                    <ConditionalRender if={displaySearchBar}>
                        {renderSearchBar()}
                    </ConditionalRender>
                    {!isLoaded ? 
                        <LoaderComponent isLoading={true} loaderStyle={{width: 32, margin: '36px auto', display: 'block'}}/>
                    :
                    <div>
                        <div>
                            <Pagination totalPages={totalPages}
                                        currentPage={currentPage}
                                        onPageChange={fetchFeed}
                                        style={{padding: '8px 8px 0px'}}
                            />
                        </div>
                        <div style={{clear: 'both'}}></div>
                    </div>
                    }
                    <ConditionalRender if={items.length === 0 && isLoaded}>
                        <ConditionalRender if={!pageData.actualSearchTerm}>
                            <div style={{textAlign: 'center', marginTop: '24px'}}>
                                <div className="text_subtitle_big">No High Confidence results to show!</div>
                                <div className="text_body_light" style={{marginTop: '8px'}}>Switch to low confidence for news articles</div>
                            </div>
                        </ConditionalRender>
                        <ConditionalRender if={pageData.actualSearchTerm}>
                            <div style={{textAlign: 'center', marginTop: '24px'}}>
                                <img src={SearchLensSad} style={{width: "34px", height: "42px"}} alt="No results" ></img>
                                <div className="text_subtitle" style={{marginTop: '12px'}}>No results match your search criteria</div>
                            </div>
                        </ConditionalRender>
                    </ConditionalRender>
                    <ConditionalRender if={isLoaded}>
                    <div>
                        <HighConfidenceProvider shortProfileFields={shortProfileFields}>                 
                            {items.map(item => (
                                <FeedPostErrorBoundary key={item.id}>   
                                    <Post item={item} 
                                      tagsList={tagsList} 
                                      assigneeList={assigneeList} 
                                      memberFolders={pageData.memberFolders}
                                      filtersState={filtersState} 
                                      windowDimensions={windowDimensions} 
                                      checkUserAccess={checkUserAccess} 
                                      pageDataReducer={pageDataReducer}
                                      folderUpdate={pageData.folderAdded}
                                      searchTerm={pageData.actualSearchTerm} 
                                      allPostsRef={allPostsRef}
                                      currentPage={currentPage}
                                      discardCallBack={(curr_post_id = item.id, next_post) => discardCallBack(curr_post_id, next_post)}
                                    />
                                </FeedPostErrorBoundary>
                            ))}
                        </HighConfidenceProvider>
                    </div>
                    <div style={{marginBottom: "24px"}}>
                        <Pagination totalPages={totalPages}
                                    currentPage={currentPage}
                                    onPageChange={fetchFeed}
                        />
                    </div>
                    {popupsList.confirmPopup && confirmPopup()}
                    {
                        successMessages.message && (<FlashMessages messageTheme='success' duration={successMessages.duration} closeCallback={dataExportedMessage}>{successMessages.message}</FlashMessages>)
                    }
                    </ConditionalRender>
                </React.Fragment>
            );
        }
}

export default HighConfidenceResults;