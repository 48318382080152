import { createContext, useContext } from "react";
import { ALL_MAPPER_FILTER_ACTIONS } from "../../filters/MapperFilters/FiltersStateReducer";

const MapperFilterContext = createContext();

export const useMapperFilterContext = () => useContext(MapperFilterContext);

export const MapperFilterProvider = ({ children, filtersState, filtersStateReducer, handleFilterApply }) => {
    return (
        <MapperFilterContext.Provider value={{ filtersState, filtersStateReducer, handleFilterApply }}>
            {children}
        </MapperFilterContext.Provider>
    );
};
export default MapperFilterContext;
