import ManagingTeamSettings from "../../../../../assets/help_center/manage_team_settings.png"
import InviteTeam from "../../../../../assets/help_center/invite_team.png"
import TeamNotification from "../../../../../assets/help_center/team_notification.png"
import TeamSettings from "../../../../../assets/help_center/team_settings.png"
import AnScrollToLinkPage from "../../../../cta_utils/AnScrollToLinkPage";

export const ManagingTeam = () => {
    return (
        <div style={{ marginTop: 24 }}>
            <div className="help_center_top_section">
                <div className="text_title_big" style={{marginBottom: 20}}>Managing a team</div>
                <div style={{border: "1px solid grey", padding: "14px 20px", borderRadius: "4px", fontWeight: "700"}}>
                    <div className="text_body" style={{color: "#707274", fontWeight: 700}}> SKIP AHEAD TO</div>
                    <div style={{marginTop: "12px"}}>
                        <span style={{color: "#437EFE", fontSize: "14px"}}><AnScrollToLinkPage href="#manage_team"> Manage team </AnScrollToLinkPage></span>
                        <span style={{color: "#437EFE", marginLeft: 20, fontSize: "14px"}}><AnScrollToLinkPage href="#invite_people"> Invite people to your organization </AnScrollToLinkPage></span>
                        <span style={{color: "#437EFE", marginLeft: 20, marginRigt: 77, fontSize: "14px"}}><AnScrollToLinkPage href="#team_notification"> Team notifications </AnScrollToLinkPage></span>
                        <span style={{color: "#437EFE", marginLeft: 20, fontSize: "14px"}}><AnScrollToLinkPage href="#team_settings"> Team settings </AnScrollToLinkPage></span>
                    </div>
                </div>
            </div>
            <div className="help_center" id="manage_team">
                Manage team
            </div>

            <div className="help_center_text">
                You can view the active members currently in the team and team members who have requested to join the team under this tab. 
            </div>
            
            <div style={{ color: '#282A2C', fontSize: '16px', marginTop: "32px", fontWeight: 600, lineHeight: '22px' }}>
                Active team members:
            </div>
            <div className="help_center_text">
                You can view the current team members, change their roles and remove someone from your team.
            </div>
            <img className="help_center_img" src={ManagingTeamSettings} style={{maxWidth: "650px", maxHeight: 356, margin: "12px auto 0px"}}></img>

            <div style={{ color: '#282A2C', fontSize: '16px', marginTop: "32px", fontWeight: 600, lineHeight: '22px' }}>
                Roles:
            </div>
            <div className="help_center_text">
                There are primarily 2 roles in a team - <br/>
                i. Admin user: who has access to all the features and <br/>
                ii. Regular user: who has limited access. A regular user cannot access the following features:
                <ul style={{marginLeft: -6}}>
                    <li>Archiving a prospect</li>
                    <li>Downloading prospect data</li>
                    <li>Manage Team</li>
                    <li>Team Notifications</li>
                    <li>Team settings</li>
                </ul>
            </div>

            <div style={{ color: '#282A2C', fontSize: '18px', marginTop: "48px", fontWeight: 700, lineHeight: '22px' }} id="invite_people">
                Invite people to your organization 
            </div>
            <div className="help_center_text">
                You can add someone to your team by inviting them either by sharing an invite link or by using the invite by email. 
            </div>
            <img className="help_center_img" src={InviteTeam} style={{maxWidth: "650px", maxHeight: "393px", margin: "12px auto 0px"}}></img>

            <div style={{ color: '#282A2C', fontSize: '16px', marginTop: "48px", fontWeight: 600, lineHeight: '22px' }}>
                Shareable invite links 
            </div>
            <div className="help_center_item" style={{ marginTop: 4 }}>
                Shareable invite links make it easy to invite multiple people to a team. Admins can share the link through a variety of channels such as email, Slack, text, etc. To share the link: Click on the invite button and copy the link.
            </div>

            <div style={{ color: '#282A2C', fontSize: '16px', marginTop: "48px", fontWeight: 600, lineHeight: '22px' }}>
                Invite by email
            </div>
            <div className="help_center_item" style={{ marginTop: 4 }}>
                You can also invite team members directly using their email. To invite a single user through email, 
                <ul>
                    <li>Click on the Invite button,</li>
                    <li>Enter the name, email and designation of the person and </li>
                    <li>Click on Invite. </li>
                </ul>
            </div>

            <div className="help_center" id="team_notification">
                Team notifications 
            </div>
            <div className="help_center_text">
                From the Team Notifications tab you can manage the notifications and notification frequency of your team members. 
            </div>
            <img className="help_center_img" src={TeamNotification} style={{maxWidth: "650px", maxHeight: 335, margin: "12px auto 0px"}}></img>

            <div className="help_center" id="team_settings">
                Team settings 
            </div>
            <div className="help_center_text">
                From the Team Settings tab, you can update your team’s name, Linkedin url, country, time zone and team aliases
            </div>
            <img className="help_center_img" src={TeamSettings} style={{maxWidth: "650px", maxHeight: 384, margin: "12px auto 0px"}}></img>

            
        </div>
    );
};