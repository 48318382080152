export const MENTION_TYPES = [
    {
        text: "Comment (Expert / Leadership)",
        value: "Comment (Expert / Leadership)",
    },
    {
        text: "Achievement / Highlighting",
        value: "Achievement / Highlighting",
    },
    {
        text: "Financial Insight of stockholding",
        value: "Financial Insight of stockholding",
    },
    {
        text: "Company achievement",
        value: "Company achievement",
    },
    {
        text: "Award / Recognition",
        value: "Award / Recognition",
    },
    {
        text: "Event Participation",
        value: "Event Participation",
    },
    { text: "Philanthropy", value: "Philanthropy" },
    { text: "Job Updates", value: "Job Updates" },
    { text: "Personal News", value: "Personal News" },
    { text: "Obituary", value: "Obituary" },
    { text: "Negative News", value: "Negative News" },
];

const mention_types = "Comment (Expert / Leadership), Achievement / Highlighting, Financial Insight of stockholding, Company achievement, Award / Recognition, Event Participation, Philanthropy, Job Updates, Personal News, Obituary, Negative News"

export {mention_types}