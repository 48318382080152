import OpenAI from 'openai';

let EMOTION_API_KEY = "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";
let API_KEY = EMOTION_API_KEY;

const openai = new OpenAI({ 
    apiKey: API_KEY, // your API key goes here
    dangerouslyAllowBrowser: true,
    maxRetries: 0
});

const generateGPTResponse = async (prompt, options={}) => {
    return openai.completions.create({
        model: 'text-davinci-003',
        prompt: prompt, 
    });
}

export default generateGPTResponse