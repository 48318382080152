import UIN from "../../../../../assets/help_center/uin.png"
import UINExport from "../../../../../assets/help_center/uin_export.png"
import UINTable1 from "../../../../../assets/help_center/uin_table1.png"
import UINTags from "../../../../../assets/help_center/uin_tags.png"
import AnScrollToLinkPage from "../../../../cta_utils/AnScrollToLinkPage";

export const UpdatingProspectData = () => {
    return (
        <div style={{ margin: "24px 0px" }}>
        <div className="help_center_top_section">
            <div className="text_title_big" style={{marginBottom: 20}}>Updating prospect data</div>
            <div style={{border: "1px solid grey", padding: "14px 20px", borderRadius: "4px", fontWeight: "700"}}>
                <div className="text_body" style={{color: "#707274", fontWeight: 700}}> SKIP AHEAD TO</div>
                <div style={{marginTop: "12px"}}>
                    <span style={{color: "#437EFE", fontSize: "14px"}}><AnScrollToLinkPage href="#UIN" >Unique Identification Number</AnScrollToLinkPage> </span>
                    <span style={{color: "#437EFE", marginLeft: 20, fontSize: "14px"}}><AnScrollToLinkPage href="#uin_export"> Exporting your data to get UIN</AnScrollToLinkPage></span>
                </div>
            </div>
        </div>

            <div className="help_center_text">
                Having a large database of prospects is natural and it is also expected that some information will become outdated over time. Although manually updating each prospect is possible, it may require a lot of your time and effort to do so. For that exact reason, AlmaConnect News allows you to update your prospect data in bulk using the import process with a Unique Identification Number (UIN).
            </div>

            <div id="UIN" className="help_center">
                Unique Identification Number (UIN)
            </div>
            <div className="help_center_text">
                Every prospect that has been imported is assigned with a Unique Identification Number (UIN) upon creation.  You can find this number on the prospect profile page.
            </div>
            <img className="help_center_img" src={UIN} style={{maxWidth: "640px", maxHeight: "244px", margin: "auto"}}></img>

            

            <div className="help_center" id="uin_export">
                Exporting your data to get UIN
            </div>

            <div className="help_center_text">
                You can export your already imported data along with the UIN by clicking on Download data from the prospects page.
            </div>
            <img className="help_center_img" src={UINExport} style={{maxWidth: "640px", maxHeight: "276px", margin: "12px auto 0px"}}></img>

            <div className="help_center_text">
                To update your prospect details, it's necessary to include the Unique Identification Number (UIN) and any other fields you'd like to update or add in the import.
            </div>
            <div className="help_center_text">
                For example, let’s say that you want to change the company name and assign tags for existing prospects. You need to first export the data. Here is one example of what your file would look like when you export it before the changes:
            </div>

            <img className="help_center_img" src={UINTable1} style={{maxWidth: "640px",maxHeight: "130px", margin: "12px auto 0px"}}></img>
            <div className="help_center_text">
                Once exported, make all necessary changes to the data within the file. Here is an example of the same file with changes made to it.
            </div>

            <img className="help_center_img" src={UINTags} style={{maxWidth: "640px", maxHeight: "130px", margin: "12px auto 0px"}}></img>

            <div className="help_center_text">
                Keep in mind that the ID field should not be change; you only need to change the information that needs to be updated or added.
            </div>
            <div className="help_center_text">
            Then import the file on AlmaConnect News, and map ID field with &#60;name&#62; this time. Once the import is done. Our system will automatically update your database with your new information, as it will know which elements you are updating based on the Unique Identification Number (UIN).
            
            </div>
        </div>
    );
};