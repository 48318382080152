import React, { useEffect, useState } from 'react'
import { apiAgent } from '../../../axiosConfig'
import ConditionalRender from '../../../ConditionalRender'
import { LoaderComponent } from '../../utils/LoaderComponent'
import {Button} from "../../cta_utils/AnButton";
import {Link} from "react-router-dom";
import DownloadImage from "../../../assets/download.png";

const ImportHistory = () => {
    const [importsList, setImportsList] = useState([])
    const [fetchingStatuses, _setFetchingStatuses] = useState({
        isFetching: false,
        error: "" 
    })
    useEffect(() => {
        _setFetchingStatuses({isFetching: true, error: ''})
        apiAgent({
            method: 'get',
            url: '/prospect_imports',
            params: {}
        }).then(res => {
            _setFetchingStatuses({isFetching: false, error: ''})
            setImportsList(res.data.data)
        }).catch(err => {
            _setFetchingStatuses({isFetching: false, error: "Something went wrong! Can't fetch Import history at the moment."})
        })
    }, [])
    return (
        <>
            <div style={{maxWidth: 984, width: '100%', margin: '24px auto 0px', border: '1px solid #E7ECEB', borderRadius: 4, boxSizing: 'border-box'}}>
                <div style={{padding: 24}}>
                    <div style={{fontWeight: 700, fontSize: 24, color: '#282A2C'}}>
                    Import history
                    </div>
                </div>
                <ConditionalRender if={(!fetchingStatuses.isFetching && !fetchingStatuses.error)}>
                    <table className="import_history_table">
                        <thead>
                        <tr className="text_subtitle_light">
                                <th style={{width: '38%'}}>
                                    File name
                                </th>
                                <th style={{width: '20%'}}>
                                    Date & Time
                                </th>
                                <th style={{width: '12%'}}>
                                    Imported
                                </th>
                                <th style={{width: '12%'}}>
                                    Failed
                                </th>
                                <th style={{width: '12%'}}>
                                    Updated
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                importsList.map((single_import, index) => {
                                    return(
                                        <tr>
                                            <td>
                                                <Link to={"/import_prospects/"+single_import.id} className="text_body">
                                                    {single_import.name}
                                                </Link>
                                                {single_import.original_file_url && (<a href={single_import.original_file_url} className="text_link">
                                                    <img src={DownloadImage} style={{ cursor: "pointer", height: 16, paddingLeft: 8 }}/>
                                                </a>)}
                                            </td>
                                            <td>
                                                {single_import.created_at}
                                            </td>
                                            <td>
                                                {single_import.imported}
                                                {single_import.import_success_file_url && (<a href={single_import.import_success_file_url} className="text_link">
                                                    <img src={DownloadImage} style={{ cursor: "pointer", height: 16, paddingLeft: 8 }}/>
                                                </a>)}
                                            </td>
                                            <td>
                                                {single_import.imports_failed}
                                                {single_import.import_failure_file_url && (<a href={single_import.import_failure_file_url} className="text_link">
                                                    <img src={DownloadImage} style={{ cursor: "pointer", height: 16, paddingLeft: 8 }}/>
                                                </a>)}
                                            </td>
                                            <td>
                                                {single_import.imports_updated}
                                                {single_import.import_update_file_url && (<a href={single_import.import_update_file_url} className="text_link">
                                                    <img src={DownloadImage} style={{ cursor: "pointer", height: 16, paddingLeft: 8 }}/>
                                                </a>)}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </ConditionalRender>
                <ConditionalRender if={fetchingStatuses.isFetching}>
                    <LoaderComponent isLoading={true} loaderStyle={{width: 32, display: 'block', margin: '0px auto 16px'}}></LoaderComponent>
                </ConditionalRender>
                <ConditionalRender if={fetchingStatuses.error}>
                    <div className='text_subtitle_light' style={{marginBottom: 16, textAlign: 'center'}}>{fetchingStatuses.error}</div>
                </ConditionalRender>
            </div>
        </>
    )
}

export default ImportHistory
