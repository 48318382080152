import AnDropDown from "../../../cta_utils/AnDropDown"
import { ALL_MAPPER_ACTIONS } from "../reducers/TempMapperReducer";

export const SuggestionDropdown = ({pageDataReducer, toggleDropDown}) => {
    const setPopupsList = (payload) => pageDataReducer({type: ALL_MAPPER_ACTIONS['SET_STATE'], payload: {popupsList: payload}})
    return (
        <AnDropDown style={{ position: 'absolute', right: 0, padding: '20px', background: 'white', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', borderRadius: 4, width: 'max-content', zIndex: 99 }}
            toggleDropDown={toggleDropDown}>
            <div className="an_dropdown_filter text_body" style={{ cursor: 'pointer' }} onClick={() => setPopupsList({ summaryPopup: true })}>Summary</div>
            <div className="an_dropdown_filter text_body" style={{ cursor: 'pointer' }} onClick={() => setPopupsList({ generateSuggestionPopup: true })}>Generate Suggestions</div>
            {/* <div className="an_dropdown_filter text_body" style={{cursor: 'pointer'}} onClick={() => setPopupsList({exportPopup: true})}>Download</div> */}
            <div
                className="an_dropdown_filter text_body_light red"
                style={{ cursor: 'pointer' }} onClick={() => setPopupsList({ clearSuggestionPopup: true })}>Clear Suggestions
            </div>
        </AnDropDown>
    )
}