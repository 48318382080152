import { useCallback, useReducer } from "react";
import AnPopup from "../../../../cta_utils/AnPopup";
import { Button } from "../../../../cta_utils/AnButton";
import { AnInputCheckBox } from "../../../../input_utils/AnInputCheckBox";
import ConditionalRender from "../../../../../ConditionalRender";
import { FilterSuggestionBox } from "../../utils/FilterSuggestionBox";
import { matchingArticleFilterReducer } from "../reducers/matchingArticleFilterReducer";
import { AnInputBox } from "../../../../input_utils/AnInput";
import { indexOfObject } from "../../../../utils/ArrayUtils";

export const MatchingArticleBasedFilters = ({
    filtersState,
    filtersStateReducer,
    suggestionUrl = "/prospects/prospect_term_search",
    tempFilterReducer = matchingArticleFilterReducer,
    allFilterTabs,
    ...props
}) => {
    const [tempFiltersState, tempFiltersStateReducer] = useReducer(
        tempFilterReducer,
        { ...filtersState }
    );    
    let { aggregatesData } = tempFiltersState;
    let { filtersSelected } = tempFiltersState;
    const activeFilterType = tempFiltersState.activeFilterType;
    const SubKeyOrTabKey = tempFiltersState.activeFilterTab.sub_key || tempFiltersState.activeFilterTab.tab_key;
    
    const handleFilterCheckBoxChange = (e, range) => {
        let val = e.target.value;
        const filter_type = tempFiltersState.activeFilterType;
        let mappedKey = tempFiltersState.activeFilterTab.tab_key;
        let index = indexOfObject(filtersSelected[filter_type][mappedKey], "value", val);

        if (index == -1) {
            let new_filt = {value: val};
            if(range) new_filt = {...new_filt, ...range}
            let new_val = [...filtersSelected[filter_type][mappedKey], new_filt];                        
            tempFiltersStateReducer({
                type: "SELECTED_FILTER",
                payload: { 
                    mappedKey: mappedKey, 
                    selections: new_val, 
                    selectedVal: val                    
                },
            });
        } else {
            let temp = [...filtersSelected[filter_type][mappedKey]];
            temp.splice(index, 1);
            tempFiltersStateReducer({
                type: "SELECTED_FILTER",
                payload: { mappedKey: mappedKey, selections: temp },
            });
        }
    };

    const handleFilterRangeChange = (e, index) => {
        const {name, value} = e.target;
        const mappedKey = tempFiltersState.activeFilterTab.tab_key;
        let new_filters = [...filtersSelected[activeFilterType][mappedKey]];
        let new_val = {...new_filters[index]};
        let range_value = value;
        if(name == "min") range_value = Math.max(1, Math.min(new_val.max, value));
        if(name == "max") range_value = Math.max(1, Math.max(new_val.min, value));
        new_val[name] = range_value;
        new_filters[index] = new_val;
        tempFiltersStateReducer({
            type: "SELECTED_FILTER",
            payload: { mappedKey: mappedKey, selections: new_filters },
        });
    }

    const handleFilterApply = () => {
        filtersStateReducer({
            type: "APPLY_TEMP_FILTERS",
            payload: { 
                filtersSelected: tempFiltersState.filtersSelected , 
            },
        });
        handlePopupClose();
    };

    const handlePopupClose = () => {
        //do some compare logic to show maybe confirmation popup to discard changes in future
        filtersStateReducer({
            type: "SET_POPUP",
            payload: { showable: false },
        });
    };
    const handleFilterSubTabs = useCallback((e) => {
        tempFiltersStateReducer({
            type: "SET_ACTIVE_FILTER_SUB_TAB",
            payload: { activeFilterTabKey: e.target.getAttribute("name") },
        });
    }, []);

    const renderRanges = (item) => {

        const index = indexOfObject(filtersSelected[activeFilterType][tempFiltersState.activeFilterTab.tab_key], "value", item.term);
        const selected_item = filtersSelected[activeFilterType][tempFiltersState.activeFilterTab.tab_key][index]
        return(
            <div className="default_flex_container" 
                style={{gap: "8px", flexWrap: "wrap", marginTop: "-8px"}}>
                <AnInputBox style={{maxWidth: "120px"}}>
                    <input type="number" value={selected_item.min} name="min" onChange={(e) => handleFilterRangeChange(e, index)} placeholder="Min Score"></input>
                </AnInputBox>
                <span className="text_body_light">-</span>
                <AnInputBox style={{maxWidth: "120px"}}>
                    <input type="number" value={selected_item.max} name="max" onChange={(e) => handleFilterRangeChange(e, index)} placeholder="Max Score"></input>
                </AnInputBox>
            </div>
        )
    }
    const renderActiveFilter = () => {
        return (
            <>
                <div>
                    <ConditionalRender
                        if={tempFiltersState.activeFilterTab.search_bar}
                    >
                        <FilterSuggestionBox
                            suggestionUrl={suggestionUrl}
                            term={tempFiltersState.activeFilterTab}
                            style={{ marginBottom: 8, margintTop: "-6px" }}
                            filtersState={tempFiltersState}
                            filtersStateReducer={tempFiltersStateReducer}
                            placeholder={
                                tempFiltersState.activeFilterTab.search_bar
                                    ?.placeholder
                            }
                            inputClass="an_search_box"
                        ></FilterSuggestionBox>
                    </ConditionalRender>
                    <div className="filter_aggregates_container" style={{gridTemplateColumns: "repeat(1, 1fr)", gridAutoRows: '0fr', height: 310}}>
                        {
                        aggregatesData[activeFilterType][
                           SubKeyOrTabKey
                        ]?.map((item, index) => {
                            let is_checked = indexOfObject(filtersSelected[activeFilterType][tempFiltersState.activeFilterTab.tab_key], "value", item.term) != -1                                
                            return (
                                <div key={item.term + index}>
                                    <AnInputCheckBox
                                        label={`${item.term} ${item.count ? ("(" + item.count + ")") : ""}`}
                                        isChecked={is_checked}
                                        style={{ marginBottom: 16 }}
                                    >
                                        <input
                                            checked={is_checked}
                                            type="checkbox"
                                            value={item.term}
                                            onChange={(e) => {
                                                handleFilterCheckBoxChange(e,
                                                    item.range,
                                                );
                                            }}
                                        />
                                    </AnInputCheckBox>
                                    {is_checked && item.range && renderRanges(item)}
                                </div>
                            );
                        })}
                        <ConditionalRender
                            if={
                                aggregatesData[
                                    SubKeyOrTabKey
                                ]?.length == 0
                            }
                        >
                            <div className="text_body_light">
                                {tempFiltersState.activeFilterTab.empty_text}
                            </div>
                        </ConditionalRender>
                    </div>
                </div>
            </>
        );
    };

    const renderFiltersLeftPane = () => {        
        return allFilterTabs[tempFiltersState.activeFilterType].map((tab) => {
            const subOrtab = tab.sub_key || tab.tab_key; 
            return(
                <>                
                    <div
                        key={subOrtab}
                        className={
                            (SubKeyOrTabKey) == (subOrtab) 
                                ? "text_subtitle"
                                : "text_subtitle_light"
                        }
                        style={{ cursor: "pointer", marginBottom: 20 }}
                        name={subOrtab}
                        onClick={handleFilterSubTabs}
                    >
                        {tab.label}
                    </div>
                </>
    )});
    };

    return (
        <AnPopup
            name="filtersPopup"
            handleOnClosePopup={handlePopupClose}
            maxWidth={640}
        >
            <div className="flex_container_space_between">
                <div className="text_title">Filters</div>
                <div
                    className="text_link"
                    onClick={() =>
                        tempFiltersStateReducer({
                            type: "CLEAR_SELECTED_FILTERS",
                        })
                    }
                >
                    Clear filters
                </div>
            </div>
            <div
                className="line_seperator_ex_light_grey"
                style={{ margin: "16px 0px" }}
            ></div>
            <div style={{display: 'flex', gap: "8px", marginBottom: "24px"}} className="show-tabs">
                <div className= { activeFilterType ==='include' ? 'active-tab show_tab_container':'show_tab_container'}
                    style={{paddingBottom: "8px", }}
                    onClick={() => tempFiltersStateReducer({
                        type: "SET_ACTIVE_FILTER_TYPE",
                        payload: {filterType: "include"}
                    })}
                >
                    <span style={{padding: "0px 8px"}}>Including</span>
                </div>
                <div className={activeFilterType ==='exclude' ? 'active-tab show_tab_container':'show_tab_container'}
                    style={{paddingBottom: "8px"}}
                    onClick={() => tempFiltersStateReducer({
                        type: "SET_ACTIVE_FILTER_TYPE",
                        payload: {filterType: "exclude"}
                    })}
                >
                    <span style={{padding: "0px 8px"}}>Excluding</span>
                </div>
            </div>
            <div
                className="default_flex_container"
                style={{
                    alignItems: "normal",
                    borderBottom: "1px solid #E7ECEB"
                }}
            >
                <div style={{ paddingTop: 24, paddingRight: 21 }}>
                    {renderFiltersLeftPane()}
                </div>
                <div style={{ width: 1, background: "#E7ECEB" }}></div>
                <div style={{ marginLeft: 21, paddingTop: 24, width: "100%" }}>
                    {renderActiveFilter()}
                </div>
            </div>
            <div style={{ display: "flex", marginTop: 24 }}>
                <Button
                    buttonSize="Medium"
                    innerButtonStye={{ padding: "8px 30px" }}
                    onClick={handleFilterApply}
                    isLoading={false}
                >
                    Apply
                </Button>
                <Button
                    buttonSize="Medium"
                    type="Secondary"
                    buttonStyle={{ marginLeft: 20 }}
                    innerButtonStye={{ padding: "8px 28px" }}
                    onClick={handlePopupClose}
                    disabled={false}
                >
                    Cancel
                </Button>
            </div>
        </AnPopup>
    );
};
