import GreyDownArrow from '../../../../../assets/GreyArrowDown.svg' 
import AnDropDown from '../../../../cta_utils/AnDropDown'
import { ALL_MAPPER_FILTER_ACTIONS, initialRangeFilter } from '../FiltersStateReducer'
import cancel from '../../../../../assets/cross.svg'
import { defaultConfidences } from '../MapperSuggestionUtils'
import ConditionalRender from '../../../../../ConditionalRender'
import RangeFilter from './RangeFilter'
import { useMapperFilterContext } from '../../../new_temp_mapper/contexts/mapper_filter_context'
import between from '../../../../../assets/datamine/Mapper Suggestions/between.svg'
import { useState } from 'react'
import { setState } from '../../../../utils/CommonReducer'
import { ResetFilterBtn } from './ResetFilterBtn'
const ConfidenceFilter = ({
    ...props
}) => {
    const {filtersState, filtersStateReducer} = useMapperFilterContext();
    const [dropDownList, setDropDownList] = useState({})
    const toggleDropDown = () => setDropDownList({})
    const appliedConfidence = () => {
        let new_range_filter = {...filtersState.range_filter}
        let range = {...filtersState.range}
        switch (new_range_filter.cond) {
            case "Above": {
                return "≥ " + range.min
            }
            case "Below": {
                return "≤ " + range.max
            }
            case "Between": {
               return <>{range.min} <img src={between} style={{verticalAlign: 'baseline'}}></img> {range.max}</>
            }
            case "Equal": {
                return `= ${range.min}`
            }
        }
    }
    const renderConfidenceDropDown = () => {
        return (
            <AnDropDown
                style={{
                    position: "absolute",
                    background: "white",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                    borderRadius: 4,
                    width: "164px",
                    zIndex: 99,
                }}
                toggleDropDown={toggleDropDown}
            >
                <ConditionalRender if={filtersState.range_filter.min && filtersState.range_filter.max}>
                <ResetFilterBtn resetCallBack={()=> {setState(filtersStateReducer)({range_filter: initialRangeFilter, range: {min: "", max: ""}});toggleDropDown();}}/>
                <div className="line_seperator_ex_light_grey"></div>
                </ConditionalRender>
                <div className='text_body_light' style={{padding: '12px 16px 8px', paddingTop: filtersState.range_filter.min && filtersState.range_filter.max ? '12px' : '16px'}}>Confidence</div>
                {
                    defaultConfidences.map((item, i) => {
                    let isApplied = item.label === filtersState.range_filter.label
                    return (
                    <div key={i} className='filter_item_container'>
                        <div
                            key={item.label}
                            className={ isApplied ? "filter_item text_body select_filter" : "filter_item text_body_light" }
                            onClick={() => {setState(filtersStateReducer)({range_filter: item, range: {min: item.min, max: item.max}});toggleDropDown();}}
                        >
                            {item.label}
                        </div>
                    </div>
                    )})
                }
                <div className="line_seperator_ex_light_grey"></div>
                <RangeFilter rangeLabel={() => "Confidence"} toggleDropDown={toggleDropDown}/>
        </AnDropDown>
        )
    }
    return (
        <div>
            <div style={{ width: 'max-content' }}>
                <div className="default_flex_container filter_btn" style={{ padding: 10, borderRadius: 6 }}
                    onClick={() => setDropDownList({ confidenceDropDownList: true })}>
                    <div className='text_body_big_light_semi_bold'>Confidence</div>
                    {
                        filtersState.range.min && filtersState.range.max ? (
                            <div className='applied_filters_box default_flex_container' style={{ justifyContent: 'center', minWidth: 38, padding: '0px 4px', marginLeft: 4 }}>
                                <span >{appliedConfidence()}</span>
                            </div>
                        ) : (
                            <img src={GreyDownArrow} />
                        )
                    }
                </div>
            </div>
            {dropDownList.confidenceDropDownList && renderConfidenceDropDown()}
        </div>
    )
}

export default ConfidenceFilter