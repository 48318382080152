import React from 'react'

import {apiAgent} from './axiosConfig';
import {Button} from './components/cta_utils/AnButton.js'

import {AnInputBox} from './components/input_utils/AnInput.js'
import { AnInputCheckBox } from './components/input_utils/AnInputCheckBox';
import TryAlmaFree from './components/logout_components/tryAlmaFree.js'
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import ConditionalRender from './ConditionalRender';
import ConfirmMail from './PreAuthRender/ConfirmMail';

class SignupScreen extends React.Component{
    static contextTypes = {
        router: PropTypes.object
    }

    constructor(props, context){
        super(props, context);
        this.state = {
            email: '',
            termsAndServices: false,
            isLoading: false,
            errors: {},            
            shouldRenderFreeTrial: this.props.history?.location?.pathname == '/signup'
        };
    }

    onEmailInput = (event) => {
        this.setState({
            email: event.target.value,
            errors: {...this.state.errors, email: ""}
        })
    }

    onFormSubmit = () => {
        var errors = {}, error = false;
        if(!this.state.email) {
            errors["email"] = "Please enter Email.";
            error = true;
        }
        this.setState({errors: errors});
        if(error)
            return;
        if(!this.state.termsAndServices) {this.setState({errors: {t_and_c_error: "Please accept Terms of Use and Privacy Policy"}}); return;}
        this.setState({isLoading: true, errors: {}});
        apiAgent({
            method: 'post',
            url: '/users/signup',
            data: {
                email: this.state.email.toLowerCase()
            }
        }).then(
            (response) => {
                window.location =  `/confirm?email=${this.state.email}`;
            },
            (error)  => {
                this.setState({...this.state, isLoading: false})
            }
        );
    }

    render(){
        let computed_container_class = this.state.shouldRenderFreeTrial ? "full_signup_component_container" : "only_signup_component_container"
        return (
            <React.Fragment>
                <div className={computed_container_class}>
                    <ConditionalRender if={this.state.shouldRenderFreeTrial}> 
                        <TryAlmaFree />
                    </ConditionalRender>
                    <div className="signup_component">
                        <div className="text_title_big" style={{textAlign: 'center'}}>
                            Signup
                        </div>
                        <div style={{marginTop: '24px'}}>
                            <AnInputBox label={"Your work email"} error={this.state.errors["email"]}>
                                <input type="text" id="email" value={this.state.email} onChange={this.onEmailInput} placeholder="johndoe@domain.com"/>
                            </AnInputBox>
                        </div>
                        <div className='privacy_aggrement_flex_container text_caption' style={{marginTop: 16}}>
                            <AnInputCheckBox isChecked={this.state.termsAndServices}>
                                <input type="checkbox" checked={this.state.termsAndServices} onChange={() => this.setState({termsAndServices: !this.state.termsAndServices})}></input>
                            </AnInputCheckBox>
                            <div style={{marginLeft: 10}}>
                                <span>I accept AlmaConnect News </span> 
                                <a href="https://www.almaconnect.com/terms-of-use" className="text_link_small">Terms of Use</a>
                                <span> and </span>
                                <a href="https://www.almaconnect.com/privacy-policy" className="text_link_small">Privacy Policy</a><span>.</span>
                            </div>
                        </div>
                        <div style={{marginTop: '20px'}}>
                            <Button buttonSize="Big" onClick={this.onFormSubmit} isLoading={this.state.isLoading} buttonStyle={{width: '100%'}} error={this.state.errors?.t_and_c_error}>
                                Get Started
                            </Button>
                        </div>
                        <div className="text_body_light" style={{marginTop: 20, textAlign: 'center'}}>
                            Already have an account?  <a href="/login" className="text_link">Log In</a>
                        </div>
                    </div>
                </div>
                {
                    // (<ReduxTest />)
                }
            </React.Fragment>
        );
    }
}

export default withRouter(SignupScreen);
