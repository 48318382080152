import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import ConditionalRender from '../../../ConditionalRender';
import { Button } from '../../cta_utils/AnButton';
import { apiAgent } from '../../../axiosConfig';
import getApiUrl from '../../utils/UrlHelper';
import Pagination from '../../../Pagination';
import { LoaderComponent } from '../../utils/LoaderComponent';
import ArticleContent from '../../feed_components/ArticleContent';
import KwArticleContent from './_KwArticleContent';
import AnDropdownFilter from '../../cta_utils/AnDropdownfilter';
import SearchLens from "../../../assets/searchLensDark.svg";
import { indexOfObject } from '../../utils/ArrayUtils';
import { dateFormator } from '../../utils/dateUtils';
import SaveLeadPopup from './_SaveLeadPopup';
import Cancel from "../../../assets/Cancel.svg";
import CalendarIcon from "../../../assets/CalendarIcon.svg";
import DropDownIcon from "../../../assets/GreyArrowDown.svg";
import MediaSourcesIcon from "../../../assets/MediaSourcesIcon.svg";
import InfoIcon from "../../../assets/Info.svg";
import AnToolTip from '../../cta_utils/AnTooltip';
import AnPopup from '../../cta_utils/AnPopup';
import { AnInputBox } from '../../input_utils/AnInput';
import AlmaLogo from "../../../assets/alma_logo.png";
import { FlashMessages } from '../../cta_utils/FlashMessages';
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import generateGPTResponse from '../../utils/openAiUtil';
import { saveSearch } from './apiHitter';

const prompt_text = "which of these below 11 comma seperated categories does the text above fit in? Comment (Expert / Leadership), Achievement / Highlighting, Financial Insight of stockholding, Company achievement, Award / Recognition, Event Participation, Philanthropy, Job Updates, Personal News, Obituary, Negative News."

const ALL_DATE_OPTIONS = [{label: "Today", key: 0}, {label: "Last 7 days", key: -7}, {label: "Last 14 days", key: -14}, {label: "Last 30 days", key: -30}, {label: "Last 45 days", key: -45}]
const ALL_MEDIA_SOURCES = [{label: "Top Media", key: "verified"},{label: "All Media", key: "all"}]

const initLoadSearchSpace = (initState, location_state) => {
    if(location_state?.from === "/saved_searches")
        return {...initState, ...location_state.searchSpace}
    return initState;
}

const UserSearchSpace = (props) => {    
    const location = useLocation();
    
    const [searchSpace, setSearchSpace] = useReducer(reducer, {
        description: "",
        mustKeywords: [],
        shouldKeywords: [],
        mustNotKeywords: [],
        dateFilter: {label: "Last 14 days", key: -14}, // 0 means Today, -7 means 7 days ago...
        mediaSourcesType: {label: "All Media", key: "all"}
    }, (st) => initLoadSearchSpace(st, location.state))

    const [pageData, setPageData] = useReducer(reducer, {
        articles: undefined,
        currentPage: 1,
        totalCount: null,
        totalPages: 1,
        isLoading: {},
        errors: {},
        activeInputType: "",
        tempKeyword: "",
        popupsList: {},
        responseMessage: {},
    })
    const [sendResultsData, setSendResultsData] = useState({
        email: "",
        name: ""
    })
    const initialLoader = useRef('not-loaded');
    const fetchSearchSpace = useCallback((sp_id) => {
        setPageState({isLoading: {isFetchingSearchSpace: true}});
        apiAgent({
            method: "get",
            url: getApiUrl("get_single_search_space", {":id": sp_id})
        }).then(res => {
            setPageState({isLoading: {}});
            let {data} = res;
            initialLoader.current = 'searchSpace-loaded'
            setSearchSpaceState({
                id: data.id, 
                mustKeywords: data.and_keywords,
                shouldKeywords: data.or_keywords,
                mustNotKeywords: data.and_not_keywords,
            })
        }).catch(err => {
            window.location.href = "/search";
        })
    }, [])

    const searchArticles = (page=1) => {
        const size = 20;
        setPageState({isLoading: {isFetchingArticles: true}})
        
        let today_date = new Date();
        let data_params = {to: dateFormator(today_date, {type: "custom", format: "yyyy-mm-dd"},"-")}
        today_date.setDate(today_date.getDate() + searchSpace.dateFilter.key)
        data_params.from = dateFormator(today_date, {type: "custom", format: "yyyy-mm-dd"},"-")
        
        if(searchSpace.mediaSourcesType.key === "verified") data_params.rss_candidate_tags = ["verified"];
        
        apiAgent({
            method: "post",
            url: getApiUrl("get_keyword_search_articles"),
            data:{
                all_of_these: searchSpace.mustKeywords,
                any_of_these: searchSpace.shouldKeywords,
                none_of_these: searchSpace.mustNotKeywords,
                size: size,
                skip: (page-1)*size,
                ...data_params
            }
        }).then(res => {
            setPageState({
                isLoading: {isFetchingArticles: false},
                articles: res.data.data,
                currentPage: page,
                totalCount: res.data.total,
                totalPages: res.data.total_pages
            });
        }).catch(err => {
            console.log(err)
            setPageState({isLoading: {isFetchingArticles: false}})
        })
    }

    useEffect(() => {
        if(props.match?.params?.id && !props.location?.state?.from){
            // Implies Visited directly via link
            fetchSearchSpace(props.match?.params?.id);
        }
    }, [fetchSearchSpace, props])

    useEffect(() => {
        if(location.state?.from === "/saved_searches")
        initialLoader.current = 'searchSpace-loaded'
    }, [])

    useEffect(() => {
        if(initialLoader.current === 'searchSpace-loaded')
        {
                searchArticles(1);
                initialLoader.current = 'fetched-articles'
        }
    }, [searchSpace])

    const setSearchSpaceState = (payload) => {
        setSearchSpace({
            type: "SET_STATE",
            payload
        })
    }
    const setPageState = (payload) => {
        setPageData({
            type: "SET_STATE",
            payload
        })
    }

    const handleKeywordChange = (e) => {
        let {value} = e.target;
        setPageState({tempKeyword: value});
    }

    const handleKeywordAdd = (e) => {
        const {value, name} = e.target;
        if (e.key === "Enter" && value?.replaceAll(" ","")) {
            if(!searchSpace[name].some(kw => kw === value)){
                setSearchSpaceState({[name]: [...searchSpace[name], value]});
                setPageState({tempKeyword: ""});
            }
        }
    }

    const handleKeywordRemove = (name, index) => {
        let tempVal = [...searchSpace[name]];
        tempVal.splice(index, 1);
        setSearchSpaceState({[name]: [...tempVal]});
    }

    const renderFitlerButtons = () => {
        return (
            <div className='flex_container_space_between' style={{marginBottom: "18px"}}>
                <div className="default_flex_container">
                    <img src={SearchLens} alt="lens" style={{marginRight: 8}}></img>
                    <div className="text_title">New Search</div>
                </div>
                <div className='default_flex_container' style={{position: "relative", gap: "10px"}}>
                    <div style={{position: "relative"}}>
                        <AnDropdownFilter
                            options={[...ALL_DATE_OPTIONS]}
                            option={searchSpace.dateFilter.key}
                            innerStyle={{right: "0px", width: "100%", marginTop: "8px"}}
                            setOption={(d_type) => {
                                let index = indexOfObject(ALL_DATE_OPTIONS, "key", d_type);
                                if(index !== -1) setSearchSpaceState({dateFilter: {...ALL_DATE_OPTIONS[index]}})
                            }}
                        >
                            <div className='filter_dropdown_box'>
                                <img src={CalendarIcon} alt="Date"></img>
                                <div className='text_body_light'>{searchSpace.dateFilter?.label}</div>
                                <img src={DropDownIcon} alt="\ /"></img>
                            </div>
                        </AnDropdownFilter>
                    </div>
                    <div style={{position: "relative"}}>
                        <AnDropdownFilter
                            options={[...ALL_MEDIA_SOURCES]}
                            option={searchSpace.mediaSourcesType.key}
                            innerStyle={{right: "4px", width: "100%", marginTop: "8px"}}
                            setOption={(d_type) => {
                                let index = indexOfObject(ALL_MEDIA_SOURCES, "key", d_type);
                                if(index !== -1) setSearchSpaceState({mediaSourcesType: {...ALL_MEDIA_SOURCES[index]}})
                            }}
                        >
                            <div className='filter_dropdown_box'>
                                <img src={MediaSourcesIcon} alt="Date"></img>
                                <div className='text_body_light'>{searchSpace.mediaSourcesType.label}</div>
                                <img src={DropDownIcon} alt="\ /" style={{width: "18px", height: "18px"}}></img>
                            </div>
                        </AnDropdownFilter>
                    </div>
                </div>
            </div>
        )
    }
    const renderKeywordCard = ({cardType, conjunction, ...options}) => {
        const {activeInputType, tempKeyword} = pageData;
        
        return(
            <div className='keywords_card_container'>
                <div className='keyword_card'>
                    {searchSpace[cardType].map((kw, index) => {
                        return(
                            <div key={kw + index} className='default_flex_container'>
                                <div className='keyword_pill'>
                                    <div className='text_caption'>{kw}</div>
                                    <img src={Cancel} alt="X" style={{width: "16px", height: "16px", cursor: "pointer"}}
                                        onClick={(e) => handleKeywordRemove(cardType, index)}
                                    ></img>
                                </div>
                                <div className='invisible_pill text_caption_light' style={{padding: "8px"}}>
                                    {conjunction}
                                </div>
                            </div>
                        )
                    })}
                    <div className='invisible_pill' style={{width: "max-content"}}>
                        <ConditionalRender if={activeInputType !== cardType}>
                            <div className='text_link' style={{whiteSpace: "nowrap"}}
                                onClick={() => setPageState({activeInputType: cardType, tempKeyword: ""})}>
                                    + Keyword
                            </div>
                        </ConditionalRender>
                        <ConditionalRender if={activeInputType === cardType}>
                            <input autoFocus={true} value={tempKeyword} name={cardType}
                                className='inline_inputter'
                                onBlur={() => setPageState({activeInputType: "", tempKeyword: ""})}
                                onChange={handleKeywordChange}
                                onKeyDown={handleKeywordAdd}
                            >
                            </input>
                        </ConditionalRender>
                    </div>
                </div>
                <div className="instruction_text">Press enter to add keyword</div>
            </div>
        )
    }

    const sendNewsData = () => {
        if(sendResultsData.name == "" || sendResultsData.email == ""){
            return;
        }
        setPageState({...pageData, isLoading: {sendNewsResults: true}})
        apiAgent({
            method: "post",
            url: '/post/send_sharable_article_news_results',
            data:{
                email: sendResultsData.email,
                name: sendResultsData.name,
                article_ids: pageData.articles.map(article => article.id || article._id).slice(0,5),
                tags: [...searchSpace.mustKeywords, ...searchSpace.shouldKeywords]
            }
        }).then(res => {
            setPageState({...pageData,
                responseMessage: {
                    type: "success",
                    message: "Email sent",
                    firstIcon: true
                }
            });
            setSendResultsData({name: "", email: ""});
            setPageState({popupsList: {}, isLoading: {sendNewsResults: false}});
        }).catch(err => {
            console.log(err);
        })
    }

    const submitSearchSaved = () => {
        setPageState({...pageData, isLoading: {searchSave: true}})
        saveSearch(searchSpace).then(res => {
            setPageState({...pageData,
                responseMessage: {
                    type: "success",
                    message: "Search saved",
                    duration: 3000
                },
                popupsList: {},
                isLoading: {searchSave: false},
                submitted: true
            });
        }).catch(err => {
            console.log(err)
        })
    }

    const sendNewsResultsPopup = () => {
        return (
            <AnPopup name="sendNewsResultsPopup" handleOnClosePopup={() => setPageState({...pageData, popupsList: {}})} maxWidth={388}>
                <div style={{display: "block", margin: "0px auto 16px", width: "fit-content"}}>
                    <img src={AlmaLogo} style={{width: 138, height: 32}}></img>
                </div>
                <AnInputBox label="Name&#42;">
                        <input
                            type="text"
                            id="Name"
                            value={sendResultsData.name}
                            onChange={(e) =>
                                setSendResultsData({...sendResultsData, name: e.target.value})
                            }
                            placeholder="Name"
                        />
                </AnInputBox>
                <AnInputBox label="Email&#42;">
                        <input
                            type="text"
                            id="Email"
                            value={sendResultsData.email}
                            onChange={(e) =>
                                setSendResultsData({...sendResultsData, email: e.target.value})
                            }
                            placeholder="Email"
                        />
                </AnInputBox>
                    <Button buttonStyle={{marginTop: 16}}
                            onClick={() => {sendNewsData()}} isLoading={pageData.isLoading.sendNewsResults}>Submit</Button>
            </AnPopup>
        )
    }

    const renderArticles = () => {
        return(
            <>
                <div className="line_seperator_ex_light_grey"  style={{ margin: "26px 0px 32px" }}></div>
                <div className='articles_container'>
                    <div className='flex_container_space_between'>
                        <div className="text_title">{pageData.totalCount} {pageData.totalCount === 1 ? "Result" : "Results"}</div>
                        <div className="text_link" onClick={() => setPageState({...pageData, popupsList: {sendNewsResultsPopup: true}})}>Send news results</div>
                    </div>
                    <Pagination
                        style={{margin: "24px auto", padding: 0}}
                        totalPages={pageData.totalPages}
                        currentPage={pageData.currentPage}
                        onPageChange={searchArticles}
                    />
                    <ConditionalRender if={pageData.articles.length > 0}>
                        {                        
                            pageData.articles.map(article => {
                                return(
                                    <div key={article.id}>
                                        <KwArticleContent
                                            item={{shared_link: article}}
                                            style={{marginBottom: "16px", alignItems: "flex-start"}}
                                        ></KwArticleContent>
                                    </div>
                                )
                            })
                        }
                    </ConditionalRender>
                    <ConditionalRender if={pageData.articles.length === 0}>
                        <div style={{margin: "98px auto", width: "max-content", textAlign: "center", whiteSpace: "wrap"}}>
                            <div className='text_title'>
                                Oops! Nothing matched your search criteria
                            </div>
                            <div className='text_body_big_light' style={{marginTop: 8}}>
                                Try using different keywords
                            </div>
                        </div>
                    </ConditionalRender>
                </div>                
            </>
        )
    }
    if(pageData.isLoading.isFetchingSearchSpace) return <LoaderComponent isLoading={true} type="FULL_PAGE" loaderStyle={{ width: 64}}/>
    return (
        <div className='user_search_space_root'>
            <div className='flex_container_space_between'>
                <div className='page_title'>Search using Keywords</div>
                <Link to="/saved_searches" className='text_link' style={{textDecoration: "none"}}>Saved Searches</Link>
            </div>
            <div className="line_seperator_ex_light_grey"  style={{ margin: "9px 0px 18px 0px" }}></div>
            {renderFitlerButtons()}
            <div className='all_keywords_container'>
                <div className='card_item'>
                    <div className='default_flex_container' style={{marginBottom: "8px"}}>
                        <div className='text_body'>All of these <span className="text_body_light">(AND) </span></div>
                        <AnToolTip
                            tooltipText={'Your search will show any news articles that contains all of these keywords.'}
                            innerClass='text_body_light'
                            messageTheme='background-light-black bottom'
                            innerStyle={{width: "228px", padding: "9px 12px", whiteSpace: "wrap", left: "-106px", color: "white"}}
                        >
                            <img src={InfoIcon} alt="info"style={{width: 16, height: 16, marginLeft: 6}}></img>
                        </AnToolTip>
                    </div>
                    {renderKeywordCard({cardType: "mustKeywords", conjunction: "AND"})}
                </div>
                <div className='card_item'>
                    <div className='default_flex_container' style={{marginBottom: "8px"}}>
                        <div className='text_body'>Atleast one of these <span className="text_body_light">(OR)</span></div>
                        <AnToolTip
                                tooltipText={'Your search will show any news articles that contains at least one of these keywords, plus all the keywords connected by AND.'}
                                innerClass='text_body_light'
                                messageTheme='background-light-black bottom'
                                innerStyle={{width: "228px", padding: "9px 12px", whiteSpace: "wrap", left: "-106px", color: "white"}}
                            >
                                <img src={InfoIcon} alt="info"style={{width: 16, height: 16, marginLeft: 6}}></img>
                        </AnToolTip>
                    </div>
                    {renderKeywordCard({cardType: 'shouldKeywords', conjunction: "OR"})}
                </div>
                <div className='card_item'>
                    <div className='default_flex_container' style={{marginBottom: "8px"}}>
                        <div className='text_body'>None of these <span className="text_body_light">(AND NOT)</span></div>
                        <AnToolTip
                                tooltipText={'Your search will not show news results with any of these following keywords.'}
                                innerClass='text_body_light'
                                messageTheme='background-light-black bottom'
                                innerStyle={{width: "228px", padding: "9px 12px", whiteSpace: "wrap", left: "-106px", color: "white"}}
                            >
                                <img src={InfoIcon} alt="info"style={{width: 16, height: 16, marginLeft: 6}}></img>
                        </AnToolTip>
                    </div>
                    {renderKeywordCard({cardType: 'mustNotKeywords', conjunction: "AND"})}
                </div>
            </div>
            <div className='default_flex_container' style={{justifyContent: 'flex-end', marginTop: "18px"}}>
                <Button type='Secondary' innerButtonStye={{width: "max-content", padding: "9px 19px", height: "unset", marginRight: "11px"}}
                    onClick={() => submitSearchSaved()}
                >
                    Save search
                </Button>
                <Button type='Primary' innerButtonStye={{width: "max-content", padding: "9px 19px", height: "unset"}}
                    onClick={() => searchArticles(1)}
                    isLoading={pageData.isLoading.isFetchingArticles}
                >
                    Search
                </Button>
            </div>
            {!pageData.isLoading.isFetchingArticles && pageData.articles !== undefined && renderArticles()}                                    
            <ConditionalRender if={pageData.isLoading.isFetchingArticles}>
                <LoaderComponent isLoading={true}></LoaderComponent>
            </ConditionalRender>
            {pageData.popupsList.sendNewsResultsPopup && sendNewsResultsPopup()}
            {pageData.responseMessage.type && (<FlashMessages closeCallback={() => setPageState({responseMessage: {}})} messageTheme={pageData.responseMessage.type} firstIcon={pageData.responseMessage.firstIcon}>{pageData.responseMessage.message}</FlashMessages>)}
        </div>
    )
}

const reducer = (state, action) => {
    switch(action.type){
        case "SET_STATE": {
            return {...state, ...action.payload}
        }
        default: return state
    }
}

export default UserSearchSpace