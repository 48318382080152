import Cross from "../../assets/Cancel.svg"
import RedCross from "../../assets/RedCross.png"
export const AppliedFilters = ({
    filtersState,
    filtersStateReducer,
    innerTextClass="text_body_light",
    outerClass,
    showCancel=true,
    ...props
}) => {
    const handleFilterRemove = (e, mappedKey, index, filterType) => {
        e.stopPropagation();
        let {filtersSelected} = filtersState;        
        filtersSelected[filterType][mappedKey] = [...filtersSelected[filterType][mappedKey]]
        let range_filter = filtersSelected[filterType][mappedKey][0]
        if(filtersSelected[filterType][mappedKey].length > 0 && range_filter.type === 'range')
        {
            range_filter.value.min = ""
            range_filter.value.max = ""
        }
        else
        filtersSelected[filterType][mappedKey].splice(index, 1)
        filtersStateReducer({
            type: "APPLY_TEMP_FILTERS",
            payload: { filtersSelected: filtersSelected },
        });
    }
    return (
    <div className="applied_filter_container">
        {
            Object.keys(filtersState.filtersSelected).map(filterType => {
                return Object.keys(filtersState.filtersSelected[filterType]).map(key => {
                    let filtersSelected = [...filtersState.filtersSelected[filterType][key]];
                    if(filtersSelected.length === 0) return null;
                    return(filtersSelected.map((selected_filter, index) => {
                        let range_text = ""
                        let label = "";
                        if(selected_filter.type && selected_filter.type === 'range')
                        {
                            if((selected_filter.value.min && selected_filter.value.max) === "") return null;
                            if(selected_filter.value.max > 0){
                                range_text = `(${selected_filter.value.min}-${selected_filter.value.max})`
                            }
                            label = selected_filter.label
                        }
                        else{
                            label = selected_filter.value
                        }
                        let computed_class = `applied_filter_item ${filterType==="include" ? 'text_link applied_type_box_highlight_green' : "type_box_highlight_red"}`
                        if(selected_filter.applied)
                        computed_class = `applied_filter_item ${filterType==="include" ? 'text_link disabled_applied_filter_item' : "type_box_highlight_red"}`
                        return (
                            <div key={key + label}                                    
                                className={outerClass || computed_class}
                            >
                                <div className={`${innerTextClass} truncate`} style={{opacity: selected_filter.applied ? "0.5" : "1", marginRight: "6px", textTransform: "capitalize", whiteSpace: 'nowrap'}}>{label} {range_text}</div>
                                {!selected_filter.applied && showCancel && <img src={filterType === "include" ? Cross : RedCross} style={{width: "16px"}} alt="Remove"
                                    onClick={(e) => handleFilterRemove(e, key, index, filterType)} 
                                />}
                            </div>
                        )
                    }))
                })
            })
        }
    </div>
    )
}