import { useState } from "react";
import ALL_PROSPECT_SHOW_ACTIONS from "../actions/prospectProfileShowActions";
import ALL_ACTIONS from "../actions/prospectProfileShowActions";

export const initialProspectShowState = {
    prospectProfile: {},
    tempProfile: {},
    tagsList: [],
    tabType: 'profile',
    posts: [],
    assigneeList: [],
    popupsList: {},
    isLoading: false,
    currentAnswer: {},
    currentTags: [],
    activeRelationTab: 'Spouse',
    currentAssigns: [],
    prospectDropdown: false,
    taggedProspects: [],
    successMessage: "",
    lockedAssigns: {},
    currentEducation: {},
    mergingFields: [{custom_field_ids: []}],
    preview: false,
    isFetching: {}
}

const ProspectShowReducer = (state, action) => {
    switch(action.type){
        case ALL_ACTIONS['SET_STATE']: {
            return {...state, ...action.payload}
        }
        case ALL_ACTIONS['TOGGLE_LOADER']:{
            return {...state, isLoading: !state.isLoading}
        }        
        default:{
            return {...state}
        }
    }
}

export default ProspectShowReducer
