import { apiAgent } from "../../axiosConfig";
import getApiUrl from "../utils/UrlHelper";

export const saveOrRemoveFromProfile = (props) => {
    let {setState, item, parentProps} = props
    setState({ isLoading: true });
    apiAgent({
        method: "post",
        url: getApiUrl("get_save_or_remove_post", {":id": item.id}),
        data: {},
    })
        .then((res) => {
            let success_msg = !item.is_saved ? "Saved to the profile" : "Removed from the profile"
            setState({
                item: {...item, is_saved: !item.is_saved},
                isLoading: false,
                success_message: success_msg,
                messageTheme: "success"
            });
            setState({ show_dropdown: false })      
            if(parentProps.from === 'saved_posts')
            parentProps.removeSavedPostCallBack(item); 
        })
        .catch((err) => {
            setState({ isLoading: false });
        });
}