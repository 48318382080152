import { FRONTEND_CONFIG } from "../../configurations/application_config";


export const applyUrlFilters = (initialState, whitelist=[]) => {
    if(whitelist.length == 0)   whitelist = Object.keys(initialState.filtersSelected);
    let url_params = new URLSearchParams(window.location.search);
    let whitelisted_param =  whitelist.filter( key => url_params.get(key)).reduce((res, key) => (res[key] = JSON.parse(url_params.get(key)), res), {})
    initialState.filtersSelected = {...initialState.filtersSelected, ...whitelisted_param}
    let appliedFiltersCount = Object.keys(
        initialState.filtersSelected
    ).reduce(
        (prev, curr) =>
            prev + initialState.filtersSelected[curr].length,
        0
    );
    let type = url_params.get("post_type");
    if(type){
        type = type.replace(/"/g, "").slice(1, -1);
        initialState.postType = type
    }
    initialState.appliedFiltersCount = appliedFiltersCount
    return initialState
}

export const getFilterUrl = (filterState, pathname="/", select_keys=[], options={type: "full"}) => {
    if(select_keys.length == 0) select_keys = Object.keys(filterState);
    let search_p = select_keys.filter(k => filterState[k].length > 0).reduce((res, k) => {res[k] = filterState[k];return res}, {})
    let url = new URL(pathname, FRONTEND_CONFIG.WEB_URL)
    
    Object.keys(search_p).forEach(k => {
        let v = search_p[k];
        if(!v) return;
        if(v && v.length == 0) return;
        url.searchParams.append(k, JSON.stringify(v));
    })
    if(options.type=="full") return url.href;
    return url.pathname + url.search
}