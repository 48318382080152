import { useSelector } from "react-redux";
import { useEffect, useReducer, useState } from "react";
import ProfileIcon from "../../assets/Profile.svg";
import Tags from "../../assets/Tags.svg";
import Folders from "../../assets/folder_icon.svg";
import SettingsIcon from "../../assets/Settings.svg";
import TeamMembersIcon from "../../assets/TeamMembers.svg";
import SyncIcon from "../../assets/SyncIcon.svg";
import LogOutIcon from "../../assets/LogOut.svg";
import darkDropdown from "../../assets/darkDropdown.svg";
import { AnInputBox, AnSuggestionBox } from "../input_utils/AnInput";
import { useHistory } from "react-router";
import userIcon from "../../assets/user.svg";
import { useDispatch } from "react-redux";
import { updateCurrentTeam } from "../../redux/currentTeamSlice";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import ConditionalRender from "../../ConditionalRender";
import almaLogo from "../../almanews_logo.svg";
import { NameInitial } from "../cta_utils/AnNameInitial";
import AnDropDown from "../cta_utils/AnDropDown";
import { apiAgent } from "../../axiosConfig";
import { switchCurrentTeam } from "../../actions/teamSliceActions/actions";
import { LoaderComponent } from "../utils/LoaderComponent";
import useWindowSize from "../custom_hooks/useWindowSize";
import { isWidthDown, isWidthUp } from "../utils/designUtils";
import SmallLoginHeader from "./SmallLoginHeader";
import useCheckUserAccess from "../custom_hooks/useCheckUserAccess";
import { indexOfObject } from "../utils/ArrayUtils";


const LoggedinHeader = (props) => {
    const current_user = useSelector((state) => state.current_user.user);
    const current_team = useSelector((state) => state.current_team.team);
    // TODO: please use reducers if there is a need to maintain state
    const [activeTab, setActiveTab] = useState("");
    const [dropdownState, setDropDownState] = useState(false);
    const _dispatch = useDispatch();
    const _history = useHistory();
    const windowsDimension = useWindowSize();
    const checkUserAccess = useCheckUserAccess();
    const LOGIN_HEADERS = [
        {key: "NewsFeed", label: "Newsfeed", url: "/feed", access: true}, 
        {key: "ProspectList", label: "Prospects", url: "/prospects", access: true},
        {key: "DirectoryList", label: "Directory", url: "/directory", access: current_team.show_directory_tab_in_header || current_user.is_publisher},
    ]
    const toggleDropDown = () => setDropDownState(!dropdownState);

    const handleSwitchAccount = () => {
        const cookies = new Cookies();
        cookies.remove("team_id", { path: "/" });
        _dispatch(updateCurrentTeam({}));
        _history.push("/switch_acc");
    };
    useEffect(() => {
        setHeader();
        const unlisten = _history.listen(() => {
            setHeader();
        });
        return unlisten;
    }, []);

    const setHeader = () => {
        let indexofHeader = indexOfObject(LOGIN_HEADERS, "url", _history.location.pathname);
        indexofHeader !== -1 ? setActiveTab(LOGIN_HEADERS[indexofHeader].key) : setActiveTab("")
    }
    const handleRedirectToProspectShow = (name, val) => {
        if (val?.id) _history.push(`/prospects/${val.id}`);
    };

    const forceSwitchTeam = (n, t) => {
        if(!t) return
        let team = {...t, id: t._id, approved_at: true}
        _dispatch(switchCurrentTeam(team));
        window.location.reload()
    }


    const renderTeamSwitcher = () => {
        return(
            <div style={{maxWidth: 200, width: "100%", marginRight: 26, cursor: "pointer"}}>
                <AnSuggestionBox
                    callback={forceSwitchTeam}
                    suggestionUrl="/suggestions/teams"
                    placeholder="Search Teams"
                    inputClass="an_search_box smooth"
                    inputElemStyle={{width: "75%"}}
                    name="Team Switch"
                    value={current_team.name}
                    onFocus={(elem) => {
                        elem.target.style.width = "100%";
                    }}
                    onBlur={(elem) => {
                        elem.target.style.width = "75%";
                    }}
                />
            </div>
                
        )
    }

    const renderLoginDropDown = () => {
        return (
            <>
                <div
                    style={{
                        position: "fixed",
                        width: "100%",
                        height: "100%",
                        left: 0,
                        top: 0,
                    }}
                    onClick={toggleDropDown}
                ></div>
                <div
                    className="text_subtitle_light box-shadow-content"
                    style={{
                        position: "absolute",
                        padding: 20,
                        marginTop: 8,
                        right: 0,
                        background: "#fff",
                        width: 240,
                        cursor: "pointer",
                        zIndex: 999
                    }}
                >
                    <ConditionalRender if={checkUserAccess('page_teams')}>
                        <Link
                            to="/admin/teams"
                            className="login_drop_link"
                        >
                            <div className="text_link">{current_team.name}</div>
                        </Link>
                        <div
                            style={{height: 1, background: "#E7ECEB", margin: "16px 0px",}}
                        ></div>
                    </ConditionalRender>
                    <Link
                        to={`/profile/${current_user.id || current_user.api_key}`}
                        className="login_drop_link"
                    >
                        <img
                            src={ProfileIcon}
                            className="login_drop_icon"
                        ></img>
                        <div>Profile</div>
                    </Link>
                    <Link
                        to="/settings/profile"
                        className="login_drop_link"
                    >
                        <img
                            src={SettingsIcon}
                            className="login_drop_icon"
                        ></img>
                        <div>Settings</div>
                    </Link>
                    <Link
                        to="/manage/tags"
                        className="login_drop_link"
                    >
                        <img
                            src={Tags}
                            className="login_drop_icon"
                        ></img>
                        <div>Tags/Labels</div>
                    </Link>
                    <ConditionalRender if={checkUserAccess("add_to_folder")}>
                        <Link
                            to="/manage/folders"
                            className="login_drop_link"
                        >
                            <img
                                src={Folders}
                                className="login_drop_icon"
                            ></img>
                            <div>Folders</div>
                        </Link>
                    </ConditionalRender>
                    <ConditionalRender if={checkUserAccess('team_managing')}>
                        <Link
                            to="/settings/manage_teams"
                            className="login_drop_link"
                        >
                            <img
                                src={TeamMembersIcon}
                                className="login_drop_icon"                                
                            ></img>
                            <div>Team members</div>
                        </Link>
                    </ConditionalRender>
                    <ConditionalRender if={checkUserAccess('super_admin_panel')}>
                        <Link
                            to="/admin/panel"
                            className="login_drop_link"
                        >
                            <img
                                src={TeamMembersIcon}
                                className="login_drop_icon"
                            ></img>
                            <div>Super Admin Panel</div>
                        </Link>
                    </ConditionalRender>
                    {
                        // <a style={{display: 'flex', marginBottom: 12, justifyContent: 'flex-start', alignItems: 'center', color: 'inherit', textDecoration: 'none'}}>
                        //   <div style={{width: 32, height: 32, borderRadius: '50%', marginRight: 12, background: '#F3F4F5'}}></div>
                        //   <div>All tags</div>
                        // </a>
                        // <a style={{display: 'flex', marginBottom: 12, justifyContent: 'flex-start', alignItems: 'center', color: 'inherit', textDecoration: 'none'}}>
                        //   <div style={{width: 32, height: 32, borderRadius: '50%', marginRight: 12, background: '#F3F4F5'}}></div>
                        //   <div>Custom fields</div>
                        // </a>
                    }

                    <div style={{height: 1,background: "#E7ECEB",margin: "16px 0px"}}></div>

                    <a  className="login_drop_link"
                        onClick={handleSwitchAccount}
                    >
                        <img
                            src={SyncIcon}
                            className="login_drop_icon"
                        ></img>
                        <div>Switch Accounts</div>
                    </a>
                    <a  className="login_drop_link"
                        onClick={props.logout}
                    >
                        <img
                            src={LogOutIcon}
                            className="login_drop_icon"
                        ></img>
                        <div>Logout</div>
                    </a>
                    <ConditionalRender if={current_team.expiring}>
                        <div className="login_drop_banner">
                            Your free trial will end in {current_team.trial_expiring_on_days} days. &nbsp;<b><a className="text_link" href={"https://calendly.com/almaconnect-news-scanner/custom-demo?month=2024-06"} target="_blank" style={{ color: 'rgba(0, 196, 181, 1)'}}>Upgrade</a></b>&nbsp; to keep your newsfeed updated.
                        </div>
                    </ConditionalRender>
                </div>
            </>
        );
    };
    
    if(isWidthDown(windowsDimension.width, "lg")) return <SmallLoginHeader />
    
    return (
        <div style={(current_team.expiring && activeTab === "NewsFeed") ? {paddingBottom: 30} : {}}>
            <div className="login_header" style={{position: "fixed", top: "0", width: "100%", zIndex: 999}}>
                <div style={{position: "relative",height: "100%"}} className="flex_container_space_between">
                    <a href="/feed" className="login_header_text">
                        <img
                            src={almaLogo}
                            style={{ width: 148, display: "block" }}
                        />
                    </a>
                    
                    <div className="default_flex_container">
                        {
                            LOGIN_HEADERS.map((header) => {
                            return ( 
                                <ConditionalRender if={header.access}>
                            <div
                                    key={header.key}
                                    className={`login_header_item deafult_flex_container ${activeTab === header.key
                                            ? "login_header_active_tab"
                                            : ""
                                        }`}
                                >
                                    <Link
                                        to={header.url}
                                        className={
                                            activeTab === header.key
                                                ? "text_body_big_light_bold"
                                                : "text_body_big_light"
                                        }
                                        style={{ textDecoration: "none" }}
                                    >
                                        {header.label}
                                    </Link>
                                </div>
                                </ConditionalRender>
                            )})
                        }
                        <ConditionalRender if={checkUserAccess('display_search_prospects_header')}>
                        <div style={{ maxWidth: 180 }}>
                            <AnSuggestionBox
                                callback={handleRedirectToProspectShow}
                                suggestionUrl="/prospects/search?team_search=true"
                                placeholder="Search Prospects"
                                inputClass="an_search_box"
                                name="Prospect_search"
                            />
                        </div>
                        </ConditionalRender>
                    </div>
                    <div className="default_flex_container">
                        <Link
                            className="text_subtitle_light"
                            to="/help_center"
                            style={{ marginRight: 26, textDecoration: "none" }}
                            target="_blank"
                        >
                            Help center
                        </Link>
                        <ConditionalRender if={checkUserAccess('team_switch')}>
                            {renderTeamSwitcher()}
                        </ConditionalRender>
                        <div
                            style={{ position: "relative" }}
                            onClick={toggleDropDown}
                        >
                            {
                                current_user.image_url ? (
                                    <img
                                        src={current_user.image_url}
                                        style={{
                                            width: 40,
                                            height: 40,
                                            borderRadius: "50%",
                                            background: "rgba(67, 126, 254, 0.1)",
                                            cursor: "pointer",
                                        }}
                                    />
                                ) : (
                                    <NameInitial
                                        name={current_user.name}
                                        width={40}
                                        textStyle={{ fontSize: 18 }}
                                    />
                                )
                            }
                            {dropdownState && renderLoginDropDown()}
                        </div>
                    </div>            
                </div>
            </div>
            <ConditionalRender if={current_team.expiring && activeTab === "NewsFeed"}>
                <div style={{position: "fixed", height: "48px", top: "80px", width: "100%", zIndex:900, background:"#FFFFFF", opacity:"100%"}}>
                    <div className="login_header_banner">
                        Your free trial period will end in {current_team.trial_expiring_on_days} days. Upgrade to keep the news updates flowing. &nbsp;<b><a href={"https://calendly.com/almaconnect-news-scanner/custom-demo?month=2024-06"} target="_blank"><u className="text_subtitle">Upgrade now</u></a></b>&nbsp;&nbsp;<svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.33398 7.50016L4.66732 4.16683L1.33398 0.833496" stroke="#282A2C" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
                    </div>
                </div>
            </ConditionalRender>
        </div>
    );
};

const PartialLoggedinHeader = (props) => {
    const [dropdownState, setDropDownState] = useState(false);
    const toggleDropDown = () => setDropDownState(!dropdownState);

    const renderPartialLoginDropDown = () => {
        return (
            <>
                <div
                    style={{
                        position: "fixed",
                        width: "100%",
                        height: "100%",
                        left: 0,
                        top: 0,
                    }}
                    onClick={toggleDropDown}
                ></div>
                <div
                    className="text_subtitle_light box-shadow-content"
                    style={{
                        position: "absolute",
                        padding: 20,
                        marginTop: 8,
                        right: 0,
                        top: "100%",
                        background: "#fff",
                        width: "max-content",
                        cursor: "pointer",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            marginBottom: 12,
                            justifyContent: "flex-start",
                            alignItems: "center",
                            color: "inherit",
                            textDecoration: "none",
                        }}
                        onClick={props.logout}
                    >
                        <img
                            src={LogOutIcon}
                            style={{
                                width: 32,
                                height: 32,
                                borderRadius: "50%",
                                marginRight: 12,
                                background: "#F3F4F5",
                            }}
                        ></img>
                        <div>Logout</div>
                    </div>
                    
                </div>
            </>
        );
    };

    return (
        <div className="login_header">
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    position: "relative",
                    height: "100%",
                }}
            >
                <a href="/" className="login_header_text">
                    <img
                        src={almaLogo}
                        style={{ width: 148, display: "block" }}
                    />
                </a>
                <div onClick={toggleDropDown} style={{ position: "relative" }}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 40,
                            height: 40,
                            borderRadius: "50%",
                            background: "rgba(67, 126, 254, 0.1)",
                            cursor: "pointer",
                        }}
                    >
                        <img
                            src={userIcon}
                            style={{ width: 20, height: 20, display: "block" }}
                        />
                        {dropdownState && renderPartialLoginDropDown()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoggedinHeader;

export { PartialLoggedinHeader };

// MAKE PROPER CSS CLASSES IF POSSIBLE MAYBE
