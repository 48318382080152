import AnDropDown from "../../../../cta_utils/AnDropDown";
import { AnInputCheckBox } from "../../../../input_utils/AnInputCheckBox";
import { useMapperFilterContext } from "../../../new_temp_mapper/contexts/mapper_filter_context";
import { ALL_MAPPER_FILTER_ACTIONS } from "../FiltersStateReducer";
import cross from '../../../../../assets/cross.svg'
import { Button } from "../../../../cta_utils/AnButton";
import { ALL_TABLE_ACTIONS } from "../../../new_temp_mapper/reducers/TableReducer";
import ConditionalRender from "../../../../../ConditionalRender";
import { useMemo, useState } from "react";
export const HiddenColumnFilter = ({
    tableState,
    tableStateReducer,
    ...props
}) => {
    const { filtersState, filtersStateReducer } = useMapperFilterContext();
    const [dropDownList, setDropDownList] = useState({})
    const toggleDropDown = () => {
        filtersStateReducer({ type: ALL_MAPPER_FILTER_ACTIONS["SET_STATE"], payload: { hiddenColumns: [] } })
        setDropDownList({});
    }
    const hiddenFields = useMemo(()=> {
        let temp_fields = [...tableState.all_columns[0].fields].filter((field) => field.display === false)
        temp_fields.map((field) => {
            if([...filtersState.hiddenColumns].includes(field.key))
                field.is_checked = true
            else
                field.is_checked = false
        })
        return temp_fields
    }, [filtersState.hiddenColumns, tableState.all_columns[0].fields]) 
    const handleFilterApply = (e) => {
        e.preventDefault();
        e.stopPropagation();
        tableStateReducer({type: ALL_TABLE_ACTIONS['DISPLAY_HIDDEN_COLUMNS'], payload: {hiddenColumns: [...filtersState.hiddenColumns]}})
        toggleDropDown()
    }
    const renderDropDown = () => {
        return (
            <AnDropDown
                style={{
                    position: "absolute",
                    right: 0,
                    top: 50,
                    padding: '16px',
                    background: "white",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                    borderRadius: 4,
                    width: "208px",
                    zIndex: 99,
                }}
                toggleDropDown={toggleDropDown}
            >
                {
                    hiddenFields.map((field) => {
                        return (
                            <AnInputCheckBox key={field.key} label={field.label} isChecked={field.is_checked} style={{marginBottom: 8}}>
                                <input type="checkbox" checked={field.is_checked} onChange={() =>
                                    filtersStateReducer({ type: ALL_MAPPER_FILTER_ACTIONS["SELECT_HIDDEN_COLUMN"], payload: { selections: [field.key] } })}
                                ></input>
                            </AnInputCheckBox>
                        )
                    })
                }
                <div  className="line_seperator_ex_light_grey"  style={{ margin: "16px -16px" }}></div>
                <div className='flex_container_space_between' style={{justifyContent: 'flex-end'}}>
                    <div style={{ display: "flex" }}>
                        <Button
                            buttonSize="Small"
                            type="Secondary"
                            innerButtonStye={{ padding: "4px 10px" }}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                toggleDropDown()}}
                        >
                            Cancel
                        </Button>
                        <Button
                            buttonSize="Small"
                            innerButtonStye={{ padding: "4px 10px" }}
                            buttonStyle={{ marginLeft: 8 }}
                            onClick={handleFilterApply}
                            isLoading={false}
                        >
                            Apply
                        </Button>

                    </div>
                </div>
            </AnDropDown>
        )
    }
    return (
        <th style={{ height: 1, minWidth: '38px', zIndex: 5 }} onClick={() => setDropDownList({addHiddenColumnDropDown: true})}>
            <div className="column_hover" style={{position: 'relative', borderLeft: '1px solid #E0E3EBBF', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}>    
                <div>
                    <img className="rotate45" src={cross} style={{ width: 14, height: 14 }}></img>
                </div> 
                {dropDownList.addHiddenColumnDropDown && hiddenFields.length > 0 && renderDropDown()}
            </div>
        </th>
    )
}