import FindYourTeam from "../components/views/connect_team/FindYourTeam.js";
import RequestedToJoin from "../components/views/connect_team/RequestedToJoin.js";
import SettingsPage from "../components/views/settings/SettingsPage.js";
import ManageProfile from "../components/views/settings/ManageProfile.js";
import ManageTeams from "../components/views/settings/ManageTeams.js";
import TeamSettings from "../components/views/settings/TeamSettings.jsx";
import ManageDataFields from "../components/views/settings/ManageDataFields.js";
import ManageNotifications from "../components/views/settings/ManageNotifications";
import ManageTags from "../components/views/settings/ManageTags.js";
import PostFolders from "../components/views/settings/PostFolders.js";
import PostFolderShow from "../components/views/settings/PostFolderShow.js";
import InviteUsers from "../components/views/invitations/InviteUsers.js";
import ProspectList from "../components/views/prospects/prospectsList/index"
import ProfileShow from "../components/views/profiles/ProfileShow.js";
import SmartAggregatesList from "../components/admin_views/aggregates/smart_aggregates_list.jsx";
import Teams from "../components/admin_views/teams/teams";
import SystemGeneratedPosts from "../components/admin_views/aggregates/system_generated_posts.js";
import TeamsDashboard from "../components/admin_views/teams/teams_dashboard.jsx";
import SystemConfigurationSettings from "../components/admin_views/system_configuration/system_configuration_settings";
import AdminPanel from "../components/admin_views/panel.js";
import ImportProspects from "../components/views/prospects/ImportProspects.js";
import ProspectImportShow from "../components/views/prospects/prospectImportShow.js";
import ProspectShow from "../components/views/profiles/prospectProfile/index.jsx";
import SimilarArticles from "../components/views/news_feed/similar_articles.js";
// import NewsFeed from '../components/views/news_feed/news_feed.js'
import RssCandidates from "../RssCandidates.js";
import RssSources from "../rss_sources/RssSources.js";
import Articles from "../Articles.js";
import NewsQueries from "../NewsQueries.js";
import ChooseTeam from "../components/views/connect_team/ChooseTeam.js";
import CompleteProfile from "../completeProfile.js";
import SwitchAccounts from "../components/views/switch_accounts/SwitchAccounts.js";
import { CreateTeam } from "../components/views/connect_team/CreateTeam.js";
import { InviteUserSignup } from "../components/views/invitations/InviteUserSignup.js";
import { HelpCenterPage } from "../components/views/support_views/help_center/HelpCenterPage.js";
import TeamNewsPDFGeneration from "../components/views/support_views/pdf_generation/TeamNews.js"
import { TeamNotifications } from "../components/views/settings/TeamNotifications.js";
import SinglePost from "../components/views/news_feed/single_post"
import TeamAdminStats from "../components/admin_views/teams/admin_stats.js";
import ActivityPage from "../components/admin_views/activities/index.jsx";
import ProspectMappings from "../components/admin_views/prospect_mappings/ProspectMappings.js"
import AdminCreateTeam from "../components/admin_views/create_team/AdminCreateTeam";
import { CompaniesPage } from "../components/admin_views/companies/index.jsx";
import { CategoriesPage } from "../components/admin_views/categories/index.jsx"
import FlaggedArticles from "../components/admin_views/aggregates/flagged_articles.js";
import ProspectInsightArticles from "../components/views/news_feed/ProspectInsightArticles.jsx";
import OutboundTeamsStats from "../components/admin_views/outbound_stats/OutboundTeamsStats.jsx";
import SearchViaKeywords from "../components/views/search_feed/searchViaKeywords.js";
import NewsSourceFeed from "../components/views/news_feed/news_source_feed.js"
import SingleRssCandidate from "../components/admin_views/rss_candidate/SingleRssCandidate.jsx";
import LearnMore from "../components/views/subscribe_unsubscribe/LearnMore.jsx";
import Features from "../components/views/subscribe_unsubscribe/Features.jsx";
import FreePlan from "../components/views/subscribe_unsubscribe/FreePlan.jsx";
import ContactUs from '../components/views/subscribe_unsubscribe/ContactUs.jsx';
import PremiumPlan from "../components/views/subscribe_unsubscribe/PremiumPlan.jsx";
import AlmaNewsLanding from "../components/landing_pages/alma_news_landing.js";
import PipeDriveApp from "../components/views/subscribe_unsubscribe/pipe_drive_app.js"
import LinkedinProspects from "../components/admin_views/linkedin_prospects/index.jsx";
import DataMineProspectsList from "../components/views/data_mine/prospects/index.jsx";
import WebPageJobCategory from "../components/views/data_mine/prospects/job_category_webpage.jsx";
import DataMineList from "../components/views/data_mine/lists/index.jsx";
import AlmaDataMine from "../components/landing_pages/alma_datamine.js";
import { dataminePrivacyPolicy } from "../components/views/landing/dataminePrivacyPolicy.js";
import RecordingAnalytics from "../RecordingAnalytics";
import LinkedinProspectMapping from "../components/admin_views/linkedin_prospect_mapping/index.jsx";
import JobUpdatesDashboard from "../components/admin_views/job_updates_dashboard/index.jsx"
import UserSearchSpace from "../components/views/search_feed/UserSearchSpace.jsx";
import SavedSearchSpaces from "../components/views/search_feed/SavedSearchSpaces.jsx";
import EventReg from "../components/views/events/event_reg.js";
import ThankYou from "../components/views/events/thank_you.js";
import RssExperiment from "../components/admin_views/rss_experiment/index.js";
import MapLinkedinProspectsPage from "../components/admin_views/temp_mapper/index.js";

import GetPremium from "../components/views/subscribe_unsubscribe/GetPremium.jsx";
import { ShareDataImport } from "../components/views/public_pages/_ShareDataImport.jsx";
import PipeDrivePermissions from "../components/views/pipedrive/PipeDrivePermissions.jsx";
import PipeDriveStatus from "../components/views/pipedrive/PipeDriveStatus.jsx";
import NewMapLinkedinProspectsPage from "../components/admin_views/new_temp_mapper/index.js";

const routes = [
    {
        path: "/rss-candidates",
        component: RssCandidates,
        protection: ["LOGGED_IN"],
        exact: true,
        checkAuthorization: "page_rss_candidates"
    },
    {
        path: "/rss-sources",
        component: RssSources,
        protection: ["LOGGED_IN"],
        exact: true,
        checkAuthorization: "page_rss_sources"
    },
    {
        path: "/articles",
        component: Articles,
        protection: ["LOGGED_IN", "IS_ADMIN"],
    },
    {
        path: "/news_queries",
        component: NewsQueries,
        protection: ["LOGGED_IN", "IS_ADMIN"],
    },
    {
        path: "/connect_teams",
        component: ChooseTeam,
        protection: ["LOGGED_IN"],
    },
    {
        path: "/complete_profile",
        component: CompleteProfile,
        protection: ["LOGGED_IN", "HAS_NOT_SELECTED_TEAM"],
        layout: "pre_auth_layout"
    },
    {
        path: "/find_team",
        component: FindYourTeam,
        protection: ["LOGGED_IN"],
    },
    {
        path: "/create_team",
        component: CreateTeam,
        protection: ["LOGGED_IN"],
    },
    {
        path: "/requested",
        component: RequestedToJoin,
        protection: ["LOGGED_IN"],
    },
    {
        path: "/prospects/archived",
        component: ProspectList,
        protection: ["LOGGED_IN"],
    },
    {
        path: "/prospects/:prospect_id",
        component: ProspectShow,
        protection: ["LOGGED_IN"],
    },
    {
        path: '/prospect_articles/:prospect_id',
        component: ProspectInsightArticles,
        protection: ["LOGGED_IN"]
    },
    {
        path: "/:article_id/similar_articles",
        component: SimilarArticles,
        protection: ["LOGGED_IN"],
    },
    {
        path: "/post/:post_id",
        component: SinglePost,
        protection: ["LOGGED_IN"],
    },
    {
        path: "/news_source/:source/feed",
        component: NewsSourceFeed,
        protection: ["LOGGED_IN"]
    },
    // {
    //   path: '/feed',
    //   component: NewsFeed
    //
    // },
    {
        path: "/pipe_drive",
        component: PipeDriveApp,
        layout: "pre_auth_layout_no_space landing_header_no_overlap"

    },
    {
        path: "/prospects",
        component: ProspectList,
        protection: ["LOGGED_IN"],
    },
    {
        path: "/import_prospects/:id",
        component: ProspectImportShow,
        protection: ["LOGGED_IN"],
    },
    {
        path: "/import_prospects",
        component: ImportProspects,
        protection: ["LOGGED_IN"],
    },
    {
        path: "/settings",
        component: SettingsPage,
        routes: [
            {
                path: "/settings/profile",
                component: ManageProfile,
                protection: ["LOGGED_IN"],
                layout: "none"
            },
            {
                path: "/settings/manage_teams",
                component: ManageTeams,
                protection: ["LOGGED_IN"],
                layout: "none",
                checkAuthorization: "team_managing"
            },
            {
                path: "/settings/data_fields",
                component: ManageDataFields,
                protection: ["LOGGED_IN"],
                layout: "none"
            },
            {
                path: "/settings/notifications",
                component: ManageNotifications,
                protection: ["LOGGED_IN"],
                layout: "none"
            },
            {
                path: "/settings/team_notifications",
                component: TeamNotifications,
                protection: ["LOGGED_IN"],
                layout: "none",
                checkAuthorization: "team_notification"
            },
            {
                path: "/settings/team_settings",
                component: TeamSettings,
                protection: ["LOGGED_IN"],
                layout: "none",
                checkAuthorization: "team_setting"
            }
        ],
        protection: ["LOGGED_IN"],
    },
    {
        path: "/manage/tags",
        component: ManageTags,
        protection: ["LOGGED_IN"],
    },
    {
        path: "/manage/folders",
        component: PostFolders,
        exact: true,
        protection: ["LOGGED_IN", "IS_PUBLISHER"],
    },
    {
        path: "/manage/folders/:folder_id",
        component: PostFolderShow,
        exact: true,
        protection: ["LOGGED_IN", "IS_PUBLISHER"],
    },
    {
        path: "/invite_users",
        component: InviteUsers,
        protection: ["LOGGED_IN"],
    },
    {
        path: "/profile/:profile_id",
        component: ProfileShow,
        protection: ["LOGGED_IN"],
    },
    {
        path: "/admin/panel",
        component: AdminPanel,
        protection: ["LOGGED_IN"],
        checkAuthorization: "super_admin_panel"
    },
    {
        path: "/admin/smart_publish",
        component: SmartAggregatesList,
        protection: ["LOGGED_IN", "IS_PUBLISHER"],
        checkAuthorization: "page_smart_publish"
    },
    {
        path: "/admin/flagged_articles",
        component: FlaggedArticles,
        protection: ["LOGGED_IN", "IS_PUBLISHER"],
        checkAuthorization: "page_flagged_articles"
    },
    {
        path: "/admin/activities",
        component: ActivityPage,
        protection: ["LOGGED_IN", "IS_PUBLISHER"],
        checkAuthorization: "page_team_user_activities"
    },
    {
        path: "/admin/teams",
        component: Teams,
        protection: ["LOGGED_IN", "IS_PUBLISHER"],
        checkAuthorization: "page_teams"
    },
    {
        path: "/admin/system_generated_posts",
        component: SystemGeneratedPosts,
        protection: ["LOGGED_IN"],
        checkAuthorization: "page_system_generated_posts"
    },
    {
        path: "/admin/teams_dashboard",
        component: TeamsDashboard,
        protection: ["LOGGED_IN", "IS_PUBLISHER"],
        checkAuthorization: "page_teams_dashboard"
    },
    {
        path: "/admin/team_stats",
        component: TeamAdminStats,
        protection: ["LOGGED_IN", "IS_PUBLISHER"],
        checkAuthorization: "page_team_stats"
    },
    {
        path: "/admin/system_configuration",
        component: SystemConfigurationSettings,
        protection: ["LOGGED_IN", "IS_SUPER_ADMIN"],
    },
    {
        path: "/admin/outbound_stats",
        component: OutboundTeamsStats,
        protection: ["LOGGED_IN", "IS_PUBLISHER"],
        checkAuthorization: "page_outbound_stats"
    },
    {
        path: "/admin/prospect_mappings",
        component: ProspectMappings,
        protection: ["LOGGED_IN"],
        checkAuthorization: "page_prospect_mappings"
    },
    {
        path: "/admin/create_team",
        component: AdminCreateTeam,
        protection: ["LOGGED_IN", "IS_PUBLISHER"],
        checkAuthorization: "team_creating"
    },
    {
        path: "/admin/companies",
        component: CompaniesPage,
        protection: ["LOGGED_IN", "IS_PUBLISHER"],
        checkAuthorization: "page_companies"
    },
    {
        path: "/admin/categories",
        component: CategoriesPage,
        protection: ["LOGGED_IN"],
        checkAuthorization: "page_categories"
    },
    {
        path: "/admin/linkedin_prospects",
        component: LinkedinProspects,
        protection: ["LOGGED_IN"],
        checkAuthorization: "page_linkedin_prospects"
    },
    {
        path: "/help_center",
        component: HelpCenterPage,
        layout: "pre_auth_layout_no_space",
        protection: [],
    },
    {
        path: "/",
        component: AlmaNewsLanding,
        layout: "pre_auth_layout_no_space landing_header_no_overlap",
        exact: true
    },    
    {
        path: "/pdf_generation/team_news",
        component: TeamNewsPDFGeneration,
        protection: [],
    },
    {
        path: "/search_articles/keyword_term_search",
        component: SearchViaKeywords,
        protection: [],
    },
    {
        path: "/rss-candidates/:id/external_article",
        component: SingleRssCandidate,
        protection: ["LOGGED_IN"],
        exact: true,
    },
    {
        path: "/learn_more",
        component: LearnMore,
        exact: true,
        layout: "pre_auth_layout_no_space landing_header_no_overlap"
    },
    {
        path: "/features",
        component: Features,
        exact: true,
        layout: "pre_auth_layout_no_space landing_header_no_overlap"
    },
    {
        path: "/plans",
        component: FreePlan,
        exact: true,
        layout: "pre_auth_layout_no_space landing_header_no_overlap"
    },
    {
        path: "/contact_us",
        component: ContactUs,
        exact: true,
        layout: "pre_auth_layout_no_space landing_header_no_overlap"
    },
    {
        path: "/premium",
        component: PremiumPlan,
        exact: true,
        layout: "pre_auth_layout_no_space landing_header_no_overlap"
    },
    {
        path: "/get_premium",
        component: GetPremium,
        exact: true,
        layout: "pre_auth_layout_no_space landing_header_no_overlap"
    },
    {
        path: "/team/prospects",
        component: DataMineProspectsList,
        exact: true,
        protection: ["LOGGED_IN"],
        layout: "blank_layout"
    },
    {
        path: "/team/webpage_jobs",
        component: WebPageJobCategory,
        exact: true,
        protection: ["LOGGED_IN"],
        layout: "feed_layout"
    },
    {
        path: "/team/lists",
        component: DataMineList,
        exact: true,
        protection: ["LOGGED_IN"],
        layout: "blank_layout"
    },
    {
        path: "/team/lists/:list_id",
        component: DataMineProspectsList,
        exact: true,
        protection: ["LOGGED_IN"],
        layout: "blank_layout"
    },
    {
        path: "/data_mine",
        component: AlmaDataMine,
        exact: true,
        layout: "pre_auth_layout_no_space landing_header_no_overlap"
    },
    {
        path: "/data_mine/privacy_policy",
        component: dataminePrivacyPolicy,
        exact: true,
        layout: "pre_auth_layout_no_space landing_header_no_overlap"
    },
    {
        path: "/pageviewing",
        component: RecordingAnalytics,
        exact: true,
        layout: "pre_auth_layout_no_space landing_header_no_overlap"
    },
    // {
    //   path: '/invite_request',
    //   component: InviteUserSignup
    // }
    {
        path: "/admin/linkedin_prospect_mapping",
        component: LinkedinProspectMapping,
        protection: ["LOGGED_IN"],
        checkAuthorization: "page_linkedin_prospect_mapping"
    },
    {
        path: "/admin/job_updates_dashboard",
        component: JobUpdatesDashboard,
        protection: ["LOGGED_IN"],
        checkAuthorization: "job_updates_dashboard"
    },
    {
        path: "/search/:id",
        component: UserSearchSpace,
        protection: ["LOGGED_IN"],
        layout: "feed_layout",
        checkAuthorization: "page_search_space"
    },
    {
        path: "/search",
        component: UserSearchSpace,
        protection: ["LOGGED_IN"],
        layout: "feed_layout",
        checkAuthorization: "page_search_space"
    },
    {
        path: "/saved_searches",
        component: SavedSearchSpaces,
        protection: ["LOGGED_IN"],
        layout: "feed_layout",
        checkAuthorization: "page_search_space"
    },
    {
        path: "/directory",
        component: DataMineProspectsList,
        exact: true,
        protection: ["LOGGED_IN"],
        layout: "blank_layout"
    },
    {
        path: "/rss-experiments",
        component: RssExperiment,
        protection: ["LOGGED_IN"],
        exact: true,
        checkAuthorization: "page_rss_experiments"
    },
    {
        path: "/admin/temp-mapper",
        component: MapLinkedinProspectsPage,
        protection: ["LOGGED_IN"],
        exact: true,
        checkAuthorization: "page_temp_mappers"
    },
    {
        path: "/admin/new-temp-mapper",
        component: NewMapLinkedinProspectsPage,
        protection: ["LOGGED_IN", "HAS_SELECTED_TEAM"],
        exact: true,
        checkAuthorization: "page_temp_mappers",
        layout: "blank_layout"
    },
    {
        path: "/mapper-suggestions",
        component: MapLinkedinProspectsPage,
        protection: ["LOGGED_IN"],
        exact: true,
        checkAuthorization: "page_mapper_suggestions"
    },
    {
        path: "/import_data",
        component: ShareDataImport,
        exact: true,
        layout: "pre_auth_layout"
    },
    {
        path: "/pipedrive-permission",
        component: PipeDrivePermissions,
        protection: ["LOGGED_IN"],
        exact: true,
        layout: "blank_layout"
    },
    {
        path: "/pipedrive-installation",
        component: PipeDriveStatus,
        protection: ["LOGGED_IN"],
        exact: true,
        layout: "blank_layout"
    },
];

export default routes;
