import React, { useState, useEffect } from "react";
import { Button, TabbedButton } from "../cta_utils/AnButton.js";
import { apiAgent } from "../../axiosConfig.js";
import { AnInputBox } from "../input_utils/AnInput.js";
import AnPopup from "../cta_utils/AnPopup";
import AnMultipleSuggestionInputBox from "../input_utils/AnMultipleSuggestionBox.js";
import { NameInitial } from "../cta_utils/AnNameInitial";
import { Link } from "react-router-dom";
import { AnInputRadioButton } from "../input_utils/AnInputRadioButton.js";
import { TextHighlighter } from "../utils/textHighlighter.js";
import MenuDots from "../../assets/MenuDots.svg";
import Share from "../../assets/share.svg";
import Flag from "../../assets/flag.svg";
import AnDropDown from "../cta_utils/AnDropDown";
import {connect, useSelector} from "react-redux";
import {MENTION_TYPES} from "../utils/AllMentionTypes";
import ConditionalRender from "../../ConditionalRender.js";
import {FlashMessages} from "../cta_utils/FlashMessages";
import { indexOfObject } from "../utils/ArrayUtils.js";
import Seemore from "../utils/SeemoreUtils.js";
import AnToolTip from "../cta_utils/AnTooltip.js";
import useCheckUserAccess from "../custom_hooks/useCheckUserAccess.js";

const descTypes = [{label: 'Description', key: 'description'}, {label: "Smart Description", key: 'smart_description'}];
const LowConfidencePost = ({
    renderPostHeader=true, 
    renderParentType="default",
    confidenceType,
    ...props}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [currentAggregate, setCurrentAggregate] = useState();
    const [popupsList, setPopupsList] = useState({});
    const [mentionType, setMentionType] = useState("");
    const [flaggedReason, setFlaggedReason] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [interests, setInterests] = useState("");
    const current_user = useSelector(state => state.current_user?.user);
    const current_team = useSelector(state => state.current_team?.team);
    const [descType, setDescType] = useState("description");
    const [descTabType, setDescTabType] = useState("description");
    const [smartDesc, setSmartDesc] = useState("");

    const checkUserAccess = useCheckUserAccess();
    useEffect(() => {
        setCurrentAggregate(props.item);
        if(props.item.mentioned_for){
            let index = indexOfObject(MENTION_TYPES, "text", props.item.mentioned_for)
            setMentionType(MENTION_TYPES[index]?.value);
        }
    }, []);

    const showPublishPopup = () => {
        var prospects = currentAggregate.news_query.prospects;
        if (prospects) setSelectedOptions(prospects);
        setPopupsList({ ...popupsList, publishPopup: true });
    };

    const flagAggregate = (article_id) => {
        setIsLoading(true);
        apiAgent({
            method: "post",
            url: "/aggregate/" + article_id + "/flag",
            data: {
                flagged_reason: flaggedReason,
                tab_type: props.tabType
            },
        }).then(
            (response) => {
                setIsLoading(false);
                setPopupsList({ ...popupsList, flagPopup: false });
                setFlaggedReason("");
            },
            (error) => {}
        );
    }

    const publishAggregate = (article_id) => { 
        var tagged_prospect_ids = selectedOptions.map((item) => item.id);
        setIsLoading(true);
        apiAgent({
            method: "post",
            url: "/aggregate/" + article_id + "/publish",
            data: {
                mentioned_for: mentionType,
                tagged_prospect_ids: tagged_prospect_ids,
                article_description: getDescription(),
                interests: interests
            },
        }).then(
            (response) => {
                //TODO: Remove all these lines except callback one and unmount from callback
                handlePublishPopupClose();
                setIsLoading(false);
                setCurrentAggregate({...currentAggregate, published: true});
                props.item.published = true;
                props.callbacks?.onPublishCallback?.(currentAggregate, "published")
            },
            (error) => {}
        );
    };

    const discardSearchTerm = () => {
        if(!window.confirm("Are you sure you want to discard this search term?")) return;
        setIsLoading(true);
        apiAgent({
            method: "post",
            url: "/aggregates/" + currentAggregate.id + "/discard_search_term",
            data: {
            },
        }).then(
            (response) => {
                setIsLoading(false);
                setCurrentAggregate({...currentAggregate, discarded: true});
                props.callbacks?.onDiscardCallback?.(currentAggregate, "discarded")
            },
            (error) => {}
        );
    }

    const handlePublishPopupClose = () => {
        setPopupsList({ ...popupsList, publishPopup: false });
    };

    const setArticleDescription = (value) => {
        var tempArticle = { ...currentAggregate.sharable_article };
        tempArticle.description = value;
        setCurrentAggregate({
            ...currentAggregate,
            sharable_article: tempArticle,
        });
    };

    const fetch_smart_desc = () => {
        let dataParams = { ...currentAggregate.sharable_article };
        if(!dataParams.description) return;
        apiAgent({
            method: 'get',
            url: "/aggregates/" + currentAggregate.id + "/fetch_smart_desc",
            params: {}
        }).then(res => {
            setSmartDesc(res.data.data);
        }).catch(err => {

        })
    }

    const getDescription = () => {
        return descType == 'description' ? currentAggregate.sharable_article.description : smartDesc
    }

    const flagPopup = () => {
        return (
            <AnPopup
                name="publishPopup"
                handleOnClosePopup={() => setPopupsList({ ...popupsList, flagPopup: false })}
                maxWidth={600}
            >
                <AnInputBox label={"Flag Reason"} style={{ marginTop: "16px" }}>
                    <textarea
                        type="text"
                        value={flaggedReason || ""}
                        onChange={(e) => {
                            setFlaggedReason(e.target.value);
                        }}
                    ></textarea>
                </AnInputBox>
                <Button
                    onClick={() => {
                        flagAggregate(currentAggregate.id);
                    }}
                    isLoading={isLoading}
                    buttonStyle={{ marginTop: "16px" }}
                >
                    Flag Article
                </Button>
            </AnPopup>
        );
    }

    const publishPopup = () => {
        return (
            <AnPopup
                name="publishPopup"
                handleOnClosePopup={handlePublishPopupClose}
                maxWidth={600}
            >
                <div className="text_title" style={{ marginBottom: "20px" }}>
                    Move to High Confidence
                </div>
                <AnMultipleSuggestionInputBox
                    label="Add prospects related to news article"
                    inputClass="an_search_box"
                    placeholder="Search Prospects"
                    suggestionUrl="/prospects/search?team_search=true"
                    selectedOptions={selectedOptions}
                    callback={setSelectedOptions}
                    style={{ margin: "16px 0px 0px" }}
                    theme="modern_selected"
                ></AnMultipleSuggestionInputBox>
                <div
                    style={{
                        margin: "12px 0px 16px",
                        borderTop: "0.5px solid #B6B9BB",
                    }}
                ></div>
                <div className="text_body">
                    <span className="text_body_light">Mentioned for: </span>{" "}         
                </div>
                {MENTION_TYPES.map((mention_type) => {
                    let is_checked = mentionType == mention_type.value;
                    return (
                        <>
                            <AnInputRadioButton
                                label={mention_type.text}
                                style={{ marginTop: 12 }}
                                isChosen={is_checked}
                            >
                                <input
                                    type="radio"
                                    id={mention_type.text}
                                    style={{ width: "16px", height: "16px" }}
                                    name="mention"
                                    value={mention_type.value}
                                    checked={is_checked}
                                    onChange={() => {
                                        setMentionType(mention_type.value);
                                    }}
                                />
                            </AnInputRadioButton>
                        </>
                    );
                })}
                {mentionType && (
                    <div
                        style={{ marginTop: "16px", padding: "10px" }}
                        className="text_body news_container"
                    >
                        {mentionType}
                    </div>
                )}
                <AnInputBox label={"Interests"} style={{margin: '16px 0px'}}>
                    <input type="text" id="interests" value={interests} onChange={(e) => setInterests(e.target.value)} placeholder="Interests"/>
                </AnInputBox>
                <div
                    style={{
                        margin: "16px 0px",
                        borderTop: "0.5px solid #B6B9BB",
                    }}
                ></div>
                <div className="text_body_light">Article Link: </div>
                <div
                    className="text_body news_container"
                    style={{ marginTop: "6px", padding: "10px" }}
                >
                    {currentAggregate.sharable_article.url}
                </div>

                { (checkUserAccess('page_smart_publish') && renderParentType === 'smart') ? 
                <>
                <div className="flex_container_space_between" style={{marginTop: '20px'}}>
                    <div className="default_flex_container">
                    {
                        descTypes.map(type => (
                            <div>
                                <TabbedButton buttonStyle={{padding: '4px 6px'}} isActive={descTabType === type.key} onClick={() => {setDescTabType(type.key)}}>
                                    {type.label}
                                </TabbedButton>
                            </div>
                        ))
                    }
                    </div>
                    <ConditionalRender if={descTabType=='smart_description'}>
                        <div className="text_link" onClick={() => fetch_smart_desc()}>Generate Smart Description</div>
                    </ConditionalRender>
                </div>
                <AnInputBox style={{ marginTop: "16px" }}>
                    <textarea
                        type="text"
                        id="metaDescription"
                        value={descTabType == 'description' ? currentAggregate.sharable_article.description : smartDesc}
                        onChange={(e) => {
                            descTabType == 'description' ? setArticleDescription(e.target.value) : setSmartDesc(e.target.value)
                        }}
                    ></textarea>
                </AnInputBox>
                <div className="default_flex_container">
                {
                    smartDesc && descTypes.map(type => {
                        let is_checked = descType == type.key;
                        return (
                            <>
                                <AnInputRadioButton
                                    label={type.label}
                                    style={{ margin: '12px 12px 0px 0px' }}
                                    isChosen={is_checked}
                                >
                                    <input
                                        type="radio"
                                        id={type.key}
                                        style={{ width: "16px", height: "16px" }}
                                        name="description"
                                        value={type.key}
                                        checked={is_checked}
                                        onChange={() => {
                                            setDescType(type.key);
                                        }}
                                    />
                                </AnInputRadioButton>
                            </>
                        )
                    })
                }
                </div>
                </>
                :
                <>
                <AnInputBox label={"Description"} style={{ marginTop: "16px" }}>
                <textarea
                    type="text"
                    id="metaDescription"
                    value={currentAggregate.sharable_article.description || ""}
                    onChange={(e) => {
                        setArticleDescription(e.target.value);
                    }}
                ></textarea>
                </AnInputBox>
                </>
  
                }
                <div className="text_body" style={{ marginTop: "16px" }}>
                    <div
                        style={{ marginBottom: "8px" }}
                        className="text_body_light"
                    >
                        Article Preview:
                    </div>
                    <a
                        href={currentAggregate.sharable_article.url}
                        target="_blank"
                        className="news_container"
                    >
                        { currentAggregate.sharable_article.image_url &&
                        <div style={{ width: "144px", float: "left" }}>
                            <img
                                src={
                                    currentAggregate.sharable_article.image_url
                                }
                                className="post_image"
                            />
                        </div>
                        }
                        <div style={{ 
                            width: currentAggregate.sharable_article.image_url? "calc(100% - 144px)" : 'auto',  
                            float: currentAggregate.sharable_article.image_url? "right": "left"
                            }}>
                            <div
                                className="text_subtitle_big"
                                style={{ marginBottom: "4px" }}
                            >
                                {currentAggregate.sharable_article.title}
                            </div>
                            <div className="text_body_light">
                                {getDescription()}
                            </div>
                        </div>
                        <div style={{ clear: "both" }}></div>
                    </a>
                </div>
                <Button
                    onClick={() => {
                        publishAggregate(currentAggregate.id);
                    }}
                    isLoading={isLoading}
                    buttonStyle={{ marginTop: "16px" }}
                >
                    Publish Article
                </Button>
            </AnPopup>
        );
    };

    const renderProspectDropdown = () => {
        return (
            <AnDropDown
                style={{
                    position: "absolute",
                    right: 0,
                    padding: "20px",
                    background: "white",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                    borderRadius: 4,
                    width: "max-content",
                    zIndex: 99,
                }}
                toggleDropDown={() => setShowDropdown(false)}
            >
                <div
                    style={{ cursor: "pointer" }}
                    onClick={() => discardSearchTerm()}
                >
                    Discard Search Term
                </div>
            </AnDropDown>
        );
    };

    const renderProspect = () => {
        var name = currentAggregate.news_query.person_name;
        var designation = currentAggregate.news_query.company_name;
        let prospect_id = "";
        let prospect = {};
        if (
            currentAggregate.news_query.prospects &&
            currentAggregate.news_query.prospects.length > 0
        ) {
            name = currentAggregate.news_query.prospects[0].name || currentAggregate.news_query.prospects[0].company;
            designation = currentAggregate.news_query.prospects[0].designation;
            if(!currentAggregate.news_query.prospects[0].name)
                designation = "";
            prospect_id = currentAggregate.news_query.prospects[0].id;
            prospect = currentAggregate.news_query.prospects[0] || {} ;
        }
        return (
            <>
                <a {... prospect_id ? {href: `/prospects/${prospect_id}`} : {}} style={{ float: "left", display: "flex", textDecoration: 'none'}}>
                    <NameInitial
                        name={name}
                        profile_image={prospect.profile_image}
                    />
                    <div style={{ marginLeft: "16px", maxWidth: 320 }}>
                        <div className="text_subtitle_big">{name}</div>
                        <div className="text_body_light truncate">{designation}</div>
                    </div>
                </a>
                {
                    props.admin && (
                        <>
                            <div onClick={() => setPopupsList({ ...popupsList, flagPopup: true })} style={{ float: "right", marginLeft: 12, marginTop: 8, cursor: 'pointer' }}>
                                <img src={Flag} />
                            </div>
                            <div style={{ float: "right", marginLeft: 12, marginTop: 8, cursor: 'pointer' }}
                                 onClick={() => {navigator.clipboard.writeText( window.location.href+'?smart_id='+currentAggregate.id+'&tab_type='+props.tabType);setSuccessMsg("Post link copied")}}>
                                    <img src={Share} />
                            </div>
                            { currentAggregate.published_by && (
                                <span className="text_body_light"
                                          style={{float: "right", marginLeft: 12, marginTop: 8}}>
                                    Published by <span className="text_subtitle"> {currentAggregate.published_by} </span>
                                </span>
                            )}
                        </>
                    )
                }
                <ConditionalRender if={checkUserAccess('news_publishing')}>
                {
                    !currentAggregate.published && (
                        <Button
                            onClick={() => {
                                showPublishPopup();
                            }}
                            buttonSize="LessPadding"
                            buttonStyle={{ float: "right" }}
                        >
                            Move to High Confidence
                        </Button>
                    )
                }
                </ConditionalRender>
                <div style={{ clear: "both" }}></div>
                <div
                    style={{
                        margin: "16px 0px",
                        borderTop: "0.5px solid #B6B9BB",
                    }}
                ></div>
            </>
        );
    };
    if (!currentAggregate) return <div></div>;
    else if ((currentAggregate.published || currentAggregate.discarded) && !props.singleArticle)
        return <div></div>;
    else {
        return (
            <div>
                <div className="article_container">
                    <ConditionalRender if={renderPostHeader}>
                        {renderProspect()}
                    </ConditionalRender>
                    <div style={{ marginBottom: "16px" }}>
                        <ConditionalRender if={props.item.published_description}>Mentioned for: {props.item.published_description}</ConditionalRender>
                        <ConditionalRender if={props.item.published_description && props.item.interests}>
                            &nbsp;|&nbsp; 
                        </ConditionalRender>
                        {props.item.interests}
                    </div>
                    <a
                        href={currentAggregate.sharable_article.url}
                        className="news_container"
                        target="_blank"
                    >
                        { currentAggregate.sharable_article.image_url &&
                        <div style={{ width: "144px", float: "left" }}>
                            <img
                                src={
                                    currentAggregate.sharable_article.image_url
                                }
                                className="post_image"
                            />
                        </div>
                        }
                        <div style={{ 
                            width: currentAggregate.sharable_article.image_url? "calc(100% - 144px)" : 'auto',  
                            float: currentAggregate.sharable_article.image_url? "right": "left"
                            }}>
                            {/* <div className="text_subtitle_big" style={{marginBottom: '4px'}}>{currentAggregate.sharable_article.title}</div> */}
                            <TextHighlighter
                                contentString={
                                    currentAggregate.sharable_article.title
                                }
                                toMatch={
                                    currentAggregate.news_query.person_name
                                }
                                typography={{ className: "text_subtitle_big" }}
                                style={{ marginBottom: 4 }}
                            />
                            <Seemore
                                short_description = {currentAggregate.sharable_article.short_description}
                                description = {currentAggregate.sharable_article.description}
                                person_name = {currentAggregate.news_query.person_name}
                            >
                            </Seemore>
                            {/* <div className="text_body_light">{currentAggregate.sharable_article.description}</div> */}
                            <div
                                className="text_caption_light"
                                style={{ marginTop: "8px" }}
                            >
                                <Link to={"/news_source/"+currentAggregate.sharable_article.source+"/feed"} className="text_link_small">{currentAggregate.sharable_article.source}</Link> | {" "}
                                {currentAggregate.aggregated_date}
                                {current_user.is_super_admin && (
                                    <React.Fragment>
                                        {" "} | {" "}
                                        {
                                            (currentAggregate.media_score > 0) && (<span>Media Score: {currentAggregate.media_score + " | "}</span>)
                                        }
                                        <span style={{wordBreak: 'break-all'}}>
                                            {currentAggregate.sharable_article.rss_sources.map(source => (
                                                <a href={source.url} target="_blank" className="text_link_small" style={{marginRight: 4}}>{source.host}</a>
                                            ))}
                                        </span>
                                    </React.Fragment>
                                    )
                                }
                            </div>
                        </div>
                        <div style={{ clear: "both" }}></div>
                    </a>
                    <div>
                    {currentAggregate.similar_articles_text &&
                        currentAggregate.similar_articles_text != "" && (
                            <div
                                className="text_caption"
                                style={{ marginTop: "12px", float: "left"}}
                            >
                                Also featured in{" "}
                                {currentAggregate.similar_articles_text}.{" "}
                                <Link
                                    to={`/${currentAggregate.id}/similar_articles`}
                                    className="text_link_small"
                                >
                                    View articles
                                </Link>
                            </div>
                        )}
                        {props.admin && (<div style={{float: 'right', marginTop: "12px"}} className="text_caption">last published at :
                            {
                                currentAggregate.news_query.prospects &&
                                currentAggregate.news_query.prospects.length > 0 && (
                                <Link
                                    to={
                                        "/post/" +
                                        currentAggregate.news_query.prospects[0].last_post_id
                                    }
                                    className="text_link_small"
                                >
                                    {currentAggregate.news_query.prospects[0].last_published_date}
                                </Link>
                                )}
                        </div>)}
                        <div style={{clear: "both" }}></div>
                    </div>
                    {
                        props.admin && (
                            <div style={{border: '0.5px solid #B6B9BB', padding: '12px', marginTop: 12, borderRadius: 4}}>
                                <div className="text_body" style={{float: 'left'}}>{currentAggregate.news_query.person_name} - {currentAggregate.news_query.company_name}</div>
                                <div className="text_link" style={{color: '#F94545', float: 'right'}} onClick={() => discardSearchTerm()}>Discard Search Term</div>
                                <div style={{clear: "both" }}></div>
                            </div>
                        )
                    }
                    <ConditionalRender if={checkUserAccess('display_omf_tags') && confidenceType !== 'low'}>
                        <div className="flex_container_space_between" style={{marginTop: 16}}>
                        <ConditionalRender if={currentAggregate.matching_article_tags}>
                            <div>
                                <span className="text_subtitle_light">Tags: </span>
                                {
                                    Object.keys(currentAggregate.matching_article_tags || {}).map(k => (
                                        <AnToolTip
                                            containerClass='text_subtitle an_tooltip'
                                            innerStyle={{padding: "8px"}}
                                            tooltipText={currentAggregate.matching_article_tags[k]}
                                        >
                                            <span key={k} style={{marginLeft: "12px"}}></span>
                                            {k}
                                        </AnToolTip>
                                    ))
                                }
                            </div>
                        </ConditionalRender>
                            <ConditionalRender if={currentAggregate.matching_article_score}>
                                <div>
                                    <span className="text_subtitle_light">Score: </span>
                                    <span className="text_subtitle">{currentAggregate.matching_article_score}</span>
                                </div>
                            </ConditionalRender>
                        </div>
                    </ConditionalRender>
                    {popupsList.publishPopup && publishPopup()}
                    {popupsList.flagPopup && flagPopup()}
                </div>
                {successMsg && (
                    <FlashMessages
                        messageTheme="success"
                        closeCallback={() =>
                            setSuccessMsg(null)
                        }
                    >
                        {successMsg}
                    </FlashMessages>
                )}
            </div>
        );
    }
};


const mapStateToProps = function (state) {
    return {
        current_user: state.current_user.user,
        current_team: state.current_team.team,
    };
};

export default connect(mapStateToProps)(LowConfidencePost);
