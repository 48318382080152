import React, { useEffect, useReducer } from 'react'
import reducer from '../../utils/CommonReducer'
import { AnInputBox } from '../../input_utils/AnInput'
import { Button } from '../../cta_utils/AnButton'
import { AnInputCheckBox } from '../../input_utils/AnInputCheckBox'
import { apiAgent } from '../../../axiosConfig'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const PipeDriveSignup = () => {
    
    const history = useHistory();    
    const [pageData, setPageData] = useReducer(reducer, {
        email: "",
        termsAndServices: false,
        isLoading: {},
        errors: {},
    })

    const setState = (payload) => setPageData({type: "SET_STATE", payload})
    
    const onFormSubmit = () => {
        let errors = {};
        if(!email) errors["email"] = "Please enter Email.";
        if(Object.keys(errors).length > 0){
            setState({errors: errors});
            return;
        }
        if(!termsAndServices) errors["t_and_c_error"] = "Please accept Terms of Use and Privacy Policy";
        if(Object.keys(errors).length > 0){
            setState({errors: errors});
            return;
        }
        setState({isLoading: {isSigningUp: true}, errors: {}});        
        apiAgent({
            method: 'post',
            url: '/users/signup',
            data: { email: email.toLowerCase(), source: "pipedrive"}
        }).then(
            (response) => {
                history.push("/confirm", {email: email});
                // window.location =  `/confirm?email=${this.state.email}`;
            },
            (error)  => {
                setState({isLoading: {}})
            }
        );
    }
    const {email, termsAndServices, isLoading, errors} = pageData;
    return (
        <div className='flex_container_space_between pipedrive-signup' style={{maxWidth: "1024px", margin: "auto", flexWrap: "wrap", padding: "72px 0px"}}>
            <div style={{maxWidth: "464px"}}>
                <div className='text_headline3'>Track <span className='primary-color'>all</span> prospects & alumni accurately...everyday...</div>
                <div className='text_title_big' style={{fontWeight: "normal", marginTop: "18px"}}>Discover potential donors and strengthen bonds</div>
                <div style={{marginTop: '24px'}}>
                    <Button buttonSize="Big" innerButtonStye={{padding: "8px 16px"}} onClick={(e) => console.log("Clicked", e.target)} buttonStyle={{width: '100%'}}>
                        Explore AlmaConnect News
                    </Button>
                </div>
            </div>
            <div className="signup-component" style={{maxWidth: "464px"}}>
                <div style={{textAlign: 'center'}}>
                    <span className="text_title_big" style={{fontWeight: "normal"}}>Install </span><span className='text_title_big'>Pipedrive</span>
                </div>
                <div className="text_sutitle" style={{textAlign: 'center'}}>
                    Create your account or log in
                </div>                               
                <div style={{marginTop: '24px'}}>
                    <AnInputBox label={"Your work email"} error={errors["email"]}>
                        <input type="text" id="email" value={email} onChange={(e) => setState({email: e.target.value})} placeholder="johndoe@domain.com"/>
                    </AnInputBox>
                </div>
                <div className='privacy_aggrement_flex_container default_flex_container text_caption' style={{marginTop: 16}}>
                    <AnInputCheckBox isChecked={termsAndServices}>
                        <input type="checkbox" checked={termsAndServices} onChange={(e) => setState({termsAndServices: !termsAndServices})}></input>
                    </AnInputCheckBox>
                    <div style={{marginLeft: 6}}>
                        <span>I accept AlmaConnect </span> 
                        <a href="https://www.almaconnect.com/terms-of-use" className="text_link_small">terms of service</a>
                        <span> and </span>
                        <a href="https://www.almaconnect.com/privacy-policy" className="text_link_small">privacy policy</a><span>.</span>
                    </div>
                </div>
                <div style={{marginTop: '20px'}}>
                    <Button buttonSize="Big" onClick={onFormSubmit} isLoading={isLoading.isSigningUp} buttonStyle={{width: '100%'}} error={errors?.t_and_c_error}>
                        Get Started
                    </Button>
                </div>
                <div className="text_body_light" style={{marginTop: 20, textAlign: 'center'}}>
                    Already have an account?  <Link to="/login?redirect_uri=https://news.almaconnect.com/pipedrive-permission" className="text_link">Log In</Link>
                </div>
            </div>            
        </div>
    )
}

export default PipeDriveSignup

