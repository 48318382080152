import { plans } from "./AllPlanTypes"

export const PostFilterOptions = [
    {
        group_label: "", 
        group_key: "viewType",
        options: [
            {
                label: 'All',
                key: 'all'
            },
            {
                label: 'Seen',
                key: 'seen'
            },
            {
                label: 'Unseen',
                key: 'unseen'
            }
        ]
    },
    {
        group_label: "", 
        group_key: "containComment",
        options: [
            {
                label: 'All',
                key: 'all'
            },
            {
                label: 'Contains comments',
                key: 'contains_comments'
            },
            {
                label: 'No comments',
                key: 'no_comments'
            }
        ]
    }
]

export const JobUpdatesDashboardFilterOptions = [
    {
        group_label: "", 
        group_key: "teamType",
        options: plans
    },
    {
        group_label: "", 
        group_key: "syncType",
        options: [
            { 
                key: 'queue', 
                label: 'Queue' 
            }, 
            { 
                key: 'completed', 
                label: 'Completed' 
            },
            { 
                key: 'failed', 
                label: 'Failed' 
            },
            { 
                key: 'cancelled', 
                label: 'Cancelled' 
            }
        ]
    }
]

export const NewPostFilterOptions = [
    {
        group_label: "Comments", 
        group_key: "containComment",
        options: [
            {
                label: 'All',
                key: 'all'
            },
            {
                label: 'Contains comments',
                key: 'contains_comments'
            },
            {
                label: 'No comments',
                key: 'no_comments'
            }
        ]
    },
    {
        group_label: "Views", 
        group_key: "viewType",
        options: [
            {
                label: 'All',
                key: 'all'
            },
            {
                label: 'Seen',
                key: 'seen'
            },
            {
                label: 'Unseen',
                key: 'unseen'
            }
        ]
    },
    {
        group_label: "Review", 
        group_key: "review",
        options: [
            {
                label: 'All',
                key: 'all'
            },
            {
                label: 'Reviewed',
                key: 'reviewed'
            },
            {
                label: 'Not reviewed',
                key: 'not_reviewed'
            }
        ]
    }
]

export const TempPostFilterOptions = [
    {
        group_label: "", 
        group_key: "containSuggestion",
        options: [
            {
                label: 'All',
                key: 'all'
            },
            {
                label: 'Contains suggestions',
                key: 'contains_suggestions',
                value: true
            },
            {
                label: 'No suggestions',
                key: 'no_suggestions',
                value: false
            }
        ]
    }
]