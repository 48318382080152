import React, { memo, useState } from 'react'
import { Link } from 'react-router-dom'
import ConditionalRender from '../../ConditionalRender'
import { NameInitial } from '../cta_utils/AnNameInitial'
import AnToolTip from '../cta_utils/AnTooltip'
import { TextHighlighter } from '../utils/textHighlighter'
import AnDropDown from "../cta_utils/AnDropDown"
import MenuDots from "../../assets/MenuDots.svg"
import GreyArrowDown from "../../assets/GreyArrowDown.svg";

import { apiAgent } from '../../axiosConfig'
import { AnInputBox } from '../input_utils/AnInput'
import { Button } from '../cta_utils/AnButton'
import getApiUrl from '../utils/UrlHelper'
import useCheckUserAccess from '../custom_hooks/useCheckUserAccess'

const PostActions = ({
    post,
    ...props
}) => {
    const [state, setState] = useState({show_dropdown: false})
    const checkUserAccess = useCheckUserAccess();
    const discardPost = () => {
        if (!window.confirm("Are you sure you want to delete this post?"))
            return;
        apiAgent({
            method: "post",
            url: "/post/" + post.id + "/discard",
            data: {},
        })
            .then((res) => {
                props?.callbacks?.deletionCallback({});
            })
            .catch((err) => {
            });
    }
    const archiveProspect = () => {
        if(!window.confirm("Are you sure you want to archive this prospect?")) return;
        apiAgent({
            method: "post",
            url: getApiUrl("archive_prospect", {":prospect_id": post.tagged_prospects[0].id}),
            data: {}
        }).then(res => {
            props?.callbacks?.archiveCallback();
            setState({show_dropdown: false});
        }).catch(err => {

        })
    }
    return (
        <>
            <div
                onClick={() =>
                    setState({show_dropdown: true})
                }
                className="text_body"
            >
                <img
                    src={MenuDots}
                    style={{cursor: "pointer", height: 24}}
                />
                <ConditionalRender if={state.show_dropdown}>
                    <AnDropDown
                        style={{
                            position: "absolute",
                            right: 0,
                            padding: "20px",
                            background: "white",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                            borderRadius: 4,
                            width: "max-content",
                            zIndex: 99,
                        }}
                        toggleDropDown={() => setState({ show_dropdown: false })}
                    >
                        <div                            
                            onClick={discardPost}
                            style={{cursor: "pointer"}}
                        >
                            Delete Post
                        </div>
                        <ConditionalRender if={checkUserAccess("prospect_archiving")}>
                            <div style={{marginTop: 16, cursor: 'pointer'}} onClick={archiveProspect}>
                                Archive
                            </div>
                        </ConditionalRender>
                    </AnDropDown>
                </ConditionalRender>               
            </div>
        </>        
    );    
}

const SimplifiedProspect = ({
    post,
    ...props
}) => {
    const [state, setState] = useState({show_dropdown: false})
    if(post.tagged_prospects.length === 0) return <div></div>;
    const [tagged_prospect] = post.tagged_prospects;
    const name = tagged_prospect.name || tagged_prospect.company;

    return(
        <div className="flex_container_space_between">
            <Link
                to={{
                    pathname: "/prospects/" + tagged_prospect.id,
                    state: null
                }}
                className="default_flex_container"
                style={{
                    alignItems: 'flex-start',
                    textDecoration: "none",
                }}
            >
                <NameInitial
                    name={name}
                />
                <div style={{ marginLeft: "16px", maxWidth: 320}}>
                    <div className="default_flex_container">
                        <div className="text_subtitle_big">
                            {name}
                        </div>
                        <div className="truncate" style={{marginLeft: 16}}>
                            {
                                post.tags?.map((tag) => <span key={tag.id} className="text_caption_small_light" style={{textTransform: 'uppercase', marginRight: 8}}>{tag.name}</span>)
                            }
                        </div>
                    </div>
                        <ConditionalRender if={post.tagged_prospects[0].name}>
                            <div className="text_body_light truncate">
                                {post.tagged_prospects[0].designation}
                            </div>
                        </ConditionalRender>
                </div>
            </Link>
            <div
                className="default_flex_container"
                style={{ position: "relative" }}
            >
                <AnToolTip
                    tooltipText={post.formatted_created_at}
                    innerStyle={{marginLeft: -60}}
                >
                    <div>{post.created_at_time}</div>
                </AnToolTip>
                <ConditionalRender if={props.actions}>
                    <div style={{position: "relative"}}>
                        <PostActions post={post} callbacks={props.callbacks}></PostActions>
                    </div>
                </ConditionalRender>
            </div>
        </div>
    )
}

const PostFooter = ({
    post,
    ...props
    }) => {
        const [activeTab, setActiveTab] = useState("");
        const [isLoading, setIsLoading] = useState({})
        const [flagReason, setFlagReason] = useState("");
        const flagArticle = () => {
            if(!flagReason) return;
            setIsLoading({flagging: true});
            apiAgent({
                method: "post",
                url: getApiUrl("flag_matching_article", {":article_id": post.matching_article_id}),
                data: {
                    flagged_reason: flagReason,
                }
            }).then(res => {
                setIsLoading({});
                setActiveTab(null);
            }).catch(err => {
                setIsLoading({});
            })            
        }
        const toggleActiveTab = (tab) => {
            if(tab === activeTab) setActiveTab(null);
            else setActiveTab(tab);
        }
        return (
            <>
                <ConditionalRender if={post.similar_articles_text && post.similar_articles_text !== ""}>
                    <>
                        <div
                            className="text_caption"
                            style={{ marginTop: "12px" }}
                        >
                            Also featured in{" "}
                            {post.similar_articles_text}.{" "}
                            <Link
                                to={ `/${post.id}/similar_articles`}
                                className="text_link_small"
                            >
                                View articles
                            </Link>
                        </div>
                    </>
                </ConditionalRender>
                {post.news_query && 
                    <div style={{border: '0.5px solid #B6B9BB', padding: '12px', marginTop: 12, borderRadius: 4}}>
                        <div className="text_body" style={{float: 'left'}}>{post.news_query.person_name} - {post.news_query.company_name}</div>
                        {(<div style={{float: 'right'}} className="text_caption">last published at :
                            {
                                post.news_query.prospects &&
                                post.news_query.prospects.length > 0 && (
                                <Link
                                    to={
                                        "/post/" +
                                        post.news_query.prospects[0].last_to_last_post_id
                                    }
                                    className="text_link_small"
                                    target="_blank"
                                >
                                    {post.news_query.prospects[0].last_to_last_published_date}
                                </Link>
                                )}
                        </div>)}
                        <div style={{clear: "both" }}></div>
                    </div>
                }
                <ConditionalRender if={props.actions}>
                    <div className='default_flex_container' style={{marginTop: 14, cursor: "pointer"}} onClick={() => toggleActiveTab("flag")}>
                        <div className='text_subtitle'>Flag</div>
                        <img src={GreyArrowDown} className={activeTab === "flag" ? "to_arrow_up": "to_arrow_down"} style={{ width: 24 }}/>
                    </div>
                    <ConditionalRender if={activeTab === "flag"}>
                        <div className='default_flex_container' style={{gap: 24}}>
                            <AnInputBox style={{width: "100%"}}>
                                <input type="text" value={flagReason} onChange={(e) => setFlagReason(e.target.value)} placeholder="Add comments.."></input>
                            </AnInputBox>
                            <Button buttonSize="LessPadding" onClick={flagArticle} isLoading={isLoading.flagging}>Submit</Button>
                        </div>
                    </ConditionalRender>
                </ConditionalRender>
            </>
        )
}

const ArticleContent = memo(({
    item,
    containerClass="default_flex_container news_container",
    showProspect=false,
    showPostFooter=false,
    ...props
}) => {
    return (
        <div style={{border: "1px solid #B6B9BB", padding: 24, borderRadius: 4, marginBottom: 16}}>
            <div className='flex_container_space_between'>
                <div style={{border: "1px solid #B6B9BB", borderRadius: 4, marginBottom: 16, backgroundColor: "#F3F4F5"}}>
                    <div className='text_body' style={{padding: 10, color: "#282A2C"}}> ID: {item.id} </div>
                </div>
                <div className='text_caption_bold'>
                    <div style={{marginBottom: 16, color: "#707274", fontWeight: 600}}>Published Date: <span style={{color: "#282A2C"}}>
                        {item.shared_link.published_at
                            ? `${item.shared_link.published_at.substring(0, 10)}`
                            : ""}</span> </div>
                </div>
            </div>
            <div style={{borderTop: "0.5px solid #B6B9BB", marginBottom: "16px"}}></div>
            <div>
                <ConditionalRender if={showProspect}>
                    <SimplifiedProspect post={item} actions={props.actions} callbacks={props.callbacks}/>
                    <div style={{margin: "16px 0px",borderTop: "0.5px solid #B6B9BB"}}></div>
                </ConditionalRender>
                <a
                    href={item.shared_link.url}
                    target="_blank"
                    className={containerClass}
                    style={props.style}
                >
                    {item.shared_link.image_url && 
                        <img
                            src={item.shared_link.image_url}
                            className="post_image"
                            style={{marginRight: "16px"}}
                        />
                    }
                    <div style={{wordBreak: 'break-word'}}>
                        <TextHighlighter
                            contentString={
                                item.shared_link.title
                            }
                            toMatch={
                                item.tagged_prospects?.[0]?.name
                            }
                            typography={{ className: "text_subtitle_big" }}
                            style={{ marginBottom: 4 }}
                        />
                        <TextHighlighter
                            contentString={
                                item.shared_link.description
                            }
                            toMatch={
                                item.tagged_prospects?.[0]?.name
                            }
                        />
                        <div
                            className="text_caption_light"
                            style={{ marginTop: "8px" }}
                        >
                            {item.shared_link.source}{" | "}
                            {item.shared_link.media_score && (`Media Score : ${item.shared_link.media_score} | `)}
                            {item.shared_link.rss_sources && (
                                <span style={{wordBreak: 'break-all'}}>
                                    {item.shared_link.rss_sources.map(source => (
                                        <a href={source.url} target="_blank" className="text_link_small" style={{marginRight: 4}}>{source.host}</a>
                                    ))}
                                </span>
                            )}
                        </div>
                    </div>
                    <div style={{ clear: "both" }}></div>
                </a>
                <div className='text_link' style={{color: "#707274"}}>{item.shared_link.url} </div>
                <ConditionalRender if={showPostFooter}>
                    <PostFooter post={item} actions={props.actions}/>
                </ConditionalRender>
            </div>
        </div>
    )
})

export default ArticleContent