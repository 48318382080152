import React, {useState} from 'react'
import ConditionalRender from '../../ConditionalRender.js'
import {debounce} from 'lodash'
import { apiAgent } from '../../axiosConfig.js'

export const AnInputBox = ({
  inputClass="an_input_box",
  searchLensClass=null,
  ...props
}) => {
  const renderChildren = () => {
    if(searchLensClass) return(
      <div style={{position: "relative"}}>
        {props.children}
        <ConditionalRender if={searchLensClass}>
          <span className={searchLensClass}>
            <img src="/search.svg" />
          </span>
        </ConditionalRender>
      </div>      
    )
    return(
      <>
        {props.children}
        <ConditionalRender if={searchLensClass}>
          <span className={searchLensClass}>
            <img src="/search.svg" />
          </span>
        </ConditionalRender>
      </>
    )
  }
  return(
    <div style={props.style}>
      <ConditionalRender if={props.label}>
        <div className="text_body_light">
          {props.label}
        </div>
      </ConditionalRender>
      <div className={`${inputClass} ${props.error ? 'an_input_error' : ''}`}>
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 280 41" fill="none" style={{ position: 'absolute', top: 0, left: 0, zIndex: -1 }}>
            <path fill="#F8F8F8" stroke="#E7EBEC" />
        </svg>
        {renderChildren()}
        <ConditionalRender if={props.error}>
          <div className="text_caption_error" style={{marginTop: typeof props.error === 'string' ? 8 : 0}}>{props.error}</div>
        </ConditionalRender>
      </div>
      <ConditionalRender if={props.helper}>
        <div className="text_caption_light">
          {props.helper}
        </div>
      </ConditionalRender>
    </div>
  )
}

export class AnSuggestionBox extends React.Component {

  MINCHAR = 2
  constructor(props){
    super(props);

    this.state = {
      activeOption: 0,
      fetchedRes: [],
      showOptions: false,
      userInput: this.props.value,
      isFetching: false,
    };

  }

  debouncedHandleChange = debounce((ip) => {
    this.setState({isFetching: true})
    apiAgent({
      method: 'get',
      url: this.props.suggestionUrl,
      params: {query: ip, ...this.props.params}
    }).then(res => {
      this.setState({fetchedRes: res.data.data, isFetching: false, showOptions: true})
    }).catch(err => {
      this.setState({isFetching: false, showOptions: true, fetchedRes: []})
    })
  }, 300)

  onChange = (e) => {
    const { options } = this.props;
    const userInput = e.currentTarget.value;
    this.setState({...this.state, userInput})
    if(userInput && userInput.length >= this.MINCHAR){
      this.debouncedHandleChange(userInput)
    }
    else if(!userInput){
      this.props.callback(this.props.name, null)
    }
  };

  onClick = (e) => {
    const { activeOption, fetchedRes } = this.state;
    this.props.callback(this.props.name, fetchedRes[activeOption])
    this.setState({
      activeOption: 0,
      fetchedRes: [],
      showOptions: false,
      userInput: e.currentTarget.innerText
    });
  };
  onKeyDown = (e) => {
    const { activeOption, fetchedRes } = this.state;

    if (e.keyCode === 13) { // Return
      this.props.callback(this.props.name, fetchedRes[activeOption])
      this.setState({
        activeOption: 0,
        showOptions: false,
        userInput: fetchedRes[activeOption]?.name
      }, () => {
      });
    } else if (e.keyCode === 38) { // Up
      if (activeOption === 0) {
        return;
      }
      this.setState({ activeOption: activeOption - 1 });
    } else if (e.keyCode === 40) { // Down
      if (activeOption === fetchedRes.length - 1) {
        return;
      }
      this.setState({ activeOption: activeOption + 1 });
    }
  };
  
  resetAll = () => {
    this.setState({
      activeOption: 0,
      fetchedRes: [],
      showOptions: false,
      userInput: "",
      isFetching: false,
    })
  }
  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: { activeOption, fetchedRes, showOptions, userInput, isFetching }
    } = this;
    let component = this;
    let optionList;
    if((showOptions || isFetching) && !userInput) this.resetAll()
    if (showOptions && userInput) {
      if (fetchedRes.length) {
        optionList = (
          <ul className="suggestions_container" style={{listStyle: "none"}}>
            {fetchedRes.map((optionName, index) => {
              let className = 'suggested_item';
              if (index === activeOption) {
                className += ' active_option';
              }
              return (
                <li className={className} key={optionName.id} onClick={onClick} onMouseEnter={() => {component.setState({activeOption: index})}}>
                  {optionName.name}
                </li>
              );
            })}
          </ul>
        );
      } else {
        optionList = (
          <ul className="suggestions_container" style={{listStyle: "none"}}>
            <li className="suggested_item" key="NoResults">No Results</li>
          </ul>
        );
      }
    }

    let computed_classes = this.props.inputClass || 'an_input_box';
    computed_classes = this.props.error ? (computed_classes + ' an_input_error') : computed_classes
    return(
      <>
        <ConditionalRender if={showOptions}>
          <div style={{inset: '0', position: 'fixed'}} onClick={this.resetAll}>
          </div>
        </ConditionalRender>
        
        <ConditionalRender if={this.props.label}>
          <div className="text_caption_light">
            {this.props.label}
          </div>
        </ConditionalRender>
        <div className={computed_classes} style={{textAlign: "left"}}>

          <div style={{position: "relative"}}>
            <input type="text" placeholder={this.props.placeholder}
              onChange={onChange}
              onKeyDown={onKeyDown}
              onFocus={(elem) => this.props.onFocus?.(elem)}
              onBlur={(elem) => this.props.onBlur?.(elem)}
              className="smooth_transitioner"
              style={{...this.props.inputElemStyle}}              
              value={userInput}
            />

            <ConditionalRender if={this.props.inputClass}>
              <span className="an_search_lens">
                <img src="/search.svg" />
              </span>
            </ConditionalRender>

            <ConditionalRender if={isFetching}>
              <div style={{position: "absolute", width: 20, right: 8, top: 0, height: "100%", textAlign: "center", fontSize: 0}}>
                <div className="vertical_middle"></div>
                <div style={{display: "inline-block", verticalAlign: "middle"}} className="loader-green4">
                  <div className="loader-box loader_box1"></div>
                  <div className="loader-box loader_box2"></div>
                  <div className="loader-box loader_box3"></div>
                  <div style={{clear: "both"}}></div>
                </div>
              </div>
            </ConditionalRender>
          </div>
          <div style={{position: 'relative'}}>
            {optionList}
          </div>
        </div>
      </>
    )
  }
}

