export const initialMapperData = {
    mapping_data: [],
    isFetching: {loadingPageData: false},
    dropDownList: {suggestionDropdown: false},
    popupsList: {},
    currItem: {},
    successMessages: {
        message: "",
        duration: 0,
        from: ""
    },
    totalPages: 0,
    currentPage: 1,
    totalCount: 0,
    average_confidence_of_mapped: 0,
    no_of_no_sugg: 0,
    no_of_unmapped: 0,
    no_of_mapped: 0
}
const MapperReducer = (state, action) => {
    switch(action.type){
        case "SET_STATE": {
            return {
                ...state,
                ...action.payload
            }
        }
        default: return state;
    }
}
export const ALL_MAPPER_ACTIONS = {
    SET_STATE: "SET_STATE"
}
export default MapperReducer