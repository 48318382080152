import React from 'react'
import { Button } from '../../cta_utils/AnButton'
import NewsBanner from "../../../assets/outbound/NewsBanner.png"
import NewsBannerAlter from "../../../assets/outbound/NewsBanner_alter.png"
import NewsBannerMobile from "../../../assets/outbound/NewsBannerMobile.png"
import TryPremium from "../../../assets/outbound/trypremium.png"
import WhatIsIt from "../../../assets/outbound/WhatIsIt.png"
import ArrowBlueFilled from "../../../assets/ArrowBlueFilled.svg"
import HowWeDoIt1 from "../../../assets/outbound/How we do it/Identify.png"
import HowWeDoIt2 from "../../../assets/outbound/How we do it/AiScan.png"
import ShareMail from "../../../assets/outbound/How we do it/ShareMail.png"
import Engage from "../../../assets/outbound/Benefits/Engage.png"
import AccuracyAndReach from "../../../assets/outbound/Benefits/AccuracyAndReach.png"
import FindHidden from "../../../assets/outbound/Benefits/FindHidden.png"
import GreyDot from "../../../assets/GreyDot.svg"
import TryOutFrame from "../../../assets/outbound/How we do it/tryOutFrame.png"

import BryantLogo from "../../../assets/outbound/Logos/bryantlogo.png"
import CarnegieMellon from "../../../assets/outbound/Logos/Carnegie.png"
import LMU from "../../../assets/outbound/Logos/Loyola.png"
import LouisburgCollege from "../../../assets/outbound/Logos/Louisburg.png"
import LPFCH from "../../../assets/outbound/Logos/LPFCH.png"
import Marquette from "../../../assets/outbound/Logos/Marquette.png"
import PCOM from "../../../assets/outbound/Logos/Pcom.png"
import PingrySchool from "../../../assets/outbound/Logos/Pingry.png"
import Punahou from "../../../assets/outbound/Logos/Punahou.png"
import Ryerson from "../../../assets/outbound/Logos/Ryerson.png"
import UniversityOfLiverpool from "../../../assets/outbound/Logos/UniversityOfLiverpool.png"
import WillametteUniversity from "../../../assets/outbound/Logos/Willamette.png"
import TmnKatie from "../../../assets/outbound/testimonials/Katie.png"
import TmnJennifer from "../../../assets/outbound/testimonials/Jennifer.png"
import TmnMarlboro from "../../../assets/outbound/testimonials/Marlboro.png"
import TmnAugsburg from "../../../assets/outbound/testimonials/Augsburg.png"
import TmnBryant from "../../../assets/outbound/testimonials/Bryant.png"
import TmnPingry from "../../../assets/outbound/testimonials/Pingry.png"
import TmnMenlo from "../../../assets/outbound/testimonials/Menlo.png"
import TmnLouis from "../../../assets/outbound/testimonials/Louis.png"
import TmnPlay from "../../../assets/outbound/testimonials/Play.png"

import Card1 from "../../../assets/outbound/security/Card1.png"
import Card2 from "../../../assets/outbound/security/Card2.png"
import Card3 from "../../../assets/outbound/security/Card3.png"
import Card4 from "../../../assets/outbound/security/Card4.png"

import Carousel from "react-bootstrap/Carousel"

import useWindowSize from '../../custom_hooks/useWindowSize'
import { isWidthDown } from '../../utils/designUtils'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { subscribeApi } from './apiHitters'
import { useState } from 'react'
import ConditionalRender from '../../../ConditionalRender'
import AnPopup from "../../cta_utils/AnPopup"
import { AmplitudeEventTrack } from '../../landing_pages/amplitude_event_track'

const testimonials_data = [
    {
        image: TmnKatie,
        play_button: TmnPlay,
        video_link: "https://www.youtube.com/embed/vGrPUMbQLbI",
        hover_button: "play_btn",
        play_style: "z-index: 9999;width: 64px;height: 64px;margin: auto;",
        content: "We've been really pleased with the results. It's a really great product that has really changed the way that we've been doing news alerts.",
        person_info: "-Katie Bokenkamp, Willamette University"
    },
    {
        image: TmnJennifer,
        play_button: TmnPlay,
        video_link: "https://www.youtube.com/embed/NWllqNmJEFw",
        hover_button: "play_btn",
        play_style: "z-index: 9999;width: 64px;height: 64px;margin: auto;",
        content: "AlmaConnect News opened a new world for us in terms of the information we have and what our major gift officers can get hold on. We're truly excited.",
        person_info: "-Jennifer Winchell, Whitman College"
    },
    {
        image: TmnMarlboro,
        content: "AlmaConnect has not only made that easier to do, but also helped new team members familiarize themselves with their assigned constituents seamlessly. After only a few weeks of using the platform, one team member was able to secure an annual gift thanks to the awareness of a constituent in the news.",
        person_info: "-Natalie Makous Cuadra, Marlborough School"    
    },
    {
        image: TmnAugsburg,
        content: "There is so much information out there, and AlmaConnect makes navigating through it all to locate what I need easy.",
        person_info: "-Jauston Campbell, Augsburg University"
    },
    {
        image: TmnBryant,
        content: "I am very happy with the service and continue to find prospects that I would not have found through other methods.",
        person_info: "-Nicole Melmed, Bryant University"
    },
    {
        image: TmnPingry,
        content: "I actually met with, and formed a relationship with, a current parent based on seeing his name in one of the news alerts. He was previously unknown to our office, but has an incredibly fascinating career and his family donated generously to our school this year without any solicitation!",
        person_info: "-Brad Fadem, Assistant Director of Institutional Advancement, Pingry School"
    },
    {
        image: TmnMenlo,
        content: "I am using AlmaConnect daily and it is an easy and straightforward process. The articles you’re finding are relevant and helpful with our work.",
        person_info: "-Vidya Kagan, Director of Data Management & Research,  Menlo School"
    },
    {
        image: TmnLouis,
        content: "AlmaConnect has been a great resource for us to keep up with alumni! We have recently been working on increasing our engagement on LinkedIn. AlmaConnect has provided us with a lot of great alumni news articles for us to share on this platform. We are excited to continue to utilize this service to stay in the know of alumni successes.",
        person_info: "- Kathryn O'Leary, Alumni Engagement Officer, Louisburg College"
    }
  ];

const LearnMore = () => {
    const windowDimensions = useWindowSize();
    const history = useHistory();
    const [verifying, setVerifying] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [popupsList, setPopupsList] = useState({testimonialPopup: false});
    const [tmnInterval, setTmnInterval] = useState(5000);
    const handleOnsubscribe = () => {
        let uniqId = null;
        let team_id = null;
        let searchParams = new URLSearchParams(window.location.search);
        if(searchParams.get('id')) uniqId = searchParams.get('id');
        if(searchParams.get('team_id')) team_id = searchParams.get('team_id');
        if(!uniqId){
            if(team_id) history.push(`/subscribe?team_id=${team_id}`)
            else history.push("/subscribe");
        }else{
            setVerifying(true);
            subscribeApi({id: uniqId}).then(res => {
                if(res.data.subscribed) history.push("/subscribed");
                else setVerifying(false);
            }).catch(err => setVerifying(false))
        }
        AmplitudeEventTrack('learn_more_subscribe_now');
    }
    const handleTestimonialClick = (e, index) => {
        e.stopPropagation();
        let data = testimonials_data[index];
        if(!data.play_button) return;
        setTmnInterval(null);
        setActiveIndex(index);
        setPopupsList({testimonialPopup: true})
    }
    const handlePopupClose = (list={}) => {
        setPopupsList(list);
        setTmnInterval(5000);
    }
    const renderFullPageArrows = () => {
        return(
            <>
                <div className='default_flex_container' style={{justifyContent: "center", gap: 12, alignItems: "flex-start", marginTop: "16px"}}>
                    <div style={{}}>
                        <div style={{margin: "auto", height: 78, width: 62, background: "white", borderRadius: "0 0 0 4px", borderLeft: "2px dashed #437efe", borderBottom: "2px dashed #437efe", position: "relative"}}>
                            <div style={{boxSizing: "border-box", height: "10px", width: "10px", background: "transparent", borderRight: "2px solid #437efe", borderBottom: "2px solid #437efe", position: "absolute", right: 0, bottom: 0, transform: "translateY(calc(50% + 1px)) rotate(-45deg)"}}>                    
                            </div>
                        </div>
                    </div>
                    <div 
                        style={{ width: "300px", borderRadius: "60px", padding: "14px 18px 11px", height: "48px", fontSize: "17px", textTransform: "none", textAlign: "center", 
                            fontWeight: 600, lineHeight: "21px", color: "white", background: "#437EFE",
                            alignSelf: "flex-end", transform: "translateY(50%)"
                        }}                        
                    >
                        We match articles with the data
                    </div>
                    <div>
                        <div style={{margin: "auto", height: 78, width: 62, background: "white", borderRadius: "0 0 4px 0", borderRight: "2px dashed #437efe", borderBottom: "2px dashed #437efe", position: "relative"}}>
                            <div style={{boxSizing: "border-box", height: "10px", width: "10px", background: "transparent", borderLeft: "2px solid #437efe", borderBottom: "2px solid #437efe", position: "absolute", left: 0, bottom: 0, transform: "translateY(calc(50% + 1px)) rotate(45deg)"}}>                    
                            </div>
                        </div>
                    </div>                
                </div>
                <div style={{width: 0, height: 70, border: "1px dashed #437efe", margin: "36px auto 12px", position: "relative"}}>
                    <div style={{boxSizing: "border-box", height: "10px", width: "10px", background: "transparent", borderLeft: "2px solid #437efe", borderBottom: "2px solid #437efe", position: "absolute", left: 0, bottom: 0, transform: "translateX(calc(-50%)) rotate(-45deg)"}}>                    
                    </div>
                </div>
            </>
        )
    }

    const section2FP = () => {
        return(
            <section className='section_2'>
                <div className='content_container' >
                    <div className='text_headline3' style={{marginBottom: "38px", textAlign: "center", fontWeight: 800}}>
                        How we do it?
                    </div>
                    <div className='default_flex_container' style={{gap: 48, justifyContent: "center"}}>
                        <div className="pastel_box1">
                            <div style={{padding: "32px 32px 0px 32px"}}>
                                <div className="text_title" style={{fontWeight: 700}}>
                                    Identify your alumni on web
                                </div>
                                <div className='text_body_big_light' style={{marginTop: "8px"}}>
                                    We look for public information to find your alumni on the web, then integrate them in our system 
                                    to track their news
                                </div>
                            </div>
                            <img src={HowWeDoIt1} style={{maxHeight: "298px"}}/>
                        </div>
                        <div className="pastel_box2">
                            <div>
                                <div className="text_title" style={{fontWeight: 700}}>
                                    AI powered algorithms scan
                                </div>
                                <div className='text_body_big_light' style={{marginTop: "8px"}}>
                                    Our AI searches for news mentions of these prospects in more than 400,000+ sources.
                                </div>
                            </div>
                            <img src={HowWeDoIt2} style={{maxHeight: "226px", maxWidth: "341px", margin: "47px auto 0px", display: "block"}}/>
                        </div>
                    </div>
                    {renderFullPageArrows()}
                    <div className='flex_container_space_between pastel_box3'>
                        <img src={ShareMail} style={{maxWidth: "480px", margin: "20px 0px"}}/>
                        <div style={{paddingRight: "42px", maxWidth: "356px", textAlign: "right"}}>
                            <div className="text_title" style={{fontWeight: 700}}>Share upto 5 news results</div>
                            <div className="text_body_big_light" style={{marginTop: "8px"}}>
                                You get a daily emailer having upto 5 news results featuring your alumni for free.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
    const section2Mobile = () => {
        return(
            <section className='section_2'>
                <div className='content_container' >
                    <div className='text_headline3' style={{marginBottom: "24px", textAlign: "center"}}>
                       How we do it?
                    </div>
                    <div>
                        <div className="pastel_container">
                            <div className="pastel_box1">
                                <div style={{padding: "32px 32px 0px 32px"}}>
                                    <div className="text_title" style={{fontWeight: 700}}>
                                        Identify your alumni on web
                                    </div>
                                    <div className='text_body_big_light' style={{marginTop: "8px"}}>
                                        We look for public information to find your alumni on the web, then integrate them in our system 
                                        to track their news
                                    </div>
                                </div>
                                <img src={HowWeDoIt1}/>
                            </div>
                            <div style={{margin: "auto", height: 78, width: 1, background: "white", borderLeft: "2px dashed #437EFE", borderBottom: "2px dashed #00C4B5", position: "relative"}}>
                            </div>
                        </div>
                        <div className="pastel_box2">
                            <div>
                                <div className="text_title" style={{fontWeight: 700}}>
                                    AI powered algorithms scan
                                </div>
                                <div className='text_body_big_light' style={{marginTop: "8px"}}>
                                    Our AI searches for news mentions of these prospects in more than 400,000+ sources.
                                </div>
                            </div>
                            <img src={HowWeDoIt2}/>
                        </div>
                        <div className='default_flex_container' style={{justifyContent: "center", gap: 24}}>
                            <div style={{width: 0, height: 70, border: "1px dashed #437EFE", position: "relative"}}>
                                <div style={{boxSizing: "border-box", height: "10px", width: "10px", background: "transparent", borderLeft: "2px solid #437EFE", borderBottom: "2px solid #437EFE", position: "absolute", left: 0, bottom: 0, transform: "translateX(calc(-50%)) rotate(-45deg)"}}>                    
                                </div>
                            </div>
                            <div style={{width: 0, height: 70, border: "1px dashed #437EFE", position: "relative"}}>
                                <div style={{boxSizing: "border-box", height: "10px", width: "10px", background: "transparent", borderLeft: "2px solid #437EFE", borderBottom: "2px solid #437EFE", position: "absolute", left: 0, bottom: 0, transform: "translateX(calc(-50%)) rotate(-45deg)"}}>                    
                                </div>
                            </div>
                        </div>
                        <div 
                            style={{ margin: "18px auto 0", maxWidth: "max-content", borderRadius: "60px", padding: "14px 18px 11px", height: "auto", fontSize: "17px", textTransform: "none", textAlign: "center", fontWeight: 600, lineHeight: "21px", color: "white", background: "#437EFE"}}                        
                        >
                            We match articles with the data
                        </div>
                        <div style={{width: 0, height: 70, border: "1px dashed #437EFE", position: "relative", margin: "0px auto 18px"}}>
                            <div style={{boxSizing: "border-box", height: "10px", width: "10px", background: "transparent", borderLeft: "2px solid #437EFE", borderBottom: "2px solid #437EFE", position: "absolute", left: 0, bottom: 0, transform: "translateX(calc(-50%)) rotate(-45deg)"}}>                    
                            </div>
                        </div>
                    </div>
                    <div className='flex_container_space_between pastel_box3'>
                        <img src={ShareMail} style={{maxWidth: "480px", width: "100%", margin: "0px 20px"}}/>
                        <div className='text_box'>
                            <div className="text_title" style={{fontWeight: 700}}>Share upto 5 news results</div>
                            <div className="text_body_big_light" style={{marginTop: "8px"}}>
                                You get a daily emailer having upto 5 news results featuring your alumni for free.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    const renderTestimonialPopup = () => {
        return(
            <AnPopup name="testimonialPopup" handleOnClosePopup={handlePopupClose} maxWidth="1000px" height="577px">
                <iframe style={{maxWidth: "1000px", maxHeight: "577px", width: "100%", height: "100%"}}src={testimonials_data[activeIndex].video_link} frameborder="0"  
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                </iframe>
            </AnPopup>
        )
    }

    const section5Mobile = () => {
        return (
            <section className='section_1'>
                <div className='content_container' style={{padding: "80px 30px"}}>
                    <div className="banner_box" style={{background: "#3A4459", minHeight: "fit-content", padding: 10, borderRadius: 16}}>
                        <div className='text_box' style={{padding: "42px 28px"}}>
                            <div style={{maxWidth: "542px", zIndex: 99}}>
                                <div className="text_title_big" style={{top: "50%", color: "white"}}>
                                    Try AlmaConnect News Premium
                                </div>
                                <div className='text_title_normal' style={{marginTop: "12px", color: "white"}}>
                                    Discover what our Premium service has to offer.
                                </div>
                                <a href='https://calendly.com/almaconnect-news-scanner/custom-demo' onClick={() => AmplitudeEventTrack('learn_more_try_premium')}>
                                <Button buttonSize='Big' 
                                    buttonStyle={{marginTop: "25px"}}
                                    innerButtonStye={{padding: "12px 44px", height: "auto"}}
                                >
                                        Try Premium
                                </Button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    return (
    <div className="learn_extra_container">
        <section className='section_1'>
            <div className='content_container'>
                <div className="banner_box">
                    <div className='text_box'>
                        <div style={{maxWidth: "542px", zIndex: 99}}>
                            <div className="text_headline1" style={{top: "50%"}}>
                                Get <span style={{color: "#437EFE"}}>free news alerts</span> of your alumni
                            </div>
                            <div className='text_title_normal' style={{marginTop: "16px"}}>
                                Subscribe to 'AlmaConnect Free Newsletter' service to receive news results featuring your alumni.
                            </div>
                            <Button buttonSize='Big' 
                                    buttonStyle={{marginTop: "24px"}}
                                    innerButtonStye={{padding: isWidthDown(windowDimensions.width, "lg") ? "12px 45px" :"12px 64px", height: "auto"}}
                                    onClick={handleOnsubscribe}
                                    isLoading={verifying}
                                >
                                    Subscribe Now
                            </Button>
                        </div>
                    </div>
                    <div className='image_flex_container'>
                        <div className="flex-item-1"></div>
                        <div className="flex-item-2">
                            <img src={NewsBanner} />
                        </div>
                    </div>
                </div>
                <div className='what_is_it_box default_flex_container' style={{gap: "62px"}}>
                    <div className='text_box' style={{padding: 0, maxWidth: 550}}>
                        <div className='text_headline3' style={{marginBottom: "24px", fontWeight: 800}}>
                            What is it?
                        </div>
                        <div className="default_flex_container text_title_normal" style={{alignItems: "flex-start", marginBottom: "21px"}}>
                            <img src={ArrowBlueFilled} width="36px" style={{marginRight: "12px"}} />
                            <div> 
                                AlmaConnect Free Newsletter is a <span style={{color: "#437EFE"}}>free email subscription service</span> to access news alerts when your 
                                alumni gets featured.
                            </div>
                        </div>
                        <div className="default_flex_container text_title_normal" style={{alignItems: "flex-start", marginBottom: "21px"}}>
                            <img src={ArrowBlueFilled} width="36px" style={{marginRight: "12px"}} />
                            <div>
                                In an email feed, receive upto <span style={{color: "#437EFE"}}>5 news results</span> regularly.  
                            </div>
                        </div>
                        <div className="default_flex_container text_title_normal" style={{alignItems: "flex-start"}}>
                            <img src={ArrowBlueFilled} width="36px" style={{marginRight: "12px"}} />
                            <div>   
                                There will be <span style={{color: "#437EFE"}}>zero manual effort</span> on your part. All you will need to do is utilise the news 
                                results to generate better fundraising opportunities.
                            </div>
                        </div>
                    </div>
                    <img src={WhatIsIt} style={{maxWidth: "540px",width: "100%", maxHeight: 400, height: "100%"}}/>
                </div>
            </div>
        </section>
        <hr style={{width:"60%", margin: "auto", marginTop: "76px", marginBottom: 24}}></hr>
        {isWidthDown(windowDimensions.width, "lg") ? section2Mobile() : section2FP()}
        <section className='section_3'>
            <div className='content_container'>
                <div className='text_headline3' style={{marginBottom: "2px", textAlign: "center", fontWeight: 800}}>
                    BENEFITS
                </div>
                <div className='text_title_large' style={{fontWeight: 400, textAlign: "center"}}>
                    Discover new prospects and engage better with current donors -
                </div>
                <div className='text_title_large' style={{textAlign: "center", color: "#282A2C", fontWeight: 800}}>
                    Effortlessly, Accurately, Globally
                </div>
                <div  className="line_seperator_ex_light_grey" style={{width: "85%", textAlign: "center", margin: "16px auto 24px" }}></div>
                <div className='icon_info_box'>
                    <div style={{maxWidth: "660px"}}>
                        <div className='text_title_big'>
                            Engage with alumni
                        </div>
                        <div className='text_title' style={{marginTop: "8px", color: "#707274", fontWeight: 600}}>
                            Use this information to reach out to your alumni, congratulate them & strengthen 
                            your relationship. You can even share the success stories of these alumni in your community
                        </div>
                    </div>
                    <img src={Engage} style={{width: "100px", height: "100px"}}/>
                </div>
                <div  className="line_seperator_ex_light_grey" style={{width: "85%", textAlign: "center", margin: "16px auto 24px" }}></div>          
                <div className='icon_info_box'>
                    <div style={{maxWidth: "660px"}}>
                        <div className='text_title_big'>
                            Find hidden prospects
                        </div>
                        <div className='text_title' style={{marginTop: "8px", color: "#707274", fontWeight: 600}}>
                            People are progressing in their careers all the time, news events help you 
                            identify alumni who might not have been hidden on your radar so far.
                        </div>
                    </div>
                    <img src={FindHidden} style={{width: "100px", height: "100px"}}/>
                </div>
                <div  className="line_seperator_ex_light_grey" style={{width: "85%", textAlign: "center", margin: "16px auto 24px" }}></div>
                <div className='icon_info_box'>
                    <div style={{maxWidth: "660px"}}>
                        <div className='text_title_big'>
                            Accuracy & Worldwide Reach
                        </div>
                        <div className='text_title' style={{marginTop: "8px", color: "#707274", fontWeight: 600}}>
                            Get news results with more than 97% accuracy for which our system crawls news feeds globally
                        </div>
                    </div>
                    <img src={AccuracyAndReach} style={{width: "100px", height: "100px"}}/>
                </div>
            </div>
        </section>
        <section className='section_4'>
            <div className='content_container' style={{paddingTop: "0px"}}>
                <div className="table_box">
                    <div className='text_headline3'>
                        Compare features across our Free and Premium plans
                    </div>
                    <div className='header_row' style={{gridTemplateColumns: "repeat(2, 34%)"}}>
                        <div className='item text_title_big center' >Free</div>
                        <div className='item text_title_big blue center'>Premium</div>
                    </div>
                    <div className='grid_row'>
                        <div className='item col1'>
                            Prospects Tracked
                        </div>
                        <div className='item center col2'>
                            upto 10,000
                        </div>
                        <div className='item center col3 blue'>
                            Unlimited
                        </div>
                    </div>
                    <div className='grid_row'>
                        <div className='item col1'>
                            No. of News Results
                        </div>
                        <div className='item center col2'>
                            upto 5 per email                            
                        </div>
                        <div className='item center col3 blue'>
                            Unlimited                            
                        </div>
                    </div>
                    <div className='grid_row'>
                        <div className='item col1'>
                            Personalized Email Digest
                        </div>
                        <div className='item center col2'>
                            No                          
                        </div>
                        <div className='item center col3 blue'>
                            Yes                          
                        </div>
                    </div>
                    <div className='grid_row'>
                        <div className='item col1'>
                            Platform Accessibility
                            <div className='sub_text' style={{marginTop: "4px"}}>
                                Team management, Tags & Filters, Comments,
                                CRM Integration and many more
                            </div>
                        </div>
                        <div className='item center col2'>
                            No                          
                        </div>
                        <div className='item center col3 blue'>
                            Yes                          
                        </div>
                    </div>
                    <div className='grid_row'>
                        <div className='item col1'>
                            Support
                        </div>
                        <div className='item center col2'>
                            No                          
                        </div>
                        <div className='item center col3 blue'>
                            Yes                          
                        </div>
                    </div>
                    <div className='grid_row' style={{border: "none"}}>
                        <div className='item col1'>
                            Smart Notifications
                        </div>
                        <div className='item center col2'>
                            No                          
                        </div>
                        <div className='item center col3 blue'>
                            Yes                          
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {isWidthDown(windowDimensions.width, 1035) ?  
            section5Mobile() :
            <section className='section5'>
                <div className='content_container'>
                    <div className="banner_box">
                        <div className='text_box'>
                            <div style={{maxWidth: "542px", zIndex: 99}}>
                                <div className="text_title_big" style={{top: "50%", color: "white"}}>
                                    Try AlmaConnect News Premium
                                </div>
                                <div className='text_subtitle_big' style={{marginTop: "16px", color: "white"}}>
                                    Discover what our Premium service has to offer    
                                </div>
                                <a
                                onClick={() => AmplitudeEventTrack('learn_more_try_premium')}
                                 style={{display: "block", maxWidth: "fit-content"}} href="https://calendly.com/almaconnect-news-scanner/custom-demo" target="_blank">
                                    <Button buttonSize='Big' 
                                        buttonStyle={{marginTop: "24px"}}
                                        innerButtonStye={{padding: "13px 64px", height: "auto"}}
                                    >
                                            Try Premium
                                    </Button>
                                </a>
                            </div>
                        </div>
                        <img src={TryPremium}/>
                    </div>
                </div>
        </section>
        }
        <section className="section6">
            <div className='content_container'>
                <img src={BryantLogo} style={{maxHeight: "68px", width: "216px", margin: "39px 0px 32px"}}/>
                <div className='testimonial_text'>"I am very happy with the service and continue to find prospects that I would not have found through other methods."</div>
                <div className='text_title' style={{margin: "auto", padding: "32px 14px 38px", fontWeight: 700, color: "#707274"}}>- Nicole Melmed, <span style={{fontWeight: 600}}> Bryant University</span></div>
                <div style={{display: 'flex', paddingBottom: "66px", justifyContent: "center"}}>
                <Button buttonSize="Big" 
                        buttonStyle={{width: 'max-content'}} 
                        innerButtonStye={{padding: "12px 72px", height: "auto"}}
                        onClick={handleOnsubscribe}
                        isLoading={verifying}
                >
                    Subscribe now
                </Button>
                </div>
            </div>
        </section>
        {popupsList.testimonialPopup && renderTestimonialPopup()}
    </div>
  )
}

export default LearnMore