import { useEffect, useMemo, useReducer, useState } from "react"
import { Button } from "../../../cta_utils/AnButton"
import AnPopup from "../../../cta_utils/AnPopup"
import { AnInputBox } from "../../../input_utils/AnInput"
import ConditionalRender from "../../../../ConditionalRender"
import { PastEducation } from "./PastEducation"
import MenuDots from '../../../../assets/MenuDots.svg'
import reducer from "../../../utils/CommonReducer"
import AnDropDown from "../../../cta_utils/AnDropDown"
import { indexOfObject } from "../../../utils/ArrayUtils"
import { remove_past_education } from "../api"
import ALL_PROSPECT_SHOW_ACTIONS from "./actions/prospectProfileShowActions"
import getApiUrl from "../../../utils/UrlHelper"
import { apiAgent } from "../../../../axiosConfig"
const initialCurrentEducation = {id: 0, institute_name: '', course: '', branch: '', from_year: '', to_year: ''}
export const PastEducationEditPopup = ({
    handlePopupClose,
    profileData,
    profileDataDispatcher,
    ...props
}) => {
    const [popupState, popupStateReducer] = useReducer(reducer, initialState)
    const setState = (payload) => popupStateReducer({type: 'SET_STATE', payload: payload})
    const toggleDropDown = () => setState({dropDownList: {}})
    const {active, dropDownList} = popupState
    const past_educations = [...profileData.prospectProfile.past_educations]
    const isLoading = profileData.isLoading
    const prospect_id = profileData.prospectProfile.id
 
    const addAnother = () => {
        let new_past_edu = {...initialCurrentEducation}
        setProspectProfile({past_educations: [new_past_edu, ...past_educations]})
        setState({active: new_past_edu, dropDownList: {}, isNew: true})
    }
    const setProspectProfile = (payload) => {
        profileDataDispatcher({
            type: ALL_PROSPECT_SHOW_ACTIONS['SET_STATE'],
            payload: {
                prospectProfile: {
                    ...profileData.prospectProfile,
                    ...payload
                },
            }
        })
    }
    const handleInputChange = (e, index) => setState({active: {...popupState.active, [e.target.name]: e.target.value}})
    const handleSubmitEducation = () => {
        let temp_education = {};
        Object.keys(active).forEach(key => {
          if (active[key]) {
            temp_education[key] = active[key];
          }
        });        
        apiAgent({
            method: "post",
            url: getApiUrl("edit_past_education"),
            data: {past_education: temp_education, prospect_id: profileData.prospectProfile.id}
          }).then(res => {
            setProspectProfile({past_educations: res.data.past_educations})
            collapse();
          }).catch(
            (error) => {
                if(error?.response?.data?.error)
                setState({errors: {validation: error?.response?.data?.error}})
            }
          )
    }
    const removePastEducation = () => {
        let temp_educations = [...past_educations]
        let data_params = {prospect_id: prospect_id, past_education_id: temp_educations[active_dropdown_index]?.id}
        remove_past_education(data_params).then(res => {
            if(active_dropdown_index !== -1)
            temp_educations.splice(active_dropdown_index, 1)
            profileDataDispatcher({
                type: ALL_PROSPECT_SHOW_ACTIONS['SET_STATE'],
                payload: {
                    prospectProfile: {
                        ...profileData.prospectProfile,
                        past_educations: temp_educations
                    },
                    tempProfile: {
                        ...profileData.tempProfile,
                        past_educations: temp_educations
                    }
                }
            })

        }).catch(err => {

        })
    }
    const renderactivePastEducationDropdown = () => {
        return (
            <AnDropDown style={{position: 'absolute', right: 42, padding: '20px', background: 'white', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', borderRadius: 4, width: 'max-content', zIndex: 99}}
            toggleDropDown={toggleDropDown}>
            <div  className="an_dropdown_filter text_body" style={{cursor: 'pointer'}} onClick={() => expand()}>Edit</div>
            <div  className="an_dropdown_filter text_body" style={{cursor: 'pointer'}} onClick={() => removePastEducation()}>Remove</div>
            </AnDropDown>
        )
    }
    const collapse = () => {setState({active: {}, dropDownList: {}, isNew: false, errors: {}})}
    const expand = () => {
        let temp_educations = [...past_educations]
        if(active_dropdown_index !== -1)
        setState({active: temp_educations[active_dropdown_index]})
    }
    const cleanupAddAnother = () => {
        let temp_educations = [...past_educations]
        let valid_educations = temp_educations.filter((edu) => edu.institute_name)
        setProspectProfile({ past_educations: valid_educations})
   }

    const active_dropdown_index = useMemo(() => {
        let temp_educations = [...past_educations]
        let id =  Object.keys(popupState.dropDownList)?.[0]
        let index = indexOfObject(temp_educations, "id", id)
        return index
    }, [dropDownList])

    return (
        <AnPopup name="EditEducationPopup" handleOnClosePopup={() => {handlePopupClose();collapse();}}>
            <div className="text_title">
                Edit Past Education
            </div>
            <div className="line_seperator_ex_light_grey" style={{ margin: "16px -24px" }}></div>
            <ConditionalRender if={!popupState.isNew}>
            <div className="text_link" style={{cursor: 'pointer'}} onClick={() => addAnother()}>+Add Education</div>
            </ConditionalRender>
            <div className="separated_border past_edu_container">
            {
                past_educations.map((past_education, index) => {
                    return (
                        <div key={past_education.id}>
                            <ConditionalRender if={active.id !== past_education.id}>
                                <div className='flex_container_space_between' style={{margin: '16px 0px 0px'}}>
                                <PastEducation edu={past_education}></PastEducation>
                                <div style={{cursor: 'pointer'}} onClick={() => setState({ dropDownList: {[past_education.id]: true}})}>
                                    <img src={MenuDots}></img>
                                    {popupState.dropDownList?.[past_education.id] && renderactivePastEducationDropdown()}
                                </div>
                                </div>
                            </ConditionalRender>
                            <ConditionalRender if={active.id === past_education.id}>
                            <div>
                                <AnInputBox label={"Institution"} style={{ marginTop: popupState.isNew ? 0 : 12 }}>
                                    <input type="text" id="institute_name" name="institute_name" value={active.institute_name} onChange={(e) => handleInputChange(e, index)} />
                                </AnInputBox>
                                <div className="default_flex_container">
                                    <AnInputBox label={"Course"} style={{ marginTop: 8, marginRight: 12 }}>
                                        <input type="text" id="course" name="course" value={active.course} onChange={(e) => handleInputChange(e, index)} />
                                    </AnInputBox>
                                    <AnInputBox label={"Branch"} style={{ marginTop: 8 }}>
                                        <input type="text" id="branch" name="branch" value={active.branch} onChange={(e) => handleInputChange(e, index)} />
                                    </AnInputBox>
                                </div>
                                <div className="default_flex_container">
                                    <AnInputBox label={"Joining year"} style={{ marginTop: 8, marginRight: 12 }}>
                                        <input type="text" id="from_year" name="from_year" value={active.from_year} onChange={(e) => handleInputChange(e, index)} />
                                    </AnInputBox>
                                    <AnInputBox label={"Graduation year"} style={{ marginTop: 8 }}>
                                        <input type="text" id="to_year" name="to_year" value={active.to_year} onChange={(e) => handleInputChange(e, index)} />
                                    </AnInputBox>
                                </div>
                            </div>
                                <div style={{ display: 'flex', marginTop: 8 }}>
                                    <Button buttonSize="Medium" innerButtonStye={{ padding: '8px 36px' }}
                                        onClick={() => handleSubmitEducation()} isLoading={isLoading}>Save</Button>
                                    <Button buttonSize="Medium" type="Secondary" buttonStyle={{ marginLeft: 8 }} innerButtonStye={{ padding: '8px 28px' }}
                                        onClick={() => {collapse();cleanupAddAnother();}} isLoading={false}>Cancel</Button>
                                </div>
                                <ConditionalRender if={popupState.errors["validation"]}>
                                <div className="text_caption_error" style={{marginTop: 4}}>{popupState.errors["validation"]}</div>
                                </ConditionalRender>
                            </ConditionalRender>
                        </div>
                    )
                })
            }
            </div>
        </AnPopup>
    )
}
const initialState = {
    active: {},
    dropDownList: {},
    isNew: false,
    errors: {}
}