import GettingStarted1 from "../../../../../assets/help_center/getting_started1.png"
import GettingStarted2 from "../../../../../assets/help_center/getting_started2.png"
import GettingStartedTags from "../../../../../assets/help_center/getting_started_tags.png"
import GettingStartedTeam from "../../../../../assets/help_center/getting_started_team.png"
import SettingsDropdown from "../../../../../assets/help_center/settings_dropdown.png"

export const SettingsBasics = () => {
    return (
        <div style={{ margin: "24px 0px" }}>
        <div className="help_center_top_section"> 
            <div className="text_title_big" style={{marginBottom: 20}}>Settings basics</div>
        </div>
            <div className="help_center_text">
                Account Settings helps you adjust the details of your profile and manage your team and its settings.
            </div>
            
            <div className="help_center_text">
            To access Settings:<br/>
            <ul style={{marginLeft: -4}}>
                <li>From the top bar, click on your profile photo</li>
                <li>Select Settings from the drop-down menu</li>
            </ul>
            </div>
            
            <img className="help_center_img" src={SettingsDropdown} style={{maxWidth: "650px", maxHeight: "346px", margin: "auto"}}></img>
            


            <div className="help_center_text">
                In the Settings menu, you will find 6 tabs, each with different options:
            </div>
            
            <div className="help_center_text">
                <ul>
                    <li><span style={{fontWeight: 600}}> Profile -</span> change your basic profile details like Name, Phone number and Title</li>
                    <li><span style={{fontWeight: 600}}>Notifications -</span> set your notification settings when you want to receive notifications - for all news mentions, or only for news mentions of prospects assigned to you - and also set the email notification frequency</li>
                    <li><span style={{fontWeight: 600}}>Data Fields -</span> view the list of default fields present and create custom fields to import prospect data. </li>
                    <li><span style={{fontWeight: 600}}>Manage Team -</span> view the current members of your team, assign roles to team members and invite new members</li>
                    <li><span style={{fontWeight: 600}}>Team Notifications -</span> set notifications and notification frequency for your team members</li>
                    <li><span style={{fontWeight: 600}}>Team Settings -</span> access your team's settings to change your team’s name, country, time zone and team aliases </li>
                </ul>
            </div>

            
            <div className="help_center_notes" style={{marginTop: 16}}>
                <div className="help_center_item" style={{ padding: "11px 14px"}}>
                    <ul>
                        <li>Admin Users have access to all the features on the portal</li>
                        <li style={{marginTop: 4}}>Regular users have limited access. They do not have access to:<br/>
                            - Archiving a prospect<br/>
                            - Downloading prospect data<br/>
                            - Manage Team<br/>
                            - Team Notifications<br/>
                            - Team Settings<br/>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};