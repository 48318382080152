import React, { useMemo } from 'react';

export const TextHighlighter = ({
    contentString,
    toMatch, // string, array of string, [{toMatchText: '', highlightCustomStyle: ''}],
    typography={className: 'text_body_light'},
    highlightStyle="background: rgba(255, 172, 18, 0.4); text-transform: capitalize",
    ...props
}) => {
    let toMatches = Array.isArray(toMatch) ? toMatch : [toMatch];
    toMatches = toMatches.map((item) => {
        if (item !== null && item !== undefined) {
            if (typeof item === 'number') {
                item = item.toString();
            }
            return typeof item === 'string' ? { toMatchText: item } : item;
        }
        return null;
    }).filter(item => item !== null);
    let toMatchStr = toMatches.map((toMatch) => toMatch?.toMatchText).join(", ")
    const replaceContent = useMemo(() => {
        if (!toMatch || !contentString) return contentString;

        let replacedContent = contentString;
        toMatches.forEach((toMatchExp) => {
            if (toMatchExp && toMatchExp.toMatchText) {
                let temptoMatchText = toMatchExp.toMatchText;
                let highlightCustomStyle = toMatchExp.highlightCustomStyle || highlightStyle;
                let escapedToMatchExp = escapeRegex(temptoMatchText);
                let matchExp = new RegExp(`(${escapedToMatchExp})(?![^<]*<\/span>)`, "gi");
                replacedContent = replacedContent.replaceAll(matchExp, `<span style="${highlightCustomStyle}">${temptoMatchText}</span>`);
            }
        });

        return replacedContent;
    }, [contentString, toMatchStr, highlightStyle]);

    if (!contentString) return <div></div>;
    return <div className={typography.className} style={props.style} dangerouslySetInnerHTML={{ __html: replaceContent }}></div>;
};

export const escapeRegex = (string) => {
    return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
};