import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import MenuDots from '../../../../../assets/MenuDots.svg'
import {NameInitial} from "../../../../cta_utils/AnNameInitial";
import {Button} from "../../../../cta_utils/AnButton";
import {apiAgent} from "../../../../../axiosConfig";
import ConditionalRender from "../../../../../ConditionalRender";
import ALL_LIST_ACTIONS from '../actions/prospectListActionTypes';
import useWindowSize from '../../../../custom_hooks/useWindowSize';
import { isWidthDown } from '../../../../utils/designUtils';
import TopProspectImage from '../../../../../assets/top_prospect.svg';
import AnToolTip from '../../../../cta_utils/AnTooltip';

const Prospect = (props) => {

  const ConfidenceResult = (Type) => {
    return (
      <Link 
          to={`${getFeedurl(`${props.prospect.id}`, `tabType=feed&postsType=${Type}`)}`} 
          className="text_caption hover_under_line">
          { Type == 'high' ? props.prospect.high_confidence_results : props.prospect.low_confidence_results }
      </Link>
    );
  }

  const [isLoading, setIsLoading] = useState(false);
  const windowDimensions = useWindowSize();

  const unarchiveProspect = () => {
    setIsLoading(true);
    apiAgent({
      method: 'post',
      url: `/prospects/${props.prospect.id}/unarchive`,
      data: {}
    }).then(res => {
      setIsLoading(false)
      let payload = {
          prospect_id: props.prospect.id,
          successMessages: {
              message: "Prospect Unarchived",
              from: "Prospect Unarchived",
          }
      }
      props.prospectsListDispatcher({
        type: ALL_LIST_ACTIONS["UPDATE_PROSPECT_LIST"], 
        payload: payload
    })
    }).catch(err => {
      setIsLoading(false)
    })
  }

  const renderProspectActions = () => {
    if(isWidthDown(windowDimensions.width, "lg"))
      return (
        <div className='default_flex_container' style={{paddingTop: 0}}>
          <div style={{position: 'relative'}} onClick={() => {
            props.setCurrentProspect(props.prospect)
          }}>
            <img src={MenuDots} style={{cursor: 'pointer'}}/>
            {(props.currentProspect.id == props.prospect.id) && props.prospectDropDown && props.renderProspectDropdown()}
          </div>
        </div>
      )
      else
      return (       
        !props.archived ?
            (
                <div className='default_flex_container' style={{paddingTop: 8}}>
                  <div className="text_caption_small_light" style={{paddingTop: 4, paddingRight: 12, width: 92, textAlign: 'right'}}>HIGH {ConfidenceResult('high')} ; LOW {ConfidenceResult('low')}</div>
                  <div style={{position: 'relative'}} onClick={() => {
                    props.setCurrentProspect(props.prospect)
                  }}>
                    <img src={MenuDots} style={{cursor: 'pointer'}}/>
                    {(props.currentProspect.id == props.prospect.id) && props.prospectDropDown && props.renderProspectDropdown()}
                  </div>
                </div>
            )
            :
            (
                <Button buttonSize="LessPadding" type="Secondary" isLoading={isLoading} onClick={() => {unarchiveProspect()}} buttonStyle={{float: 'right', marginLeft: 16}}>
                  Unarchive
                </Button>
            )         
      )
  }
  return(
    <>
      <div className="prospect_block">
        <Link to={{
          pathname: `${getFeedurl(`${props.prospect.id}`)}`,
          state: {filtersState: props.filtersState, from: window.location.pathname}          
        }} 
          style={{textDecoration: 'none', cursor: 'pointer', display: 'flex'}}>
          <NameInitial name={props.prospect.name || props.prospect.designation} profile_image={props.prospect.profile_image}/>
          <div className='prospect_sub_block'>
            <div className="default_flex_container">
              <div className="text_subtitle_big">{props.prospect.name || props.prospect.designation}</div>
              {
                props.prospect.top_prospect &&
                  (
                    <AnToolTip
                    innerStyle={{padding: '4px 8px'}}
                    tooltipText={props.prospect.top_prospect_stat}
                    >
                      <img src={TopProspectImage} style={{marginLeft: 8}}/>
                    </AnToolTip>   
                )
              }
              <div className="truncate" style={{marginLeft: 16}}>
                {
                  props.prospect.tags?.map(tag => <span key={tag.id} className="text_caption_small_light" style={{textTransform: 'uppercase', marginRight: 8}}>{tag.name}</span>)
                }
              </div>
            </div>
            <ConditionalRender if={props.prospect.name}>
              <div className="text_body_light truncate">
                {props.prospect.designation}
              </div>
            </ConditionalRender>
          </div>
        </Link>
        {
          !props.archived ?
              (
                  <div style={{display: 'flex', paddingTop: 8}}>
                    <div className="text_caption_small_light" style={{paddingTop: 4, paddingRight: 12, textAlign: 'right'}}>HIGH {ConfidenceResult('high')} ; LOW {ConfidenceResult('low')}</div>
                    <div style={{position: 'relative'}} onClick={() => {
                      props.setCurrentProspect(props.prospect)
                    }}>
                      <img src={MenuDots} style={{cursor: 'pointer'}}/>
                      {(props.currentProspect.id == props.prospect.id) && props.prospectDropDown && props.renderProspectDropdown()}
                    </div>
                  </div>
              )
              :
              (
                  <Button buttonSize="LessPadding" type="Secondary" isLoading={isLoading} onClick={() => {unarchiveProspect()}} buttonStyle={{float: 'right', marginLeft: 16}}>
                    Unarchive
                  </Button>
              )
        }
      </div>
    </>
  )
}
export default Prospect;

export const getFeedurl = (prospect_id, params=null) => {
  if(params)
  return (`/prospects/${prospect_id}?${params}`)

  return (`/prospects/${prospect_id}`)
}
