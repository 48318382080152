import { useMemo, useState } from "react";
import tick2 from "../../assets/tick2.svg"
import filtersIcon2 from "../../assets/filtersIcon2.svg"
import AnDropDown from "./AnDropDown.js";
import ConditionalRender from "../../ConditionalRender.js";
import Icon from "../utils/Icon.js";
import { AnInputRadioButton } from "../input_utils/AnInputRadioButton.js";
import Tooltip from "./Tooltip.js";

const NewDropdownGroupFilter = ({
    group_options,
    filterList,
    onChangeCallback,
    customContainerClass,
    customStyle,
    positionRight = false,
    outerDropdownStyle,
    clearFilterCallBack,
    ...props
}) => {
    const [showdropdown, setShowdropdown] = useState(false);
    const [activeOption, setActiveOption] = useState(group_options[0].group_key);
    const toggleDropDown = () => { setShowdropdown(d => !d) }
    const handleOptionToggle = (e, group) => {
        e.stopPropagation(); 
        if(activeOption === group.group_key)
        setActiveOption("")
        else
        setActiveOption(group.group_key)
    }
    const renderGroupOptions = () => group_options.map((group, ind) => (
        <div key={group.group_key}>
            <div className='flex_container_space_between'
                onClick={(e) => handleOptionToggle(e, group)}
                style={{ cursor: "pointer" }}
            >
                <div className="default_flex_container">
                    <div className="text_subtitle" style={{marginRight: 4}}>{group.group_label}</div>
                    <ConditionalRender if={filterList[group.group_key] !== 'all'}>
                        <Icon name="GreenDot"/>
                    </ConditionalRender>
                </div>
                <Icon name="GreyArrowDown" className={
                    activeOption === group.group_key
                        ? "to_arrow_up"
                        : "to_arrow_down"
                }
                  style={{ width: 24 }} />
            </div>
            <div onClick={(e) => e.stopPropagation()}>
                <ConditionalRender if={group.group_key === activeOption}>
                {
                    group.options.map((option, index) => (
                        <AnInputRadioButton key={option.key} label={option.label}
                            isChosen={filterList[group.group_key] === option.key}
                            style={{marginBottom: 10, marginTop: index === 0 ? 10 : 0}}
                        >
                            <input type="radio"
                                checked={filterList[group.group_key] === option.key}
                                onChange={(e) => {onChangeCallback(group.group_key, option.key)}}
                            />
                        </AnInputRadioButton>
                    ))
                }
                </ConditionalRender>
            </div>
            <ConditionalRender if={ind !== group_options.length - 1}>
                <div className="line_seperator_ex_light_grey" style={{ margin: "12px 0px" }}></div>
            </ConditionalRender>
        </div>
    ))
    const filterApplied = useMemo(() => {
       return group_options.filter((group) => filterList[group.group_key] !== 'all').length > 0
    }, [filterList])
    const handleFilter = () => {
        return (
            <AnDropDown
                style={{
                    position: "absolute",
                    right: positionRight ? 'auto' : 0,
                    top: 36,
                    padding: "14px",
                    background: "white",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                    borderRadius: 4,
                    width: "278px",
                    zIndex: 99,
                    marginTop: 4,
                    ...outerDropdownStyle
                }}
                toggleDropDown={toggleDropDown}
            >
                <div className="flex_container_space_between">
                    <div className="text_subtitle_big" style={{ lineHeight: '24px' }}>More Filters</div>
                    <ConditionalRender if={filterApplied}>
                        <div className="text_link" onClick={() => {toggleDropDown();clearFilterCallBack()}}>Clear</div>
                    </ConditionalRender>
                </div>
                <div className="line_seperator_ex_light_grey" style={{ margin: "12px auto" }}></div>
                {renderGroupOptions()}
            </AnDropDown>
        )
    }
    return (
        <div>
            <div style={{ position: 'relative' }}>
                <Tooltip
                    tooltipText={() => 'More Filters'}
                    innerClass='text_body_light'
                    textTheme='background-black bottom'
                    innerStyle={{ padding: '8px', alignSelf: 'center', color: 'white', borderRadius: '8px', opacity: '70%' }}
                    style={{ marginTop: 4 }}
                    showWithoutOverflowing={true}
                >
                    <div className={customContainerClass} onClick={toggleDropDown} style={customStyle}>
                        <Icon className={filterApplied ? "white_image" : ""} name="FiltersIcon2" />
                    </div>
                </Tooltip>
                {showdropdown && handleFilter()}
            </div>
        </div>
    )
}
//propTypes = { group_options, filterList, setOption, customStyle}

export default NewDropdownGroupFilter