import React from 'react'
import ConditionalRender from '../../ConditionalRender'
import checkBox from '../../assets/checkBox.svg'
import Dash from '../../assets/Dash.png'

export const AnInputCheckBox = ({
    labelStyle={marginLeft: 8},
    labelClass="text_body_light",
    isChecked=false,
    ...props
  }) => {
    const isDisabled = props.children?.props?.disabled;
    return(
      <div className="an_input_checkbox" style={props.style}>
        <label>          
          {props.children}
          <div className="checkbox">
            <div className="tick">
              <img src={checkBox}></img>
            </div>
          </div>
          <ConditionalRender if={props.label}>
            <span className={isChecked ? 'text_body' : 'text_body_light'} style={labelStyle}>
              {props.label}
            </span>
          </ConditionalRender>
        </label>
        <ConditionalRender if={props.error}>
          <div className="text_caption_error" style={{marginTop: 8}}>{props.error}</div>
        </ConditionalRender>
      </div>
  )
}
