import { apiAgent } from "../../../axiosConfig";

export const feedFetcher = (q) => apiAgent({
    method: "get",
    url: "/search/articles",
    params: {q: q}
})

export const saveSearch = (searchSpace) => apiAgent({
    method: "post",
    url: "/search_spaces/create_or_update",
    data: {searchSpace: searchSpace}
})
