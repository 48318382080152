import { useState } from "react"
import AnPopup from "./AnPopup"
const SuccessMessagePopup = ({
    ...props
}) => {
    const [close, setClose]=useState(false)
    const handlePopupClose = () => {
        setClose(true);
    }
    return (
        <>
            {!close &&
            <AnPopup handleOnClosePopup={handlePopupClose} maxWidth={props.maxWidth}>
                {props.children}
            </AnPopup>}
        </>
    );
}
     
export default SuccessMessagePopup;