import { indexOfObject, swapItems } from "../../../utils/ArrayUtils";
import { FillColumnValue } from "../TableUtils";
export const nameStyle = {position: 'sticky', left: 0, background: 'white'}
export const initialTableState = {
    tableData: [],
    all_columns: [
        {
            is_fixed: false, fields: [
                { key: 'name', label: 'Name', labelStyle: { minWidth: "180px", width: '25%', ...nameStyle}, display: true, search_enabled: true, dropDownEnabled: false, swapEnabled: false, sortingEnabled: false, sorting_type: 'text' },
                { key: 'company', label: 'Company', labelStyle: { minWidth: "140px", width: '25%' }, display: true, search_enabled: false, dropDownEnabled: true, swapEnabled: true, sortingEnabled: false, sorting_type: 'text' },
                { key: 'university', label: 'University', labelStyle: { minWidth: "140px", width: '25%' }, display: true, search_enabled: false, dropDownEnabled: true, swapEnabled: true, sortingEnabled: false, sorting_type: 'text' },
                { key: 'grad_year', label: 'Grad Year', labelStyle: { minWidth: "120px", width: '25%'}, display: true, search_enabled: false, dropDownEnabled: true, swapEnabled: true, sortingEnabled: false, sorting_type: 'text' },
                { key: 'first_name', label: 'First Name', labelStyle: { minWidth: "120px" }, display: false, search_enabled: false, dropDownEnabled: true, swapEnabled: true, sortingEnabled: false, sorting_type: 'text' },
                { key: 'last name', label: 'Last Name', labelStyle: { minWidth: "120px" }, display: false, search_enabled: false, dropDownEnabled: true, swapEnabled: true, sortingEnabled: false, sorting_type: 'text' },
                { key: 'course', label: 'Course', labelStyle: { minWidth: "100px" }, display: false, search_enabled: false, dropDownEnabled: true, swapEnabled: true, sortingEnabled: false, sorting_type: 'text' },
                { key: 'location', label: 'Location', labelStyle: { minWidth: "160px" }, display: false, search_enabled: false, dropDownEnabled: true, swapEnabled: true, sortingEnabled: false, sorting_type: 'text' },
                { key: 'alias_names', label: 'Alias Name', labelStyle: { minWidth: '140px' }, display: false, search_enabled: false, dropDownEnabled: true, swapEnabled: true, sortingEnabled: false, sorting_type: 'text' },
                { key: 'title', label: 'Title', labelStyle: { minWidth: '140px' }, display: false, search_enabled: false, dropDownEnabled: true, swapEnabled: true, sortingEnabled: false, sorting_type: 'text' },
            ]
        },
        {
            is_fixed: true, fields: [
                { key: 'tags', label: 'Tags', labelStyle: { minWidth: "204px", width: '100%' }, search_enabled: false, dropDownEnabled: false, swapEnabled: false, sortingEnabled: false, sorting_type: 'text' },
                { key: 'order', label: 'Order', labelStyle: { minWidth: "100px", width: '100%' }, search_enabled: false, dropDownEnabled: true, swapEnabled: false, sortingEnabled: true, sorting_type: 'text' },
                { key: 'suggestions', label: 'Sugg.', labelStyle: { minWidth: "80px", width: '100%' }, search_enabled: false, dropDownEnabled: true, swapEnabled: false, sortingEnabled: true, sorting_type: 'text' },
                { key: 'confidence', label: 'Confid.', labelStyle: { minWidth: "90px", width: '100%' }, search_enabled: false, dropDownEnabled: true, swapEnabled: false, sortingEnabled: true, sorting_type: 'text' },
                { key: 'status', label: 'Status', labelStyle: { minWidth: "100px", width: '100%' }, search_enabled: false, dropDownEnabled: true, swapEnabled: false, sortingEnabled: true, sorting_type: 'text' },
            ]
        },
    ],
    dropDownList: {},
    searchBarActive: false
}
const TableReducer = (state, action) => {
    switch (action.type) {
        case "SET_STATE": {
            return {
                ...state,
                ...action.payload
            }
        }
        case "SWAP_COLUMN": {
            let { column_index, index1, index2 } = action.payload
            let new_all_columns = [...state.all_columns]
            new_all_columns[column_index] = { ...new_all_columns[column_index] }
            let old_fields = [...new_all_columns[column_index].fields]
            let new_fields = swapItems(old_fields, index1, index2)
            new_all_columns[column_index].fields = [...new_fields]
            return {
                ...state,
                all_columns: new_all_columns,
                dropDownList: {}
            }
        }
        case "SET_TABLE_DATA": {
            let { items, mappingType, pageDataReducer } = action.payload;
            let newTableData = []
            let new_all_columns = [...state.all_columns]
            items.forEach((item) => {
                let tableRow = FillColumnValue(new_all_columns, item, mappingType, pageDataReducer)
                newTableData.push(tableRow)
            })
            return {
                ...state,
                tableData: newTableData
            }
        }
        case "DISPLAY_HIDDEN_COLUMNS": {
            let { hiddenColumns } = action.payload
            let { all_columns } = state
            let new_columns = [...all_columns]
            new_columns[0] = { ...all_columns[0] }
            new_columns[0].fields = [...new_columns[0].fields]
            hiddenColumns.forEach((key) => {
                let index = indexOfObject([...new_columns[0].fields], "key", key)
                if (index !== -1){
                    new_columns[0].fields[index].display = true
                }
            })
            let new_width = 100/(new_columns[0].fields.filter((field) => field.display)?.length || 1)
            new_columns[0].fields.filter((field, index) => field.display).map((active_field)=> {
                active_field.labelStyle = {...active_field.labelStyle, width: `${new_width}%`}
            })
            return {
                ...state,
                all_columns: new_columns
            }
        }
        case "REMOVE_HIDDEN_COLUMN": {
            let { field_key } = action.payload
            let { all_columns } = state
            let new_columns = [...all_columns]
            new_columns[0] = { ...all_columns[0] }
            new_columns[0].fields = [...new_columns[0].fields]
            let index = indexOfObject([...new_columns[0].fields], "key", field_key)
            if (index !== -1)
                new_columns[0].fields[index].display = false
            let new_width = 100/(new_columns[0].fields.filter((field) => field.display)?.length || 1)
            new_columns[0].fields.filter((field) => field.display).map((active_field)=> {
                active_field.labelStyle = {...active_field.labelStyle, width: `${new_width}%`}
            })
            return {
                ...state,
                all_columns: new_columns
            }
        }
        default: return state;
    }
}
export const ALL_TABLE_ACTIONS = {
    SET_STATE: "SET_STATE",
    SWAP_COLUMN: "SWAP_COLUMN",
    SET_TABLE_DATA: "SET_TABLE_DATA",
    DISPLAY_HIDDEN_COLUMNS: "DISPLAY_HIDDEN_COLUMNS",
    REMOVE_HIDDEN_COLUMN: "REMOVE_HIDDEN_COLUMN"
}
export const findLastDisplayTrueIndex = (fields) => {
    return fields.reduceRight((acc, field, index) => {
      if (acc === -1 && field.display) {
        return index;
      }
      return acc;
    }, -1);
  };
export default TableReducer