import { useSelector } from "react-redux";
import { useMemo } from "react";

const AllFields = () => {
    const current_team = useSelector((state) => state.current_team.team);

    const fields = useMemo(() => {
        const baseFields = [
            {
                "id": "id",
                "field_name": "Unique ID",
            },
            {
                "id": "name",
                "field_name": "Name",
            },
            {
                "id": "company",
                "field_name": "Company"
            },
            {
                "id": "full_name",
                "field_name": "Full Name",
            },
            {
                "id": "spouse_name",
                "field_name": "Spouse Name",
            },
            {
                "id": "title",
                "field_name": "Title"
            },
            {
                "id": "alias_names",
                "field_name": "Alias Names"
            },
            {
                "id": "college",
                "field_name": "College",
            },
            {
                "id": "course_name",
                "field_name": "Course",
            },
            {
                "id": "branch_name",
                "field_name": "Branch",
            },
            {
                "id": "graduation_year",
                "field_name": "Graduation Year",
            },
            {
                "id": "past_educations",
                "field_name": "Past Education"
            },
            {
                "id": "city",
                "field_name": "City",
            },
            {
                "id": "state",
                "field_name": "State",
            },
            {
                "id": "country",
                "field_name": "Country",
            },
            {
                "id": "location",
                "field_name": "Location",
            },
            {
                "id": "linkedin_url",
                "field_name": "LinkedIn URL",
            },
            {
                "id": "assigns",
                "field_name": "Assigned Team Member"
            },
            {
                "id": "tags",
                "field_name": "Tags"
            },
            {
                "id": "custom_companies",
                "field_name": "Other Companies"
            }
        ];

        if (current_team.save_to_crm_enabled) {
            baseFields.splice(1, 0, {
                "id": "crm_primary_id",
                "field_name": "CRM ID",
            });
        }

        return baseFields;
    }, [current_team.save_to_crm_enabled]);

    return fields;
}

export default AllFields;
