import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import Cookies from 'universal-cookie'
import { apiAgent } from '../../../axiosConfig'
import { COOKIE_SETTINGS } from '../../../config/cookiesSettings'
import { updateCurrentTeam } from '../../../redux/currentTeamSlice'
import { Button } from '../../cta_utils/AnButton'
import { AnInputBox } from '../../input_utils/AnInput'

export const CreateTeam = () => {
    const [newTeamDetails, _setNewTeamDetails] = useState({position: '', institute: ''})
    const [isLoading, _setIsLoading] = useState(false)
    const [errorMessage, _setErrorMessage] = useState({onSubmit: ''})
    const _history = useHistory()
    const _dispatch = useDispatch()
    const handleInputChange = (e) => _setNewTeamDetails({...newTeamDetails, [e.target.name]: e.target.value})
    
    const handleSubmitDetails = () => {        
        let errors = validateSubmit()
        _setErrorMessage({...errors, onSubmit: ''})
        if(Object.keys(errors).length > 0) return                
        _setIsLoading(true)
        apiAgent({
            method: 'post',
            url: '/teams',
            data: {
                ...newTeamDetails
            }
        }).then(res => {
            _setIsLoading(false)
            if(res.data.error_message) _setErrorMessage(state => {return {...state, onSubmit: res.data.error_message}})
            else {
                let cookies = new Cookies()
                let team = res.data.team
                cookies.set('team_id', res.data.team.id, {path: '/', maxAge: COOKIE_SETTINGS['AUTH_DURATION']})
                _dispatch(updateCurrentTeam(team))
                _history.push('/feed')
               // _history.push('/invite_users')
            }
        }).catch(err => {
            _setErrorMessage(state => {
                return {...state, onSubmit: err.message}
            })
            _setIsLoading(false)
        })
    }

    const validateSubmit = () => {
        let errors = {}
        if(!newTeamDetails.position) errors.position = 'Please enter Title/Position'
        if(!newTeamDetails.institute) errors.institute = 'Please enter Institute name'
        return errors
    }
    
    return (
        <div className="create_team_container">
            <div className="text_title" style={{textAlign: 'center'}}>Add your institution details</div>
            <div style={{marginTop: 24}}>                
                <AnInputBox label="Your Title/Position" error={errorMessage.position}>
                    <input type="text" name="position" value={newTeamDetails.position} onChange={handleInputChange}/>
                </AnInputBox>
                <div style={{marginTop: 8}}>
                    <AnInputBox label="Institution" error={errorMessage.institute}>
                        <input type="text" name="institute" value={newTeamDetails.institute} onChange={handleInputChange} />
                    </AnInputBox>
                </div>
            </div>
            <Button buttonSize="Big" buttonStyle={{width: '100%', marginTop: 24}}
                onClick={handleSubmitDetails} isLoading={isLoading} error={errorMessage.onSubmit}>
                    Create a new account
            </Button>     
        </div>
    )
}