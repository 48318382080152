import React, { useCallback, useEffect, useReducer } from 'react'
import AnPopup from '../../cta_utils/AnPopup'
import reducer from '../../utils/CommonReducer'
import { useSelector } from 'react-redux'
import { AnInputRadioButton } from '../../input_utils/AnInputRadioButton'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { NameInitial } from '../../cta_utils/AnNameInitial'
import Tooltip from '../../cta_utils/Tooltip'
import { AnInputBox } from '../../input_utils/AnInput'
import { Button } from '../../cta_utils/AnButton'
import { map_and_post_job } from './api'
import { AnInputCheckBox } from '../../input_utils/AnInputCheckBox'
import ConditionalRender from '../../../ConditionalRender'
import AnMultipleSuggestionInputBox from '../../input_utils/AnMultipleSuggestionBox'
import { SuggestionTags } from './_SuggestionTags'


const MapAndPostJobPopup = ({linkedinProspect, selectedJobUpdate={}, onFinishCallback, ...props}) => {
    const current_user = useSelector(state => state.current_user.user);
    const [componentData, setComponentData] = useReducer(reducer, {
        isLoading: {},
        selectedSuggestion: "",        
        manualProspect: [],
        manualProspectEnabled: false,
        createNewProspect: false,
        errors: {}
    })
    const {selectedSuggestion, manualProspect, manualProspectEnabled, createNewProspect} = componentData    
    const {prospect_suggestions: prospectSuggestions} = selectedJobUpdate

    const setComponentState = useCallback((payload) => {
        setComponentData({type: "SET_STATE", payload})
    }, [])
    
    useEffect(() => {        
        if(manualProspectEnabled) setComponentState({selectedSuggestion: "", createNewProspect: false})
    },[manualProspectEnabled, setComponentState])
    
    useEffect(() => {
        if(selectedSuggestion) setComponentState({manualProspect: [], createNewProspect: false, manualProspectEnabled: false})
    },[selectedSuggestion, setComponentState])

    useEffect(() => {        
        if(createNewProspect) setComponentState({selectedSuggestion: "", manualProspect: [], manualProspectEnabled: false})
    },[createNewProspect, setComponentState])
    
    const mapAndPostSubmit = () => {
        let errors = {}
        if(!selectedSuggestion && !(manualProspect?.length > 0) && !createNewProspect){
            errors.postingJob = "Please select a suggestion or enter a prospect id or create new prospect"
        }

        if(Object.keys(errors).length > 0){
            setComponentState({errors: errors});
            return
        }
        setComponentState({isLoading: {postingJob: true}, errors: errors})
        map_and_post_job({
            post_id: selectedJobUpdate.id, 
            prospect_id: selectedSuggestion || manualProspect[0]?.id,
            create_new_prospect: createNewProspect
        }).then(res => {
            if(res.data.success){
                setComponentState({isLoading: {}})           
                onFinishCallback(selectedJobUpdate.id)
            }else{
                setComponentState({
                    isLoading: {},
                    errors: {postingJob: "Something went wrong!"}
                })
            }
        }).catch(err => setComponentState({
            isLoading: {},
            errors: {postingJob: "Something went wrong!"}
        }))
    }

    return (
        <AnPopup handleOnClosePopup={props.handleOnClose} maxWidth={580}>
            <div className="text_title">Map and Save</div>
            <div className="line_seperator_ex_light_grey" style={{margin: "8px 0px 22px"}}></div>
            {prospectSuggestions.length > 0 ? prospectSuggestions.map((prospect, index) => {                
                return(
                <>
                    <div key={prospect.id} className='flex_container_space_between' style={{marginTop: index>0 ? "42px":"0px"}}>
                        <div className='default_flex_container'>
                            <AnInputRadioButton label=""
                                isChosen={selectedSuggestion === prospect.id}                                   
                            >
                                <input type="radio"
                                    checked={selectedSuggestion === prospect.id}
                                    onChange={(e) => setComponentState({selectedSuggestion: prospect.id})}
                                />
                            </AnInputRadioButton>
                            <div style={{marginLeft: "20px"}}>                            
                                <Link to={{ pathname: `/prospects/${prospect.id}` }}
                                    className="default_flex_container"
                                    style={{alignItems: 'flex-start',textDecoration: "none"}}
                                >
                                    <NameInitial
                                        name={prospect.name}
                                        profile_image={prospect.profile_image}
                                    />
                                    <div style={{marginLeft: 8, maxWidth: 246}}>
                                        <div className='default_flex_container' style={{  position: "relative", color: '#B6B9BB'}}>
                                            <div className='text_subtitle_big'>{prospect.name}</div>
                                            <Tooltip
                                                tooltipText={() => prospect.tags?.map((tag, i) => <span key={tag} style={{textTransform: 'uppercase'}}>{tag.name}{((i + 1) !== prospect.tags?.length) ? (i + 1) % 3 === 0 ? <br /> : ", " : ""}</span>)}
                                                innerClass='text_body_light'
                                                textTheme='background-light-black bottom'
                                                innerStyle={{ padding: '8px', alignSelf: 'center', color: 'white', borderRadius: '8px'}}
                                                style={{marginTop: 6}}
                                            >
                                                <div className="truncate" style={{marginLeft: 16, fontSize: 10 }}>
                                                    {prospect.tags?.map((tag) => <span key={tag.id} className="text_caption_small_light" style={{textTransform: 'uppercase', marginRight: 8 }}>{tag.name}</span>)}
                                                </div>
                                            </Tooltip>
                                        </div>
                                        <Tooltip
                                                tooltipText={() => prospect.designation}
                                                innerClass='text_body_light'
                                                textTheme='background-light-black bottom'
                                                innerStyle={{ padding: '8px', alignSelf: 'center', color: 'white', borderRadius: '8px'}}
                                                style={{marginTop: 6}}
                                            >
                                        <div className="text_body_light truncate">{prospect.designation}</div>
                                        </Tooltip>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <ConditionalRender if={prospect.similarity}>
                            <div className='text_body_light' style={{alignSelf: "flex-start"}}>Confidence: <span className='text_body'> {prospect.similarity}%</span></div>
                        </ConditionalRender>
                    </div>
                    <div style={{margin: "12px 0px 0px 36px"}}></div>
                    <SuggestionTags tags={prospect.suggestion_tags}/>
                </>)
            }) : 
            <div className='text_subtitle_light' style={{margin: '72px auto', fontWeight: 600, textAlign: 'center'}}>
                No suggestions found
            </div>
            }
            <div className='default_flex_container' style={{margin: "24px 0px"}}>
                <div className="line_seperator_ex_light_grey" style={{width: "100%"}}></div>
                <div className='text_caption'>Or</div>
                <div className="line_seperator_ex_light_grey" style={{width: "100%"}}></div>                        
            </div>
            <div>
                <AnInputCheckBox label="Search Prospects Manually" isChecked={manualProspectEnabled}>
                    <input type="checkbox" name="manualProspectEnabled" checked={manualProspectEnabled} onChange={() => setComponentState({manualProspectEnabled: !manualProspectEnabled})}></input>
                </AnInputCheckBox>
                <ConditionalRender if={manualProspectEnabled}>
                    <div style={{marginTop: 8, marginLeft: 22}}>
                        <AnMultipleSuggestionInputBox
                            inputClass="an_search_box"
                            placeholder="Search for prospects"
                            suggestionUrl="/prospects/search?team_search=true"
                            selectedOptions={manualProspect}
                            callback={(val) => setComponentState({manualProspect: val})}
                            style={{ margin: "16px 0px 0px" }}
                            theme="modern_selected"
                        ></AnMultipleSuggestionInputBox>
                    </div>
                </ConditionalRender>
            </div>
            <div className='default_flex_container' style={{margin: "24px 0px"}}>
                <div className="line_seperator_ex_light_grey" style={{width: "100%"}}></div>
                <div className='text_caption'>Or</div>
                <div className="line_seperator_ex_light_grey" style={{width: "100%"}}></div>                        
            </div>
            <div>
                <AnInputCheckBox label="Create New Prospect" isChecked={createNewProspect}>
                    <input type="checkbox" name="createNewProspect" checked={createNewProspect} onChange={() => setComponentState({createNewProspect: !createNewProspect})}></input>
                </AnInputCheckBox>
            </div>
            <Button buttonStyle={{marginTop: "32px"}}innerButtonStye={{padding: "8px 12px"}}
                onClick={mapAndPostSubmit}
                isLoading={componentData.isLoading.postingJob}
                error={componentData.errors?.postingJob}
            >Save Post</Button>
        </AnPopup>
        
    )
}

export default MapAndPostJobPopup