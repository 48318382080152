import React, { useRef, useState } from "react";
import ListPoints from "./assets/ListPoints.svg";
import ListPointsTicked from "./assets/ListPointsTicked.svg";
import ListPointsPlus from "./assets/ListPointsPlus.svg";
import DownLoadIcon from "../../../assets/Download.svg";
import ProspectImportSuccess from "./assets/prospect_import_success.png"
import { Button } from "../../cta_utils/AnButton";
import Uploader from "../../upload/uploader";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { apiAgent } from "../../../axiosConfig";
import { Link } from "react-router-dom";
import { HelpCenterLinks } from "../support_views/help_center/HelpCenterPage";
import {FlashMessages} from "../../cta_utils/FlashMessages";
import AnPopup from "../../cta_utils/AnPopup";

const AddProspectStep1 = (props) => {
    const inputFieldRef = useRef(null);
    const inputFieldHelpRef = useRef(null);
    const [importFile, setImportFile] = useState({
        files: [],
        names: [],
        uploaded: false,
        uploadedUrl: null,
    });
    const [importHelpFile, setImportHelpFile] = useState({
        files: [],
        names: [],
        uploaded: false,
        uploadedUrl: null,
    });
    const [errors, setErrors] = useState({});
    const [errorMsg, setErrorMsg] = useState("");
    const [fileUploading, setFileUploading] = useState(false);
    const [helpFileUploading, setHelpFileUploading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isHelpLoading, setIsHelpLoading] = useState(false);
    const [isShareLoading, setIsShareLoading] = useState(false);
    const [popupsList, setPopupsList] = useState({});
    const current_team = useSelector((state) => state.current_team.team);
    const current_user = useSelector(state => state.current_user.user)
    const history = useHistory();

    const handleFileUploadButton = () => {
        inputFieldRef.current?.click();
    };

    const handleHelpFileUploadButton = () => {
        inputFieldHelpRef.current?.click();
    };

    const onUploadBeginCallback = () => {
        if(importFile.files.length > 0 ) setFileUploading(true);
        if(importHelpFile.files.length > 0 ) setHelpFileUploading(true);
        setErrors({ import_file: null });
    };
    const onUploadFinishCallback = () => {
        setFileUploading(false);
        setHelpFileUploading(false);
        setErrors({ import_file: null });
    };
    const onUploadErrorCallback = () => {
        setFileUploading(false);
        setHelpFileUploading(false);
        setImportFile({
            files: [],
            names: [],
            uploaded: false,
            uploadedUrl: null,
        });
        setErrors({
            import_file:
                "Oops! Something went wrong while uploading the file. Please try again",
        });
    };
    const handleTransitionToStep2 = () => {
        let errors = {};
        if (importFile.files.length == 0 || !importFile.uploadedUrl) {
            errors.import_file = "Please Upload a .csv file";
        }
        setErrors(errors);
        if (Object.keys(errors).length > 0) return;

        setIsLoading(true);
        apiAgent({
            method: "post",
            url: "/prospect_import/create",
            data: {
                prospect_import: {
                    // name: `import_${new Date().toISOString().slice(0,19)}`,
                    name: importFile.names[0],
                    original_file: importFile.uploadedUrl,
                    team_id: current_team.id,
                },
            },
        })
            .then((res) => {
                setIsLoading(false);
                props.setStep2Data(res.data.data);
                props.setActiveStep("step_2");
            }, (err) => {
                setIsLoading(false);
                if(err.response.data)
                    setErrorMsg(err.response.data.error);
            })
    };

    const shareImportFIle = () => {
        let errors = {};
        if (importHelpFile.files.length == 0 || !importHelpFile.uploadedUrl) {
            errors.import_file = "Please Upload a .csv file";
        }
        setErrors(errors);
        if (Object.keys(errors).length > 0) return;

        setIsShareLoading(true);
        apiAgent({
            method: "post",
            url: "/share_import_data",
            data: {
                name: current_user.name,
                email: current_user.email,
                institution: current_team.name,
                type: "Share import data",
                prospect_import: {
                    name: importHelpFile.names[0],
                    original_file: importHelpFile.uploadedUrl,
                },
            },
        })
        .then((res) => {
            setIsShareLoading(false);
            setPopupsList({});
            setPopupsList({shareImportSuccess: true});
            setImportHelpFile({files: [],names: [],
                uploaded: false,
                uploadedUrl: null })
        }, (err) => {
            setIsShareLoading(false);
            if(err.response.data)
                setErrorMsg(err.response.data.error);
        })
    }

    const importProspectHelpPopup = () => {
        return (
            <AnPopup name="importProspectHelpPopup" handleOnClosePopup={() => setPopupsList({})} maxWidth={480}>
                <div className="text_title">Share prospect data</div>
                <div className="text_caption" style={{fontSize: 14, lineHeight: "20px"}}>Upload your prospect data here, and we'll add it to the team</div>
                <div
                    className="file_upload_container"
                    style={{ marginTop: 32, padding: "44px 0px 28px" }}
                >
                    {importHelpFile.uploaded ? (
                        <div
                            className="text_body_light"
                            style={{ marginBottom: 10, width: 250, overflowWrap: "break-word"}}
                        >
                            {importHelpFile.names[0]} File Uploaded
                        </div>
                    ) : null}
                    <div style={{ zIndex: 99 }}>
                        <Button
                            buttonSize="Medium"
                            onClick={handleHelpFileUploadButton}
                            isLoading={helpFileUploading}
                            innerButtonStye={{
                                padding: "8px 25px",
                                textTransform: "none",
                            }}
                        >
                            Upload file
                        </Button>
                    </div>

                    <Uploader
                        selectedFile={importHelpFile}
                        setSelectedFile={setImportHelpFile}
                        isRefedTo={inputFieldHelpRef}
                        onUploadBeginCallback={onUploadBeginCallback}
                        onUploadFinishCallback={onUploadFinishCallback}
                        onUploadErrorCallback={onUploadErrorCallback}
                    />

                    <div className="text_body_light" style={{ marginTop: 8 }}>
                        or drag a file here.
                    </div>
                    <div
                        className="text_caption_light"
                        style={{ marginTop: 8 }}
                    >
                        Supported formats: Excel (.csv)
                    </div>
                    {errors.import_file && (
                        <div className="text_caption_error">
                            {errors.import_file}
                        </div>
                    )}
                </div>
                <Button
                    buttonSize="Medium"
                    isLoading={isShareLoading}
                    onClick={shareImportFIle}
                    buttonStyle={{ marginLeft: 16, paddingTop: "16px", display: "flex", justifyContent: "end"}}
                    innerButtonStye={{ padding: "8px 49px" }}
                >
                    Share
                </Button>
            </AnPopup>
        )
    }

    const shareImportSuccess = () => {
        return (
            <AnPopup name="importProspectHelpPopup" handleOnClosePopup={() => setPopupsList({})} maxWidth={488}>
                <div style={{textAlign: "center"}}>
                    <img src={ProspectImportSuccess}></img>
                    <div className="text_title">Your data has been received</div>
                    <div className="text_caption" style={{fontSize: 14, lineHeight: "20px"}}>Please allow us some time to import your data and get back to you.</div>
                </div>
            </AnPopup>
        )
    }

    return (
        <>
            <div style={{ padding: 24 }}>
                <div className="text_title">Tips to upload your data</div>
                <div style={{ marginTop: 24 }}>
                    <div style={{ display: "flex", alignItems: "flex-start" }}>
                        <img src={ListPoints} style={{ width: 32 }}></img>
                        <div style={{ marginLeft: 12, width: "100%" }}>
                            <div className="text_subtitle_big">
                                Learn AlmaNews’ data structure
                            </div>
                            <div
                                className="text_body_light"
                                style={{ marginTop: 4 }}
                            >
                                Download and use our sample file to see exactly
                                how we organize data in AlmaNews.
                            </div>
                            <div style={{ marginTop: 16, display: "flex" }}>
                                <a
                                    href="https://karmabox-temp.s3.amazonaws.com/prod/SampleDBforImport-Individuals.csv"
                                    style={{
                                        border: "1px solid #E7ECEB",
                                        borderRadius: 16,
                                        height: 32,
                                        padding: "0px 16px",
                                        width: "max-content",
                                        cursor: "pointer",
                                        textDecoration: "none",
                                    }}
                                >
                                    <img
                                        src={DownLoadIcon}
                                        style={{
                                            display: "inline",
                                            verticalAlign: "middle",
                                            width: 20,
                                            height: "100%",
                                        }}
                                    ></img>
                                    <div
                                        style={{
                                            display: "inline",
                                            verticalAlign: "middle",
                                            marginLeft: 8,
                                            height: "100%",
                                        }}
                                        className="text_link"
                                    >
                                        Download .csv sample file for
                                        individuals
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        marginTop: 24,
                        display: "flex",
                        alignItems: "flex-start",
                    }}
                >
                    <img src={ListPointsTicked} style={{ width: 32 }}></img>
                    <div style={{ marginLeft: 12, width: "100%" }}>
                        <div className="text_subtitle_big">
                            Fill in mandatory fields
                        </div>
                        <div
                            className="text_body_light"
                            style={{ marginTop: 4 }}
                        >
                            Certain fields need to be entered in order for your
                            import to be successful. Make sure they are complete
                            before you start importing.
                        </div>
                        <Link
                            className="text_link"
                            to={HelpCenterLinks["Data Fields"] + "&scrollTo=default_fields"}
                            hash="#default_fields"
                            target="_blank"
                        >
                            Learn about default fields
                        </Link>
                    </div>
                </div>

                <div
                    style={{
                        marginTop: 24,
                        display: "flex",
                        alignItems: "flex-start",
                    }}
                >
                    <img src={ListPointsPlus} style={{ width: 32 }}></img>
                    <div style={{ marginLeft: 12, width: "100%" }}>
                        <div className="text_subtitle_big">
                            Create custom fields
                        </div>
                        <div
                            className="text_body_light"
                            style={{ marginTop: 4 }}
                        >
                            Some data fields may not be present by default. Make
                            sure you create these custom fields before importing
                            your data.
                        </div>
                        <div className="default_flex_container">
                            <Link
                                className="text_link"
                                to="/settings/data_fields"
                                target="_blank"
                            >
                                Add new custom fields
                            </Link>
                            <Link
                                className="text_link"
                                to={HelpCenterLinks["Data Fields"] + "&scrollTo=custom_fields"}
                                style={{ marginLeft: 16 }}
                                target="_blank"
                            >
                                Learn about custom fields
                            </Link>
                        </div>
                    </div>
                </div>
                <div
                    className="file_upload_container"
                    style={{ marginTop: 32, padding: "44px 0px 28px" }}
                >
                    {importFile.uploaded ? (
                        <div
                            className="text_body_light"
                            style={{ marginBottom: 10 }}
                        >
                            {importFile.names[0]} File Uploaded
                        </div>
                    ) : null}
                    <div style={{ zIndex: 99 }}>
                        <Button
                            buttonSize="Medium"
                            onClick={handleFileUploadButton}
                            isLoading={fileUploading}
                            innerButtonStye={{
                                padding: "8px 25px",
                                textTransform: "none",
                            }}
                        >
                            Upload file
                        </Button>
                    </div>

                    <Uploader
                        selectedFile={importFile}
                        setSelectedFile={setImportFile}
                        isRefedTo={inputFieldRef}
                        onUploadBeginCallback={onUploadBeginCallback}
                        onUploadFinishCallback={onUploadFinishCallback}
                        onUploadErrorCallback={onUploadErrorCallback}
                    />

                    <div className="text_body_light" style={{ marginTop: 8 }}>
                        or drag a file here.
                    </div>
                    <div
                        className="text_caption_light"
                        style={{ marginTop: 8 }}
                    >
                        Supported formats: Excel (.csv)
                    </div>
                    {errors.import_file && (
                        <div className="text_caption_error">
                            {errors.import_file}
                        </div>
                    )}
                </div>
                <div
                    className="default_flex_container"
                    style={{ marginTop: 32, justifyContent: "flex-end" }}
                >
                    <Button
                        buttonSize="Medium"
                        type="Secondary"
                        onClick={() => {
                            history.push("/imports");
                        }}
                        buttonStyle={{ zIndex: 99 }}
                        innerButtonStye={{ padding: "8px 14px" }}
                    >
                        Cancel Import
                    </Button>
                    <Button
                        buttonSize="Medium"
                        isLoading={isLoading}
                        onClick={handleTransitionToStep2}
                        buttonStyle={{ marginLeft: 16 }}
                        innerButtonStye={{ padding: "8px 34px" }}
                    >
                        Next
                    </Button>
                </div>
                <div className="text_subtitle_big" style={{marginTop: "26px",color: "#3A3F42", lineHeight: "20px",  padding: "16px", backgroundColor: "#437EFE1A"}}>
                    Do you want us to import your prospect data instead? <span className="text_link" style={{textDecoration: "underline", fontSize: 16, fontWeight: 600}} onClick={() => {setPopupsList({importProspectHelpPopup: true})}}>Click here to share your data with us.</span>
                </div>
            </div>
            <div
                style={{
                    padding: 24,
                    borderTop: "1px solid #E7ECEB",
                }}
            >
                <div>
                    <div className="text_subtitle_big">
                        Updating prospect data
                    </div>
                    <div
                        className="text_subtitle_semi_bold"
                        style={{ marginTop: 4 }}
                    >
                        You can update the prospect data, assign team members or
                        add tags later by re-importing the data along with a
                        Unique Identification Number (UIN). You can download the
                        data from the prospects page to get the UINs
                        corresponding to already imported prospects.
                    </div>
                    <Link
                        to={HelpCenterLinks["Updating prospect data"]}
                        className="text_link"
                        style={{ marginTop: 8 }}
                        target="_blank"
                    >
                        Learn more about updating prospect data
                    </Link>
                </div>
            </div>
            {errorMsg}
            {
                errorMsg && (<FlashMessages
                    messageTheme="warning"
                    closeCallback={() => {
                        setErrorMsg("");
                    }}
                >
                    {errorMsg}
                </FlashMessages>)
            }
            {popupsList.importProspectHelpPopup && importProspectHelpPopup()}
            {popupsList.shareImportSuccess && shareImportSuccess()}
        </>
    );
};

export default AddProspectStep1;
