import GreyDownArrow from '../../../../../assets/GreyArrowDown.svg' 
import AnDropDown from '../../../../cta_utils/AnDropDown'
import { ALL_MAPPER_FILTER_ACTIONS } from '../FiltersStateReducer'
import { ORDERS } from '../MapperSuggestionUtils'
import ConditionalRender from '../../../../../ConditionalRender'
import { useMapperFilterContext } from '../../../new_temp_mapper/contexts/mapper_filter_context'
import { useState } from 'react'
import { setState } from '../../../../utils/CommonReducer'
import { ResetFilterBtn } from './ResetFilterBtn'
const OrderFilter = ({
    ...props
}) => {
    const {filtersState, filtersStateReducer} = useMapperFilterContext();
    const [dropDownList, setDropDownList] = useState({})
    const toggleDropDown = () => setDropDownList({})
    const renderOrderDropDown = () => {
        return (
            <AnDropDown
                style={{
                    position: "absolute",
                    background: "white",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                    borderRadius: 4,
                    width: "100px",
                    zIndex: 99,
                }}
                toggleDropDown={toggleDropDown}
            >
                <ConditionalRender if={filtersState.order}>
                <ResetFilterBtn resetCallBack={() => {setState(filtersStateReducer)({ order: '', dropDownList: {} });toggleDropDown();}}/>
                <div className="line_seperator_ex_light_grey"></div>
                </ConditionalRender>
                <div className='text_body_light' style={{padding: '12px 16px 8px', paddingTop: filtersState.order ? '12px' : '16px'}}>Order</div>
                {
                    ORDERS.map((item) => (
                    <div className='filter_item_container'>
                        <div
                            key={item}
                            className={ filtersState.order === item ? "filter_item text_body select_filter" : "filter_item text_body_light" }
                            onClick={() => {setState(filtersStateReducer)({order: item});toggleDropDown()}}
                        >
                            {item}
                        </div>
                    </div>
                    ))
                }
        </AnDropDown>
        )
    }
    return (
        <div>
            <div style={{ width: 'max-content' }}>
                <div className="default_flex_container filter_btn" style={{ padding: 10, borderRadius: 6 }}
                    onClick={() => setDropDownList({ orderDropDownList: true })}>
                    <div className='text_body_big_light_semi_bold'>Order</div>
                    {
                        filtersState.order > 0 ? (
                            <div className='applied_filters_box default_flex_container' style={{ justifyContent: 'center', marginLeft: 4 }}>
                                <span >{filtersState.order}</span>
                            </div>
                        ) : (
                            <img src={GreyDownArrow} />
                        )
                    }
                </div>
            </div>
            {dropDownList.orderDropDownList && renderOrderDropDown()}
        </div>
    )
}

export default OrderFilter