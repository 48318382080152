import { indexOfObject } from "../../../utils/ArrayUtils"

export const initialRangeFilter = {cond: 'Above', min: '', max: '', label: ""}
export const initialMapperFilterState = { 
    order: '',
    range_filter: initialRangeFilter,
    range: {min: "", max: ""},
    selectedTags: [],
    tagsDropdown: false,
    isLoading: {filterSubmission: false},
    list_name: '',
    mappedFilter:  {label: "All profiles", key: "all"},
    dropDownList: {suggestionDropdown: false},
    popupsList: {},
    hiddenColumns: [],
    sortings: {},
    search: {name: ""},
    suggestionTags: []
}
const minRange = 0
const maxRange = 100
const MapperFiltersReducer = (state, action) => {
    switch(action.type){
        case "SET_STATE": {
            return {
                ...state,
                ...action.payload
            }
        }
        case "SELECT_TAGS": {
            let {selections} = action.payload;
            let {suggestionTags} = state
            let newSuggestionTags = [...suggestionTags]
            selections.forEach((tag_key) => {
                let index = indexOfObject(newSuggestionTags, "key", tag_key)
                let new_tag = {...newSuggestionTags[index]}
                if(new_tag.is_selected)
                    new_tag.is_selected = false
                else
                    new_tag.is_selected = true
                newSuggestionTags[index] = new_tag
            })
            return {
                ...state,
                suggestionTags: newSuggestionTags
            };
        }
        case "APPLY_RANGE_FILTER": {
            let {range_filter} = state
            let {new_value} = action.payload
            let new_range_filter = {...range_filter}
            switch (new_range_filter.cond) {
                case "Above": {
                    new_range_filter.min = new_value
                    new_range_filter.max = maxRange
                    break;
                }
                case "Below": {
                    new_range_filter.min = minRange
                    new_range_filter.max = new_value
                    break;
                }
                case "Between": {
                    new_range_filter.min = new_value
                    new_range_filter.max = new_range_filter.max
                    break;
                }
                case "Equal": {
                    new_range_filter.min = new_value
                    new_range_filter.max = new_value
                    break;
                }
            }
            return {
                ...state,
                range_filter: new_range_filter
            }
        }
        case "SELECT_HIDDEN_COLUMN": {
            let {selections} = action.payload;
            let {hiddenColumns} = state
            let newHiddenColumns = [...hiddenColumns]
            selections.forEach((tag_key) => {
                let index = newHiddenColumns.indexOf(tag_key)
                if(index !== -1)
                    newHiddenColumns.splice(index, 1)
                else
                newHiddenColumns.push(tag_key)
            })
            return {
                ...state,
                hiddenColumns: newHiddenColumns
            };
        }
        case "CLEAR_TAGS": {
            let {suggestionTags} = state
            let newSuggestionTags = [...suggestionTags]
            newSuggestionTags.forEach((tag) => tag.is_selected = false)
            return {
                ...state,
                suggestionTags: newSuggestionTags
            };
        }
        case "RESET_ALL": {
            let new_state = {...initialMapperFilterState}
            let {suggestionTags} = state
            let newSuggestionTags = [...suggestionTags]
            newSuggestionTags.forEach((tag) => tag.is_selected = false)
            return {
                ...new_state,
                suggestionTags: newSuggestionTags
            };
        }
        default: return state;
    }
}

export const ALL_MAPPER_FILTER_ACTIONS = {
    SET_STATE: "SET_STATE",
    SELECT_TAGS: "SELECT_TAGS",
    APPLY_RANGE_FILTER: "APPLY_RANGE_FILTER",
    SELECT_HIDDEN_COLUMN: "SELECT_HIDDEN_COLUMN",
    CLEAR_TAGS: "CLEAR_TAGS",
    RESET_ALL: "RESET_ALL"
}

export default MapperFiltersReducer