import Facebook from "../../assets/share_on_links/facebook.png"
import LinkedIN from "../../assets/share_on_links/linkedin.png"
import Twitter from "../../assets/share_on_links/twitter.png"
const ShareOn = ({
    ...props
}) => {
    return (
        <div className='default_flex_container'>
            <img src={props.icon} style={{width: 24, height: 24}}></img>
            <button className={props.class || 'text_body'}
                style={{
                    background: 'none',
                    border: 'none',
                    marginLeft: 12,
                    cursor: 'pointer'}}
                 onClick={() => {window.open(props.url, props.label, "width=660,height=400,scrollbars=no;resizable=no")}}
            >
                {props.text}
            </button>
        </div>
    )
}
const getElements = (params) => {
    const FacebookUrl = "https://www.facebook.com/sharer/sharer.php?" + SocialGetUrl(params["Facebook"]);
    const LinkedinUrl = "https://www.linkedin.com/shareArticle?mini=true&" + SocialGetUrl(params["LinkedIn"]);
    const TwitterUrl = "https://twitter.com/intent/tweet?mini=true&" + SocialGetUrl(params["Twitter"]);
    const SUB_SECTIONS = {
        "Facebook": {
            url: FacebookUrl,  //if this doesn't work, use OG like this <meta property="og:title" content="your-title" />
            source: Facebook         
        },
        "LinkedIn": {
            url: LinkedinUrl,
            source: LinkedIN 
        },
        "Twitter": {
            url: TwitterUrl,
            source: Twitter 
        },
    };
    return SUB_SECTIONS;
}
const SocialGetUrl = (data) => {
    return Object.keys(data).map(function(key) {
        return [key, data[key]].map(encodeURIComponent).join("=");
    }).join("&");
}

//Icon,Text,Link
export {ShareOn, SocialGetUrl, getElements};
