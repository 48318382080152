import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { apiAgent } from '../../../axiosConfig.js'
import ConditionalRender from '../../../ConditionalRender.js'
import AnPopup from '../../cta_utils/AnPopup.js'
import {AnInputBox} from '../../input_utils/AnInput.js'
import { Button } from '../../cta_utils/AnButton.js'
import {NameInitial} from "../../cta_utils/AnNameInitial.js";
import MenuDots from "../../../assets/MenuDots.svg";
import GoBack from '../../cta_utils/GoBack.jsx'

const SimilarArticles = (props) => {
    const [article, setArticle] = useState({})
    const [isFetching, _setIsFetching] = useState(false)
    let current_user = useSelector(state => state.current_user.user)
    let current_team = useSelector(state => state.current_team.team)

    useEffect(() => {
        _setIsFetching(true)
        apiAgent({
            method: 'get',
            url: `/aggregates/${props.match.params.article_id}/similar_articles`,
            params: {}
        }).then(res => {
            setArticle(res.data.data);
            _setIsFetching(false)
        })
    }, [props.match.params.article_id])

    const renderProspect = () => {
        if(article.news_query) {
            return (
                <React.Fragment>
                    <div style={{float: 'left', display: 'flex'}}>
                        <NameInitial name={article.news_query.person_name}/>
                        <div style={{marginLeft: '16px'}}>
                            <div className="text_subtitle_big">{article.news_query.person_name}</div>
                            <div className="text_body_light">{article.news_query.company_name}</div>
                        </div>
                    </div>
                    <div style={{clear: 'both'}}></div>
                </React.Fragment>
            );
        }
        else
            return (<div></div>);
    }

    return (
        <div className="similar_articles_container">
            <div className="text_title">Similar articles featuring</div>
            <div className='flex_container_space_between'>
                <div style={{marginTop: '16px'}}>
                    {!isFetching && renderProspect()}
                </div>
                <GoBack className="default_flex_container text_link hover_under_line" arrow_color={"green"} text="Back to newsfeed" arrow={false}/>
            </div>
            <div style={{margin: '16px 0px', borderTop: '0.5px solid #B6B9BB'}}></div>
            {!isFetching && article.similar_articles && article.similar_articles.map(article => (
                <div style={{margin: '24px 0px'}}>
                    <a href={article.url} className="news_container">
                        { article.image_url && 
                        <div style={{width: '144px', float: 'left'}}>
                            <img src={article.image_url} style={{width: '128px', height: '128px'}} className="post_image" />
                        </div>
                        }
                        <div style={{ 
                            width: article.image_url? "calc(100% - 144px)" : 'auto',  
                            float: article.image_url? "right": "left"
                            }}>
                            <div className="text_subtitle_big" style={{marginBottom: '4px'}}>{article.title}</div>
                            <div className="text_body_light">{article.description}</div>
                            <div className="text_caption" style={{marginTop: '8px'}}>{article.source} | {article.created_date}</div>
                        </div>
                        <div style={{clear: 'both'}}></div>
                    </a>
                </div>
            ))}
        </div>
    )
}

export default SimilarArticles
