import React, {useState, useEffect} from 'react'
import {apiAgent} from '../../../axiosConfig.js'
import {AnInputBox} from '../../input_utils/AnInput.js'
import {Button} from '../../cta_utils/AnButton.js'
import userIcon from '../../../assets/user.svg'
import userCredentialIcon from '../../../assets/exit.svg'
import {AnInputRadioButton} from "../../input_utils/AnInputRadioButton";
import { NOTIFICATIONS_FREQUENCIES, NOTIFICATIONS_TYPES } from '../../utils/AllNotificationOptions.js'
import { FlashMessages } from '../../cta_utils/FlashMessages.js'

const ManageNotifications = (props) => {
    const [notificationsFrequency, setNotificationsFrequency] = useState(null)
    const [notificationsType, setNotificationsType] = useState(null)
    const [loading, setLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState()

    useEffect(() => {
        props.setActiveSettingTab('notifications')
        apiAgent({
            method: 'get',
            url: '/users/get_notifications',
            params: {}
        }).then(res => {
            if(res.data.notifications_type)
                setNotificationsType(res.data.notifications_type);
            if(res.data.notifications_frequency)
                setNotificationsFrequency(res.data.notifications_frequency);
        }).catch(err => {

        })
    }, [])

    const detailsSubmit = () => {
        setLoading(true)
        apiAgent({
            method: 'post',
            url: '/users/update_notifications',
            data: {notifications_type: notificationsType,
                notifications_frequency: notificationsFrequency}
        }).then(res => {
            setLoading(false)
            setSuccessMessage("Changes saved")
        }).catch(err => {
            setLoading(false)
        })
    }

    return (
        <>
            <div className="text_title" style={{marginBottom: 16}}>Notifications Settings</div>
            <div style={{maxWidth: 640, margin: 'auto'}}>
                <div className="text_body" style={{marginTop: 16}}>Notify me about News mentions of:</div>
                {
                    NOTIFICATIONS_TYPES.map(type => (
                        <AnInputRadioButton key={type} label={type}
                                            isChosen={notificationsType === type} style={{marginTop: 8}}>
                            <input type="radio" id="create_team" name="type" value={type}
                                   checked={notificationsType === type}
                                   onChange={(e) => {
                                       setNotificationsType(e.target.value);
                                   }}/>
                        </AnInputRadioButton>
                    ))
                }
                <div style={{margin: '16px 0px', borderTop: '0.5px solid #B6B9BB'}}></div>
                <div className="text_body">Email notification frequency of news</div>
                {
                    NOTIFICATIONS_FREQUENCIES.map(frequency => (
                        <AnInputRadioButton key={frequency} label={frequency}
                                            isChosen={notificationsFrequency === frequency} style={{marginTop: 8}}>
                            <input type="radio" id="create_team" name="frequency" value={frequency}
                                   checked={notificationsFrequency === frequency}
                                   onChange={(e) => {
                                       setNotificationsFrequency(e.target.value);
                                   }}/>
                        </AnInputRadioButton>
                    ))
                }

                <Button buttonSize="LessPadding" loading={loading} onClick={detailsSubmit} buttonStyle={{marginTop: '24px', width: 'max-content'}} innerButtonStye={{padding: '8px 16px'}}>
                    Save changes
                </Button>
            </div>
            {successMessage && <FlashMessages messageTheme='success' firstIcon={true} closeCallback={setSuccessMessage}>{successMessage}</FlashMessages>}
        </>
    )
}

export default ManageNotifications
