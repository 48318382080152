import { useState } from "react";
import { useSelector } from "react-redux";
import { Switch, Link, useHistory } from "react-router-dom";
import ConditionalRender from "../../../ConditionalRender.js";
import RouteRenderer from "../../../routes/RouteRenderer.js";
import useCheckUserAccess from "../../custom_hooks/useCheckUserAccess.js";
import useWindowSize from "../../custom_hooks/useWindowSize.js";
import { isWidthUp } from "../../utils/designUtils.js";
const SettingsPage = (props) => {
    const current_user = useSelector((state) => state.current_user.user);
    const current_team = useSelector((state) => state.current_team.team);
    const [activeSettingTab, setActiveSettingTab] = useState("profile");
    const windowDimensions = useWindowSize();
    const checkUserAccess = useCheckUserAccess();
    let history = useHistory();
    
    const selectActiveSettingTab = (tab) => {
        setActiveSettingTab(tab);
        history.push(`/${tab}`);
    };

    return (
        <>
            <div className="settings_outer_container">
                <div className="text_title_big">Settings</div>
                <ConditionalRender if={isWidthUp(windowDimensions.width, "md")}>
                    <div
                        className="line_seperator_ex_light_grey"
                        style={{marginTop: 16 }}
                    ></div>
                </ConditionalRender>

                <div className="settings_inner_container">    
                    <div className="all_settings_tabs">
                        <Link
                            to={props.match.path + "/profile"}
                            className={"setting_tab" + (activeSettingTab === "profile" ? " active" : "")}
                            onClick={(e) => {
                                setActiveSettingTab("profile");
                            }}
                        >
                            Profile
                        </Link>
                        <Link
                            to={props.match.path + "/notifications"}
                            className={"setting_tab" + (activeSettingTab === "notifications" ? " active" : "")}
                        >
                            Notifications
                        </Link>
                        <Link
                            to={props.match.path + "/data_fields"}
                            className={"setting_tab" + (activeSettingTab === "data_fields" ? " active" : "")}                           
                        >
                            Data Fields
                        </Link>
                        <ConditionalRender if={checkUserAccess('team_managing')}>
                            <Link
                                to={props.match.path + "/manage_teams"}
                                className={"setting_tab" + (activeSettingTab === "manage_teams" ? " active" : "")}
                            >
                                Manage Team
                            </Link>
                            <ConditionalRender if={checkUserAccess('team_notification')}>
                                <Link
                                    to={props.match.path + "/team_notifications"}
                                    className={"setting_tab" + (activeSettingTab === "team_notifications" ? " active" : "")}
                                >
                                    Team Notifications
                                </Link>
                            </ConditionalRender>
                            <ConditionalRender if={checkUserAccess('team_setting')}>
                                <Link
                                    to={props.match.path + "/team_settings"}
                                    className={"setting_tab" + (activeSettingTab === "team_settings" ? " active" : "")}
                                >
                                    Team Settings
                                </Link>
                            </ConditionalRender>
                        </ConditionalRender>
                    </div>
                    <div
                        style={{
                            background: "white",
                            flex: "1 1 640px",
                            boxSizing: "border-box",
                            padding: 24,
                        }}
                    >
                        <Switch>
                            {props.routes.map((route, index) => {
                                let passed_props = {
                                    ...route,
                                    props: {
                                        setActiveSettingTab:
                                            setActiveSettingTab,
                                    },
                                };
                                return (
                                    <RouteRenderer
                                        {...passed_props}
                                        key={index}
                                    />
                                );
                            })}
                        </Switch>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SettingsPage;
