import { TextHighlighter } from "../utils/textHighlighter"

export const JobUpdateDesc = ({job_update, searchTerm, ...props}) => {
    const renderHighlight = (customClass, content) => {
        return (
            <TextHighlighter typography={{className: customClass}} contentString={content} toMatch={searchTerm} highlightStyle="background: rgba(0, 196, 181, 0.35); text-transform: capitalize"/>
        )
    }
    
    return (
        <div className="text_subtitle_medium default_flex_container" style={{flexWrap: 'wrap'}}>
            {renderHighlight('text_subtitle_medium preWrap', `${job_update.name?.split(" ")[0]} has started a new position as `)}
            {renderHighlight('text_subtitle', job_update.designation)}
            {renderHighlight('text_subtitle_medium preWrap', `${job_update.company_name ? " at " : " "}`)}
            {renderHighlight('text_subtitle', job_update.company_name)}
            {renderHighlight('text_subtitle_medium preWrap', `${job_update.location ? ", " : " "}`)}
            {renderHighlight('text_subtitle_semi_bold', job_update.location)}
        </div>
    )

}