import React, {useCallback, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {Button, TabbedButton} from '../../cta_utils/AnButton.js'
import InviteUsers from '../invitations/InviteUsers.js'
import ActiveTeamMembers from './ActiveTeamMembers.js'
import InvitedTeamMembers from './InvitedTeamMembers.js'
import TeamMembershipRequests from './TeamMembershipRequests.js'
import AnPopup from "../../cta_utils/AnPopup";
import {AnInputBox} from "../../input_utils/AnInput";
import {apiAgent} from "../../../axiosConfig";
import {useSelector} from "react-redux";
import { FlashMessages } from '../../cta_utils/FlashMessages.js';
import ConditionalRender from '../../../ConditionalRender.js'
import FileUpload from '../../../FileUpload.js'
import getApiUrl from '../../utils/UrlHelper.js'
import AnSelectBox from '../../input_utils/AnSelectBox.js'

const all_member_types = [{label: "Admin", value: "admin"}, {label: "Regular", value: "regular"}, {label: "Passive", value: "passive"}];
const ManageTeams = (props) => {
  const [activeTab, setActiveTab] = useState('Active')
  const [popupsList, setPopupsList] = useState({});
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [inviteLink, setInviteLink] = useState('');
  const [successMessage, setSuccessMessage] = useState();
  const current_team = useSelector( state => state.current_team.team);
  const [data,setData] = useState(false);
  const [errors,setErrors] = useState({});
  const [inviteType, setInviteType] = useState("by_email");
  const [inviteForm, setInviteForm] = useState({
    name: "",
    email: "",
    position: "",
    teamRole: ""
  })
  let {email, name, position, teamRole} = inviteForm
  
  const setInviteState = useCallback((payload) => setInviteForm(st => ({...st, ...payload})), [])
  
  
  useEffect(() => {
    props.setActiveSettingTab('manage_teams')
    let paramsString = window.location.search;
    let searchParams = new URLSearchParams(paramsString);
    if(searchParams.get('invite') === 'true'){
      setPopupsList({invitePopup: true});
      getInviteLink();
    }
    if(searchParams.get('to') === 'join_request'){
      setActiveTab('Join Requests')
    }
  }, [])

  useEffect(() => {
    if(teamRole === "passive" && email) setInviteState({email: ""})
  }, [teamRole, email, setInviteState])

  const inviteEmail = () => {
    let errors = {};
    if(!teamRole) errors["teamRole"] = "Please select member type";
    if(!email && teamRole && teamRole !== "passive") {
      errors["email"] = "Email can't be blank";
    }
    if(!name) {
      errors["name"] = "Name can't be blank";
    }    
    setErrors(errors)
    if(Object.keys(errors).length > 0) return;
    setIsLoading(true)
    setData(true)
    let url = `/teams/${current_team.id}/add_team_user`;
    let data_params = {
      email: email.toLowerCase(),
      name: name,
      position: position,
      team_role: teamRole
    }    
    apiAgent({
      method: 'post',
      url: url,
      data: data_params
    }).then(res => {
      setIsLoading(false);
      setSuccessMessage(teamRole === "passive" ? "User added to team" : "User invited and added to team");
      setData(false)
      closePopup();
    }).catch(err => {
      setIsLoading(false)      
    })
  }

  const getInviteLink = () => {
    apiAgent({
      method: 'get',
      url: '/team/invite_link',
      params: {
      }
    }).then(
        (response) => {
          setInviteLink(response.data.invite_link);
        },
        (error)  => {
        }
    );
  }

  const closePopup = () => {
    setPopupsList({});
    setInviteState({name: "", email: "", position: "", teamRole: ""})
  }

  const handleImportSuccess = () => {
      setSuccessMessage("Users will be added to the team shortly");
      closePopup();
  }

  const renderByEmail = () => {
    return (
      <>
          <AnInputBox label={"Name"} error={errors["name"]}>
            <input type="text" name="name" value={name} onChange={(e) => {setInviteState({[e.target.name]: e.target.value}); setErrors({})}} placeholder="Name"/>
          </AnInputBox>          
          <AnInputBox label={"Your Title/Position"} style={{marginTop: 8}}>
            <input type="text" name="position" value={position} onChange={(e) => {setInviteState({[e.target.name]: e.target.value}); setErrors({})}} placeholder="Associate Dean"/>
          </AnInputBox>
          <AnSelectBox label="Member Type" style={{width: "192px", marginTop: 8}} error={errors["teamRole"]} 
            placeholder='Select member type'
            selectedValue={teamRole}
            options={all_member_types}
            callback={(val) => setInviteState({teamRole: val})}
          >
          </AnSelectBox>
          <ConditionalRender if={teamRole === "regular" || teamRole === "admin"}>
            <AnInputBox label={"Email Address"} error={errors["email"]} style={{marginTop: 8}}>
              <input type="text" name="email" value={email} onChange={(e) => {setInviteState({[e.target.name]: e.target.value}); setErrors({})}} placeholder="Email"/>
            </AnInputBox>
          </ConditionalRender>
          <div style={{display: 'flex', marginTop: 16}}>
            <Button buttonSize="Medium" buttonStyle={{width: 'max-content'}} innerButtonStye={{padding: '8px 20px'}}
                    onClick={() => {inviteEmail()}} isLoading={isLoading}>Invite</Button>            
          </div>
      </>
    )
  }

  const renderByImport = () => {
    return (
      <>
        <div className="text_subtitle">Follow the steps to import team members:</div>
          <ol className="text_body_light" style={{paddingLeft: 20, marginTop: 8}}>
            <li>
              Download this sample file - <a href="https://karmabox-temp.s3.amazonaws.com/prod/prod_sample_team_members_import_file.csv" className="text_link hover_under_line">Click here.</a>
            </li>
            <li>
              Fill up the details
            </li>
            <li>
              Save as CSV & Upload.
            </li>
          </ol>
          <FileUpload 
              style={{marginTop: "12px", maxWidth: "600px"}}
              submitUrl={getApiUrl("bulk_import_team_members")}
              uploaderSecondaryText={{displayable: false}}
              submitSuccessMessage="Users will be added to the team shortly"
              onSubmitFinishCallback={handleImportSuccess}
          />
      </>
    )
  }

  const invitePopup = () => {
    return(
        <AnPopup name="invitePopup" handleOnClosePopup={() => {closePopup()}} maxWidth={480}>
          <div className="text_title" style={{marginBottom: 16}}>Invite Team Members</div>
          <div style={{margin: '16px 0px', borderTop: '0.5px solid #B6B9BB'}}></div>          
          <div className='manage_teams_sub_tabs' style={{marginBottom: "18px"}}>
            <TabbedButton buttonStyle={{padding: '0px 0px 10px 0px', marginRight: "18px", borderBottom: "none"}} isActive={inviteType === 'by_email'} onClick={() => {setInviteType('by_email')}}>
              Invite by email
            </TabbedButton>
            <TabbedButton buttonStyle={{padding: '0px 0px 10px 0px', borderBottom: "none"}} isActive={inviteType === 'by_import'} onClick={() => {setInviteType('by_import')}}>
              Import team members
            </TabbedButton>
          </div>
          {
            inviteType === "by_email" ? renderByEmail() : renderByImport()
          }
          {inviteLink && (
              <div>
                <div style={{margin: '16px 0px', borderTop: '0.5px solid #B6B9BB'}}></div>
                <div>
                  <div className="text_body" style={{marginBottom: 4}}>Share your team invite link:</div>
                  <div className="text_link">{inviteLink}</div>
                </div>
              </div>
            )}
        </AnPopup>)
  }

  const renderActiveTabContent = () => {
    if(activeTab=='Active'){
      return <ActiveTeamMembers inviteEmail={data} />
    }else if (activeTab=="Invite") {
      return <InvitedTeamMembers />
    }else if (activeTab=="Join Requests") {
      return <TeamMembershipRequests />
    }
  }

  return(
    <>
      <div className="text_title" style={{marginBottom: 16}}>Manage Team</div>
      <div className="text_body_big_light">Manage your team mates, invite new members to this team and receive join requests from here.</div>
      <div className='manage_teams_container'>
        <div className='manage_teams_tabs'>
          <div className='manage_teams_sub_tabs'>
            <TabbedButton buttonStylestyle={{padding: '12px 16px'}} isActive={activeTab === 'Active'} onClick={() => {setActiveTab('Active')}}>
              Active
            </TabbedButton>
            {/*<TabbedButton isActive={activeTab === 'Invite'} onClick={() => {setActiveTab('Invite')}}>
              Invite
            </TabbedButton>*/}
            <TabbedButton isActive={activeTab === 'Join Requests'} onClick={() => {setActiveTab('Join Requests')}}>
              Join Requests
            </TabbedButton>
          </div>
          <span className='invite_btn'>
            <Button innerButtonStye={{padding: '8px 37px'}} onClick={() => {setPopupsList({invitePopup: true});getInviteLink()}}>
              +Invite
            </Button>
          </span>
        </div>
        {
          renderActiveTabContent()
        }
        {popupsList.invitePopup && invitePopup()}
        {successMessage && <FlashMessages messageTheme='success' firstIcon={true} closeCallback={setSuccessMessage}>{successMessage}</FlashMessages>}
      </div>
    </>
  )
}

export default ManageTeams
