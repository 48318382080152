import React, { useEffect, useReducer } from 'react'
import AnPopup from '../cta_utils/AnPopup'
import { Button, TabbedButton } from '../cta_utils/AnButton'
import ConditionalRender from '../../ConditionalRender'
import { AnInputCheckBox } from '../input_utils/AnInputCheckBox'
import ContinueArrow from "../../assets/ContinueArrow.svg"
import { indexOfObject } from '../utils/ArrayUtils'
import { apiAgent } from '../../axiosConfig'
import getApiUrl from '../utils/UrlHelper'
import { LoaderComponent } from '../utils/LoaderComponent'
import { NameInitial } from '../cta_utils/AnNameInitial'
import {Link} from 'react-router-dom'
import linkedinIcon from "../../assets/linkedin_icon.png"
import { currentMonth, currentYear, getMonthName } from '../utils/dateUtils'
import { AnInputRadioButton } from '../input_utils/AnInputRadioButton'
import EditPencil from '../../assets/EditPencil.svg'
import { DateFilter } from '../cta_utils/AnDateFilter'
import Tick from "../../assets/outbound/Logos/tick.png"
import { AnConfirmDropdown } from '../cta_utils/AnConfirmDropdown'
const initialSelectedDate = {fromMonth: currentMonth, fromYear: currentYear, toMonth: currentMonth, toYear: currentYear}
const AddToCrmPopup = ({
    prospect,
    post,
    ...props
}) => {
    const [modalData, setModalData] = useReducer(reducer, {
        activeTab: "approval_tab",        
        matchedRelations: [],
        currentCrmRelations: [],
        currentLiExp: [],
        selectedRelations: [],
        primaryRelation: null,
        isLoading: {},
        dropDownList: {},
        selectedDate: {...initialSelectedDate},
        matched_ids: []
    })
    const {matchedRelations, activeTab, selectedRelations, currentCrmRelations, currentLiExp, isLoading, primaryRelation, dropDownList, selectedDate, matched_ids} = modalData;
    useEffect(() => {
        setModalData({type: "SET_STATE", payload: {
            isLoading: {fetchingData: true}
        }})
        apiAgent({
            method: "get",
            url: getApiUrl("get_crm_matched_relationships"),
            params: {prospect_id: prospect.id}
        }).then(res => {
            let matchedRel = [...res.data.matched_relations]
            let ids = []
            matchedRel.forEach((rel, index) => {
                rel.updated_start_month = parseInt(rel.start_month);
                rel.updated_start_year = parseInt(rel.start_year);
                rel.updated_end_month = parseInt(rel.end_month);
                rel.updated_end_year = parseInt(rel.end_year)
                if(rel.end_year || rel.end_month)
                ids.push(rel.id)
            })
            setModalData({type: "SET_STATE", payload: {
                matchedRelations: res.data.matched_relations,
                currentCrmRelations: res.data.current_relations,
                currentLiExp: res.data.ln_current_profs,
                selectedRelations: [...res.data.matched_relations],
                primaryRelation: res.data.matched_relations.filter(rel => rel.is_new)[0]?.id,
                isLoading: {},
                matched_ids: ids
            }})
        }).catch(err => {
            setModalData({type: "SET_STATE", payload: {
                isLoading: {},
            }})
        })
    }, [])

    const handleTickMarkChange = (rel) => {
        let index = indexOfObject(selectedRelations, "id", rel.id)
        let temp = [...selectedRelations]      
        if(index === -1){
            temp.push({...rel})
        }else{
            temp.splice(index, 1)
        }
        setModalData({type: "SET_STATE", payload:{ selectedRelations: temp}})
    }
    const handleOnApprove = () => {
        if(selectedRelations.length === 0) return
        let data_params = {
            prospect_id: prospect.id,
            post_id: post.id,
            // Be cautious with is_primary in backend. On edit is_primary should not change. Only change in new jobs
            relations: selectedRelations.map(sel_rel => ({id: sel_rel.id, 
                position: sel_rel.position, 
                company_name: sel_rel.company, 
                start_year: sel_rel.updated_start_year, 
                start_month: sel_rel.updated_start_month, 
                end_year: sel_rel.updated_end_year, 
                end_month: sel_rel.updated_end_month, 
                new_job: sel_rel.is_new,
                is_primary: primaryRelation === sel_rel.id  }))
        }

        setModalData({type: "SET_STATE", payload: {
            isLoading: {approvingData: true}
        }})
        apiAgent({
            method: "post",
            url: getApiUrl("post_crm_matched_relationships"),
            data: data_params
        }).then(res => {
            setModalData({type: "SET_STATE", payload: {
                isLoading: {}
            }})
            // What if prospect is not present in crm ?
            if(res.data.success) {
                props.onFinish?.({success_message: "Post will be saved to CRM", messageTheme: "success", item: {...post, saved_to_crm: true}})
                props.handleOnClosePopup()
            }
        }).catch(err => {
            setModalData({type: "SET_STATE", payload: {
                isLoading: {}
            }})
            props.onFinish?.({success_message: "Something went wrong", messageTheme: "warning"})            
            props.handleOnClosePopup()
        })
    }
    const add_relation = (sel_rel) => {
        let relation = {id: sel_rel.id, position: sel_rel.position, company_name: sel_rel.company, start_year: sel_rel.start_year, start_month: sel_rel.start_month, end_year: sel_rel.end_year, end_month: sel_rel.end_month, new_job: true, is_primary: false }
        let data_params = {
            prospect_id: prospect.id,
            post_id: post.id,
            // Be cautious with is_primary in backend. On edit is_primary should not change. Only change in new jobs
            ...relation
        }
        setModalData({type: "SET_STATE", payload: {
            isLoading: {addingRelation: true}
        }})
        apiAgent({
            method: "post",
            url: getApiUrl("create_relation"),
            data: data_params
        }).then(res => {
            let current_li_exp = [...currentLiExp]
            if(res.data.success) {
                let index = indexOfObject(current_li_exp, "id", sel_rel.id)
                if(index !== -1) 
                current_li_exp[index].saved_to_crm = true
                setModalData({type: "SET_STATE", payload: {
                    isLoading: {},
                    currentLiExp: current_li_exp
                }})
            }
        }).catch(err => {
            setModalData({type: "SET_STATE", payload: {
                isLoading: {}
            }})
        })
    }
    const markAsPrimary = (rel_id) => {                
        setModalData({type: "SET_STATE", payload: {
            primaryRelation: rel_id
        }})
    }
    const getJobDateString = (month, year, fallbackRes="") => {
        let res = "";
        if(!month && !year) return fallbackRes
        if(month) res = getMonthName(month) + " ";
        if(year) res+=year;
        return res
    }
    const handleOnFilterSaveCallback = (id) => {
        let matched_relations = [...matchedRelations]
        let index = indexOfObject(matched_relations, "id", id)
        if(index !== -1)
        {
            matched_relations[index].updated_start_month = parseInt(selectedDate.fromMonth);
            matched_relations[index].updated_start_year = parseInt(selectedDate.fromYear);
            matched_relations[index].updated_end_month = parseInt(selectedDate.toMonth);
            matched_relations[index].updated_end_year = parseInt(selectedDate.toYear)
        }
        setModalData({type: 'SET_STATE', payload: {dropDownList: {[id]: false}, matchedRelations: matched_relations}})
    }
    const handleEditPencil = (rel) => {
        let selected_date = {...selectedDate}
        selected_date.fromMonth = rel.updated_start_month
        selected_date.fromYear = rel.updated_start_year
        selected_date.toMonth = rel.updated_end_month 
        selected_date.toYear = rel.updated_end_year 
        setModalData({type: 'SET_STATE', payload: {dropDownList: {[rel.id]: true}, selectedDate: selected_date} })
    }
    const handleOnCloseDropdown = () => {
        setModalData({type: 'SET_STATE', payload: {dropDownList: {}, selectedDate: {...initialSelectedDate}}})
    }
    const renderMatchedRelations = () => {
        return(
            <div>
                <ConditionalRender if={matchedRelations.length > 0}>
                    <div style={{maxWidth: "672px", marginTop: "20px", border: "1px solid #B6B9BB", borderRadius: "8px"}}>
                        <ConditionalRender if={matchedRelations.filter(rel => !rel.is_new).length !== 0}>
                            <div style={{padding: "20px 16px 0px"}}>
                                <div className='text_body_bold' style={{marginBottom: "16px"}}>End current job{matchedRelations.filter(rel => !rel.is_new).length > 1 ? "s" : ""}</div>                
                                <div>
                                    <div className='default_flex_container' style={{marginBottom: "12px"}}>
                                        <div style={{flexBasis: 16, marginRight: 8}}></div>
                                        <div style={{flexBasis: "100%", maxWidth: '300px'}}>
                                            <div className='grey_keyword_pill text_caption' style={{width: "max-content", marginBottom: 0}}>
                                                CRM data
                                            </div>
                                        </div>
                                        <div style={{flexBasis: 16}}></div>
                                        <div style={{flexBasis: "100%", maxWidth: '300px'}}>
                                            <div className='grey_keyword_pill text_caption' style={{width: "max-content", marginBottom: 0}}>
                                                Changing to
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {matchedRelations.filter(rel => !rel.is_new).map((rel, index) => {
                                    let is_checked = indexOfObject(selectedRelations, "id", rel.id) !== -1
                                    return(
                                        <div key={rel.id + index}className='default_flex_container' style={{marginBottom: "12px"}}>
                                            <AnInputCheckBox isChecked={is_checked} style={{flexBasis: 16, alignSelf: "flex-start", marginRight: 8}}>
                                                <input type="checkbox" checked={is_checked} onChange={() => handleTickMarkChange(rel)}></input>
                                            </AnInputCheckBox>
                                            <div style={{flexBasis: "100%", maxWidth: '300px', minHeight: 69}}>
                                                <div style={{borderRadius: "4px", background: "#F3F4F5", padding: 16}}>
                                                    <div className='text_body'>{rel.old_position}, {rel.company}</div>
                                                    <div className='text_caption_light' style={{marginTop: "4px"}}>{getJobDateString(rel.start_month, rel.start_year)} - present</div>
                                                </div>
                                            </div>
                                                <div className='default_flex_container' style={{width: '100%'}}>
                                                    <img src={ContinueArrow} style={{width: 16}} alt="->"></img>
                                                    <div className="default_flex_container" style={{position: 'relative',flexBasis: "100%", maxWidth: '300px', minHeight: 69}}>
                                                            <div style={{width: "100%", borderRadius: "4px", background: "#F3F4F5", padding: 16}}>
                                                                <div className='text_body'>{rel.new_position}, {rel.company}</div>
                                                                <div className='default_flex_container' style={{marginTop: "4px"}}>
                                                                <div className='text_caption_light'>{getJobDateString(rel.updated_start_month, rel.updated_start_year)} - {getJobDateString(rel.updated_end_month, rel.updated_end_year, "present")}</div>
                                                                <div style={{position: 'relative'}}>
                                                                <img src={EditPencil} alt="edit" style={{cursor: 'pointer', width: 16, height: 16, marginLeft: 4}} onClick={() => handleEditPencil(rel)}/>
                                                                {dropDownList?.[rel.id] && <DateFilter 
                                                                selectedDate={selectedDate} 
                                                                curr_rel_id = {rel.id}
                                                                setSelectedDate={(selected_date) => setModalData({type: 'SET_STATE', payload: {selectedDate: selected_date}})} 
                                                                handleOnFilterSave={(id) => handleOnFilterSaveCallback(id)}
                                                                handleOnClose={() => handleOnCloseDropdown()}/>}
                                                                </div>
                                                                </div>
                                                            </div>         
                                                            {matched_ids.includes(rel.id) ? 
                                                            <div className='matched_label_container green'><div className='matched_label green'>Matched</div></div>       
                                                            : 
                                                            <div className='matched_label_container orange'><div className='matched_label orange'>Not Matched</div></div>       
                                                            }                                   
                                                    </div>
                                                </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </ConditionalRender>
                        <ConditionalRender if={matchedRelations.filter(rel => !rel.is_new).length > 0 && matchedRelations.filter(rel => rel.is_new).length > 0 }>
                            <div style={{marginTop: "16px", borderBottom: "1px solid #B6B9BB"}}></div>
                        </ConditionalRender>
                        <ConditionalRender if={matchedRelations.filter(rel => rel.is_new).length > 0 }>
                            <div style={{padding: "16px 16px 20px"}}>
                                <div className='text_body_bold' style={{marginBottom: "16px"}}>Add new job{matchedRelations.filter(rel => rel.is_new).length > 1 ? "s" : ""}</div>
                                {matchedRelations.filter(rel => rel.is_new).map((rel, index) => {
                                    let is_checked = indexOfObject(selectedRelations, "id", rel.id) !== -1                    
                                    return(
                                        <div key={rel.id + index} className='default_flex_container' style={{marginBottom: "12px"}}>
                                            <AnInputCheckBox isChecked={is_checked} style={{flexBasis: 16, alignSelf: "flex-start", marginRight: 8}}>
                                                <input type="checkbox" checked={is_checked} onChange={() => handleTickMarkChange(rel)}></input>
                                            </AnInputCheckBox>
                                            <div style={{flexBasis: "100%", maxWidth: '300px', minHeight: 69}}>
                                                <div style={{borderRadius: "4px", background: "rgba(111, 184, 237, 0.10)", padding: 16, border: "1px solid #6FB8ED"}}>
                                                    <div className='text_body'>{rel.position}, {rel.company}</div>
                                                    <div className='text_caption_light' style={{marginTop: "4px"}}>{getJobDateString(rel.start_month, rel.start_year)} - {getJobDateString(rel.end_month, rel.end_year, "present")}</div>
                                                </div>
                                            </div>
                                            <ConditionalRender if={is_checked}>
                                                <AnInputRadioButton key={rel.id} label="Primary employment"
                                                    isChosen={primaryRelation === rel.id}
                                                    style={{ marginLeft: "16px" }}    
                                                >
                                                    <input type="radio"
                                                        checked={primaryRelation === rel.id}
                                                        onChange={(e) => markAsPrimary(rel.id)}
                                                    />
                                                </AnInputRadioButton>
                                            </ConditionalRender>                            
                                        </div>
                                    )
                                })}
                            </div>
                        </ConditionalRender>
                    </div>
                    <Button 
                        innerButtonStye={{padding: '8px 20px', marginTop: "21px"}}
                        isLoading={isLoading.approvingData}
                        onClick={handleOnApprove}
                    >
                        Approve
                    </Button>
                </ConditionalRender>
                <ConditionalRender if={matchedRelations.length === 0}>
                    <div style={{maxWidth: "672px", marginTop: "20px", border: "1px solid #B6B9BB", borderRadius: "8px"}}>
                        <div className='text_body_light' style={{margin: "51px auto", width: "max-content"}}>CRM data is up-to-date</div>
                    </div>
                </ConditionalRender>
            </div>
        )
    }

    const renderCRMExp = () => {
        if(currentCrmRelations.length === 0)
            return(
                <div style={{maxWidth: "672px", marginTop: "20px", border: "1px solid #B6B9BB", borderRadius: "8px"}}>
                    <div className='text_body_light' style={{margin: "51px auto", width: "max-content"}}>No data found</div>
                </div>
            )
        return (
            <div>
                {currentCrmRelations.map((crm_rel, index) => {
                    return(
                        <div key={crm_rel.id + index} className='default_flex_container' style={{marginTop: 20, gap: 12}}>
                            <div style={{width: 20, height: 20, borderRadius: "50%", backgroundColor: "#CDCFD2", alignSelf: "flex-start"}}></div>
                            <div style={{maxWidth: 320}}>
                                <div className='text_body'>{[crm_rel.position, crm_rel.company].join(", ")}</div>
                                <div className="text_caption_light" style={{marginTop: 4}}>{getJobDateString(crm_rel.start_month, crm_rel.start_year)} - {getJobDateString(crm_rel.end_month, crm_rel.end_year, "present")}</div>
                            </div>
                        </div>)
                })}
            </div>
        )
    }
    const renderLinkedinExp = () => {
        if(currentLiExp.length === 0)
            return(
                <div style={{maxWidth: "672px", marginTop: "20px", border: "1px solid #B6B9BB", borderRadius: "8px"}}>
                    <div className='text_body_light' style={{margin: "51px auto", width: "max-content"}}>No data found</div>
                </div>
            )
        return (
            <div>
                {currentLiExp.map((li_rel, index) => {
                    let is_matched = matchedRelations.some(rel => rel.prof_id === li_rel.id || rel.id === li_rel.id);
                    return (
                        <div className='flex_container_space_between'>
                            <div key={li_rel.id + index} className='default_flex_container' style={{ marginTop: 20, gap: 12 }}>
                                <div style={{ width: 20, height: 20, borderRadius: "50%", backgroundColor: "#CDCFD2", alignSelf: "flex-start" }}></div>
                                <div style={{ maxWidth: 320 }}>
                                    <div className='text_body'>{[li_rel.position, li_rel.company].join(", ")}</div>
                                    <div className="text_caption_light" style={{ marginTop: 4 }}>{getJobDateString(li_rel.start_month, li_rel.start_year)} - {getJobDateString(li_rel.end_month, li_rel.end_year, "present")}</div>
                                </div>
                            </div>
                            <ConditionalRender if={!is_matched}>
                                <div>{li_rel.saved_to_crm ?
                                    <span className='text_body_light'><img src={Tick} style={{ width: 16, height: 16, marginRight: 2 }}></img>Added to CRM</span>
                                    :
                                    (<div>
                                        <span className='text_link' style={{ cursor: 'pointer' }} onClick={() => setModalData({ type: 'SET_STATE', payload: { dropDownList: { [li_rel.id]: true } } })}>+Add</span>
                                        {dropDownList?.[li_rel.id] && <AnConfirmDropdown 
                                        message='Are you sure you want to add this to your CRM?'
                                        btnLabels={{submitLabel: 'Yes', cancelLabel: 'Cancel'}}
                                        onSubmitCallback={() => add_relation(li_rel)}
                                        onCancelCallback={() => handleOnCloseDropdown()}
                                        />
                                        }
                                    </div>
                                    )
                                }
                                </div>
                            </ConditionalRender>
                        </div>
                    )
                })}
            </div>
        )
    }    
    
    if(isLoading.fetchingData){
        return(
            <AnPopup maxWidth={720} handleOnClosePopup={props.handleOnClosePopup}>
                <LoaderComponent isLoading={true}></LoaderComponent>
            </AnPopup>
        )
    }
    return (
        <AnPopup maxWidth={720} handleOnClosePopup={props.handleOnClosePopup}>
            <div className='text_title'>Save job update to CRM</div>
            <div  className="line_seperator_ex_light_grey"  style={{ margin: "12px 0px 16px" }}></div>
            <div className='prospect_block' style={{padding: 0, marginBottom: 20, justifyContent: 'normal', alignItems: 'flex-start', borderTop: 'none'}}>                
                <NameInitial name={prospect.name || prospect.designation} />
                <div className="prospect_sub_block">
                    <div className="default_flex_container">
                        <Link to={`/prospects/${prospect.id}`} target="_blank" className="text_subtitle_big" style={{textDecoration: 'none'}}>
                            {prospect.name || prospect.designation}
                        </Link>
                        <ConditionalRender if={prospect.linkedin_url}>
                            <a href={prospect.linkedin_url} target="_blank" style={{marginLeft: 6, display: 'flex'}}>
                                <img src={linkedinIcon}></img>
                            </a>
                        </ConditionalRender>
                    </div>
                    <ConditionalRender if={prospect.name}>
                        <div className="text_body_light truncate">
                        {prospect.designation}
                        </div>
                    </ConditionalRender>
                </div>
            </div>
            <div style={{display: 'flex', gap: 32, borderBottom: "1px solid #E7ECEB"}}>
                <TabbedButton buttonStyle={{padding: '0px 0px 12px', borderBottom: "none", color: "#707274"}} isActive={activeTab === "approval_tab"} activeStyle={{color: "#282A2C", fontWeight: "700", borderBottom: "3px solid #00C4B5"}} onClick={() => {setModalData({type: "SWITCH_TAB", payload: "approval_tab"})}}>
                    Approve Employment changes
                </TabbedButton>
                <TabbedButton buttonStyle={{padding: '0px 0px 12px', borderBottom: "none", color: "#707274"}} isActive={activeTab === "crm_exp_tab"} activeStyle={{color: "#282A2C", fontWeight: "700", borderBottom: "3px solid #00C4B5"}} onClick={() => {setModalData({type: "SWITCH_TAB", payload: "crm_exp_tab"})}}>
                    Current CRM experiences ({currentCrmRelations.length})
                </TabbedButton>
                <TabbedButton buttonStyle={{padding: '0px 0px 12px', borderBottom: "none", color: "#707274"}} isActive={activeTab === "li_exp_tab"} activeStyle={{color: "#282A2C", fontWeight: "700", borderBottom: "3px solid #00C4B5"}} onClick={() => {setModalData({type: "SWITCH_TAB", payload: "li_exp_tab"})}}>
                    Current LinkedIn experiences ({currentLiExp.length})
                </TabbedButton>
            </div>
            <ConditionalRender if={activeTab === "approval_tab"}>
                {renderMatchedRelations()}
            </ConditionalRender>
            <ConditionalRender if={activeTab === "crm_exp_tab"}>{renderCRMExp()}</ConditionalRender>
            <ConditionalRender if={activeTab === "li_exp_tab"}>{renderLinkedinExp()}</ConditionalRender>
        </AnPopup>
    )
}

export default AddToCrmPopup

const reducer = (state, action) => {
    switch(action.type){
        case "SET_STATE": {
            return {...state, ...action.payload}
        }
        case "SWITCH_TAB": {
            return {...state, activeTab: action.payload}
        }
        default: return state
    }
}