import React , {useEffect} from 'react';
import { uploadFile } from 'react-s3';

const S3_BUCKET = 'karmabox-temp';
const REGION = 'ap-south-1';
// const ACCESS_KEY = process.env.AWS_ACCESS_KEY;
// const SECRET_ACCESS_KEY = process.env.AWS_SECRET_ACCESS_KEY;
const ACCESS_KEY = 'AKIAUBEI7Y2FX4THBDUO';
const SECRET_ACCESS_KEY = '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';

const config = {
    bucketName: S3_BUCKET,
    region: REGION,
    dirName: 'prod',
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
};

const Uploader = (props) => {

    /*
      setSelectedFile -> File state setter callback
      onUploadBeginCallback(optional)
      onUploadFinishCallback(optional)
      onUploadErrorCallback(Optional)
    */

    useEffect(() => {
        const handleUpload = async (file) => {
            props.onUploadBeginCallback?.()
            uploadFile(file, config)
                .then(data => {
                    // url is in data.location
                    props.setSelectedFile({...props.selectedFile, uploaded: true, uploadedUrl: data.location});
                })
                .catch(err => {
                  props.onUploadErrorCallback?.()
                })
        };
        if(props.selectedFile.files.length > 0){
            handleUpload(props.selectedFile.files[0])            
        }        
    }, [props.selectedFile.files])

    useEffect(() => {
        if(props.selectedFile.uploaded){
            props.onUploadFinishCallback?.();
        }
    }, [props.selectedFile.uploaded])

    const handleFileInput = (e) => {
        if(e.target.files[0]){
            let tmp = new Blob([e.target.files[0]], {type: e.target.files[0].type})
            tmp.name = e.target.files[0].name.replaceAll(/[^\w\.]/g, '');
            props.setSelectedFile({files: [tmp], names: [e.target.files[0].name], uploaded: false});
        }
    };


    return (
        <div>
            <input type="file" onChange={handleFileInput} ref={props.isRefedTo}/>
        </div>
    )
};

export default Uploader;
