import React, { useState, useEffect } from 'react';
import { apiAgent } from '../../../axiosConfig.js';
import { useParams } from 'react-router-dom';
import { AnInputBox } from '../../input_utils/AnInput.js';
import FeedPostErrorBoundary from '../../error_handlers/FeedPostErrorBoundary.jsx';
import Post from "../../feed_components/Post.js";
import { Button } from '../../cta_utils/AnButton.js';
import userIcon from '../../../assets/user.svg';
import userCredentialIcon from '../../../assets/exit.svg';
import AnPopup from "../../cta_utils/AnPopup";
import AnMultipleSuggestionBox from "../../input_utils/AnMultipleSuggestionBox";
import AnMultipleSuggestionInsideBox from "../../input_utils/AnMultipleSuggestionInsideBox";
import { LoaderComponent } from '../../utils/LoaderComponent.js';
import { FlashMessages } from '../../cta_utils/FlashMessages.js';
import FileUpload from '../../../FileUpload.js';
import SuccessMessagePopup from '../../cta_utils/SuccessMessagePopup.js';
import useCheckUserAccess from '../../custom_hooks/useCheckUserAccess.js';
import ConditionalRender from '../../../ConditionalRender.js';
import useWindowSize from '../../custom_hooks/useWindowSize';
import Users from "../../../assets/Users.svg";
import Lock from "../../../assets/Lock.svg";
import AnToggleButton from '../../cta_utils/AnToggleButton.js'
import Folder from "../../../assets/Folder.svg";
import GoBack from '../../cta_utils/GoBack';
import { useSelector } from 'react-redux';
import { NameInitial } from '../../cta_utils/AnNameInitial.js';
import getApiUrl from '../../utils/UrlHelper.js';

const PostFolderShow = () => {
    const [articles, setArticles] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const { folder_id } = useParams();
    const windowDimensions = useWindowSize();
    const checkUserAccess = useCheckUserAccess();
    const [folder, setFolder] = useState();
    const [popupsList, setPopupsList] = useState({});
    const [tempFolder, setTempFolder] = useState({});
    const current_user = useSelector(state => state.current_user.user);

    useEffect(() => {
        apiAgent({
            method: 'get',
            url: getApiUrl("fetch_folder_articles", {":folder_id": folder_id}),
            params: {}
        }).then(res => {
            setIsFetching(false);
            setArticles(res.data.data);
        }).catch(err => {
            setIsFetching(false);
        });
    }, [folder_id]);

    useEffect(() => {
        setIsFetching(true);
        apiAgent({
            method: 'get',
            url: getApiUrl("get_current_folder", {":folder_id": folder_id}),
            params: {}
        }).then(res => {
            setFolder(res.data.folders[0]);
            
        }).catch(err => {
        });
    }, [folder_id]);

    const editFolderPopup = () => {
      
        const handleFolderNameChange = (e) => {
            const updatedFolder = { ...tempFolder };
            updatedFolder.name = e.target.value;
            setTempFolder(updatedFolder);
        };
    
        const handleFolderPrivacyToggle = () => {
            const updatedFolder = { ...tempFolder };
            updatedFolder.showFolderPrivacyToggle = !updatedFolder.showFolderPrivacyToggle;
            setTempFolder(updatedFolder);
        };
    
        const handleFolderSharingData = (val) => {
            const updatedFolder = { ...tempFolder };
            updatedFolder.team_members = val;
            setTempFolder(updatedFolder);
        };
    
        const saveEditedFolder = () => { 
            let members;
            if (tempFolder.showFolderPrivacyToggle) {
            members = [current_user.id].concat(
                tempFolder.team_members.map((member) => member.id)
            );
            } else {
            members = [current_user.id];
            }
            apiAgent({
                method: 'post',
                url: getApiUrl("update_folder"),
                params: {
                  folder_id: tempFolder.id,
                  name: tempFolder.name,
                  team_member_ids: members
                }
            }).then(res => {
              window.location.reload();
            }).catch(err => {
                setIsFetching(false)
            })
        };
    
        return (
            <AnPopup
                name="editFolderPopup"
                handleOnClosePopup={() => setPopupsList({})}
                maxWidth={480}
            >
                <div className="text_title">Edit Folder</div>
                <AnInputBox label={"Folder Name"} style={{ margin: '16px 0px' }}>
                    <input
                        type="text"
                        id="folder_name"
                        value={tempFolder.name}
                        onChange={handleFolderNameChange}
                        placeholder="Folder name"
                    />
                </AnInputBox>
                <AnToggleButton
                    toggleState={tempFolder.showFolderPrivacyToggle}
                    toggleText="Allow team members to this folder"
                    onChangeCallback={handleFolderPrivacyToggle}
                    outerToggleStyle={{ margin: "16px 0px" }}
                />
                <ConditionalRender if={tempFolder.showFolderPrivacyToggle}>
                    <AnMultipleSuggestionInsideBox
                        callback={handleFolderSharingData}
                        suggestionUrl="/suggestions/team_members"
                        placeholder="Search team members"
                        inputClass="an_search_box"
                        name="Team_member_search"
                        theme="default"
                        selectedOptions={tempFolder.team_members || []}
                    />
                </ConditionalRender>
                <Button onClick={saveEditedFolder} buttonSize="LessPadding">
                    Save
                </Button>
            </AnPopup>
        );
      };

    return (
        <>
        <div style={{ display: "flex", justifyContent: "center", paddingTop: 26 }}>
            <div style={{ display: "block", margin: "0px 120px" }}>
                <div className="text_title_big" style={{ marginLeft: 0, color: "#282A2C" }}>
                    <>
                        <div className="default_flex_container" style={{ marginBottom: 8 }}>
                            <GoBack className="hover_under_line" text="" arrow_color={"grey"} />
                            <div className="default_flex_container">
                                <img src={Folder} style={{ weight: 24, height: 24, marginRight: 8, marginLeft: 6 }} alt="Folder Icon" />
                                <div> {folder && folder.name}</div>
                            </div>
                        </div>
                        <div className="text_subtitle_semi_bold" style={{ marginLeft: 46, marginBottom: 8 }}>{folder && folder.post_count} items</div>
                    </>
                </div>
                <div style={{margin: "16px 0px 0px", borderTop: "0.5px solid rgb(182, 185, 187)"}}></div>
                <>
                    <div className="default_flex_container" style={{ alignItems: 'flex-start' }}>
                        <ConditionalRender if={!isFetching}>
                            <ConditionalRender if={articles.length === 0}>
                                <div style={{marginTop: '100px', textAlign: 'center', width: "640px"}}>
                                    <div className="text_body_big_bold" style={{marginBottom: 8}}>Nothing Saved yet</div>
                                    <div className="text_body_light">All the saved items will show up here</div>
                                </div>
                            </ConditionalRender>  
                            <div style={{ maxWidth: 640, marginLeft: 0 }}>
                                {articles.length > 0 && articles.map(item => (
                                    <Post key={item.id} item={item} simpleView={true} windowDimensions={windowDimensions} checkUserAccess={checkUserAccess} />
                                ))}
                            </div>
                        </ConditionalRender>
                        <ConditionalRender if={isFetching}>
                            <div style={{width: 640}}>
                                <LoaderComponent isLoading={isFetching}  loaderStyle={{width: 64, margin: '40px auto', display: 'block'}}/>
                            </div>
                        </ConditionalRender>
                        <div>
                            {folder && folder.team_members.length > 0 ? (
                                <div className='folderInfoBox'>
                                    <div className='flex_container_space_between'>
                                        <div className="text_body_big_bold">
                                            Shared with
                                        </div>
                                        {folder && folder.is_owner && <div className='text_link' onClick={() => {setTempFolder(folder); setPopupsList({editFolderPopup: true})}}>+Add</div>}
                                    </div>
                                    {folder && folder.team_members.length > 0 && folder.team_members.slice(0, 5).map(member => (
                                        <div key={member} className="default_flex_container" style={{ marginTop: 16 }}>
                                            <NameInitial name={member.name} width={40} textStyle={{ fontSize: 16 }}>
                                                {member.name[0]}
                                            </NameInitial>
                                            <div className="text_subtitle" style={{ marginLeft: 6 }}>
                                                {member.name}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                ) : (
                                <div className='folderInfoBox'>
                                    <div style={{ textAlign: "center" }}>
                                        <img src={Lock} style={{ width: 24, height: 24 }} alt="Lock Icon" />
                                        <div className='text_body_big_bold'> Private </div>
                                        <div className='text_body'><span className='text_link' onClick={() => {setTempFolder(folder); setPopupsList({editFolderPopup: true})}} >+ Add </span>team members to this folder</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div> 
                </>
            </div>
        </div>
        {popupsList.editFolderPopup && editFolderPopup()}
        </>
    );
}

export default PostFolderShow;