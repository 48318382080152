import { createSlice } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";

export const currentUserSlice = createSlice({
    name: "current_user",
    initialState: {
        user: {},
        apiKeyStatus: "pending",
        isLoading: false,
        loadError: "",
    },
    reducers: {
        updateCurrentUser: (state, action) => {
            let new_user = action.payload;
            let access_list = {};
            (new_user.access_list || []).forEach(element => {
                access_list[element] = true
            });
            new_user.access_list = access_list;
            return {
                ...state,
                user: new_user,
                apiKeyStatus: "validated",
            };
        },
        updateLoadingStage: (state, action) => {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        updateLoadError: (state, action) => {
            let new_user = state.user;
            if(!action.payload.timedout){
                let cookies = new Cookies();
                cookies.remove("api_key", { path: "/" });
                cookies.remove("team_id", { path: "/" });                
                new_user = {}
            }
            return {
                ...state,
                loadError: action.payload.error_message,
                apiKeyStatus: "failed",
                user: new_user,
            };
        },
        userLogOut: (state) => {
            return {
                ...state,
                user: {},
                apiKeyStatus: "missing",
            };
        },
    },
});

export const {
    updateCurrentUser,
    userLogOut,
    updateLoadingStage,
    updateLoadError,
} = currentUserSlice.actions;

export default currentUserSlice.reducer;
