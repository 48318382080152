import React, { useState } from "react";

const Collapsible = (props) => {
    const [open, setOpen] = useState(false);
    
    const toggle = () => {
        setOpen(!open);
    };
    return (
      <div>
        <div className="text_title_big" style={{cursor: "pointer"}} onClick={toggle}>{props.label} <span style={{float: "right", fontSize: 24}}>{open ? '-' : '+'}</span></div>
            <div className={open ? "content-show" : "content-parent"}>
                <div className='content'> {props.children} </div>
            </div>
      </div>
    );
  };

export default Collapsible;
