import { useState } from "react";
import { apiAgent } from "../../axiosConfig";
import { Button } from "../cta_utils/AnButton.js";

const SearchTeams = ({
    containerStyle,
    placeholder,
    ...props}) => {

    const handleOnChange = (e) => {
        props.setSearchTerm(e.target.value);
    };
    const handleOnKeyDown = (e) => {
        if (e.key == "Enter") {
            props.fetchTeams(1);
        }
    };

    return (
        <div className="default_flex_container" style={{maxWidth: "700px", marginBottom: "24px", ...containerStyle}}>
            <div className="an_search_box" style={{flexBasis: "100%"}}>
                <div style={{ position: "relative" }}>
                    <input
                        type="text"
                        value={props.searchTerm}
                        placeholder={placeholder || "Search Team"}
                        onChange={handleOnChange}
                        onKeyDown={handleOnKeyDown}
                    />
                    <span className="an_search_lens">
                        <img src="/search.svg" />
                    </span>
                </div>
            </div>
            <Button buttonStyle={{marginLeft: "12px"}}
                innerButtonStye={{padding: "9px 44px", height: '100%'}}
                onClick={() => props.fetchTeams(1)}                    
            >
                Search
            </Button>
        </div>

    );
}

export default SearchTeams;
