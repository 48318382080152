import GettingStarted1 from "../../../../../assets/help_center/getting_started1.png"
import GettingStarted2 from "../../../../../assets/help_center/getting_started2.png"
import GettingStartedTags from "../../../../../assets/help_center/getting_started_tags.png"
import GettingStartedTeam from "../../../../../assets/help_center/getting_started_team.png"
import AnScrollToLinkPage from "../../../../cta_utils/AnScrollToLinkPage";

export const ImportFile = () => {

    return (
        <div style={{ marginTop: 24 }}>
            <div className="help_center_top_section">
            <div className="text_title_big" style={{marginBottom: 20}}>Creating a file to import</div>
            <div style={{border: "1px solid grey", padding: "14px 20px", borderRadius: "4px", fontWeight: "700"}}>
                <div className="text_body" style={{color: "#707274", fontWeight: 700}}> SKIP AHEAD TO</div>
                <div style={{marginTop: "12px"}}>
                    <span style={{color: "#437EFE", fontSize: "14px"}}><AnScrollToLinkPage href="#getting_ready" >Getting yourself ready for an import </AnScrollToLinkPage></span>
                    <span style={{color: "#437EFE", marginLeft: 20, fontSize: "14px"}}><AnScrollToLinkPage href="#spreadsheet_tips"> Spreadsheet formatting tips </AnScrollToLinkPage></span>
                    <span style={{color: "#437EFE", marginLeft: 20, marginRigt: 77, fontSize: "14px"}}><AnScrollToLinkPage href="#default_fields"> Default and Custom fields </AnScrollToLinkPage></span>
                </div>
                <div style={{marginTop: "12px"}}>
                    <span style={{color: "#437EFE", marginTop: "12px", fontSize: "14px"}}><AnScrollToLinkPage href="#tags">Tags</AnScrollToLinkPage></span>
                    <span style={{color: "#437EFE", marginLeft: 20, fontSize: "14px"}}><AnScrollToLinkPage href="#team_members" >Team member</AnScrollToLinkPage></span>
                </div>
            </div>
            </div>
            <div className="help_center" id="getting_ready">
                Getting yourself ready for an import
            </div>

            <div className="help_center_text">
                Before you import, you need to think about what kind of prospect data you wish to add to AlmaConnect News and format your import file accordingly.
            </div>
            <img className="help_center_img" src={GettingStarted1} style={{maxWidth: "650px", maxHeight: 133, margin: "16px auto"}}></img>

            <div className="help_center_notes">
                <div className="help_center_item" style={{ padding: "11px 14px"}}> Not sure how to format your CSV? Download our sample file <a className="text_link" href="https://d3sr7cc30ek8f4.cloudfront.net/raw/prod/61dea9e09c69f8001474d9e6/SampleDBforImport-Individuals.csv">here</a></div>
            </div>

            <div className="help_center" id="spreadsheet_tips">
                Spreadsheet formatting tips
            </div>

            <div style={{backgroundColor: "rgba(67, 126, 254, 0.10)", marginTop: 12}}>
                <ul style={{padding: "21px 25px"}}>
                    <li className="help_center_semi_bold">
                        Remove formulas – We recommend using a fresh spreadsheet without any formulas in the import process. If you have a spreadsheet that uses formulas or data-linking to generate the contents of a cell, we suggest copying the data in your spreadsheet and pasting it into a fresh sheet without the formulas.
                    </li>
                    <li  className="help_center_semi_bold" style={{marginTop: 12}}>
                        One tab per spreadsheet – Make sure your spreadsheet only has one tab containing data when importing. If your spreadsheet has more than one tab, you will need to copy and paste them to individual files and import them one file at a time.
                    </li>
                    <li  className="help_center_semi_bold" style={{marginTop: 12}}>
                        No special symbols – Make sure your spreadsheet doesn't have any symbols for numeric or monetary fields. For example, if you are importing a column for deal value, make sure that the cell just includes the number "1000" and not the symbol "$1000".
                    </li>
                    <li  className="help_center_semi_bold" style={{marginTop: 12}}>
                        Spreadsheet size limit – While there is no maximum limit on the number of columns in the spreadsheet, the maximum file size is limited to 50MB, with a maximum limit of 50,000 rows within the spreadsheet.   
                    </li>
                </ul>
            </div>

            <div className="help_center" id="default_fields">
                Default and Custom fields
            </div>

            <div className="help_center_text">
                Name, Company Name, College Name, Location, Title, Assigned Team Members and Tags are the default fields. Each default field will have to be on a separate column in your spreadsheet and be mapped individually to the field that exists in AlmaConnect News.     
            </div>
            <img className="help_center_img" src={GettingStarted2} style={{maxHeight: 360, margin: "24px auto"}}></img>
            <div className="help_center_text">
                If you have additional data about your prospects, you can also easily add them to your data through the import. If the field does not exist as a default field, you can add the field as a custom field before importing any data, so that your data has somewhere to be mapped to.
            </div>
            <div className="help_center_text">
                You can learn more about the default fields and custom fields in this <a className="text_link" href="/help_center?mainTab=Getting%20Started&subTab=Data%20Fields"> article.</a>
            </div>

            <div className="help_center" id="tags">
                Tags   
            </div>

            <div className="help_center_text">
                Tags are descriptive keywords you can add to your prospects to filter them easily. There are two ways to create tags in your team on AlmaConnect News
                <br/>
                <div style={{marginTOp: 12}}></div>
                i. Add tags individually. <br/>
                ii. Importing tags using a csv.
            </div>
            
            <img className="help_center_img" src={GettingStartedTags} style={{maxHeight: 294, margin: "24px auto"}}></img>
            <div className="help_center_text">
                You can learn more about adding tags in this <a className="text_link" href="help_center?mainTab=Prospect Management&subTab=Assigning tags and team members"> article.</a>
            </div>

            <div className="help_center" id="team_members">
                Team members   
            </div>
            <div className="help_center_text">
                If you have started using AlmaConnect News and want to get your coworkers/ team members in on the action too, you can invite them to join your team in AlmaConnect News.
            </div>

            <div className="help_center_notes" style={{marginTop: 16}}>
                <div className="help_center_item" style={{ padding: "11px 14px"}}> This action is only available to Admins </div>
            </div>
            <div className="help_center_item" style={{ marginTop: 16 }}>
                To invite your team members: 
                <ul>
                <li>Go to Settings &#62; Manage team  </li>
                <li>Click on the "+Invite" button</li>
                </ul>
            </div>
            <img className="help_center_img" src={GettingStartedTeam} style={{maxHeight: 294, margin: "24px auto"}}></img>
            
            <div className="help_center_text">
                You can either send your team an invite link or add them manually.
            </div>

            <div className="help_center_notes" style={{marginTop: 16}}>
                <div className="help_center_item" style={{ padding: "11px 14px"}}> It is not mandatory for your team members to join the team to receive news email alerts. But if your team members choose to join your team by signing up, they access the portal and all the features on the portal directly. </div>
            </div>
            <div className="help_center_item" style={{ marginTop: 12, marginBottom: 24}}>
                You can learn more about assigning team members to prospects in this <a className="text_link" href="help_center?mainTab=Prospect Management&subTab=Assigning tags and team members"> article.</a>
            </div>
        </div>
    );
};