import searchIcon from '../../../../../assets/search.svg'
import { useMapperFilterContext } from '../../../new_temp_mapper/contexts/mapper_filter_context'
import { ALL_MAPPER_FILTER_ACTIONS } from '../FiltersStateReducer'
import cross from '../../../../../assets/cross.svg'
import { ALL_TABLE_ACTIONS } from '../../../new_temp_mapper/reducers/TableReducer'
export const ColumnSearch = ({toggleSearchBar}) => {
    const {filtersState, filtersStateReducer, handleFilterApply} = useMapperFilterContext()
    const handleOnChange = (e) => {
        let val = e.target.value;
        filtersStateReducer({type: ALL_MAPPER_FILTER_ACTIONS['SET_STATE'], payload: {search: {name: val}}})
    }
    const handleOnKeyDown = (e) => {
        if (e.key == "Enter") {
            handleFilterApply()
        }
    };
    const cancelSearch = (e) => {
        filtersStateReducer({type: ALL_MAPPER_FILTER_ACTIONS['SET_STATE'], payload: {search: {name: ""}}})
        toggleSearchBar(e)
    }
    return (
        <div className="an_search_box" style={{ padding: '0px', height: 36, width: '100%'}}>
        <div className='default_flex_container' style={{ position: "relative", background: 'white', border: '0.5px solid #70727480', borderRadius: 4 }}>
            <div style={{width: '100%'}}>
            <input
                type="text"
                value={filtersState.search.name}
                placeholder={"Search"}
                onChange={handleOnChange}
                onKeyDown={handleOnKeyDown}
                style={{paddingLeft: 34, height: 36, background: 'white'}}
            />
            </div>
            <div className="an_search_lens" style={{left: 6, height: 24}}>
                <img src={searchIcon} style={{height: 'inherit'}}/>
            </div>
            <div style={{width: 24, height: 24, margin: '0px 6px'}} onClick={cancelSearch}>
                <img src={cross} style={{width: 14, height: 14}}></img>
            </div>
        </div>
    </div>
    )
}