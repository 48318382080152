import React from 'react'
import swapnil from './../../assets/Swapnil.png'
import dhruv from './../../assets/Dhruv.png'
import pranav from './../../assets/Pranav.png'
import shwetha from './../../assets/Shwetha.png'
import tick from './../../assets/Tick.png'
import email from './../../assets/Email.png'
import phone from './../../assets/Phone.png'


class TryAlmaFree extends React.Component{
    render() {
        return (
            <div className="alma_free_container">
                <div className="text_headline3" style={{paddingBottom: '16px'}}>Try AlmaNews for free</div>
                <div className="text_body_big_light">
                    <div className="default_flex_container" style={{paddingBottom: '12px', gap: 8}}><img src={tick} className="tick" /><div>No Credit Card required</div></div>
                    <div className="default_flex_container" style={{paddingBottom: '12px', gap: 8}}><img src={tick} className="tick" /><div>Unlimited logins</div></div>
                    <div className="default_flex_container" style={{paddingBottom: '12px', gap: 8}}><img src={tick} className="tick" /><div>Add unlimited prospects</div></div>
                    <div className="default_flex_container" style={{gap: 8}}><img src={tick} className="tick" /><div>Unlimited news articles daily</div></div>
                </div>
                <div className='line_separate_alma_free'></div>
                <div className="text_title_big contact_us_alma_free">Contact us directly</div>
                <div className="text_body_big_light">
                    <div className='default_flex_container' style={{marginBottom: '12px', gap: 8}}><img src={email} className="tick" /><div>global.sales@almaconnect.com</div></div>
                    <div className='default_flex_container' style={{gap: 8}}><img src={phone} className="tick" /><div>+1 (650) 668-5781</div></div>
                </div>
            </div>
        );
    }
}

export default TryAlmaFree;