import {useState, useEffect} from 'react'
import {apiAgent} from '../../../axiosConfig.js'
import {useSelector} from 'react-redux'

import {Button} from '../../cta_utils/AnButton.js'
import TableHeads from '../../visualizers/tabular/TableHeads.jsx'
import TableRows from '../../visualizers/tabular/TableRows.jsx'


const TeamMembershipRequests = () => {
  
  const [membershipRequests, setMembershipRequests] = useState([]);
  const current_team = useSelector(state => state.current_team.team)

  useEffect(() => {
    apiAgent({
      menthod: 'get',
      url: '/team_members',
      params: {
        //some filter params to see pending
        approval_status: 'pending'
      }
    }).then(res => {
        if(res.data){
          setMembershipRequests(res.data.data)
        }
    }).catch(err => {
        console.log("Error from ActiveTeamMembers component did mount", err)
    })
  }, [])


  const acceptRequest = (index) => {
    let temp = [...membershipRequests]
    temp[index].isApproving = true;
    setMembershipRequests(temp);    
    apiAgent({
      method: 'post',
      url: `/team_members/${membershipRequests[index].id}/approve`,
      data: {}
    }).then(res => {
      let temp = [...membershipRequests]
      temp.splice(index, 1)
      setMembershipRequests(temp)
    }).catch(err => {
      console.log("From Approval Request TeammemberReques component", err)
      let temp = [...membershipRequests]
      temp[index].isApproving = false;
      setMembershipRequests(temp);
    })
  }

  const rejectRequest = (index) => {
    let temp = [...membershipRequests]
    temp[index].isRejecting = true;
    setMembershipRequests(temp);
    apiAgent({
      method: 'post',
      url: `/team_members/${membershipRequests[index].id}/reject`,
      data: {}
    }).then(res => {
      let temp = [...membershipRequests]
      temp.splice(index, 1)
      setMembershipRequests(temp)
    }).catch(err => {
      let temp = [...membershipRequests]
      temp[index].isRejecting = false;
      setMembershipRequests(temp);
      console.log("From Approval Request TeammemberReques component", err)
    })
  }

  return(
    <>
      <table className="data_table manage_team_table_container">
        <TableHeads typoGraphyClass="text_body_big_light" headerClass="table_header_row">
            <th style={{width: "60%"}}>Name</th>
            <th style={{width: "40%"}}></th>
        </TableHeads>
        <tbody>
          {
            membershipRequests.map((member, index) => (              
              <TableRows key={member.id} rowClass="table_content_row">
                <td>
                  <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: 48, height: 48, borderRadius: '50%', background: 'rgba(67, 126, 254, 0.1)', cursor: 'pointer', fontSize: 20, fontWeight: 600, color: '#4D99DF'}}>
                      {member.user.name?.[0].toUpperCase()}
                    </div>
                    <div style={{marginLeft: 10}}>
                      <div className="text_subtitle_big">
                        {member.user.name || "No name"}
                      </div>
                      <div className="text_body_light">
                        {member.user.email}
                      </div>
                    </div>
                  </div>
                </td>
                <td style={{padding: "12px 24px", border: 'none'}}>
                  <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                    <Button buttonSize="Small" innerButtonStye={{padding: '6px 17px'}}
                      disabled={member.isRejecting} isLoading={member.isApproving} onClick={() => {acceptRequest(index)}}>
                      Accept
                    </Button>
                    <Button buttonSize="Small" type="Secondary" buttonStyle={{marginLeft: 10}} innerButtonStye={{padding: '6px 17px'}}
                      disabled={member.isApproving} isLoading={member.isRejecting} onClick={() => {rejectRequest(index)}}>
                      Reject
                    </Button>
                  </div>
                </td>
              </TableRows>
            ))
          }
        </tbody>
      </table>
    </>
  )
}

export default TeamMembershipRequests
