import React, { useState, useEffect, Fragment } from "react";
import { apiAgent } from "../../../axiosConfig.js";
import Pagination from "../../../Pagination";
import { Link, useHistory } from "react-router-dom";
import { switchCurrentTeam } from "../../../actions/teamSliceActions/actions.js";
import { useDispatch } from "react-redux";
import { Button, TabbedButton } from "../../cta_utils/AnButton.js";
import TableHeads from "../../visualizers/tabular/TableHeads.jsx";
import TableRows from "../../visualizers/tabular/TableRows.jsx";
import { LoaderComponent } from "../../utils/LoaderComponent.js";
import { NameInitial } from "../../cta_utils/AnNameInitial";
import AnPopup from "../../cta_utils/AnPopup";
import { AnInputBox } from "../../input_utils/AnInput";
import { FlashMessages } from "../../cta_utils/FlashMessages";
import { AnInputCheckBox } from "../../input_utils/AnInputCheckBox";
import ConditionalRender from "../../../ConditionalRender.js";
import AnToggleButton from "../../cta_utils/AnToggleButton.js";

const flaggedByTypes = [{ label: 'From Newsfeed', key: 'from_newsfeed' }, { label: "From Smart Publish", key: 'from_smart_publish' }];
const PostTypes = [{ label: "News results", key: "news_results" }, { label: "Job updates", key: "job_updates" }];

const FlaggedArticles = () => {
    let history = useHistory();
    const [articles, setArticles] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [nonReplied, setNonReplied] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [currArticle, setCurrArticle] = useState({});
    const [popupsList, setPopupsList] = useState({});
    const [successMsg, setSuccessMsg] = useState(null);
    const [flaggedByType, setFlaggedByType] = useState("from_newsfeed");
    const _dispatch = useDispatch();
    const [postType, setPostType] = useState("news_results");

    useEffect(() => {
        fetchFlaggedArticles(currentPage);
    }, [flaggedByType, nonReplied, postType]);

    const fetchFlaggedArticles = (page) => {
        let type = postType === 'job_updates' ? postType : flaggedByType
        setIsFetching(true);
        apiAgent({
            method: "get",
            url: '/aggregates/flagged_articles',
            params: {
                page: page,
                not_replied: nonReplied,
                type: type
            },
        }).then(
            (res) => {
                setArticles(res.data.data);
                setTotalPages(res.data.total_pages);
                setTotal(res.data.total);
                setIsFetching(false);
                setCurrentPage(page);
            },
            (error) => {
                setIsFetching(false);
            }
        );
    };

    const removeArticle = (id) => {
        if (!window.confirm("Are you sure you want to remove?")) return;
        apiAgent({
            method: "post",
            url: `/flagged_articles/${id}/remove`,
            data: {
            },
        }).then(
            (response) => {
                fetchFlaggedArticles(1);
                setSuccessMsg("Deleted");
                setPopupsList({});
            },
            (error) => {
                setPopupsList({});
            }
        );
    }

    const addReply = () => {
        setIsLoading(true);
        apiAgent({
            method: "post",
            url: `/flagged_articles/${currArticle.id}/reply`,
            data: {
                reply: currArticle.reply
            },
        }).then(
            (response) => {
                setIsLoading(false);
                fetchFlaggedArticles(1);
                setSuccessMsg("Reply added");
                setPopupsList({});
            },
            (error) => {
                setPopupsList({});
            }
        );
    }

    const addReviewPopup = () => {
        return (
            <AnPopup name="addReviewPopup" handleOnClosePopup={() => setPopupsList({})} maxWidth={480}>
                <div className="text_title" style={{ marginBottom: 16 }}>Reply</div>
                <AnInputBox>
                    <textarea name="name" value={currArticle.reply} onChange={(e) => setCurrArticle({ ...currArticle, reply: e.target.value })} placeholder="Top Prospect"></textarea>
                </AnInputBox>
                <div style={{ display: 'flex', marginTop: 16 }}>
                    <Button buttonSize="Medium" buttonStyle={{ width: 'max-content' }} innerButtonStye={{ padding: '8px 20px' }}
                        onClick={() => { addReply() }} isLoading={isLoading}>Add Reply</Button>
                </div>
            </AnPopup>)
    }

    const updateArticleType = (type) => {
        setFlaggedByType(type);
    }

    const renderFlaggedArticles = () => {
        return (
            <div>
                {articles && articles.map(article => {
                    let article_url = new URL(article.url)
                    article_url.searchParams.set("from_flagged", true)
                    return (
                        <div className="article_container" style={{ position: 'relative' }} key={article.id}>
                            <div className="text_caption_error" style={{ position: 'absolute', top: 24, right: 24, cursor: 'pointer' }} onClick={() => { removeArticle(article.id) }}>Remove</div>
                            {
                                article.prospects.map(prospect => (
                                    <div style={{ display: 'flex', marginBottom: 16 }}>
                                        <NameInitial
                                            name={prospect.name}
                                        />
                                        <div style={{ marginLeft: "16px" }}>
                                            <div className="text_subtitle_big">{prospect.name}</div>
                                            <div className="text_body_light">{prospect.company}</div>
                                        </div>
                                        <div style={{ clear: "both" }}></div>
                                    </div>
                                ))
                            }
                            <div className="text_body news_container" style={{ background: '#FAFBFC', border: '0.5px solid #B6B9BB)', padding: 12 }}>
                                URL: <span><Link to={article_url} target="_blank" className="text_link">{article.url}</Link></span>
                            </div>
                            <div className="text_body_light" style={{ marginTop: 12 }}>
                                Team: <span> {article.team}</span>
                            </div>
                            <div className="line_seperator_ex_light_grey" style={{ margin: "16px auto" }}></div>
                            <div className="text_body_light" style={{ marginTop: 12 }}>
                                Flagged by <span className="text_body_bold"> {article.flagged_by} </span> on <span className="text_body_bold"> {article.flagged_on} </span>
                            </div>
                            <div className="text_body_light" style={{ marginTop: 12 }}>
                                Comments: <span className="text_body"> {article.comment}</span>
                            </div>
                            <div className="text_body_light" style={{ marginTop: 12 }}>
                                Reply: <span className="text_body"> {article.reply} <span className="text_link" onClick={() => { setPopupsList({ addReviewPopup: true }); setCurrArticle(article) }}>
                                    {article.reply ? 'Edit' : 'Add'} </span>
                                </span>
                            </div>
                            <ConditionalRender if={article.rss_candidate_id}>
                                <div style={{marginTop: 12}}>
                                <Link to={{ pathname: `/rss-candidates/${article.rss_candidate_id}/external_article`, search: `?article_url=${article.sharable_article_url ? article.sharable_article_url : ""}` }}
                                    className="text_link"
                                    target="_blank">Help us improve</Link>
                                </div>
                            </ConditionalRender> 
                        </div>
                    )
                }
                )}
            </div>
        );
    }

    return (
        <div>
            <div className="aggregate_container" style={{ maxWidth: "880px", marginTop: 32 }}>
                <div className="text_title_big" style={{ marginBottom: "16px" }}>Flagged Posts</div>
                <div style={{ display: 'flex'}}>
                    {
                        PostTypes.map(type => (
                            <div key={type.key} className={postType === type.key ? 'text_link box_border_highlight' : 'text_body_light'} style={{ cursor: 'pointer', padding: '8px 16px' }} onClick={() => { setPostType(type.key) }}>{type.label}</div>
                        ))
                    }
                </div>
                <ConditionalRender if={postType === 'news_results'}>
                <div className="default_flex_container" style={{ marginTop: 24, gap: 24, borderBottom: "1px solid #B6B9BB", maxWidth: 'fit-content'}}>
                    {
                        flaggedByTypes.map(type => (
                            <div key={type.key}>
                                <TabbedButton buttonStyle={{ padding: '0px 0px 10px', borderBottom: "none"}} isActive={flaggedByType === type.key} onClick={() => { updateArticleType(type.key) }}>
                                    {type.label}
                                </TabbedButton>
                            </div>
                        ))
                    }
                </div>
                </ConditionalRender>
                <div style={{ clear: 'both' }}></div>
                <div className="flex_container_space_between" style={{marginTop: 32}}>
                <div className="text_subtitle_big">{total} {total > 1 ? 'Posts' : 'Post'}</div>
                <div>
                    <Pagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        onPageChange={fetchFlaggedArticles}
                    />
                </div>
                <div>
                        <AnToggleButton
                            toggleState={nonReplied}
                            toggleText="Only non replied"
                            onChangeCallback={() => setNonReplied(!nonReplied)}
                        />
                </div>
                </div>
                {
                    !isFetching ?
                        renderFlaggedArticles() :
                        (<LoaderComponent isLoading={true} loaderStyle={{ width: 32, margin: '36px auto', display: 'block' }} />)

                }
            </div>
            {popupsList.addReviewPopup && addReviewPopup()}
            {successMsg && (
                <FlashMessages
                    messageTheme="success"
                    closeCallback={() =>
                        setSuccessMsg(null)
                    }
                >
                    {successMsg}
                </FlashMessages>
            )}
        </div>
    );
};

export default FlaggedArticles;
