import { useState } from "react";
import tick2 from "../../assets/tick2.svg"
import filtersIcon2 from "../../assets/filtersIcon2.svg"
import AnDropDown from "./AnDropDown.js";
import ConditionalRender from "../../ConditionalRender";

const AnDropdownFilter = ({
    innerStyle={},
    ...props
}) => {
    const [showdropdown, setShowdropdown] = useState(false); 
    const handleFilter = () => {
        return (
            <AnDropDown
                style={{
                    position: "absolute",
                    right: 0,
                    padding: "14px",
                    background: "white",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                    borderRadius: 4,
                    width: "fit-content",
                    zIndex: 99,
                    ...innerStyle
                }}
                toggleDropDown={() => setShowdropdown(false)}
            >
                {
                    props.options.map((option) => (
                        <div
                            key={option.key}
                            className={ props.option == option.key ? "an_dropdown_filter text_body" : "an_dropdown_filter text_body_light" }
                            onClick={() => props.setOption(option.key)}
                        >
                        <span>{option.label}</span>
                        { props.option == option.key && <img src={tick2} style={{marginLeft: 4}}></img>}
                        </div>
                    ))
                }
        </AnDropDown>
        )
    }
    return (
        <>
            <div onClick={() => setShowdropdown(!showdropdown)} style={props.style}>
                <ConditionalRender if={props.children}>
                    {props.children}
                </ConditionalRender>
                <ConditionalRender if={!props.children}>
                    <img src={filtersIcon2} style={{cursor: "pointer"}}></img>
                </ConditionalRender>
            {  showdropdown && handleFilter() }
            </div>
        </>
    )
}

export default AnDropdownFilter