export const AllDataMineFilterTabs = [
    {
       label: "Recent Job Changes",
       tab_key: "profile_updates",
       active: true
    },
    {
        label: "Matching type",
        tab_key: "matching_type",
        active: true,
        reset_label: "All data"
    },
    {
        label: "Company",
        tab_key: "current_company",
        active: true,
        search_enabled: true,
        suggestion_url: "/linkedin_prospects/term_search",
        search_term: "current_company",
        placeholder: "Search Company",
        reset_label: "All Companies"
    },
    {
        label: "Designation",
        tab_key: "current_designation",
        active: true,
        search_enabled: true,
        suggestion_url: "/linkedin_prospects/term_search",
        search_term: "current_designation",
        placeholder: "Search Designation",
        reset_label: "All Designations"
    },
    {
        label: "Location",
        tab_key: "location",
        active: true,
        search_enabled: true,
        suggestion_url: "/linkedin_prospects/term_search",
        search_term: "location",
        placeholder: "Search Location",
        reset_label: "All Locations"
    },
    {
        label: "Industry",
        tab_key: "industry",
        active: false,
        search_enabled: true,
        suggestion_url: "/linkedin_prospects/term_search",
        search_term: "industry",
        placeholder: "Search Industry",
        reset_label: "All Industries"
    },
    {
        label: "Seniority Level",
        tab_key: "seniority_level",
        active: false,
        search_enabled: false,
        reset_label: "All Seniority Level"
    },
    // {
    //     label: "Company Size",
    //     tab_key: "company_size",
    //     type: "range",
    //     initialValue: {min: 0, max: 100},
    //     limit: {min: 0},
    //     active: false
    // },
    {
        label: "Education",
        tab_key: "institute",
        search_enabled: true,
        suggestion_url: "/linkedin_prospects/term_search",
        search_term: "institute",
        placeholder: "Search Education",
        active: false,
        reset_label: "All Educations"
    },
    {
        label: "Course",
        tab_key: "course",
        search_enabled: true,
        suggestion_url: "/linkedin_prospects/term_search",
        search_term: "course",        
        placeholder: "Search Course",
        active: false,
        reset_label: "All Courses"
    },
    {
        label: "Branch",
        tab_key: "branch",
        search_enabled: true,
        suggestion_url: "/linkedin_prospects/term_search",
        search_term: "branch",
        placeholder: "Search Branch",
        active: false,
        reset_label: "All Branches"
    },
    {
        label: "Graduation Year",
        tab_key: "graduation_year",
        search_enabled: true,
        suggestion_url: "/linkedin_prospects/term_search",
        search_term: "graduation_year",
        placeholder: "Search Graduation year",
        active: false,
        reset_label: "All Graduation years"
    },
    {
        label: "Volunteering Role",
        tab_key: "volunteering_role",
        search_enabled: true,
        suggestion_url: "/linkedin_prospects/term_search",
        search_term: "volunteering_role",
        placeholder: "Search Volunteering Role",
        active: false,
        reset_label: "All Volunteering Roles"
    },
    {
        label: "Volunteering Type",
        tab_key: "volunteering_type",
        search_enabled: true,
        suggestion_url: "/linkedin_prospects/term_search",
        search_term: "volunteering_type",
        placeholder: "Search Volunteering Type",
        active: false,
        reset_label: "All Volunteering Type"
    },
    {
        label: "Skills",
        tab_key: "skill",
        search_enabled: true,
        suggestion_url: "/linkedin_prospects/term_search",
        search_term: "skill",
        placeholder: "Search Skill",
        active: false,
        reset_label: "All Skills"
    },    
]