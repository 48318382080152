import React, { useState, useRef, useEffect } from 'react';
import Cross from "../../assets/Cancel.svg";
import AnDropDown from '../cta_utils/AnDropDown';
import ArrowDown from "../../assets/darkDropdown.svg";
import Cancel from "../../assets/Cancel.svg";
import { useCallback } from 'react';
import {debounce} from 'lodash'
import { apiAgent } from '../../axiosConfig';
import ConditionalRender from '../../ConditionalRender';
import { NameInitial } from "../cta_utils/AnNameInitial.js";

const MINCHAR = 2

const AnMultiSelectDropdown = ({
  options = [],
  maxSelections = 10,
  dropdownStyle,
  ...props
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [enableInput, setEnableINput] = useState(true);
  const dropdownRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [props.selectedValues.length]);

  const handleDocumentClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setShowOptions(false);
    }
  };

  const handleSelectChange = (option) => {
    const selectedOption = option;
    if (
      selectedOption &&
      !props.selectedValues.some((opt) => opt.key === selectedOption.key) &&
      props.selectedValues.length < maxSelections
    ) {
      props.setSelectedValues((prevSelectedValues) => [
        ...prevSelectedValues,
        selectedOption,
      ]);
  
      if (props.selectedValues.length + 1 === maxSelections) {
        setShowOptions(false);
      }
    }
  };

  const handleDeselect = (index) => {
    const newSelectedValues = props.selectedValues.filter((_, i) => i !== index);
    props.setSelectedValues(newSelectedValues);
    setShowOptions(true);
  };

  const handleOnClick = (e) => {
    setShowOptions(false);
    if(props.selectedValues.length < maxSelections){
        setShowOptions(true);
    }
  }

  const renderOptionList = () => {
    if(showOptions){
      return (
      <ul className="suggestions_container" style={{ listStyle: "none", width: 212, overflowX: "hidden" }}>
        {options.map((option, index) => {
            const key = Object.keys(option)[0];
            const optionName = option[key];

            return (
                <React.Fragment key={key}>
                {index > 0 && <div style={{ border: "1px solid #E7ECEB", margin: "0px -24px" }}></div>}
                <div className="text_body" style={{ fontWeight: 700, padding: "8px" }}>
                    {Object.keys(option)}
                </div>
                {optionName.map((field) => (
                    <li
                    className="text_subtitle_semi_bold"
                    key={field.key}
                    style={{ cursor: "pointer", padding: "4px 8px" }}
                    onClick={() => handleSelectChange(field)}
                    >
                    {field.label}
                    </li>
                ))}
                </React.Fragment>
            );
        })}
      </ul>)
    }else{
      return(
        <ul className="suggestions_container" style={{listStyle: "none"}}>
        </ul>
        )
    }
  };

  const selectedOptionPills = props.selectedValues.map((opt, index) => (
    <div key={opt.key} className='keyword_pill' style={{ border: "none", background: "rgba(112, 114, 116, 0.15)", margin: "4px 2px", borderRadius: 4 }}>
      <div className='text_caption'>{opt.label}</div>
      <img
        src={Cancel}
        alt='X'
        style={{ width: '16px', height: '16px', cursor: 'pointer', marginLeft: 4 }}
        onClick={(e) => handleDeselect(index)}
      ></img>
    </div>
  ));  

  return (
    <div className='user_search_space_root' style={{ margin: '16px 0px' }}>
      <div className='all_keywords_container'>
        <div className='card_item'>
            <div
            className='keywords_card_container'
            style={{
                padding: '0px 8px',
                border: '1px solid #b6b9bb',
                height: "auto",
                width: 432,
            }}
            ref={dropdownRef}
            >
            <div className='keyword_card'>
                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                {selectedOptionPills}
                <div className='invisible_pill' style={{padding: "10px 0px", marginBottom: 0}}>
                    <input
                    name={props.name}
                    className='inline_inputter'
                    onClick={handleOnClick}
                    style={{ flex: 1 }}
                    />
                    {renderOptionList()}
                </div>
                </div>
            </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default AnMultiSelectDropdown;